/* eslint-disable react-hooks/rules-of-hooks */
import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import withSizes from "react-sizes";
import SiteNavigationLink from "./SiteNavigationLink";
import OverflowMenu from "./OverflowMenu";
import breakpoints from "utils/styles/breakpoints";
import { getFontAwesomeIcon } from "features/MainNavigation/utils";
import useIsExtensionPopout from "hooks/useIsExtensionPopout";

type Props = {
  siteNavigation: {
    members: {
      icon: string;
      uiLink: string;
      name: string;
      title: string;
      badgeRecord: unknown;
    }[];
  };
};

type ComposedProps = {
  hideMenu: boolean;
  showOverflowMenu: boolean;
};

class SiteNavigation extends Component<Props & ComposedProps> {
  mapFunc({
    icon,
    uiLink,
    name,
    title,
    badgeRecord,
  }: {
    icon: string;
    uiLink: string;
    name: string;
    title: string;
    badgeRecord: unknown;
  }) {
    const fontAwesomeIcon = getFontAwesomeIcon(icon);

    // Do not display automation, analytics, or settings when in Chrome extension.
    if (useIsExtensionPopout()) {
      if (name === "analytics" || name === "settings" || name === "automation") {
        return null;
      }
    }

    return (
      <SiteNavigationLink
        key={name}
        to={uiLink}
        title={title}
        badge={badgeRecord}
        badgeId={get(badgeRecord, "id", badgeRecord)}
        icon={fontAwesomeIcon}
      />
    );
  }

  render() {
    const { siteNavigation, hideMenu, showOverflowMenu } = this.props;
    const baseNav = siteNavigation.members.filter((m) => {
      return m.name !== "analytics" && m.name !== "settings";
    });

    // All navigation items excluding Analytics and Settings.
    const baseNavigation = baseNav.map(this.mapFunc);

    // All navigation items.
    const allNavigation = siteNavigation.members.map(this.mapFunc);

    // Do not show any navigation when screen size is 0px - 599px.
    if (hideMenu) {
      return null;
    }

    // Show navigation with overflow menu for screen sizes 600px - 767px.
    if (showOverflowMenu) {
      return (
        <>
          {baseNavigation}
          {!useIsExtensionPopout() && (
            <OverflowMenu siteNavigation={siteNavigation} />
          )}
        </>
      );
    }

    // Show navigation without overflow menu for screen sizes 768px+.
    return allNavigation;
  }
}

export default withSizes<ComposedProps, ComposedProps & Props>(({ width }) => {
  return {
    hideMenu: width < breakpoints.navbarMedium, // 0px - 599px
    showOverflowMenu:
      width >= breakpoints.navbarMedium && width < breakpoints.medium, // 600px - 767px
  };
})(SiteNavigation) as (p: Props) => JSX.Element; // todo: https://github.com/DefinitelyTyped/DefinitelyTyped/pull/51460
