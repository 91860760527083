import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import capitalize from "lodash/capitalize";

import MuiErrorIcon from "@mui/icons-material/Error";
import Tooltip from "@mui/material/Tooltip";

import Logo from "components/Logo";

const Wrapper = styled.span`
  align-items: center;
  display: flex;
  font-weight: 700;
`;

const StatusText = styled.span`
  margin: 0 5px;
`;

const StyledErrorBase = styled.div`
  background: white;
  position: relative;
  height: 19px;
  width: 18px;
  margin-right: 5px;
  margin-left: 2px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledErrorIcon = styled(MuiErrorIcon)`
  position: relative;
  left: 0;
  right: 0;
  margin-left: 1px;
  color: ${(props) => {
    return props.theme.colors.error.main;
  }};
`;

class DeliveryStatus extends Component {
  static propTypes = {
    isLastMessage: PropTypes.bool,
    message: PropTypes.object.isRequired,
  };

  renderFailureStatus() {
    const { message } = this.props;
    const statusText = message.errorCreating
      ? "Something went wrong!"
      : "Not Delivered";
    return (
      <Wrapper>
        <Tooltip title={message.friendlyStateDescription}>
          <StyledErrorBase>
            <StyledErrorIcon></StyledErrorIcon>
          </StyledErrorBase>
        </Tooltip>
        <StatusText>{statusText}</StatusText>
      </Wrapper>
    );
  }

  renderSendingStatus() {
    return (
      <Wrapper>
        <Logo animate dotColor="primary" color="transparent" width={20} />
      </Wrapper>
    );
  }

  renderStatusTextOnly() {
    const { message } = this.props;
    return (
      <Wrapper>
        <StatusText data-testid="status-text">
          {capitalize(message.deliveryState)}
        </StatusText>
      </Wrapper>
    );
  }

  renderSuccessStatus() {
    const { isLastMessage } = this.props;
    if (!isLastMessage) {
      return null;
    }
    return this.renderStatusTextOnly();
  }

  render() {
    const {
      message: { deliveryState, errorCreating },
    } = this.props;
    if (
      !errorCreating &&
      ["requested", "created", "queued"].includes(deliveryState)
    ) {
      return this.renderSendingStatus();
    }
    if (!errorCreating && deliveryState === "dispatched") {
      return null;
    }
    if (
      errorCreating ||
      ["failed", "failed_transient"].includes(deliveryState)
    ) {
      return this.renderFailureStatus();
    }
    if (deliveryState === "delivered") {
      return this.renderSuccessStatus();
    }
    return this.renderStatusTextOnly();
  }
}

export default DeliveryStatus;
