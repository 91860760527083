import PropTypes from "prop-types";
import get from "lodash/get";

import { Field } from "formik";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import renderOptionalTooltip from "../utils/renderOptionalTooltip";

function PersonalizationTextField({
  personalization,
  errors,
  setFieldTouched,
  setFormIsDirty,
  touched,
  values,
}) {
  const { description, title, type } = personalization;
  return (
    <Box my={4}>
      <Field name={`${title}.value`}>
        {({ field, form }) => {
          return (
            <Box display="flex" flex="0 0 auto">
              <TextField
                {...field}
                error={
                  touched[title] &&
                  Boolean(get(errors, [title, "value"], undefined))
                }
                helperText={
                  touched[title] && get(errors, [title, "value"], undefined)
                }
                id={`${title}.value`}
                inputProps={
                  type === "email" ? { "data-lpignore": true } : undefined
                }
                label={title}
                onBlur={() => {
                  setFieldTouched(title);
                  setFormIsDirty(form.dirty);
                }}
                onChange={(e) => {
                  setFieldTouched(title);
                  form.setFieldValue(field.name, e.target.value);
                }}
                style={{ width: "80%" }}
                type="text"
                variant="outlined"
                value={get(values, [title, "value"], "")}
              />
              <Box ml={2}>
                {description && renderOptionalTooltip(description, title)}
              </Box>
            </Box>
          );
        }}
      </Field>
    </Box>
  );
}

PersonalizationTextField.propTypes = {
  personalization: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PersonalizationTextField;
