import { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import Loader from "components/Loader";
import Logo from "components/Logo";
import withRecord from "higherOrderComponents/withRecord";

function BrandRegistration({ fetchAccountRequest, id, name, slug }) {
  const [accountSettings, setAccountSettings] = useState({});
  const [loading, setLoading] = useState(true);

  const registrationLink = `https://help.textus.com/s/10dlc-registration${
    slug ? `?next_slug=${slug}` : ""
  }`;
  const { brandStatus } = accountSettings ?? {};

  useEffect(() => {
    fetchAccountRequest(id, null, {
      successCallback: (response) => {
        const { settings } = response ?? {};
        setAccountSettings(settings);
        setLoading(false);
      },
    });
  }, [id]);

  const renderStatus = useCallback(
    (status) => {
      if (status === "accepted") {
        return (
          <Box display="flex">
            <CheckCircleOutlineOutlinedIcon margin="0.6rem" />
            <Typography
              fontSize="1rem"
              fontWeight={200}
              margin="0.4rem"
              component="h3"
            >
              Accepted
            </Typography>
          </Box>
        );
      }
      if (status === "rejected") {
        return (
          <Box display="flex" style={{ color: "#840d0d" }}>
            <HighlightOffOutlinedIcon margin="0.6rem" />
            <Typography
              fontSize="1rem"
              fontWeight={200}
              margin="0.4rem"
              component="h3"
            >
              Rejected
            </Typography>
          </Box>
        );
      }
      if (status === "pending") {
        return (
          <Box display="flex">
            <AccessTimeOutlinedIcon margin="0.6rem" />
            <Typography
              fontSize="1rem"
              fontWeight={200}
              margin="0.4rem"
              component="h3"
            >
              Pending
            </Typography>
          </Box>
        );
      }
      return (
        <Button
          color="primary"
          href={registrationLink}
          target="_blank"
          variant="contained"
        >
          Register
        </Button>
      );
    },
    [registrationLink],
  );

  const getBrandRegistrationCopy = useCallback(() => {
    if (brandStatus === "accepted") {
      return "Your Brand has been successfully registered. Complete your Campaign Use Cases if you have not done so yet.";
    }
    if (brandStatus === "rejected") {
      return "Your Brand registration has been rejected. Please contact our Support Team to learn more.";
    }
    if (brandStatus === "pending") {
      return "Your Brand registration may take up to 24 hours to be reflected on this page.";
    }
    return "Click here to begin registering your brand. You will be brought to a form to complete.";
  }, [brandStatus]);

  if (loading) {
    return (
      <Loader isLoading={loading} aria-label="Page is Loading">
        <Logo animate dotColor="contrast" />
      </Loader>
    );
  }
  return (
    <>
      <Typography component="h2" fontSize="1.5rem" fontWeight={300}>
        {name}
      </Typography>
      <Box display="flex" flexDirection="column" m="1rem 2rem" width="60%">
        <Box display="flex" justifyContent="space-between" m=".5rem 0">
          <Typography component="h3" fontSize="1rem" fontWeight={200}>
            Register
          </Typography>
          <Box display="flex">
            {renderStatus(brandStatus)}
            <Box display="inline-block" m=".5rem">
              <Tooltip placement="top" title={getBrandRegistrationCopy()}>
                <InfoOutlinedIcon m={4} />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

BrandRegistration.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  fetchAccountRequest: PropTypes.func.isRequired,
};

export default withRecord({
  actions: ["fetch"],
  container:
    "features/AccountSettings/BrandInformation/BrandRegistration/account",
  multiple: true,
  noFetch: true,
  shape: {},
  showLoader: () => {
    return false;
  },
  type: "account",
})(BrandRegistration);
