import { Component } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import Modal from "components/Modal";
import QuickComposer from "features/Compose/containers/QuickComposer";

class ComposeRoot extends Component {
  static propTypes = {
    composeRootContainer: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool,
    setCompose: PropTypes.func.isRequired,
  };

  handleClose = () => {
    this.props.setCompose({ active: false, recipientIds: [] });
  };

  render() {
    const {
      composeRootContainer: {
        substate: { active, recipientIds },
      },
      fullScreen,
    } = this.props;
    return (
      <Modal
        transitionIn={active}
        fullScreen={fullScreen}
        isActive={active}
        closeModal={this.handleClose}
      >
        <QuickComposer
          closeModal={this.handleClose}
          fullScreen={fullScreen}
          isActive={active}
          recipientIds={recipientIds}
        />
      </Modal>
    );
  }
}

export default withSizes(({ width }) => {
  return { fullScreen: width < 620 };
})(ComposeRoot);
