import { Badge, Box, IconButton, Menu, MenuItem } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useSnackbar } from "notistack";
import { useFilters } from "../../hooks/useFilters";
import { useDetailReportHeader } from "../../hooks/useDetailReportHeader";
import BillingFilter from "../BillingFilter";
import EditAnalyticsReportModal from "../EditAnalyticsReportModal";
import ScheduleAnalyticsReportModal from "../ScheduleAnalyticsReportModal";
import { Usage } from "../../models/BillingAnalyticsModels";
import { fetchAccountUsage } from "../../api/fetchAccountUsage";
import { AccountUsageTableProps } from "./types";
import { useLoading } from "hooks";
import { Snackbar } from "components/Snackbar";

export function AccountUsageTable({
  currentAccount,
  currentUser,
  data,
  filterOptions,
  filterSelectHandler,
  fullScreen,
  paginationData,
  scheduledReports,
  selectedFilterOption,
  setAccountUsageData,
  setPaginationData,
  setScheduledReports,
  sendReport,
  isLoadingAccountUsage,
  pageUrl,
  paginationModel,
  setPaginationModel,
}: AccountUsageTableProps) {
  const gridHeaderHeight = 60;
  const gridMinColumnWidth = 160;
  const columns = [
    {
      field: "accountName",
      headerName: "Account name",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
    {
      field: "messageCount",
      headerName: "Total messages sent",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
    {
      field: "segmentCount",
      headerName: "SMS messages used",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
  ];

  const [url, setUrl] = useState<string | undefined>(pageUrl);

  const [rowCountState, setRowCountState] = useState(
    paginationData?.count || 0,
  );

  const formattedData = data?.map((usage: Usage, i: number) => {
    return { id: i, ...usage };
  });

  const { isLoading, startLoading, stopLoading } = useLoading();

  const { enqueueSnackbar } = useSnackbar();

  const formatUrl = (page: number, size: number) => {
    const shortenedUrl = url?.replace("/api/v4", "");
    const splitUrl = shortenedUrl?.split("&page=")[0];
    const finalUrl = `${splitUrl}&page=${page + 1}&page-size=${size}`;
    setUrl(finalUrl);
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) => {
      return paginationData?.count === undefined
        ? prevRowCountState
        : paginationData?.count;
    });
  }, [paginationData?.count, setRowCountState]);

  useEffect(() => {
    startLoading();

    const getPage = async () => {
      if (url && !url?.includes("/api/v4")) {
        try {
          const response = await fetchAccountUsage(url);
          const json = await response.json();
          setAccountUsageData(json.usages);
          setPaginationData(json.page);
        } catch (error) {
          enqueueSnackbar(`Failed to load usage data: Error: ${error}`, {
            content: (key, message) => {
              return <Snackbar id={key} message={message} variant="error" />;
            },
          });
        }
      }
      stopLoading();
    };
    getPage();
  }, [url]);

  const { account, accountList } = useFilters({
    currentUser,
  });

  const {
    anchorEl,
    associatedReports,
    closeEditModal,
    deleteReport,
    editReport,
    existingValues,
    handleEditModalToggle,
    handleMenuClose,
    handleScheduleClick,
    handleScheduleModal,
    navigateToReportList,
    openEditModal,
    openScheduleModal,
    scheduleReport,
    updateReport,
  } = useDetailReportHeader({
    account,
    accountList,
    billingReport: true,
    currentAccount,
    currentReport: {
      title: "Cumulative Account Usage",
    },
    currentUser,
    reportName: "CUMULATIVE_ACCOUNT_USAGE",
    reportType: "billing",
    scheduledReports,
    setScheduledReports,
  });

  return (
    <Box height="100%">
      <Box
        display="flex"
        flexDirection={{ sm: "row", xs: "column" }}
        alignItems={{ sm: "center", xs: "start" }}
        justifyContent="space-between"
        mt="24px"
        mb="18px"
      >
        <Typography
          variant="h5"
          color="text.primary"
          sx={{
            fontWeight: 500,
            fontSize: "20px",
            mb: {
              xs: "16px",
            },
          }}
        >
          Usage by account
        </Typography>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => {
              return sendReport("CUMULATIVE_ACCOUNT_USAGE");
            }}
            aria-label="Send Account Usage Report via Email"
            size="large"
          >
            <EmailIcon />
          </IconButton>
          <IconButton
            onClick={handleScheduleClick}
            aria-label="Schedule Account Usage Report via Email"
            size="large"
            sx={{
              mr: "8px",
            }}
          >
            <Badge
              badgeContent={associatedReports.length}
              color="error"
              aria-label={`${associatedReports.length} Scheduled Cumulative Account Usage Report`}
              sx={{
                "& .MuiBadge-badge": {
                  color: "customColors.badgeText",
                },
              }}
            >
              <CalendarTodayIcon />
            </Badge>
          </IconButton>
          {Boolean(anchorEl) && (
            <Menu
              aria-label="Cumulative Account Usage Report Scheduling Menu"
              id="Cumulative Account Usage Report Scheduling Menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={handleScheduleModal}>New Report</MenuItem>
              <MenuItem onClick={handleEditModalToggle}>
                Edit Scheduled Report
              </MenuItem>
            </Menu>
          )}
          <EditAnalyticsReportModal
            accountList={accountList}
            currentAccount={currentAccount}
            deleteReport={deleteReport}
            editReport={editReport}
            existingReports={associatedReports}
            fullScreen={fullScreen}
            handleEditModalToggle={handleEditModalToggle}
            open={openEditModal}
            title="Cumulative Account Usage"
          />
          <ScheduleAnalyticsReportModal
            accountList={accountList}
            fullScreen={fullScreen}
            handleClose={closeEditModal}
            initialValues={existingValues}
            navigateToReportList={navigateToReportList}
            open={openScheduleModal}
            scheduleReport={scheduleReport}
            title="Cumulative Account Usage"
            updateReport={updateReport}
          />
          <BillingFilter
            label="Time period"
            selectHandler={filterSelectHandler}
            menuOptions={filterOptions}
            selectedOption={selectedFilterOption || "Term to date"}
          />
        </Box>
      </Box>
      <DataGridPro
        pagination
        paginationMode="server"
        autoHeight
        columns={columns}
        columnHeaderHeight={gridHeaderHeight}
        disableRowSelectionOnClick
        rows={formattedData || []}
        rowCount={rowCountState}
        loading={isLoadingAccountUsage || isLoading}
        columnBuffer={2}
        paginationModel={paginationModel}
        onPaginationModelChange={(newPaginationModel) => {
          const { pageSize, page } = newPaginationModel;
          setPaginationModel({ pageSize, page });
          formatUrl(page, pageSize);
        }}
        pageSizeOptions={[10, 25, 50, 100]}
        localeText={{
          noRowsLabel: "",
        }}
        sx={{
          "& .MuiDataGrid-columnSeparator": { display: "none" },
          border: "1px solid rgba(0, 0, 0, 0.3)",
          marginBottom: 100,
        }}
      />
    </Box>
  );
}
