/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";

import { useSnackbar } from "notistack";
import {
  UseKeywordGroupProps,
  Contact as AutomationContact,
  CollectionView,
  ContactCollection,
} from "../models/AutomationModels";
import { fetchSubscriptions } from "../api/fetchSubscriptions";
import { fetchAutomationKeyword } from "../api/fetchAutomationKeyword";
import { useCurrentAccount, useLoading } from "hooks";

import DeliverabilityStatus from "components/DeliverabilityStatus";
import {
  AccountPlan,
  AccountPlanType,
} from "features/AccountSettings/components/AccountConfiguration/models/AccountPlanModels";
import {
  Contact,
  SelectedRecord,
} from "features/Sequences/components/Drawers/EnrollContactsDrawer/types";
import { fetchV3Accounts } from "features/Accounts/api/fetchV3";
import { formatSelectedRecords } from "utils/formatSelectedRecords";
import { Snackbar } from "components/Snackbar";
import { Keyword } from "models/Keyword";

const useKeywordGroup = ({
  contactCollection,
  setContactModal,
  keywordGroupId,
}: UseKeywordGroupProps) => {
  const history = useHistory();
  const currentAccount = useCurrentAccount();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<
    // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
    (AutomationContact | Contact)[]
  >([]);
  const [allSelected, setAllSelected] = useState<boolean>(false);
  const [licenses, setLicenses] = useState<AccountPlanType[]>([]);
  const [displayedContacts, setDisplayedContacts] = useState<SelectedRecord[]>(
    [],
  );
  const [pagination, setPagination] = useState<CollectionView>({
    // v3 endpoint with "view" instead of v4's "page" object
    id: "",
    first: "",
    next: "",
    previous: "",
    "@type": "",
    "@context": "",
  });
  const [currentKeyword, setCurrentKeyword] = useState<Keyword>();
  const [page, setPage] = useState(0);
  const [collection, setCollection] = useState<ContactCollection>();

  const { enqueueSnackbar } = useSnackbar();

  // ==== METHODS ==== //
  const handleFetchAutomationKeywordById = async () => {
    try {
      // Fetch keyword by id.
      const response = await fetchAutomationKeyword(
        currentAccount,
        keywordGroupId,
      );
      const json = await response.json();

      setCurrentKeyword(json);
    } catch {
      enqueueSnackbar(
        `There was a problem fetching keyword with id ${keywordGroupId}. Please try again.`,
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    }
  };

  const getAccountLicenses = async () => {
    try {
      startLoading();
      const response = await fetchSubscriptions(currentAccount);
      const body = await response.json();
      const assignedLicenses: AccountPlanType[] = body.map(
        (license: AccountPlan) => {
          return license.type;
        },
      );
      setLicenses(assignedLicenses);
    } catch (error) {
      throw new Error(`Error fetching account licenses: ${error as string}`);
    } finally {
      stopLoading();
    }
  };

  const handleSelectedContacts = (values: string[]) => {
    setSelectedContacts(values);
  };

  useEffect(() => {
    const getPage = async () => {
      await handleFetchAutomationKeywordById();
    };

    getPage().catch((error) => {
      console.error(error);
    });
  }, [keywordGroupId]);

  const selectAllCount = contactCollection.totalItems;
  const selectedCount = selectedContacts.length ?? 0;
  const totalSelected = allSelected ? selectAllCount : selectedCount;

  const handleSendCampaignClick = () => {
    const contactFilterId = contactCollection.id.replace("/contacts", "");
    const state = allSelected
      ? {
          initialActiveStep: 1,
          contactFilterId,
        }
      : { selectedContacts };
    history.push({
      pathname: `/${currentAccount.slug}/campaigns/new`,
      state,
    });
  };

  const campaignDisabled = useMemo(() => {
    const { settings, phoneNumbers } = currentAccount;
    const dailyCampaignRecipients =
      settings?.dailyCampaignRecipients?.value ?? 0;
    const messagingEnabled = phoneNumbers.length > 0;
    const contactsSelected = selectedContacts.length > 0 || allSelected;
    if (!messagingEnabled) return true;
    return !(dailyCampaignRecipients >= totalSelected && contactsSelected);
  }, [currentAccount, selectedContacts, totalSelected]);

  const sequenceLinkingDisabled =
    selectedCount > 0 && !allSelected && selectAllCount !== selectedCount;

  const getCollapsedColumns = () => {
    return [
      {
        title: "Name",
        primary: true,
        getTableCellContent: (contact: AutomationContact) => {
          return contact?.name ?? "Unknown Name";
        },
      },
    ];
  };

  const getColumns = () => {
    return [
      {
        title: "Name",
        primary: true,
        getTableCellContent: (contact: AutomationContact) => {
          return contact?.name ?? "Unknown Name";
        },
      },
      {
        title: "Primary number",
        align: "left",
        getTableCellContent: (contact: AutomationContact) => {
          const primaryPhone = contact?.phones?.members?.[0] ?? {};
          const { deliverabilityStatus, formattedPhoneNumber, phoneNumber } =
            primaryPhone;
          return phoneNumber ? (
            <Box display="flex" alignItems="center">
              <Button
                onClick={() => {
                  return setContactModal({ active: true, contact });
                }}
              >
                <DeliverabilityStatus
                  deliverabilityStatus={deliverabilityStatus}
                />
                <Box ml="10px">
                  {formattedPhoneNumber ?? "No Number Assigned"}
                </Box>
              </Button>
            </Box>
          ) : (
            ""
          );
        },
        nowrap: true,
      },
      {
        title: "Business name",
        align: "left",
        getTableCellContent: (contact: AutomationContact) => {
          return contact?.data?.business;
        },
      },
      {
        title: "Tags",
        align: "left",
        getTableCellContent: (contact: AutomationContact) => {
          return [...new Set(contact?.data?.tags)].map((tag) => {
            return <Chip size="small" key={tag} color="primary" label={tag} />;
          });
        },
      },
    ];
  };

  const fetchNextPage = async () => {
    if (!pagination?.next) {
      return null;
    }

    const response = await fetchV3Accounts(
      currentAccount.slug,
      pagination.next,
    );
    const newCollection = await response.json();
    const newFormattedContacts = formatSelectedRecords(newCollection.members);
    setCollection(newCollection);
    setPagination(newCollection.view);
    return setDisplayedContacts([
      ...displayedContacts,
      ...newFormattedContacts,
    ]);
  };

  const fetchPreviousPage = async () => {
    if (!pagination?.previous) {
      return null;
    }
    const response = await fetchV3Accounts(
      currentAccount.slug,
      pagination.previous,
    );
    const newCollection = await response.json();
    setCollection(newCollection);
    return setPagination(newCollection.view);
  };

  // ==== RETURN ==== //
  return {
    allSelected,
    campaignDisabled,
    collection,
    currentKeyword,
    displayedContacts,
    fetchNextPage,
    fetchPreviousPage,
    getAccountLicenses,
    getCollapsedColumns,
    getColumns,
    handleSelectedContacts,
    handleSendCampaignClick,
    isLoading,
    licenses,
    page,
    selectAllCount,
    selectedContacts,
    selectedCount,
    sequenceLinkingDisabled,
    selectedRecords,
    setAllSelected,
    setCollection,
    setDisplayedContacts,
    setPagination,
    setPage,
    setSelectedContacts,
    setSelectedRecords,
  };
};

export default useKeywordGroup;
