import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { matchPath, Redirect } from "react-router-dom";

import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";
import Root from "features/Contacts/containers/ContactFilterCollection/components/Root";

export class ContactFilterCollection extends Component {
  static propTypes = {
    contactFilterCollection: PropTypes.object.isRequired,
  };

  render() {
    const { contactFilterCollection } = this.props;
    const match = matchPath(document.location.pathname, {
      path: "/:accountSlug/contacts",
      exact: true,
    });
    if (match) {
      return (
        <Redirect
          to={`/${match.params.accountSlug}/contacts/${contactFilterCollection.members[0].slug}`}
        />
      );
    }
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "ContactFilterCollection",
    shape: { members: [] },
    type: "contactFilterCollection",
  }),
  withRecord({
    actions: ["delete"],
    container:
      "features/Contacts/containers/ContactFilterCollection/contactFilter",
    type: "contactFilter",
  }),
  withConnect,
)(ContactFilterCollection);
