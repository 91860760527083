import { ReactNode } from "react";
import { Box, BoxProps, useTheme } from "@mui/material";

function StickyFooter({
  children,
  ...props
}: { children: ReactNode } & BoxProps) {
  const theme = useTheme();

  return (
    <Box
      position="sticky"
      component="footer"
      width="100%"
      bottom={0}
      bgcolor="background.paper"
      boxShadow={theme.shadows[4]}
      zIndex={999}
      data-testid="sticky-footer"
      {...props}
    >
      {children}
    </Box>
  );
}

export { StickyFooter };
