import { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import { compose } from "redux";
import withSizes from "react-sizes";
import Button from "@mui/material/Button";
import { tosEffectiveDate } from "../constants/tosEffectiveDate";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import Loader from "components/Loader";
import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";
import ModalFooter from "components/ModalFooter";
import breakpoints from "utils/styles/breakpoints";
import TermsOfUse from "features/Legal/components/TermsOfUse";
import withRecord from "higherOrderComponents/withRecord";

function TermsOfUseAgreementModal({
  currentUser,
  fullScreen,
  acceptUserRequest,
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleAcceptTermsOfUse = () => {
    setIsLoading(true);
    acceptUserRequest(`${currentUser.id}/accept_tos`, {
      successCallback: () => {
        return setIsLoading(false);
      },
      errorCallback: () => {
        return setIsLoading(false);
      },
    });
  };

  const shouldOpenBasedOnTosAcceptanceDate = () => {
    if (!currentUser.tosAcceptedAt) return true;
    if (
      new Date(currentUser.tosAcceptedAt).getTime() < tosEffectiveDate.getTime()
    )
      return true;
    return false;
  };

  return (
    <Modal
      fullScreen={fullScreen}
      transitionIn={shouldOpenBasedOnTosAcceptanceDate()}
    >
      <Box
        aria-label="Terms of Use Agreement Modal"
        height={fullScreen ? "100vh" : "80vh"}
        width={fullScreen ? "100vw" : "80vw"}
        display="flex"
        flexDirection="column"
      >
        <ModalHeader aria-label="Terms of Use Modal Header">
          Terms of Use
        </ModalHeader>
        <Box maxWidth="100%" maxHeight="calc(100% - 120px)">
          <TermsOfUse showSidebar={false} toggleSidebar={() => {}} noTitle />
        </Box>
        <ModalFooter cancelButton={false}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAcceptTermsOfUse}
          >
            <Loader isLoading={isLoading}>Accept Terms of Use</Loader>
          </Button>
        </ModalFooter>
      </Box>
    </Modal>
  );
}

TermsOfUseAgreementModal.propTypes = {
  currentUser: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  acceptUserRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
  withConnect,
  withRecord({
    actions: ["accept"],
    container: "features/Legal/TermsOfUseAgreementModal/user",
    type: "user",
  }),
)(TermsOfUseAgreementModal);
