import { useState, MouseEvent } from "react";

import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { ACCOUNT_LICENSES } from "../constants/KeywordConstants";
import { Bubble, MessageBody, MessageTipWrapper } from "./styles";
import { KeywordSubscribersDropdownButtonProps } from "./types";
import AttachmentPreview from "features/Campaigns/components/AttachmentPreview";
import MessageBubbleTip from "components/ConversationThread/MessageBubbleTip";
import { useLayout } from "hooks";

import Modal from "components/Modal";
import ModalContent from "components/ModalContent";

function KeywordSubscribersDropdownButton({
  campaignDisabled,
  currentKeyword,
  currentLink,
  licenses,
  sequenceLinkingDisabled,
  openSelectSequenceDrawer,
  handleSendCampaignClick,
  toggleUnlinkDialog,
}: KeywordSubscribersDropdownButtonProps) {
  const { fullScreen } = useLayout();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const hasSequences = licenses.includes(ACCOUNT_LICENSES.sequences);

  const handleArrowClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseModal = () => {
    return setIsOpen(false);
  };

  const handleCloseArrowMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    return setIsOpen(true);
  };

  const handleOpenSelectSequenceDrawer = () => {
    openSelectSequenceDrawer();
    handleCloseArrowMenu();
  };

  const handleUnlinkClick = () => {
    toggleUnlinkDialog({
      id: currentKeyword?.id ?? null,
      keyword: currentKeyword?.keyword ?? null,
    });
    handleCloseArrowMenu();
  };

  const autoResponse = currentKeyword?.responseBody ?? "";
  const attachments = currentKeyword?.attachments;

  return (
    <Box display="flex" flex="0 0 auto" mr={2}>
      {hasSequences ? (
        <ButtonGroup variant={hasSequences ? "contained" : "outlined"}>
          <Button
            aria-label="View Keyword Auto-response"
            onClick={handleOpenModal}
            data-testid="view-autoresponse-button"
          >
            View Auto-Response
          </Button>
          <Button
            data-testid="open-sequence-arrow-menu"
            size="small"
            onClick={handleArrowClick}
          >
            <KeyboardArrowDownIcon />
          </Button>
        </ButtonGroup>
      ) : (
        <Box display="flex" flexDirection="row" gap={1}>
          <Button
            aria-label="View Keyword Auto-response"
            onClick={handleOpenModal}
            size="large"
            variant="outlined"
            data-testid="view-autoresponse-button"
          >
            View Auto-Response
          </Button>
          <Button
            color="primary"
            onClick={handleSendCampaignClick}
            variant="contained"
            disabled={campaignDisabled}
            data-testid="send-campaign-button-from-keyword-group"
          >
            Send Campaign
          </Button>
        </Box>
      )}
      <Menu
        data-testid="view-sequence-arrow-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseArrowMenu}
        slotProps={{
          root: {
            slotProps: {
              backdrop: {
                sx: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              },
            },
          },
          paper: {
            sx: {
              width: "13.75rem",
            },
          },
        }}
      >
        <MenuItem
          onClick={handleSendCampaignClick}
          disabled={campaignDisabled}
          data-testid="send-campaign-from-keywords-menu-item"
        >
          Send campaign
        </MenuItem>
        {currentLink ? (
          <MenuItem onClick={handleUnlinkClick}>Unlink sequence</MenuItem>
        ) : (
          <MenuItem
            onClick={handleOpenSelectSequenceDrawer}
            disabled={sequenceLinkingDisabled}
            data-testid="link-sequence-from-keywords-menu-item"
          >
            Link sequence
          </MenuItem>
        )}
      </Menu>

      {/* KEYWORD AUTORESPONSE PREVIEW MODAL */}
      <Modal
        ariaLabel="Auto Response Preview"
        closeModal={handleCloseModal}
        fullScreen={fullScreen}
        transitionIn={isOpen}
      >
        <Box display="flex" m={1} justifyContent="flex-end">
          <IconButton onClick={handleCloseModal} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        <Grid container justifyContent="center" minWidth={400}>
          <Typography variant="h6">Keyword Auto-Response</Typography>
        </Grid>
        <ModalContent>
          <Grid container rowSpacing={1}>
            <Grid item xs={12}>
              <Box
                my={2}
                display="flex"
                flexDirection="column"
                alignItems="end"
              >
                <Bubble>
                  <MessageBody>
                    {!!attachments?.length && (
                      <AttachmentPreview attachment={attachments[0]} />
                    )}
                    <p>{autoResponse}</p>
                  </MessageBody>
                  <MessageTipWrapper>
                    <MessageBubbleTip direction="out" />
                  </MessageTipWrapper>
                </Bubble>
              </Box>
            </Grid>
          </Grid>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default KeywordSubscribersDropdownButton;
