export default [
  {
    name: "U.S. East Coast Data Center (Waltham) (CLS5, CLS2)",
    apiHost: "https://rest.bullhornstaffing.com",
    authHost: "https://auth.bullhornstaffing.com",
  },
  {
    name: "U.S. East Data Center (CLS40, CLS41, CLS42, CLS43, CLS44)",
    apiHost: "https://rest-east.bullhornstaffing.com",
    authHost: "https://auth-east.bullhornstaffing.com",
  },
  {
    name: "U.S. West Coast Data Center (CLS30, CLS31, CLS32, CLS33, CLS34, CLS35)",
    apiHost: "https://rest-west.bullhornstaffing.com",
    authHost: "https://auth-west.bullhornstaffing.com",
  },
  {
    name: "U.S. West Coast Data Center (CLS50)",
    apiHost: "https://rest-west50.bullhornstaffing.com",
    authHost: "https://auth-west50.bullhornstaffing.com",
  },
  {
    name: "APAC Data Center (Singapore) (CLS60)",
    apiHost: "https://rest-apac.bullhornstaffing.com",
    authHost: "https://auth-apac.bullhornstaffing.com",
  },
  {
    name: "APAC Data Center (Australia) (CLS66)",
    apiHost: "https://rest-aus.bullhornstaffing.com",
    authHost: "https://auth-aus.bullhornstaffing.com",
  },
  {
    name: "UK Data Center (CLS21, CLS22, CLS23)",
    apiHost: "https://rest-emea.bullhornstaffing.com",
    authHost: "https://auth-emea.bullhornstaffing.com",
  },
  {
    name: "UK Sandbox (CLS29)",
    apiHost: "https://rest-emea9.bullhornstaffing.com",
    authHost: "https://auth-emea9.bullhornstaffing.com",
  },
  {
    name: "German Data Center (CLS70)",
    apiHost: "https://rest-ger.bullhornstaffing.com",
    authHost: "https://auth-ger.bullhornstaffing.com",
  },
  {
    name: "French Data Center (CLS71)",
    apiHost: "https://rest-fra.bullhornstaffing.com",
    authHost: "https://auth-fra.bullhornstaffing.com",
  },
  {
    name: "U.S. West Coast Sandbox (CLS91, CLS99)",
    apiHost: "https://rest-west9.bullhornstaffing.com",
    authHost: "https://auth-west9.bullhornstaffing.com",
  },
];
