import styled, { css } from "styled-components";

export const styles = css`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.secondaryHeaderFontSize;
  }};
  font-weight: 700;
  margin: 0;
`;

/**
 * @deprecated use MUI <Typography> instead
 */
const H4 = styled.h4`
  ${styles};
`;

export default H4;
