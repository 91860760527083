/* eslint-disable jsx-a11y/anchor-is-valid */
/* Using component="button" in a MUI Link adds link styles to a button */

import { useMemo } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Link as RouterLink } from "react-router-dom";
import queryString from "query-string";

import Box from "@mui/material/Box";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import {
  nameStyles,
  numbersStyles,
  childrenStyles,
  usersStyles,
  manageStyles,
} from "./rowStyles";
import ViewMoreRow from "./ViewMoreRow";
import Logo from "components/Logo";

const getPhoneText = (phoneNumbers) => {
  if (phoneNumbers.length === 0) {
    return "Non-messaging admin account";
  }
  if (phoneNumbers.length === 1) {
    return phoneNumbers[0];
  }
  return `${phoneNumbers.length} Numbers`;
};

// Props come from react-vtree FixedSizeTree component
function AccountNode({ data, isOpen, style, toggle }) {
  const {
    accountHierarchyId,
    brandInformation,
    children,
    effectiveRole,
    fetchAccountCollectionRequest,
    fetchAccountHierarchyRequest,
    id,
    name,
    nameOnly,
    nestingLevel,
    phoneNumbers,
    setCurrentAccount,
    type,
    users,
  } = data;
  const childNodes = children?.members ?? [];
  const childrenId = children?.id;
  const totalCount = children?.totalItems ?? 0;
  const isLeaf = totalCount === 0;
  const isClosed = childNodes.length !== totalCount && totalCount < 20;
  const toggleHandler = () => {
    if (nestingLevel > 0 || type !== "partner") {
      fetchAccountCollectionRequest(childrenId);
    } else {
      fetchAccountHierarchyRequest(accountHierarchyId);
    }
    toggle();
  };

  const icon = useMemo(() => {
    const showLoader = isOpen && childNodes.length === 0;
    if (showLoader && !isClosed)
      return (
        <Logo animate color="transparent" dotColor="currentColor" width={18} />
      );
    if (isOpen && !isClosed) return <ExpandMoreIcon />;
    return <ChevronRightIcon />;
  }, [isClosed, isOpen, childNodes]);

  if (type === "load_more") {
    return <ViewMoreRow data={data} style={style} />;
  }
  return (
    <ListItem
      key={id}
      style={{ ...style, paddingLeft: `${(nestingLevel + 1) * 26}px` }}
      label={name}
      button
      onClick={() => {
        return toggleHandler();
      }}
    >
      {!isLeaf && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        style={{ paddingRight: 0, paddingLeft: isLeaf ? "26px" : "0px" }}
      >
        <Box display="flex" flexDirection="row" flexWrap="nowrap">
          <Box key="name" style={{ ...nameStyles }}>
            {name}
          </Box>
          {!nameOnly && (
            <>
              <Box key="numbers" style={{ ...numbersStyles }}>
                {getPhoneText(phoneNumbers)}
              </Box>
              <Box key="children" style={{ ...childrenStyles }}>
                {totalCount}
              </Box>
            </>
          )}
          {!nameOnly && !brandInformation && (
            <>
              <Box key="users" style={{ ...usersStyles }}>
                <Link
                  component={RouterLink}
                  color="inherit"
                  to={{
                    pathname: "/users",
                    search: queryString.stringify(
                      { accounts: [id] },
                      { arrayFormat: "bracket" },
                    ),
                  }}
                  disabled={effectiveRole !== "admin"}
                  style={
                    effectiveRole !== "admin" ? { pointerEvents: "none" } : {}
                  }
                >
                  {get(users, ["totalItems"], 0)}
                </Link>
              </Box>
              <Box key="manage" style={{ ...manageStyles }}>
                {effectiveRole && (
                  <Link
                    component="button"
                    color="inherit"
                    onClick={() => {
                      return setCurrentAccount(id);
                    }}
                  >
                    View
                  </Link>
                )}
              </Box>
            </>
          )}
        </Box>
      </ListItemText>
    </ListItem>
  );
}

AccountNode.propTypes = {
  data: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AccountNode;
