import type { DrawerProps } from "@mui/material";
import type { ReactElement, ReactNode, Ref } from "react";

export type Props<T, R> = Omit<DrawerProps, "open" | "onClose" | "children"> & {
  children?:
    | ((state: State<T, R>) => ReactElement | ReactNode)
    | DrawerProps["children"];
  ref: Ref<DrawerRef<T, R>>;
  onClose?: (
    ...params: Parameters<NonNullable<DrawerProps["onClose"]>>
  ) => boolean;
  defaultCloseValue?: R;
};

export interface State<T, R> {
  close: (data: R) => () => void;
  parameters: T | undefined;
}

export interface DrawerRef<T, R> {
  open: (parameters: T) => Promise<R>;
}
