/**
 * Create event bus
 *
 * @link https://refactoring.guru/design-patterns/observer
 *
 * @returns methods to produce and subscribe
 */
export const createBus = <T extends unknown[]>() => {
  const subscribers = new Set<(...params: T) => void>();

  /**
   * Produce to events
   *
   * @param params
   */
  const produce = (...params: T) => {
    subscribers.forEach((subscriber) => {
      try {
        subscriber(...params);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    });
  };

  /**
   * Subscribe to events
   *
   * @param subscriber
   * @returns
   */
  const subscribe = (subscriber: (...params: T) => void) => {
    subscribers.add(subscriber);

    /**
     * Unsubscribe
     */
    return () => {
      subscribers.delete(subscriber);
    };
  };

  return { produce, subscribe };
};
