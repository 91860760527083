const apiServer =
  window.apiServer || process.env.BASE_URL || window.location.origin;
const authEndpoint = `${apiServer}/pusher/auth`;
const accountEvents = [
  "updated",
  "campaign-recipient-updated",
  "campaign-updated",
  "client-response-ended",
  "client-response-started",
  "contact-updated",
  "contact-phone-updated",
  "conversation-updated",
  "inbox-updated",
  "message-updated",
  "contact-collection-updated",
];

const userEvents = [
  "account-analytic-created",
  "import-updated",
  "inbound-message-audio",
  "inbox-updated",
  "user-analytic-created",
];

const conversationEvents = [
  "conversation.open",
  "conversation.close",
  "conversation.block",
  "conversation.unblock",
  "conversation.subscribe",
  "conversation.unsubscribe",
  "conversation.assign",
  "conversation.unassign",
  "conversation.provider_phone_change",
  "message.created",
  "message.scheduled",
  "message.rescheduled",
  "message.queued",
  "message.received",
  "message.queued",
  "message.dispatched",
  "message.delivered",
  "message.failed_transient",
  "message.unknown",
  "message.failed",
  "message.resend",
];

export { authEndpoint, accountEvents, userEvents, conversationEvents };
