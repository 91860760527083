/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
import { IntegrationProvider } from "models/IntegrationProvider";

export const generateMessageLoggingText = (
  integrationState: IntegrationProvider | undefined,
) => {
  const { providerName, settings } = integrationState ?? {};
  if (providerName === "Bullhorn") {
    return "By default, TextUs logs inbound and outbound messages as individual notes to Bullhorn records. You can configure your integration to log messages in batches by enabling the batch note logging setting and selecting your batch frequency. Individual notes can be logged to a custom object if needed.";
  }
  if (providerName === "Hubspot") {
    return "By default, TextUs logs inbound and outbound messages as individual notes to HubSpot records.";
  }
  if (settings?.batching) {
    return `By default, TextUs logs inbound and outbound messages as individual notes to ${providerName} records. You can configure your integration to log messages in batches by enabling the batch note logging setting and selecting your batch frequency.`;
  }
  return `By default, TextUs logs inbound and outbound messages as individual notes to ${providerName} records.`;
};
