import { memo } from "react";
import PropTypes from "prop-types";
import { FixedSizeList } from "react-window";
import AccountRow from "./AccountRow";

const itemSize = 60;
const searchHeight = 52;

function AccountList({ accountList, containerHeight, ...otherProps }) {
  const height = containerHeight
    ? containerHeight - searchHeight
    : Math.min(accountList.length * itemSize, 400);

  return (
    <FixedSizeList
      height={height}
      width={320}
      itemSize={itemSize}
      itemCount={accountList.length}
    >
      {({ index, style }) => {
        return (
          <AccountRow
            account={accountList[index]}
            index={index}
            style={style}
            {...otherProps}
          />
        );
      }}
    </FixedSizeList>
  );
}

AccountList.propTypes = {
  accountList: PropTypes.array.isRequired,
  containerHeight: PropTypes.number,
};

export default memo(AccountList);
