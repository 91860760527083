import { Identifiable } from "../types";
import { Account } from "./Account";
import { identify } from "./identify";

interface UserMembership {
  account: Account.Raw;
  id: string;
  role: string;
  user: string;
}

const root = "/users/";

export namespace User {
  export interface Raw {
    automatedActor: boolean;
    discarded: boolean;
    email: string;
    featureFlags: Record<string, boolean>;
    firstName: string;
    hasPassword: boolean;
    hasSubAccounts: boolean;
    id: string;
    lastName: string;
    memberships: {
      id: string;
      members: UserMembership[];
      totalItems: number;
    };
    name: string;
    savedReplies: string;
    settings: {
      notifications: { email: boolean };
      customSettings: Record<string, string>;
      defaultMessagingAccount: unknown;
    };
    signature: { content: string; id: string } | null;
    topLevelAccounts: Account.Raw[];
    tosAcceptedAt: null;
    uiConfig: Record<string, unknown>;
    adminUserSchema: unknown;
  }

  export type Like = Identifiable<Raw>;

  export type Identifier = "id";

  export type Identity = Raw[Identifier];

  export type Collection = Record<Identity, Raw>;
}

export const sanitizeUserId = (id: User.Identity) => {
  if (id.startsWith(root)) {
    return id.split("/")[2];
  }

  return id;
};

export const identifyUser = (user: User.Like) => {
  const id = identify(user);

  return sanitizeUserId(id);
};
