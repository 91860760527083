/* eslint-disable class-methods-use-this */
import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { enqueueSnackbar } from "notistack";

function withAccountSettingsSubmit(WrappedComponent) {
  class WithAccountSettingsSubmit extends Component {
    static propTypes = {
      account: PropTypes.object.isRequired,
      updateAccountRequest: PropTypes.func.isRequired,
    };

    errorCallback = (actions) => {
      return (errors) => {
        const validationErrors = get(errors, ["validationErrors", "settings"], {
          message: ["There was an error saving your account settings"],
        });
        const errorsKey = Object.keys(validationErrors)[0];
        const errorMessage = validationErrors[errorsKey][0];
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        enqueueSnackbar(errorMessage, {
          variant: "error",
        });
      };
    };

    successCallback = (
      actions,
      message = "Account settings saved successfully!",
    ) => {
      return () => {
        actions.setSubmitting(false);
        enqueueSnackbar(message, { variant: "info" });
      };
    };

    handleSubmit = (values, actions, successMessage) => {
      const { account, updateAccountRequest } = this.props;
      updateAccountRequest(account.id, values, {
        successCallback: this.successCallback(actions, successMessage),
        errorCallback: this.errorCallback(actions),
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} handleSubmit={this.handleSubmit} />
      );
    }
  }

  return WithAccountSettingsSubmit;
}

export default withAccountSettingsSubmit;
