import { createTextUsService } from "@tesseract/core";

export const textUsApiV3 = createTextUsService({
  options: {
    headers: {
      Accept: "application/vnd.textus+jsonld",
      "Content-Type": "application/json",
    },
  },
});

export const textUsApiV4 = createTextUsService({
  url: "/api/v4",
  options: {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  },
});
