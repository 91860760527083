/* eslint-disable @typescript-eslint/require-await */
import { textUsApiV4 } from "services";

export const getMergeLinkToken = async (
  accountSlug: string,
  integrationName: string,
  category: string,
): Promise<Response> => {
  const body = {
    provider: "merge",
    config: { integration: integrationName, category },
  };
  return textUsApiV4.post(`/${accountSlug}/integrations/merge`, body);
};
