import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import MainView from "./components/MainView";
import { actionGenerators, saga, reducer, selectors } from "./state";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";

class JobAdderCallback extends Component {
  render() {
    return <MainView {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    jobAdderCallbackContainer: selectors.selectJobAdderCallback(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "jobAdderCallbackContainer",
  reducer,
});
const withSaga = injectSaga({ key: "jobAdderCallbackContainer", saga });

export default compose(withReducer, withSaga, withConnect)(JobAdderCallback);
