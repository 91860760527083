import { ListItem, Typography, Link, Chip } from "@mui/material";
import List from "@mui/material/List";
import { ComponentProps } from "react";
import type { BullhornCandidate } from "@tesseract/core";
import { Props } from "./types";

const listItemProps: ComponentProps<typeof ListItem> = {
  sx: {
    display: "block",
  },
  disablePadding: true,
};

const formatName = ({
  firstName,
  lastName,
}: BullhornCandidate.OwnerObject): string => {
  return `${firstName}${firstName && lastName ? " " : ""}${lastName}`;
};

export const getOwnerName = (
  owner: BullhornCandidate.OwnerValue | BullhornCandidate.OwnerObject,
): string => {
  if (["string", "number"].includes(typeof owner)) {
    return `${owner}`;
  }

  const { firstName, lastName } = owner as BullhornCandidate.OwnerObject;

  return firstName || lastName
    ? formatName(owner as BullhornCandidate.OwnerObject)
    : `ID: ${(owner as BullhornCandidate.OwnerObject).id}`;
};

export function BullhornCandidateDetails({ candidate }: Props) {
  return (
    <List
      disablePadding
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 2,
      }}
    >
      {candidate?.owner && (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Owner</Typography>
          <Typography variant="body1">
            {getOwnerName(candidate.owner)}
          </Typography>
        </ListItem>
      )}
      {candidate?.occupation && (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Title</Typography>
          <Typography variant="body1">{candidate.occupation}</Typography>
        </ListItem>
      )}
      {candidate?.namePrefix && (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Prefix</Typography>
          <Typography variant="body1">{candidate.namePrefix}</Typography>
        </ListItem>
      )}
      {candidate?.status && (
        <ListItem {...listItemProps}>
          <Typography variant="subtitle2">Status</Typography>
          <Chip
            sx={(theme) => {
              return {
                backgroundColor: theme.palette.action.selected,
              };
            }}
            size="small"
            variant="filled"
            label={candidate.status}
          />
        </ListItem>
      )}
      {candidate?.email && (
        <ListItem {...listItemProps}>
          <Link
            href={`mailto:${candidate.email}`}
            target="_blank"
            rel="noreferrer"
          >
            {candidate.email}
          </Link>
        </ListItem>
      )}
    </List>
  );
}
