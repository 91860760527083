import { snakeCase, toUpper } from "lodash";
import Pusher from "pusher-js";
import type { Store } from "redux";
import { disconnectPusher } from "../createPusherInstance";
import {
  formatConversationChannel,
  formatGlobalChannel,
  formatPrivateChannel,
} from "./formatChannels";
import {
  accountEvents,
  userEvents,
  conversationEvents,
} from "utils/pusherConfig";

const subscribeToGlobalChannel = ({ pusher, store }: { pusher: Pusher, store: Store }) => {
  if (!pusher) return;
  const event = "app-updated";

  // THIS WILL NORMALIZE THE STATE IN MAPS TO A PLAIN JSON OBJECT

  const channel = pusher.subscribe(formatGlobalChannel());
  channel.bind("pusher:subscription_error", () => {
    // This is the first AUth channel to subscribe, so if this fails we need to disconnect
    // Pusher doesnt return specific error message if is a auth error for permission
    // or if the channel is not found
    disconnectPusher();
  });
  channel.bind("pusher:subscription_succeeded", () => {
    store.dispatch({
      type: "app/PusherComponent/PUSHER_CHANNEL_SUBSCRIBED",
      payload: true,
    });
  });
  channel.bind(event, (data: any) => {
    store.dispatch({
      type: `app/PusherComponent/${toUpper(snakeCase(event))}`,
      channel: formatGlobalChannel(),
      event,
      data,
    });
  });
};

const subscribeToUserChannel = ({
  currentUserId,
  pusher,
  store
}: {
  currentUserId: string;
  pusher: Pusher;
  store: Store
}) => {
  if (!pusher || !pusher.connection.socket_id) return;
  const channelName = `private-users-${currentUserId}@updates`;
  const channel = pusher.subscribe(channelName);

  userEvents.forEach((event) => {
    channel.bind(event, (data: string) => {
      store.dispatch({
        type: `app/PusherComponent/${toUpper(snakeCase(event))}`,
        channel: channelName,
        event,
        data,
      });
    });
  });
};

const subscribeToChannel = ({
  channelName,
  pusher,
  store,
}: {
  channelName: string;
  pusher: Pusher;
  store: Store;
}) => {
  if (!pusher) return;
  const channel = pusher.subscribe(channelName);
  accountEvents.forEach((event: string) => {
    channel.bind(event, (data: any) => {
      store.dispatch({
        type: `app/PusherComponent/${toUpper(snakeCase(event))}`,
        channel: channelName,
        event,
        data,
      });
    });
  });
};

const subscribeToConversationChannel = ({
  channelName,
  pusher,
  store,
}: {
  channelName: string;
  pusher: Pusher;
  store: Store;
}) => {
  if (!pusher) return;
  const channel = pusher.subscribe(channelName);
  conversationEvents.forEach((event) => {
    channel.bind(event, (data: any) => {
      store.dispatch({
        type: `app/PusherComponent/${toUpper(snakeCase(event))}`,
        channel: channelName,
        event,
        data,
      });
    });
  });
};

const unsubscribeFromGlobalChannel = ({ pusher, store}: { pusher: Pusher, store: Store }) => {
  if (!pusher) return;
  pusher.unsubscribe(formatGlobalChannel());
};

const unsubscribeFromUserChannel = ({
  currentUserId,
  pusher,
  store
}: {
  currentUserId: string;
  pusher: Pusher;
  store: Store;
}) => {
  if (!pusher) return;
  const channelName = `private-users-${currentUserId}@updates`;
  pusher.unsubscribe(channelName);
};

const unsubscribeFromChannel = ({
  channelName,
  pusher,
}: {
  channelName: string;
  pusher: Pusher;
}) => {
  if (!pusher) return;
  pusher.unsubscribe(channelName);
};

const unsubscribeFromConversationChannel = ({
  channelName,
  pusher,
}: {
  channelName: string;
  pusher: Pusher;
}) => {
  if (!pusher) return;
  pusher.unsubscribe(channelName);
};

export const channelSubscription = ({ pusher, store }: { pusher: Pusher, store: Store }) => {
  const state = store.getState();

  // THIS WILL NORMALIZE THE STATE IN MAPS TO A PLAIN JSON OBJECT
  const sanitizedState = JSON.parse(JSON.stringify(state));
  const allAccounts = sanitizedState.global.records["/navbar"].members.map(
    (account: string) => {
      return account.replace("/navbar/", "");
    },
  );
  const currentUserId = sanitizedState.global.currentUser.replace(
    "/users/",
    "",
  );
  if (!pusher || !pusher.connection.socket_id) return;

  // UNSUBSCRIBE TO ALL CHANNELS
  unsubscribeFromGlobalChannel({ pusher, store });
  if (currentUserId) {
    unsubscribeFromUserChannel({ currentUserId, pusher, store });
  }
  allAccounts.forEach((account: string) => {
    const channel = formatPrivateChannel(account);
    const conversationChannel = formatConversationChannel(account);
    unsubscribeFromChannel({ channelName: channel, pusher });
    unsubscribeFromConversationChannel({
      channelName: conversationChannel,
      pusher,
    });
  });

  // SUBSCRIBE TO ALL CHANNELS
  subscribeToGlobalChannel({ pusher, store });
  if (currentUserId) {
    subscribeToUserChannel({ currentUserId, pusher, store });
  }
  allAccounts.forEach((account: string) => {
    const channel = formatPrivateChannel(account);
    const conversationChannel = formatConversationChannel(account);
    subscribeToChannel({ channelName: channel, pusher, store });
    subscribeToConversationChannel({
      channelName: conversationChannel,
      pusher,
      store
    });
  });
};
