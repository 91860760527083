import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const linkKeywordToSequence = async (
  currentAccount: Account.Raw,
  sequenceId: string,
  keywordGroupId: string,
): Promise<Response> => {
  return textUsApiV4.patch(
    `/${currentAccount.slug}/automation_keywords/${keywordGroupId}/link`,
    {
      sequenceId,
    },
  );
};
