import { useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";

import { Box, Chip, Tabs, Tab, useMediaQuery } from "@mui/material";
import TabPanel from "./components/TabPanel";
import {
  CONTACT_VARIABLES,
  USER_VARIABLES,
} from "./constants/personalizationVariables";
import { DialogHeader } from "components/DialogHeader";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

const applyAccessibilityProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

function TemplateVariablePicker({
  closeHandler,
  currentUser,
  handleTemplateVariableSelected,
  hideUserPersonalization,
  userSchema = {},
}) {
  const mobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const [currentTab, setCurrentTab] = useState(0);
  const userSchemaFields = get(userSchema, ["fields", "members"], []);
  const userPersonalizationValues = get(
    currentUser,
    ["settings", "customSettings"],
    {},
  );
  const additionalUserVariables = userSchemaFields
    .filter(({ id }) => {
      return userPersonalizationValues[id];
    })
    .map(({ id, title }) => {
      return [`{{${id.slice(1).replaceAll("/", ".")}}}`, `${title}`];
    });

  const updatedUserVariables = [...USER_VARIABLES, ...additionalUserVariables];

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const renderTabContents = (templateVariables) => {
    return (
      <Box
        display="grid"
        gridAutoFlow="column"
        gridTemplateRows="repeat(6, auto)"
        p="20px"
      >
        {templateVariables.map(([templateVariable, templateDisplayName]) => {
          return (
            <Chip
              color="primary"
              label={templateDisplayName}
              key={templateVariable}
              onClick={() => {
                handleTemplateVariableSelected(templateVariable);
              }}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <Box>
      <DialogHeader
        title={`${hideUserPersonalization ? "Contact" : ""} ${hideUserPersonalization ? "p" : "P"}ersonalized variables`}
        fullScreen={mobile}
        isPopover
        onClose={closeHandler}
      />
      {!hideUserPersonalization && (
        <Tabs
          value={currentTab}
          onChange={handleChange}
          sx={(theme) => {
            return {
              width: "100%",
              borderBottom: `1px solid ${theme.palette.divider}`,
            };
          }}
        >
          <Tab
            label="Contact"
            {...applyAccessibilityProps(0)}
            sx={{ width: "50%" }}
          />
          <Tab
            label="You"
            {...applyAccessibilityProps(1)}
            sx={{ width: "50%" }}
          />
        </Tabs>
      )}
      <Box display="flex" flex="0 0 auto">
        <TabPanel value={currentTab} index={0}>
          {renderTabContents(CONTACT_VARIABLES)}
        </TabPanel>
        {!hideUserPersonalization && (
          <TabPanel value={currentTab} index={1}>
            {renderTabContents(updatedUserVariables)}
          </TabPanel>
        )}
        <TabPanel value={currentTab} index={2} />
      </Box>
    </Box>
  );
}

TemplateVariablePicker.propTypes = {
  closeHandler: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  handleTemplateVariableSelected: PropTypes.func.isRequired,
  hideUserPersonalization: PropTypes.bool,
  userSchema: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withConnect,
  withRecord({
    actions: ["fetch"],
    container:
      "features/AccountSettings/components/CustomUserSchema/userSchema",
    shape: { fields: { members: [] } },
    type: "userSchema",
    showLoader: () => {
      return false;
    },
  }),
)(TemplateVariablePicker);
