import { all, takeLatest, put, select } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";

import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import { makeRequest } from "utils/generateContainerState/generateSagas";

// ACTION TYPES
const SEND_TEST_MESSAGE_REQUEST =
  "app/GettingStarted/SEND_TEST_MESSAGE_REQUEST";
const SEND_TEST_MESSAGE_SUCCESS =
  "app/GettingStarted/SEND_TEST_MESSAGE_SUCCESS";
const SEND_TEST_MESSAGE_FAILURE =
  "app/GettingStarted/SEND_TEST_MESSAGE_FAILURE";

const actionTypes = {
  SEND_TEST_MESSAGE_REQUEST,
  SEND_TEST_MESSAGE_SUCCESS,
  SEND_TEST_MESSAGE_FAILURE,
};

// ACTION GENERATORS
const sendTestMessage = (payload) => {
  return {
    type: SEND_TEST_MESSAGE_REQUEST,
    payload,
  };
};

const actionGenerators = { sendTestMessage };

function* testMessage({ payload }) {
  try {
    const currentAccount = yield select(selectCurrentAccount);
    const params = {
      name: payload.contactName,
      phones: [
        {
          number: payload.phoneNumber,
          type: "Mobile",
        },
      ],
      data: {
        businessName: currentAccount.name,
        tags: [],
      },
    };
    const contact = yield makeRequest({
      url: currentAccount.contacts,
      params,
      method: "POST",
    });

    const to = contact.phones.members.find(({ phoneNumber }) => {
      return phoneNumber.endsWith(payload.phoneNumber.replace(/\D/, ""));
    }).phoneNumber;

    const messageParams = {
      body: "Welcome to TextUs! Reply to this text and then check your Inbox! 📥",
      to,
    };

    yield makeRequest({
      url: currentAccount.sendMessages,
      params: messageParams,
      method: "POST",
    });

    yield put({ type: SEND_TEST_MESSAGE_SUCCESS });
  } catch (error) {
    yield put({ type: SEND_TEST_MESSAGE_FAILURE, error });
  }
}

function* saga() {
  yield all([takeLatest(SEND_TEST_MESSAGE_REQUEST, testMessage)]);
}

// REDUCERS
const isSending = (state = false, action) => {
  switch (action.type) {
    case SEND_TEST_MESSAGE_REQUEST:
      return true;
    case SEND_TEST_MESSAGE_SUCCESS:
    case SEND_TEST_MESSAGE_FAILURE:
      return false;
    default:
      return state;
  }
};

const errorSending = (state = null, action) => {
  switch (action.type) {
    case SEND_TEST_MESSAGE_REQUEST:
    case SEND_TEST_MESSAGE_SUCCESS:
      return null;
    case SEND_TEST_MESSAGE_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const reducer = combineReducers({ isSending, errorSending });

// SELECTORS
const selectGettingStarted = (state) => {
  return state.get("gettingStartedContainer").toJS();
};
const selectors = { selectGettingStarted };

export { actionTypes, actionGenerators, reducer, saga, selectors };
