import { ChangeEvent, useState } from "react";
import { Link, Route, useHistory } from "react-router-dom";

import {
  Box,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { capitalize } from "lodash";
import { CampaignRecipientGridProps } from "./types";
import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";

import CampaignRecipientCollection from "features/Campaigns/containers/CampaignRecipientCollection";
import { InfoOutlinedIcon } from "icons";

const filterOptions = [
  "total",
  "sent",
  "delivered",
  "replied",
  "unreplied",
  "failed",
  "skipped",
  "omitted",
] as const;

function CampaignRecipientGrid({
  campaign,
  currentAccount,
  location,
  match,
  setCompose,
}: CampaignRecipientGridProps) {
  const history = useHistory();

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const [selectedFilter, setSelectedFilter] = useState("total");

  const { deliveryStatistics, state } = campaign;
  const { skipped, unreplied } = deliveryStatistics ?? {};
  const tabName = location.pathname.split("/").pop();

  const filters = filterOptions.filter((option) => {
    if (state === "scheduled") return option === "total";
    if (skipped === 0) return option !== "skipped";
    if (!unreplied) return option !== "unreplied";
    return true;
  });

  const getLabel = (
    filter:
      | "total"
      | "skipped"
      | "sent"
      | "delivered"
      | "replied"
      | "unreplied"
      | "failed"
      | "omitted",
  ) => {
    if (!campaign.deliveryStatistics) return null;
    if (filter === "skipped") {
      return (
        <Box display="flex" alignItems="center">
          {filter} ({campaign.deliveryStatistics[filter]})
          <Box ml={1}>
            <Tooltip
              placement="right"
              title={
                <Typography
                  p={1}
                  style={{
                    padding: "5px 0px",
                    fontWeight: "normal",
                    fontSize: "11px",
                    textAlign: "center",
                    maxWidth: "216px",
                  }}
                >
                  Contacts who are filtered out due to having already received a
                  campaign message within the timeframe your organization has
                  set up.
                </Typography>
              }
            >
              <InfoOutlinedIcon color="inherit" />
            </Tooltip>
          </Box>
        </Box>
      );
    }
    return `${filter} (${campaign.deliveryStatistics[filter]})`;
  };

  const renderFilterOptions = () => {
    if (!deliveryStatistics) return null;
    return filters.map((filter) => {
      return mobileScreen ? (
        <MenuItem key={filter} value={filter}>
          {filter === "total"
            ? `All contacts (${deliveryStatistics?.total})`
            : `${capitalize(filter)} (${deliveryStatistics[filter]})`}
        </MenuItem>
      ) : (
        <Tab
          value={filter}
          key={filter}
          label={getLabel(filter)}
          component={Link}
          to={`${match.url}/${filter}`.replace("//", "/")}
        />
      );
    });
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setSelectedFilter(event.target.value);
    history.push(`${match.url}/${event.target.value}`);
  };

  return (
    <Box
      data-testid="campaign-recipient-grid"
      display="flex"
      flexDirection="column"
      flex="1 1 auto"
      padding="1rem"
      sx={{
        minHeight: {
          xs: "auto",
          sm: 0,
        },
      }}
    >
      <Box
        display="flex"
        flex="1 1 auto"
        height="100%"
        margin="0 auto"
        minHeight={0}
        flexDirection={mobileScreen ? "column" : "row"}
        minWidth={0}
        width="100%"
        gap="1rem"
      >
        {mobileScreen ? (
          <TextField
            select
            onChange={handleChange}
            value={selectedFilter}
            label="View"
          >
            {renderFilterOptions()}
          </TextField>
        ) : (
          <Tabs
            value={tabName}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            orientation="vertical"
          >
            {renderFilterOptions()}
          </Tabs>
        )}
        {filters.map((filter) => {
          return (
            <Route key={filter} path={`${match.path}/${filter}`}>
              <CampaignRecipientCollection
                campaignRecipientCollectionId={getPaginatedId(
                  getNestedId(campaign, `${filter}Recipients`),
                )}
                currentAccount={currentAccount}
                location={location}
                match={match}
                setCompose={setCompose}
              />
            </Route>
          );
        })}
      </Box>
    </Box>
  );
}

export { CampaignRecipientGrid };
