/* eslint-disable @typescript-eslint/no-floating-promises */
import { Button, ButtonGroup, Menu, MenuItem } from "@mui/material";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { updateIntegration } from "../../api/updateIntegration";
import { RemoveIntegrationModal } from "../RemoveIntegrationModal/RemoveIntegrationModal";
import { IntegrationDetailsActionsProps } from "./types";
import { ArrowDropDownIcon } from "icons";

export function IntegrationDetailsActions({
  setSelectedIntegration,
  setUpdatedIntegrationData,
  updatedIntegrationData,
}: IntegrationDetailsActionsProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRemoveIntegrationDialog, setOpenRemoveIntegrationDialog] =
    useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const open = Boolean(anchorEl);
  // Luke TODO: Create handler for reauthentication for merge integrations
  //   const handleReauthentication = () => {
  //   };

  // const { open, isReady, error } = useMergeLink({
  //     linkToken: "8xMvMS-exnut2wsdc998RXueSIETSYktHfJbnXI2341HeP0mmLH48A", // Replace ADD_GENERATED_LINK_TOKEN with the token retrieved from your backend (Step 1)
  //     onSuccess,
  //   });

  const handleToggleActive = async () => {
    try {
      const response = await updateIntegration(
        updatedIntegrationData?.id ?? "",
        {
          active: !updatedIntegrationData?.active,
        },
      );
      if (!response.ok) {
        throw new Error("Integration update failed.");
      }
      enqueueSnackbar(
        updatedIntegrationData?.active
          ? "Integration activity paused."
          : "Integration activity resumed.",
        {
          variant: "info",
        },
      );
      setUpdatedIntegrationData({
        ...updatedIntegrationData,
        active: !updatedIntegrationData?.active,
      });
    } catch {
      enqueueSnackbar("Integration update failed.", {
        variant: "error",
      });
    }
  };

  return (
    <>
      <ButtonGroup>
        <Button
          onClick={() => {
            handleToggleActive();
          }}
          sx={{
            paddingLeft: "12px",
          }}
        >
          {updatedIntegrationData?.active ? (
            <PauseIcon
              sx={{
                marginRight: "4px",
              }}
            />
          ) : (
            <PlayArrowIcon
              sx={{
                marginRight: "4px",
              }}
            />
          )}
          {updatedIntegrationData?.active ? "Pause" : "Resume"}
        </Button>
        <Button
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <ArrowDropDownIcon data-testid="send-options-button" />
        </Button>
      </ButtonGroup>
      <Menu
        data-testid="view-integration-actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {updatedIntegrationData?.strategy === "merge" ? (
          <MenuItem
            data-testid="reauthenticate-integration"
            onClick={() => {
              // Luke TODO: invoke handleReauthentication() here once Merge integrations are implemented
            }}
          >
            Re-authenticate
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => {
            setOpenRemoveIntegrationDialog(true);
          }}
          data-testid="duplicate-sequence"
          sx={{
            color: "error.main",
          }}
        >
          Remove integration
        </MenuItem>
      </Menu>
      <RemoveIntegrationModal
        open={openRemoveIntegrationDialog}
        setOpenRemoveIntegrationDialog={setOpenRemoveIntegrationDialog}
        setSelectedIntegration={setSelectedIntegration}
        updatedIntegrationData={updatedIntegrationData}
      />
    </>
  );
}
