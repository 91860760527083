import { Alert, useTheme } from "@mui/material";
import type { SettingsCardAlertProps } from "./types";

export function SettingsCardAlert({ sx, ...props }: SettingsCardAlertProps) {
  const theme = useTheme();
  return (
    <Alert
      sx={{
        marginX: "16px",
        marginBottom: 2,
        paddingX: 2,
        paddingY: "6px",
        ...(typeof sx === "function" ? sx(theme) : sx),
      }}
      {...props}
    />
  );
}
