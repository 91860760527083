/**
 * Remove undefined elements from object
 * @param obj
 * @returns
 */
export const sanitize = <T extends object>(
  obj: Record<keyof T, Partial<T[keyof T]> | undefined>,
): Record<keyof T, Exclude<T[keyof T], undefined>> => {
  return JSON.parse(JSON.stringify(obj));
};
