import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "@mui/material/Button";
import Badge from "@mui/material/Badge";
import SendIcon from "@mui/icons-material/Send";
import * as factories from "factories";

import { Root as MessageBubbleTipStyles } from "components/ConversationThread/MessageBubbleTip";
import Avatar from "components/Avatar";
import ConversationFilterOptionStyle from "features/Inbox/styles/ConversationFilterOptionStyle";
import { getFontAwesomeIcon } from "features/MainNavigation/utils";
import H3 from "components/H3";
import List from "components/List";
import Logo from "components/Logo";
import MessageBody from "components/ConversationThread/MessageBody";
import MessageBubble from "components/ConversationThread/MessageBubble";
import PageHeader from "components/Page/PageHeader";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";
import SiteNavigationLink from "features/MainNavigation/SiteNavigation/SiteNavigationLink";
import UnreadIcon from "features/Inbox/containers/ConversationCollectionItem/components/UnreadIcon";

const Root = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  flex-flow: column nowrap;
  background: ${(props) => {
    return props.theme.colors.background.default;
  }};
  border-left: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  flex: 1 1 100%;
  height: 100%;
`;

const Window = styled.div`
  width: 88%;
  height: 88%;
  max-width: 999px;
  max-height: 900px;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  flex-flow: column nowrap;
`;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
`;

const WindowSidebar = styled(PageSidebar)`
  position: relative;
  background: ${(props) => {
    return props.theme.colors.background.default;
  }};
  flex: 0 0 200px;
  height: 100%;
`;

const WindowContent = styled.div`
  height: 100%;
  flex: 1 1 100%;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
`;

const WindowThread = styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  flex: 1 1 100%;
  display: flex;
  flex-flow: column nowrap;
`;

const Header = styled.div`
  width: 100%;
  height: ${(props) => {
    return props.theme.layout.navHeight;
  }};
  background: ${(props) => {
    return props.palette.primaryColor;
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;

  div:first-child {
    display: flex;
    height: 100%;
  }
`;

const StyledMessageBubble = styled(MessageBubble)`
  margin-bottom: 22px;

  ${MessageBody} {
    color: ${(props) => {
      return props.palette.primaryColor;
    }};
  }

  &:nth-child(odd) {
    ${MessageBody} {
      background: ${(props) => {
        return props.palette.outboundMessageColor;
      }} !important;
    }

    ${MessageBubbleTipStyles} {
      g {
        fill: ${(props) => {
          return props.palette.outboundMessageColor;
        }} !important;
      }
    }
  }
`;

const Thread = styled.div`
  padding: 40px;
  flex: 1 1 100%;
`;

const StyledButton = styled(Button)`
  background: ${(props) => {
    return props.color === "primary" && props.palette.primaryColor;
  }} !important;
  color: ${(props) => {
    return props.color === "secondary" && props.palette.secondaryColor;
  }} !important;
  border: ${(props) => {
    return (
      props.variant === "outlined" &&
      `solid 1px ${props.palette.secondaryColor}`
    );
  }} !important;
`;

const Buttons = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  background: ${(props) => {
    return props.theme.colors.background.default;
  }};
  flex: 1 1 100%;
  padding: 0 20px;

  & > button {
    margin-right: 20px;
  }
`;

const StyledAvatar = styled(Avatar)`
  & > div > div {
    align-items: center;
  }
`;

const StyledBadge = styled(Badge)`
  span {
    background: ${(props) => {
      return props.palette.secondaryColor;
    }} !important;
  }
`;

const StyledPageSidebarHeaderButton = styled(PageSidebarHeaderButton)`
  background-color: ${(props) => {
    return props.palette.primaryColor;
  }} !important;
`;

const StyledUnreadIcon = styled(UnreadIcon)`
  margin-right: -3px;
  background: ${(props) => {
    return props.palette.tertiaryColor;
  }} !important;
`;

const StyledList = styled(List)`
  flex: 0 0 200px;
  background: ${(props) => {
    return props.theme.colors.background.default;
  }};
`;

const ListItem = styled.a`
  ${ConversationFilterOptionStyle};
  justify-content: space-between;
`;

const StyledPageHeader = styled(PageHeader)`
  ${H3} {
    color: ${(props) => {
      return props.palette.primaryColor;
    }} !important;
  }
`;

const StyledSiteNavigationLink = styled(SiteNavigationLink)`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  line-height: ${(props) => {
    return props.theme.layout.navHeight;
  }} !important;
  margin-left: 20px;
`;

const PreviewLogo = styled.img`
  height: 40px;
  margin-top: 5px;
`;

export default class Preview extends Component {
  static propTypes = {
    palette: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    logo: PropTypes.string,
  };

  render() {
    const { palette, currentUser, logo } = this.props;
    return (
      <Root>
        <Window>
          <Header palette={palette}>
            <div>
              {logo ? (
                <PreviewLogo src={logo} />
              ) : (
                <Logo showName width={120} color={palette.tertiaryColor} />
              )}
              <StyledSiteNavigationLink
                title="Link"
                to="#"
                icon={getFontAwesomeIcon("inbox")}
                viewBox="0 0 28 12"
              />
            </div>

            <Badge badgeContent="4" color="error">
              <StyledAvatar subject={currentUser} />
            </Badge>
          </Header>
          <Container>
            <WindowSidebar>
              <PageSidebarHeader>
                <StyledPageSidebarHeaderButton
                  clickHandler={() => {
                    return {};
                  }}
                  icon={<SendIcon />}
                  text="Header Button"
                  palette={palette}
                  primary
                />
              </PageSidebarHeader>
              <StyledList>
                <ListItem>
                  <span>Active Conversations</span>
                  <StyledUnreadIcon palette={palette} />
                </ListItem>
                <ListItem>
                  <span>New Conversations</span>
                  <StyledBadge
                    badgeContent="3"
                    palette={palette}
                    color="secondary"
                  >
                    <span />
                  </StyledBadge>
                </ListItem>
                <ListItem>Starred Conversations</ListItem>
                <ListItem>Scheduled Messages</ListItem>
              </StyledList>
            </WindowSidebar>
            <WindowContent>
              <StyledPageHeader title="Theme Preview" palette={palette} />

              <WindowThread>
                <Thread>
                  <StyledMessageBubble
                    palette={palette}
                    message={factories.message({
                      id: "/messages/2",
                      body: "Hey there Layla! Are you available tomorrow?",
                      attachments: factories.attachmentsCollection(),
                    })}
                  />

                  <StyledMessageBubble
                    palette={palette}
                    message={factories.message({
                      id: "/messages/3",
                      body: "Yeah! Anytime!",
                      attachments: factories.attachmentsCollection(),
                    })}
                  />

                  <StyledMessageBubble
                    palette={palette}
                    message={factories.message({
                      id: "/messages/4",
                      body: "Awesome! I went ahead and scheduled a call between you and Mrs. Whitmoore @ 3pm. Her number is 720-555-7432. Good luck!",
                      attachments: factories.attachmentsCollection(),
                    })}
                  />
                </Thread>
                <Buttons>
                  <StyledButton
                    palette={palette}
                    variant="contained"
                    color="primary"
                  >
                    Primary Button
                  </StyledButton>
                  <StyledButton
                    palette={palette}
                    variant="outlined"
                    color="secondary"
                  >
                    Secondary Button
                  </StyledButton>
                </Buttons>
              </WindowThread>
            </WindowContent>
          </Container>
        </Window>
      </Root>
    );
  }
}
