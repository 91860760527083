import type { Account, Contact } from "@tesseract/core";
import { identifyAccount, identifyContact } from "@tesseract/core";
import { textUsApiV4 } from "services";

export type Query = {
  providerId: string;
};

export function fetchCandidates(
  account: Account.Like,
  contact: Contact.Like,
  integration: string,
  options?: RequestInit,
) {
  const accountId = identifyAccount(account);
  const contactId = identifyContact(contact);

  return textUsApiV4.get(
    `/${accountId}/contacts/${contactId}/integrations/${integration}`,
    undefined,
    options,
  );
}
