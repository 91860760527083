import { Component } from "react";
import withRecord from "higherOrderComponents/withRecord";
import Root from "features/Campaigns/containers/NewCampaignContactFilter/components/Root";

class NewCampaignContactFilter extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "Campaigns/containers/NewCampaignContactFilter",
  shape: {},
  type: "contactFilter",
})(NewCampaignContactFilter);
