import assert from "assert";
import { userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";

/* The following network requests works exculsively for out ContactBatchActions component
 From the contact batch action toolbar, the follwing actions are made with DELETE requests:
 Opting-out, deleting/removing contacts, unblocking */
export const contactBatchPutRequest = async (
  url: string,
  contacts: string[],
): Promise<Response | null> => {
  const user = await userManager.getUser();
  if (!user?.access_token) {
    return null;
  }

  const request = new Request(url, {
    method: "PUT",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      contacts,
    }),
  });

  return fetch(request);
};
