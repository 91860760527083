/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Switch, Redirect, Route } from "react-router-dom";

import { compose } from "redux";
import KeywordAutomation from "./screens/KeywordAutomation";
import KeywordGroup from "./screens/KeywordGroup";
import KeywordGroups from "./screens/KeywordGroups";
import {
  AutomationProps,
  AutomationNavValues,
} from "./models/AutomationModels";
import { useAutomationNavLinks } from "./hooks/useAutomationNavLinks";
import useKeywordGroups from "./hooks/useKeywordGroups";
import AutomationSidebar from "./components/AutomationSidebar";
import { useKeywordLinking } from "features/Sequences/hooks/useKeywordLinking";
import PageWrapper from "components/Page/PageWrapper";
import NewKeyword from "features/Keywords/components/NewKeyword";
import Keyword from "features/Keywords/components/Keyword";

import {
  AUTOMATION_PATHS,
  SECONDARY_NAV_LINKS,
} from "features/Automation/constants/AutomationConstants";
import { IndividualSequenceOverview } from "features/Sequences/screens/IndividualSequenceOverview";
import { Sequences } from "features/Sequences/screens/SequencesOverview";
import withSidebar from "higherOrderComponents/withSidebar";
import type { KeywordResponse } from "models/Keyword";

function Automation({
  currentAccount,
  isSinglePanel,
  location,
  showSidebar,
  toggleSidebar,
}: AutomationProps): JSX.Element {
  const { automation, keywordsMain, keywordGroups, sequences } =
    AUTOMATION_PATHS;
  const { mine } = SECONDARY_NAV_LINKS;

  const [selectedSequenceNavLink, setSelectedSequenceNavLink] =
    useState<AutomationNavValues>(mine);
  const [selectedKeywordNavLink, setSelectedKeywordNavLink] =
    useState<AutomationNavValues>(mine);

  const [keywords, setKeywords] = useState<KeywordResponse | null>(null);
  const { slug } = currentAccount ?? {};
  const { initialPage, keywordsEnabled } = useAutomationNavLinks();

  const {
    confirmUnlinkRef,
    links,
    linkKeyword,
    setLinks,
    toggleUnlinkDialog,
    unlinkKeyword,
  } = useKeywordLinking();

  const { rowsPerPage, loading, page, getRow, handlePageChange } =
    useKeywordGroups(keywords, setKeywords);

  useEffect(() => {
    const initialLinks = keywords?.items
      ?.filter((keyword) => {
        // filters for only Keywords with a linked Sequence
        return keyword.sequences.length > 0;
      })
      .map((link) => {
        return {
          sequence: {
            id: link.sequences[0].id,
            name: link.sequences[0].name,
          },
          keyword: {
            id: link.id,
            keyword: link.keyword,
          },
        };
      });
    setLinks(initialLinks ?? []);
  }, [keywords]);

  const url = `/${slug}/${automation}`;
  const keywordsMainUrl = `${url}/${keywordsMain}`;
  const keywordGroupsUrl = `${url}/${keywordGroups}`;
  const sequencesUrl = `${url}/${sequences}`;

  const { keywordAnswers } = currentAccount.featureFlags;

  return (
    <PageWrapper>
      {/* ==== SIDEBAR NAV LINKS ==== */}
      <AutomationSidebar
        keywordsMainUrl={keywordsMainUrl}
        keywordGroupsUrl={keywordGroupsUrl}
        sequencesUrl={sequencesUrl}
        location={location}
        keywordAnswers={keywordAnswers}
        selectedSequenceNavLink={selectedSequenceNavLink}
        setSelectedSequenceNavLink={setSelectedSequenceNavLink}
        selectedKeywordNavLink={selectedKeywordNavLink}
        setSelectedKeywordNavLink={setSelectedKeywordNavLink}
      />

      {/* ==== ROUTES ==== */}
      {/* Wait for initial page to be set. */}
      {initialPage ? (
        <Switch location={location.path}>
          {/* /automation */}
          <Route
            exact
            path={url}
            render={() => {
              return <Redirect to={`${url}/${initialPage}`} />;
            }}
          />

          {/* /automation/keywords */}
          <Route
            exact
            path={`${url}/${keywordsMain}`}
            render={() => {
              // User tried to navigate directly to this page without proper license.
              if (!keywordsEnabled) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              return (
                <KeywordAutomation
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  keywords={keywords}
                  showSidebar={showSidebar}
                  setKeywords={setKeywords}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords/new */}
          <Route
            exact
            path={`${url}/${keywordsMain}/new`}
            render={() => {
              if (!keywordsEnabled || !keywordAnswers) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              return (
                <NewKeyword
                  isSinglePanel={isSinglePanel}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords/:id */}
          <Route
            exact
            path={`${url}/${keywordsMain}/:keywordId`}
            render={({ match }) => {
              if (!keywordsEnabled || !keywordAnswers) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              const { keywordId } = match?.params ?? {};
              return (
                <Keyword
                  currentAccount={currentAccount}
                  keywordId={keywordId}
                  isSinglePanel={isSinglePanel}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords_groups */}
          <Route
            exact
            path={keywordGroupsUrl}
            render={() => {
              // User tried to navigate directly to this page without proper license.
              if (!keywordsEnabled) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              return (
                <KeywordGroups
                  isSinglePanel={isSinglePanel}
                  keywords={keywords}
                  loading={loading}
                  links={links}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  getRow={getRow}
                  handlePageChange={handlePageChange}
                  setLinks={setLinks}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords */}
          <Route
            exact
            path={keywordsMainUrl}
            render={() => {
              // User tried to navigate directly to this page without proper license.
              if (!keywordsEnabled) {
                return <Redirect to={`${url}/${initialPage}`} />;
              }

              return (
                <KeywordAutomation
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  keywords={keywords}
                  showSidebar={showSidebar}
                  setKeywords={setKeywords}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/keywords_groups/:id */}
          <Route
            exact
            path={`${url}/${keywordGroups}/:keywordGroupId`}
            render={({ match }) => {
              const { keywordGroupId } = match?.params ?? {};
              const contactCollectionId = `/${currentAccount.slug}/contact_filters/keyword:${keywordGroupId}/contacts`;
              return (
                <KeywordGroup
                  confirmUnlinkRef={confirmUnlinkRef}
                  contactCollectionId={contactCollectionId}
                  isSinglePanel={isSinglePanel}
                  keywordGroupId={keywordGroupId}
                  links={links}
                  linkKeyword={linkKeyword}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                  toggleUnlinkDialog={toggleUnlinkDialog}
                />
              );
            }}
          />

          {/* /automation/sequences */}
          <Route
            exact
            path={sequencesUrl}
            render={() => {
              return (
                <Sequences
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  returnPath={sequencesUrl}
                  selectedNavLink={selectedSequenceNavLink}
                  setSelectedNavLink={setSelectedSequenceNavLink}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                />
              );
            }}
          />

          {/* /automation/sequences/:id */}
          <Route
            exact
            path={`${url}/${sequences}/:sequenceId`}
            render={({ match }) => {
              const { sequenceId } = match?.params ?? {};
              return (
                <IndividualSequenceOverview
                  currentAccount={currentAccount}
                  isSinglePanel={isSinglePanel}
                  returnPath={sequencesUrl}
                  sequenceId={sequenceId}
                  showSidebar={showSidebar}
                  toggleSidebar={toggleSidebar}
                  unlinkKeyword={unlinkKeyword}
                />
              );
            }}
          />
        </Switch>
      ) : null}
    </PageWrapper>
  );
}

export default compose(withSidebar)(Automation);
