import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import PageHeader from "components/Page/PageHeader";
import withRecord from "higherOrderComponents/withRecord";
import GroupCollection from "features/Groups/pages/GroupFilter/GroupCollection";

function GroupFilter({ groupFilter, location, toggleSidebar, showSidebar }) {
  const { groups, title } = groupFilter;
  let pageHeaderTitle = title;
  if (typeof groups !== "string") {
    pageHeaderTitle += ` (${groups.totalItems.toLocaleString()})`;
  }

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <PageHeader
        title={pageHeaderTitle}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
      <Box
        display="flex"
        flexGrow={1}
        flexShrink={1}
        flexDirection="column"
        overflow="auto"
        position="relative"
      >
        <GroupCollection
          groupFilter={groupFilter}
          groupCollectionId={getPaginatedId(
            getNestedId(groupFilter, "groups"),
            { location },
          )}
        />
      </Box>
    </Box>
  );
}

GroupFilter.propTypes = {
  groupFilter: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

export default withRecord({
  actions: ["fetch"],
  container: "features/Groups/pages/GroupFilter/groupFilter",
  shape: { groups: {} },
  type: "groupFilter",
})(GroupFilter);
