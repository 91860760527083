import { textUsApiV3 } from "services";

interface Props {
  phoneNumber: string;
  accountSlug: string;
  name: string;
}

const createContactApi = async ({ phoneNumber, accountSlug, name }: Props) => {
  const result = await textUsApiV3.post(`/${accountSlug}/contacts`, {
    name,
    phones: [
      {
        number: phoneNumber.toString(),
      },
    ],
    method: "ui",
    source: "manual",
  });

  return result.json();
};

export { createContactApi };
