import { all, put, takeLatest } from "redux-saga/effects";

import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";
import { makeRequest } from "utils/generateContainerState/generateSagas";

// ACTION TYPES
const actionTypes = generateActionTypes({
  container: "features/Profile/containers/BasicProfile",
  actions: ["upload"],
  recordType: "avatar",
});

// ACTION GENERATORS
const actionGenerators = generateActionGenerators(actionTypes);

const sagas = {
  *uploadAvatarRequest({ url, params, options = {} }) {
    try {
      const response = yield makeRequest({
        url,
        params: { user: params.user, contentType: params.file.type },
        method: "POST",
      });
      yield fetch(response.signedUrl, {
        method: "PUT",
        body: params.file,
      });
      if (options.successCallback) options.successCallback(response);
      yield put(actionGenerators.uploadAvatarSuccess(response));
    } catch (error) {
      yield put(actionGenerators.uploadAvatarFailure(error));
    }
  },
};

function* saga() {
  yield all([
    takeLatest(actionTypes.UPLOAD_AVATAR_REQUEST, sagas.uploadAvatarRequest),
  ]);
}

export { actionTypes, actionGenerators, saga, sagas };
