import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { actionGenerators, saga, reducer, selectors } from "./state";
import useExtensionMessageHandler from "./useExtensionMessageHandler";
import useExtensionReady from "./useExtensionReady";
import useTesseractAuthReady from "./useTesseractAuthReady";
import PageLoader from "components/PageLoader";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";

function ExtensionMessageHandler({ extensionMessageHandlerContainer }) {
  useExtensionMessageHandler();
  useExtensionReady();
  useTesseractAuthReady();

  const { isHandling } = extensionMessageHandlerContainer.substate;
  return isHandling ? <PageLoader title="Searching Page" /> : null;
}

ExtensionMessageHandler.propTypes = {
  extensionMessageHandlerContainer: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    extensionMessageHandlerContainer:
      selectors.selectExtensionMessageHandlerContainer(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "extensionMessageHandlerContainer",
  reducer,
});
const withSaga = injectSaga({ key: "extensionMessageHandlerContainer", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ExtensionMessageHandler);
