import assert from "assert";
import { stringifyUrl } from "query-string";
import { userManager } from "@tesseract/core";
import { headers } from "./constants";
import { Query } from "./types";

export const fetchV3Accounts = async (
  slug: string,
  next: string | null,
  query: Query = {},
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url =
    next ??
    stringifyUrl({
      url: `/accounts/${slug}/accounts`,
      query: {
        ...query,
      },
    });

  const request = new Request(url, {
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
    },
    method: "GET",
  });

  return fetch(request);
};
