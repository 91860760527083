export default function SelectGroupIcon(displayMode: { mode: string }) {
  const { mode } = displayMode;

  return mode === "light" ? (
    <svg
      width="60"
      height="60"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="groups">
        <g id="surface1">
          <path
            id="Vector"
            d="M18.3333 19.8957C22.1354 21.0259 26.2396 18.0155 26.1875 13.9478C25.8542 5.76025 14.1615 5.76025 13.8281 13.9478C13.8281 16.7707 15.7344 19.1613 18.3333 19.8957Z"
            fill="#94BCF9"
          />
          <path
            id="Vector_2"
            d="M29.9586 29.802C29.1461 25.0415 24.9846 21.4113 20.0002 21.4113C14.3596 21.3749 9.64086 26.229 9.92211 31.7447C10.0159 32.0259 10.2659 32.2134 10.5627 32.2134H29.4117C30.5471 32.1197 29.9846 30.5311 29.9482 29.8124L29.9586 29.802Z"
            fill="#94BCF9"
          />
          <path
            id="Vector_3"
            d="M31.9998 21.9476C34.4946 21.9476 36.5207 19.9215 36.5207 17.4268C36.2915 11.4528 27.7082 11.4528 27.479 17.4268C27.479 19.9215 29.505 21.9476 31.9998 21.9476Z"
            fill="#6FA5F8"
          />
          <path
            id="Vector_4"
            d="M32 22.4268C30.7187 22.4268 29.4531 22.7601 28.3594 23.4007C28.6823 23.734 28.974 24.1038 29.2552 24.4788C30.4948 26.1611 31.2656 28.2393 31.4115 30.3591H38.5625C38.9323 30.3591 39.2552 30.0518 39.2552 29.6663C39.2552 25.6663 36 22.4111 32 22.4111V22.4268Z"
            fill="#6FA5F8"
          />
          <path
            id="Vector_5"
            d="M7.99984 21.9476C10.4946 21.9476 12.5207 19.9215 12.5207 17.4268C12.2915 11.4528 3.70817 11.4528 3.479 17.4268C3.479 19.9215 5.50505 21.9476 7.99984 21.9476Z"
            fill="#6FA5F8"
          />
          <path
            id="Vector_6"
            d="M11.6407 23.4008C6.94798 20.5727 0.640682 24.1872 0.734431 29.6664C0.734431 30.0414 1.04172 30.3591 1.42714 30.3591H8.57297C8.76047 27.7445 9.86464 25.2654 11.6407 23.4008Z"
            fill="#6FA5F8"
          />
        </g>
      </g>
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 40 40"
      fill="none"
    >
      <path
        d="M18.3333 19.8957C22.1354 21.0259 26.2396 18.0155 26.1875 13.9478C25.8542 5.76025 14.1615 5.76025 13.8281 13.9478C13.8281 16.7707 15.7344 19.1613 18.3333 19.8957Z"
        fill="#7B82AA"
      />
      <path
        d="M29.9586 29.802C29.1461 25.0415 24.9846 21.4113 20.0002 21.4113C14.3596 21.3749 9.64086 26.229 9.92211 31.7447C10.0159 32.0259 10.2659 32.2134 10.5627 32.2134H29.4117C30.5471 32.1197 29.9846 30.5311 29.9482 29.8124L29.9586 29.802Z"
        fill="#7B82AA"
      />
      <path
        d="M31.9998 21.9476C34.4946 21.9476 36.5207 19.9215 36.5207 17.4268C36.2915 11.4528 27.7082 11.4528 27.479 17.4268C27.479 19.9215 29.505 21.9476 31.9998 21.9476Z"
        fill="#52536C"
      />
      <path
        d="M32 22.4268C30.7187 22.4268 29.4531 22.7601 28.3594 23.4007C28.6823 23.734 28.974 24.1038 29.2552 24.4788C30.4948 26.1611 31.2656 28.2393 31.4115 30.359H38.5625C38.9323 30.359 39.2552 30.0518 39.2552 29.6663C39.2552 25.6663 36 22.4111 32 22.4111V22.4268Z"
        fill="#52536C"
      />
      <path
        d="M7.99984 21.9476C10.4946 21.9476 12.5207 19.9215 12.5207 17.4268C12.2915 11.4528 3.70817 11.4528 3.479 17.4268C3.479 19.9215 5.50505 21.9476 7.99984 21.9476Z"
        fill="#52536C"
      />
      <path
        d="M11.6407 23.4008C6.94797 20.5727 0.640682 24.1872 0.734431 29.6664C0.734431 30.0414 1.04172 30.3591 1.42714 30.3591H8.57297C8.76047 27.7445 9.86464 25.2654 11.6407 23.4008Z"
        fill="#52536C"
      />
    </svg>
  );
}
