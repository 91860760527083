import { merge } from "lodash";
import { Service } from "../Service";
import { addAuthorization, headers } from ".";

/**
 * Create a new service for the TextUs API.
 * @param options The default options for the service.
 * @returns The service.
 */
export const createTextUsService = ({
  url,
  options,
}: {
  url?: string;
  options?: RequestInit;
}) => {
  return new Service({
    url,
    options: merge(options, {
      headers,
    }),
  }).intercept(addAuthorization);
};
