import { Box, Typography, Dialog, DialogContent } from "@mui/material";
import { Dispatch } from "react";
import { DialogFooter } from "components/DialogFooter";
import { DialogHeader } from "components/DialogHeader";

interface SavedReplyDialogProps {
  open: boolean;
  setOpen: Dispatch<React.SetStateAction<boolean>>;
  templateOwner: string;
  templateTitle: string;
  handleDeleteTemplate: () => void;
}

export default function SavedReplyDeleteDialog({
  open,
  setOpen,
  templateOwner,
  templateTitle,
  handleDeleteTemplate,
}: SavedReplyDialogProps) {
  return (
    <Dialog
      PaperProps={{
        sx: {
          width: "26.5rem",
          height: "auto",
          padding: "0rem",
          margin: "0rem",
        },
      }}
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <DialogHeader
        title="Delete template?"
        onClose={() => {
          return setOpen(false);
        }}
      />
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.25rem"
          justifyContent="space-between"
          padding="0rem"
        >
          <Typography>
            {`This template was created by ${templateOwner}.`}
          </Typography>
          <Typography>
            {`Deleting ${templateTitle} will permanently remove it
      from this account and any other accounts that have
      access to it.`}
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            Are you sure you want to continue? This action cannot be undone.
          </Typography>
        </Box>
      </DialogContent>
      <DialogFooter
        cancelText="Nevermind"
        confirmText="Yes, delete"
        confirmVariant="text"
        confirmColor="error"
        onCancel={() => {
          return setOpen(false);
        }}
        onConfirm={() => {
          handleDeleteTemplate();
          setOpen(false);
        }}
      />
    </Dialog>
  );
}
