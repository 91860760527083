import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

class NewCampaignContactCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "Campaigns/containers/NewCampaignContactCollection",
    shape: { members: [{ phones: { members: [] } }] },
    showLoader: () => {
      return false;
    },
    type: "contactCollection",
  }),
  withConnect,
)(NewCampaignContactCollection);
