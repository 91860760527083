import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class HealthcareSourceStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /contingenttalentmanagement/i.test(this.location?.href);

  getParsers = () => {
    const { href } = this.location;
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      textSelector: "#header_tempinfo",
      getNamesFunction: () =>
        Array.from(
          doc.querySelectorAll(
            ".cv-leftcol-2 .cv-fieldset:first-of-type td.cv-form-data",
          ),
        )
          .filter(node => {
            const label = node.parentNode.querySelector(".cv-form-label");
            return label && /first name|last name/i.test(label.textContent);
          })
          .map(node => node.textContent.trim())
          .filter(text => text),
      canParse: () => /tempview/i.test(href),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "table td a[id*=tempViewTip]",
      containerSelector: "tr",
      canParse: () => /tempmanager/i.test(href),
    });
    const clientContactListPageParser = new MultiContactParser({
      doc,
      namesSelector: "table td a[href*=clientcontactid]",
      containerSelector: "tr",
      canParse: () => /clientcontact_manager/i.test(href),
    });
    const queryPageParser = new MultiContactParser({
      doc,
      namesSelector: "table td a[href*=tempview]",
      containerSelector: "tr",
      canParse: () => /orderquery/i.test(href),
    });
    return [
      listPageParser,
      queryPageParser,
      clientContactListPageParser,
      detailPageParser,
    ];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "HealthcareSourceStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "HealthcareSourceStrategy");
      return null;
    }
  };
}
