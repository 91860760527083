import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";

// ACTION TYPES
const TOGGLE_OTHER_FILTERS =
  "app/ConversationFilterCollection/TOGGLE_OTHER_FILTERS";

const TOGGLE_CAMPAIGN_FILTERS =
  "app/ConversationFilterCollection/TOGGLE_CAMPAIGN_FILTERS";

// ACTION GENERATORS
const toggleCampaignFilters = () => {
  return {
    type: TOGGLE_CAMPAIGN_FILTERS,
  };
};

const toggleOtherFilters = () => {
  return {
    type: TOGGLE_OTHER_FILTERS,
  };
};

const actionGenerators = {
  toggleCampaignFilters,
  toggleOtherFilters,
};

// REDUCERS
const showCampaignFilters = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_CAMPAIGN_FILTERS:
      return !state;
    default:
      return state;
  }
};

const showOtherFilters = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_OTHER_FILTERS:
      return !state;
    default:
      return state;
  }
};

const reducer = combineReducers({
  showCampaignFilters,
  showOtherFilters,
});

// SELECTORS
const selectContainer = (state) => {
  return state.get("Inbox/containers/ConversationFilterCollectionContainer");
};

const selectShowOtherFilters = createSelector(selectContainer, (substate) => {
  return substate.get("showOtherFilters");
});

const selectShowCampaignFilters = createSelector(
  selectContainer,
  (substate) => {
    return substate.get("showCampaignFilters");
  },
);

const selectors = {
  selectShowOtherFilters,
  selectShowCampaignFilters,
};

export { actionGenerators, reducer, selectors };
