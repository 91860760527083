import List from "components/List";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";

function LegalSidebar() {
  return (
    <PageSidebar>
      <List>
        <li key="privacy-policy">
          <PageSidebarNavLink to="/legal/privacy-policy">
            Privacy Policy
          </PageSidebarNavLink>
        </li>
        <li key="terms-of-service">
          <PageSidebarNavLink to="/legal/terms-of-use">
            Terms of Use
          </PageSidebarNavLink>
        </li>
      </List>
    </PageSidebar>
  );
}

export default LegalSidebar;
