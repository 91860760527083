import { capitalize } from "lodash";
import { GridFilter } from "../models/AccountPlanModels";

const getCheckedValues = (isChecked: GridFilter): [number, string] => {
  const checkedKeys = Object.keys(isChecked).filter((key) => {
    return isChecked[key as keyof GridFilter];
  });
  const checkedCount = checkedKeys.length;
  const checkedString = checkedKeys
    .map((key) => {
      return capitalize(key);
    })
    .join(", ");
  return [checkedCount, checkedString];
};

export default getCheckedValues;
