/**
 * This component will render the sequence drawer shell.
 * Handles styling and rendering the header and footer.
 *
 * Usage:
 * <BaseDrawer {...props}>
 *    { main_content }
 * </BaseDrawer>
 */
import { Box, Button, Drawer, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { kebabCase } from "lodash";
import { Props } from "./types";

export function BaseDrawer({
  children,
  isOpen,
  title,
  buttonProps,
  hideBackdrop = false,
  showFooter = true,
  confirmText = "Confirm",
  handleClose,
  handleClear,
  handleBack,
  handleCancel,
  handleConfirm,
}: Props): JSX.Element {
  const { clear, confirm } = buttonProps ?? {};

  const drawerHeader = (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "background.default",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        justifyContent: "space-between",
        padding: "12px 10px 12px 16px",
      }}
    >
      <Typography
        sx={(theme) => {
          return {
            color: theme.palette.primary.main,
            fontWeight: "500",
            fontSize: "20px",
          };
        }}
      >
        {title}
      </Typography>

      <IconButton onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  );

  const drawerContent = (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 0",
        flexFlow: "column nowrap",
        height: "100%",
        overflow: "auto",
      }}
    >
      {children}
    </Box>
  );

  const drawerFooter = (
    <Box
      sx={{
        backgroundColor: "background.paper",
        boxShadow: 5,
        display: "flex",
        padding: "12px 16px",
      }}
    >
      {handleClear && clear?.visible && (
        <Box>
          <Button
            data-testid={`${kebabCase(title)}-clear-all-button`}
            size="large"
            onClick={handleClear}
          >
            Clear All
          </Button>
        </Box>
      )}

      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          gap: "7px",
          justifyContent: "flex-end",
        }}
      >
        {handleBack && (
          <Button
            data-testid={`${kebabCase(title)}-back-button`}
            size="large"
            onClick={handleBack}
          >
            Back
          </Button>
        )}

        {handleCancel && (
          <Button
            data-testid={`${kebabCase(title)}-cancel-button`}
            size="large"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        )}

        <Button
          data-testid={`${kebabCase(title)}-confirm-button`}
          disabled={confirm?.disabled}
          size="large"
          variant="contained"
          type="button"
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box>
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={handleClose}
        hideBackdrop={hideBackdrop}
        PaperProps={{
          sx: {
            maxWidth: 420,
            width: "100%",
          },
        }}
      >
        {drawerHeader}
        {drawerContent}
        {showFooter && drawerFooter}
      </Drawer>
    </Box>
  );
}
