import type {
  CardHeaderProps,
  IconButtonProps,
  SwitchProps,
} from "@mui/material";
import { Account } from "@tesseract/core";

export type SettingsCardHeaderProps = {
  account?: Account.Raw;
  active?: boolean;
  canEditLock?: boolean;
  hideSwitch?: boolean;
  locked?: boolean;
  onActiveChange?: SwitchProps["onChange"] & IconButtonProps["onClick"];
  onLockedChange?: IconButtonProps["onClick"];
} & CardHeaderProps;
