import { styled } from "@mui/material/styles";

import Accordion from "@mui/material/Accordion";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";

const StyledTableCell = styled(TableCell)(({ theme }) => {
  return {
    borderBottom: "none",
    fontSize: "12px",
    padding: "14px",
  };
});

const StyledGroupTableCell = styled(TableCell)(({ theme }) => {
  return {
    borderBottom: "none",
    fontSize: "14px",
    padding: "4px",
  };
});

const StyledHeaderTableCell = styled(TableCell)(({ theme }) => {
  return {
    fontSize: "14px",
    padding: "8px",
  };
});

const StyledTableRow = styled(TableRow)(({ theme }) => {
  return {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  };
});

const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    boxShadow: "none",
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:first-of-type": {
      borderRadius: "0px",
    },
  };
});

const Bubble = styled("div")(({ theme }) => {
  return {
    alignItems: "flex-end",
    display: "flex",
    flexFlow: "row nowrap",
    justifyContent: "flex-end",
    transition: "all ease-in-out 0.3s",
  };
});

const MessageTipWrapper = styled("div")(({ theme }) => {
  return {
    marginLeft: "-10px",
    minWidth: "15px",
  };
});

const MessageBody = styled("div")(({ theme }) => {
  return {
    // @ts-ignore
    background: theme.palette?.customColors?.outboundMessageColor,
    borderRadius: "14px",
    color: theme.palette.primary.main,
    fontSize: "14px",
    height: "100%",
    padding: "8px 14px",
    p: {
      marginTop: 0,
      " &:last-child": {
        marginBottom: 0,
      },
      a: {
        color: theme.palette.primary.main,
      },
    },
    whiteSpace: "break-spaces",
    wordBreak: "break-all",
  };
});

export {
  Bubble,
  MessageBody,
  MessageTipWrapper,
  StyledAccordion,
  StyledGroupTableCell,
  StyledHeaderTableCell,
  StyledTableCell,
  StyledTableRow,
};
