import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { textUsColors } from "@tesseract/theme/src/palettes/colors";

const Overlay = styled.div`
  background: ${(props) => {
    return props.fullScreen ? "transparent" : textUsColors.blueGrey.A80;
  }};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  font-size: 33px;
  color: ${(props) => {
    return props.theme.colors?.background.paper ?? "#fafafa";
  }};
`;

const fullScreenCSS = css`
  position: fixed;
  top: 55px;
  left: 0%;
  height: calc(100% - env(safe-area-inset-bottom));
  width: 100%;
  border-radius: 0;
  transform: translate(0);
`;

const Modal = styled.div`
  background: ${(props) => {
    return props.theme.colors?.background.paper ?? "#fafafa";
  }};
  border-radius: ${(props) => {
    return props.theme.defaults?.modalBorderRadius ?? "6px";
  }};
  box-shadow: 10px 10px 41px 0 rgba(0, 0, 0, 0.3);
  left: 50%;
  max-height: 100%;
  max-width: 100%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: unset;
  z-index: 10;
  ${(props) => {
    return props.fullScreen && fullScreenCSS;
  }};
`;

const Root = styled.div`
  ${Overlay}, ${Modal} {
    transition: all cubic-bezier(0.785, 0.135, 0.15, 0.86)
      ${(props) => {
        return props.transitionDuration;
      }}ms;
  }

  &.${(props) => {
      return props.transitionClassname;
    }}-enter {
    ${Overlay} {
      opacity: 0;
    }

    ${Modal} {
      transition-delay: ${(props) => {
        return props.transitionDelay;
      }}ms;
      transition-duration: ${(props) => {
        return props.transitionDuration;
      }}ms;
      transform: translate3d(-50%, 100vh, 0);

      ${(props) => {
        return (
          props.fullScreen &&
          css`
            transform: translate3d(0%, 100vh, 0);
          `
        );
      }};
    }
  }

  &.${(props) => {
      return props.transitionClassname;
    }}-enter-active {
    ${Overlay} {
      opacity: 1;
    }

    ${Modal} {
      transform: translate3d(-50%, -50%, 0);

      ${(props) => {
        return (
          props.fullScreen &&
          css`
            transform: translate3d(0%, 0%, 0);
          `
        );
      }};
    }
  }

  &.${(props) => {
      return props.transitionClassname;
    }}-exit {
    ${Overlay} {
      opacity: 1;
      transition-delay: ${(props) => {
        return props.transitionDelay;
      }}ms;
    }

    ${Modal} {
      transform: translate3d(-50%, -50%, 0);

      ${(props) => {
        return (
          props.fullScreen &&
          css`
            transform: translate3d(0%, 0%, 0);
          `
        );
      }};
    }
  }

  &.${(props) => {
      return props.transitionClassname;
    }}-exit-active {
    ${Overlay} {
      opacity: 0;
    }

    ${Modal} {
      transform: translate3d(-50%, 100vh, 0);
      transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);

      ${(props) => {
        return (
          props.fullScreen &&
          css`
            transform: translate3d(0%, 100vh, 0);
          `
        );
      }};
    }
  }
`;

class ModalWrapper extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    children: PropTypes.any,
    closeModal: PropTypes.func,
    fullScreen: PropTypes.bool,
  };

  render() {
    const { ariaLabel } = this.props;
    return (
      <Root {...this.props}>
        <Overlay
          onClick={this.props.closeModal}
          fullScreen={this.props.fullScreen}
        />
        <Modal aria-label={ariaLabel} fullScreen={this.props.fullScreen}>
          {this.props.children}
        </Modal>
      </Root>
    );
  }
}

export default ModalWrapper;
