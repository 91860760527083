import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import MainColumn from "./MainColumn";
import QuickLinks from "./QuickLinks";
import UserInfoHeader from "./UserInfoHeader";
import animation from "components/TopLevelPage/constants/animation";
import breakpoints from "utils/styles/breakpoints";
import Container from "components/TopLevelPage/Container";
import Header from "components/TopLevelPage/Header";
import Root from "components/TopLevelPage/Root";

const { duration: animationDuration, stagger: animationStagger } = animation;

const Tiles = styled.div`
  display: flex;
  flex-flow: row;
  height: auto;
  margin: 50px 0;
  width: 100%;

  @media (max-width: ${breakpoints.large}px) {
    flex-flow: column;
    margin-top: 0;
  }
`;

const SideColumn = styled.div`
  flex: 0 0 260px;
  height: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    position: -webkit-sticky;
    position: sticky;
    top: 185px;
  }
`;

export default class MainView extends Component {
  static propTypes = {
    accountNavbarElements: PropTypes.array.isRequired,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    setCompose: PropTypes.func.isRequired,
    setCurrentAccount: PropTypes.func.isRequired,
  };

  render() {
    const {
      accountNavbarElements,
      currentAccount,
      currentUser,
      setCompose,
      setCurrentAccount,
    } = this.props;

    return (
      <Root>
        <Container>
          <CSSTransition
            classNames="transition"
            key="top-level-page-header"
            timeout={animationDuration}
          >
            <Header animationDuration={animationDuration}>
              <UserInfoHeader
                currentAccount={currentAccount}
                currentUser={currentUser}
              />
            </Header>
          </CSSTransition>
          <CSSTransition key="tiles" timeout={0}>
            <Tiles>
              <MainColumn
                accountNavbarElements={accountNavbarElements}
                animationDuration={animationDuration}
                animationStagger={animationStagger}
                currentAccount={currentAccount}
                currentUser={currentUser}
                setCompose={setCompose}
                setCurrentAccount={setCurrentAccount}
              />
              <SideColumn>
                <TransitionGroup appear component={null}>
                  <CSSTransition
                    classNames="transition"
                    key="quick-links"
                    timeout={animationDuration + animationStagger + 200}
                  >
                    <QuickLinks
                      animationDuration={animationDuration}
                      animationStagger={animationStagger + 200}
                      currentAccount={currentAccount}
                      currentUser={currentUser}
                      setCompose={setCompose}
                    />
                  </CSSTransition>
                </TransitionGroup>
              </SideColumn>
            </Tiles>
          </CSSTransition>
        </Container>
      </Root>
    );
  }
}
