import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import startCase from "lodash/startCase";

import { compactInteger } from "humanize-plus";

import breakpoints from "utils/styles/breakpoints";
import H4 from "components/H4";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";

const ANALYTIC_TYPES = ["account", "global"];
const METRICS = ["totalOptOuts", "contactCreated", "userCreated"];

const Wrapper = styled.div`
  ${H4} {
    margin-bottom: 10px;
  }

  ${SettingsDescription} {
    margin-bottom: 10px;
  }
`;

const MetricSection = styled.section`
  margin-bottom: 20px;
`;

const MetricRow = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    flex-flow: row;
  }
`;

const Metric = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  flex-flow: column nowrap;
  flex: 1 1 120px;
  padding: 15px;
  text-align: center;

  ${H4} {
    font-weight: 300;
    white-space: nowrap;
  }
`;

const Stat = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-size: 66px;
  font-weight: 700;
  margin: 0;
`;

export default class Root extends Component {
  static propTypes = {
    optOutAnalytics: PropTypes.object.isRequired,
  };

  render() {
    const { optOutAnalytics } = this.props;
    return (
      <Wrapper>
        {ANALYTIC_TYPES.filter((type) => {
          return Object.values(optOutAnalytics[type]).every((val) => {
            return Number.isInteger(val);
          });
        }).map((type) => {
          const analytics = optOutAnalytics[type];
          const useType = type === "global" ? "shared" : type;
          return (
            <MetricSection key={`OptOutAnalytics-${type}`}>
              <H4>{`${startCase(useType)} Opt Outs`}</H4>
              <SettingsDescription>
                <p>
                  Opt Outs are company-wide by default. If a contact is opted
                  out of texts from one account, they will be opted out of texts
                  from all accounts in your organization.
                </p>
                <p>
                  Should you feel this is set up incorrectly, please contact
                  Support.
                </p>
                <p>
                  Below are the Opt Out stats for all accounts in your company,
                  broken down by contacts who texted the keyword
                  &quot;STOP&quot; and contacts manually opted out by a user.
                </p>
              </SettingsDescription>
              <MetricRow>
                {METRICS.map((metric) => {
                  return (
                    <Metric key={`OptOutAnalytics-${type}-${metric}`}>
                      <H4>{startCase(metric)}</H4>
                      <Stat>
                        <span>{compactInteger(analytics[metric], 1)}</span>
                      </Stat>
                    </Metric>
                  );
                })}
              </MetricRow>
            </MetricSection>
          );
        })}
      </Wrapper>
    );
  }
}
