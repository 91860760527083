import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import queryString from "query-string";
import Logo from "components/Logo";
import MultipleSuccessGraphic from "utils/images/salesforce-success.png";
import H3 from "components/H3";
import successTransitions from "utils/successTransitions";
import {
  integrationsId,
  salesforceSubdomain,
} from "constants/salesforceAuthConstants";
import localStorageHelper from "utils/localStorageHelper";

const { createSuccessful } = successTransitions;

const Root = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
`;

const Success = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  flex-flow: column;

  ${H3} {
    margin: 10px 0;
  }

  img {
    margin: 40px 0;
    width: 380px;
  }
`;

class MainView extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    salesforceCallbackContainer: PropTypes.object.isRequired,
    createIntegrationRequest: PropTypes.func.isRequired,
  };

  state = {
    wasSuccessful: false,
  };

  componentDidMount() {
    const { createIntegrationRequest } = this.props;
    const integrationUrl = localStorageHelper.getItem(integrationsId);
    const subdomain = localStorageHelper.getItem(salesforceSubdomain);
    const config = {
      ...queryString.parse(this.props.location.search),
      subdomain,
    };

    createIntegrationRequest(integrationUrl, {
      config,
      active: true,
      provider: "salesforce",
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.salesforceCallbackContainer) {
      if (
        createSuccessful({
          container: "salesforceCallbackContainer",
          nextProps,
          props: this.props,
        })
      ) {
        this.setState({ wasSuccessful: true }, () => {
          setTimeout(() => {
            window.close();
          }, 3000);
        });
      }
    }
  }

  render() {
    return (
      <Root>
        {this.state.wasSuccessful ? (
          <Success>
            <img
              alt="successfully authorized salesforce account"
              src={MultipleSuccessGraphic}
            />
            <H3>Successfully Authorized Salesforce</H3>
          </Success>
        ) : (
          <Logo animate color="transparent" dotColor="primary" />
        )}
      </Root>
    );
  }
}

export default MainView;
