import { Component } from "react";
import styled from "styled-components";
import Button from "features/Authentication/components/PrimaryButton";

const Root = styled(Button)`
  position: relative;
`;

const Emoji = styled.span`
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default class PasswordlessButton extends Component {
  render() {
    return (
      <Root
        {...this.props}
        aria-label="Passwordless Login Button"
        data-testid="passwordless-sign-in-button"
      >
        <Emoji>
          <span role="img" aria-label="Inbox Emoji">
            📨
          </span>
        </Emoji>
        <span>Send a login link</span>
      </Root>
    );
  }
}
