import { getConversationByContactIdApi } from "../api/getConversationByContactIdApi";
import { getConversationByPhoneNumberApi } from "../api/getConversationByPhoneNumberApi";

// Return conversationId , just the slug, example 8NWQXY
const getOrCreateConversationId = async ({
  contactId,
  phoneNumber,
  accountSlug,
}: {
  contactId: string;
  phoneNumber: string;
  accountSlug: string;
}): Promise<string> => {
  const conversation = await getConversationByContactIdApi({
    contactId,
    accountSlug,
  });

  if (conversation?.members && conversation.members.length > 0) {
    return conversation.members[0].id.replace(/^\/conversations\//, "");
  }

  const newConversation = await getConversationByPhoneNumberApi({
    phoneNumber,
    accountSlug,
  });

  return newConversation.id.replace(/^\/conversations\//, "");
};

export default getOrCreateConversationId;
