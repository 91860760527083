import { Component } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { closeSnackbar } from "notistack";

class SnackbarCloseButton extends Component {
  static propTypes = {
    snackbarKey: PropTypes.number.isRequired,
    padding: PropTypes.string,
  };

  render() {
    const { snackbarKey, padding } = this.props;
    return (
      <IconButton
        key="close"
        aria-label="Close"
        onClick={() => {
          return closeSnackbar(snackbarKey);
        }}
        size="large"
        sx={(muiTheme) => {
          return {
            padding: padding || "9px",
            "& .MuiSvgIcon-root": {
              color: muiTheme.palette.primary.contrastText,
            },
          };
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    );
  }
}

export default SnackbarCloseButton;
