import assert from "assert";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { get } from "lodash";
import { TeamManagement } from "../../TeamManagement";
import { Props } from "./types";
import { dispatcher } from "features/Accounts";
import { Create as CreateAccounts } from "features/Accounts/Create";
import { FormErrors } from "features/Accounts/Create/components/Form";
import {
  accountKeyMapInverse,
  userKeyMapInverse,
} from "features/Accounts/api/constants";
import { ValidationErrorResponse } from "features/Accounts/api";

const validationErrorResponseCode = 422;

const transformUsers = (
  validationErrors: ValidationErrorResponse["validationErrors"]["account"][string]["users"],
) => {
  return Object.fromEntries(
    Object.entries(validationErrors).map(([index, value]) => {
      return [
        index,
        Object.fromEntries(
          Object.entries(value).map(([key, _value]) => {
            assert(key in userKeyMapInverse);

            return [
              userKeyMapInverse[key as keyof typeof userKeyMapInverse],
              _value[0],
            ];
          }),
        ),
      ];
    }),
  );
};

const transform = (
  validationErrors: ValidationErrorResponse["validationErrors"]["account"],
): FormErrors => {
  return Object.fromEntries(
    Object.entries(validationErrors).map(([id, value]) => {
      return [
        id,
        Object.fromEntries(
          Object.entries(value).map(([key, _value]) => {
            assert(key in accountKeyMapInverse);

            if (key === "users") {
              return [
                accountKeyMapInverse[key],
                transformUsers(
                  _value as ValidationErrorResponse["validationErrors"]["account"][string]["users"],
                ),
              ];
            }

            return [
              accountKeyMapInverse[key as keyof typeof accountKeyMapInverse],
              _value[0],
            ];
          }),
        ),
      ];
    }),
  );
};

export function Create({ account, toggleSidebar }: Props) {
  const history = useHistory();
  const snackbar = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<FormErrors | undefined>(undefined);

  useEffect(() => {
    return dispatcher.subscribe("createMany", (promise) => {
      setIsSubmitting(true);
      setErrors(undefined);

      promise
        .then(async (response) => {
          if (response.status === validationErrorResponseCode) {
            const data = (await response.json()) as ValidationErrorResponse;

            const validationErrors = get(
              data,
              "validationErrors.account",
              undefined,
            );

            assert(validationErrors && typeof validationErrors === "object");

            setErrors({
              accounts: transform(validationErrors),
            });
          }
          assert(response.ok);

          snackbar?.enqueueSnackbar(
            `
              Success! Your new account details have been submitted. 
              You will receive an email once your accounts are active.
            `,
            {
              variant: "success",
            },
          );

          history.push("../");
        })
        .catch(() => {
          snackbar?.enqueueSnackbar("Failed to create accounts", {
            variant: "error",
          });
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    });
  }, [history, snackbar]);

  return (
    <TeamManagement toggleSidebar={toggleSidebar}>
      <CreateAccounts
        currentAccount={account}
        isSubmitting={isSubmitting}
        dispatcher={dispatcher}
        serverErrors={errors}
      />
    </TeamManagement>
  );
}
