export default {
  navHeight: "55px",
  navExpandedHeight: "300px",
  sidebarWidth: "240px",
  analyticsSidebarWidth: "320px",
  analyticsSidebarWidthMobile: "260px",
  analyticsUserSidebarWidth: "300px",
  analyticsUserSidebarWidthMobile: "260px",
  topHeaderHeight: "68px",
  modalHeaderHeight: "60px",
  secondaryTopHeader: "45px",
  singlePanelConversationSidebarWidth: "80vw",
  accountNavBarZIndex: 5000,
};
