import PropTypes, { string } from "prop-types";
import debounce from "lodash/debounce";
import { SizeMe } from "react-sizeme";

import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import { useMediaQuery, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import SearchOverlay from "./SearchOverlay";
import Input from "./Input";
import Preview from "./Preview";
import { getOrCreateConversationByPhone } from "features/Conversation";
import H3 from "components/H3";
import H5 from "components/H5";
import NewMessage from "containers/NewMessage";
import { useCurrentAccount } from "hooks";

NewMessage.displayName = "NewMessage";

const headerHeight = 60;
const borderRadius = "10px";

const Root = styled.div`
  width: ${(props) => {
    return props.fullScreen ? "100%" : "600px";
  }};
  height: ${(props) => {
    return props.fullScreen ? "calc(100% - 55px)" : "80vh";
  }};
  max-height: ${(props) => {
    return !props.fullScreen && (props.showConversationPreview ? 666 : 420);
  }}px;
  display: flex;
  flex-flow: column nowrap;
  border-radius: ${borderRadius};
  position: relative;
  transition: ${(props) => {
    return props.theme.mixins.transition({
      easing: "ease-in-out",
      timing: "0.8s",
    });
  }};
  margin-top: env(safe-area-inset-top);

  & > header {
    height: ${headerHeight}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
    flex: 0 0 auto;
    overflow: hidden;
    padding-right: 16px;
  }
`;

const AccountInfo = styled.div`
  padding: 20px;

  ${H3}, ${H5} {
    margin: 0;
  }
`;

const Content = styled.div`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: 0;
`;

const usePreviousRecipients = (recipients) => {
  const ref = useRef(recipients);
  useEffect(() => {
    ref.current = recipients;
  });
  return ref.current;
};

function QuickComposer(props) {
  const {
    addRecipient,
    closeModal,
    currentAccount,
    fetchContactPhoneCollectionRequest,
    fullScreen,
    isActive,
    isShort,
    recipients,
    removeRecipient,
    resetSearchResults,
    searchResults,
    quickComposerContainer: {
      substate: { activateSignature, isFetching },
    },
  } = props;

  const [searchQuery, setSearchQuery] = useState();
  const [conversationId, setConversationId] = useState();
  const [isUnsubscribed, setIsUnsubscribed] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const fetchData = async (phoneNum) => {
    const data = await getOrCreateConversationByPhone(currentAccount, phoneNum);
    if (data) {
      const json = await data.json();
      return json;
    }
    return null;
  };

  const { isCampaignProMember } = useCurrentAccount();

  const showConversationPreview = recipients.length > 0;
  const hasMultipleRecipients = recipients.length > 1;
  const conversationSearchUrl =
    (recipients &&
      recipients.length === 1 &&
      `/${currentAccount.slug}/conversations/${recipients[0].phoneNumber}`) ||
    null;

  const previousRecipients = usePreviousRecipients(recipients);

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const getRecipientArray = async () => {
    return Promise.all(
      recipients.map((recip) => {
        return fetchData(recip.phoneNumber);
      }),
    );
  };

  useEffect(() => {
    const getData = () => {
      if (previousRecipients?.length !== recipients?.length) {
        setSearchQuery("");
      }
    };

    getData();
  }, [previousRecipients?.length, recipients?.length]);

  useEffect(() => {
    const getRecipientData = async () => {
      const recipientArray = await getRecipientArray();

      const isRecipientUnsubscribed = recipientArray?.some((recipient) => {
        return recipient.unsubscribed;
      });

      setIsUnsubscribed(isRecipientUnsubscribed);
    };

    getRecipientData().catch((error) => {
      return enqueueSnackbar(`Error fetching recipient data: ${error}`, {
        variant: "error",
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipients.length]);

  const clearRecipients = () => {
    recipients.map((r) => {
      return removeRecipient(r.id);
    });
  };

  const handleClose = () => {
    clearRecipients();
    closeModal();
  };

  function makeContactPhoneRequest(newQuery) {
    return fetchContactPhoneCollectionRequest(
      `${currentAccount.contactPhones}?q=${newQuery}`,
    );
  }
  const debouncedContactPhoneRequest = (newQuery) => {
    debounce(makeContactPhoneRequest(newQuery), 350);
  };

  const handleSuccess = () => {
    enqueueSnackbar("Message sent.", { variant: "info" });

    setTimeout(() => {
      if (isActive) {
        handleClose();
      }
    }, 250);
  };

  const handleError = (errorMessage) => {
    enqueueSnackbar(`${errorMessage}`, { variant: "error" });

    setTimeout(() => {
      if (isActive) {
        handleClose();
      }
    }, 250);
  };

  const handleInput = ({ target: { value } }) => {
    const newQuery = value.replace(/^\s+/, "");
    setSearchQuery(newQuery);

    if (newQuery.length >= 3) {
      debouncedContactPhoneRequest(newQuery);
    }
    resetSearchResults();
  };

  return (
    <Root
      aria-label="Compose"
      fullScreen={fullScreen}
      showConversationPreview={showConversationPreview}
    >
      {!mobileScreen && (
        <header>
          <AccountInfo>
            <H3 aria-label={`Account name ${currentAccount.name}`}>
              {currentAccount.name}
            </H3>
            <H5
              aria-label={`Account phone number ${currentAccount.phoneNumber}`}
            >
              {currentAccount.phoneNumber}
            </H5>
          </AccountInfo>
          <IconButton
            aria-label="Close Button"
            onClick={handleClose}
            size="large"
            data-testid="close-modal"
          >
            <CloseIcon />
          </IconButton>
        </header>
      )}
      <Input
        recipients={recipients}
        searchQuery={searchQuery}
        onQueryChange={handleInput}
        onRecipientRemove={({ id }) => {
          return removeRecipient(id);
        }}
        mobileScreen={mobileScreen}
        handleClose={handleClose}
      />
      <Content>
        <SizeMe monitorHeight>
          {({ size: { height } }) => {
            return (
              <Preview
                conversationSearchUrl={conversationSearchUrl}
                hasMultipleRecipients={hasMultipleRecipients}
                isActive={showConversationPreview && !isShort}
                hideImage={height < 200}
                setConversationId={setConversationId}
              />
            );
          }}
        </SizeMe>
        {searchQuery && (
          <SearchOverlay
            addRecipient={addRecipient}
            isFetching={isFetching}
            searchQuery={searchQuery}
            results={searchResults}
            onResultSelect={({ id }) => {
              return addRecipient(id);
            }}
            recipients={recipients}
          />
        )}
        <NewMessage
          activateSignature={activateSignature}
          conversationId={
            !hasMultipleRecipients && conversationId
              ? conversationId
              : undefined
          }
          handleSuccess={handleSuccess}
          handleError={handleError}
          quickComposer
          placeholder="Say hello"
          recipients={recipients.map((r) => {
            return r.phoneNumber;
          })}
          unsubscribed={isUnsubscribed}
          isCampaignProMember={isCampaignProMember}
        />
      </Content>
    </Root>
  );
}

export default QuickComposer;

QuickComposer.propTypes = {
  addRecipient: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  conversationPreviewContainer: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
  fetchContactPhoneCollectionRequest: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool,
  isActive: PropTypes.bool,
  isShort: PropTypes.bool,
  quickComposerContainer: PropTypes.object.isRequired,
  recipients: PropTypes.array.isRequired,
  removeRecipient: PropTypes.func.isRequired,
  resetSearchResults: PropTypes.func,
  searchResults: PropTypes.array.isRequired,
};
