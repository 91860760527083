import { TextUsColor } from "./types";

export const blue: TextUsColor = {
  50: "#E3F2FD",
  100: "#BBDEFB",
  200: "#90CAF9",
  300: "#64B5F6",
  400: "#42A5F5",
  500: "#2196F3",
  600: "#1E88E5",
  700: "#0057B2",
  800: "#1565CO",
  900: "#0D47A1",
  A8: "rgba(21, 101, 192, 0.08)",
  A12: "rgba(21, 101, 192, 0.12)",
  A16: "rgba(21, 101, 192, 0.16)",
  A30: "rgba(21, 101, 192, 0.3)",
  A50: "rgba(21, 101, 192, 0.5)",
};
