const shallowDenormalize = ({ id, records }) => {
  const record = records[id];
  if (!record) return id;
  return {
    ...record,
    ...(record.members && {
      members: record.members.map((memberId) => {
        return records[memberId] || memberId;
      }),
    }),
  };
};

export default shallowDenormalize;
