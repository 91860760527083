import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";

import Badge from "@mui/material/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import withRecord from "higherOrderComponents/withRecord";
import breakpoints from "utils/styles/breakpoints";

const IconWrapper = styled.div`
  position: relative;

  svg {
    align-self: center;
  }
`;

const Title = styled.div`
  display: none;
  font-size: 11px;
  font-weight: 700;
  margin-left: 15px;
  margin-top: 3px;
  text-decoration: none;
  text-transform: uppercase;
`;

const Root = styled(NavLink)`
  color: ${(props) => {
    return props.theme.colors.navigation.color;
  }};
  text-decoration: none;
  padding: 0;

  &:visited {
    fill: ${(props) => {
      return props.theme.colors.navigation.color;
    }};
  }

  &.active {
    color: ${(props) => {
      return props.theme.colors.navigation.hoverColor;
    }};
  }

  &:hover {
    color: ${(props) => {
      return props.theme.colors.navigation.hoverColor;
    }};
  }

  @media (min-width: ${breakpoints.large}px) {
    padding: 0 20px;

    ${Title} {
      display: block;
    }
  }
`;

class SiteNavigationLink extends Component {
  static propTypes = {
    badge: PropTypes.any,
    icon: PropTypes.object,
    className: PropTypes.string,
    title: PropTypes.string,
    to: PropTypes.string,
  };

  render() {
    const { badge, icon, to, title, className } = this.props;
    const badgeContent = get(badge, "value", 0);
    return (
      <Root
        to={to}
        className={className}
        data-product-tour={`SiteNavigationLink-${title}`}
      >
        <IconWrapper>
          <Badge badgeContent={badgeContent} variant="dot" color="error">
            <FontAwesomeIcon icon={icon} />
          </Badge>
        </IconWrapper>
        {title && <Title>{title}</Title>}
      </Root>
    );
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "MainNavigation/SiteNavigationIcon/badge",
  shouldFetch: ({ badge, badgeId }) => {
    return isEmpty(badge) && Boolean(badgeId);
  },
  showLoader: () => {
    return false;
  },
  shape: {},
  type: "badge",
})(SiteNavigationLink);
