import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import {
  nameStyles,
  numbersStyles,
  childrenStyles,
  usersStyles,
  manageStyles,
} from "./rowStyles";

function AccountHeaderRow({ nameOnly, brandInformation }) {
  return (
    <Box
      aria-label="Account Section Header"
      borderColor="divider"
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      fontSize="body2.fontSize"
      fontWeight="500"
      height="55px"
      py={2}
      px={2}
      style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.12)" }}
    >
      <Box key="name" style={{ ...nameStyles }}>
        {nameOnly ? "Account" : "Name"}
      </Box>
      {!nameOnly && (
        <>
          <Box key="numbers" style={{ ...numbersStyles }}>
            Phone Numbers
          </Box>
          <Box key="children" style={{ ...childrenStyles }}>
            Accounts
          </Box>
        </>
      )}
      {!nameOnly && !brandInformation && (
        <>
          <Box key="users" style={{ ...usersStyles }}>
            Users
          </Box>
          <Box key="manage" style={{ ...manageStyles }}></Box>
        </>
      )}
    </Box>
  );
}

AccountHeaderRow.propTypes = {
  brandInformation: PropTypes.bool,
  nameOnly: PropTypes.bool.isRequired,
};

export default AccountHeaderRow;
