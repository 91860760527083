import { useState } from "react";
import { fetchBlueprints } from "features/Sequences/api";
import { useCurrentAccount, useLoading } from "hooks";
import { Blueprint } from "features/Sequences/components/CreateSequenceModal/components/Blueprints/types";

export const useBlueprints = () => {
  const currentAccount = useCurrentAccount();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const [blueprints, setBlueprints] = useState<Blueprint[]>([]);
  const [selectedCard, setSelectedCard] = useState<Blueprint | undefined>(
    undefined,
  );

  const getBlueprints = async () => {
    try {
      startLoading();
      const response = await fetchBlueprints(currentAccount);
      const body = await response.json();
      setBlueprints(body);
      setSelectedCard(body[0]);
    } catch (err) {
      throw new Error(`Error fetching blueprints: ${err}`);
    } finally {
      stopLoading();
    }
  };

  return {
    areBlueprintsLoading: isLoading,
    blueprints,
    selectedCard,
    getBlueprints,
    setSelectedCard,
  };
};
