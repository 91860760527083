/* eslint-disable react/no-array-index-key */
import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import startCase from "lodash/startCase";
import uniq from "lodash/uniq";

import {
  Button,
  FormControl,
  OutlinedInput,
  NativeSelect,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import H3 from "components/H3";
import ImportSection from "features/ContactImports/containers/NewContactImport/components/ImportSection";
import List from "components/List";
import PageFooter from "components/Page/PageFooter";

const CONTACT_ATTRIBUTE_OPTIONS = [
  { value: "", label: "[Do not import]", matcher: /^(?![\s\S])/i },
  { value: "firstName", label: "First Name", matcher: /first.*name/i },
  { value: "lastName", label: "Last Name", matcher: /last.*name/i },
  { value: "name", label: "Full Name", matcher: /(full.*name|^name$)/i },
  { value: "phoneNumber", label: "Phone Number", matcher: /phone/i },
  { value: "business", label: "Business", matcher: /business|company/i },
  { value: "tags", label: "Tags", matcher: /tag/i },
];

const Preview = styled(ImportSection)`
  align-items: start;
  max-width: 100%;
  text-align: left;

  h4 {
    margin-bottom: 10px;
    margin-top: 0;
  }
`;

const TableWrapper = styled.div`
  overflow: scroll;
  text-align: left;
  width: 100%;

  thead > tr:first-child {
    height: 40px;

    > th {
      border: none;
    }
  }

  th,
  td {
    min-width: 144px;
  }

  tr:first-child th {
    box-sizing: content-box;
    padding: 0 16px 0 0;
  }
`;

const Footer = styled.footer`
  margin: 0;
  text-align: right;
  width: 100%;
`;

const SummaryRow = styled.div`
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
`;

const Summary = styled.div`
  flex: 0 0 auto;
  font-weight: 700;
`;

const Errors = styled(List)`
  color: ${(props) => {
    return props.theme.colors.error.main;
  }};
`;

const Error = styled.li``;

export default class ImportPreview extends Component {
  static propTypes = {
    fromExtension: PropTypes.bool,
    handleSubmit: PropTypes.func.isRequired,
    handleCancel: PropTypes.func,
    isUploading: PropTypes.bool.isRequired,
    header: PropTypes.array,
    previewRows: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    withHeaders: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      assignedHeaders: props.header.map((header) => {
        const assignedAttr = CONTACT_ATTRIBUTE_OPTIONS.find(({ matcher }) => {
          return matcher.test(header);
        });
        return assignedAttr ? assignedAttr.value : "";
      }),
    };
  }

  getErrors = () => {
    const { assignedHeaders } = this.state;
    const nameAssigned = assignedHeaders.some((h) => {
      return ["firstName", "lastName", "name"].includes(h);
    });
    const phoneAssigned = assignedHeaders.includes("phoneNumber");
    const duplicates = uniq(
      assignedHeaders
        .filter((header) => {
          return header !== "";
        })
        .filter((header) => {
          return (
            assignedHeaders.indexOf(header) !==
            assignedHeaders.lastIndexOf(header)
          );
        }),
    );
    let errors = [];
    if (!nameAssigned) {
      errors = [...errors, "Please select a name column"];
    }
    if (!phoneAssigned) {
      errors = [...errors, "Please select a phone number column"];
    }
    if (duplicates.length > 0) {
      errors = [
        ...errors,
        ...duplicates.map((duplicate) => {
          return `Please select only one ${startCase(duplicate)}`;
        }),
      ];
    }
    if (
      (assignedHeaders.includes("name") &&
        (assignedHeaders.includes("firstName") ||
          assignedHeaders.includes("lastName"))) ||
      (!assignedHeaders.includes("firstName") &&
        assignedHeaders.includes("lastName")) ||
      (assignedHeaders.includes("firstName") &&
        !assignedHeaders.includes("lastName"))
    ) {
      errors = [
        ...errors,
        "Please select both a first and last name or a full name",
      ];
    }
    return errors;
  };

  handleHeaderChange = (event, { dataIndex }) => {
    const { assignedHeaders } = this.state;
    this.setState({
      assignedHeaders: assignedHeaders.map((header, index) => {
        return index === dataIndex ? event.target.value : header;
      }),
    });
  };

  handleSubmit = () => {
    this.props.handleSubmit(this.state.assignedHeaders);
  };

  render() {
    const {
      fromExtension,
      header,
      isUploading,
      previewRows,
      total,
      withHeaders,
    } = this.props;
    const { assignedHeaders } = this.state;
    const directions = fromExtension
      ? "Please verify the contact names and numbers below"
      : "Please map each column of your spreadsheet to one of the contact attributes";
    const errors = this.getErrors();
    return (
      <>
        <Preview>
          {withHeaders && <H3>Review contact attributes</H3>}
          <Typography variant="body2" mb={3}>
            {directions}
          </Typography>
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  {header.map((data, dataIndex) => {
                    return (
                      <TableCell key={`select--${dataIndex}`}>
                        <FormControl
                          variant="standard"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <NativeSelect
                            id={`select--${dataIndex}`}
                            input={<OutlinedInput />}
                            name="column"
                            onChange={(event) => {
                              return this.handleHeaderChange(event, {
                                dataIndex,
                              });
                            }}
                            value={assignedHeaders[dataIndex]}
                          >
                            {CONTACT_ATTRIBUTE_OPTIONS.map(
                              ({ value, label }) => {
                                return (
                                  <option
                                    value={value}
                                    key={`select--${dataIndex}--${value}`}
                                  >
                                    {label}
                                  </option>
                                );
                              },
                            )}
                          </NativeSelect>
                        </FormControl>
                      </TableCell>
                    );
                  })}
                </TableRow>
                {!fromExtension && (
                  <TableRow>
                    {header.map((data, dataIndex) => {
                      return (
                        <TableCell key={`header--${dataIndex}`}>
                          {data}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {previewRows.map((row, rowIndex) => {
                  return (
                    <TableRow key={rowIndex}>
                      {row.map((data, dataIndex) => {
                        return (
                          <TableCell key={`${rowIndex}--${dataIndex}`}>
                            {data}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableWrapper>
          <Footer>
            <SummaryRow>
              <Summary>{`Previewing ${previewRows.length} of ${total} Rows`}</Summary>
              {errors.length > 0 && (
                <Errors data-testid="error-displays">
                  {errors.map((error) => {
                    return <Error key={error}>{error}</Error>;
                  })}
                </Errors>
              )}
            </SummaryRow>
          </Footer>
        </Preview>

        <PageFooter>
          <Button
            className="cancel-button"
            color="primary"
            onClick={this.props.handleCancel}
          >
            Cancel
          </Button>

          {!isUploading && (
            <Button
              aria-label="Import Button"
              data-testid="import-button"
              className="submit-button"
              variant="outlined"
              color="primary"
              onClick={this.handleSubmit}
              disabled={errors.length > 0}
            >
              Import
            </Button>
          )}
        </PageFooter>
      </>
    );
  }
}
