import { createRef, Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Textarea from "react-textarea-autosize";

import Signature from "components/Signature";

const Wrapper = styled.div`
  width: 100%;

  .textus-NewMessageTextArea {
    padding: 0.75rem;
    resize: none;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

const SignatureWrapper = styled.div`
  max-height: 0;
  opacity: 0;
  transition: all linear 0.3s;
  width: 100%;
  visibility: hidden;

  &.isActive {
    max-height: 83px;
    opacity: 1;
    visibility: visible;
  }
`;

export default class MessageInputForMobile extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    handleCmdEnter: PropTypes.func,
    isSignatureActive: PropTypes.bool,
    itemToAdd: PropTypes.object,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    signature: PropTypes.object,
    value: PropTypes.string,
    withSignature: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.textarea = createRef();
  }

  componentDidUpdate = (prevProps) => {
    if (!prevProps.itemToAdd && Boolean(this.props.itemToAdd)) {
      this.insertItem();
    }
  };

  insertItem = () => {
    const { itemToAdd, value } = this.props;
    const { item, type } = itemToAdd;
    const { selectionStart, selectionEnd } = this.textarea.current;
    const newValue =
      type === "savedReply"
        ? item
        : `${value.slice(0, selectionStart)}${item}${value.slice(
            selectionEnd,
          )}`;
    this.props.handleChange(newValue);
  };

  handleTextareaChange = (event) => {
    this.props.handleChange(event.target.value);
  };

  handleKeyDown = (event) => {
    const { handleCmdEnter } = this.props;
    if (
      handleCmdEnter &&
      event.keyCode === 13 &&
      (event.metaKey || event.ctrlKey)
    ) {
      handleCmdEnter(event);
    }
  };

  render() {
    const {
      isSignatureActive,
      name,
      onBlur,
      onFocus,
      placeholder,
      signature,
      value,
      withSignature,
    } = this.props;
    return (
      <Wrapper>
        <Textarea
          id="textus-NewMessageTextArea"
          className="textus-NewMessageTextArea"
          minRows={2}
          maxRows={5}
          name={name}
          onBlur={onBlur}
          onChange={this.handleTextareaChange}
          onFocus={onFocus}
          onKeyDown={this.handleKeyDown}
          placeholder={placeholder}
          ref={this.textarea}
          value={value}
        />
        {withSignature && (
          <SignatureWrapper className={isSignatureActive && "isActive"}>
            <Signature
              maxCharCount={120}
              signature={signature}
              updatedSignatureContent={() => {}}
            />
          </SignatureWrapper>
        )}
      </Wrapper>
    );
  }
}
