import {
  Box,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { ConfirmJobDialogProps } from "./types";

export function ConfirmJobDialog({ close, name, id }: ConfirmJobDialogProps) {
  const [jobType, setJobType] = useState<string>("job_submission");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setJobType((event.target as HTMLInputElement).value);
  };

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <DialogTitle
          component="div"
          aria-label="Confirmation Title"
          padding="0.5rem 1rem"
          children={
            <Typography variant="h6" fontWeight={500} color="text.primary">
              Find numbers
            </Typography>
          }
        />
        <IconButton
          data-testid="close-confirmation-modal"
          sx={{ marginRight: "1rem", marginTop: "-0.5rem" }}
          onClick={() => {
            close(false);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <DialogContent sx={{ padding: "0.5rem 1.5rem" }}>
        <Box
          display="flex"
          flexDirection="column"
          gap="20px"
          justifyContent="space-between"
        >
          <DialogContentText
            aria-label="Confirmation Message"
            variant="body1"
            color="text.primary"
          >
            Confirm <b>{name}</b> and <b>ID {id}</b> are the contacts you want
            to message:
          </DialogContentText>

          <FormControl>
            <RadioGroup
              aria-labelledby="job-type-label"
              name="radio-buttons-group"
              value={jobType}
              onChange={handleChange}
            >
              <FormControlLabel
                value="job_submission"
                control={<Radio />}
                label="Submission"
              />
              <FormControlLabel
                value="job_appointment"
                control={<Radio />}
                label="Appointments"
              />
              <FormControlLabel
                value="job_placement"
                control={<Radio />}
                label="Placement"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </DialogContent>

      <DialogActions sx={{ padding: "16px" }}>
        <Button
          aria-label="Cancel"
          onClick={() => {
            close(false);
          }}
          data-testid="close-confirm-job-dialog"
        >
          Cancel
        </Button>
        <Button
          aria-label="Confirm"
          variant="contained"
          onClick={() => {
            close(jobType);
          }}
          data-testid="confirm-job"
        >
          Confirm
        </Button>
      </DialogActions>
    </>
  );
}
