import { useSnackbar } from "notistack";
import type { Sequence } from "models/Sequence";
import { useDialog } from "components/Dialog";
import { useCurrentAccount, useLoading } from "hooks";
import { updateSequenceState } from "features/Sequences/api";
import { Snackbar } from "components/Snackbar";
import { SEQUENCE_STATES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

export const useArchiveSequenceDialog = () => {
  const { ref: archiveSequenceDialogRef, open: openDialog } = useDialog<
    any,
    boolean
  >();

  const currentAccount = useCurrentAccount();

  const { enqueueSnackbar } = useSnackbar();

  const { isLoading, startLoading, stopLoading } = useLoading();

  const handleArchiveSequence = async (
    sequence: Sequence,
    finallyCallback?: () => void,
  ) => {
    startLoading();
    try {
      if (!sequence) {
        return;
      }

      const response = await updateSequenceState(
        currentAccount,
        sequence.id,
        SEQUENCE_STATES.archive,
      );

      if (response.ok) {
        enqueueSnackbar("Successfully archived sequence.", {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        });
      }
    } catch (err) {
      throw new Error(`Error archiving sequence: ${err}`);
    } finally {
      stopLoading();
      if (finallyCallback) finallyCallback();
    }
  };

  const openArchiveSequenceDialog = (
    sequence: Sequence,
    finallyCallback?: () => void,
  ) => {
    const parameters = {
      sequence,
    };

    const close = async (confirm: boolean | undefined) => {
      if (confirm) {
        handleArchiveSequence(sequence, finallyCallback);
      }
    };

    openDialog(parameters, close)();
  };

  return {
    archiveSequenceDialogRef,
    isLoadingArchive: isLoading,
    openArchiveSequenceDialog,
  };
};
