import { ComponentProps, useCallback, useEffect, useMemo } from "react";
import { Box, FormHelperText, Grid, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import { Formik } from "formik";
import { FormValues, UsersFormProps } from "./types";
import { validateEmail } from "utils/validateEmail";
import createUUID from "utils/uuid";
import { Checkbox } from "components/Checkbox";
import { Select } from "components/Select";
import { Input } from "components/Input";

/**
 * Create object with unique keys and undefined values
 *
 * @param length - number of records in object
 * @returns blank object
 */
export const createBlankRecords = (length: number): FormValues["users"] => {
  return Object.fromEntries(
    Array.from({ length }).map(() => {
      return [
        createUUID(),
        {
          name: "",
          email: "",
          role: "user",
          sendWelcome: false,
        } satisfies FormValues["users"][string],
      ];
    }),
  );
};

const checkboxLabel = "Send welcome email to new users";

function Form({
  users,
  ...props
}: {
  users: FormValues["users"] | undefined;
} & Omit<ComponentProps<typeof Formik<FormValues>>, "initialValues">) {
  const initialValues = useMemo<FormValues>(() => {
    return {
      users: users ?? createBlankRecords(1),
    };
  }, [users]);

  return <Formik<FormValues> {...props} initialValues={initialValues} />;
}

function Fields({ values, setValues, serverErrors, errors }: UsersFormProps) {
  const setUsers = useCallback(
    (users: typeof values.users) => {
      const { users: deleted, ...rest } = values;

      setValues({ users, ...rest });
    },
    [setValues, values],
  );

  useEffect(() => {
    const emptyUsers = Object.entries(values.users).filter(([id, user]) => {
      return user !== undefined && Object.keys(user).length === 0;
    });
    if (emptyUsers.length > 0) {
      const { users } = values;

      emptyUsers.forEach(([id]) => {
        users[id] = undefined;
      });

      setUsers(users);
    }
  }, [setUsers, values]);

  const handle = useMemo(() => {
    return {
      createRow: () => {
        const [[key, value]] = Object.entries(createBlankRecords(1));

        const { users } = values;

        users[key] = value;

        setUsers(users);
      },
      deleteRow: (id: keyof typeof values.users) => {
        return () => {
          const { [id]: deleted, ...rest } = values.users;

          setUsers(rest);
        };
      },
    };
  }, [values, setUsers]);

  return (
    <>
      {Object.entries(values.users).map(([id, user], index) => {
        return (
          <Grid
            display="grid"
            gap="12px 2px"
            gridTemplateColumns="1fr 90px"
            gridTemplateAreas={`
              ". ."
              ". empty"
              ". empty"
            `}
            key={id}
            mb={2}
            pb={2}
            pr={3}
            sx={{
              borderBottomStyle: "solid",
              borderColor: "grey.300",
              borderBottomWidth: "1px",
            }}
          >
            <Grid mb={1} display="contents">
              <Box>
                <Input
                  required
                  label="Name"
                  name={`users[${id}].name`}
                  sx={{ width: "100% " }}
                  error={
                    Boolean((errors?.users?.[id] as any)?.name) ||
                    Boolean((serverErrors?.users?.[id] as any)?.name)
                  }
                />
                {Boolean((serverErrors?.users?.[id] as any)?.name) && (
                  <FormHelperText error>
                    {(serverErrors?.users?.[id] as any)?.name}
                  </FormHelperText>
                )}
              </Box>

              <Grid
                container
                item
                justifyContent="space-between"
                alignContent="center"
                maxWidth="74px"
              >
                <IconButton
                  disabled={Object.keys(values.users).length === 1}
                  onClick={handle.deleteRow(id)}
                  aria-label={`Delete ${user?.name ?? `row ${index + 1}`}`}
                  sx={{
                    color: "text.primary",
                  }}
                  size="small"
                >
                  <RemoveCircleOutline fontSize="small" />
                </IconButton>
                {index === Object.keys(values.users).length - 1 && (
                  <IconButton
                    disabled={Object.keys(values.users).length >= 10}
                    onClick={handle.createRow}
                    aria-label="Create Row"
                    size="small"
                    sx={{
                      color: "text.primary",
                    }}
                  >
                    <AddCircleOutlineIcon fontSize="small" />
                  </IconButton>
                )}
              </Grid>
              <Box>
                <Input
                  type="email"
                  required
                  validate={validateEmail}
                  label="Email"
                  name={`users[${id}].email`}
                  sx={{ width: "100% " }}
                  error={
                    Boolean((errors?.users?.[id] as any)?.email) ||
                    Boolean((serverErrors?.users?.[id] as any)?.email)
                  }
                />
                {Boolean((serverErrors?.users?.[id] as any)?.email) && (
                  <FormHelperText error>
                    {(serverErrors?.users?.[id] as any)?.email}
                  </FormHelperText>
                )}
              </Box>
              <Select
                required
                label="Role"
                options={[
                  {
                    value: "admin",
                    label: "Admin",
                  },
                  {
                    value: "user",
                    label: "User",
                  },
                ]}
                name={`users[${id}].role`}
              />
              <Grid
                px={1}
                sx={{
                  marginTop: "-2px",
                  marginBottom: "9px",
                }}
              >
                <Checkbox
                  name={`users[${id}].sendWelcome`}
                  options={[
                    { value: true, label: checkboxLabel },
                    { value: false, label: checkboxLabel },
                  ]}
                />
              </Grid>
              <Grid
                style={{
                  gridArea: "empty",
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export const UsersForm = {
  Form,
  Fields,
};
