import PropTypes from "prop-types";
import styled from "styled-components";
import { TransitionGroup } from "react-transition-group";
import breakpoints from "utils/styles/breakpoints";

const Root = styled.div`
  width: 100%;
  max-width: ${(props) => {
    return props.maxWidth || 1200;
  }}px;
  height: auto;
  margin: 0 auto;
  padding: 0 50px 100px;

  @media (max-width: ${breakpoints.medium - 1}px) {
    padding: ${(props) => {
      return props.noPaddingOnMobile ? "0" : "0 20px 20px";
    }};
  }
`;

function Container(props) {
  return (
    <Root {...props}>
      <TransitionGroup appear component={null}>
        {props.children}
      </TransitionGroup>
    </Root>
  );
}

Container.propTypes = {
  children: PropTypes.any,
  maxWidth: PropTypes.number,
};

export default Container;
