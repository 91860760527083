import { ChangeEvent, useContext, useState } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { BlueprintsContext } from "../../context";
import { BlueprintsTemplatesCards } from "../BlueprintsTemplateCards";
import { BlueprintsTemplatesFilter } from "../BlueprintsTemplatesFilter";
import { BlueprintsFilters } from "../BlueprintsTemplatesFilter/types";
import { BlueprintsTemplatesPreview } from "../BlueprintsTemplatesPreview";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";
import { DialogFooter } from "components/DialogFooter";

function BlueprintsTemplates() {
  const {
    isSmallScreen,
    selectedCard,
    handleBlueprintsBack,
    handleChooseBlueprint,
  } = useContext(BlueprintsContext);

  const [showPreview, setShowPreview] = useState(false);
  const [filters, setFilters] = useState<BlueprintsFilters>({
    useCase: "all",
    scheduleType: SCHEDULE_TYPES.relative,
  });

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  const handleFilterChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
  };

  return (
    <Box
      sx={(theme) => {
        return {
          [theme.breakpoints.down("md")]: {
            width: "25.25rem",
          },
          [theme.breakpoints.between("md", "xl")]: {
            width: "19rem",
          },
          [theme.breakpoints.up("xl")]: {
            width: "35rem",
          },
        };
      }}
    >
      {showPreview ? (
        <BlueprintsTemplatesPreview />
      ) : (
        <>
          <BlueprintsTemplatesFilter
            filters={filters}
            handleFilterChange={handleFilterChange}
          />
          <BlueprintsTemplatesCards filters={filters} />
        </>
      )}
      {isSmallScreen ? (
        <DialogFooter
          altButton={
            <Button onClick={togglePreview}>{`${
              showPreview ? "Close " : ""
            }Preview`}</Button>
          }
          confirmText="Choose"
          cancelText="Back"
          onCancel={handleBlueprintsBack}
          onConfirm={() => {
            if (selectedCard) {
              handleChooseBlueprint(selectedCard);
            }
          }}
        />
      ) : null}
    </Box>
  );
}

export { BlueprintsTemplates };
