import { Component } from "react";
import PropTypes from "prop-types";
import Papa from "papaparse";
import get from "lodash/get";
import XLSX from "xlsx";

import { Box, LinearProgress, Typography } from "@mui/material";

import ImportError from "features/ContactImports/containers/NewContactImport/components/ImportError";
import ImportLoader from "features/ContactImports/containers/NewContactImport/components/ImportLoader";
import ImportPreviewRedesign from "features/ContactImports/containers/NewContactImport/components/ImportPreviewRedesign";
import ImportUpload from "features/ContactImports/containers/NewContactImport/components/ImportUpload";
import PageHeader from "components/Page/PageHeader";
import ImportingContacts from "utils/images/importing-contacts.svg";

export default class Root extends Component {
  static propTypes = {
    contactImport: PropTypes.object,
    currentAccount: PropTypes.object.isRequired,
    extractedPhones: PropTypes.array.isRequired,
    fromCampaign: PropTypes.bool,
    importContactImportRequest: PropTypes.func.isRequired,
    location: PropTypes.object,
    setFileUploaded: PropTypes.func,
    toggleSidebar: PropTypes.func,
    updateExtractedPhones: PropTypes.func.isRequired,
    uploadContactImportRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = this.getInitialState();
  }

  getInitialState = () => {
    return {
      errorImporting: null,
      errorParsing: null,
      errorUploading: null,
      file: null,
      fromExtension: false,
      importSuccess: false,
      isImporting: false,
      isParsing: false,
      isUploading: false,
      parsedResults: null,
      showLongRunningMessage: false,
    };
  };

  componentDidMount() {
    if (this.props.extractedPhones.length > 0) {
      this.handleExtractedPhones(this.props);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.longRunningTimeout);
  }

  convertToCsv = ({ data, fileName }) => {
    const workbook = XLSX.read(data, { type: "array" });
    const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
    const csv = XLSX.utils.sheet_to_csv(firstWorksheet, {
      header: 1,
    });
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    return new File([csvBlob], `${fileName}.csv`);
  };

  parseFile = (csv) => {
    Papa.parse(csv, {
      skipEmptyLines: true,
      complete: (parsedResults) => {
        this.setState({
          parsedResults,
          isParsing: false,
          errorParsing: null,
        });
      },
      error: (error) => {
        this.setState({ errorParsing: error });
      },
    });
  };

  uploadFile = (file) => {
    const { currentAccount, uploadContactImportRequest, setFileUploaded } =
      this.props;
    this.setState({ isUploading: true });
    // eslint-disable-next-line no-unused-expressions
    setFileUploaded && setFileUploaded(true);
    uploadContactImportRequest(
      currentAccount.presignContactImport,
      {
        file,
      },
      {
        successCallback: (signedUrl) => {
          this.setState({ signedUrl, isUploading: false });
        },
        errorCallback: (errorUploading) => {
          this.setState({ errorUploading, isUploading: false });
        },
      },
    );
  };

  handleExtractedPhones = (props) => {
    const csv = Papa.unparse(props.extractedPhones);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const csvFile = new File([csvBlob], "extension.csv");
    this.setState({ fromExtension: true });
    this.handleFilesAccepted([csvFile]);
  };

  handleFilesAccepted = (file) => {
    const fileName = file.name.substr(0, file.name.lastIndexOf("."));
    const reader = new FileReader();
    reader.onload = (event) => {
      this.setState({ file, isParsing: true }, () => {
        const data = new Uint8Array(event.target.result);
        const csvFile = this.convertToCsv({ data, fileName });
        this.parseFile(csvFile);
        this.uploadFile(csvFile);
      });
    };
    reader.readAsArrayBuffer(file);
  };

  handleSubmit = (assignedHeaders) => {
    const { signedUrl } = this.state;
    const {
      currentAccount,
      extractedPhones,
      importContactImportRequest,
      location,
      updateExtractedPhones,
    } = this.props;
    this.setState({ isImporting: true });
    this.longRunningTimeout = setTimeout(() => {
      this.setState({ showLongRunningMessage: true });
    }, 3000);
    const source = get(location, ["state", "source"]);
    importContactImportRequest(
      currentAccount.contactImport,
      {
        headers: assignedHeaders,
        url: signedUrl,
        filename: this.state.file.name,
        ...(source ? { method: "extension", source } : {}),
      },
      {
        successCallback: () => {
          this.setState({
            file: null,
            importSuccess: true,
            isImporting: false,
            parsedResults: null,
          });
          if (extractedPhones.length > 0) updateExtractedPhones([]);
        },
        errorCallback: (errorImporting) => {
          this.setState({ errorImporting, isUploading: false });
          if (extractedPhones.length > 0) updateExtractedPhones([]);
        },
      },
    );
  };

  startNewImport = () => {
    this.setState(this.getInitialState());
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.extractedPhones.length === 0 &&
      nextProps.extractedPhones.length > 0
    ) {
      this.handleExtractedPhones(nextProps);
    }
  }

  render() {
    const { contactImport, fromCampaign, toggleSidebar } = this.props;
    const {
      errorImporting,
      errorParsing,
      errorUploading,
      file,
      fromExtension,
      importSuccess,
      isImporting,
      isParsing,
      isUploading,
      parsedResults,
      showLongRunningMessage,
    } = this.state;
    const contactImportState = get(contactImport, ["state"]);
    const errorMessage = (() => {
      if (errorParsing) return errorParsing.message;
      const error = errorImporting || errorUploading;
      return error
        ? get(
            error,
            ["detail"],
            "Uh Oh! Something went wrong! Please try again.",
          )
        : undefined;
    })();
    const state = (() => {
      if (importSuccess) return "success";
      if (errorMessage) return "error";
      if (!file) return "upload";
      if (isParsing || isUploading || isImporting) return "loading";
      if (parsedResults && parsedResults.data) return "preview";
      return "";
    })();
    return (
      <Box className="new-contact-import-wrapper">
        {toggleSidebar && (
          <PageHeader title="Import contacts" toggleSidebar={toggleSidebar} />
        )}
        {(() => {
          switch (state) {
            case "upload":
              return (
                <ImportUpload
                  handleFilesAccepted={this.handleFilesAccepted}
                  withHeaders={!fromCampaign}
                  withInstructionText={!fromCampaign}
                  startNewImport={this.startNewImport}
                />
              );
            case "loading":
              return <ImportLoader />;
            case "success": {
              if (
                contactImportState &&
                contactImportState !== "processed" &&
                showLongRunningMessage
              ) {
                return (
                  <Box
                    alignItems="center"
                    className="importing-contacts-loader"
                    display="flex"
                    flexDirection="column"
                    flexWrap="nowrap"
                  >
                    <img
                      alt="Importing contacts..."
                      src={ImportingContacts}
                      style={{
                        marginBottom: "24px",
                      }}
                    />

                    <LinearProgress
                      sx={{
                        width: "200px",
                        marginBottom: "24px",
                      }}
                    />

                    <Typography marginBottom="24px" variant="body2">
                      Hang tight! We're pulling in your contacts.
                    </Typography>
                  </Box>
                );
              }
              return <ImportLoader />;
            }
            case "error":
              return (
                <ImportError
                  errorMessage={errorMessage}
                  startNewImport={this.startNewImport}
                />
              );
            case "preview":
              return (
                <ImportPreviewRedesign
                  fromExtension={fromExtension}
                  handleSubmit={this.handleSubmit}
                  handleCancel={this.startNewImport}
                  header={parsedResults.data[0]}
                  isUploading={isUploading}
                  previewRows={parsedResults.data.slice(1, 6)}
                  total={parsedResults.data.length - 1}
                  withHeaders={!fromCampaign}
                />
              );
            default:
              return null;
          }
        })()}
      </Box>
    );
  }
}
