import { getAccountSettings } from "./accountSettings";
import { getInboxSettings } from "./inboxSettings";
import { getMessageSettings } from "./messageSettings";
import { getCampaignSettings } from "./campaignSettings";
import { getContactSettings } from "./contactSettings";
import { getAnalyticsSettings } from "./analyticsSettings";
import { getPrivacySettings } from "./privacySettings";
import { getOrganizationSettings } from "./organizationSettings";
import { getTeamManagementSettings } from "./teamManagementSettings";
import { SettingsSection } from "models/SettingsSection";
import { Account } from "models/Account";
import { User } from "models/User";

export const getSettingsOptions = ({
  account,
  isAdmin,
  role,
}: {
  account: Account;
  isAdmin: boolean;
  role: string;
}): SettingsSection[] => {

  return [
    getAccountSettings({ account, isAdmin }),
    getInboxSettings({ isAdmin }),
    getMessageSettings({ account, isAdmin }),
    getCampaignSettings({ account, isAdmin }),
    getContactSettings({ account, isAdmin }),
    getAnalyticsSettings({ isAdmin }),
    getPrivacySettings({ isAdmin }),
    getOrganizationSettings({
      account,
      isAdmin,
    }),
    getTeamManagementSettings({ account, role }),
  ]
    .filter((section) => {
      return section !== null;
    })
    .filter((section) => {
      return section?.settings.length;
    });
};
/* currently filters out based on the overall settings section being null,
 * or their sub-settings that returned empty lists
 * At this point we know that there are only SettingsSection left in the array
 */
