import { all, put, select, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router";

import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import { updateCurrentAccount } from "features/EntryPoint/containers/App/actions";

// ACTION TYPES
const actionTypes = {
  SET_CURRENT_ACCOUNT: "app/AccountNavbar/SET_CURRENT_ACCOUNT",
};

// ACTION GENERATORS
export const setCurrentAccount = (currentAccountId, { pathname } = {}) => {
  return {
    type: actionTypes.SET_CURRENT_ACCOUNT,
    currentAccountId,
    pathname,
  };
};
const actionGenerators = {
  setCurrentAccount,
};

// SAGAS
function* setCurrentAccountSaga({ currentAccountId, pathname }) {
  yield put(updateCurrentAccount(currentAccountId));
  const currentAccount = yield select(selectCurrentAccount);
  const newPathname = pathname || `/${currentAccount.slug}`;
  yield put(push({ pathname: newPathname }));
}

function* saga() {
  yield all([
    takeLatest(actionTypes.SET_CURRENT_ACCOUNT, setCurrentAccountSaga),
  ]);
}

export { actionGenerators, saga };
