/* eslint-disable max-classes-per-file */
import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import DirectoryPhone from "./DirectoryPhone";
import H3 from "components/H3";
import H5 from "components/H5";
import Label from "components/Label";

export const Root = styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  padding: 30px;
  display: flex;
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};

  &:last-child {
    border-bottom: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
  }

  ${H3} {
    margin-bottom: 5px;
  }
`;

const CheckColumn = styled.div`
  padding-right: 10px;
  padding-top: 5px;
  flex: 0 0 auto;
`;

const Content = styled.div`
  flex: 1 1 100%;
`;

const WorksAt = styled(H5)`
  font-size: ${(props) => {
    return props.theme.fonts.messageFontSize;
  }};
  margin-bottom: 5px;

  span {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

const check = `
  content: "";
  display: none;
  position: absolute;
  border: solid ${(props) => {
    return props.theme.colors.primary.contrastText;
  }};
  border-width: 0 2px 2px 0;
  left: 50%;
  top: 50%;
  height: 8px;
  width: 4px;
  transform: translate(-50%, calc(-50% - 1px)) rotate(45deg);
`;

const dash = `
  background: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  content: "";
  display: block;
  position: absolute;
  left: 3px;
  top: 5px;
  height: 2px;
  width: 6px;
`;

const Checkmark = styled.span`
  border: ${(props) => {
    return props.theme.mixins.border({
      color: props.theme.colors.text.secondary,
    });
  }};
  border-radius: ${(props) => {
    return props.round
      ? "50%"
      : `calc(0.5 * ${props.theme.defaults.borderRadius})`;
  }};
  height: ${(props) => {
    return props.large ? 20 : 14;
  }}px;
  left: 0;
  position: absolute;
  top: 0;
  width: ${(props) => {
    return props.large ? 20 : 14;
  }}px;

  &:after {
    ${(props) => {
      return props.partiallySelected ? dash : check;
    }};
  }
`;

const Input = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  ${(props) => {
    return props.checkboxOnly ? "width: 0;" : "";
  }}

  &:checked + ${Checkmark} {
    border: none;
    background: ${(props) => {
      return props.theme.colors.secondary.main;
    }};
  }

  &:checked + ${Checkmark}:after {
    display: block;
  }
`;

const CheckboxLabel = styled(Label)`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 14px;
  padding-left: ${(props) => {
    return props.checkboxOnly ? "14px" : "28px";
  }};
  position: relative;
  user-select: none;

  &:hover {
    ${Checkmark} {
      background-color: ${(props) => {
        return props.theme.colors.action.hover;
      }};
    }
  }

  &:hover ${Input}:checked + ${Checkmark} {
    border: none;
    background-color: ${(props) => {
      return props.theme.colors.secondary.main;
    }};
  }
`;

class Checkbox extends Component {
  static propTypes = {
    changeHandler: PropTypes.func.isRequired,
    checkboxOnly: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    className: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    partiallySelected: PropTypes.bool,
    large: PropTypes.bool,
    round: PropTypes.bool,
    gradient: PropTypes.bool,
  };

  render() {
    const {
      changeHandler,
      checkboxOnly,
      checked,
      className,
      id,
      label,
      partiallySelected,
      gradient,
      large,
      round,
    } = this.props;
    return (
      <CheckboxLabel
        className={className}
        gradient={gradient}
        checkboxOnly={checkboxOnly}
      >
        {label && label}
        <Input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={changeHandler}
          gradient={gradient}
          checkboxOnly={checkboxOnly}
        />
        <Checkmark
          large={large}
          round={round}
          partiallySelected={partiallySelected}
        />
      </CheckboxLabel>
    );
  }
}

export default class ContactCard extends Component {
  static propTypes = {
    contact: PropTypes.object.isRequired,
    isSelected: PropTypes.bool,
    handleSelect: PropTypes.func,
  };

  render() {
    const { contact, isSelected, handleSelect } = this.props;
    return (
      <Root>
        {handleSelect && (
          <CheckColumn>
            <Checkbox
              id={contact.id}
              round
              large
              gradient
              checked={isSelected}
              changeHandler={handleSelect}
            />
          </CheckColumn>
        )}
        <Content>
          <H3>{contact.name}</H3>
          {contact.data.business && (
            <WorksAt>
              Works at <span>{this.props.contact.data.business}</span>
            </WorksAt>
          )}
          {this.props.contact.phones.members.map(
            ({ type, formattedPhoneNumber }) => {
              return (
                <DirectoryPhone
                  key={formattedPhoneNumber}
                  number={formattedPhoneNumber}
                  type={type}
                />
              );
            },
          )}
        </Content>
      </Root>
    );
  }
}
