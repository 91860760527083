import { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid,
  Typography,
  useMediaQuery,
  Divider,
} from "@mui/material";
import { handleResponse } from "@tesseract/core/src/services";
import { useSnackbar } from "notistack";
import CampaignMetric from "../../Campaign/components/CampaignMetric";
import { useCurrentAccount } from "hooks";
import { ExpandMoreIcon } from "icons";
import { fetchCampaignAnalytics } from "features/Campaigns/api";
import { campaignFilterSlugs } from "features/Campaigns/constants";

const METRICS = ["campaignsSent", "recipients", "replyRate", "failureRate"];

function CampaignOverviewAnalytics() {
  const { enqueueSnackbar } = useSnackbar();

  const [campaignAnalytics, setCampaignAnalytics] = useState({});

  const { slug: accountSlug } = useCurrentAccount();

  const showMetrics = Boolean(
    METRICS.some((metric) => {
      return campaignAnalytics[metric];
    }),
  );

  const getAnalytics = useCallback(() => {
    const controller = new AbortController();

    fetchCampaignAnalytics(accountSlug, campaignFilterSlugs.all, {
      signal: controller.signal,
    })
      .then(handleResponse)
      .then(setCampaignAnalytics)
      .catch((error) => {
        enqueueSnackbar("Something went wrong, please try again.", {
          variant: "error",
        });
        console.error(error);
      });

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSlug]);

  useEffect(() => {
    return getAnalytics();
  }, [getAnalytics]);

  return (
    <Box>
      <Accordion
        sx={{
          boxShadow: "none",
          border: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            flexDirection: "row-reverse",
            gap: "0.5rem",
            padding: "0 0.5rem",
            ".MuiAccordionSummary-content": {
              margin: 0,
              "&.Mui-expanded": {
                margin: 0,
              },
            },
            "&.Mui-expanded": {
              minHeight: "3rem",
            },
          }}
        >
          <Typography variant="h6">Activity: Week to date</Typography>
        </AccordionSummary>
        <Divider />
        <AccordionDetails sx={{ padding: "0.5rem 0 0 0" }}>
          <Grid container spacing={2}>
            {showMetrics ? (
              METRICS.map((metric) => {
                return (
                  <Grid item xs={12} sm={6} md={3} key={metric}>
                    <CampaignMetric
                      campaignAnalytics={campaignAnalytics}
                      metric={metric}
                      key={metric}
                    />
                  </Grid>
                );
              })
            ) : (
              <Grid item xs={12}>
                <Typography
                  align="center"
                  variant="h6"
                  margin="0.5rem 1rem 1rem 1rem"
                >
                  You have no campaign activity in the past week
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Divider />
    </Box>
  );
}

export { CampaignOverviewAnalytics };
