import { useMemo, useState } from "react";

export const useSwitch = (initialState = false) => {
  const [is, setIs] = useState<boolean>(initialState);

  const methods = useMemo(() => {
    return {
      on: () => {
        setIs(true);
      },
      off: () => {
        setIs(false);
      },
    };
  }, []);

  return {
    ...methods,
    is,
  };
};
