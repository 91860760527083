import { Component } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import styled from "styled-components";
import Button from "@mui/material/Button";

import get from "lodash/get";
import STEP_TYPES from "../constants/step-types";
import FadeUpIn from "./Transition/FadeUpIn";
import breakpoints from "utils/styles/breakpoints";
import BasicButton from "components/BasicButton";
import ICONS from "constants/icons";
import Icon from "components/Icon";
import H3 from "components/H3";
import Tile from "features/Dashboard/components/Tile";
import Logo from "components/Logo";

const controlsWidth = `120px`;

const Root = styled(Tile)`
  position: relative;
  flex-direction: column;
  overflow: hidden;
  flex: 1 1 auto;

  header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 25px;
    position: relative;
    border-bottom: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};

    ${H3} {
      margin: 0;
    }

    [role="img"] {
      margin: 0 20px 0 0;
      font-size: 34px;
      animation: wave infinite ease-in-out 7s;
    }
  }

  footer {
    width: 100%;
    padding: 0;
    border-top: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
  }

  @keyframes wave {
    10%,
    20%,
    30%,
    100% {
      transform: rotate(0deg);
    }

    15%,
    25% {
      transform: rotate(20deg);
    }
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    header {
      padding: 12px 15px;

      ${H3} {
        margin: 0;
        font-weight: 600;
        font-size: 14px;
      }

      [role="img"] {
        font-size: 30px;
      }
    }
  }
`;

const StepContentContainer = styled.div`
  height: 120px;
  position: relative;
  width: 100%;
`;

const StepContent = styled(BasicButton)`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  display: flex;
  align-items: flex-start;
  padding: 30px;
  padding-bottom: 40px;
  width: 100%;
  flex: 1 1 100%;
  position: relative;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.02);
  }

  [role="img"] {
    margin: 0 20px 0 0;
    font-size: 34px;
  }

  &.fade-appear,
  &.fade-enter {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
    transform: translateX(-20px);
  }

  &.fade-appear-active,
  &.fade-enter-active {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateX(0px);
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045) 500ms;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    padding: 12px 15px;

    [role="img"] {
      margin: 0 12px 0 0;
      font-size: 24px;
    }
  }
`;

const StepInfo = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;
  height: 100%;
  padding-bottom: 10px;

  ${H3} {
    margin: 0;
    text-align: left;
    transition: all ease-in-out 0.2s;
    font-weight: 600;
    font-size: 20px;
  }

  p {
    margin: 7px 0;
    font-size: 16px;
    text-align: left;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    ${H3} {
      font-size: 15px;
    }

    p {
      font-size: 13px;
    }
  }
`;

const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
  flex: 0 0 ${controlsWidth};

  @media (max-width: ${breakpoints.medium - 1}px) {
    flex: 0 0 90px;
  }
`;

const Arrow = styled(BasicButton)`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: relative;
  outline: 0;
  cursor: pointer;

  svg {
    height: 12px;
    width: 12px;
    fill: ${(props) => {
      return props.theme.colors.text.secondary;
    }};
    z-index: 1;
    ${(props) => {
      return props.theme.mixins.flexCenter;
    }};
  }

  &:after {
    ${(props) => {
      return props.theme.mixins.coverParent;
    }};
    content: " ";
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
    border-radius: 50%;
    transform: scale(0);
    transition: all ease-in-out 0.1s;
    z-index: 0;
  }

  &:hover {
    svg {
      fill: ${(props) => {
        return props.theme.colors.text.primary;
      }};
    }

    &:after {
      transform: scale(1);
    }
  }
`;

const StepIndicator = styled.div`
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  position: absolute;
  right: 0;
  bottom: 0;
`;

const StepIndicatorItem = styled(BasicButton)`
  background: ${(props) => {
    return props.isActive ? props.theme.colors.secondary.main : "#eee";
  }};
  width: 25px;
  height: 3px;
  margin: 0 4px;
  border-radius: 3px;
  transition: width cubic-bezier(0.6, -0.28, 0.735, 0.045) 0.2s;
  cursor: pointer;
  outline: 0;

  &:hover {
    background: ${(props) => {
      return !props.isActive && props.theme.colors.text.secondary;
    }};
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    width: 6px;
    height: 6px;
    margin: 0 3px;
    border-radius: 50%;
  }
`;

const PhoneNumberPrompt = styled.form`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  background: ${(props) => {
    return props.theme.colors.background.default;
  }};
  z-index: 1;
  display: flex;
  align-items: center;
  overflow: hidden;

  input {
    flex: 1 1 100%;
    height: 100%;
    padding: 0 30px;
    opacity: 0.6;
    font-size: 24px;
    font-weight: 700;

    &:focus {
      color: ${(props) => {
        return props.theme.colors.primary.main;
      }};
      opacity: 1;
      outline: 0;
    }
  }

  button {
    flex: 0 0 auto;
    height: 45px;
    margin: 0 30px;

    svg {
      fill: currentColor;
      margin-right: 7px;
      width: 12px;
    }
  }
`;

const TestMessageStatus = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  font-size: 24px;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  z-index: 2;
`;

const PhoneError = styled.p`
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 10px 30px;
  margin: 0;
  color: ${(props) => {
    return props.theme.colors.error.main;
  }};
  z-index: 10;
  font-size: 11px;
`;

class MainView extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    sendTestMessage: PropTypes.func.isRequired,
    gettingStartedContainer: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      steps: this.getInitialSteps(props),
      activeStepIndex: 0,
      showPhonePrompt: false,
      phoneNumber: "",
      showTestMessageSuccess: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { isSending, errorSending } = this.props.gettingStartedContainer;
    const testMessageWasSuccessful =
      !isSending &&
      !errorSending &&
      prevProps.gettingStartedContainer.isSending;

    if (testMessageWasSuccessful) {
      this.showTestMessageSuccess();
    }
  }

  componentWillUnmount() {
    if (this.TestMessageSuccessTimeout) {
      clearTimeout(this.TestMessageSuccessTimeout);
    }
  }

  getInitialSteps = (props) => {
    const messagingNotEnabled =
      get(props.currentAccount, ["phoneNumbers"], []).length === 0;

    if (messagingNotEnabled) {
      return props.currentUser.gettingStartedElements.members.filter((m) => {
        return m.elementType !== "test_message";
      });
    }
    return props.currentUser.gettingStartedElements.members;
  };

  setActiveIndex = (i) => {
    return this.setState({ activeStepIndex: i, showPhonePrompt: false });
  };

  setTestMessageComplete = () => {
    this.setState((prevState) => {
      return {
        steps: prevState.steps.filter((step) => {
          return step.elementType !== "test_message";
        }),
        showTestMessageSuccess: false,
        showPhonePrompt: false,
      };
    });
  };

  previous = () => {
    const activeStepIndex =
      this.state.activeStepIndex - 1 > -1
        ? this.state.activeStepIndex - 1
        : this.state.steps.length - 1;
    this.setActiveIndex(activeStepIndex);
  };

  next = () => {
    const activeStepIndex =
      this.state.activeStepIndex + 1 < this.state.steps.length
        ? this.state.activeStepIndex + 1
        : 0;
    this.setActiveIndex(activeStepIndex);
  };

  showTestMessageSuccess = () => {
    this.setState(
      {
        showTestMessageSuccess: true,
      },
      () => {
        this.TestMessageSuccessTimeout = setTimeout(() => {
          this.setTestMessageComplete();
        }, 2000);
      },
    );
  };

  handleUpdateAccountSettings = () => {
    const { steps, activeStepIndex } = this.state;
    const activeStep = steps[activeStepIndex];
    const url = `/${activeStep.record.slug}/settings`;
    return this.props.history.push(url);
  };

  handleStepAction = (step) => {
    switch (step.type) {
      case "test_message":
        return this.setState({ showPhonePrompt: true });
      case "set_your_avatar":
        return this.props.history.push("/profile");
      case "update_account_settings":
        return this.handleUpdateAccountSettings();
      default:
        return null;
    }
  };

  handleSendTestMessage = (e) => {
    e.preventDefault();
    const { phoneNumber } = this.state;
    const { currentUser } = this.props;

    this.props.sendTestMessage({
      phoneNumber,
      contactName: currentUser.name,
    });
  };

  updatePhoneNumber = ({ target: { value: phoneNumber } }) => {
    this.setState({
      phoneNumber,
    });
  };

  renderContent = () => {
    const { activeStepIndex, steps } = this.state;
    const activeStep = steps.find((s, i) => {
      return i === activeStepIndex;
    });

    if (activeStep) {
      const step = STEP_TYPES.find((s) => {
        return s.type === get(activeStep, ["elementType"]);
      });

      return (
        <StepContent
          onClick={() => {
            return this.handleStepAction(step);
          }}
        >
          <span role="img" aria-label={step.title}>
            {step.icon}
          </span>
          <StepInfo>
            {step.account}
            <H3>
              {step.title}{" "}
              {activeStep.record && `for ${activeStep.record.name}`}
            </H3>
            <p>{step.subTitle}</p>
          </StepInfo>
        </StepContent>
      );
    }

    return <></>;
  };

  renderTestMessageStatus() {
    const { gettingStartedContainer } = this.props;
    const { isSending } = gettingStartedContainer;
    const { showTestMessageSuccess } = this.state;
    if (isSending || showTestMessageSuccess) {
      return (
        <TestMessageStatus>
          <TransitionGroup appear>
            {isSending && (
              <FadeUpIn duration={500} key="message-sending">
                <Logo animate dotColor="primary" color="transparent" />
              </FadeUpIn>
            )}
            {showTestMessageSuccess && (
              <FadeUpIn duration={500} key="message-sent">
                <p>
                  <span role="img" aria-label="sparkle">
                    ✨
                  </span>{" "}
                  You should receive a message shortly!
                </p>
              </FadeUpIn>
            )}
          </TransitionGroup>
        </TestMessageStatus>
      );
    }

    return null;
  }

  renderPhonePrompt() {
    const { phoneNumber } = this.state;
    const { errorSending } = this.props.gettingStartedContainer;

    return (
      <PhoneNumberPrompt onSubmit={this.handleSendTestMessage}>
        {errorSending && (
          <PhoneError>
            {get(errorSending, [
              "response",
              "validationErrors",
              "phones",
              "0",
              "number",
              "0",
            ])}
          </PhoneError>
        )}
        {this.renderTestMessageStatus()}
        <input
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          type="tel"
          onChange={this.updatePhoneNumber}
          placeholder="Please enter your phone number"
          value={phoneNumber}
        />
        <Button variant="contained" color="primary" type="submit">
          <Icon icon={ICONS.AIRPLANE} />
          <span>Send Test Message</span>
        </Button>
      </PhoneNumberPrompt>
    );
  }

  render() {
    const { steps, activeStepIndex, showPhonePrompt } = this.state;

    return (
      <Root dataTag="GettingStarted-Root" {...this.props}>
        <header>
          <span role="img" aria-label="wave">
            👋
          </span>

          <H3>Let&apos;s Get You Started</H3>

          {steps.length > 1 && (
            <Controls>
              <Arrow onClick={this.previous}>
                <Icon icon={ICONS.CHEVRON_LEFT} iconSize={20} />
              </Arrow>

              <Arrow onClick={this.next}>
                <Icon icon={ICONS.CHEVRON_RIGHT} iconSize={20} />
              </Arrow>
            </Controls>
          )}
        </header>

        <StepContentContainer>
          {showPhonePrompt && this.renderPhonePrompt()}
          <TransitionGroup component={null}>
            <CSSTransition
              key={activeStepIndex}
              timeout={{ enter: 500, exit: 0 }}
              classNames="fade"
              appear
            >
              {this.renderContent()}
            </CSSTransition>
          </TransitionGroup>

          {steps.length > 1 && (
            <StepIndicator>
              {steps.map((s, i) => {
                return (
                  <StepIndicatorItem
                    key={s.id}
                    onClick={() => {
                      return this.setActiveIndex(i);
                    }}
                    isActive={i === this.state.activeStepIndex}
                  />
                );
              })}
            </StepIndicator>
          )}
        </StepContentContainer>
      </Root>
    );
  }
}

export default MainView;
