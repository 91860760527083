import Honeybadger from "@honeybadger-io/js";
import get from "lodash/get";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class BullhornNovoStrategy {
  constructor({
    activeFrameAttributes,
    activeFrameParsedHtml,
    parsedHtml,
    location,
  }) {
    this.activeFrameAttributes = activeFrameAttributes;
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () =>
    /bullhorn/i.test(this.location?.href) &&
    this.parsedHtml.getElementById("novo-body");

  getParsers = () => {
    const doc = this.activeFrameParsedHtml;
    const activeFrameId = this.activeFrameAttributes.id;
    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: [
        'div[data-automation-id="firstName"] value div',
        'div[data-automation-id="lastName"] value div',
      ],
      textSelector: 'div[data-automation-id="details-card"]',
      canParse: () => /ContactWnd|CandidateWnd/i.test(activeFrameId),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "tr td.name",
      containerSelector: "tr",
      canParse: () => /MenuWnd|List/i.test(activeFrameId),
      filterChildrenFunc: childNode => childNode.className !== "id",
    });
    const tearsheetParser = new MultiContactParser({
      doc,
      namesSelector: "novo-entity-cell a",
      containerSelector: "novo-data-table-row",
      canParse: () => /Tearsheet/i.test(activeFrameId),
      filterChildrenFunc: childNode =>
        get(childNode, ["dataset", "automationId"]) !== "novo-column-id",
    });
    return [detailPageParser, listPageParser, tearsheetParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "BullhornNovoStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "BullhornNovoStrategy");
      return null;
    }
  };
}
