import SvgIcon from "@mui/material/SvgIcon";
import ICONS from "constants/icons";

export default function (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1024 1024">
      <path d={ICONS.SIGNATURE} />
    </SvgIcon>
  );
}
