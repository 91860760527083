import PropTypes from "prop-types";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import queryString from "query-string";
import AccountTreeSelector from "features/AccountTreeSelector";

function AccountFilterPopper({ anchorEl, handleClick, selected = [] }) {
  return (
    <Popper anchorEl={anchorEl} open placement="bottom-end" transition>
      {({ TransitionProps }) => {
        return (
          <Grow {...TransitionProps} timeout={350}>
            <Paper>
              <AccountTreeSelector
                accountTreeCollectionId={`/accounts/tree?${queryString.stringify(
                  {
                    role: "admin",
                  },
                )}`}
                handleClick={(ev, accountTree) => {
                  ev.preventDefault();
                  handleClick(accountTree);
                }}
                selected={selected}
              />
            </Paper>
          </Grow>
        );
      }}
    </Popper>
  );
}

AccountFilterPopper.propTypes = {
  anchorEl: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
};

export default AccountFilterPopper;
