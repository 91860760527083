import {
  Box,
  CardHeader,
  IconButton,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import type { SettingsCardHeaderProps } from "./types";
import { ExpandMoreIcon, ExpandLessIcon, LockIcon, LockOpenIcon } from "icons";

export function SettingsCardHeader({
  account,
  active: value,
  canEditLock = true,
  hideSwitch,
  locked,
  onActiveChange,
  onLockedChange,
  title,
  ...props
}: SettingsCardHeaderProps) {
  const getTooltipText = () => {
    if (!account) {
      return "";
    }
    if (!canEditLock) {
      return `Must be an admin on ${account.name} to modify`;
    }
    if (locked) {
      return `Locked for ${account.name}${account.childrenCount > 0 ? ` and ${account.childrenCount} accounts` : ""}`;
    }
    return `Prevent modifications for ${account.name}${account.childrenCount > 0 ? ` and ${account.childrenCount} accounts` : ""}`;
  };

  return (
    <CardHeader
      sx={{
        marginBottom: 1,
      }}
      title={
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          {onActiveChange && hideSwitch ? (
            <IconButton
              onClick={onActiveChange}
              data-testid="toggle-defaults-card-button"
            >
              {value ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : null}
          {onActiveChange && !hideSwitch ? (
            <Switch
              value={value}
              checked={value}
              disabled={locked}
              onChange={onActiveChange}
              color="secondary"
              data-testid="settings-card-header-switch"
            />
          ) : null}
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {title}
          </Typography>
          {onLockedChange && (
            <Tooltip title={getTooltipText()}>
              {/* div wrapper required for Tooltip to show on disabled button */}
              <div>
                <IconButton
                  {...(canEditLock
                    ? {
                        onClick: onLockedChange,
                      }
                    : {
                        disabled: true,
                      })}
                  data-testid="settings-card-header-lock-button"
                >
                  {locked ? <LockIcon /> : <LockOpenIcon />}
                </IconButton>
              </div>
            </Tooltip>
          )}
        </Box>
      }
      {...props}
    />
  );
}
