import queryString from "query-string";
import { CampaignType } from "../types";
import { textUsApiV3 } from "services";

function fetchCampaignCollection(
  endpoint: string,
  query?: { type?: CampaignType | CampaignType[]; cursor?: string },
  options?: RequestInit,
) {
  const queryParams = queryString.stringify(
    { cursor: query?.cursor, type: query?.type },
    { arrayFormat: "bracket" },
  );

  const urlWithSearch = query ? `${endpoint}?${queryParams}` : endpoint;

  return textUsApiV3.get(urlWithSearch, undefined, options);
}

export { fetchCampaignCollection };
