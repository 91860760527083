import { textUsApiV3 } from "services";

function fetchSavedReplyCollection(
  url: `/saved_replies/${string}/filter=${string}` | `/saved_replies/${string}` | `${string}/saved_replies`,
  filter?: "mine" | "all",
  query?: string,
) {
  const requestUrl = filter ? `${url}?filter=${filter}&q=${query || ""}` : url;
  return textUsApiV3.get(requestUrl);
}

export { fetchSavedReplyCollection };
