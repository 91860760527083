import PropTypes from "prop-types";
import Box from "@mui/material/Box";

import List from "components/List";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";
import withRecord from "higherOrderComponents/withRecord";

function GroupFilterCollection({
  currentAccount,
  groupFilterCollection,
  isSinglePanel,
  toggleSidebar,
}) {
  return (
    <Box>
      <List style={{ flex: "0 0 auto" }}>
        {groupFilterCollection.members.map((groupFilter) => {
          return (
            <li
              key={groupFilter.id}
              data-product-tour={`GroupFilter-${groupFilter.slug}`}
            >
              <PageSidebarNavLink
                onClick={isSinglePanel ? toggleSidebar : undefined}
                to={`/${currentAccount.slug}/groups/${groupFilter.slug}`}
              >
                {groupFilter.title}
              </PageSidebarNavLink>
            </li>
          );
        })}
      </List>
    </Box>
  );
}

GroupFilterCollection.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  groupFilterCollection: PropTypes.object.isRequired,
  isSinglePanel: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default withRecord({
  actions: ["fetch"],
  container:
    "features/Groups/GroupsSidebar/GroupFilterCollection/groupFilterCollection",
  shape: { members: [] },
  type: "groupFilterCollection",
})(GroupFilterCollection);
