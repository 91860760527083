import PropTypes from "prop-types";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Tooltip from "@mui/material/Tooltip";

import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";
import GroupFilterCollection from "features/Groups/GroupsSidebar/GroupFilterCollection";

function GroupsSidebar({
  currentAccount,
  handleOpenCreateGroupModal,
  isSinglePanel,
  toggleSearch,
  toggleSidebar,
}) {
  return (
    <PageSidebar>
      <PageSidebarHeader>
        <PageSidebarHeaderButton
          clickHandler={handleOpenCreateGroupModal}
          data-product-tour="AddGroupButton"
          icon={
            <GroupAddIcon
              aria-label="Add Group Button"
              data-testid="add-group-icon"
              fontSize="small"
            />
          }
          primary
          text="Add Group"
        />
        <Tooltip title="Search Groups">
          <IconButton
            aria-label="Search Groups Button"
            data-testid="search-groups-button"
            data-product-tour="SearchGroupsButton"
            onClick={toggleSearch}
            size="large"
          >
            <SearchIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </PageSidebarHeader>
      <GroupFilterCollection
        currentAccount={currentAccount}
        groupFilterCollectionId={
          currentAccount.groupFilters || `/${currentAccount.slug}/group_filters`
        }
        isSinglePanel={isSinglePanel}
        toggleSidebar={toggleSidebar}
      />
    </PageSidebar>
  );
}

GroupsSidebar.propTypes = {
  currentAccount: PropTypes.object.isRequired,
  handleOpenCreateGroupModal: PropTypes.func.isRequired,
  isSinglePanel: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default GroupsSidebar;
