import { ChangeEvent, useCallback, useEffect, useState } from "react";
import {
  Account,
  AccountCollection,
  AccountPlan,
  LicenseGridRow,
} from "../models/AccountPlanModels";
import getAccountLicenses from "../utils/getAccountLicenses";
import { fetchAccounts } from "features/Accounts/api";
import { useCurrentAccount } from "hooks";
import { useAccounts } from "features/Accounts/state";

const useLicenseGrid = (accountPlans: AccountPlan[]) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState<LicenseGridRow[]>([]);
  const [filteredRows, setFilteredRows] = useState<LicenseGridRow[]>([]);
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState({
    campaigns: false,
    sequences: false,
    keywords: false,
    none: false,
  });
  const [searchTerm, setSearchTerm] = useState("");

  const currentAccount = useCurrentAccount() ?? {};

  const { accountCollection, members, totalItems, view, isLoading } =
    useAccounts(currentAccount, {
      type: "messaging",
      items: 100,
    });

  const isFilterApplied = Object.values(isChecked).some((check) => {
    return check === true;
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  const formatRows = useCallback(
    (responseBody: AccountCollection | null) => {
      if (responseBody === null) {
        return null;
      }
      return responseBody.items?.map((account: Account) => {
        return {
          id: `/accounts/${account.slug}`,
          accountName: account.name,
          licenses: getAccountLicenses(account.slug, accountPlans),
        };
      });
    },
    [accountPlans],
  );

  const handleCheck = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked({ ...isChecked, [event.target.id]: event.target.checked });
  };

  const getSearchedRows = useCallback(
    async (query: string) => {
      setLoading(true);
      try {
        const response = await fetchAccounts(currentAccount.slug, {
          "q[slugOrNameOrProviderPhonesPhoneNumberCont]": query,
        });
        const body: AccountCollection = await response.json();
        const newRows = formatRows(body);
        setRows(newRows ?? []);
        setNextPage(body.page.nextUrl);
      } catch (err) {
        throw new Error(`Error fetching searched contacts: ${err}`);
      } finally {
        setLoading(false);
      }
    },
    [currentAccount.slug, formatRows],
  );

  const fetchNextPage = async () => {
    if (!nextPage) {
      return null;
    }
    const response = await fetchAccounts(currentAccount.slug, {
      q: nextPage,
    });
    const body: AccountCollection = await response.json();

    const newRows = body.items?.map((account: Account) => {
      return {
        id: account.id,
        accountName: account.name,
        licenses: getAccountLicenses(account.id, accountPlans),
      };
    });

    setRows([...rows, ...newRows]);

    return setNextPage(body.page.nextUrl);
  };

  return {
    accountCollection,
    filteredRows,
    isChecked,
    isFilterApplied,
    loading,
    members,
    rows,
    searchTerm,
    totalItems,
    view,
    fetchNextPage,
    formatRows,
    getSearchedRows,
    handleCheck,
    setIsChecked,
    setFilteredRows,
    setNextPage,
    setRows,
    setSearchTerm,
  };
};

export { useLicenseGrid };
