import Skeleton from "@mui/material/Skeleton";

export const renderSkeletonRows = (count: number, height?: string) => {
  return Array.from({ length: count }).map((_, index) => {
    return (
      <Skeleton
        key={`skeleton-${index + count}`}
        height={height || "inherit"}
      />
    );
  });
};
