import { useState, useEffect } from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import queryString from "query-string";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Tooltip from "@mui/material/Tooltip";

import BrandRegistration from "./BrandRegistration";
import AccountSearch from "features/AccountManagement/AccountSearch";
import AccountTree from "features/AccountManagement/AccountTree";
import Loader from "components/Loader";
import Logo from "components/Logo";
import H3 from "components/H3";
import P from "components/P";
import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import withRecord from "higherOrderComponents/withRecord";

// import AccountSlugs from "./AccountSlugs";
// import AccountSlugModal from "./AccountSlugModal";

function BrandInformation({
  fetchAccountCollectionRequest,
  toggleSidebar,
  location,
  history,
}) {
  // const [openModal, setOpenModal] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(true);

  // const handleModalToggle = () => setOpenModal(!openModal);

  const accountHierarchyId = queryString.stringifyUrl({
    url: "/accounts",
    query: {
      ancestors: true,
      ...queryString.parse(location.search),
    },
  });

  useEffect(() => {
    const accountId = queryString.stringifyUrl({
      url: "/accounts",
      query: { legal: true },
    });
    fetchAccountCollectionRequest(accountId, null, {
      successCallback: (response) => {
        const accounts = response?.members;
        setAccountList(accounts);
        setLoading(false);
      },
    });
  }, [accountHierarchyId]);

  if (loading) {
    return (
      <Loader isLoading={loading} aria-label="Page is Loading">
        <Logo animate dotColor="contrast" />
      </Loader>
    );
  }

  return (
    <SettingsPageWrapper>
      <PageHeader title="Brand Information" toggleSidebar={toggleSidebar} />
      <SettingsPageContent maxWidth="1024px" overflow="auto">
        <Box display="flex" my={4}>
          <Box>
            <H3>What is 10DLC?</H3>
            <P style={{ margin: ".5rem 0" }}>
              Changes are coming to SMS Providers in 2021.
            </P>
            <P>
              In the simplest terms, 10DLC will allow you to reliably message
              large groups with transparency and trust. New campaign registry
              standards aim to prevent abuse texting and spam, providing
              customers with greater levels of trust among the businesses they
              choose to communicate with via SMS.
            </P>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            minWidth="20%"
            ml={4}
          >
            <Button
              href="https://textus.com/what-is-10dlc/"
              target="_blank"
              variant="outlined"
            >
              Learn More
            </Button>
          </Box>
        </Box>
        <Box my={4}>
          <H3>Register Your Brand</H3>
          <P style={{ margin: ".5rem 0" }}>
            Complete the forms below to begin your brand registration. Visit our{" "}
            <strong>
              <Link
                color="inherit"
                href="https://help.textus.com/s/"
                target="_blank"
              >
                Help Center Documentation
              </Link>
            </strong>{" "}
            to find FAQs.
          </P>
          <Divider />
          <Box m="2rem 0">
            {accountList.map(({ name, slug, id }) => {
              return (
                <BrandRegistration name={name} id={id} slug={slug} key={id} />
              );
            })}
          </Box>
        </Box>
        {/* <Box my={4}>
          <Card style={{ minHeight: "20rem" }} variant="outlined">
            <Box borderBottom={1} color="grey.300" display="flex" p="1rem">
              <H3 style={{ alignSelf: "center" }}>Account Slugs</H3>
              <AccountSlugModal
                handleAccountSlugModalToggle={handleModalToggle}
                openAccountSlugModal={openModal}
              />
            </Box>
            <AccountSlugs />
          </Card>
        </Box> */}
        <Box my={4}>
          <Card variant="outlined" style={{ height: "500px" }}>
            <Box
              borderBottom={1}
              color="grey.300"
              display="flex"
              justifyContent="space-between"
              p="1rem"
            >
              <H3>Accounts</H3>
              {/* TODO: Add API Call for Account Tree Export  */}
              <Tooltip title="Coming Soon">
                <span>
                  <Button disabled variant="outlined">
                    Export
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <AccountSearch history={history} location={location} />
            <Box
              aria-label="Account Tree"
              height="calc(100% - 55px)"
              width="100%"
            >
              <AccountTree
                accountHierarchyId={accountHierarchyId}
                brandInformation
              />
            </Box>
          </Card>
        </Box>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

BrandInformation.propTypes = {
  fetchAccountCollectionRequest: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountSettings/BrandInformation/accountCollection",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountCollection",
  }),
)(BrandInformation);
