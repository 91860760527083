import {
  DataGridPro,
  GridColDef,
  GridComparatorFn,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";

import { useLocation } from "react-router-dom";

import { MenuButton, StyledCellLink } from "./components";
import { SequencesDataGridProps } from "props/SequenceDataGridProps";
import { SEQUENCES_TABS } from "features/Sequences/screens/SequencesOverview/constants";

const updatedAtComparator: GridComparatorFn<string> = (a, b) => {
  /* Function allows sorting of strings such as "6 hours ago"
   * and "2 weeks ago" by assigning a numerical value based on
   * the dateStrings (array order matters), and first sorting by
   * that and then sorting by the value of the numbers in the
   * strings using localeCompare.
   */
  const dateStrings = Object.freeze([
    "second",
    "seconds",
    "minute",
    "minutes",
    "hour",
    "hours",
    "day",
    "days",
    "month",
    "months",
    "year",
    "years",
  ]);

  const getStringIndex = (value: string) => {
    return dateStrings
      .map((string, index) => {
        return value.includes(string) ? index : undefined;
      })
      .find((number) => {
        return number !== undefined;
      });
  };

  if ((getStringIndex(a) ?? 0) - (getStringIndex(b) ?? 0)) {
    return (getStringIndex(a) ?? 0) - (getStringIndex(b) ?? 0);
  }

  return a.localeCompare(b, undefined, { numeric: true });
};

export function SequencesDataGrid({
  isLoading,
  isSearchEntered,
  rows,
  selectedTab,
  handleArchiveSequenceClick,
  handleDuplicateSequenceClick,
}: SequencesDataGridProps) {
  // ==== FIELDS ==== //
  const { pathname: sequencesPath } = useLocation();

  const { archive } = SEQUENCES_TABS;

  const getEmptyText = () => {
    if (isSearchEntered) {
      return "No results found";
    }

    return selectedTab === archive
      ? "No sequences archived yet"
      : "No sequences created yet";
  };

  // ==== METHODS ==== //
  const renderNameLink = (params: GridRenderCellParams) => {
    const { row: sequence } = params;
    const { id: sequenceId, name: sequenceName } = sequence;
    return (
      <StyledCellLink to={`${sequencesPath}/${sequenceId}`}>
        {sequenceName}
      </StyledCellLink>
    );
  };

  // ==== COLUMN DATA ==== //
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: 160,
      renderCell: renderNameLink,
    },
    {
      field: "stepCount",
      headerName: "No. steps",
      type: "number",
      flex: 1,
    },
    {
      field: "pendingCount",
      headerName: "Pending",
      type: "number",
      flex: 1,
    },
    {
      field: "contactCount",
      headerName: "All contacts",
      type: "number",
      flex: 1,
    },
    {
      field: "deliveryRate",
      headerName: "Delivery rate",
      type: "number",
      flex: 1,
      minWidth: 60,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `${params.value.toLocaleString()}%`;
      },
    },
    {
      field: "engagementRate",
      headerName: "Engagement rate",
      type: "number",
      flex: 1,
      minWidth: 100,
      valueFormatter: (params: GridValueFormatterParams<number>) => {
        return `${params.value.toLocaleString()}%`;
      },
    },
    {
      field: "createdBy",
      headerName: "Created by",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "updatedAt",
      headerName: "Date modified",
      flex: 1,
      minWidth: 100,
      sortComparator: updatedAtComparator,
    },
    {
      field: "moreVertIcon",
      headerName: "",
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      filterable: false,
      hideSortIcons: true,
      width: 10,
      align: "center",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <MenuButton
            handleArchiveSequenceClick={handleArchiveSequenceClick}
            handleDuplicateSequenceClick={handleDuplicateSequenceClick}
            sequence={params.row}
          />
        );
      },
    },
  ];

  const getColumns = () => {
    const archiveTabColumns = columns.filter((column) => {
      return !["activeSwitch", "moreVertIcon"].includes(column.field);
    });

    return selectedTab === archive ? archiveTabColumns : columns;
  };

  // ==== RENDER ==== //
  return (
    <DataGridPro
      columnBuffer={2}
      columns={getColumns()}
      disableRowSelectionOnClick
      hideFooter
      loading={isLoading}
      rows={rows}
      getRowId={(row) => {
        return row.id;
      }}
      initialState={{
        sorting: {
          sortModel: [{ field: "updatedAt", sort: "asc" }],
        },
      }}
      localeText={{
        noRowsLabel: getEmptyText(),
      }}
    />
  );
}
