import $ from "jquery";
import getContactDataFromText from "phoneExtraction/utils/getContactDataFromText";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";

export default class MultiContactParser {
  constructor({
    doc,
    canParse,
    getNamesFunction,
    namesSelector,
    containerSelector,
    searchTextFunction,
    getMetadataFunction,
    filterChildrenFunc = () => true,
  }) {
    this.doc = doc;
    this.canParse = canParse;
    this.getNamesFunction = getNamesFunction;
    this.namesSelector = namesSelector;
    this.containerSelector = containerSelector;
    this.filterChildrenFunc = filterChildrenFunc;
    const defaultSearchTextFunction = node =>
      Array.from(
        $(node)
          .closest(this.containerSelector)
          .children(),
      )
        .filter(this.filterChildrenFunc)
        .map(child => {
          const clonedNode = child.cloneNode(true);
          clonedNode
            .querySelectorAll(`[style*="display: none"]`)
            .forEach(n => n.remove());
          return clonedNode.textContent;
        })
        .join(", ");
    this.getMetadataFunction = getMetadataFunction;
    this.searchTextFunction = searchTextFunction || defaultSearchTextFunction;
  }

  getNames = node => {
    if (this.getNamesFunction) return this.getNamesFunction(node);
    return getNamesFromText(node.textContent.trim());
  };

  getContactData = () =>
    Array.from($(this.doc).find(this.namesSelector))
      .map(node =>
        getContactDataFromText(this.getNames(node))(
          this.searchTextFunction(node),
        ),
      )
      .reduce((acc, i) => [...acc, ...i], []);

  parse = () => {
    if (!this.canParse()) return null;
    if (this.getMetadataFunction) return this.getMetadataFunction();
    const contactData = this.getContactData();
    if (contactData.length > 0) {
      return contactData;
    }
    return null;
  };
}
