import type { Formik, FormikProps } from "formik";
import type { ComponentProps } from "react";
import { Account } from "@tesseract/core";
import type { UsersAutocompleteProps } from "features/Users";

export type DuplicateRulesFormValues = {
  matchDuplicatesBasedOn: NonNullable<
    Account.Settings["importRules"]["value"]
  >["matchDuplicatesBasedOn"];
  overwrite: NonNullable<Account.Settings["importRules"]["value"]>["overwrite"];
};

export type DuplicateRulesFormProps = ComponentProps<
  typeof Formik<DuplicateRulesFormValues>
>;

export type DuplicateRulesFieldsProps = Omit<
  FormikProps<DuplicateRulesFormValues> & {
    usersAutocompleteProps?: Partial<
      Omit<
        UsersAutocompleteProps,
        "renderInput" | "renderOption" | "value" | "onChange" | "onBlur"
      >
    >;
  },
  "handleReset" | "prependOptions"
>;
