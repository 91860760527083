import { useMemo } from "react";
import { Link } from "react-router-dom";

import { Box, Theme, Typography, styled, useMediaQuery } from "@mui/material";
import {
  DataGridPro,
  GridCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";

import { format } from "date-fns";
import { formatValues } from "../../SendCampaign/sharedComponents/constants/timeOptions";
import { RecurringCampaignProps } from "./types";
import CampaignMessage from "./CampaignMessage";
import { useTimeZones } from "hooks";
import { columnTooltips } from "features/Campaigns/constants";

const StyledLink = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.text.hyperlink,
    fontWeight: 700,
  };
});

function RecurringCampaign({
  campaign,
  currentAccount,
  handlePageChange,
  group,
  groupName,
  instanceCollection,
  isLoading,
}: RecurringCampaignProps) {
  const { attachments, schedule, lastSentAt } = campaign;
  const {
    sentCount,
    deliveryRate,
    totalRecipients,
    failureRate,
    replyRate,
    skipped,
    sendDate,
  } = columnTooltips;
  const { slug } = currentAccount;
  const { totalItems = 0 } = instanceCollection ?? {};

  const { abbreviatedAccountTimeZone } = useTimeZones();
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const {
    formattedEndDate,
    formattedFrequency,
    formattedSendDate,
    formattedTime,
  } = formatValues(schedule);

  const [, groupId] = group.split("/groups/");

  const columns = [
    {
      field: "dateSent",
      headerName: "Date sent",
      flex: 1,
      description: sendDate,
      valueGetter: ({ row }: GridCellParams) => {
        return row.scheduledAt;
      },
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MMM do, yyyy");
      },
    },
    {
      field: "totalRecipients",
      headerName: "Total recipients",
      flex: 1,
      description: totalRecipients,
      type: "number",
      valueGetter: ({ row }: GridCellParams) => {
        return row.deliveryStatistics.total;
      },
    },
    {
      field: "skippedRecipients",
      headerName: "Skipped recipients",
      flex: 1,
      description: skipped,
      type: "number",
      valueGetter: ({ row }: GridCellParams) => {
        return row.deliveryStatistics.skipped;
      },
    },
    {
      field: "sentCount",
      headerName: "Sent count",
      flex: 1,
      description: sentCount,
      type: "number",
      valueGetter: ({ row }: GridCellParams) => {
        return row.deliveryStatistics.sent;
      },
    },
    {
      field: "deliveryRate",
      headerName: "Delivery %",
      flex: 1,
      description: deliveryRate,
      type: "number",
      valueGetter: ({ row }: GridCellParams) => {
        const { delivered, sent } = row.deliveryStatistics;
        return sent > 0 ? Math.round((delivered / sent) * 100) : 0;
      },
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return `${value}%`;
      },
    },
    {
      field: "replyRate",
      headerName: "Reply %",
      flex: 1,
      description: replyRate,
      type: "number",
      valueGetter: ({ row }: GridCellParams) => {
        const { replied, delivered } = row.deliveryStatistics;
        return delivered > 0 ? Math.round((replied / delivered) * 100) : 0;
      },
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return `${value}%`;
      },
    },
    {
      field: "failureRate",
      headerName: "Failure %",
      flex: 1,
      description: failureRate,
      type: "number",
      valueGetter: ({ row }: GridCellParams) => {
        const { failed, total } = row.deliveryStatistics;
        return total > 0 ? Math.round((failed / total) * 100) : 0;
      },
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return `${value}%`;
      },
    },
  ];

  const displayedColumns = mobileScreen ? columns.slice(0, 1) : columns;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      height="100%"
      sx={{ overflowY: "auto" }}
    >
      <Box m={2} display="flex" justifyContent="space-between">
        <Box>
          {lastSentAt ? (
            <Box>
              <Typography variant="body2">Last sent</Typography>
              <Typography variant="body2" fontWeight={700}>
                {format(
                  new Date(lastSentAt),
                  `EEEE, MMM do 'at' h:mmaaa '${abbreviatedAccountTimeZone}'`,
                )}
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant="body2">Scheduled</Typography>
              <Typography variant="body2" fontWeight={700}>
                {formattedSendDate}
              </Typography>
            </Box>
          )}
          <Typography variant="body2" fontWeight={700}>
            Ends {formattedEndDate}
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            gap="0.25rem"
            alignItems="center"
          >
            <Typography variant="body2">Sending to group:</Typography>
            <StyledLink to={`/${slug}/groups/account/${groupId}`}>
              {groupName}
            </StyledLink>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Box
          display="flex"
          justifyContent="space-evenly"
          alignItems="center"
          width="100%"
          flexDirection={mobileScreen ? "column" : "row"}
        >
          <Box
            m={2}
            maxWidth="160px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="overline" color="text.secondary">
              Campaigns Sent
            </Typography>
            <Typography variant="dataSmall" color="primary" textAlign="center">
              {totalItems}
            </Typography>
          </Box>
          <Box
            m={2}
            maxWidth="160px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="overline" color="text.secondary">
              Sends on
            </Typography>
            <Typography variant="dataSmall" color="primary" textAlign="center">
              {formattedFrequency} on {formattedSendDate}
            </Typography>
          </Box>
          <Box
            m={2}
            maxWidth="160px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography variant="overline" color="text.secondary">
              Time to Send
            </Typography>
            <Typography variant="dataSmall" color="primary" textAlign="center">
              {`${formattedTime} ${abbreviatedAccountTimeZone}`}
            </Typography>
          </Box>
        </Box>
        <Box
          alignItems="flex-end"
          display="flex"
          flexDirection="column"
          margin={mobileScreen ? "0.5rem 1rem" : "0.5rem 0 0 0"}
        >
          <CampaignMessage
            attachment={attachments?.length ? attachments[0] : undefined}
            campaign={campaign}
          />
        </Box>
      </Box>
      <Box marginTop="1rem" width="100%" height="100%">
        <Box height="100%" width="100%">
          <DataGridPro
            columns={displayedColumns}
            disableVirtualization
            rows={instanceCollection?.members ?? []}
            autoHeight
            disableRowSelectionOnClick
            loading={isLoading}
            pagination
            paginationMode="server"
            pageSizeOptions={[]}
            rowCount={totalItems}
            onPaginationModelChange={handlePageChange}
            sx={(theme) => {
              return { borderTop: `1px solid ${theme.palette.divider}` };
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default RecurringCampaign;
