import { Component } from "react";
import Root from "features/AccountSettings/containers/OptOutAnalytics/components/Root";
import withRecord from "higherOrderComponents/withRecord";

class OptOutAnalytics extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "AccountSettings/containers/OptOutAnalytics",
  shape: {},
  type: "optOutAnalytics",
})(OptOutAnalytics);
