import { Component } from "react";
import { compose } from "redux";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class UserCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/ActiveConversation/containers/UserCollection",
    shape: { members: [] },
    type: "userCollection",
  }),
)(UserCollection);
