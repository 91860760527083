export default function BlankSearchIcon(displayMode: { mode: string }) {
  const { mode } = displayMode;

  return mode === "light" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="118"
      viewBox="0 0 124 118"
      fill="none"
      data-testid="blank-search-icon"
    >
      <path
        d="M58.0399 18.5393C74.9399 18.5393 88.6899 32.2893 88.6899 49.1893C88.6899 66.0893 74.9399 79.8393 58.0399 79.8393C41.1399 79.8393 27.3899 66.0893 27.3899 49.1893C27.3899 32.2893 41.1399 18.5393 58.0399 18.5393ZM58.0399 11.2793C37.0999 11.2793 20.1299 28.2493 20.1299 49.1893C20.1299 70.1293 37.0999 87.0993 58.0399 87.0993C78.9799 87.0993 95.9499 70.1293 95.9499 49.1893C95.9499 28.2493 78.9799 11.2793 58.0399 11.2793Z"
        fill="#AFBDC3"
      />
      <path
        d="M58.0399 18.5393C74.9399 18.5393 88.6899 32.2893 88.6899 49.1893C88.6899 66.0893 74.9399 79.8393 58.0399 79.8393C41.1399 79.8393 27.3899 66.0893 27.3899 49.1893C27.3899 32.2893 41.1399 18.5393 58.0399 18.5393ZM58.0399 11.2793C37.0999 11.2793 20.1299 28.2493 20.1299 49.1893C20.1299 70.1293 37.0999 87.0993 58.0399 87.0993C78.9799 87.0993 95.9499 70.1293 95.9499 49.1893C95.9499 28.2493 78.9799 11.2793 58.0399 11.2793Z"
        fill="#AFBDC3"
      />
      <path
        d="M58.0399 18.5393C74.9399 18.5393 88.6899 32.2893 88.6899 49.1893C88.6899 66.0893 74.9399 79.8393 58.0399 79.8393C41.1399 79.8393 27.3899 66.0893 27.3899 49.1893C27.3899 32.2893 41.1399 18.5393 58.0399 18.5393ZM58.0399 11.2793C37.0999 11.2793 20.1299 28.2493 20.1299 49.1893C20.1299 70.1293 37.0999 87.0993 58.0399 87.0993C78.9799 87.0993 95.9499 70.1293 95.9499 49.1893C95.9499 28.2493 78.9799 11.2793 58.0399 11.2793Z"
        fill="#94BCF9"
      />
      <path
        d="M58.0398 75.8902C72.7858 75.8902 84.7398 63.9362 84.7398 49.1902C84.7398 34.4442 72.7858 22.4902 58.0398 22.4902C43.2938 22.4902 31.3398 34.4442 31.3398 49.1902C31.3398 63.9362 43.2938 75.8902 58.0398 75.8902Z"
        fill="#6FA5F8"
      />
      <path
        d="M73.8202 46.3405C72.6302 39.8605 67.5302 34.7405 61.0602 33.5105C59.4102 33.2005 58.2002 31.7805 58.2002 30.1105C58.2002 27.9105 60.2202 26.3005 62.3802 26.7105C71.6302 28.4805 78.9102 35.7905 80.6202 45.0605C81.0202 47.2205 79.4102 49.2205 77.2202 49.2205C75.5302 49.2205 74.1202 48.0005 73.8102 46.3405H73.8202Z"
        fill="white"
      />
      <path
        d="M121.75 102.58L92.1002 72.9297C89.4202 76.7697 86.1102 80.1297 82.3102 82.8697L112.45 113.01C113.75 114.31 115.45 114.96 117.16 114.96C118.87 114.96 120.57 114.31 121.87 113.01C122.03 112.85 122.15 112.67 122.29 112.5C121.93 113.19 121.47 113.82 120.9 114.39C119.44 115.85 117.49 116.66 115.43 116.66C113.37 116.66 111.42 115.85 109.96 114.39L80.0002 84.4297C79.6502 84.6497 79.2902 84.8597 78.9302 85.0697L109.1 115.24C110.79 116.93 113.04 117.86 115.43 117.86C117.82 117.86 120.07 116.93 121.76 115.24C123.45 113.55 124.38 111.3 124.38 108.91C124.38 106.52 123.45 104.27 121.76 102.58H121.75Z"
        fill="#94BCF9"
      />
      <path
        d="M21.6698 10.5603C21.6698 10.9103 21.4198 11.1603 21.0198 11.1803C19.8998 11.2403 18.8098 11.4203 17.7398 11.7803C14.1398 13.0003 12.1498 15.5003 11.7798 19.2903C11.7498 19.6303 11.7298 19.9603 11.7198 20.3003C11.6998 20.8303 11.4998 21.1203 11.1098 21.1303C10.7298 21.1303 10.5098 20.8403 10.4898 20.3003C10.4198 18.8603 10.0798 17.4803 9.44979 16.1803C8.07979 13.3503 5.75979 11.8203 2.70979 11.3203C2.23979 11.2403 1.75979 11.2003 1.27979 11.1703C0.78979 11.1303 0.50979 10.9103 0.51979 10.5203C0.52979 10.1503 0.79979 9.93029 1.26979 9.91029C3.06979 9.83029 4.74979 9.33029 6.33979 8.47029C8.53979 7.27029 9.78979 5.38029 10.2998 2.99029C10.4498 2.28029 10.4498 1.53029 10.4898 0.790289C10.5098 0.300289 10.7098 0.0102887 11.0998 0.000288747C11.4798 -0.00971125 11.7098 0.240289 11.7398 0.710289C11.8398 2.44029 12.2298 4.09029 13.0698 5.62029C14.4598 8.14029 16.6498 9.47029 19.4598 9.83029C19.9598 9.89029 20.4698 9.90029 20.9698 9.93029C21.3998 9.96029 21.6698 10.1903 21.6798 10.5503L21.6698 10.5603Z"
        fill="#6FA3F6"
      />
      <path
        d="M22.4198 25.5995C22.4198 25.7895 22.2898 25.9195 22.0698 25.9295C21.4698 25.9595 20.8798 26.0595 20.3098 26.2495C18.3798 26.9095 17.3098 28.2495 17.1098 30.2695C17.0898 30.4495 17.0798 30.6295 17.0798 30.8095C17.0798 31.0995 16.9598 31.2495 16.7498 31.2495C16.5498 31.2495 16.4298 31.0995 16.4198 30.8095C16.3798 30.0395 16.1998 29.2995 15.8598 28.6095C15.1198 27.0995 13.8798 26.2795 12.2498 26.0095C11.9998 25.9695 11.7398 25.9495 11.4798 25.9295C11.2198 25.9095 11.0698 25.7895 11.0698 25.5795C11.0698 25.3795 11.2198 25.2695 11.4698 25.2495C12.4398 25.2095 13.3398 24.9395 14.1898 24.4795C15.3698 23.8395 16.0398 22.8295 16.3098 21.5395C16.3898 21.1595 16.3898 20.7595 16.4098 20.3595C16.4198 20.0995 16.5298 19.9395 16.7398 19.9395C16.9398 19.9395 17.0698 20.0695 17.0798 20.3195C17.1298 21.2495 17.3398 22.1295 17.7898 22.9495C18.5398 24.2995 19.7098 25.0095 21.2098 25.2095C21.4798 25.2395 21.7498 25.2495 22.0198 25.2595C22.2498 25.2695 22.3998 25.3995 22.3998 25.5895L22.4198 25.5995Z"
        fill="#6FA3F6"
      />
      <path
        d="M26.6201 16.7191C26.6201 16.8391 26.5401 16.9291 26.4001 16.9291C26.0201 16.9491 25.6401 17.0091 25.2801 17.1391C24.0501 17.5591 23.3601 18.4191 23.2301 19.7191C23.2201 19.8291 23.2101 19.9491 23.2101 20.0691C23.2101 20.2491 23.1301 20.3491 23.0001 20.3491C22.8701 20.3491 22.8001 20.2491 22.7901 20.0691C22.7701 19.5791 22.6501 19.0991 22.4301 18.6591C21.9601 17.6891 21.1601 17.1691 20.1201 16.9891C19.9601 16.9591 19.7901 16.9491 19.6301 16.9391C19.4601 16.9291 19.3701 16.8491 19.3701 16.7191C19.3701 16.5891 19.4701 16.5191 19.6301 16.5091C20.2501 16.4791 20.8201 16.3091 21.3701 16.0191C22.1201 15.6091 22.5501 14.9591 22.7301 14.1391C22.7801 13.8991 22.7801 13.6391 22.8001 13.3891C22.8001 13.2191 22.8701 13.1191 23.0101 13.1191C23.1401 13.1191 23.2201 13.1991 23.2301 13.3591C23.2601 13.9491 23.4001 14.5191 23.6901 15.0391C24.1701 15.8991 24.9201 16.3591 25.8801 16.4791C26.0501 16.4991 26.2301 16.4991 26.4001 16.5091C26.5501 16.5091 26.6401 16.5991 26.6401 16.7191H26.6201Z"
        fill="#6FA3F6"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="124"
      height="118"
      viewBox="0 0 124 118"
      fill="none"
    >
      <g clipPath="url(#clip0_9640_44998)">
        <path
          d="M58.0999 18.5591C75.0199 18.5591 88.7799 32.3191 88.7799 49.2391C88.7799 66.1591 75.0199 79.9191 58.0999 79.9191C41.1799 79.9191 27.4199 66.1591 27.4199 49.2391C27.4199 32.3191 41.1799 18.5591 58.0999 18.5591ZM58.0999 11.2891C37.1399 11.2891 20.1499 28.2791 20.1499 49.2391C20.1499 70.1991 37.1399 87.1891 58.0999 87.1891C79.0599 87.1891 96.0499 70.1991 96.0499 49.2391C96.0499 28.2791 79.0599 11.2891 58.0999 11.2891Z"
          fill="#AFBDC3"
        />
        <path
          d="M58.0999 18.5591C75.0199 18.5591 88.7799 32.3191 88.7799 49.2391C88.7799 66.1591 75.0199 79.9191 58.0999 79.9191C41.1799 79.9191 27.4199 66.1591 27.4199 49.2391C27.4199 32.3191 41.1799 18.5591 58.0999 18.5591ZM58.0999 11.2891C37.1399 11.2891 20.1499 28.2791 20.1499 49.2391C20.1499 70.1991 37.1399 87.1891 58.0999 87.1891C79.0599 87.1891 96.0499 70.1991 96.0499 49.2391C96.0499 28.2791 79.0599 11.2891 58.0999 11.2891Z"
          fill="#AFBDC3"
        />
        <path
          d="M58.0999 18.5591C75.0199 18.5591 88.7799 32.3191 88.7799 49.2391C88.7799 66.1591 75.0199 79.9191 58.0999 79.9191C41.1799 79.9191 27.4199 66.1591 27.4199 49.2391C27.4199 32.3191 41.1799 18.5591 58.0999 18.5591ZM58.0999 11.2891C37.1399 11.2891 20.1499 28.2791 20.1499 49.2391C20.1499 70.1991 37.1399 87.1891 58.0999 87.1891C79.0599 87.1891 96.0499 70.1991 96.0499 49.2391C96.0499 28.2791 79.0599 11.2891 58.0999 11.2891Z"
          fill="#7B82AA"
        />
        <path
          d="M58.1001 75.9698C72.8627 75.9698 84.8301 64.0023 84.8301 49.2398C84.8301 34.4772 72.8627 22.5098 58.1001 22.5098C43.3375 22.5098 31.3701 34.4772 31.3701 49.2398C31.3701 64.0023 43.3375 75.9698 58.1001 75.9698Z"
          fill="#52536C"
        />
        <path
          d="M73.9 46.3898C72.7 39.9098 67.6 34.7798 61.13 33.5498C59.48 33.2298 58.27 31.8198 58.27 30.1398C58.27 27.9398 60.29 26.3298 62.45 26.7398C71.71 28.5098 79 35.8298 80.71 45.1098C81.11 47.2698 79.5 49.2798 77.31 49.2798C75.62 49.2798 74.21 48.0598 73.9 46.3898Z"
          fill="#92BAF7"
        />
        <path
          d="M121.87 102.69L92.1902 73.0098C89.5002 76.8498 86.1902 80.2198 82.3902 82.9598L112.56 113.13C113.86 114.43 115.57 115.08 117.27 115.08C118.97 115.08 120.68 114.43 121.98 113.13C122.14 112.97 122.26 112.79 122.4 112.62C122.04 113.31 121.58 113.94 121.01 114.51C119.55 115.97 117.6 116.78 115.53 116.78C113.46 116.78 111.52 115.97 110.05 114.51L80.0602 84.5198C79.7102 84.7398 79.3502 84.9498 78.9902 85.1598L109.19 115.36C110.88 117.05 113.13 117.98 115.53 117.98C117.93 117.98 120.17 117.05 121.87 115.35C123.57 113.65 124.49 111.41 124.49 109.02C124.49 106.63 123.56 104.38 121.86 102.68L121.87 102.69Z"
          fill="#7B82AA"
        />
        <path
          d="M21.69 10.5701C21.69 10.9201 21.44 11.1701 21.04 11.1901C19.92 11.2501 18.82 11.4301 17.76 11.7901C14.16 13.0201 12.16 15.5201 11.79 19.3101C11.76 19.6501 11.74 19.9801 11.73 20.3201C11.71 20.8501 11.5 21.1501 11.12 21.1501C10.74 21.1501 10.52 20.8601 10.5 20.3201C10.43 18.8801 10.09 17.5001 9.45002 16.2001C8.08002 13.3701 5.76002 11.8401 2.70002 11.3401C2.23002 11.2601 1.75002 11.2201 1.27002 11.1901C0.780025 11.1501 0.500025 10.9301 0.510025 10.5401C0.520025 10.1701 0.790025 9.95006 1.26002 9.93005C3.07002 9.85005 4.75002 9.35005 6.34002 8.49005C8.54002 7.29005 9.79003 5.40005 10.31 3.00005C10.46 2.29005 10.46 1.53005 10.5 0.800054C10.52 0.310054 10.72 0.0200543 11.11 0.0100543C11.49 5.43008e-05 11.72 0.250054 11.75 0.730054C11.85 2.46005 12.24 4.12005 13.08 5.64005C14.47 8.16005 16.66 9.49005 19.48 9.86005C19.98 9.92005 20.49 9.93005 20.99 9.96005C21.42 9.99005 21.69 10.2201 21.7 10.5801L21.69 10.5701Z"
          fill="#92BAF7"
        />
        <path
          d="M22.4398 25.6192C22.4398 25.8092 22.3098 25.9392 22.0898 25.9492C21.4898 25.9792 20.8998 26.0792 20.3298 26.2692C18.3998 26.9292 17.3298 28.2692 17.1298 30.2992C17.1098 30.4792 17.0998 30.6592 17.0998 30.8392C17.0998 31.1292 16.9798 31.2792 16.7698 31.2792C16.5698 31.2792 16.4498 31.1292 16.4398 30.8392C16.3998 30.0692 16.2198 29.3292 15.8798 28.6292C15.1398 27.1192 13.8998 26.2892 12.2698 26.0192C12.0198 25.9792 11.7598 25.9592 11.4998 25.9392C11.2398 25.9192 11.0898 25.7992 11.0898 25.5892C11.0898 25.3892 11.2398 25.2692 11.4898 25.2592C12.4598 25.2192 13.3598 24.9492 14.2098 24.4892C15.3898 23.8492 16.0598 22.8392 16.3398 21.5492C16.4198 21.1692 16.4198 20.7592 16.4398 20.3692C16.4498 20.1092 16.5598 19.9492 16.7698 19.9492C16.9698 19.9492 17.0998 20.0792 17.1098 20.3292C17.1598 21.2592 17.3698 22.1392 17.8198 22.9592C18.5698 24.3092 19.7398 25.0192 21.2498 25.2192C21.5198 25.2492 21.7898 25.2592 22.0598 25.2692C22.2898 25.2792 22.4398 25.4092 22.4398 25.5992V25.6192Z"
          fill="#92BAF7"
        />
        <path
          d="M26.6499 16.7391C26.6499 16.8591 26.5699 16.9491 26.4299 16.9491C26.0499 16.9691 25.6699 17.0291 25.3099 17.1591C24.0699 17.5791 23.3899 18.4391 23.2599 19.7391C23.2499 19.8491 23.2399 19.9691 23.2399 20.0891C23.2399 20.2691 23.1599 20.3691 23.0299 20.3691C22.8999 20.3691 22.8299 20.2691 22.8199 20.0891C22.7999 19.5991 22.6799 19.1191 22.4599 18.6791C21.9899 17.7091 21.1899 17.1791 20.1499 17.0091C19.9899 16.9791 19.8199 16.9691 19.6599 16.9591C19.4899 16.9491 19.3999 16.8691 19.3999 16.7391C19.3999 16.6091 19.4999 16.5391 19.6599 16.5291C20.2799 16.4991 20.8599 16.3291 21.3999 16.0291C22.1599 15.6191 22.5799 14.9691 22.7599 14.1491C22.8099 13.8991 22.8099 13.6491 22.8299 13.3891C22.8299 13.2191 22.8999 13.1191 23.0399 13.1191C23.1699 13.1191 23.2499 13.1991 23.2599 13.3691C23.2899 13.9591 23.4299 14.5291 23.7199 15.0491C24.1999 15.9091 24.9499 16.3691 25.9099 16.4991C26.0799 16.5191 26.2599 16.5191 26.4299 16.5291C26.5799 16.5291 26.6699 16.6191 26.6699 16.7391H26.6499Z"
          fill="#92BAF7"
        />
      </g>
      <defs>
        <clipPath id="clip0_9640_44998">
          <rect
            width="124"
            height="117.98"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
