import { Component } from "react";
import PropTypes from "prop-types";

import ExtensionModal from "features/ExtensionPrompts/components/ExtensionModal";

export default class ExtensionPromptsRoot extends Component {
  static propTypes = {
    extensionPromptsContainer: PropTypes.object.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    setExtensionPrompts: PropTypes.func.isRequired,
  };

  handleClose = () => {
    this.props.setExtensionPrompts({
      type: undefined,
    });
  };

  render() {
    const {
      fullScreen,
      extensionPromptsContainer: {
        substate: { type },
      },
    } = this.props;
    return (
      <>
        <ExtensionModal
          active={type === "noNumbersFound"}
          autoDismiss
          closeModal={this.handleClose}
          content="The extension did not find any phone numbers on the page"
          fullScreen={fullScreen}
          title="No Numbers Found"
        />
        <ExtensionModal
          active={type === "contactsNotEnabled"}
          closeModal={this.handleClose}
          content="Contacts cannot be imported to your current account. Please select an account with contacts enabled. You can also set a default account to avoid this in the future."
          fullScreen={fullScreen}
          title="Contacts Not Enabled"
        />
      </>
    );
  }
}
