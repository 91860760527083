import { AssignContactResponse } from "../interfaces/AssignContactResponse";
import { textUsApiV3 } from "services";

interface Props {
  conversationId: string;
  contactId: string;
}

const assignContactToConversationApi = async ({
  conversationId,
  contactId,
}: Props): Promise<AssignContactResponse> => {
  const result = await textUsApiV3.put(
    `/conversations/${conversationId}/assign_contact`,
    {
      assignedContact: `/contacts/${contactId}`,
    },
  );
  return result.json();
};

export { assignContactToConversationApi };
