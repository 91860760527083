import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import {
  selectAppSettings,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import Root from "features/AccountSettings/containers/Integrations/components/Root";
import withRecord from "higherOrderComponents/withRecord";

class Integrations extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    appSettings: selectAppSettings(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/AccountSettings/containers/IntegrationCollection",
    shape: { members: [] },
    type: "integrationCollection",
  }),
  withRecord({
    actions: ["create", "update", "delete"],
    container: "features/AccountSettings/containers/Integrations",
    type: "integration",
  }),
  withConnect,
)(Integrations);
