import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

import { compose } from "redux";
import withSizes from "react-sizes";

import { Formik, Field, Form } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import breakpoints from "utils/styles/breakpoints";

import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

function EditGroupNameModal({
  closeModal,
  fullScreen,
  groupName,
  handleEdit,
  visible,
}) {
  return (
    <Modal
      fullScreen={fullScreen}
      transitionIn={visible}
      ariaLabel="Edit Group Name Modal"
      closeModal={closeModal}
    >
      <ModalHeader aria-label="Edit Group Name" closeModal={closeModal}>
        Edit Group Name
      </ModalHeader>
      <ModalContent maxWidth="100%">
        <Box minWidth="360px">
          <Formik
            initialValues={{ name: groupName }}
            onSubmit={(values) => {
              return handleEdit(values);
            }}
          >
            {({ isSubmitting, isValid, values: { name } }) => {
              return (
                <Form>
                  <Field type="text" name="name">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          autoFocus
                          fullWidth
                          inputProps={{ "data-lpignore": true }}
                          label="Group Name"
                          id="group-name"
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                  <Box textAlign="right" mt={1}>
                    <Button
                      aria-label="Cancel"
                      color="primary"
                      onClick={closeModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      aria-label="Save Group Name"
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={
                        !isValid || isSubmitting || isEqual(name, groupName)
                      }
                    >
                      Save
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  );
}

EditGroupNameModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  handleEdit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default compose(
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
)(EditGroupNameModal);
