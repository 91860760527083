import { useEffect, useState, MouseEvent, useCallback } from "react";
import {
  AvatarGroup,
  Box,
  IconButton,
  Popover,
  Tooltip,
  Typography,
} from "@mui/material";
import type {
  AssignedUser,
  ConversationAssignment,
  ConversationAssignmentsOptionProps,
} from "./types";
import { GroupAddIcon } from "icons";
import UserCollection from "features/ActiveConversation/containers/UserCollection";
import { fetchConversationAssignments } from "features/ActiveConversation/api/fetchConversationAssignments";
import UserAvatar from "components/Avatar";
import type { Conversation } from "features/Inbox/containers/ActiveConversationFilter/components/types";

function ConversationUpdateOption({
  disabled,
  currentAccount,
  currentUser,
  conversation,
}: ConversationAssignmentsOptionProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [conversationAssignments, setConversationAssignments] = useState<
    ConversationAssignment[]
  >([]);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderAssignedUserAvatars = (users: AssignedUser[]) => {
    return (
      <AvatarGroup
        max={3}
        slotProps={{
          additionalAvatar: {
            sx: (theme) => {
              return {
                backgroundColor: theme.palette.text.secondary,
                height: "32px",
                width: "32px",
              };
            },
          },
        }}
      >
        {users.map((user) => {
          return <UserAvatar key={user.id} subject={user} />;
        })}
      </AvatarGroup>
    );
  };

  const getAssignedUsers = useCallback(async (convo: Conversation) => {
    try {
      const response = await fetchConversationAssignments(convo.assignments);
      const { members } = await response.json();
      setConversationAssignments(members);
    } catch (error) {
      console.error(
        `Error fetching conversation assignments: ${String(error)}`,
      );
    }
  }, []);

  useEffect(() => {
    getAssignedUsers(conversation).catch((error) => {
      console.error(
        `Error fetching conversation assignments: ${String(error)}`,
      );
    });
  }, [conversation, getAssignedUsers]);

  const assignedUsers = conversationAssignments.map((assignment) => {
    return assignment.assignee;
  });

  return conversation.currentState === "new" ? null : (
    <>
      <Tooltip title="Assigned users" disableFocusListener>
        <Box paddingTop="3px">
          {assignedUsers.length > 0 ? null : <Box sx={{ height: "7px" }} />}
          <IconButton
            data-testid="assigned-users-button"
            disabled={disabled}
            aria-haspopup="true"
            onClick={handleOpen}
            data-product-tour="ConversationOption-Assign"
          >
            {assignedUsers.length > 0 ? (
              renderAssignedUserAvatars(assignedUsers)
            ) : (
              <GroupAddIcon />
            )}
          </IconButton>
        </Box>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Box borderRadius="0.25rem" width="16.25rem" height="24.375rem">
          <Typography
            variant="body2"
            fontWeight={700}
            padding="15px 20px 5px 20px"
          >
            Assigned Users
          </Typography>
          {Boolean(anchorEl) && (
            <UserCollection
              userCollectionId={currentAccount.users}
              currentUser={currentUser}
              conversation={conversation}
              conversationAssignments={conversationAssignments}
              fetchConversationAssignmentsCollectionRequest={() => {
                return getAssignedUsers(conversation);
              }}
            />
          )}
        </Box>
      </Popover>
    </>
  );
}

export default ConversationUpdateOption;
