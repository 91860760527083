interface PusherBackoff {
  currentRetry: number;
  maxRetries: number;
  delay: number;
  multiplier: number;
}

export const addPusherRetriesAndBackoff = (
  backoff: PusherBackoff,
): PusherBackoff => {
  const currentBackoff = backoff;

  // Try maxRetries times before increasing the delay
  currentBackoff.currentRetry += 1;

  // If we have reached the maxRetries, increase the delay
  if (currentBackoff.currentRetry > currentBackoff.maxRetries) {
    currentBackoff.currentRetry = 0;
    currentBackoff.delay *= currentBackoff.multiplier;
  }

  return currentBackoff;
};
