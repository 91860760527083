import { useState, useRef, useEffect, useCallback, createRef } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  Input,
  Link,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import { acceptedFileTypes } from "./constants";
import { Props } from "./types";
import { getImageDimensions } from "./utils";
import getFileThumbnail, { thumbnailMap } from "utils/getFileThumbnail";
import Logo from "components/Logo";
import { useDropzone } from "components/Dropzone/useDropzone";

const accept = Object.keys(acceptedFileTypes).join(", ");
const maxFileSize = 525_000;

function isValid(pastedImage: File | undefined): pastedImage is File {
  return Boolean(
    pastedImage &&
      pastedImage.type in acceptedFileTypes &&
      pastedImage.size <= maxFileSize,
  );
}

export function AttachmentsOption(props: Props) {
  const {
    attachments,
    closeExpandedActionsMenu,
    disabled,
    pastedImage,
    uploadAttachmentRequest,
    handleAttachmentsChange,
    currentAccount,
    outboundMmsDisabled,
    isCampaignProMember = false,
    recipients,
    getExceedsRecipients,
    setExceedsRecipients,
  } = props;
  const prevProps = useRef<Props>();
  const anchorRef = createRef<HTMLButtonElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [errors, setErrors] = useState<string[][]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  /**
   * Handles the accepted files and performs necessary actions.
   *
   * @param file The file to be handled.
   */
  const handleFilesAccepted = useCallback(
    async (file: File) => {
      setErrors([]);
      setUploading(true);
      const objectURL = URL.createObjectURL(file);
      const dimensions = file.type.includes("image")
        ? await getImageDimensions(objectURL)
        : [300, 300];
      uploadAttachmentRequest(
        currentAccount.presignAttachment,
        {
          file,
        },
        {
          successCallback: ({
            key,
            signedUrl,
          }: {
            key: string;
            signedUrl: string;
          }) => {
            handleAttachmentsChange([
              {
                file: objectURL,
                name: file.name,
                size: file.size,
                width: dimensions[0],
                height: dimensions[1],
                contentType: file.type,
                originalFilename: file.name,
                key,
                signedUrl,
              },
            ]);
            setUploading(false);
          },
        },
      );
    },
    [
      currentAccount.presignAttachment,
      handleAttachmentsChange,
      setUploading,
      uploadAttachmentRequest,
    ],
  );

  /**
   * Handles the rejected files and updates the error state.
   * @param filesWithErrors - An array of objects containing the rejected files and their corresponding errors.
   */
  const handleFilesRejected = useCallback(
    (
      filesWithErrors: {
        errors: {
          message: string;
        }[];
      }[],
    ) => {
      const newErrors = filesWithErrors.map(
        (file: { errors: { message: string }[] }) => {
          return file.errors.map((e: { message: string }) => {
            return e.message;
          });
        },
      );
      setErrors(newErrors);
      handleAttachmentsChange([]);
    },
    [handleAttachmentsChange],
  );

  const { getInputProps, getRootProps, isDragActive, open } = useDropzone({
    accept,
    maxSize: maxFileSize,
    onFileAccepted: handleFilesAccepted,
    onFileRejected: handleFilesRejected,
    noClick: true,
  });

  useEffect(() => {
    const isValidFile = isValid(pastedImage);
    const isPastedImageUpdated =
      pastedImage && pastedImage !== prevProps.current?.pastedImage;

    if (isPastedImageUpdated && isValidFile) {
      handleFilesAccepted(pastedImage).catch((error) => {
        return console.error(error);
      });
      setAnchorEl(anchorRef.current);
    }
    if (isPastedImageUpdated && !isValidFile) {
      const errantFile = [
        {
          file: pastedImage,
          errors: [
            {
              message: `Something went wrong. Please try pasting again with a file under ${
                maxFileSize / 1000
              }KB in .png, .jpg, .gif, .rtf, .pdf, .ics or .vcf format.`,
            },
          ],
        },
      ];
      handleFilesRejected(errantFile);
      setAnchorEl(anchorRef.current);
    }
    prevProps.current = props;
  }, [anchorRef, handleFilesAccepted, handleFilesRejected, pastedImage, props]);

  useEffect(() => {
    if (getExceedsRecipients) {
      getExceedsRecipients(attachments, recipients);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachments, recipients]);

  return (
    <>
      <Tooltip
        title={outboundMmsDisabled ? "Disabled by admin" : "Attachments"}
        disableFocusListener
      >
        <span>
          <IconButton
            component="button"
            data-testId="attachments-button"
            disabled={disabled || outboundMmsDisabled}
            aria-haspopup="true"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
            }}
            size="large"
            ref={anchorRef}
          >
            <Badge
              badgeContent={
                uploading ? (
                  <Logo
                    animate
                    width={20}
                    color="transparent"
                    dotColor="contrast"
                  />
                ) : (
                  attachments?.length
                )
              }
              color="secondary"
            >
              <AttachFileIcon />
            </Badge>
          </IconButton>
        </span>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => {
          setAnchorEl(null);
          closeExpandedActionsMenu?.();
        }}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={(theme) => {
          return {
            transition: theme.transitions.create(["background-color"], {
              duration: theme.transitions.duration.standard,
              easing: theme.transitions.easing.easeInOut,
            }),
            ...(isDragActive
              ? {
                  backgroundColor: alpha(theme.palette.text.primary, 0.18),
                  "& .MuiPopover-paper": {
                    transform: "scale(1.04)!important",
                  },
                }
              : {}),
          };
        }}
      >
        <Card
          id="textus-AttachmentsOption"
          {...getRootProps()}
          sx={{
            width: "320px",
          }}
        >
          <Typography variant="h6" margin="1rem">
            Attachment
          </Typography>
          <Input
            type="hidden"
            sx={{
              position: "absolute",
            }}
            inputProps={getInputProps()}
          />
          {(attachments?.length || uploading) && !isDragActive ? (
            <>
              <CardContent
                sx={{
                  padding: 0,
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Box
                  sx={{
                    paddingX: 2,
                  }}
                >
                  {(() => {
                    if (uploading) {
                      return (
                        <Box sx={{ aspectRatio: "36/29" }}>
                          <Skeleton
                            variant="rectangular"
                            animation="pulse"
                            sx={{
                              height: "100%",
                              width: "100%",
                            }}
                          />
                        </Box>
                      );
                    }

                    if (attachments[0].contentType in thumbnailMap) {
                      return (
                        <Box
                          component="figure"
                          sx={(theme) => {
                            return {
                              aspectRatio: "36/29",
                              width: "100%",
                              margin: 0,
                              backgroundColor: theme.palette.action.selected,
                              display: "grid",
                              placeContent: "center",
                            };
                          }}
                        >
                          <img
                            style={{
                              margin: "auto",
                            }}
                            src={thumbnailMap[attachments[0].contentType]}
                            alt={attachments[0].name}
                          />
                          <Typography
                            sx={{
                              marginTop: 2,
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              paddingX: 1,
                            }}
                            color="text.secondary"
                            component="figcaption"
                          >
                            {attachments[0].name}
                          </Typography>
                        </Box>
                      );
                    }

                    return (
                      <>
                        <Box
                          sx={(theme) => {
                            return {
                              aspectRatio: "36/29",
                              backgroundImage: `url(${getFileThumbnail(
                                attachments[0],
                              )})`,
                              backgroundSize: "contain",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundColor: theme.palette.action.selected,
                            };
                          }}
                        />
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            padding: 1,
                          }}
                        >
                          {attachments[0].name}
                        </Typography>
                      </>
                    );
                  })()}
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: "space-between",
                }}
              >
                <Button
                  data-testid="delete-attachment"
                  color="error"
                  type="button"
                  onClick={() => {
                    handleAttachmentsChange([]);
                    return setExceedsRecipients && setExceedsRecipients(false);
                  }}
                >
                  Delete
                </Button>
                <Button data-testid="change-attachment" onClick={open}>
                  Change
                </Button>
              </CardActions>
            </>
          ) : (
            <CardContent
              sx={(theme) => {
                return {
                  margin: 2,
                  marginTop: 0,
                  paddingY: 3,
                  border: `1px dashed ${theme.palette.divider}`,
                  textAlign: "center",
                  gap: 1,
                  display: "flex",
                  flexDirection: "column",
                  transition: theme.transitions.create(["border"], {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.easeInOut,
                  }),
                  ...(isDragActive
                    ? {
                        borderColor: theme.palette.text.primary,
                      }
                    : {}),
                };
              }}
            >
              <Avatar
                sx={{
                  backgroundColor: "transparent",
                  color: "text.primary",
                  margin: "auto",
                }}
              >
                <AttachFileIcon />
              </Avatar>
              <Stack
                sx={{
                  gap: "8px",
                }}
              >
                {/* eslint-disable jsx-a11y/anchor-is-valid */}
                <Link
                  sx={(theme) => {
                    return {
                      color: theme.palette.text.hyperlink,
                    };
                  }}
                  data-testid="select-attachment"
                  component="button"
                  onClick={open}
                  variant="subtitle1"
                >
                  Select attachment
                </Link>
                {/* eslint-enable jsx-a11y/anchor-is-valid */}
                <Typography component="span" align="center">
                  or drag and drop
                </Typography>
              </Stack>
              {errors.length > 0 ? (
                <Typography
                  sx={{
                    marginTop: 2,
                  }}
                  color="error"
                  variant="body2"
                >
                  {errors[0][0]}
                </Typography>
              ) : (
                <Typography
                  align="center"
                  variant="body2"
                  color="text.secondary"
                >
                  .png, .jpg, .gif, .rtf, .pdf, .ics & .vcf <br />
                  supported
                  <br />
                  <br />
                  Max file size: 525KB
                </Typography>
              )}
            </CardContent>
          )}
        </Card>
      </Popover>
    </>
  );
}
