export const DEFAULT_MAX_MESSAGE_LENGTH = 480;
export const MAX_MESSAGE_LENGTHS_OPTIONS = [
  {
    value: 160,
    valueLabel: "160 Characters 1 SMS Message",
    label: "160",
  },
  {
    value: 320,
    valueLabel: "320 Characters 2 SMS Messages",
    label: "320",
  },
  {
    value: 480,
    valueLabel: "480 Characters 3 SMS Messages",
    label: "480",
  },
  {
    value: 640,
    valueLabel: "640 Characters 4 SMS Messages",
    label: "640",
  },
  {
    value: 800,
    valueLabel: "800 Characters 5 SMS Messages",
    label: "800",
  },
  {
    value: 960,
    valueLabel: "960 Characters 6 SMS Messages",
    label: "960",
  },
  {
    value: 1120,
    valueLabel: "1120 Characters 7 SMS Messages",
    label: "1120",
  },
  {
    value: 1280,
    valueLabel: "1280 Characters 8 SMS Messages",
    label: "1280",
  },
  {
    value: 1440,
    valueLabel: "1440 Characters 9 SMS Messages",
    label: "1440",
  },
];
export const SHORT_IO_URL = "textlink.us";
export const ROWS_PER_PAGE = window?.default_page_size || 100;
