import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Typography } from "@mui/material";
import { formatDistanceToNow, parseISO } from "date-fns";
import BasicButton from "components/BasicButton";
import conversationDisplayProperties from "utils/conversationDisplayProperties";
import getLatestPreviewableItemPreview from "utils/getLatestPreviewableItemPreview";
import H4 from "components/H4";
import List from "components/List";
import Avatar from "components/Avatar";

const EmptySection = styled.div`
  padding: 15px 20px;
`;

const ConversationList = styled(List)`
  width: 100%;
`;

const Conversation = styled.li`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:hover {
    background: rgba(0, 0, 0, 0.04);
  }

  &:last-child {
    border-bottom: none;
  }
`;

const ConversationButton = styled(BasicButton)`
  background: ${(props) => {
    return props.isActive ? "rgba(0, 0, 0, 0.04)" : "transparent";
  }};
  font-size: 0.8rem;
  padding: 15px 20px;
  text-align: left;
  width: 100%;

  img {
    color: ${(props) => {
      return props.isActive
        ? props.theme.colors.background.paper
        : props.theme.colors.background.default;
    }};
  }

  &:hover {
    img {
      color: ${(props) => {
        return props.theme.colors.background.paper;
      }};
    }
  }
`;

const ConversationAvatar = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;
`;

const ConversationContent = styled.div`
  flex: 1 1 auto;
  max-width: calc(100% - 43px);
`;

const ConversationHeader = styled.header`
  align-items: center;
  display: flex;
`;

const ConversationHeaderText = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
`;

export default class Root extends Component {
  static propTypes = {
    activeConversationId: PropTypes.string,
    appSettings: PropTypes.object.isRequired,
    conversationCollection: PropTypes.object.isRequired,
    conversationCollectionType: PropTypes.string,
    setActiveConversation: PropTypes.func.isRequired,
  };

  handleConversationClick = (conversation) => {
    return () => {
      const { conversationCollectionType, setActiveConversation } = this.props;
      setActiveConversation(conversation.id, {
        conversationCollectionType,
        conversation,
      });
    };
  };

  render() {
    const { activeConversationId, appSettings, conversationCollection } =
      this.props;
    if (conversationCollection.totalItems === 0)
      return <EmptySection>No conversations found</EmptySection>;
    return (
      <ConversationList>
        {conversationCollection.members.map((conversation) => {
          const { name, number } = conversationDisplayProperties(conversation);
          const accountTitle =
            typeof conversation.account === "string"
              ? conversation.account.replace("/accounts/", "")
              : conversation.account.name;
          const latestPreviewableItem = get(conversation, [
            "latestPreviewableItem",
          ]);
          const contactTitle = name || number;
          const preview = getLatestPreviewableItemPreview(
            latestPreviewableItem,
          );
          const attachments = get(
            latestPreviewableItem,
            ["attachments", "members"],
            [],
          );
          return (
            <Conversation key={conversation.id}>
              <ConversationButton
                onClick={this.handleConversationClick(conversation)}
                isActive={activeConversationId === conversation.id}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    marginBottom: "16px",
                  }}
                >
                  <ConversationAvatar>
                    <Avatar size={33} subject={accountTitle} />
                  </ConversationAvatar>
                  <ConversationContent>
                    <ConversationHeader>
                      <ConversationHeaderText>
                        <Typography variant="body2" color="text.primary">
                          {accountTitle}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          {contactTitle}
                        </Typography>
                      </ConversationHeaderText>
                      {latestPreviewableItem && (
                        <Typography variant="caption" color="text.secondary">
                          {formatDistanceToNow(
                            new Date(
                              get(latestPreviewableItem, ["timelinePosition"]),
                            ),

                            { addSuffix: false },
                          )}
                        </Typography>
                      )}
                    </ConversationHeader>
                    <Box>
                      {attachments?.length > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "action.active",
                            maxWidth: "100%",
                          }}
                        >
                          <AttachFileIcon />
                          <Typography
                            sx={{
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                            }}
                            color="text.secondary"
                            variant="body2"
                          >
                            {attachments[0].originalFilename}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </ConversationContent>
                </Box>

                <Typography
                  variant="caption"
                  color="text.secondary"
                  component="div"
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {preview}
                </Typography>
              </ConversationButton>
            </Conversation>
          );
        })}
      </ConversationList>
    );
  }
}
