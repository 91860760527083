import withRecord from "higherOrderComponents/withRecord";

function Badges() {
  return null;
}

export default withRecord({
  actions: ["fetch"],
  container: "MainNavigation/Badges/BadgeCollection",
  shape: {},
  showLoader: () => {
    return false;
  },
  type: "badgeCollection",
})(Badges);
