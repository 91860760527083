import PropTypes from "prop-types";
import { AccordionDetails, Box, Chip, Grid, Typography } from "@mui/material";
import LinkIcon from "@mui/icons-material/Link";
import CampaignMessage from "./CampaignMessage";
import CampaignMetric from "./CampaignMetric";
import CampaignPreview from "./CampaignPreview";
import { CampaignRecipientGrid } from "./CampaignRecipientGrid";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import { ExpandMoreIcon } from "icons";

const METRICS = ["recipients", "responseRate", "deliveryRate", "failureRate"];

function OneTimeCampaign({
  campaign,
  currentAccount,
  location,
  match,
  setCompose,
}) {
  const attachments = campaign?.attachments?.members ?? [];

  const registeredLinks =
    campaign?.campaignTemplates?.members[0]?.registeredLinks?.members ?? [];

  const registeredLinkIncluded = registeredLinks.length > 0;

  const metrics = [
    ...METRICS,
    ...(registeredLinks.length > 0 ? ["totalClicks", "clickThroughRate"] : []),
  ];

  const getMetrics = (metric) => {
    const { delivered, failed, replied, total } =
      campaign?.deliveryStatistics ?? {};
    const totalClickCount = registeredLinks.reduce((sum, { clickCount }) => {
      return sum + clickCount;
    }, 0);

    const campaignAnalytics = {
      recipients: total || 0,
      responseRate: replied / total || 0,
      deliveryRate: delivered / total || 0,
      failureRate: failed / total || 0,
      totalClicks: totalClickCount || 0,
      clickThroughRate: totalClickCount / total || 0,
    };
    return (
      <Grid item xs={2} key={metric}>
        <CampaignMetric campaignAnalytics={campaignAnalytics} metric={metric} />
      </Grid>
    );
  };

  const renderCampaignMessageOrPreview = () => {
    const { messageBody } = campaign ?? {};
    return messageBody.length <= 160 && attachments.length === 0 ? (
      <CampaignMessage campaign={campaign} />
    ) : (
      <CampaignPreview campaign={campaign} />
    );
  };

  return (
    <Box display="flex" flexDirection="column" maxHeight="100%">
      <Box
        display="flex"
        flexDirection="column"
        flex="0 0 auto"
        padding={{ xs: "20px 10px", md: "20px" }}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems={{ xs: "center", sm: "flex-end" }}
          margin="0 auto"
          width="100%"
        >
          {renderCampaignMessageOrPreview()}
          {registeredLinkIncluded && (
            <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
              <Chip
                fontWeight={600}
                color="primary"
                icon={<LinkIcon />}
                label="Shortened Link"
                size="small"
              />
            </Box>
          )}
        </Box>
      </Box>
      <ExpansionPanel>
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Campaign performance</Typography>
        </ExpansionPanelSummary>
        <AccordionDetails>
          <Grid
            container
            spacing={{ sm: 2 }}
            columns={{ xs: 2, sm: 6, lg: 12 }}
            justifyContent="center"
          >
            {metrics.map((metric) => {
              return getMetrics(metric);
            })}
          </Grid>
        </AccordionDetails>
      </ExpansionPanel>

      <CampaignRecipientGrid
        campaign={campaign}
        currentAccount={currentAccount}
        location={location}
        match={match}
        setCompose={setCompose}
      />
    </Box>
  );
}

OneTimeCampaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  setCompose: PropTypes.func.isRequired,
};

export default OneTimeCampaign;
