import camelCase from "lodash/camelCase";
import get from "lodash/get";
import { createSelector } from "reselect";

import { selectRecords } from "features/EntryPoint/containers/App/selectors";
import denormalizeWithShape from "utils/denormalizeWithShape";
import deepEqualSelector from "utils/deepEqualSelector";
import shallowDenormalize from "utils/shallowDenormalize";

/**
 * Denormalizes the data using the provided parameters.
 * If a shape is provided, it uses `denormalizeWithShape` function, otherwise it uses `shallowDenormalize` function.
 * @param {Object} options - The options for denormalization.
 * @param {string} options.id - The ID of the data.
 * @param {Immutable.List} options.records - The records to denormalize.
 * @param {Object} options.shape - The shape for denormalization (optional).
 * @returns {any} - The denormalized data.
 */
const denormalizeFunc = ({ id, records, shape }: any) => {
  if (shape) {
    return denormalizeWithShape({ id, records: records.toJS(), shape });
  }
  return shallowDenormalize({ id, records: records.toJS() });
};

/**
 * Generates selectors for a container.
 * @param container - The name of the container.
 * @param idPropsVar - The variable used to select a specific record by its ID.
 * @param schema - The schema used for denormalization.
 * @param shape - The shape of the container state.
 * @returns An object containing the generated selectors.
 */
export default ({ container, idPropsVar, schema, shape }: any) => {
  const selectContainerDomain = (state: any) => {
    return (
      state.get(camelCase(`${container}Container`)) ||
      state.get(camelCase(container))
    );
  };
  if (!idPropsVar) {
    return {
      [`select${container}`]: createSelector(
        selectContainerDomain,
        (substate) => {
          return { substate: substate.toJS() };
        },
      ),
    };
  }

  /**
   * Retrieves the record ID from the props object.
   * @param _ The state object (unused).
   * @param props The props object.
   * @returns The record ID.
   */
  const selectRecordId = (_: any, props: any) => {
    return get(props, idPropsVar);
  };

  /**
   * Selects and denormalizes a record from the state based on the provided ID.
   * If the record with the given ID exists in the state, it returns the denormalized record.
   * Otherwise, it returns an empty object.
   *
   * @returns The denormalized record.
   */
  const selectDenormalizedRecord = deepEqualSelector(
    createSelector(selectRecords, selectRecordId, (records, id) => {
      return records.get(id) ? denormalizeFunc({ id, records, shape }) : {};
    }),
  );

  return {
    [`select${container}`]: createSelector(
      [selectContainerDomain, selectDenormalizedRecord],
      (substate, denormalizedRecord) => {
        return {
          substate: substate.toJS(),
          denormalizedRecord,
        };
      },
    ),
  };
};
