import styled, { css } from "styled-components";

export const BaseButtonCSS = css`
  background-color: transparent;
  background: "transparent";
  border-color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  border-radius: 5px;
  border: 1px solid;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  cursor: pointer;
  font-size: 11px;
  font-weight: 700;
  height: 40px;
  line-height: 28px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 0 20px;
  text-transform: uppercase;
  width: 100%;

  a {
    display: block;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }

  ${(props) => {
    return props.large
      ? `
      line-height: 38px;
      height: 40px;
      padding: 0 30px;
      border-radius: 8px
    `
      : null;
  }};

  ${(props) => {
    return props.small
      ? `
      line-height: 18px;
      height: 20px;
      padding: 0 10px;
      border-radius: 3px
    `
      : null;
  }};

  &:hover,
  &:active {
    background: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    border-color: ${"transparent"};
    color: ${(props) => {
      return props.theme.colors.primary.contrastText;
    }};

    a {
      color: ${(props) => {
        return props.theme.colors.primary.contrastText;
      }};
    }
  }

  &[disabled] {
    cursor: auto;
    background: ${(props) => {
      return props.theme.colors.action.disabledBackground;
    }};
    border-color: "transparent";
    color: ${(props) => {
      return props.theme.colors.action.disabled;
    }};
  }
`;

const BaseButton = styled.button`
  ${BaseButtonCSS};
`;

export default BaseButton;
