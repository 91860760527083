import styled from "styled-components";
import Tile from "./Tile";
import breakpoints from "utils/styles/breakpoints";

export default styled(Tile)`
  position: relative;
  height: calc(100vh - 218px);
  overflow: visible;
  border-radius: ${(props) => {
    return props.theme.defaults.tileBorderRadius;
  }}};

  @media (max-width: ${breakpoints.medium - 1}px) {
    height: calc(100vh - 128px);
    border-radius: 0;
  }
`;
