import { all, takeLatest, put, call } from "redux-saga/effects";
import queryString from "query-string";
import { CLIENT_VERSION, POPOUT, TESSERACT } from "utils/constants";

// ACTION TYPES
const PERFORM_RESET_PASSWORD_REQUEST = `/Authentication/containers/ResetPassword/PERFORM_RESET_PASSWORD_REQUEST`;
const PERFORM_RESET_PASSWORD_FAILURE = `/Authentication/containers/ResetPassword/PERFORM_RESET_PASSWORD_FAILURE`;

// ACTION GENERATORS
const performResetPasswordRequest = ({
  password,
  successCallback,
  errorCallback,
}) => {
  return {
    type: PERFORM_RESET_PASSWORD_REQUEST,
    password,
    successCallback,
    errorCallback,
  };
};

const actionGenerators = {
  performResetPasswordRequest,
};

function* saga() {
  yield all([
    takeLatest(PERFORM_RESET_PASSWORD_REQUEST, performResetPasswordSaga),
  ]);
}

function* performResetPasswordSaga({
  password,
  successCallback,
  errorCallback,
}) {
  try {
    const { token } = queryString.parse(window.location.search);
    const params = queryString.stringify({
      token,
      new_password: password,
    });

    const url = `/auth/reset_password`;
    const inPopout = window.self !== window.top;
    const options = {
      method: "PUT",
      headers: {
        Accept: "text/html",
        "Content-Type": "application/x-www-form-urlencoded",
        "X-TextUs-Client": inPopout ? POPOUT : TESSERACT,
        "X-TextUs-Client-Version": CLIENT_VERSION,
      },
      body: new URLSearchParams(params),
    };

    const response = yield call(fetch, url, options);

    if (response.status === 200) {
      successCallback();
    } else {
      const { detail } = yield response.json();
      throw Error(detail);
    }
  } catch (error) {
    errorCallback(error.message);
    yield put({
      type: PERFORM_RESET_PASSWORD_FAILURE,
      error,
    });
  }
}

export { actionGenerators, saga };
