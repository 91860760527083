import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class CareerBuilderStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /careerbuilder/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: "cbd-candidate .name",
      canParse: () => Boolean(doc.querySelector("cbd-candidate .name")),
      textSelector: "cbd-candidate",
    });
    return [detailPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "CareerBuilderStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "CareerBuilderStrategy");
      return null;
    }
  };
}
