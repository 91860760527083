import get from "lodash/get";
import { userManager } from "@tesseract/core";
import { CLIENT_VERSION, POPOUT, TESSERACT } from "./constants";
import sessionStorageHelper from "utils/sessionStorageHelper";

const baseUrl = "";
const contentType = "application/vnd.textus+jsonld";

const getAccessToken = (user) => {
  return get(
    user,
    ["access_token"],
    sessionStorageHelper.getItem("access_token"),
  );
};

const getOptions = async ({ method, params, user }) => {
  let accessToken = getAccessToken(user);
  const inPopout = window.self !== window.top;
  if (!accessToken && process.env.NODE_ENV !== "test") {
    try {
      await userManager.signinSilent();
      const updatedUser = await userManager.getUser();
      accessToken = getAccessToken(updatedUser);
    } catch {
      userManager.clearStaleState();
      userManager.signinRedirect({
        data: {
          redirectUrl: window.location.pathname,
        },
      });
    }
  }
  return {
    headers: {
      Accept: "application/vnd.textus+jsonld",
      "X-TextUs-Client": inPopout ? POPOUT : TESSERACT,
      "X-TextUs-Client-Version": CLIENT_VERSION,
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      ...(/post|patch|put|delete/i.test(method) && {
        "Content-Type": contentType,
      }),
    },
    credentials: "include",
    method,
    ...(params && { body: JSON.stringify(params) }),
  };
};

export { baseUrl, getOptions };
