import { Tooltip } from "@mui/material";
import { getIntegrationDataKeys } from "../utils/getIntegrationDataKeys";
import { getFormattedIntegrationName } from "../utils/getFormattedIntegrationName";
import { ContactSyncIconProps } from "./types";
import { IntegrationIcon } from "icons";

export function ContactSyncIcon({ contact }: ContactSyncIconProps) {
  const connectedIntegrations = getIntegrationDataKeys(contact);
  const integrationNames = connectedIntegrations.map((integration) => {
    return getFormattedIntegrationName(integration);
  });

  return integrationNames.length > 0 ? (
    <Tooltip
      title={`Linked to ${integrationNames.join(", ")}`}
      placement="top-start"
    >
      <span>
        <IntegrationIcon sx={{ marginLeft: "0.25rem" }} />
      </span>
    </Tooltip>
  ) : null;
}