import isEqual from "lodash/isEqual";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";

export const timePeriodOptions = {
  billing: [
    {
      name: "Previous Full Month",
      slug: "previous_month",
    },
  ],
  overview: [
    { name: "Daily", slug: "daily" },
    {
      name: "Previous 7 Days",
      slug: "previous_week",
    },
    {
      name: "Previous 30 Days",
      slug: "previous_30_days",
    },
    {
      name: "Month to Date",
      slug: "month_to_date",
    },
    {
      name: "Previous Full Month",
      slug: "previous_month",
    },
    {
      name: "Quarter to Date",
      slug: "quarter_to_date",
    },
    {
      name: "Previous Full Quarter",
      slug: "previous_quarter",
    },
    {
      name: "Year to Date",
      slug: "year_to_date",
    },
    {
      name: "Previous Full Year",
      slug: "previous_year",
    },
  ],
};

export const sendFrequencyOptions = {
  daily: [{ name: "Daily", slug: "FREQ=DAILY;" }],
  previous_week: [
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
  ],
  previous_30_days: [
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  month_to_date: [
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  previous_month: [{ name: "Monthly", slug: "FREQ=MONTHLY;" }],
  quarter_to_date: [
    { name: "Daily", slug: "FREQ=DAILY;" },
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  previous_quarter: [
    { name: "Daily", slug: "FREQ=DAILY;" },
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  year_to_date: [
    { name: "Daily", slug: "FREQ=DAILY;" },
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  previous_year: [{ name: "Yearly", slug: "FREQ=YEARLY;" }],
};

export const dayToSendOptions = {
  daily: [{ name: "Daily (M-F)", slug: "BYDAY=MO,TU,WE,TH,FR" }],
  weekly: [
    { name: "Monday", slug: "BYDAY=MO" },
    { name: "Tuesday", slug: "BYDAY=TU" },
    { name: "Wednesday", slug: "BYDAY=WE" },
    { name: "Thursday", slug: "BYDAY=TH" },
    { name: "Friday", slug: "BYDAY=FR" },
  ],
  biweekly: [
    { name: "Monday", slug: "BYDAY=MO" },
    { name: "Tuesday", slug: "BYDAY=TU" },
    { name: "Wednesday", slug: "BYDAY=WE" },
    { name: "Thursday", slug: "BYDAY=TH" },
    { name: "Friday", slug: "BYDAY=FR" },
  ],
  monthly: [
    { name: "1st of the Month", slug: "BYMONTHDAY=1" },
    { name: "Last of the Month", slug: "BYMONTHDAY=-1" },
  ],
  yearly: [
    { name: "1st of the Year", slug: "BYYEARDAY=1" },
    { name: "Last of the Year", slug: "BYYEARDAY=-1" },
  ],
};

export const formatValues = ({
  account,
  descendants = true,
  reports,
  schedule,
  timePeriod,
}) => {
  const [frequency, sendDate] = schedule.split(";BY").map((param) => {
    return param.split(/=(.+)/)[1];
  });
  const { name: formattedSendFrequency } = sendFrequencyOptions[
    timePeriod
  ].find(({ slug }) => {
    return slug.includes(frequency);
  });
  const sendFrequency = snakeCase(formattedSendFrequency);
  const { name: formattedDayToSend } = dayToSendOptions[sendFrequency].find(
    ({ slug }) => {
      return slug.includes(sendDate);
    },
  );
  const { name: formattedTimePeriod } = timePeriodOptions.overview.find(
    ({ slug }) => {
      return isEqual(slug, timePeriod);
    },
  );
  const formattedAccountName = account?.name;
  const formattedDescendants = descendants ? "True" : "False";
  const formattedReportName = startCase(reports[0]);
  return [
    { name: formattedReportName, slug: reports[0] },
    { name: formattedAccountName, slug: account?.slug },
    { name: formattedDescendants, slug: descendants },
    { name: formattedSendFrequency, slug: frequency },
    { name: formattedTimePeriod, slug: timePeriod },
    { name: formattedDayToSend, slug: sendDate },
  ];
};
