import { useMemo, useState, MouseEvent } from "react";

import camelCase from "lodash/camelCase";

import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import MuiTableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

import { months, tzDateRegex } from "../constants/DateOptions";
import {
  AnalyticsTableProps,
  Order,
  TableData,
} from "../models/AnalyticsModels";
import AnalyticsTableHeader from "./AnalyticsTableHeader";
import createUUID from "utils/uuid";

const TableCell = styled(MuiTableCell)({
  borderBottom: "none",
  fontSize: "12px",
  padding: "8px",
});

function AnalyticsTable({ billingReport, report }: AnalyticsTableProps) {
  const [order, setOrder] = useState<Order>("asc");
  const [orderBy, setOrderBy] = useState("account");
  const [page, setPage] = useState<number>(0);

  function descendingComparator<T>(a: T, b: T, orderByParam: keyof T) {
    if (b[orderByParam] < a[orderByParam]) return -1;
    if (b[orderByParam] > a[orderByParam]) return 1;
    return 0;
  }

  function getComparator<
    Key extends keyof Record<string | number | symbol, string>,
  >(
    orderParam: Order,
    orderByParam: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return orderParam === "desc"
      ? (a, b) => {
          return descendingComparator(a, b, orderByParam);
        }
      : (a, b) => {
          return -descendingComparator(a, b, orderByParam);
        };
  }

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    return setPage(newPage);
  };
  const rowsPerPage = billingReport ? 25 : 5;

  const { headers = [], title = "", data = [] } = report;

  const columnTitles = headers.map(({ title: titleParam }) => {
    return titleParam;
  });

  // Converts from array of arrays to array of objects with dynamic keys
  const sortableData: TableData[] = useMemo(() => {
    return data.map((row) => {
      const sortableObject = row.reduce((accumulatedObject, value, i) => {
        return { ...accumulatedObject, [camelCase(columnTitles[i])]: value };
      }, {});
      return sortableObject;
    });
  }, [columnTitles, data]);

  const columnHeads = headers.map(
    ({ title: titleParam, slug, description }) => {
      return {
        description,
        slug,
        title: titleParam,
      };
    },
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === camelCase(property) && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(camelCase(property));
  };

  // Iterates over object and returns an array of values to be mapped into the table.
  // If cellData[index] is a number, convert to a string with commas.
  const getCellData = (row: TableData) => {
    const cellData = Object.values(row);
    Object.keys(row).forEach((cell, index) => {
      if (typeof cellData[index] === "number") {
        cellData[index] = cellData[index]
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ","); // adds comma separators
      }
    });
    return cellData;
  };

  return (
    <Box sx={{ width: "100% " }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table aria-label={`${title} Report Table`} size="small">
            <AnalyticsTableHeader
              columnHeads={columnHeads}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {sortableData
                .sort(getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const displayData = getCellData(row) ?? [];
                  return (
                    <TableRow key={createUUID()}>
                      {displayData.map((cell) => {
                        if (
                          typeof cell === "string" &&
                          tzDateRegex.test(cell)
                        ) {
                          const [month, date, year] = new Date(cell)
                            .toLocaleDateString("en-US")
                            .split("/");
                          const monthIndex = parseInt(month, 10) - 1;
                          return (
                            <TableCell
                              key={createUUID()}
                            >{`${months[monthIndex]} ${date}, ${year}`}</TableCell>
                          );
                        }
                        return <TableCell key={createUUID()}>{cell}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          colSpan={headers.length}
          component="div"
          count={data.length}
          onPageChange={handleChangePage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[]}
          style={{ borderBottom: "none" }}
        />
      </Paper>
    </Box>
  );
}
export default AnalyticsTable;
