export function getWindowFeatures({
    width,
    height,
  }: {
    width: number;
    height: number;
  }): Parameters<typeof window.open>[2] {
    return Object.entries({
      menubar: "no",
      toolbar: "no",
      status: "no",
      width,
      height,
      left: (window.screen.width - width) / 2 - 16,
      top: (window.screen.height - height) / 2 - 50,
    })
      .map((record) => {
        return record.join("=");
      })
      .join(",");
  }
  