import { Component, createRef } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { IconButton, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled as muiStyled } from "@mui/material/styles";
import ICONS from "constants/icons";
import Icon from "components/Icon";
import BasicButton from "components/BasicButton";

const Input = styled.input`
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.messageFontSize;
  }};
  outline: none;
  margin: 0;
  padding: 5px;
  transition: ${(props) => {
      return props.theme.mixins.transition({ property: "border" });
    }},
    ${(props) => {
      return props.theme.mixins.transition({ property: "color" });
    }};

  &:disabled {
    border-color: "transparent";
    box-shadow: none;
    padding: 5px 0px;
    transition: none;
    &:hover {
      border-color: "transparent";
      color: ${(props) => {
        return props.theme.colors.text.primary;
      }};
    }
  }

  &:hover,
  &:focus {
    border-color: ${(props) => {
      return props.theme.colors.text.secondary;
    }};
    color: ${(props) => {
      return props.theme.colors.text.primary;
    }};
  }

  &.invalid {
    border-color: ${(props) => {
      return props.theme.colors.error.main;
    }};
  }

  &::placeholder {
    color: ${(props) => {
      return props.theme.colors.text.secondary;
    }};
  }
`;

const MAX_RECIPIENTS = 10;

const minHeight = "40px";
const InputWrapper = styled.div`
  position: relative;
  display: flex;
  flex-flow: row wrap;
  padding: 7px 20px;
  min-height: ${minHeight};
  overflow: auto;
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};

  &:hover {
    background: ${(props) => {
      return props.theme.colors.background.default;
    }};
  }

  input {
    border-radius: 0 !important;
    border: 0 !important;
    flex: 1 1 auto;
    font-weight: 600;
    transition: all ease 0.2s;

    &:focus {
      box-shadow: none;
    }
  }
`;

const To = styled(Box)(({ theme }) => {
  return {
    color: theme.colors.primary.main,
    display: "inline-block",
    fontSize: "14px",
    fontWeight: "700",
  };
});

const tagHeight = "28px";
const RecipientTag = styled.span`
  ${(props) => {
    return props.theme.mixins.flexCenter();
  }};
  align-self: center;
  background: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  border-radius: ${(props) => {
    return props.theme.defaults.bubbleBorderRadius;
  }};
  color: ${(props) => {
    return props.theme.colors.navigation.hoverColor;
  }};
  flex: 0 0 auto;
  font-size: 14px;
  font-weight: 600;
  height: ${tagHeight};
  margin: 4px;
  overflow: hidden;
  white-space: nowrap;

  p {
    padding: 0 10px;
  }
`;

const RecipientWithName = styled.span`
  span {
    font-size: ${(props) => {
      return props.theme.fonts.primaryFontSize;
    }};
    font-weight: 500;
    letter-spacing: 0.025em;
    margin-left: 0.5rem;
    &:before {
      content: "<";
      font-size: ${(props) => {
        return props.theme.fonts.primaryListItemFontSize;
      }};
      padding-right: 0.125rem;
    }
    &:after {
      content: ">";
      font-size: ${(props) => {
        return props.theme.fonts.primaryListItemFontSize;
      }};
      padding-right: 0.125rem;
    }
  }
`;

const RemoveRecipientButton = styled(BasicButton)`
  color: ${(props) => {
    return props.theme.colors.navigation.color;
  }};
  height: 100%;
  width: ${tagHeight};

  svg {
    fill: ${(props) => {
      return props.theme.colors.navigation.color;
    }};
  }

  &:hover,
  &:focus {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};

    svg {
      fill: ${(props) => {
        return props.theme.colors.navigation.hoverColor;
      }};
    }
  }
`;

class QuickComposerInput extends Component {
  static propTypes = {
    recipients: PropTypes.array.isRequired,
    searchQuery: PropTypes.string.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    onRecipientRemove: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
    mobileScreen: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.Input = createRef();
  }

  componentDidMount() {
    this.focusTimeout = setTimeout(() => {
      this.Input.current.focus();
    }, 500);
  }

  componentWillUnmount() {
    clearTimeout(this.focusTimeout);
  }

  getPlaceholder() {
    const { recipients } = this.props;
    if (recipients.length > MAX_RECIPIENTS) {
      return `Maximum  of ${MAX_RECIPIENTS} recipients allowed`;
    }
    if (recipients.length > 0) {
      return "Add Recipients";
    }
    return "Names or numbers";
  }

  renderRecipients() {
    const { recipients, theme } = this.props;
    return recipients.map((r) => {
      return (
        <RecipientTag key={r.id}>
          <p>
            {r.contact.name ? (
              <RecipientWithName>
                {r.contact.name}
                <span>{r.formattedPhoneNumber}</span>
              </RecipientWithName>
            ) : (
              `${r.formattedPhoneNumber}`
            )}
          </p>
          <RemoveRecipientButton
            onClick={() => {
              return this.props.onRecipientRemove(r);
            }}
          >
            <Icon
              icon={ICONS.CLOSE}
              iconColor={theme.colors.navigation.color}
              iconSize={16}
            />
          </RemoveRecipientButton>
        </RecipientTag>
      );
    });
  }

  render() {
    const { recipients, searchQuery, onQueryChange } = this.props;
    const overMaxRecipients = recipients.length > MAX_RECIPIENTS;
    return (
      <InputWrapper>
        <To
          sx={{
            padding: this.props.mobileScreen ? "6px 4px 0 0" : "3px 4px 0 0",
          }}
        >
          To:
        </To>
        {this.renderRecipients()}
        <Input
          autoFocus
          ref={this.Input}
          type="text"
          aria-label="add-contact"
          data-testid="add-contacts"
          placeholder={this.getPlaceholder()}
          value={searchQuery}
          onChange={onQueryChange}
          disabled={overMaxRecipients}
        />
        {this.props.mobileScreen && (
          <IconButton
            aria-label="Close Button"
            onClick={this.props.handleClose}
            data-testid="close-modal"
            size="large"
            sx={{
              paddingBotton: "0px",
              height: "35px",
              width: "35px",
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </InputWrapper>
    );
  }
}

export default withTheme(QuickComposerInput);
