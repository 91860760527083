import camelCase from "lodash/camelCase";
import {
  ActionGeneratorMap,
  WithRecordFailure,
  WithRecordSuccess,
  WithRecordRequest,
  WithRecordDefaultCallback,
} from "./types";

/**
 * Creates a failure action generator function for a given set of action types.
 *
 * @template ActionTypeMap - The type of the action type map.
 * @template SnakeCaseKey - The type of the snake case key.
 * @template ReduxContainerName - The type of the Redux container name.
 *
 * @param actionTypes - The map of action types.
 * @param actionTypeKey - The key of the action type.
 * @returns The failure action generator function.
 */
function createFailureFn<
  ActionTypeMap extends {
    readonly [K in SnakeCaseKey]: ReduxContainerName;
  },
  SnakeCaseKey extends `${string}_${string}_${string}`,
  ReduxContainerName extends `app/${string}/${SnakeCaseKey}`,
>(
  actionTypes: ActionTypeMap,
  actionTypeKey: SnakeCaseKey,
): WithRecordFailure<ReduxContainerName, SnakeCaseKey> {
  return (response: unknown, url: unknown, params: unknown) => {
    return {
      type: actionTypes[actionTypeKey as keyof ActionTypeMap],
      response,
      url,
      params,
    };
  };
}

/**
 * Creates a success action generator function.
 *
 * @template ActionTypeMap - The type of the action type map.
 * @template SnakeCaseKey - The type of the snake case key.
 * @template ReduxContainerName - The type of the Redux container name.
 *
 * @param actionTypes - The action type map.
 * @param actionTypeKey - The snake case key of the action type.
 * @returns A success action generator function.
 */
function createSuccessFn<
  ActionTypeMap extends {
    readonly [K in SnakeCaseKey]: ReduxContainerName;
  },
  SnakeCaseKey extends `${string}_${string}_${string}`,
  ReduxContainerName extends `app/${string}/${SnakeCaseKey}`,
>(
  actionTypes: ActionTypeMap,
  actionTypeKey: SnakeCaseKey,
): WithRecordSuccess<ReduxContainerName, SnakeCaseKey> {
  return (response: unknown) => {
    return {
      type: actionTypes[actionTypeKey as keyof ActionTypeMap],
      response,
    };
  };
}

/**
 * Creates a request function for generating action generators.
 *
 * @template ActionTypeMap - The type representing the mapping of action types to container names.
 * @template SnakeCaseKey - The type representing the snake case key for action types.
 * @template ReduxContainerName - The type representing the Redux container name.
 *
 * @param actionTypes - The mapping of action types to container names.
 * @param actionTypeKey - The snake case key for the action type.
 * @returns A request function that generates action generators.
 */
function createRequestFn<
  ActionTypeMap extends {
    readonly [K in SnakeCaseKey]: ReduxContainerName;
  },
  SnakeCaseKey extends `${string}_${string}_${string}`,
  ReduxContainerName extends `app/${string}/${SnakeCaseKey}`,
>(
  actionTypes: ActionTypeMap,
  actionTypeKey: SnakeCaseKey,
): WithRecordRequest<ReduxContainerName, SnakeCaseKey> {
  return (url: unknown, params: unknown, options = {}) => {
    return {
      type: actionTypes[actionTypeKey as keyof ActionTypeMap],
      url,
      params,
      options,
    };
  };
}

/**
 * Creates a default callback function for generating action objects.
 *
 * @template ActionTypeMap - The type representing the mapping of action types to Redux container names.
 * @template SnakeCaseKey - The type representing the snake case key used for action types.
 * @template ReduxContainerName - The type representing the Redux container name.
 *
 * @param actionTypes - The mapping of action types to Redux container names.
 * @param actionTypeKey - The snake case key for the action type.
 * @returns A callback function that generates action objects with the specified payload.
 */
function createDefaultFn<
  ActionTypeMap extends {
    readonly [K in SnakeCaseKey]: ReduxContainerName;
  },
  SnakeCaseKey extends `${string}_${string}_${string}`,
  ReduxContainerName extends `app/${string}/${SnakeCaseKey}`,
>(
  actionTypes: ActionTypeMap,
  actionTypeKey: SnakeCaseKey,
): WithRecordDefaultCallback<ReduxContainerName, SnakeCaseKey> {
  return (payload: unknown) => {
    return {
      type: actionTypes[actionTypeKey as keyof ActionTypeMap],
      payload,
    };
  };
}

/**
 * Retrieves the action generator function based on the provided action type key.
 * @param actionTypeKey The key representing the action type.
 * @param actionTypes The map of action types.
 * @returns The action generator function.
 */
const getAction = <
  ActionTypeMap extends {
    readonly [K in SnakeCaseKey]: ReduxContainerName;
  },
  SnakeCaseKey extends `${string}_${string}_${string}`,
  ReduxContainerName extends `app/${string}/${SnakeCaseKey}`,
>(
  actionTypeKey: SnakeCaseKey,
  actionTypes: ActionTypeMap,
) => {
  if (actionTypeKey.includes("FAILURE")) {
    return createFailureFn<ActionTypeMap, SnakeCaseKey, ReduxContainerName>(
      actionTypes,
      actionTypeKey,
    );
  }
  if (actionTypeKey.includes("SUCCESS")) {
    return createSuccessFn<ActionTypeMap, SnakeCaseKey, ReduxContainerName>(
      actionTypes,
      actionTypeKey,
    );
  }
  if (actionTypeKey.includes("REQUEST")) {
    return createRequestFn<ActionTypeMap, SnakeCaseKey, ReduxContainerName>(
      actionTypes,
      actionTypeKey,
    );
  }
  return createDefaultFn<ActionTypeMap, SnakeCaseKey, ReduxContainerName>(
    actionTypes,
    actionTypeKey,
  );
};

/**
 * Generates action generators based on the provided actionTypes object.
 *
 * @deprecated This function is part of the old state management system.
 *
 * @param actionTypes - An object containing action type keys and their corresponding values.
 * @returns An object containing action generators.
 */
export default <
  ActionTypeMap extends {
    readonly [K in SnakeCaseKey]: ReduxContainerName;
  },
  SnakeCaseKey extends `${string}_${string}_${string}`,
  ReduxContainerName extends `app/${string}/${SnakeCaseKey}`,
>(
  actionTypes: ActionTypeMap,
): ActionGeneratorMap<ActionTypeMap, SnakeCaseKey, ReduxContainerName> => {
  return Object.keys(actionTypes).reduce(
    (result, actionTypeKey) => {
      const actionName = camelCase(actionTypeKey);

      return {
        ...result,
        [actionName]: getAction<
          ActionTypeMap,
          SnakeCaseKey,
          ReduxContainerName
        >(actionTypeKey as SnakeCaseKey, actionTypes),
      } as ActionGeneratorMap<ActionTypeMap, SnakeCaseKey, ReduxContainerName>;
    },
    {} as ActionGeneratorMap<ActionTypeMap, SnakeCaseKey, ReduxContainerName>,
  );
};
