import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

const Root = styled.span`
  &.transition-appear {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
    transform: translateY(20px);
  }

  &.transition-appear-active {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateY(0px);
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1)
      ${(props) => {
        return props.duration;
      }}ms;
    transition-delay: ${(props) => {
      return props.delay;
    }}ms;
  }

  &.transition-enter {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
    transform: translateY(20px);
  }

  &.transition-enter-active {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateY(0px);
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1)
      ${(props) => {
        return props.duration;
      }}ms;
    transition-delay: ${(props) => {
      return props.delay;
    }}ms;
  }

  &.transition-enter-done {
    transition: all cubic-bezier(0.6, -0.88, 0.735, 0.045) 0.3s;
  }

  &.transition-exit {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateX(0px);
  }
`;

export default class FadeUpIn extends Component {
  static propTypes = {
    key: PropTypes.string.isRequired,
    duration: PropTypes.number.isRequired,
  };

  render() {
    return (
      <CSSTransition
        {...this.props}
        key={this.props.key}
        timeout={this.props.duration}
        classNames="transition"
      >
        <Root {...this.props} />
      </CSSTransition>
    );
  }
}
