import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import queryString from "query-string";

import { Formik, Field, Form } from "formik";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getWindowFeatures } from "./utils";
import localStorageHelper from "utils/localStorageHelper";

import {
  integrationsId,
  salesforceSubdomain,
  salesforceAuthCallback,
} from "constants/salesforceAuthConstants";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

const Logo = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  background: url("${(props) => {
    return props.src;
  }}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const StyledModalContent = styled(ModalContent)`
  min-width: 300px;
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 20px;
`;

class Salesforce extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
    deleteIntegrationRequest: PropTypes.func.isRequired,
    fetchIntegrationCollectionRequest: PropTypes.func.isRequired,
    provider: PropTypes.object.isRequired,
  };

  componentWillUnmount() {
    if (this.authWindowInterval) {
      clearInterval(this.authWindowInterval);
      localStorage.removeItem(integrationsId);
      localStorage.removeItem(salesforceSubdomain);
    }
  }

  getAuthUrl = ({ subdomain }) => {
    const { appSettings } = this.props;
    const query = queryString.stringify(
      {
        response_type: "code",
        client_id: appSettings.SALESFORCE_KEY,
        redirect_uri: salesforceAuthCallback,
      },
      {
        encode: false,
      },
    );
    return `https://${subdomain}.salesforce.com/services/oauth2/authorize?${query}`;
  };

  openAuthWindow = ({ config: { subdomain } = {} } = {}) => {
    const { account, fetchIntegrationCollectionRequest, provider } = this.props;
    const accountIntegrationId = get(account, ["integrations"]);

    localStorageHelper.setItem({
      item: integrationsId,
      value: accountIntegrationId,
    });
    localStorageHelper.setItem({
      item: salesforceSubdomain,
      value: subdomain,
    });
    if (get(this.authWindow, ["focus"])) {
      this.authWindow.focus();
    } else {
      this.authWindow = window.open(
        this.getAuthUrl({ subdomain }),
        "Salesforce Window",
        getWindowFeatures({ width: 800, height: 800 }),
      );
    }

    clearInterval(this.authWindowInterval);
    /* Every second check to see if the auth window has been closed. If the window has been closed, then close the modal, fetch the account's integrations, and remove the interval.
    https://stackoverflow.com/questions/15769514/window-onclose-function
    */
    this.authWindowInterval = setInterval(() => {
      if (this.authWindow.closed) {
        provider.closeModal();
        fetchIntegrationCollectionRequest(accountIntegrationId);
        clearInterval(this.authWindowInterval);
        this.authWindow = undefined;
      }
    }, 1000);
  };

  deleteIntegration = () => {
    const {
      account,
      deleteIntegrationRequest,
      fetchIntegrationCollectionRequest,
      provider,
    } = this.props;
    deleteIntegrationRequest(provider.integration.id, null, {
      successCallback: () => {
        fetchIntegrationCollectionRequest(account.integrations);
        provider.closeModal();
      },
    });
  };

  render() {
    const { provider } = this.props;

    const host = provider?.integration?.config?.host;

    const [subdomain] = host !== undefined ? host.split(".") : ["login"];

    return (
      <>
        <ModalHeader closeModal={provider.closeModal}>
          {provider.name}
        </ModalHeader>
        <Formik
          initialValues={{
            config: {
              subdomain,
            },
          }}
          onSubmit={(values) => {
            return provider.integration
              ? this.deleteIntegration()
              : this.openAuthWindow(values);
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <StyledModalContent>
                  <Logo src={provider.logo} />
                  <Field name="config.subdomain">
                    {({ field }) => {
                      return (
                        <FormControlLabel
                          control={
                            <Switch
                              value={field.value}
                              checked={field.value === "test"}
                              name="subdomain"
                              onChange={(event) => {
                                const { checked } = event.target;
                                setFieldValue(
                                  field.name,
                                  checked ? "test" : "login",
                                );
                              }}
                              color="secondary"
                            />
                          }
                          label="Connect to Sandbox Account"
                        />
                      );
                    }}
                  </Field>
                </StyledModalContent>
                <Footer>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    aria-label="Authorize"
                  >
                    {provider.integration ? "Deauthorize" : "Authorize"}
                  </Button>
                </Footer>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}

export default Salesforce;
