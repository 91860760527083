import styled from "styled-components";
import { Field } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

import AccountSetting from "./AccountSetting";
import { OfficeHoursValidationSchema } from "formHelpers/validationSchemas";

const FormRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  &.hours + .hours {
    margin-top: 40px;
  }

  button[type="submit"] {
    margin-top: 20px;
  }
`;

const Fields = styled.div`
  > div {
    width: calc(50% - 10px);

    &:nth-of-type(2n) {
      margin-left: 10px;
    }

    &:nth-of-type(3),
    &:nth-of-type(4) {
      margin-top: 10px;
    }
  }
`;

const Buttons = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 66px;
  margin: 0.5em 0 0 0.5em;
`;

const TextAddButton = styled(Button)`
  svg {
    margin-right: 10px;
  }
`;

const dayOptions = [
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
  { label: "Sunday", value: 0 },
];

const hourOptions = [
  { label: "12:00 AM", value: 0 },
  { label: "12:30 AM", value: 30 },
  { label: "1:00 AM", value: 60 },
  { label: "1:30 AM", value: 90 },
  { label: "2:00 AM", value: 120 },
  { label: "2:30 AM", value: 150 },
  { label: "3:00 AM", value: 180 },
  { label: "3:30 AM", value: 210 },
  { label: "4:00 AM", value: 240 },
  { label: "4:30 AM", value: 270 },
  { label: "5:00 AM", value: 300 },
  { label: "5:30 AM", value: 330 },
  { label: "6:00 AM", value: 360 },
  { label: "6:30 AM", value: 390 },
  { label: "7:00 AM", value: 420 },
  { label: "7:30 AM", value: 450 },
  { label: "8:00 AM", value: 480 },
  { label: "8:30 AM", value: 510 },
  { label: "9:00 AM", value: 540 },
  { label: "9:30 AM", value: 570 },
  { label: "10:00 AM", value: 600 },
  { label: "10:30 AM", value: 630 },
  { label: "11:00 AM", value: 660 },
  { label: "11:30 AM", value: 690 },
  { label: "12:00 PM", value: 720 },
  { label: "12:30 PM", value: 750 },
  { label: "1:00 PM", value: 780 },
  { label: "1:30 PM", value: 810 },
  { label: "2:00 PM", value: 840 },
  { label: "2:30 PM", value: 870 },
  { label: "3:00 PM", value: 900 },
  { label: "3:30 PM", value: 930 },
  { label: "4:00 PM", value: 960 },
  { label: "4:30 PM", value: 990 },
  { label: "5:00 PM", value: 1020 },
  { label: "5:30 PM", value: 1050 },
  { label: "6:00 PM", value: 1080 },
  { label: "6:30 PM", value: 1110 },
  { label: "7:00 PM", value: 1140 },
  { label: "7:30 PM", value: 1170 },
  { label: "8:00 PM", value: 1200 },
  { label: "8:30 PM", value: 1230 },
  { label: "9:00 PM", value: 1260 },
  { label: "9:30 PM", value: 1290 },
  { label: "10:00 PM", value: 1320 },
  { label: "10:30 PM", value: 1350 },
  { label: "11:00 PM", value: 1380 },
  { label: "11:30 PM", value: 1410 },
];

function OfficeHours(props) {
  const getDescription = () => {
    return (
      <p>
        {`
          Let us know your office hours and we will help you work
          smarter and more efficiently. You can set an away message
          that is automatically sent when you aren't working and we
          will intelligently pause your messaging efforts outside these
          hours to avoid bothering your contacts at inappropriate times.
        `}
      </p>
    );
  };

  const getRender = () => {
    return ({ values }) => {
      return function (arrayHelpers) {
        return (
          <>
            {values.settings.officeHours.value.map((hours, index) => {
              return (
                <FormRow key={index} className="hours">
                  <Fields>
                    <Field
                      name={`settings.officeHours.value.${index}.startDay`}
                    >
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            select
                            variant="outlined"
                            SelectProps={{
                              native: true,
                            }}
                            label={index === 0 ? "Start day" : null}
                            id="textus-OfficeHours-startDay"
                          >
                            {dayOptions.map((option) => {
                              return (
                                <option key={option.label} value={option.value}>
                                  {option.label}
                                </option>
                              );
                            })}
                          </TextField>
                        );
                      }}
                    </Field>
                    <Field name={`settings.officeHours.value.${index}.endDay`}>
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            select
                            variant="outlined"
                            SelectProps={{
                              native: true,
                            }}
                            label={index === 0 ? "End day" : null}
                            id="textus-OfficeHours-endDay"
                          >
                            {dayOptions.map((option) => {
                              return (
                                <option key={option.label} value={option.value}>
                                  {option.label}
                                </option>
                              );
                            })}
                          </TextField>
                        );
                      }}
                    </Field>
                    <Field
                      name={`settings.officeHours.value.${index}.startTime`}
                    >
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            select
                            variant="outlined"
                            SelectProps={{
                              native: true,
                            }}
                            label={index === 0 ? "Start time" : null}
                            id="textus-OfficeHours-startTime"
                          >
                            {hourOptions.map((option) => {
                              return (
                                <option key={option.label} value={option.value}>
                                  {option.label}
                                </option>
                              );
                            })}
                          </TextField>
                        );
                      }}
                    </Field>
                    <Field name={`settings.officeHours.value.${index}.endTime`}>
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            select
                            variant="outlined"
                            SelectProps={{
                              native: true,
                            }}
                            label={index === 0 ? "End time" : null}
                            id="textus-OfficeHours-endTime"
                          >
                            {hourOptions.map((option) => {
                              return (
                                <option key={option.label} value={option.value}>
                                  {option.label}
                                </option>
                              );
                            })}
                          </TextField>
                        );
                      }}
                    </Field>
                  </Fields>
                  <Buttons>
                    <Tooltip title="Remove office hour">
                      <IconButton
                        type="button"
                        onClick={() => {
                          return arrayHelpers.remove(index);
                        }}
                        disabled={false}
                        size="large"
                      >
                        <RemoveCircleOutlineIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    {index === values.settings.officeHours.value.length - 1 && (
                      <Tooltip title="Add more office hours">
                        <IconButton
                          type="button"
                          onClick={() => {
                            return arrayHelpers.push({
                              startDay: 1,
                              endDay: 5,
                              startTime: 480,
                              endTime: 1020,
                            });
                          }}
                          size="large"
                        >
                          <AddCircleOutlineIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Buttons>
                </FormRow>
              );
            })}
            {values.settings.officeHours.value.length === 0 && (
              <TextAddButton
                color="primary"
                type="button"
                style={{ fontWeight: 700, textTransform: "none" }}
                onClick={() => {
                  return arrayHelpers.push({
                    startDay: 1,
                    endDay: 5,
                    startTime: 480,
                    endTime: 1020,
                  });
                }}
              >
                <AddCircleOutlineIcon fontSize="small" />
                Add office hours
              </TextAddButton>
            )}
          </>
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      settings={[{ default: [], name: "officeHours", render: getRender() }]}
      title="Office Hours"
      validationSchema={OfficeHoursValidationSchema}
    />
  );
}

export default OfficeHours;
