import PropTypes from "prop-types";
import styled, { useTheme } from "styled-components";

import Icon from "components/Icon";

import ICONS from "constants/icons";

const Root = styled.div`
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 4px;
  text-align: center;
  color: ${(props) => {
    return props.theme.colors.primary.contrastText;
  }};
  background: ${(props) => {
    return props.theme.colors.secondary.main;
  }};

  svg {
    margin-right: 10px;
  }
`;

function OfficeHours({ subAccountCount }) {
  const theme = useTheme();

  return (
    <Root>
      <Icon
        icon={ICONS.ALERT}
        iconSize={16}
        iconColor={theme.colors.primary.contrastText}
      />
      Modifying these settings will potentially affect
      <strong> {subAccountCount} </strong>
      sub-accounts
    </Root>
  );
}

OfficeHours.propTypes = {
  subAccountCount: PropTypes.number.isRequired,
};

export default OfficeHours;
