import { Component } from "react";
import PropTypes from "prop-types";

import PageLoader from "components/PageLoader";

export default class HeaderTransition extends Component {
  static propTypes = {
    isHeaderTransitioning: PropTypes.bool.isRequired,
    children: PropTypes.any,
  };

  render() {
    if (this.props.isHeaderTransitioning) return <PageLoader />;
    return this.props.children;
  }
}
