import { Box } from "@mui/material";
import { DialogWrapperProps } from "./types";

function DialogWrapper({ children, ...props }: DialogWrapperProps) {
  return (
    <Box
      width="auto"
      minWidth="18rem"
      maxWidth="31.25rem"
      sx={(theme) => {
        return {
          [theme.breakpoints.down("sm")]: {
            width: "18rem",
          },
        };
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export { DialogWrapper };
