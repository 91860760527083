import { create as createBus } from "../bus";
import { AbortablePromise } from "./AbortablePromise";
import { ConnectEvent, PopupParameters } from "./types";

/**
 * Create popup window
 *
 * Handles opening, closing and communicating with popup window
 */
export const createPopup = <T>(...params: PopupParameters) => {
  /**
   * Child popup window
   */
  let childWindow: Window | undefined;

  /**
   * Promise resolved by the popup
   */
  let promise: AbortablePromise<T> | undefined;

  return {
    /**
     * Open popup
     *
     * @param channel - Channel to communication with popup
     * @param post - listen for event posted by popup
     * @returns a promise to be resolved by the popup
     */
    openWindow(channel: string, post?: () => void): AbortablePromise<T> {
      if (childWindow && !childWindow.closed) {
        childWindow.focus();
      } else {
        promise = undefined;
      }

      if (promise) {
        return promise;
      }

      childWindow = window.open(...params) ?? undefined;

      /**
       * Event bus
       */
      const { produce, subscribe } = createBus<[event: ConnectEvent<T>]>();

      window.addEventListener(channel, produce as EventListener);

      /**
       * Close event listeners and reset state
       */
      const close = () => {
        childWindow = undefined;

        promise = undefined;

        window.removeEventListener(channel, produce as EventListener);
      };

      promise = new AbortablePromise<T>((resolve, reject) => {
        if (!childWindow) {
          reject(new Error("Failed to open popup"));
        }

        /**
         * Opens a connection when a connection event is received
         */
        const subscriber: Parameters<typeof subscribe>[0] = (event) => {
          event.detail.connect({ resolve, reject, post });
        };

        /**
         * Unsubscribe to events
         */
        const unsubscribe = subscribe(subscriber);

        /**
         * Close popup when promise is aborted
         */
        return () => {
          unsubscribe();

          childWindow?.close();

          close();
        };
      }).finally(close) as AbortablePromise<T>;

      return promise;
    },
  };
};
