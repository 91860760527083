import { Conversation } from "@tesseract/core";

export default (conversation: Conversation.Raw) => {
  const { formattedPhoneNumber, assignedContact, associatedContacts } =
    conversation;

  return {
    assignedContact,
    associatedContacts: associatedContacts.members,
    name: assignedContact && assignedContact.name,
    number: formattedPhoneNumber,
  };
};
