import type { SavedReply } from "@tesseract/core";
import { textUsApiV3 } from "services";

function createSavedReply(
  url: `/users/${string}/saved_replies?filter=mine`,
  values: SavedReply.FormValues,
) {
  return textUsApiV3.post(url, values);
}

export { createSavedReply };
