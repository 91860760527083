import { Button, Grid, Autocomplete, TextField } from "@mui/material";
import type { GreenhouseCandidate } from "@tesseract/core";
import { useEffect, useState } from "react";
import { useGreenhouseApplication } from "../utils/getGreenhouseApplication";
import { Props } from "./types";
import {
  GreenhouseCandidateDetails,
  GreenhouseCandidateAutocomplete,
} from "features/ContactSync";

export function GreenhouseCandidate({
  contact,
  currentAccount,
  onChanged,
  onContactUnlink,
}: Props) {
  const [selectedCandidate, setSelectedCandidate] =
    useState<GreenhouseCandidate.Raw>(contact.data.greenhouse!.Candidate!);

  const { applications } = selectedCandidate;

  const applicationToDisplay = useGreenhouseApplication(
    applications,
    selectedCandidate,
  );

  const [selectedApplication, setSelectedApplication] = useState<
    GreenhouseCandidate.ApplicationsObject | undefined
  >(applicationToDisplay);

  useEffect(() => {
    onChanged(selectedCandidate);
    setSelectedApplication(applicationToDisplay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCandidate]);

  return contact.data.greenhouse!.Candidate ? (
    <>
      <Grid container spacing={2} alignItems="center" display="flex">
        <Grid item xs={12} sm={10}>
          <GreenhouseCandidateAutocomplete
            account={currentAccount.id}
            contact={contact}
            fullWidth
            value={selectedCandidate}
            onChange={(_, value) => {
              setSelectedCandidate(value ?? selectedCandidate);
              onChanged(value ?? selectedCandidate);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            sx={{
              width: "100%",
              height: "36px",
            }}
            color="error"
            variant="outlined"
            onClick={() => {
              return onContactUnlink("greenhouse");
            }}
          >
            Unlink
          </Button>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: "25px", marginTop: "9px" }}>
          {/* 
            This is the Applications Autocomplete component.
          */}
          <Autocomplete
            disableClearable
            getOptionLabel={({ id, jobs }) => {
              return `${id} - ${jobs[0].name}`;
            }}
            onChange={(_, value) => {
              setSelectedApplication(value);
              setSelectedCandidate({
                ...selectedCandidate,
                current_application_id: value.id,
              });
            }}
            options={Object.values(applications ?? {})}
            renderInput={(renderInputParams) => {
              return (
                <TextField
                  {...renderInputParams}
                  label="Application ID"
                  helperText={
                    selectedApplication === undefined
                      ? "No IDs found, please try again later."
                      : undefined
                  }
                  FormHelperTextProps={{
                    error: selectedApplication === undefined,
                  }}
                />
              );
            }}
            value={selectedApplication}
          />
        </Grid>
      </Grid>

      <GreenhouseCandidateDetails candidate={selectedCandidate} editModal />
    </>
  ) : null;
}
