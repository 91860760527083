import styled from "styled-components";
import breakpoints from "utils/styles/breakpoints";

const Root = styled.div`
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: ${(props) => {
    return props.noScroll ? "none" : "auto";
  }};

  @media (min-width: ${breakpoints.medium}px) {
    margin-top: ${(props) => {
      return props.theme.layout.navHeight;
    }};
    top: 0;
  }
`;

export default Root;
