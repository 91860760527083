export default text =>
  text.includes(",")
    ? text
        .trim()
        .replace(/(dr|mr|mrs|ms|miss|prof)(\.\s+|\s+)/i, "")
        .split(",", 2)
        .map(name => name.trim())
        .reverse()
    : text
        .trim()
        .replace(/(dr|mr|mrs|ms|miss|prof)(\.\s+|\s+)/i, "")
        .split(/\s+/, 2);
