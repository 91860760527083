import { useLocation } from "react-router-dom";
import queryString from "query-string";
import get from "lodash/get";

import AutoReplyMessage from "../components/AutoReplyMessage";
import CharacterLimit from "../components/CharacterLimit";
import SavedReplies from "../components/SavedReplies";
import { AutomatedSender } from "../components/AutomatedSender";
import { IntroAndAppended } from "../components/IntroAndAppended";

import ConversationExport from "../components/ConversationExport";
import ConversationImport from "../components/ConversationImport";
import InboxSettings from "../components/InboxSettings";

import CampaignSettings from "../components/CampaignSettings";
import FilterContactSettings from "../components/FilterContactSettings";

import LinkStripping from "../components/LinkStripping";
import MmsSettings from "../components/MmsSettings";

import AccountConfiguration from "../components/AccountConfiguration";
import CustomUserSchema from "../components/CustomUserSchema";

import RegisteredLinks from "../components/RegisteredLinks";
import WhiteLabel from "../components/WhiteLabel";

import Integrations from "../containers/Integrations";
import { IntegrationsLandingPage } from "../components/Integrations/components/IntegrationsLandingPage/IntegrationsLandingPage";

import BrandInformation from "../components/BrandInformation";

import ManageOptOuts from "../components/ManageOptOuts";

import { Accounts } from "pages/_account/settings/team-management/accounts";
import UserManagement from "pages/UserManagement/Loadable";

import { SettingsSectionWithSubSections, SettingsSection } from "models/SettingsSection";

import getPaginatedId from "utils/getPaginatedId";
import { Account } from "models/Account";

export function getSettingsOptionsV2 ({ 
  account,
  isAdmin,
}: { 
  account: Account;
  isAdmin: boolean;
}): SettingsSectionWithSubSections[] {
  const featureFlags = account?.featureFlags || {};

  const brandRules = (
    account?.type === "organization" || 
    account?.type === "partner" ||
    !account?.phoneNumbers.length
  );

  const sections = [
    {
      sectionTitle: "Messaging Settings",
      subsections: [
        {
          title: "Messages",
          path: "message",
          settings: [
            {
              title: "Templates",
              to: `/templates`,
              OptionComponent: SavedReplies,
            },
            isAdmin && {
              title: "Intro & Appended",
              to: `/intro-appended-message`,
              OptionComponent: IntroAndAppended,
            },
            {
              title: "Office Hours & Reply",
              to: `/auto-reply-message`,
              OptionComponent: AutoReplyMessage,
            },
            featureFlags.automatedSender && {
              title: "Automated sender",
              to: `/automated-sender`,
              OptionComponent: AutomatedSender,
            },
          ]
        },// end Messages
        {
          title: "Inbox settings",
          path: "inbox",
          settings: [
            {
              title: "Clean-up Rules",
              to: `/organization`,
              OptionComponent: InboxSettings,
            },
            {
              title: "Conversation Import",
              to: `/conversation-import`,
              OptionComponent: ConversationImport,
            },
            {
              title: "Conversation Export",
              to: `/conversation-export`,
              OptionComponent: ConversationExport,
            },
          ]
        },// end Inbox Settings
        {
          title: "Campaign Settings",
          path: "campaign",
          settings: [
            {
              title: "Cutoff Time",
              to: `/campaign-delivery-cutoff-time`,
              OptionComponent: CampaignSettings,
            },
            {
              title: "Filter Out Contacts",
              to: `/filter-out-contacts`,
              OptionComponent: FilterContactSettings,
            }
          ],
        },// end Campaign Settings
        {
          title: "Privacy",
          path: "privacy",
          settings: [
            {
              title: "Character Limits",
              to: `/character-limit`,
              OptionComponent: CharacterLimit,
            },
            {
              title: "Link Removal",
              to: `/link-removal`,
              OptionComponent: LinkStripping,
            },
            {
              title: "MMS Settings",
              to: `/mms-settings`,
              OptionComponent: MmsSettings,
            },
          ],
        } // end Privacy
      ]
    }, // end Messaging Settings
    isAdmin && {
      sectionTitle: "Organization Settings",
      subsections: [
          {
          title: "Team management",
          path: "team-management",
          settings: [
            {
              title: "Accounts",
              to: `/accounts`,
              OptionComponent: Accounts,
            },
            {
              title: "Users",
              to: `/users`,
              OptionComponent: (() => {
                const location = useLocation();
                return (
                  <UserManagement
                    accountId={get(
                      queryString.parse(location.search, {
                        arrayFormat: "bracket",
                      }),
                      ["accounts", 0],
                    )}
                    userCollectionId={getPaginatedId("/users", {
                      location,
                    })}
                  />
                )
              }),
            },
            {
              title: "Licenses",
              to: `/account-configuration`,
              OptionComponent: AccountConfiguration,
            },
            {
              title: "Personalization fields",
              to: `/user-personalization`,
              OptionComponent: CustomUserSchema,
            }
          ]
        }, // end Team Management
        brandRules && {
          title: "Brand",
          path: "brand",
          settings: [
            account?.type === "organization" &&
            !!account?.settings?.legalEntity?.value && 
            {
              title: "Shortened Links",
              to: `/registered-links`,
              OptionComponent: RegisteredLinks,
            },
            featureFlags?.whiteLabelEnabled && {
              title: "White Label",
              to: "/white-label",
              OptionComponent: WhiteLabel,
              linkProps: {
                sx: { display: { xs: "none", sm: "none", md: "block" } },
              },
            }
          ]
        }, // end Brand
        {
          title: "Data & Compliance",
          path: "data-compliance",
          settings: [
            account.adminUserSchema && {
              title: "Registration (10DLC)",
              to: `/brand-registration`,
              OptionComponent: BrandInformation,
            },
            {
              title: "Conversation Export",
              to: `/conversation-export`,
              OptionComponent: ConversationExport,
            },
            isAdmin && {
              title: "Manage Opt Outs",
              to: `/manage-opt-outs`,
              OptionComponent:
                ManageOptOuts
            },
          ]
        }, // end Data & Compliance
        {
          title: "Integrations",
          path: "integrations",
          settings: [
            isAdmin &&
            (
              (account.type !== "partner" && account.childrenCount > 0) ||
              account.type === "organization"
            ) &&
            {
              title: "Options",
              to: `/options`,
              // Luke TODO: Remove feature flag once IF 1.3 is released
              OptionComponent: account.featureFlags?.integrationsFrameworkFe ? IntegrationsLandingPage : Integrations,
            },
          ]
        }, // end Brand 2
      ], 
    }, // end Organization Settings
  ];

  return sections.filter(Boolean).map((section) => {
    return {
      ...section,
      // @ts-expect-error it says `false` isn't acceptable for this type, even though they're filtered out
      subsections: section.subsections.map((sub) => {
        return {
          ...sub,
          // Filter out any null settings
          settings: sub.settings?.filter(Boolean) ?? [],
        };
      })
      // Filter out any subsections with no settings 
      .filter((sub:SettingsSection) => { return sub.settings.length > 0;}),
    };
  }).filter(Boolean) as SettingsSectionWithSubSections[];

};