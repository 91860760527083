import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

function UserFormTooltipContent() {
  return (
    <Box width="360px" p={2} pb={0}>
      <Typography paragraph component="h6" style={{ fontWeight: 700 }}>
        Memberships
      </Typography>
      <Typography paragraph>
        Accounts that a user has direct access to. They will also be able to
        navigate into accounts below these in the organizational structure, if
        there are any.
      </Typography>
      <Typography component="h6" style={{ fontWeight: 700 }}>
        Roles
      </Typography>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <SupervisorAccountIcon />
          </ListItemIcon>
          <ListItemText
            primary="Admins can control all settings on their account and any accounts below
        them in the organizational structure, if there are any."
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText
            primary="Users cannot control any settings but have full access to messaging,
        contact management, and analytics."
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default UserFormTooltipContent;
