/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import { ContractTerm, AnalyticsData } from "./types";
import { CurrentAccount } from "features/Automation/models/AutomationModels";
import { fetchContractTerms } from "features/AccountAnalytics/api/fetchContractTerms";
import { fetchBillingAnalytics } from "features/AccountAnalytics/api/fetchBillingAnalytics";

/*
This hook acts as the controller for the overview section for the Cumulative Billing
Analytics screen.
*/
const useCumulativeBillingAnalytics = (currentAccount: CurrentAccount) => {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData | any>({});
  const [asOfDate, setAsOfDate] = useState<Date>(new Date());
  const [contractTerms, setContractTerms] = useState<ContractTerm[]>([]);
  const [selectedContractTerm, setSelectedContractTerm] = useState<
    ContractTerm | any
  >({});
  const [isLoadingAnalytics, setIsLoadingAnalytics] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  /* 
  Fetches current account's contract terms
  */
  useEffect(() => {
    if (currentAccount) {
      getContractTerms();
    }
  }, [currentAccount]);

  /*
  Fetches billing analytic data when contract term is selected and/or when "As of Date" 
  picker's value is changed
  */
  useEffect(() => {
    if (selectedContractTerm.id && asOfDate) {
      getBillingAnalytics();
    }
  }, [asOfDate, selectedContractTerm]);

  const getContractTerms = async () => {
    try {
      const response = await fetchContractTerms(currentAccount.slug);
      const data = await response.json();
      const formattedData = data.map((term: ContractTerm, index: number) => {
        const startDate = parseISO(`${term.startDate}T00:00:00`);
        const endDate = parseISO(`${term.endDate}T00:00:00`);
        // Add additional property `label`. Used for the "Contract term" dropdown
        return {
          ...term,
          label:
            index === data.length - 1
              ? "Current term"
              : `${format(startDate, "MMM. y")} - ${format(endDate, "MMM. y")}`,
        };
      });
      setContractTerms(formattedData);
      setSelectedContractTerm(formattedData.at(-1));
    } catch (error) {
      enqueueSnackbar(`Failed to fetch contract terms: Error:${error}`, {
        variant: "error",
      });
    }
  };

  const getBillingAnalytics = async () => {
    try {
      const formattedAsOfDate = format(new Date(asOfDate), "y-MM-dd");
      const response = await fetchBillingAnalytics(
        currentAccount.slug,
        selectedContractTerm.startDate,
        formattedAsOfDate,
      );
      const data = await response.json();
      setAnalyticsData(data);
      setIsLoadingAnalytics(false);
    } catch (error) {
      enqueueSnackbar(`Failed to fetch analytics: Error:${error}`, {
        variant: "error",
      });
    }
  };

  const handleSelectContractTerm = (option: ChangeEvent<HTMLInputElement>) => {
    setIsLoadingAnalytics(true);
    const selectedTerm = contractTerms.find((term) => {
      return term.label === option.target.value;
    });
    setSelectedContractTerm(selectedTerm);
  };

  const hasOverage = () => {
    if (!analyticsData.used) return false;
    const { allowance, used } = analyticsData;
    return Boolean(used > allowance);
  };

  return {
    analyticsData,
    asOfDate,
    contractTerms,
    getBillingAnalytics,
    handleSelectContractTerm,
    hasOverage,
    selectedContractTerm,
    setAsOfDate,
    isLoadingAnalytics,
  };
};

export { useCumulativeBillingAnalytics };
