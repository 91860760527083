import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import H3 from "components/H3";
import BasicButton from "components/BasicButton";
import SuccessGraphic from "utils/images/success-check.svg";

const Root = styled.div`
  ${(props) => {
    return props.isActive
      ? props.theme.mixins.fadeShow
      : props.theme.mixins.fadeHide;
  }};
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter();
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  border-radius: 10px;
  z-index: 25;
  transition: ${(props) => {
    return props.theme.mixins.transition();
  }};

  div {
    ${(props) => {
      return props.theme.mixins.flexCenter();
    }};
    flex-flow: column nowrap;
    z-index: 2;
    position: relative;

    img {
      transition: ${(props) => {
        return props.theme.mixins.transition();
      }};
      width: ${(props) => {
        return props.hasMultipleRecipients ? 170 : 120;
      }}px;
      animation: ${(props) => {
          return props.isActive && "slideUpIn";
        }}
        1s ease-in-out;
    }

    ${H3} {
      ${(props) => {
        return props.isActive
          ? props.theme.mixins.fadeShow
          : props.theme.mixins.fadeHide;
      }};
      margin: 0;
      color: ${(props) => {
        return props.theme.colors.primary.main;
      }};
      font-size: ${(props) => {
        return props.theme.fonts.hugeHeaderFontSize;
      }};
      transition: ${(props) => {
        return props.theme.mixins.transition({ timing: "0.6s" });
      }};
      transition-delay: 0.6s;
    }

    p {
      ${(props) => {
        return props.isActive
          ? props.theme.mixins.fadeShow
          : props.theme.mixins.fadeHide;
      }};
      color: ${(props) => {
        return props.theme.colors.text.primary;
      }};
      font-size: ${(props) => {
        return props.theme.fonts.largeSecondaryHeaderFontSize;
      }};
      font-weight: 700;
      margin: 5px 0;
      transition: ${(props) => {
        return props.theme.mixins.transition({ timing: "0.6s" });
      }};
      transition-delay: 0.6s;
    }

    button {
      ${(props) => {
        return props.isActive
          ? props.theme.mixins.fadeShow
          : props.theme.mixins.fadeHide;
      }};
      background: ${(props) => {
        return props.theme.colors.primary.main;
      }};
      color: ${(props) => {
        return props.theme.colors.primary.contrastText;
      }};
      font-weight: 700;
      font-size: ${(props) => {
        return props.theme.fonts.primaryFontSize;
      }};
      margin: 10px 10px 10px 0;
      height: 36px;
      padding: 0 15px;
      border-radius: ${(props) => {
        return props.theme.defaults.borderRadius;
      }};
      transition: ${(props) => {
          return props.theme.mixins.transition({
            timing: "0.6s",
            property: "opacity",
          });
        }},
        ${(props) => {
          return props.theme.mixins.transition({
            timing: "0.6s",
            property: "visiblity",
          });
        }};
      transition-delay: 0.6s;
      text-transform: uppercase;

      &:hover {
        background: ${(props) => {
          return props.theme.colors.secondary.main;
        }};
        color: ${(props) => {
          return props.theme.colors.secondary.contrastText;
        }};
      }
    }
  }

  ${(props) => {
    return props.theme.animations.bounceIn;
  }};
  ${(props) => {
    return props.theme.animations.slideUpIn;
  }};
`;

export default class Success extends Component {
  static propTypes = {
    close: PropTypes.func.isRequired,
    isActive: PropTypes.bool.isRequired,
  };

  render() {
    const { close, isActive } = this.props;

    return (
      <Root isActive={isActive}>
        <div>
          <img alt="Contact Assign Success" src={SuccessGraphic} />
          <H3>Success</H3>
          <p>Updated Contact!</p>
          <span>
            <BasicButton onClick={close}>Close</BasicButton>
          </span>
        </div>
      </Root>
    );
  }
}
