import PropTypes from "prop-types";
import get from "lodash/get";
import { Field } from "formik";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

import renderOptionalTooltip from "../utils/renderOptionalTooltip";

const months = [
  { num: "", name: "" },
  { num: "01", name: "January" },
  { num: "02", name: "February" },
  { num: "03", name: "March" },
  { num: "04", name: "April" },
  { num: "05", name: "May" },
  { num: "06", name: "June" },
  { num: "07", name: "July" },
  { num: "08", name: "August" },
  { num: "09", name: "September" },
  { num: "10", name: "October" },
  { num: "11", name: "November" },
  { num: "12", name: "December" },
];

const days = [
  "",
  ...Array.from({ length: 31 }, (_, i) => {
    return `${i + 1}`.padStart(2, "0");
  }),
];

function PersonalizationCalendarDayField({
  personalization,
  errors,
  setFieldTouched,
  setFormIsDirty,
  touched,
  values,
}) {
  const { description, title, type } = personalization;

  const getMonthOptionLabel = (option) => {
    if (!option) return "";
    return option.name || option.name === "" ? option.name : option;
  };

  const getMonthFieldValue = () => {
    return get(values, [`${title}`, "value", "0"], "")
      ? months.find(({ num }) => {
          return num === get(values, [`${title}`, "value", "0"]);
        }).name
      : "";
  };

  return (
    <Box display="flex" flex="0 0 auto" my={4}>
      <Box
        display="flex"
        flex="0 0 auto"
        width="80%"
        justifyContent="space-between"
      >
        <Box width="45%">
          <Field type="select" name={`${title}.value[0]`}>
            {({ field, form }) => {
              return (
                <Autocomplete
                  getOptionLabel={(option) => {
                    return getMonthOptionLabel(option);
                  }}
                  isOptionEqualToValue={(option, selected) => {
                    return selected === option.name;
                  }}
                  onBlur={() => {
                    setFieldTouched(title);
                    setFormIsDirty(form.dirty);
                  }}
                  onChange={(event, newValue) => {
                    const valueToSet = newValue ? newValue.num : "";
                    setFieldTouched(title);
                    form.setFieldValue(field.name, valueToSet);
                  }}
                  options={months}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        aria-label={`personalization ${type} input`}
                        error={touched[title] && Boolean(errors[type])}
                        id={title}
                        InputLabelProps={{ shrink: true }}
                        label={`${title} (Month)`}
                        placeholder="Choose a month"
                        variant="outlined"
                      />
                    );
                  }}
                  value={getMonthFieldValue()}
                />
              );
            }}
          </Field>
        </Box>
        <Box width="45%">
          <Field type="select" name={`${title}.value[1]`}>
            {({ field, form }) => {
              return (
                <Autocomplete
                  onBlur={() => {
                    setFieldTouched(title);
                    setFormIsDirty(form.dirty);
                  }}
                  onChange={(event, newValue) => {
                    setFieldTouched(title);
                    form.setFieldValue(field.name, newValue);
                  }}
                  options={days}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        aria-label={`personalization ${type} input`}
                        error={touched[title] && Boolean(errors[type])}
                        id={title}
                        InputLabelProps={{ shrink: true }}
                        label={`${title} (Day)`}
                        placeholder="Choose a day"
                        variant="outlined"
                      />
                    );
                  }}
                  value={get(values, [title, "value", "1"], "")}
                />
              );
            }}
          </Field>
        </Box>
      </Box>
      <Box ml={2}>
        {description && renderOptionalTooltip(description, title)}
      </Box>
    </Box>
  );
}

PersonalizationCalendarDayField.propTypes = {
  personalization: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PersonalizationCalendarDayField;
