import { Grid, Skeleton } from "@mui/material";
import { Props } from "./types";

export function Empty({ height = "37.13px" }: Props) {
  return (
    <>
      <Grid
        item
        px="10px"
        py="12px"
        pl={2}
        sx={{
          backgroundColor: "inherit",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        item
        px="10px"
        py="12px"
        sx={{
          backgroundColor: "inherit",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        item
        px="10px"
        py="12px"
        sx={{
          backgroundColor: "inherit",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        item
        px="10px"
        py="12px"
        sx={{
          backgroundColor: "inherit",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height={height} />
      </Grid>
      <Grid
        item
        px="10px"
        py="12px"
        sx={{
          backgroundColor: "inherit",
          height: "100%",
        }}
      >
        <Skeleton variant="rectangular" width={64} height={height} />
      </Grid>
      <Grid
        item
        container
        px="10px"
        py="8px"
        pr={1}
        alignContent="center"
        sx={{
          height: "100%",
          backgroundColor: "inherit",
        }}
      >
        <Skeleton variant="circular" width={40} height={40} />
      </Grid>
    </>
  );
}
