import { useEffect } from "react";
import { Box, Button, Checkbox, Tooltip, Typography } from "@mui/material";
import Add from "@mui/icons-material/Add";
import {
  GridColDef,
  DataGridPro,
  GridCellCheckboxRenderer,
  selectedIdsLookupSelector,
} from "@mui/x-data-grid-pro";

import { ContactsGridToolbar } from "../ContactsGridToolbar";
import {
  contentTopMargin,
  gridMinColumnWidth,
  gridHeaderHeight,
  contactGridSidebarWidth,
} from "./constants";
import { ContactsDataGridProps } from "./types";
import { useContactsDataGrid } from "features/Sequences/hooks/useContactsDataGrid";
import { SequencesContact } from "features/Sequences/screens/IndividualSequenceOverview/types";
import AddContactsToGroupModal from "features/Contacts/containers/AddContactsToGroupModal";
import { Dialog } from "components/Dialog";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import QuickComposer from "features/Compose/containers/QuickComposer";
import { renderTooltipTitle } from "features/Sequences/screens/IndividualSequenceOverview/utils";

function ContactsDataGrid({
  disableAddContacts,
  isLoading,
  isSearchFilterActive,
  pagination,
  rowState,
  sequence,
  showSidebar,
  fetchNextPage,
  refreshPage,
  toggleEnrollDrawer,
}: ContactsDataGridProps) {
  const { rows } = rowState;

  const {
    allSelected,
    apiRef,
    dialogRef,
    formattedGroupContacts,
    formattedRecipients,
    fullScreen,
    isAddGroupModalOpen,
    isConfirmationModalOpen,
    isEmpty,
    isSelectionMade,
    rowCount,
    selectedRows,
    selectedRowCount,

    gridSelectAll,
    handleCheck,
    handleSelectAll,
    handleUnselectAll,
    markAsFinished,
    openQuickCompose,
    renderEnrollerAvatar,
    renderStatusChip,
    setAllSelected,
    toggleAddGroupModal,
    toggleAllSelected,
    toggleConfirmationModal,
  } = useContactsDataGrid(sequence.id, rowState, pagination);

  // If all available rows are fetched and selected, setAllSelected to true
  useEffect(() => {
    if (
      selectedRows.length > 0 &&
      rowCount === apiRef.current.state?.rowSelection.length &&
      selectedRows.length === pagination?.count
    ) {
      setAllSelected(true);
    }
  }, [selectedRows, pagination, apiRef, rows, setAllSelected, rowCount]);

  // If allSelected is true and more rows are fetched, set the new rows to checked
  useEffect(() => {
    if (allSelected) {
      gridSelectAll();
    }
  }, [allSelected, gridSelectAll, apiRef]);

  const handleMarkAsFinished = async () => {
    toggleConfirmationModal();
    await markAsFinished(rows, selectedRows);
    refreshPage();
    handleUnselectAll();
  };

  const columns: GridColDef[] = [
    {
      field: "__check__",
      type: "string",
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      filterable: false,
      headerAlign: "left",
      resizable: false,
      sortable: false,
      valueGetter: (params) => {
        const selectionLookup = selectedIdsLookupSelector(apiRef);
        return selectionLookup[params.id] !== undefined;
      },
      renderHeader: () => {
        return (
          <Checkbox
            checked={allSelected}
            onChange={handleSelectAll}
            data-testid="select-all-sequences-contacts"
          />
        );
      },
      renderCell: (params) => {
        return <GridCellCheckboxRenderer {...params} />;
      },
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
    {
      field: "phoneNumber",
      headerName: "Number",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: gridMinColumnWidth,
      renderCell: renderStatusChip,
      valueGetter: (params) => {
        return params.row.state;
      },
      sortComparator: (v1, v2, cellParams1, cellParams2) => {
        return cellParams1.value.localeCompare(cellParams2.value);
      },
    },
    {
      field: "currentStep",
      headerName: "Current step",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
    {
      field: "enrolledAt",
      headerName: "Enrolled on",
      flex: 1,
      minWidth: gridMinColumnWidth,
    },
    {
      field: "enroller",
      headerName: "Enroller",
      flex: 1,
      minWidth: gridMinColumnWidth,
      renderCell: renderEnrollerAvatar,
    },
  ];

  const noRows = isEmpty && !isLoading;

  const widthOffset = showSidebar
    ? "calc(100vw - 25rem)"
    : `calc(100vw - ${contactGridSidebarWidth})`;

  return (
    <Box height="65vh" width={widthOffset} marginBottom="4rem">
      {isEmpty ? null : (
        <DataGridPro
          apiRef={apiRef}
          checkboxSelection
          columnBuffer={2}
          columns={columns}
          slots={{
            toolbar: isSelectionMade ? ContactsGridToolbar : null,
          }}
          slotProps={{
            baseCheckbox: {
              disabled: isEmpty,
            },
            toolbar: {
              allSelected,
              pagination,
              rowCount,
              selectedRows,
              handleSelectAll,
              handleUnselectAll,
              openQuickCompose,
              toggleAddGroupModal,
              toggleAllSelected,
              toggleConfirmationModal,
            },
          }}
          disableRowSelectionOnClick
          getRowId={(row: SequencesContact) => {
            return row.id;
          }}
          columnHeaderHeight={isSelectionMade ? 0 : gridHeaderHeight}
          hideFooter
          loading={isLoading}
          localeText={{
            noRowsLabel: "",
          }}
          onRowSelectionModelChange={(contactId) => {
            return handleCheck(contactId);
          }}
          onRowsScrollEnd={fetchNextPage}
          rows={rows}
          sx={{
            borderTop: 0,
          }}
        />
      )}
      {noRows && !isSearchFilterActive ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop={contentTopMargin}
        >
          <Typography
            color="textSecondary"
            marginBottom="1rem"
            sx={{ fontSize: "1rem", fontWeight: 400 }}
            gutterBottom
          >
            Enroll contacts to start your sequence
          </Typography>
          <Tooltip title={renderTooltipTitle(sequence)}>
            <span>
              <Button
                data-testid="add-contact"
                disabled={disableAddContacts}
                onClick={toggleEnrollDrawer}
                startIcon={<Add />}
                size="medium"
                variant="outlined"
              >
                Contacts
              </Button>
            </span>
          </Tooltip>
        </Box>
      ) : null}
      {noRows && isSearchFilterActive ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          width="100%"
          marginTop={contentTopMargin}
        >
          <Typography
            color="textSecondary"
            marginBottom="1rem"
            variant="h6"
            gutterBottom
          >
            No contacts found
          </Typography>
        </Box>
      ) : null}
      <AddContactsToGroupModal
        closeModal={toggleAddGroupModal}
        contacts={
          allSelected
            ? { contactFilterSlug: `sequence:${sequence.id}` }
            : formattedGroupContacts
        }
        contactTotal={
          allSelected && pagination
            ? pagination.count
            : formattedGroupContacts.length
        }
        visible={isAddGroupModalOpen}
      />

      <ConfirmationModal
        cancellationHandler={toggleConfirmationModal}
        confirmationHandler={handleMarkAsFinished}
        isOpen={isConfirmationModalOpen}
        message="Marking a contact finished stops them from receiving the next step message in the sequence. This status cannot be changed."
        title={`Mark ${selectedRowCount} ${
          selectedRowCount === 1 ? "contact" : "contacts"
        } finished`}
      />

      {/* Quick Compose Modal */}
      <Dialog<string, boolean> ref={dialogRef}>
        {({ close }) => {
          return (
            <QuickComposer
              closeModal={() => {
                return close(true)();
              }}
              fullScreen={fullScreen}
              isActive
              recipientIds={formattedRecipients}
            />
          );
        }}
      </Dialog>
    </Box>
  );
}
export { ContactsDataGrid };
