import assert from "assert";
import { userManager } from "@tesseract/core";
import { stringifyUrl } from "query-string";
import { SequencesContactsQueryParams } from "../components/ContactsDataGrid/types";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const fetchSequenceRecipients = async (
  currentAccount: AccountType,
  sequenceId: string,
  next?: string,
  query?: SequencesContactsQueryParams,
): Promise<Response> => {
  const user = await userManager.getUser();
  assert(user?.access_token);

  const SEARCH_PARAM_OPTIONS = [
    "q[contactNameCont]",
    "q[contactPhonePhoneNumberCont]",
    "q[enrollerNameIn]",
    "q[currentStepIn][]",
    "q[stateEq]",
    "q[reasonEq]",
    "q[respondedFalse]",
    "q[respondedTrue]",
  ] as const;

  const [
    name,
    phoneNumber,
    enroller,
    stepNumber,
    state,
    reason,
    respondedFalse,
    respondedTrue,
  ] = SEARCH_PARAM_OPTIONS;

  const params: { [key: string]: string | string[] } = {
    [name]: query?.name ?? "",
    [phoneNumber]: query?.phoneNumber ?? "",
    [stepNumber]: query?.steps ?? [],
    [enroller]: query?.enrollers ?? [],
    [state]: query?.sequenceState ?? "",
    [reason]: query?.reason ?? "",
    [respondedFalse]: query?.respondedFalse ?? "",
    [respondedTrue]: query?.respondedTrue ?? "",
  };

  const url =
    next ||
    stringifyUrl({
      url: `/api/v4/${currentAccount.slug}/sequences/${sequenceId}/sequence_recipients`,
      query: { ...params },
    });

  const request = new Request(url, {
    method: "GET",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
  });

  return fetch(request);
};
