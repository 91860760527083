import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@mui/material/Button";

const Footer = styled.footer`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
`;

const CancelButton = styled(Button)``;

/**
 * @deprecated
 * - Use ui/app/components/DialogFooter/DialogFooter.tsx
 */
class ModalFooter extends Component {
  static propTypes = {
    children: PropTypes.any,
    cancelButton: PropTypes.bool,
    className: PropTypes.string,
    closeModal: PropTypes.func,
  };

  static defaultProps = {
    cancelButton: true,
    closeModal: undefined,
  };

  render() {
    return (
      <Footer className={this.props.className}>
        {this.props.cancelButton && (
          <CancelButton
            color="primary"
            onClick={this.props.closeModal}
            style={{ marginRight: "10px" }}
            size="large"
          >
            Cancel
          </CancelButton>
        )}
        {this.props.children}
      </Footer>
    );
  }
}

export default ModalFooter;
