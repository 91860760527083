import {
  StyledCard,
  StyledSubhead,
  StyledHeader,
  StyledTextContainer,
} from "../../styles";
import { CreateOptionCardProps } from "./types";

function CreateOptionCard({
  ariaLabel,
  dataTestId,
  clickHandler,
  header,
  subhead,
}: CreateOptionCardProps) {
  return (
    <StyledCard
      aria-label={ariaLabel}
      data-testid={dataTestId}
      role="button"
      onClick={clickHandler}
    >
      <StyledTextContainer>
        <StyledHeader variant="h6">{header}</StyledHeader>
        <StyledSubhead variant="body2">{subhead}</StyledSubhead>
      </StyledTextContainer>
    </StyledCard>
  );
}

export { CreateOptionCard };
