import getFileThumbnail from "utils/getFileThumbnail";

export default (attachment, appSettings, width, height, fit = "inside") => {
  if (!attachment.contentType.startsWith("image")) {
    return getFileThumbnail(attachment);
  }
  const params = {
    bucket: appSettings.ATTACHMENTS_BUCKET,
    key: attachment.key,
  };
  if (width && height) {
    const actualWidth = Math.round(width * window.devicePixelRatio);
    const actualHeight = Math.round(height * window.devicePixelRatio);
    params.edits = {
      resize: {
        width: actualWidth,
        height: actualHeight,
        fit,
        withoutEnlargement: true,
      },
    };
  }
  return `${appSettings.IMAGE_ATTACHMENTS_CDN_BASE_URL}/${btoa(
    JSON.stringify(params),
  )}`;
};
