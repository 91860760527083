import { all, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { normalize } from "normalizr";

import { selectRecords } from "features/EntryPoint/containers/App/selectors";
import * as schema from "schema";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import generateContainerState from "utils/generateContainerState";
import generateSagas from "utils/generateContainerState/generateSagas";

const { actionTypes, actionGenerators, reducers, selectors } =
  generateContainerState([
    {
      container: "ConversationSidebar",
      crudActions: ["block", "unblock", "subscribe", "unsubscribe"],
      recordType: "conversation",
      schema: schema.conversation,
    },
  ]);

// SAGAS
const getRequestMethod = (requestType) => {
  switch (requestType) {
    case "unblock":
    case "unsubscribe":
      return "DELETE";
    default:
      return "PUT";
  }
};

function* getOriginalRecord(url) {
  const records = yield select(selectRecords);
  const conversationId = url.split("/", 3).join("/");
  return records.get(conversationId);
}

function* optimisticUpdate({
  method,
  originalRecord,
  schema: conversationSchema,
  url,
}) {
  const revisedRecord = (() => {
    if (url.split("?", 1)[0].match(/block$/) && method === "PUT") {
      return {
        id: originalRecord.get("id"),
        blocked: true,
      };
    }
    if (url.split("?", 1)[0].match(/block$/) && method === "DELETE") {
      return {
        id: originalRecord.get("id"),
        blocked: false,
      };
    }
    if (url.split("?", 1)[0].match(/subscribe/) && method === "DELETE") {
      return {
        id: originalRecord.get("id"),
        unsubscribed: true,
      };
    }
    return { id: originalRecord.get("id") };
  })();
  const { entities: revisedEntities } = normalize(
    revisedRecord,
    conversationSchema,
  );
  yield put(updateRecords(revisedEntities));
}

const sagas = generateSagas({
  actionGenerators,
  getOriginalRecord,
  optimisticUpdate,
  getRequestMethod,
  schema: schema.conversation,
  shouldOptimisticallyUpdate: () => {
    return true;
  },
});

function* saga() {
  yield all([
    takeLatest(
      actionTypes.BLOCK_CONVERSATION_REQUEST,
      sagas.blockConversationRequest,
    ),
    takeLatest(
      actionTypes.UNBLOCK_CONVERSATION_REQUEST,
      sagas.unblockConversationRequest,
    ),
    takeLatest(
      actionTypes.SUBSCRIBE_CONVERSATION_REQUEST,
      sagas.subscribeConversationRequest,
    ),
    takeLatest(
      actionTypes.UNSUBSCRIBE_CONVERSATION_REQUEST,
      sagas.unsubscribeConversationRequest,
    ),
  ]);
}

// REDUCER
const reducer = combineReducers(reducers);

export { actionTypes, actionGenerators, reducer, selectors, saga };
