import { formatInTimeZone } from "date-fns-tz";
import { formatValues } from "../../SendCampaign/sharedComponents/constants/timeOptions";
import { getAccountTimeZone, getTimeZoneAbbreviation } from "utils/date";

export const createTimeStamp = ({
  currentAccount,
  schedule,
  scheduledAt,
  sendVerbiage,
  singleCampaign,
}) => {
  const date = new Date(scheduledAt);

  const timeZone = getAccountTimeZone(currentAccount);

  const { formattedFrequency, formattedSendDate, formattedTime } =
    formatValues(schedule);

  const scheduleString = `Sends ${formattedFrequency} on ${formattedSendDate} at ${formattedTime}`;

  return singleCampaign
    ? `${sendVerbiage} ${formatInTimeZone(date, timeZone, "EEEE, MMM do, yyyy h:mm a z")}`
    : `${scheduleString} ${getTimeZoneAbbreviation(timeZone)}`;
};
