import PropTypes from "prop-types";
import styled from "styled-components";
import { getIn } from "formik";
import TextField from "@mui/material/TextField";
import AccountSetting from "./AccountSetting";
import { InboxSettingsValidationSchema } from "formHelpers/validationSchemas";
import H4 from "components/H4";

const autoCloseOptions = [
  {
    label: "Never",
    value: -1,
  },
  {
    label: "Immediately",
    value: 1,
  },
  {
    label: "After an hour",
    value: 60 * 60,
  },
  {
    label: "After a day",
    value: 24 * 60 * 60,
  },
  {
    label: "After a week",
    value: 7 * 24 * 60 * 60,
  },
];

const unassignOpenOptions = autoCloseOptions.filter((obj) => {
  return obj.label !== "Immediately";
});

const unassignClosedOptions = [
  {
    label: "Never",
    value: -1,
  },
  {
    label: "Immediately",
    value: 1,
  },
  {
    label: "After an hour",
    value: 60 * 60,
  },
  {
    label: "After a day",
    value: 24 * 60 * 60,
  },
  {
    label: "After a week (default)",
    value: 7 * 24 * 60 * 60,
  },
];

const SectionHeader = styled(H4)`
  margin-top: 30px;
`;

const PageHeader = styled.p`
  font-size: 12pt;
  font-weight: bold;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
`;

function InboxSettings(props) {
  const { account } = props;

  const getDescription = () => {
    return (
      <PageHeader>
        {`
            Everyone loves a clean Inbox! Want some help along the way?
            Our Inbox Settings have got your back!
          `}
      </PageHeader>
    );
  };

  const renderAutoCloseConversations = () => {
    return ({ errors, touched }) => {
      return function ({ field }) {
        return (
          <>
            <H4>Auto-Close Conversations</H4>
            <p>
              {`
              With auto-close enabled we'll keep your Inbox tidy by
              automatically closing answered conversations after the
              chosen amount of time.
    `}
            </p>
            <p>
              {`
            Don't worry! Your conversations are safe and sound. Any replies
            will automatically re-open them for you.
          `}
            </p>
            <TextField
              {...field}
              select
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
              label="Auto close conversations"
              error={
                getIn(touched, "settings.conversationAutoCloseAfter.value") &&
                Boolean(
                  getIn(errors, "settings.conversationAutoCloseAfter.value"),
                )
              }
              helperText={
                getIn(touched, "settings.conversationAutoCloseAfter.value")
                  ? getIn(
                      errors,
                      "settings.conversationAutoCloseAfter.value",
                    ) || " "
                  : " "
              }
            >
              {autoCloseOptions.map((option) => {
                return (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </TextField>
          </>
        );
      };
    };
  };

  const renderAutoUnassignClosedConversations = () => {
    return ({ errors, touched }) => {
      return function ({ field }) {
        return (
          <>
            <SectionHeader>Auto-Unassign Closed Conversations</SectionHeader>
            <p>
              {`
              Don't want to stay assigned to a conversation after it's closed?
    `}
            </p>
            <p>
              {` Automatically unassigns everyone from a conversation
            after it has been closed for the chosen amount of time. This will ensure
            everyone in the account is notified of new inbound messages. `}
            </p>
            <TextField
              {...field}
              select
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
              label="Auto unassign closed conversations"
              error={
                getIn(
                  touched,
                  "settings.unassignClosedConversationsAfter.value",
                ) &&
                Boolean(
                  getIn(
                    errors,
                    "settings.unassignClosedConversationsAfter.value",
                  ),
                )
              }
              helperText={
                getIn(
                  touched,
                  "settings.unassignClosedConversationsAfter.value",
                )
                  ? getIn(
                      errors,
                      "settings.unassignClosedConversationsAfter.value",
                    ) || " "
                  : " "
              }
            >
              {unassignClosedOptions.map((option) => {
                return (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </TextField>
          </>
        );
      };
    };
  };

  const renderAutoUnassignOpenConversations = () => {
    return ({ errors, touched }) => {
      return function ({ field }) {
        return (
          <>
            <SectionHeader>Auto-Unassign Inactive Conversations</SectionHeader>
            <p>
              {`
              Should conversations be removed from "Assigned to you" if you haven't replied in a timely fashion?
    `}
            </p>
            <p>
              {`With this setting, unanswered conversations will be automatically unassigned if nobody responds
            within the chosen amount of time.`}
            </p>
            <TextField
              {...field}
              select
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
              label="Auto unassign open conversations"
              error={
                getIn(
                  touched,
                  "settings.unassignOpenConversationsAfter.value",
                ) &&
                Boolean(
                  getIn(
                    errors,
                    "settings.unassignOpenConversationsAfter.value",
                  ),
                )
              }
              helperText={
                getIn(touched, "settings.unassignOpenConversationsAfter.value")
                  ? getIn(
                      errors,
                      "settings.unassignOpenConversationsAfter.value",
                    ) || " "
                  : " "
              }
            >
              {unassignOpenOptions.map((option) => {
                return (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </TextField>
          </>
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      settings={[
        {
          default: 24 * 60 * 60,
          name: "conversationAutoCloseAfter",
          render: renderAutoCloseConversations(),
        },
        account.multiUser
          ? {
              default: 7 * 24 * 60 * 60,
              name: "unassignClosedConversationsAfter",
              render: renderAutoUnassignClosedConversations(),
            }
          : null,
        account.multiUser
          ? {
              default: -1,
              name: "unassignOpenConversationsAfter",
              render: renderAutoUnassignOpenConversations(),
            }
          : null,
      ].filter((s) => {
        return s;
      })}
      // this filter keeps null settings from being passed,
      // and in turn from having a form field created unnecessarily
      // which then causes an issue when calling the api with a patch
      title="Inbox Organization"
      validationSchema={InboxSettingsValidationSchema}
    />
  );
}

InboxSettings.propTypes = {
  account: PropTypes.object.isRequired,
  field: PropTypes.object,
};

export default InboxSettings;
