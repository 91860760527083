/**
 * useDuplicateSequence custom hook - handles logic for sequence duplication.
 *
 * This hook creates a dialog to handle sequence duplication and
 * manages redirection and snackbar messages based on the duplication result.
 * It returns a reference to the dialog and a handler for duplicating a sequence.
 *
 * @param {string} returnPath - The path to redirect to after a successful duplication.
 *
 * @returns {Object} - An object with the following properties:
 *   {React.MutableRefObject<{}>} duplicateSequenceDialogRef - A reference to the dialog for duplicating sequences.
 *   {Function} handleDuplicateSequenceClick - A function that opens the duplication dialog and handles the duplication process.
 */

import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useDialog } from "components/Dialog";
import { duplicateSequence } from "features/Sequences/api";
import type { Sequence } from "models/Sequence";
import { Snackbar } from "components/Snackbar";
import { useCurrentAccount, useLoading } from "hooks";
import { Blueprint } from "features/Sequences/components/CreateSequenceModal/components/Blueprints/types";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

export const useDuplicateSequence = (returnPath: string) => {
  const currentAccount = useCurrentAccount();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const { ref: duplicateSequenceDialogRef, open: openDuplicateSequenceDialog } =
    useDialog<any, any>();

  const handleDuplicateSequenceClick = (sequence: Sequence | Blueprint) => {
    openDuplicateSequenceDialog(sequence, async (values) => {
      if (values) {
        try {
          startLoading();
          const response = await duplicateSequence(
            currentAccount,
            sequence.id,
            values,
          );
          const newSequence: Sequence = await response.json();

          history.push(`${returnPath}/${newSequence.id}`);

          const isBlueprint = Boolean(values.useCase);
          const blueprintText = `Successfully created a blueprint ${
            values.scheduleType === SCHEDULE_TYPES.relative
              ? "ongoing"
              : "scheduled"
          } sequence.`;

          const snackbarSuccessText = isBlueprint
            ? blueprintText
            : "Successfully duplicated sequence.";

          enqueueSnackbar(snackbarSuccessText, {
            content: (key, message) => {
              return <Snackbar id={key} message={message} variant="info" />;
            },
          });
        } catch (err) {
          enqueueSnackbar("Failed to duplicate sequence.", {
            content: (key, message) => {
              return <Snackbar id={key} message={message} variant="error" />;
            },
          });

          throw new Error(`Error duplicating sequence: ${err}`);
        } finally {
          stopLoading();
        }
      }
    })();
  };

  return {
    duplicateSequenceDialogRef,
    isLoadingDuplicate: isLoading,

    handleDuplicateSequenceClick,
  };
};
