import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { makeRequest } from "utils/generateContainerState/generateSagas";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";

// ACTION TYPES
const actionTypes = generateActionTypes({
  container: "OptOutImportContainer",
  actions: ["create"],
  recordType: "optOutImport",
});

// ACTION GENERATORS
const actionGenerators = generateActionGenerators(actionTypes);

// SAGA
const sagas = {
  *createOptOutImportRequest({ url, params, options = {} }) {
    try {
      const currentAccount = yield select(selectCurrentAccount);
      const { signedUrl } = yield makeRequest({
        url,
        params: { filename: params.file.name },
        method: "POST",
      });
      yield call(fetch, signedUrl, {
        method: "PUT",
        body: params.file,
      });
      const optOutImport = yield makeRequest({
        url: currentAccount.optOutImport,
        params: { url: signedUrl },
        method: "POST",
      });
      yield put(actionGenerators.createOptOutImportSuccess(optOutImport));
      if (options.successCallback) options.successCallback(optOutImport);
    } catch (error) {
      yield put(actionGenerators.createOptOutImportFailure(error));
      if (options.errorCallback) options.errorCallback(error);
    }
  },
};

function* saga() {
  yield all([
    takeLatest(
      actionTypes.CREATE_OPT_OUT_IMPORT_REQUEST,
      sagas.createOptOutImportRequest,
    ),
  ]);
}

export { actionTypes, actionGenerators, saga };
