import { Component } from "react";

function withPopover(WrappedComponent) {
  class WithPopover extends Component {
    constructor(props) {
      super(props);
      this.state = {
        anchorEl: null,
      };
    }

    handleOpen = (event) => {
      this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
      this.setState({ anchorEl: null });
    };

    handleOpenAfterPaste = (ref) => {
      this.setState({ anchorEl: ref });
    };

    render() {
      const { anchorEl } = this.state;
      return (
        <WrappedComponent
          anchorEl={anchorEl}
          closeHandler={this.handleClose}
          openHandler={this.handleOpen}
          pasteHandler={this.handleOpenAfterPaste}
          {...this.props}
        />
      );
    }
  }

  return WithPopover;
}

export default withPopover;
