import PropTypes from "prop-types";
import get from "lodash/get";
import styled from "styled-components";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import AccountSetting from "./AccountSetting";
import H4 from "components/H4";

import { RegisteredLinksValidationSchema } from "formHelpers/validationSchemas";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";
import { SHORT_IO_URL } from "constants/defaults";

const Description = styled(SettingsDescription)`
  a {
    font-weight: bold;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

function RegisteredLinks(props) {
  const { account } = props;

  const getDescription = () => {
    return (
      <Box mt={2}>
        <Description>
          <Box my={2} display="flex">
            <Box alignSelf="center" mr={1}>
              <H4>Shortened Branded Links</H4>
            </Box>
            <Chip label="Campaigns Pro" color="primary" size="small" />
          </Box>
          <p>
            By using the Shortened Link option when creating campaign messages,
            you can send shortened, branded links to your contacts. Using the
            Shortened Link option will also allow you to track click through
            rates for your campaign.
          </p>
          <p>
            The branded subdomain shown below is what will be used as the base
            URL for your shortened links. We recommend using your
            organization&apos;s name so that contacts can easily identify the
            link is not spam.
          </p>
        </Description>
      </Box>
    );
  };

  const getRegisteredLinkRender = () => {
    return ({ errors, values }) => {
      return function ({ field, form }) {
        const { value: registeredLinks } =
          account?.settings?.registeredLinks ?? "";
        const { value: errorMessage } = errors?.settings?.registeredLinks ?? "";
        const { value: formValue } = values?.settings?.registeredLinks ?? "";
        return (
          <Box>
            <Box display="flex" my={4}>
              <TextField
                disabled={!!registeredLinks}
                error={!!formValue && !!errorMessage}
                helperText={
                  registeredLinks
                    ? "To change your subdomain, please contact your Account Manager or support@textus.com"
                    : formValue && errorMessage
                }
                label="Custom Branded Base URL"
                onChange={(event) => {
                  form.setFieldValue(field.name, event.target.value);
                }}
                style={{ flexGrow: 1 }}
                value={registeredLinks}
                variant="outlined"
                inputProps={{ "aria-label": "Shortened Custom URL Link" }}
              />
              <Box mx={1}>
                <p>.{SHORT_IO_URL}</p>
              </Box>
            </Box>
            <Typography variant="caption" color="primary" gutterBottom>
              Subdomain Requirements:
              <ul>
                <li>2 characters minimum.</li>
                <li>12 characters maximum.</li>
                <li>Cannot contain special characters or end with hyphens</li>
              </ul>
            </Typography>
          </Box>
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      isDisabled={!get(account, ["settings", "timeZone", "value"])}
      settings={[
        {
          default: "",
          name: "registeredLinks",
          render: getRegisteredLinkRender(),
        },
      ]}
      title="Shortened Links"
      validationSchema={RegisteredLinksValidationSchema}
    />
  );
}

RegisteredLinks.propTypes = {
  account: PropTypes.object.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default RegisteredLinks;
