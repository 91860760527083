import { useCallback, useEffect, useState } from "react";
import {
  DesktopDatePicker,
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { Box } from "@mui/material";
import {
  getMinutes,
  isBefore,
  isSameDay,
  setMinutes,
  startOfHour,
} from "date-fns";
import { SchedulerProps } from "./types";
import { DialogFooter } from "components/DialogFooter";
import { SimpleDialog } from "components/SimpleDialog";
import { useTimeZones } from "hooks";

export function Scheduler({
  closeScheduler,
  currentScheduledAt,
  isActive,
  sendLaterHandler,
}: SchedulerProps) {
  const [scheduledAt, setScheduledAt] = useState<Date | null>(null);

  const { accountTimeZone } = useTimeZones();

  const getInitialScheduledAt = useCallback(() => {
    if (!isActive) {
      return null;
    }

    if (currentScheduledAt) {
      return currentScheduledAt;
    }

    // Gets current browser time and rounds up to the nearest 15 minutes
    const now = new Date();
    const minutes = getMinutes(now);
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    const newMinutes =
      minutes === roundedMinutes ? roundedMinutes + 15 : roundedMinutes;
    return setMinutes(startOfHour(now), newMinutes);
  }, [currentScheduledAt, isActive]);

  useEffect(() => {
    const initialScheduledAt = getInitialScheduledAt();
    setScheduledAt(initialScheduledAt);
  }, [getInitialScheduledAt]);

  const handleDateChange = (newScheduledAt: Date | null) => {
    setScheduledAt(newScheduledAt);
  };

  const handleSubmit = () => {
    if (scheduledAt) {
      sendLaterHandler(scheduledAt);
    }
    closeScheduler();
  };

  return (
    <SimpleDialog
      title="Send later"
      open={Boolean(isActive)}
      handleClose={closeScheduler}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="center"
        alignItems="center"
        padding={{ xs: "1.5rem", sm: "1rem" }}
        minWidth="300px"
        gap={{ xs: "1.5rem", sm: "1rem" }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            disablePast
            label="Date"
            onChange={handleDateChange}
            slotProps={{ textField: { fullWidth: true } }}
            timezone={accountTimeZone}
            value={scheduledAt}
          />
          <DesktopTimePicker
            disablePast={
              scheduledAt ? isSameDay(scheduledAt, new Date()) : false
            }
            label="Time"
            onChange={handleDateChange}
            slotProps={{ textField: { fullWidth: true } }}
            timezone={accountTimeZone}
            value={scheduledAt}
          />
        </LocalizationProvider>
      </Box>
      <DialogFooter
        confirmText="Schedule"
        isConfirmDisabled={!scheduledAt || isBefore(scheduledAt, new Date())}
        onCancel={closeScheduler}
        onConfirm={handleSubmit}
      />
    </SimpleDialog>
  );
}
