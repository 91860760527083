const initialState = {
  pusherConnected: false,
  pusherChannelSubscribed: false,
  pusherDelay: {
    currentRetry: 0,
    maxRetries: 4,
    delay: 2000, // milliseconds
    multiplier: 2.5,
  },
};

// eslint-disable-next-line default-param-last
function pusherReducer(state = initialState, action: any) {
  switch (action.type) {
    case "app/PusherComponent/PUSHER_CONNECTED":
      return {
        ...state,
        pusherConnected: action.payload,
      };
    case "app/PusherComponent/PUSHER_ADD_DELAY":
      return {
        ...state,
        pusherDelay: { ...action.payload },
      };

    case "app/PusherComponent/PUSHER_DISCONNECTED":
      return {
        ...state,
        pusherConnected: action.payload,
        pusher: null,
      };
    case "app/PusherComponent/PUSHER_CHANNEL_SUBSCRIBED":
      return {
        ...state,
        pusherChannelSubscribed: action.payload,
      };
    default:
      return state;
  }
}

export default pusherReducer;
