import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { actionGenerators, saga, reducer, selectors } from "./state";
import Root from "./components/Root";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

class CreateContact extends Component {
  static propTypes = {
    createContactContainer: PropTypes.object.isRequired,
    createContactRequest: PropTypes.func.isRequired,
    currentAccount: PropTypes.object.isRequired,
  };

  render() {
    const { createContactRequest, currentAccount } = this.props;
    const { isCreating, errorCreating } =
      this.props.createContactContainer.substate;
    return (
      <Root
        {...this.props}
        errorSubmitting={errorCreating}
        isSubmitting={isCreating}
        requestUrl={currentAccount.contacts}
        submitHandler={createContactRequest}
      />
    );
  }
}

const withConnect = connect((state, props) => {
  return {
    createContactContainer: selectors.selectCreateContact(state, props),
    currentAccount: selectCurrentAccount(state, props),
  };
}, actionGenerators);

const withReducer = injectReducer({ key: "createContactContainer", reducer });
const withSaga = injectSaga({ key: "createContactContainer", saga });

export default compose(withReducer, withSaga, withConnect)(CreateContact);
