import styled from "styled-components";
import { Account } from "@tesseract/core";
import { CurrentAccount } from "./CurrentAccount";
import H5 from "components/H5";
import H4 from "components/H4";

export const SingleAccountRoot = styled.div`
  flex: 1 1 auto;
  min-width: 0;
  display: flex;
  flex-flow: column nowrap;
  padding: 0 8px;
  align-items: flex-end;

  ${H4} {
    text-transform: none;
    color: ${(props) => {
      return props.theme.colors.common.white;
    }};
  }

  ${H5} {
    color: ${(props) => {
      return props.theme.colors.grey["400"];
    }};
  }
`;

export function SingleAccount({
  currentAccount,
}: {
  currentAccount: Account.Raw | undefined;
}) {
  return (
    <SingleAccountRoot>
      <CurrentAccount currentAccount={currentAccount} />
    </SingleAccountRoot>
  );
}
