import { Box, Skeleton } from "@mui/material";

function ConversationSkeleton() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <Box
        sx={{
          height: "4rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #e0e0e0",
          p: 2,
        }}
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          width={15}
          height={15}
          sx={{ m: 1, display: { xs: "block", sm2: "none" } }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            width: "100%",
            paddingLeft: 1,
          }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1rem", width: "8rem" }}
          />
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "0.8rem", width: "4rem" }}
          />
        </Box>
        <Skeleton
          animation="wave"
          variant="rounded"
          width={20}
          height={15}
          sx={{ m: 1 }}
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          width={20}
          height={15}
          sx={{ m: 1 }}
        />
      </Box>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "flex-end",
        }}
      >
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: `${Math.random() * (20 - 7) + 7}rem` }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: `${Math.random() * (20 - 7) + 7}rem` }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: `${Math.random() * (20 - 7) + 7}rem` }}
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: `${Math.random() * (20 - 7) + 7}rem` }}
        />
      </Box>
      <Box
        sx={{
          height: "4.5rem",
          width: "100%",
          borderTop: "1px solid #e0e0e0",
          p: 2,
        }}
      >
        <Skeleton
          animation="wave"
          variant="text"
          sx={{ fontSize: "1rem", width: "4rem" }}
        />
      </Box>
      <Box
        sx={{
          height: "6rem",
          width: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Skeleton
          animation="wave"
          variant="circular"
          width={56}
          height={56}
          sx={{ position: "absolute", left: "9px", bottom: "15px" }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            width: "100%",
            p: 1,
            marginLeft: { xs: "75px", sm: "0px" },
            marginTop: { xs: "5px", sm: "0px" },
          }}
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            width={20}
            height={20}
            sx={{ m: "0 6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={20}
            height={20}
            sx={{ m: "0 6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={20}
            height={20}
            sx={{ m: "0 6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={20}
            height={20}
            sx={{ m: "0 6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={20}
            height={20}
            sx={{ m: "0 6px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={20}
            height={20}
            sx={{ m: "0 6px" }}
          />
        </Box>
        <Box
          sx={{
            p: { xs: "13px", sm2: "21px" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1rem", width: "7rem", mb: "4px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            height={37}
            sx={{ width: "110px", marginBottom: "20px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default ConversationSkeleton;
