/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import { BlueprintsTemplates } from "./components/BlueprintsTemplates";
import { BlueprintsContext } from "./context";
import { BlueprintsTemplatesPreview } from "./components/BlueprintsTemplatesPreview";
import { DialogHeader } from "components/DialogHeader";

function Blueprints() {
  const { blueprints, isSmallScreen, close, getBlueprints } =
    useContext(BlueprintsContext);

  useEffect(() => {
    if (blueprints.length === 0) {
      getBlueprints();
    }
  }, [blueprints]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => {
        return {
          [theme.breakpoints.down("md")]: {
            width: "25.25rem",
          },
          [theme.breakpoints.between("md", "xl")]: {
            width: "40.3rem",
          },
          [theme.breakpoints.up("xl")]: {
            width: "56.3rem",
          },
        };
      }}
    >
      <DialogHeader
        title="Pre-made blueprints for your sequence"
        subtitle="Choose a custom, pre-built sequence to kickstart your engagement"
        onClose={close(false)}
      />
      {isSmallScreen ? (
        <BlueprintsTemplates />
      ) : (
        <Box display="flex" flexDirection="row">
          <BlueprintsTemplates />
          <BlueprintsTemplatesPreview />
        </Box>
      )}
    </Box>
  );
}

export { Blueprints };
