import { connect } from "react-redux";
import { compose } from "redux";

import { AttachmentsOption } from "./AttachmentsOption";
import { actionGenerators, saga } from "./state";
import { PassedProps } from "./types";
import injectSaga from "utils/injectSaga";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

const mapStateToProps = (state: unknown) => {
  return {
    currentAccount: selectCurrentAccount(state),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withSaga = injectSaga({ key: "AttachmentsOptionContainer", saga });

export default compose(withSaga, withConnect)(AttachmentsOption) as unknown as (
  props: PassedProps,
) => JSX.Element;
