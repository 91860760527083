import PropTypes from "prop-types";
import styled from "styled-components";

const sidebarWidth = (props: any) => {
  return props.sidebarWidth || (props.theme.layout?.sidebarWidth ?? "220px");
};

const calculateWidth = (props: any) => {
  if (props.isSinglePanel) return "100vw";
  const calculatedSidebarWidth = props.showSidebar
    ? sidebarWidth(props)
    : "0px";
  return `calc(100vw - ${calculatedSidebarWidth})`;
};

const PageContent = styled.div<{
  showSidebar: boolean;
  isSinglePanel: boolean;
}>`
  background: ${(props) => {
    return props.theme.colors?.background.paper ?? "#FFF";
  }};
  display: flex;
  flex-flow: column;
  height: 100%;
  margin-left: ${(props) => {
    return props.showSidebar ? sidebarWidth(props) : 0;
  }};
  position: relative;
  transition: ${(props) => {
    return props.theme.mixins?.transition();
  }};
  width: ${(props) => {
    return calculateWidth(props);
  }};
  z-index: 3;

  ${(props) => {
    return props.isSinglePanel
      ? `
        display: block;
        left: ${props.showSidebar ? sidebarWidth(props) : 0};
        margin-left: 0;
        position: absolute;
        top: 0;
        right: 0;
      `
      : "";
  }};
`;

PageContent.propTypes = {
  isSinglePanel: PropTypes.bool.isRequired,
  showSidebar: PropTypes.bool.isRequired,
};

export default PageContent;
