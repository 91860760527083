import { CLIENT_VERSION } from "utils/constants";

export const headers = Object.freeze({
  Accept: "application/vnd.textus+jsonld",
  "X-TextUs-Client": "tesseract",
  "X-TextUs-Client-Version": CLIENT_VERSION,
});

function inverse<AKey extends string, BKey extends string>(
  obj: Record<AKey, BKey>,
): {
  [Key in (typeof obj)[keyof typeof obj]]: keyof typeof obj;
} {
  return Object.fromEntries(
    Object.entries(obj).map((array) => {
      return array.reverse();
    }),
  );
}

const accountKeys = {
  parent: "parent_account_slug",
  name: "account_name",
  phoneType: "phone_type",
  phone: "phone_number",
  users: "users",
} as const;

const userKeys = {
  id: "user_id",
  name: "name",
  email: "email",
  role: "role",
  sendWelcome: "send_welcome_email",
} as const;

export const accountKeyMap = Object.freeze(accountKeys);
export const accountKeyMapInverse = Object.freeze(inverse(accountKeys));

export const userKeyMap = Object.freeze(userKeys);
export const userKeyMapInverse = Object.freeze(inverse(userKeyMap));
