import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class ErecruitStrategy {
  constructor({
    activeFrameAttributes,
    activeFrameParsedHtml,
    parsedHtml,
    location,
  }) {
    this.activeFrameAttributes = activeFrameAttributes;
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () =>
    /\/\/er\.|erecruit|maximstaffing|belflex|parallon|kimco|mydgsi|healthtrustws|emeraldhs|arch-sc|promatch\.com|myagilus/i.test(
      this.location?.href,
    );

  getParsers = () => {
    const parentDoc = this.parsedHtml;
    const doc = this.activeFrameParsedHtml;
    const activeFrameId = this.activeFrameAttributes.id;
    const searchTextFunction = () =>
      Array.from(
        $(doc)
          .find("#contact_section")
          .clone()
          .find("img[title*='mergency']")
          .parent()
          .remove()
          .end()
          .end(),
      ).map(node => node.textContent.trim())[0];
    const detailPageParser = new SingleContactParser({
      doc,
      getNamesFunction: () => {
        const headerNode = parentDoc.querySelector("h1[title]");
        if (!headerNode) return null;
        const headerNodeTextContent = headerNode.textContent || "";
        const [
          ,
          nameInfo = headerNodeTextContent,
        ] = headerNode.textContent.split(" - ");
        return getNamesFromText(nameInfo.replace(/\(.*\)/, "").trim());
      },
      canParse: () => /manage/i.test(activeFrameId),
      searchTextFunction,
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: 'tr a[data-tipcontext="candidate"]',
      containerSelector: "tr",
      canParse: () =>
        /candidate__|match__|candidate_predefined|savedsearch/i.test(
          activeFrameId,
        ),
    });
    return [detailPageParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "ErecruitStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "ErecruitStrategy");
      return null;
    }
  };
}
