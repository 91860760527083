import { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import withSizes from "react-sizes";

import Root from "./components/Root";
import breakpoints from "utils/styles/breakpoints";
import withRecord from "higherOrderComponents/withRecord";
import { subscribeToContactUpdated } from "features/Contact/events";

function ConversationCollection(props) {
  /**
   * Subscribe to contact updated events
   *
   * Reload the contact collection when a contact is updated
   */
  useEffect(() => {
    return subscribeToContactUpdated(() => {
      props.fetchConversationCollectionRequest(
        props.conversationCollectionId,
        null,
        {
          successCallback: props.fetchConversationCollectionSuccess,
          errorCallback: props.fetchConversationCollectionError,
        },
      );
    });
  }, [props]);

  return <Root {...props} />;
}

ConversationCollection.propTypes = {
  conversationCollectionId: PropTypes.string.isRequired,
  fetchConversationCollectionRequest: PropTypes.func.isRequired,
  fetchConversationCollectionSuccess: PropTypes.func.isRequired,
  fetchConversationCollectionError: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
    };
  }),
  withRecord({
    actions: ["fetch"],
    container: "Inbox/containers/ConversationCollection",
    shape: {
      members: [
        {
          requestingUserInfo: {},
          assignedContact: {},
          latestPreviewableItem: {
            type: "Union",
            schemas: [
              {
                schema: "Message",
                sender: {},
                attachments: { members: [] },
              },
            ],
          },
          nextScheduledMessage: { attachments: { members: [] }, sender: {} },
        },
      ],
    },
    type: "conversationCollection",
  }),
)(ConversationCollection);
