import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Root from "./components/Root";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import {
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

class ContactCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  setCompose: composeActionGenerators.setCompose,
});

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "ContactCollection",
    shape: { members: [{ phones: { members: [] } }] },
    shouldFetch: (props) => {
      return props.contactCollectionId.length > 0;
    },
    options: (props) => {
      return {
        errorCallback: props.handleErrorState,
      };
    },
    showLoader: () => {
      return false;
    },
    type: "contactCollection",
  }),
  withConnect,
)(ContactCollection);
