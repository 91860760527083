import { useSelector } from "react-redux";
import type { Account } from "@tesseract/core";
import {
  selectCurrentAccount,
  selectUiLink,
} from "features/EntryPoint/containers/App/selectors";

export const useCurrentAccount = (): Account.Raw => {
  const currentAccount = useSelector((store) => {
    return selectCurrentAccount(store);
  });
  const uiLink = useSelector((store) => {
    return selectUiLink(store);
  });

  const isCampaignProMember = uiLink.includes("campaigns?pro");

  return { ...currentAccount, isCampaignProMember };
};
