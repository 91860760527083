import { createSelector } from "reselect";
import { lightTheme } from "@tesseract/theme";
import deepEqualSelector from "utils/deepEqualSelector";
import denormalizeWithShape from "utils/denormalizeWithShape";

const selectOidc = deepEqualSelector((state) => {
  return state.get("oidc").toJS();
});

const selectUser = (state) => {
  const oidcUser = state.getIn(["oidc", "user"]);
  return oidcUser ? oidcUser.toJS() : {};
};

const selectGlobal = (state) => {
  return state.get("global");
};

const selectExtractedPhones = deepEqualSelector(
  createSelector(selectGlobal, (global) => {
    return global.get("extractedPhones").toJS();
  }),
);

const selectRecords = createSelector(selectGlobal, (global) => {
  return global.get("records");
});

const selectAppSettings = deepEqualSelector(
  createSelector(selectRecords, (records) => {
    return records.getIn(["/boot", "appSettings"]).toJS();
  }),
);

const selectCurrentUserId = createSelector(selectGlobal, (global) => {
  return global.get("currentUser");
});

const selectAppColors = deepEqualSelector(
  createSelector(selectGlobal, (global) => {
    return global.get("appColors")
      ? global.get("appColors").toJS()
      : lightTheme;
  }),
);

const selectCurrentUser = deepEqualSelector(
  createSelector(selectCurrentUserId, selectRecords, (id, records) => {
    return records.get(id)
      ? denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: {
            topLevelAccounts: [],
            memberships: {
              members: [
                {
                  account: {},
                },
              ],
            },
            gettingStartedElements: { members: [{ record: {} }] },
            signature: {},
          },
        })
      : {};
  }),
);

const selectUserCanHover = createSelector(selectGlobal, (global) => {
  return global.get("userCanHover");
});

const selectCurrentAccountId = createSelector(selectGlobal, (global) => {
  return global.get("currentAccount");
});

const selectCurrentAccount = deepEqualSelector(
  createSelector(
    selectCurrentAccountId,
    selectRecords,
    (currentAccountId, records) => {
      const currentAccount = records.get(currentAccountId);
      return currentAccount ? currentAccount.toJS() : {};
    },
  ),
);

const selectAccountPlansIds = deepEqualSelector(
  createSelector(
    selectRecords,
    selectCurrentAccount,
    (records, currentAccount) => {
      const result = records.get(`/${currentAccount.slug}/account_plans`);
      return result ? result.toJS() : {};
    },
  ),
);
const selectAccountPlans = createSelector(
  selectAccountPlansIds,
  selectRecords,
  (accountPlanIds, records) => {
    const accountPlans = accountPlanIds?.members?.map((id) => {
      return records.get(id).toJS();
    });
    return accountPlans;
  },
);

const selectAccountNavbarElementIds = deepEqualSelector(
  createSelector(selectRecords, (records) => {
    return records.getIn(["/navbar", "members"]).toJS();
  }),
);

export const selectAccountNavbarElements = createSelector(
  selectAccountNavbarElementIds,
  selectRecords,
  (navbarElementIds, records) => {
    return navbarElementIds.map((id) => {
      return records.get(id).toJS();
    });
  },
);

const selectAccountsInNavbar = createSelector(
  selectAccountNavbarElements,
  selectRecords,
  (navbarElements, records) => {
    return navbarElements.map((navbarElement) => {
      return records.get(navbarElement.account).toJS();
    });
  },
);

const selectAllAccounts = deepEqualSelector(
  createSelector(
    selectCurrentAccount,
    selectAccountsInNavbar,
    (currentAccount, otherAccounts) => {
      return Array.from(new Set([currentAccount, ...otherAccounts]));
    },
  ),
);

const selectInbox = deepEqualSelector(
  createSelector(
    selectRecords,
    selectCurrentAccount,
    (records, currentAccount) => {
      const inbox = records.get(currentAccount.inbox);
      return inbox ? inbox.toJS() : {};
    },
  ),
);

const selectActiveConversationFilter = (shape) => {
  return deepEqualSelector(
    createSelector(selectRecords, selectInbox, (records, inbox) => {
      return records.get(inbox.activeConversationFilter)
        ? denormalizeWithShape({
            id: inbox.activeConversationFilter,
            records: records.toJS(),
            shape,
          })
        : {};
    }),
  );
};

const selectActiveConversations = (state, props) => {
  const { conversations } = selectActiveConversationFilter({
    conversations: {},
  })(state, props);

  return typeof conversations === "object" ? conversations : {};
};

const selectRoute = deepEqualSelector((state) => {
  return state.get("router");
});

// The following selector is a quick way to determine a campaigns pro member
// This should most likely be reworked in the future
const selectUiLink = deepEqualSelector(
  createSelector(
    selectRecords,
    selectCurrentAccount,
    (records, currentAccount) => {
      const result = records.get(
        `/navbar/${currentAccount.slug}/site_nav/campaigns`,
      );
      return result ? result.toJS()?.uiLink : "";
    },
  ),
);

export {
  selectAccountPlans,
  selectAccountPlansIds,
  selectActiveConversationFilter,
  selectActiveConversations,
  selectAllAccounts,
  selectAppColors,
  selectAppSettings,
  selectCurrentAccount,
  selectCurrentUser,
  selectExtractedPhones,
  selectGlobal,
  selectInbox,
  selectOidc,
  selectRecords,
  selectRoute,
  selectUiLink,
  selectUser,
  selectUserCanHover,
};
