import PropTypes from "prop-types";
import { Tooltip, styled } from "@mui/material";

function Tooltip10dlcNotice({ children, text, visibility }) {
  const StyledTooltip = styled(Tooltip)({
    textAlign: "center",
    whiteSpace: "pre-line",
  });
  return (
    <StyledTooltip title={visibility === "hidden" ? "" : text}>
      {children}
    </StyledTooltip>
  );
}

Tooltip10dlcNotice.propTypes = {
  children: PropTypes.any,
  text: PropTypes.string.isRequired,
  visibility: PropTypes.string,
};

export default Tooltip10dlcNotice;
