import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import pluralize from "pluralize";
import Button from "@mui/material/Button";

import Notification from "./Notification";

const NotificationContentWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

const NotificationContent = styled.div`
  align-items: center;
  display: flex;
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
`;

const NewMessageCount = styled.div`
  align-items: center;
  background: ${(props) => {
    return props.theme.colors.secondary.main;
  }};
  border-radius: 50%;
  color: ${(props) => {
    return props.theme.colors.primary.contrastText;
  }};
  display: flex;
  height: 25px;
  justify-content: center;
  margin-right: 5px;
  width: 25px;
`;

const JumpButton = styled(Button)``;

class NewMessageNotification extends Component {
  static propTypes = {
    closeHandler: PropTypes.func.isRequired,
    jumpHandler: PropTypes.func.isRequired,
    newMessageCount: PropTypes.number,
  };

  render() {
    const { closeHandler, jumpHandler, newMessageCount } = this.props;
    return (
      <Notification closeHandler={closeHandler} isActive={newMessageCount > 0}>
        <NotificationContentWrapper>
          <NotificationContent>
            <NewMessageCount>{newMessageCount}</NewMessageCount>
            {`new ${pluralize("message", newMessageCount)}`}
          </NotificationContent>
          <JumpButton color="inherit" onClick={jumpHandler}>
            Jump Down
          </JumpButton>
        </NotificationContentWrapper>
      </Notification>
    );
  }
}

export default NewMessageNotification;
