import { BlankStateV2 } from "components/BlankState";
import Logo from "components/Logo";

interface ErrorComponentProps {
  title: string;
  subtitle?: string;
  styles?: Record<string, any>;
}

function ErrorComponent({
  title,
  subtitle = "Try again later.",
  styles = {},
}: ErrorComponentProps) {
  return (
    <BlankStateV2
      title={title}
      subTitle={subtitle}
      image={<Logo color="disabled" />}
      styles={styles}
    />
  );
}

export default ErrorComponent;
