import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Typography,
} from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { Account } from "@tesseract/core";
import { transparentize } from "polished";
import { IntroAndAppendedFormValues, MessageDirection } from "../types";
import { messageSettings, messageTypes, messagePositions } from "../constants";
import { useMessageSettingsCard } from "../hooks";
import {
  SettingsCard,
  SettingsCardActions,
  SettingsCardAlert,
  SettingsCardContent,
  SettingsCardHeader,
} from "features/Settings";
import { SimpleDialog } from "components/SimpleDialog";
import MessageBubble from "components/ConversationThread/MessageBubble";
import { BlankAvatar } from "components/BlankAvatar";
import MessageInputFormRow from "components/MessageInputFormRow";
import { Input } from "components/Input";
import { LockIcon } from "icons";

function IntroMessageSettingsCard({ account }: { account?: Account.Raw }) {
  const { errors, touched, values, setFieldValue, submitForm } =
    useFormikContext<IntroAndAppendedFormValues>();
  const { introMessages } = account?.settings ?? {};
  const { fields, maxChars, placeholder } =
    messageSettings[messageTypes.introduction];

  const {
    admin,
    lockedAtAccountName,
    showPreview,
    shortenedLink,
    handleClosePreview,
    handleOpenPreview,
    setShortenedLink,
    toggleLock,
  } = useMessageSettingsCard(messageTypes.introduction, account);

  const { introMessages: introMessageFormValues } = values;
  const {
    locked,
    value: { messageEnabled, position, frequency, message },
  } = introMessageFormValues;
  const resend = frequency !== null;
  const { before, after } = messagePositions;

  const getPreviewMessage = () => {
    return position === before
      ? `<p>${message}</p><p>{User's message content}</p><p>{User's Signature if any}</p>`
      : `<p>{User's message content}</p><p>{User's Signature if any}</p><p>${message}</p>`;
  };

  const messageValues = {
    formattedBody: getPreviewMessage(),
    body: message,
    direction: "out" as MessageDirection,
    source: "textus",
  };

  return (
    <>
      <SettingsCard
        sx={(theme) => {
          return {
            [theme.breakpoints.up("lg")]: {
              maxWidth: "23rem",
            },
          };
        }}
      >
        <Field name={fields.enabled}>
          {({ field, form }: FieldProps) => {
            return (
              <SettingsCardHeader
                account={account}
                active={field.value}
                canEditLock={!introMessages?.disabled}
                locked={locked || !admin}
                title="Use introduction"
                onActiveChange={() => {
                  form.setFieldValue(field.name, !field.value);
                }}
                onLockedChange={
                  admin
                    ? () => {
                        toggleLock(values, setFieldValue);
                        return submitForm();
                      }
                    : undefined
                }
                sx={{ marginBottom: messageEnabled ? 1 : 0, padding: "0.5rem" }}
              />
            );
          }}
        </Field>
        {admin ? null : (
          <SettingsCardAlert severity="info" icon={<LockIcon />}>
            This setting can only be modified by your administrator.
          </SettingsCardAlert>
        )}
        {locked && admin ? (
          <SettingsCardAlert severity="info" icon={<LockIcon />}>
            {lockedAtAccountName === "" ? (
              <Skeleton
                width="18rem"
                sx={(theme) => {
                  return {
                    // TODO: Fix this to connect to new MUI theme values
                    backgroundColor: "rgba(40, 60, 89, 0.3)",
                  };
                }}
              />
            ) : (
              <>
                Locked by an admin for accounts below{" "}
                <strong>{lockedAtAccountName}</strong>.
              </>
            )}
          </SettingsCardAlert>
        ) : null}
        {messageEnabled && account ? (
          <>
            <SettingsCardContent>
              <MessageInputFormRow
                currentAccount={account}
                errors={errors}
                fieldName={fields.message}
                isDisabled={locked}
                hideUserPersonalization={false}
                maxChars={maxChars}
                placeholder={placeholder}
                setFieldValue={setFieldValue}
                setShortenedLink={setShortenedLink}
                shortenedLink={shortenedLink}
                touched={touched}
                values={values}
              />
              <Field name={fields.position}>
                {({ field, form }: FieldProps) => {
                  return (
                    <RadioGroup value={field.value}>
                      <Typography variant="h6">
                        Position the intro message:
                      </Typography>
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={field.value === before}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, before);
                            }}
                            data-testid="before-message-radio"
                          />
                        }
                        label="Before message content (default)"
                      />
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={field.value === after}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, after);
                            }}
                            data-testid="after-message-radio"
                          />
                        }
                        label="After message content"
                      />
                    </RadioGroup>
                  );
                }}
              </Field>
              <Field name={fields.frequency}>
                {({ field, form }: FieldProps) => {
                  return (
                    <RadioGroup sx={{ marginTop: "1rem" }}>
                      <Typography variant="h6">
                        Resend the intro message:
                      </Typography>
                      <FormControlLabel
                        control={
                          <Radio
                            {...field}
                            color="secondary"
                            checked={!resend}
                            disabled={locked}
                            onChange={() => {
                              form.setFieldValue(field.name, null);
                            }}
                            data-testid="never-resend-radio"
                          />
                        }
                        label="Never (default)"
                      />
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="flex-start"
                        sx={(theme) => {
                          return {
                            [theme.breakpoints.down("md")]: {
                              flexDirection: "column",
                              alignItems: "flex-start",
                            },
                          };
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Radio
                              {...field}
                              color="secondary"
                              checked={resend}
                              disabled={locked}
                              onChange={() => {
                                form.setFieldValue(field.name, 365);
                              }}
                              data-testid="resend-radio"
                            />
                          }
                          label="Every X Days:"
                          sx={{ marginBottom: "1rem", minWidth: "8.8rem" }}
                        />
                        <Input
                          name={field.name}
                          label="Days"
                          onChange={(event) => {
                            // Workaround to avoid using known issues with <input type="number" />
                            // More: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
                            const number = Number(event.target.value);
                            if (
                              !Number.isNaN(number) &&
                              number < Number.MAX_SAFE_INTEGER
                            ) {
                              form.setFieldValue(field.name, number);
                            }
                          }}
                          disabled={locked}
                          data-testid="intro-message-frequency-input"
                          inputProps={{ pattern: "\\d*", maxLength: 3 }} // Allows numbers only, up to 999
                        />
                      </Box>
                    </RadioGroup>
                  );
                }}
              </Field>
            </SettingsCardContent>
            <SettingsCardActions sx={{ justifyContent: "flex-end" }}>
              <Button
                onClick={handleOpenPreview}
                variant="outlined"
                data-testid="preview-intro-message-button"
              >
                Preview
              </Button>
            </SettingsCardActions>
          </>
        ) : null}
      </SettingsCard>

      {/* Preview dialog */}
      <SimpleDialog
        open={showPreview}
        handleClose={handleClosePreview}
        title="Preview introduction"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="flex-end"
          margin="1rem"
          paddingLeft="0.25rem"
        >
          <MessageBubble message={messageValues} />
          <BlankAvatar sx={{ marginLeft: "0.25rem" }} />
        </Box>
      </SimpleDialog>
    </>
  );
}

export { IntroMessageSettingsCard };
