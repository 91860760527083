import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  ListItemButton,
  ListItemButtonProps,
  Typography,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    backgroundColor: "#FAFAFA",
    margin: "0",
    width: "100%",
    "&.MuiAccordion-root": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "0",
      boxShadow: "none",
    },
    "&.Mui-expanded, &.Mui-expanded:last-of-type": {
      margin: "0",
    },
    "& .MuiAccordionSummary-root": {
      flexDirection: "row-reverse",
      "&.Mui-expanded": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  };
});

export const StyledAccordionSummary = styled((props: AccordionSummaryProps) => {
  return <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />;
})({
  height: "56px",
  "&.Mui-expanded": {
    minHeight: "56px",
  },
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    margin: "0",
    "&.Mui-expanded": {
      margin: "0",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    margin: "0 12px 0 -4px",
  },
});

export const StyledTypography = styled(AccordionDetails)({
  color: "#283C59",
  fontWeight: "500",
  fontSize: "14px",
  padding: "0",
});

export const NoSequencesCreated = (
  <Typography
    bgcolor="#fff"
    color="textSecondary"
    fontSize="14px"
    paddingY="24px"
    textAlign="center"
  >
    No sequences created yet
  </Typography>
);

export const StyledAccordionDetails = styled(AccordionDetails)({
  maxHeight: "408px",
  padding: "0",
  overflowY: "scroll",
});

export const StyledListItemButton = styled((props: ListItemButtonProps) => {
  return <ListItemButton {...props} />;
})({
  alignItems: "flex-start",
  backgroundColor: "#fff",
  flexFlow: "column",
  padding: "0.75rem 1rem",
});
