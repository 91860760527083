import { combineReducers } from "redux-immutable";
import { takeLatest, all } from "redux-saga/effects";

import * as schema from "schema";
import generateContainerState from "utils/generateContainerState";

const { actionTypes, actionGenerators, sagas, reducers, selectors } =
  generateContainerState([
    {
      container: "ScheduledMessages",
      crudActions: ["update", "delete"],
      recordType: "message",
      schema: schema.message,
    },
  ]);

// Reducer
const reducer = combineReducers(reducers);

// SAGAS
function* saga() {
  yield all([
    takeLatest(actionTypes.UPDATE_MESSAGE_REQUEST, sagas.updateMessageRequest),
    takeLatest(actionTypes.DELETE_MESSAGE_REQUEST, sagas.deleteMessageRequest),
  ]);
}

export { actionTypes, actionGenerators, reducer, selectors, saga };
