import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";
import { normalize } from "normalizr";

import { makeRequest } from "utils/generateContainerState/generateSagas";
import {
  selectCurrentAccount,
  selectRecords,
} from "features/EntryPoint/containers/App/selectors";
import {
  updateExtractedPhones,
  updateRecords,
} from "features/EntryPoint/containers/App/actions";
import * as schema from "schema";
import denormalizeWithShape from "utils/denormalizeWithShape";
import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";

// ACTION TYPES
const actionTypes = {
  ...generateActionTypes({
    container: "ExtensionContactImportContainer",
    actions: ["create"],
    recordType: "contactImport",
  }),
  CLEAR_CONTACT_IMPORT:
    "app/ExtensionContactImportContainer/CLEAR_CONTACT_IMPORT",
};

// ACTION GENERATORS
const actionGenerators = generateActionGenerators(actionTypes);

// SAGA
const sagas = {
  *createContactImportRequest({ params }) {
    try {
      const { file, source } = params;
      const currentAccount = yield select(selectCurrentAccount);
      const { signedUrl } = yield makeRequest({
        url: currentAccount.presignContactImport,
        params: { filename: file.name },
        method: "POST",
      });
      yield call(fetch, signedUrl, {
        method: "PUT",
        body: file,
      });
      const contactImport = yield makeRequest({
        url: currentAccount.contactImport,
        params: {
          headers: ["phoneNumber", "firstName", "lastName"],
          url: signedUrl,
          ...(source ? { method: "extension", source } : {}),
        },
        method: "POST",
      });
      const { entities } = normalize(contactImport, schema.contactImport);
      yield put(updateRecords(entities));
      yield put(actionGenerators.createContactImportSuccess(contactImport));
      yield put(updateExtractedPhones([]));
    } catch (error) {
      yield put(actionGenerators.createContactImportFailure(error));
      yield put(updateExtractedPhones([]));
    }
  },
};

function* saga() {
  yield all([
    takeLatest(
      actionTypes.CREATE_CONTACT_IMPORT_REQUEST,
      sagas.createContactImportRequest,
    ),
  ]);
}

const contactImportId = (state = null, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_IMPORT_SUCCESS:
      return action.response.id;
    case actionTypes.CREATE_CONTACT_IMPORT_REQUEST:
    case actionTypes.CREATE_CONTACT_IMPORT_FAILURE:
    case actionTypes.CLEAR_CONTACT_IMPORT:
      return null;
    default:
      return state;
  }
};

const reducer = combineReducers({ contactImportId });

const selectContactImportId = (state) => {
  return state.getIn(["ExtensionContactImportContainer", "contactImportId"]);
};
const selectContactImport = createSelector(
  selectRecords,
  selectContactImportId,
  (records, id) => {
    return !records.get(id) || typeof records.get(id) === "string"
      ? {}
      : denormalizeWithShape({ id, records: records.toJS(), shape: {} });
  },
);

const selectors = { selectContactImport };

export { actionTypes, actionGenerators, reducer, sagas, saga, selectors };
