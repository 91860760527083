/**
 * Sorts an array of strings in ascending or descending order.
 * This function uses the `localeCompare` method to compare strings,
 * which means it also sorts strings containing numbers in a natural way (e.g., "10" comes after "2").
 *
 * @param {string[]} array - The array of strings to be sorted.
 * @param {string} [direction="asc" | "desc"] - A string indicating the sort direction.
 * @returns {string[]} The sorted array.
 *
 * @example
 * // returns ['1 item', '2 items', '10 items']
 * sortStringArray(['10 items', '1 item', '2 items'])
 *
 */

export function sortStringArray(array: string[], direction?: "asc" | "desc") {
  return array.sort((a, b) => {
    return direction === "desc"
      ? b.localeCompare(a, undefined, { numeric: true })
      : a.localeCompare(b, undefined, { numeric: true });
  });
}
