import { Contact } from "@tesseract/core";
import { useCallback, useState } from "react";
import { fetchContact } from "../api/fetchContact";

export const useContact = () => {
  const [contact, setContact] = useState<Contact.Raw>();

  const [state, setState] = useState<"waiting" | "loading" | "error" | "done">(
    "waiting",
  );
  const [error, setError] = useState<Error>();

  const load = useCallback((id: Contact.Identity) => {
    setState("loading");
    setError(undefined);

    const controller = new AbortController();

    fetchContact(id, {
      signal: controller.signal,
    })
      .then((response) => {
        return response.json();
      })
      .then((data: Contact.Raw) => {
        setContact(data);
        setState("done");

        return data;
      })

      .catch((error_) => {
        setState("error");
        setError(error_);
      });

    return () => {
      setState("waiting");
      controller.abort();
    };
  }, []);

  return {
    load,
    contact,
    state,
    error,
  };
};
