import {
  DropzoneOptions,
  useDropzone as useDropzoneBase,
} from "react-dropzone";
import { useCallback } from "react";
import { RejectedFiles } from "./types";

const getErrorMessage = (maxFileSize: number, rejectedFiles: RejectedFiles) => {
  const code = rejectedFiles?.[0]?.errors[0].code;
  const type = rejectedFiles?.[0]?.file.type;
  const styledMaxFileSize =
    maxFileSize > 1000000
      ? `${maxFileSize / 1000000}MB`
      : `${maxFileSize / 1000}KB`;
  switch (code) {
    case "file-invalid-type":
      return `File type ${type} is not supported. Please try again with a supported file type.`;
    case "file-too-large":
      return `The file is too large. The maximum file size is ${styledMaxFileSize}. Please try again with a smaller file.`;
    default:
      return `Something went wrong. Please try again with a valid file.`;
  }
};

type Require<Obj extends object, Key extends keyof Obj> = Required<
  Pick<Obj, Key>
> &
  Omit<Obj, Key>;

type UseDropzoneParameters = Require<DropzoneOptions, "maxSize"> & {
  onFileAccepted?: (file: File) => void;
  onFileRejected?: (rejectedFiles: RejectedFiles) => void;
};

export const useDropzone = ({
  onDrop,
  maxSize,
  onFileAccepted,
  onFileRejected,
  ...options
}: UseDropzoneParameters) => {
  const handleDrop = useCallback(
    (
      ...parameters: Parameters<NonNullable<UseDropzoneParameters["onDrop"]>>
    ) => {
      const [acceptedFiles, fileRejections] = parameters;

      const acceptedFile = acceptedFiles[0];
      const rejectedFiles = [...fileRejections];

      onDrop?.(...parameters);

      if (acceptedFile) onFileAccepted?.(acceptedFile);

      if (fileRejections.length && !acceptedFile) {
        rejectedFiles[0].errors[0].message = getErrorMessage(
          maxSize,
          rejectedFiles,
        );
        onFileRejected?.(rejectedFiles);
      }
    },
    [maxSize, onDrop, onFileAccepted, onFileRejected],
  );

  return useDropzoneBase({
    onDrop: handleDrop,
    maxSize,
    ...options,
  });
};
