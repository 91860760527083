import { useState } from "react";
import { CreateScreen } from "./components/CreateScreen";
import { CreateSequenceModalProps } from "./types";
import { MODAL_SELECTIONS } from "./constants";
import { SelectionValues } from "./components/NewSequenceForm/types";
import { FormsScreen } from "./components/FormsScreen";
import { Blueprint } from "./components/Blueprints/types";

function CreateSequenceModal({
  close,
  handleBack,
  handleDuplicateSequenceClick,
  openDuplicateDrawer,
  initialSelection,
  sequenceValues,
}: CreateSequenceModalProps) {
  const { blueprints, create, initial } = MODAL_SELECTIONS;

  const [selection, setSelection] = useState<SelectionValues>(initialSelection);

  const handleNewClick = () => {
    setSelection(create);
  };

  const handleDuplicateClick = () => {
    if (openDuplicateDrawer) {
      openDuplicateDrawer();
    }

    close(false)();
  };

  const handleBlueprintsClick = () => {
    setSelection(blueprints);
  };

  const handleBlueprintsBack = () => {
    setSelection(initial);
  };

  const handleChooseBlueprint = (blueprint: Blueprint) => {
    if (handleDuplicateSequenceClick) {
      handleDuplicateSequenceClick(blueprint);
    }
    close(false)();
  };

  return selection ? (
    <FormsScreen
      close={close}
      handleBack={handleBack}
      selection={selection}
      sequenceValues={sequenceValues}
      handleBlueprintsBack={handleBlueprintsBack}
      handleChooseBlueprint={handleChooseBlueprint}
    />
  ) : (
    <CreateScreen
      close={close}
      handleBlueprintsClick={handleBlueprintsClick}
      handleDuplicateClick={handleDuplicateClick}
      handleNewClick={handleNewClick}
    />
  );
}

export { CreateSequenceModal };
