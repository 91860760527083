import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { ChangeEvent } from "react";

import { useBillingFilter } from "../hooks/useBillingFilter";

type BillingFilterProps = {
  label?: string;
  selectHandler?: (selectedTerm: ChangeEvent<HTMLInputElement>) => void;
  menuOptions?: any[];
  selectedOption?: string;
};

function BillingFilter({
  label,
  selectHandler,
  menuOptions,
  selectedOption,
}: BillingFilterProps) {
  const { monthOptions, handleSelectMonth, selectedMonth } = useBillingFilter();

  const renderMenuOptions = () => {
    return menuOptions
      ? menuOptions.map((option) => {
          return (
            <MenuItem
              value={option}
              key={option}
              data-testid={`${option} option`}
            >
              {option}
            </MenuItem>
          );
        })
      : monthOptions.map(({ value }) => {
          return (
            <MenuItem value={value} key={value} data-testid={`${value} option`}>
              {value}
            </MenuItem>
          );
        });
  };

  return (
    <Box
      alignItems="center"
      aria-label={`${label} Billing Filter`}
      display="flex"
      flexDirection={{ xs: "column", sm: "row" }}
      flex="0 0 auto"
      justifyContent="flex-start"
    >
      <Box
        width={{ xs: "100%", sm: "200px" }}
        mr={{ xs: 0, sm: 2 }}
        mb={{ xs: 2, sm: 0 }}
      >
        <TextField
          data-testid={`${label} Billing Filter`}
          fullWidth
          label={label}
          select
          onChange={selectHandler || handleSelectMonth}
          value={selectedOption || selectedMonth}
          SelectProps={{
            MenuProps: {
              hideBackdrop: true,
            },
          }}
        >
          {renderMenuOptions()}
        </TextField>
      </Box>
    </Box>
  );
}

export default BillingFilter;
