import type { Account, BullhornCandidate, Contact } from "@tesseract/core";
import { identifyAccount, identifyContact } from "@tesseract/core";
import { textUsApiV4 } from "services";

export function unlinkCandidate(
  account: Account.Like,
  contact: Contact.Like,
  integrationName: string,
  options?: RequestInit,
) {
  const accountId = identifyAccount(account);
  const contactId = identifyContact(contact);

  return textUsApiV4.destroy(
    `/${accountId}/contacts/${contactId}/integrations/${integrationName}`,
    options,
  );
}
