import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ContactSyncHyperlink } from "./ContactSyncHyperlink";
import { Contact } from "features/ContactSidebar/types";
import { ContactSyncIcon } from "features/ContactSync/ContactSyncIcon";

interface ContactDetailProps {
  contact: Contact;
  conversationName: string;
  number: string;
}

function ContactDetails({
  contact,
  conversationName,
  number,
}: ContactDetailProps) {
  return (
    <Box
      mr="20px"
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      justifyContent="center"
      whiteSpace="nowrap"
      textOverflow="ellipsis"
      overflow="hidden"
      minWidth={0}
    >
      <Box display="flex" flexDirection="row" width="12rem" alignItems="center">
        <Typography
          key="contact-name"
          variant="h6"
          color="primary.main"
          sx={(theme) => {
            return {
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            };
          }}
        >
          {conversationName || number}
        </Typography>
        <ContactSyncIcon contact={contact} />
      </Box>
      <Box display="flex" flexDirection="row" width="12rem" alignItems="center">
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ whiteSpace: "nowrap" }}
        >
          {conversationName && number}
        </Typography>
        <ContactSyncHyperlink contact={contact} />
      </Box>
    </Box>
  );
}

export default ContactDetails;
