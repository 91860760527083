import { combineReducers } from "redux-immutable";
import { takeLatest, all } from "redux-saga/effects";

import * as schema from "schema";
import generateContainerState from "utils/generateContainerState";

const { actionTypes, actionGenerators, sagas, reducers, selectors } =
  generateContainerState([
    {
      container: "ContactAssignment",
      crudActions: ["assign"],
      recordType: "conversation",
      schema: schema.conversation,
    },
  ]);

// Reducer
const reducer = combineReducers(reducers);

// SAGAS
function* saga() {
  yield all([
    takeLatest(
      actionTypes.ASSIGN_CONVERSATION_REQUEST,
      sagas.assignConversationRequest,
    ),
  ]);
}

export { actionTypes, actionGenerators, reducer, selectors, saga };
