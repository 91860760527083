import InfiniteScroll from "react-infinite-scroller";
import debounce from "lodash/debounce";
import get from "lodash/get";
import { Box, Typography, Skeleton, useMediaQuery, Theme } from "@mui/material";
import { ConversationCollectionProps } from "./types";
import ConversationCollectionItem from "features/Inbox/containers/ConversationCollectionItem";
import SCHEDULED_FILTERS from "features/Inbox/constants/scheduledFilters";
import { NoResultsScreen } from "components/Search/NoResultsScreen";
import { useCurrentAccount } from "hooks";

function LoadingSkeleton() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="1rem"
      sx={{ backgroundColor: "background.paper" }}
    >
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Skeleton width="45%" />
        <Skeleton width="30%" />
      </Box>
      <Skeleton />
      <Skeleton />
    </Box>
  );
}

export default function Root({
  conversationCollection,
  conversationFilter,
  selectedConversations,
  selectedRecords,
  setSelected,
  setSelectedRecords,
  fetchConversationCollectionRequest,
}: ConversationCollectionProps) {
  const debouncedHandleLoadMore = debounce(() => {
    fetchConversationCollectionRequest(conversationCollection.view.next);
  }, 300);

  const getSortedConversations = () => {
    const conversations = conversationCollection.members;
    if (conversationFilter.slug === "search") {
      return conversations;
    }
    if (SCHEDULED_FILTERS.includes(conversationFilter.slug)) {
      return conversations.slice().sort((a, b) => {
        const aPreviewMessageTime = get(
          a,
          ["nextScheduledMessage", "timelinePosition"],
          Date.now(),
        );
        const bPreviewMessageTime = get(
          b,
          ["nextScheduledMessage", "timelinePosition"],
          Date.now(),
        );
        return (
          Number(new Date(aPreviewMessageTime)) -
          Number(new Date(bPreviewMessageTime))
        );
      });
    }
    return conversations.slice().sort((a, b) => {
      const aPreviewMessageTime: string | number =
        get(a, ["latestPreviewableItem", "timelinePosition"], 0) ||
        get(a, ["nextScheduledMessage", "timelinePosition"], 0);
      const bPreviewMessageTime: string | number =
        get(b, ["latestPreviewableItem", "timelinePosition"], 0) ||
        get(b, ["nextScheduledMessage", "timelinePosition"], 0);
      return (
        Number(new Date(bPreviewMessageTime)) -
        Number(new Date(aPreviewMessageTime))
      );
    });
  };

  const sortedConversations = getSortedConversations();

  const hasConvos = conversationCollection.members.length;

  const currentAccount = useCurrentAccount();

  const mdAndUp = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("md");
  });

  return (
    <Box
      sx={{
        WebkitOverflowScrolling: "touch",
        flex: "1 auto",
        overflowY: hasConvos ? "scroll" : "hidden",
      }}
    >
      <InfiniteScroll
        hasMore={!!conversationCollection.view.next}
        initialLoad={false}
        loader={<LoadingSkeleton />}
        loadMore={debouncedHandleLoadMore}
        threshold={100}
        useWindow={false}
      >
        {sortedConversations.map((conversation) => {
          return (
            <ConversationCollectionItem
              key={conversation.id}
              conversation={conversation}
              conversationFilter={conversationFilter}
              selectedConversations={selectedConversations}
              selectedRecords={selectedRecords}
              setSelected={setSelected}
              setSelectedRecords={setSelectedRecords}
            />
          );
        })}
      </InfiniteScroll>

      {hasConvos && !conversationCollection.view.next ? (
        <Typography
          variant="subtitle2"
          fontSize="14px"
          textAlign="center"
          padding="10vh 0"
          sx={(theme) => {
            return { color: theme.palette.text.secondary };
          }}
        >
          All conversations loaded
        </Typography>
      ) : null}
      {!hasConvos && !mdAndUp ? (
        <NoResultsScreen
          source="conversations"
          active={Boolean(document.location.search)}
        />
      ) : null}
      {!hasConvos && mdAndUp ? (
        <Typography
          variant="subtitle2"
          fontSize="14px"
          textAlign="center"
          padding="40vh 0"
          sx={(theme) => {
            return {
              color: theme.palette.text.secondary,
            };
          }}
        >
          No conversations found
        </Typography>
      ) : null}
    </Box>
  );
}
