import Box from "@mui/material/Box";
import BusinessIcon from "@mui/icons-material/Business";
import Chip from "@mui/material/Chip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PhoneIcon from "@mui/icons-material/Phone";
import Typography from "@mui/material/Typography";

// eslint-disable-next-line react/prop-types
function ContactSearchInfo() {
  return (
    <Box width="100%">
      <Box component="header" p={2} pb={0}>
        <Typography component="h6" style={{ fontWeight: 700 }}>
          Search Tips
        </Typography>
      </Box>
      <List>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <PhoneIcon />
          </ListItemIcon>
          <ListItemText primary="You can search by phone numbers by entering at least 3 digits" />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <BusinessIcon />
          </ListItemIcon>
          <ListItemText
            className="businesses-list-item"
            disableTypography
            primary={
              <Typography>
                You can search for businesses with the following syntax
              </Typography>
            }
            secondary={
              <Box>
                <Chip label="business:TextUs" />
                <Chip label="business:&#34;Biz Name&#34;" />
              </Box>
            }
          />
        </ListItem>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText
            className="tags-list-item"
            disableTypography
            primary={
              <Typography>
                You can search for tags with the following syntax
              </Typography>
            }
            secondary={
              <Box>
                <Chip label="tags:tag1" />
                <Chip label="tags:&#34;tag with spaces&#34;" />
              </Box>
            }
          />
        </ListItem>
      </List>
    </Box>
  );
}

export default ContactSearchInfo;
