import { ReactNode } from "react";

import Typography from "@mui/material/Typography";

import { AccountPlan, AccountPlanCards } from "../models/AccountPlanModels";
import {
  HeaderText,
  LinkText,
  StyledCard,
  SubHeaderText,
} from "../styles/styles";

const getAccountPlanValues = (accountPlan: AccountPlan): ReactNode => {
  const { assignments, quantity, shared, type } = accountPlan;
  const remainingLicenses = quantity - assignments.length || 0;
  const accountPlanValues: AccountPlanCards = {
    campaigns: {
      text: (
        <SubHeaderText align="center" color="textPrimary">
          {remainingLicenses} of {quantity} Licenses Available
        </SubHeaderText>
      ),
      title: "Campaigns Pro",
    },
    support: {
      text: (
        <Typography
          align="center"
          color="textPrimary"
          sx={{ fontSize: ".75rem", lineHeight: ".85rem" }}
        >
          Contact you account manager or email{" "}
          <LinkText href="mailto:support@textus.com">
            support@textus.com
          </LinkText>{" "}
          to enable
        </Typography>
      ),
      title: "Premium Support",
    },
    analytics: {
      text: (
        <SubHeaderText align="center" color="textPrimary">
          {shared ? "Enabled" : "Not enabled"}
        </SubHeaderText>
      ),
      title: "Analytics Pro",
    },
    keywords: {
      text: (
        <SubHeaderText align="center" color="textPrimary">
          {remainingLicenses} of {quantity} Licenses Available
        </SubHeaderText>
      ),
      title: "Keywords",
    },
    sequences: {
      text: (
        <SubHeaderText align="center" color="textPrimary">
          {remainingLicenses} of {quantity} Licenses Available
        </SubHeaderText>
      ),
      title: "Sequences",
    },
  };

  const { title, text } = accountPlanValues[type] ?? {};

  return (
    <StyledCard aria-label={`${title?.toLowerCase()}-card`}>
      <HeaderText align="center">{title}</HeaderText>

      {text}

      <Typography
        variant="caption"
        color="textSecondary"
        padding="0.1rem"
      >{`${assignments.length} used`}</Typography>
    </StyledCard>
  );
};

export default getAccountPlanValues;
