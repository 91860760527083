import { textUsApiV3 } from "services";

export const postJobImport = async (
  url: string,
  type: string,
  id: string,
  source?: string,
  stages?: string[],
): Promise<Response> => {
  return textUsApiV3.post(url, {
    // Luke TODO: do need to allow `source` as a parameter rather than static? That way we can pass in `greenhouse` as well
    source,
    method: "extension",
    type,
    id,
    stages,
  });
};
