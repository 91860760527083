import React from "react";
import Button from "@mui/material/Button";

function ReloadButton(): React.ReactElement {
  const handleReload = () => {
    document.location.reload();
  };

  return (
    <Button onClick={handleReload} color="inherit">
      Reload
    </Button>
  );
};

export default ReloadButton;
