import { Switch, Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";
import { Component } from "react";

import InboxRoot from "features/Inbox/containers/InboxRoot";

const OLD_CONVERSATION_FILTER_SLUGS = [
  "active",
  "admin-active",
  "new",
  "unassigned",
];

export default class Inbox extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  render() {
    const { currentAccount, match } = this.props;
    const renderFunction = (props) => {
      return <InboxRoot {...props} inboxId={currentAccount.inbox} />;
    };
    return (
      <Switch>
        <Route
          path={OLD_CONVERSATION_FILTER_SLUGS.map((slug) => {
            return `${match.path}/${slug}`;
          })}
          render={() => {
            return <Redirect to={match.url.replace("//", "/")} />;
          }}
        />
        <Route
          path={`${match.path}/:activeConversationFilterSlug/:activeConversationSlug`}
          render={renderFunction}
        />
        <Route
          path={`${match.path}/:activeConversationFilterSlug`}
          render={renderFunction}
        />
        <Route path={`${match.path}`} render={renderFunction} />
      </Switch>
    );
  }
}
