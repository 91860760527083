import { NavLink } from "react-router-dom";
import { styled } from "@mui/material";
import { SecondaryLinksProps } from "./types";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";

const SequencesNavLink = styled(NavLink)(({ theme }) => {
  return {
    alignItems: "center",
    color: theme.palette.text.primary,
    display: "flex",
    padding: "0.75rem 3rem",
    textDecoration: "none",

    ":hover": {
      background: theme.palette.action.hover,
    },
  };
});

function SecondaryLinks({
  redirectUrl,
  selectedNavLink,
  setSelectedNavLink,
}: SecondaryLinksProps) {
  const { mine, others } = SECONDARY_NAV_LINKS;

  const sequencesSettings = [
    {
      title: mine,
      to: redirectUrl,
    },
    {
      title: others,
      to: redirectUrl,
    },
  ];

  return (
    <>
      {sequencesSettings.map(({ title, to }) => {
        const isSelected = selectedNavLink === title;
        return (
          <SequencesNavLink
            key={title}
            to={to}
            onClick={() => {
              return setSelectedNavLink(title);
            }}
            sx={(theme) => {
              return {
                fontWeight: isSelected
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightRegular,
                backgroundColor: isSelected
                  ? theme.palette.lightBlue.A12
                  : theme.palette.background.default,
              };
            }}
          >
            {title}
          </SequencesNavLink>
        );
      })}
    </>
  );
}

export { SecondaryLinks };
