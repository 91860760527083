import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionSummaryProps,
  Chip,
  Divider,
  DividerProps,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const StyledAccordion = styled(Accordion)(({ theme }) => {
  return {
    margin: "0 0 24px 0",
    width: "100%",
    "&.Mui-expanded, &.Mui-expanded:last-of-type": {
      margin: "0 0 24px 0",
    },
    "& .MuiAccordionSummary-root": {
      flexDirection: "row-reverse",
      "&.Mui-expanded": {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
    },
  };
});

export const StyledAccordionDetails = styled(AccordionDetails)({
  alignItems: "center",
  display: "flex",
  padding: "13px 24px",
});

export const StyledAccordionSummary = styled((props: AccordionSummaryProps) => {
  return <AccordionSummary expandIcon={<ExpandMoreIcon />} {...props} />;
})({
  height: "56px",
  "&.Mui-expanded": {
    minHeight: "56px",
  },
  "& .MuiAccordionSummary-content": {
    alignItems: "center",
    margin: "0",
    "&.Mui-expanded": {
      margin: "0",
    },
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    margin: "0 28px 0 18px",
  },
});

export const StyledChip = styled(Chip)({
  borderRadius: "4px",
  height: "26px",
  margin: "0 12px 0 0",
  pointerEvents: "none",
  "& .MuiChip-label": {
    fontSize: "13px",
    padding: "0 4px",
  },
  "&.finished": {
    background: "#DBFFCE",
    "& .MuiChip-label": {
      color: "#396A31",
    },
  },
  "&.pending": {
    background: "#D2E6FF",
    "& .MuiChip-label": {
      color: "#555F83",
    },
  },
  "&.failed, &.omitted": {
    background: "#FFD3D3",
    "& .MuiChip-label": {
      color: "#6B4D4D",
    },
  },
});

export const StyledDivider = styled((props: DividerProps) => {
  return <Divider orientation="vertical" flexItem {...props} sx={{}} />;
})({
  alignSelf: "center",
  height: "8px",
});
