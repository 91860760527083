export const getMessageBackgroundColor = (props) => {
  if (props.status === "stale")
    return props.theme.colors.message.staleBackgroundColor;
  if (props.status === "superStale")
    return props.theme.colors.message.superStaleBackgroundColor;
  return props.theme.colors.message.newBackgroundColor;
};

export const getMessageBorderColor = (props) => {
  if (props.status === "stale")
    return props.theme.colors.message.staleBorderColor;
  if (props.status === "superStale")
    return props.theme.colors.message.superStaleBorderColor;
  return props.theme.colors.message.newBorderColor;
};
