import { Component } from "react";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class SavedReplyCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "features/SavedReplies/containers/SavedReplyCollection",
  shape: { members: [{ user: {} }] },
  type: "savedReplyCollection",
})(SavedReplyCollection);
