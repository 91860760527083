import { Account, GreenhouseCandidate, Contact } from "@tesseract/core";
import { useCallback, useState } from "react";
import { fetchCandidates } from "../api/fetchCandidates";

export const useGreenhouseCandidates = (
  account: Account.Like,
  contact: Contact.Like,
) => {
  const [candidates, setCandidates] =
    useState<Record<string, GreenhouseCandidate.Raw>>();

  const [state, setState] = useState<"waiting" | "loading" | "error" | "done">(
    "waiting",
  );
  const [error, setError] = useState<Error>();
  const [abort, setAbort] = useState<AbortController["abort"]>();

  const load = useCallback(() => {
    setState("loading");
    setError(undefined);

    const controller = new AbortController();

    setAbort(() => {
      return () => {
        controller.abort();
      };
    });

    fetchCandidates(account, contact, "greenhouse", {
      signal: controller.signal,
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCandidates(
          Object.fromEntries(
            data.map((candidate: GreenhouseCandidate.CandidateSearchObject) => {
              return [
                `${candidate.id}`,
                /*
                  The following object is the same as the data object, but with some fields removed.
                  We need to reformat the data object to match the GreenhouseCandidate.Raw type.
                */
                {
                  applications:
                    candidate.applications.length > 0
                      ? candidate.applications.map((application) => {
                          return {
                            current_stage: {
                              id: application?.currentStage.id,
                              name: application.currentStage.name,
                            },
                            id: application.id,
                            jobs: application.jobs,
                            status: application.status,
                          };
                        })
                      : [],
                  current_application_id: candidate.currentApplication ? candidate.currentApplication.id : null,
                  emails: candidate.emails || [],
                  first_name: candidate.name.split(" ")[0] || candidate.name,
                  id: candidate.id || "",
                  last_name: candidate.name.split(" ")[1] || "",
                },
              ];
            }),
          ),
        );
        setState("done");
        setAbort(undefined);

        return data;
      })

      .catch((error_) => {
        setState("error");
        setError(error_);
        setAbort(undefined);
      });
  }, [account, contact]);

  return {
    load,
    candidates,
    state,
    error,
    abort: abort
      ? () => {
          abort?.();
          setState("waiting");
          setError(undefined);
          setAbort(undefined);
          setCandidates(undefined);
        }
      : undefined,
  };
};
