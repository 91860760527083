import { CardActions, useTheme } from "@mui/material";
import type { SettingsCardActionsProps } from "./types";

export function SettingsCardActions({
  sx,
  ...props
}: SettingsCardActionsProps) {
  const theme = useTheme();

  return (
    <CardActions
      {...props}
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: "16px",
        ...(typeof sx === "function" ? sx(theme) : sx),
      }}
    />
  );
}
