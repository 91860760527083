import { Component } from "react";
import PropTypes from "prop-types";

import ActiveConversation from "features/ActiveConversation";
import PageLoader from "components/PageLoader";
import withRecord from "higherOrderComponents/withRecord";

class StartConversationWrapper extends Component {
  static propTypes = {
    conversationId: PropTypes.string.isRequired,
    conversationUrl: PropTypes.string.isRequired,
    fetchConversationRequest: PropTypes.func.isRequired,
    setContactModal: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      conversationId,
      conversationUrl,
      fetchConversationRequest,
      setContactModal,
    } = this.props;
    if (!conversationId.startsWith("/conversations") && conversationUrl) {
      fetchConversationRequest(conversationUrl, null, {
        successCallback: (response) => {
          setContactModal({
            activeConversation: response.id,
          });
        },
      });
    }
  }

  render() {
    const { conversationId } = this.props;
    if (conversationId.startsWith("/conversations"))
      return <ActiveConversation {...this.props} />;
    return <PageLoader />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "features/ContactModal/containers/StartConversationWrapper",
  noFetch: true,
  shape: {},
  showLoader: () => {
    return false;
  },
  type: "conversation",
})(StartConversationWrapper);
