export const handleSidebarSwipe = (direction, action) => {
  switch (direction) {
    case "left":
      action();
      break;
    default:
      break;
  }
};

export const handleMainSwipe = (direction, action) => {
  switch (direction) {
    case "right":
      action();
      break;
    default:
      break;
  }
};
