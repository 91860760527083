import { useState } from "react";
import PropTypes from "prop-types";
import { Formik, Field, FieldArray, Form } from "formik";
import Button from "@mui/material/Button";

import BranchAccountWarning from "./BranchAccountWarning";
import LockedSettingBanner from "./LockedSettingsBanner";
import LockForm from "./LockForm";
import LockOverlay from "./LockOverlay";
import SettingsFormRow from "./SettingsFormRow";
import SubdomainModal from "./SubdomainModal";
import SubmitRow from "./SubmitRow";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import PageHeader from "components/Page/PageHeader";

function AccountSetting({
  account,
  description,
  handleSubmit: handleSettingsSubmit,
  isDisabled,
  settings,
  title,
  toggleSidebar,
  validationSchema,
}) {
  const [updatedAccountSettings, setUpdatedAccountSettings] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const { value: customUrlLink } =
    updatedAccountSettings?.settings?.registeredLinks ?? "";

  const toggleModal = () => {
    return setOpenModal(!openModal);
  };

  const submitSubdomainModal = (registeredLink) => {
    const { settings: updatedSettings, actions } = updatedAccountSettings ?? {};
    if (registeredLink === customUrlLink) {
      handleSettingsSubmit({ settings: updatedSettings }, actions);
    }
    toggleModal();
  };

  const handleSubmit = (values, actions) => {
    const updatedSettings = settings.reduce((prev, { name }) => {
      const value = values.settings[name]?.value;
      const updatedValue = value === -1 ? null : value;
      return {
        ...prev,
        [name]: {
          value: updatedValue,
        },
      };
    }, {});
    if (values?.settings?.registeredLinks?.value) {
      setUpdatedAccountSettings({ settings: updatedSettings, actions });
      toggleModal();
    } else {
      handleSettingsSubmit({ settings: updatedSettings }, actions);
    }
  };

  const handleSettingsLock = (values, actions) => {
    const updatedSettings = settings.reduce((prev, { name }) => {
      const value = values.settings[name]?.locked;
      return {
        ...prev,
        [name]: {
          locked: value,
        },
      };
    }, {});
    handleSettingsSubmit({ settings: updatedSettings }, actions);
  };

  const initialValues = {
    settings: settings.reduce((prev, setting) => {
      return {
        ...prev,
        [setting.name]: {
          value: account.settings[setting.name]?.value || setting.default,
        },
      };
    }, {}),
  };

  const locked = settings.some(({ name }) => {
    const setting = account.settings[name];
    return setting?.locked && setting?.lockedAtAccount?.name !== account.name;
  });

  const disabled =
    settings.some(({ name }) => {
      return account.settings[name]?.disabled;
    }) ||
    locked ||
    isDisabled;

  const isParent = account.familyCount > 1;

  return (
    <SettingsPageWrapper>
      <PageHeader title={title} toggleSidebar={toggleSidebar}>
        <LockForm
          account={account}
          handleSubmit={handleSettingsLock}
          settings={settings.map((setting) => {
            return setting.name;
          })}
        />
      </PageHeader>
      <SettingsPageContent overflow="auto">
        {isParent && (
          <BranchAccountWarning subAccountCount={account.familyCount - 1} />
        )}
        {!isParent && locked && <LockedSettingBanner />}
        <SettingsDescription>{description}</SettingsDescription>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validateOnMount={validationSchema.isValid(initialValues)}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, isSubmitting, isValid, values }) => {
            return (
              <Form
                style={{
                  position: "relative",
                  padding: disabled ? "30px 20px 20px 20px" : "0",
                }}
              >
                {disabled && <LockOverlay />}
                {settings.map((setting) => {
                  return (
                    <SettingsFormRow key={setting.name}>
                      {Array.isArray(setting.default) ? (
                        <FieldArray name={`settings.${setting.name}.value`}>
                          {setting.render({ errors, touched, values })}
                        </FieldArray>
                      ) : (
                        <Field name={`settings.${setting.name}.value`}>
                          {setting.render({ errors, touched, values })}
                        </Field>
                      )}
                    </SettingsFormRow>
                  );
                })}
                <SubmitRow>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={!isValid || isSubmitting || disabled}
                  >
                    Save
                  </Button>
                </SubmitRow>
              </Form>
            );
          }}
        </Formik>
        <SubdomainModal
          openModal={openModal}
          registeredLink={customUrlLink}
          submitSubdomainModal={submitSubdomainModal}
          toggleModal={toggleModal}
          updatedAccountSettings={updatedAccountSettings}
        />
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

AccountSetting.propTypes = {
  account: PropTypes.object.isRequired,
  description: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      default: PropTypes.any.isRequired,
      name: PropTypes.string.isRequired,
      render: PropTypes.func.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  validationSchema: PropTypes.any.isRequired,
};

export default AccountSetting;
