import { useState, MouseEvent } from "react";
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { Action, ActionMenuProps } from "../types";
import { MoreVertIcon } from "icons";

function ActionMenu({ actions }: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const iconActions = actions.filter((action) => {
    return action.showExpanded;
  });

  const menuActions = actions.filter((action) => {
    return !action.showExpanded;
  });

  const handleMoreOptionsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMoreOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (clickHandler: Action["clickHandler"]) => {
    return () => {
      clickHandler();
      handleMoreOptionsClose();
    };
  };

  return (
    <Box display="flex" flexDirection="row">
      {iconActions.map((action) => {
        if (action.button) {
          return action.button;
        }

        return (
          <Tooltip key={action.title} title={action.title}>
            <IconButton
              onClick={action.clickHandler}
              aria-label={action.title}
              size="large"
            >
              {action.icon}
            </IconButton>
          </Tooltip>
        );
      })}
      {menuActions.length > 0 ? (
        <Tooltip title="Batch actions">
          <IconButton
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            aria-label="Grid action menu"
            data-testid="grid-action-menu"
            onClick={handleMoreOptionsClick}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        disableAutoFocusItem
        id="simple-menu"
        onClose={handleMoreOptionsClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {menuActions.map((action) => {
          return (
            <MenuItem
              key={action.title}
              aria-label={action.title}
              onClick={handleMenuItemClick(action.clickHandler)}
            >
              <ListItemIcon sx={{ marginRight: "0px" }}>
                {action.icon}
              </ListItemIcon>
              <ListItemText sx={{ paddingLeft: "0px" }}>
                {action.title}
              </ListItemText>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}

export default ActionMenu;
