import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Account } from "@tesseract/core";
import type { CSSProperties } from "react";
import styled from "styled-components";
import { displayPhoneNumber } from "./utils";

export const Overflow = styled.div<{
  textAlign?: CSSProperties["textAlign"];
}>`
  width: 100%;
  display: block;
  text-align: ${(props) => {
    return props.textAlign || "right";
  }};

  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export function CurrentAccount({
  currentAccount,
}: {
  currentAccount: Account.Raw | undefined;
}) {
  return (
    <Box maxWidth="200px" width="100%">
      <Overflow>
        <Typography
          variant="subtitle2"
          sx={{
            textTransform: "none",
            fontWeight: 500,
            color: "common.white",
          }}
        >
          {currentAccount?.name}
        </Typography>
        <Typography
          variant="caption"
          sx={{
            textTransform: "none",
            fontWeight: 400,
            color: "common.white",
          }}
        >
          {currentAccount?.phoneNumbers
            ? displayPhoneNumber(currentAccount.phoneNumbers)
            : ""}
        </Typography>
      </Overflow>
    </Box>
  );
}
