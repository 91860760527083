import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { connect } from "react-redux";
import { compose } from "redux";
import withSizes from "react-sizes";
import ContactFilter from "./ContactFilter";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";
import breakpoints from "utils/styles/breakpoints";

function AddContactsModal({
  fullScreen,
  visible,
  currentAccount,
  handleAddContacts,
  closeModal,
}) {
  return (
    <Modal
      fullScreen={fullScreen}
      transitionIn={visible}
      ariaLabel="Add Contacts Modal"
      closeModal={closeModal}
    >
      <Box
        display="flex"
        flexDirection="column"
        height={fullScreen ? "100vh" : "80vh"}
        width={fullScreen ? "100vw" : "80vw"}
      >
        <ModalHeader aria-label="Add Contacts to Group" closeModal={closeModal}>
          Add Contacts to Group
        </ModalHeader>
        <Box
          aria-label="Modal Content"
          display="flex"
          flexDirection="column"
          flex="1 1 auto"
          overflow="scroll"
          position="relative"
        >
          <ContactFilter
            contactFilterId={`/${currentAccount.slug}/contact_filters/all`}
            handleAddContacts={handleAddContacts}
          />
        </Box>
      </Box>
    </Modal>
  );
}

AddContactsModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  visible: PropTypes.bool.isRequired,
  currentAccount: PropTypes.object.isRequired,
  handleAddContacts: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
  withConnect,
)(AddContactsModal);
