import { useEffect, useState } from "react";
import { Account } from "@tesseract/core";
import { FormikHelpers } from "formik";
import { IntroAndAppendedFormValues } from "../types";
import { messageTypes } from "../constants";
import { useCurrentUser } from "hooks";
import { isOrgAdmin } from "utils/isOrgAdmin";
import getUserRole from "utils/getUserRole";

const useMessageSettingsCard = (
  setting: (typeof messageTypes)[keyof typeof messageTypes],
  account?: Account.Raw,
) => {
  const user = useCurrentUser();

  const [lockedAtAccountName, setLockedAtAccountName] = useState<
    string | undefined
  >();
  const [showPreview, setShowPreview] = useState(false);
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: "",
  });

  const orgAdmin = isOrgAdmin(user);
  const lockedAtAccountAdmin =
    getUserRole(
      user,
      account ? account.settings[setting]?.lockedAtAccount : {},
    ) === "admin";
  const currentAccountAdmin = getUserRole(user, account ?? {}) === "admin";
  const admin = currentAccountAdmin || lockedAtAccountAdmin || orgAdmin;

  useEffect(() => {
    setLockedAtAccountName(
      account?.settings[setting]?.lockedAtAccount?.name ?? "",
    );
    setShortenedLink((prevState) => {
      return {
        ...prevState,
        shortLink: account?.shortenedLinkPreviewUrl ?? "",
      };
    });
  }, [account, setting]);

  const handleOpenPreview = () => {
    setShowPreview(true);
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };

  const toggleLock = (
    values: IntroAndAppendedFormValues,
    setFieldValue: FormikHelpers<IntroAndAppendedFormValues>["setFieldValue"],
  ) => {
    if (!account) {
      return undefined;
    }
    return setFieldValue(`${setting}.locked`, !values[setting].locked);
  };

  return {
    admin,
    lockedAtAccountName,
    shortenedLink,
    showPreview,
    handleClosePreview,
    handleOpenPreview,
    setShortenedLink,
    toggleLock,
  };
};

export { useMessageSettingsCard };
