type Params = {
  contactCollection?: string;
  type: string;
  recipients?: [];
  schedule?: string;
};

type FormattedPayloadParams = {
  params: Params;
  slug: string;
  contactImport: { contactFilter: { id: string } };
  scheduledAt?: string;
  campaignType: string;
};

export const getFormattedPayload = ({
  params,
  slug,
  contactImport,
  scheduledAt,
  campaignType,
}: FormattedPayloadParams) => {
  const isSingle = !!params.contactCollection && params.type === "single";

  const isRecurring =
    !!params.contactCollection && params.type === "recurring" && !contactImport;

  const isContactsPage =
    Object.prototype.hasOwnProperty.call(params, "recipients") &&
    !!params.recipients &&
    !contactImport;

  const isCsvSingle = !!contactImport && params.type === "single";

  const isCsvRecurring =
    contactImport !== undefined && params.type === "recurring";

  if (isRecurring)
    return {
      slug,
      schedule: params.schedule,
      type: campaignType,
      contactCollection: params.contactCollection,
    };

  if (isSingle)
    return {
      slug,
      scheduled_At: scheduledAt,
      type: campaignType,
      contactCollection: params.contactCollection,
    };

  if (isContactsPage)
    return {
      slug,
      scheduled_At: scheduledAt,
      type: campaignType,
      recipients: params.recipients,
    };

  if (isCsvSingle)
    return {
      slug,
      scheduled_At: scheduledAt,
      type: campaignType,
      contactFilter: contactImport.contactFilter.id,
    };

  return {
    slug,
    schedule: params.schedule,
    type: campaignType,
    contactFilter: contactImport.contactFilter.id,
  };
};
