import styled, { css } from "styled-components";

export const styles = css`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
  line-height: 22px;
  margin: 0;
`;

const P = styled.p`
  ${styles};
`;

export default P;
