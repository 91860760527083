import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class PulseStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /pulse/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: ['[aria-label="First Name"]', '[aria-label="Last Name"]'],
      namesSelectorType: "value",
      searchTextFunction: () =>
        Array.from(doc.querySelectorAll(`[aria-label$="Phone"]`))
          .map(node => node.value)
          .join(", "),
      canParse: () =>
        Boolean(
          doc.querySelector(
            '[aria-label="Contact Information"][role="tabpanel"]',
          ),
        ),
    });
    const firstNameIndex = Array.from(
      doc.querySelectorAll('[title="First Name"]'),
    ).map(node => node.getAttribute("aria-colindex"))[0];
    const listPageParser = new MultiContactParser({
      doc,
      getNamesFunction: node => [node.innerText, node.nextSibling.innerText],
      namesSelector: `[aria-colindex="${firstNameIndex}"]`,
      searchTextFunction: node => node.parentElement.innerText,
      canParse: () => Boolean(firstNameIndex),
    });
    return [detailPageParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "PulseStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "PulseStrategy");
      return null;
    }
  };
}
