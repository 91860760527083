import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Formik, Field, Form, getIn } from "formik";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonLink from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { formatInTimeZone } from "date-fns-tz";
import { useAccountSettingsSubmit } from "../hooks";
import SubmitRow from "./SubmitRow";
import LockForm from "./LockForm";
import countries from "./constants";
import { BasicSettingsValidationSchema } from "formHelpers/validationSchemas";
import H4 from "components/H4";
import PageHeader from "components/Page/PageHeader";

import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import { supportedTimeZones } from "constants/supportedTimeZones";

const FormRow = styled.div`
  margin-bottom: 10px;

  span[role="img"] {
    margin-left: 0.25em;
    font-size: 20px;
  }

  .MuiPaper span[role="img"] {
    margin-right: 10px;
    position: relative;
    top: 1px;
  }

  &.countryCode,
  &.timeZone {
    padding-bottom: 20px;
  }
`;

const PhoneNumberList = styled.div`
  margin-top: 80px;

  ${H4} {
    margin-bottom: 15px;
  }

  a {
    font-weight: bold;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

const PhoneNumber = styled.p`
  margin: 5px 0 0 0;
`;

const getTimeZoneOptions = () => {
  return supportedTimeZones.map((timeZone) => {
    const offset = formatInTimeZone(new Date(), timeZone, "x");
    const option = {
      label: `${timeZone.replace("_", " ")} (UTC${offset})`,
      value: timeZone,
    };
    return option;
  });
};

function BasicSettings(props) {
  const { account, handleSubmit, toggleSidebar } = props;
  const { phoneNumbers } = account;
  const { handleSubmit: handleSettingsSubmit } = useAccountSettingsSubmit();

  const timeZoneOptions = getTimeZoneOptions();
  const supportLink = get(
    props.account,
    ["settings", "supportLink", "value"],
    "https://help.textus.com/s",
  );

  const mapCountryCodeToOption = (countryCode) => {
    return countries.find(({ code }) => {
      return code === countryCode;
    });
  };

  const mapTimeZoneToOption = (zone) => {
    return timeZoneOptions.find(({ value }) => {
      return value === zone;
    });
  };

  const initialValues = {
    name: get(account, ["name"], ""),
    settings: {
      forwardingNumber: {
        value: get(account, ["settings", "forwardingNumber", "value"], ""),
      },
      countryCode: {
        value: get(account, ["settings", "countryCode", "value"], "us"),
      },
      timeZone: {
        value: get(
          account,
          ["settings", "timeZone", "value"],
          // Other valid codes for UTC:
          // GMT, GMT+0, GMT-0, GMT0
          "UTC",
          // Note: UTC+0 is currently not valid
        ),
      },
    },
  };

  const forwardingNumberDisabled = get(
    account,
    ["settings", "forwardingNumberDisabled", "value"],
    false,
  );

  const handleSettingsLock = (values, actions) => {
    const { locked } = account.settings.accountNameChangeDisabled;
    handleSettingsSubmit(
      {
        settings: {
          accountNameChangeDisabled: {
            locked: !locked,
          },
        },
      },
      actions,
    );
  };

  const isParent = account.familyCount > 1;

  return (
    <SettingsPageWrapper>
      <PageHeader title="Account Details" toggleSidebar={toggleSidebar}>
        <LockForm
          account={account}
          handleSubmit={handleSettingsLock}
          settings={["accountNameChangeDisabled"]}
        />
      </PageHeader>
      <SettingsPageContent overflow="auto">
        <Formik
          enableReinitialize
          initialValues={initialValues}
          // TODO: fix Formik runtime warning: https://app.shortcut.com/textus/story/35625/update-formik-usage-in-tesseract
          //   isInitialValid has been deprecated and will be removed in future versions of Formik.
          //   Please use initialErrors or validateOnMount instead.
          isInitialValid={BasicSettingsValidationSchema.isValidSync(
            initialValues,
          )}
          onSubmit={handleSubmit}
          validationSchema={BasicSettingsValidationSchema}
        >
          {({ errors, touched, isSubmitting, isValid }) => {
            return (
              <Form>
                <FormRow>
                  <Field name="name" type="text">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          error={touched.name && Boolean(errors.name)}
                          fullWidth
                          helperText={touched.name ? errors.name || " " : " "}
                          label="Account name"
                          placeholder="TextUs"
                          type="text"
                          variant="outlined"
                          disabled={
                            !isParent &&
                            account.settings.accountNameChangeDisabled?.locked
                          }
                        />
                      );
                    }}
                  </Field>
                </FormRow>
                {!forwardingNumberDisabled && (
                  <FormRow>
                    <Field name="settings.forwardingNumber.value" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(
                                touched,
                                "settings.forwardingNumber.value",
                              ) &&
                              Boolean(
                                getIn(
                                  errors,
                                  "settings.forwardingNumber.value",
                                ),
                              )
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "settings.forwardingNumber.value")
                                ? getIn(
                                    errors,
                                    "settings.forwardingNumber.value",
                                  ) || " "
                                : " "
                            }
                            label="Call forwarding number"
                            placeholder="303-555-1234"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                )}
                <FormRow className="countryCode">
                  <Field name="settings.countryCode.value" type="select">
                    {({ field, form }) => {
                      return (
                        <Autocomplete
                          autoHighlight
                          autoSelect
                          disableClearable
                          getOptionLabel={(option) => {
                            return option.label;
                          }}
                          onChange={(event, option) => {
                            form.setFieldValue(field.name, option.code);
                          }}
                          options={countries}
                          style={{ marginTop: "1em" }}
                          value={mapCountryCodeToOption(field.value)}
                          renderOption={(optionProps, option) => {
                            return (
                              <li {...optionProps}>
                                <span style={{ paddingRight: "8px" }}>
                                  {option.flag}
                                </span>
                                {option.label}
                              </li>
                            );
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                InputProps={{
                                  ...params.InputProps,
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <span role="img">
                                        {
                                          countries.find((country) => {
                                            return country.code === field.value;
                                          })?.flag
                                        }
                                      </span>
                                    </InputAdornment>
                                  ),
                                  inputProps: {
                                    ...params.inputProps,
                                    autoComplete: "new-password",
                                  },
                                }}
                                label="Country"
                                variant="outlined"
                              />
                            );
                          }}
                        />
                      );
                    }}
                  </Field>
                </FormRow>
                <FormRow className="timeZone">
                  <Field name="settings.timeZone.value" type="select">
                    {({ field, form }) => {
                      return (
                        <Autocomplete
                          autoHighlight
                          autoSelect
                          disableClearable
                          getOptionLabel={(option) => {
                            return option.label;
                          }}
                          onChange={(event, option) => {
                            form.setFieldValue(field.name, option.value);
                          }}
                          options={timeZoneOptions}
                          style={{ marginTop: "1em" }}
                          value={mapTimeZoneToOption(field.value)}
                          renderOption={(optionProps, option) => {
                            return <li {...optionProps}>{option.label}</li>;
                          }}
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label="Time zone"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                              />
                            );
                          }}
                        />
                      );
                    }}
                  </Field>
                </FormRow>
                <SubmitRow>
                  <Button
                    color="primary"
                    disabled={!isValid || isSubmitting}
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
                </SubmitRow>
              </Form>
            );
          }}
        </Formik>
        <PhoneNumberList>
          <H4>Phone Numbers</H4>
          <p>
            {(() => {
              switch (phoneNumbers.length) {
                case 0:
                  return `This account is not configured for messaging. Click on the account name in the upper-right, or go to`;
                case 1:
                  return `Your account is currently configured with the following phone number. `;
                default:
                  return `Your account is currently configured with the following ${phoneNumbers.length} phone numbers. `;
              }
            })()}{" "}
            {phoneNumbers.length > 0 > 0 ? (
              <>
                If you need to make changes, please{" "}
                {supportLink && (
                  <ButtonLink
                    href={supportLink}
                    target="_new"
                    style={{ verticalAlign: "baseline" }}
                    underline="always"
                  >
                    contact support
                  </ButtonLink>
                )}
                .
              </>
            ) : (
              <>
                <Link to="/accounts">accounts</Link> to view your messaging
                accounts!
              </>
            )}
          </p>
          {phoneNumbers.map((phoneNumber) => {
            return <PhoneNumber key={phoneNumber}>{phoneNumber}</PhoneNumber>;
          })}
        </PhoneNumberList>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

BasicSettings.propTypes = {
  account: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default BasicSettings;
