import { Component } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import BlankState from "components/BlankState";
import NewConversationGraphic from "utils/images/new-conversation.svg";
import MultipleConversationsGraphic from "utils/images/multiple-conversations.svg";
import ConversationPreview from "features/Compose/containers/ConversationPreview";

const Root = styled.div`
  flex: 1 1
    ${(props) => {
      return props.isActive ? "auto" : "0px";
    }};
  transition: ${(props) => {
    return props.theme.mixins.transition({
      easing: "ease-in-out",
      timing: "0.5s",
    });
  }};
  position: relative;
  overflow: hidden;

  .preview-content-enter {
    opacity: 0.01;
  }

  .preview-content-enter-active {
    opacity: 1;
    transition: ${(props) => {
      return props.theme.mixins.transition({
        easing: "ease-in-out",
        timing: "0.5s",
      });
    }};
    transition-delay: 500ms;
  }

  .preview-content-exit {
    opacity: 1;
  }

  .preview-content-exit-active {
    opacity: 0.01;
    transition: ${(props) => {
      return props.theme.mixins.transition({
        easing: "ease-in-out",
        timing: "0.5s",
      });
    }};
  }
`;

const Content = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter();
  }};
  ${(props) => {
    return props.isActive
      ? props.theme.mixins.fadeShow
      : props.theme.mixins.fadeHide;
  }};
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  transition-delay: ${(props) => {
    return !props.isActive ? 1 : 0;
  }}s;
  transition: ${(props) => {
    return props.theme.mixins.transition({
      easing: "ease-in-out",
    });
  }};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const MultipleRecipients = styled(BlankState)``;

const MultipleRecipientsImage = styled.img`
  height: 140px;
`;

const SingleRecipient = styled(BlankState)``;

class Preview extends Component {
  static propTypes = {
    conversationSearchUrl: PropTypes.string,
    hasMultipleRecipients: PropTypes.bool.isRequired,
    isActive: PropTypes.bool.isRequired,
    hideImage: PropTypes.bool,
    setConversationId: PropTypes.func.isRequired,
    theme: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { conversationNotFound: false, returnedConversationId: null };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.conversationSearchUrl !== nextProps.conversationSearchUrl) {
      this.setState({ returnedConversationId: null });
    }
  }

  handleConversationNotFound = () => {
    this.setState({ conversationNotFound: true });
  };

  handleReturnedConversationId = (returnedConversationId) => {
    this.setState({ returnedConversationId }, () => {
      this.props.setConversationId(returnedConversationId);
    });
  };

  renderContent() {
    const { hasMultipleRecipients, conversationSearchUrl, hideImage, theme } =
      this.props;
    const { conversationNotFound, returnedConversationId } = this.state;
    if (hasMultipleRecipients) {
      return (
        <CSSTransition
          key="multiple-recipients"
          timeout={500}
          classNames="preview-content"
        >
          <MultipleRecipients
            key="multiple"
            background={theme.colors.background.default}
            image={
              !hideImage && (
                <MultipleRecipientsImage
                  alt="Multiple Recipients"
                  src={MultipleConversationsGraphic}
                />
              )
            }
            title="This message will be sent to each recipient individually."
            subTitle="TextUs does not support group conversations."
          />
        </CSSTransition>
      );
    }
    if (conversationSearchUrl && !conversationNotFound) {
      return (
        <CSSTransition
          key="conversation-preview"
          timeout={500}
          classNames="preview-content"
        >
          <ConversationPreview
            conversationId={returnedConversationId || conversationSearchUrl}
            handleConversationNotFound={this.handleConversationNotFound}
            handleReturnedConversationId={this.handleReturnedConversationId}
          />
        </CSSTransition>
      );
    }

    return (
      <CSSTransition
        key="single-recipient"
        timeout={500}
        classNames="preview-content"
      >
        <SingleRecipient
          key="single"
          background={theme.colors.background.default}
          image={
            !hideImage && (
              <img alt="Single Recipient" src={NewConversationGraphic} />
            )
          }
          title="You are about to start a new conversation"
          subTitle="Texting people is a personal, effective way to get in touch"
        />
      </CSSTransition>
    );
  }

  render() {
    const { isActive } = this.props;
    return (
      <Root isActive={isActive}>
        <Content isActive={isActive}>
          <TransitionGroup component={null}>
            {this.renderContent()}
          </TransitionGroup>
        </Content>
      </Root>
    );
  }
}

export default withTheme(Preview);
