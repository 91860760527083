import { all, call, put, select, takeLatest } from "redux-saga/effects";

import { baseUrl, getOptions } from "utils/apiConfig";
import { selectUser } from "features/EntryPoint/containers/App/selectors";
import * as schema from "schema";
import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";
import generateSagas from "utils/generateContainerState/generateSagas";
import request from "utils/request";

// ACTION TYPES
const actionTypes = generateActionTypes({
  container: "NewContactImport",
  actions: ["import", "upload"],
  recordType: "contactImport",
});

// ACTION GENERATORS
const actionGenerators = generateActionGenerators(actionTypes);

// SAGA
const getRequestMethod = (requestType) => {
  switch (requestType) {
    case "upload":
      return "PUT";
    default:
      return "POST";
  }
};

const initialSagas = generateSagas({
  actionGenerators,
  getRequestMethod,
  schema: schema.contactImport,
  shouldOptimisticallyUpdate: () => {
    return false;
  },
});

const sagas = {
  ...initialSagas,
  *uploadContactImportRequest({ url, params, options = {} }) {
    try {
      const user = yield select(selectUser);
      const requestUrl = `${baseUrl}${url}`;
      const requestOptions = yield getOptions({
        method: "POST",
        params: { filename: params.file.name },
        user,
      });
      const { signedUrl } = yield call(request, requestUrl, requestOptions);
      yield call(fetch, signedUrl, {
        method: "PUT",
        body: params.file,
      });
      yield put(actionGenerators.uploadContactImportSuccess(signedUrl));
      if (options.successCallback) options.successCallback(signedUrl);
    } catch (error) {
      yield put(actionGenerators.uploadContactImportFailure(error));
      if (options.errorCallback) options.errorCallback(error);
    }
    if (options.cleanup) options.cleanup();
  },
};

function* saga() {
  yield all([
    takeLatest(
      actionTypes.IMPORT_CONTACT_IMPORT_REQUEST,
      sagas.importContactImportRequest,
    ),
    takeLatest(
      actionTypes.UPLOAD_CONTACT_IMPORT_REQUEST,
      sagas.uploadContactImportRequest,
    ),
  ]);
}

export { actionTypes, actionGenerators, saga };
