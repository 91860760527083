import {
  UPDATE_APP_COLORS,
  UPDATE_CURRENT_ACCOUNT,
  UPDATE_EXTRACTED_PHONES,
  UPDATE_RECORDS,
  UPDATE_USER_CAN_HOVER,
} from "./constants";

export const updateAppColors = (appColors) => {
  return {
    type: UPDATE_APP_COLORS,
    appColors,
  };
};

export const updateCurrentAccount = (currentAccountId) => {
  return {
    type: UPDATE_CURRENT_ACCOUNT,
    currentAccountId,
  };
};

export const updateRecords = (records, options) => {
  return {
    type: UPDATE_RECORDS,
    records,
    options,
  };
};

export const updateExtractedPhones = (phones) => {
  return {
    type: UPDATE_EXTRACTED_PHONES,
    payload: phones,
  };
};

export const updateUserCanHover = (payload) => {
  return {
    type: UPDATE_USER_CAN_HOVER,
    payload,
  };
};
