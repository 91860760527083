function LogoGradient() {
  return (
    <svg style={{ position: "absolute" }}>
      <defs>
        <linearGradient
          x1="13.372%"
          x2="78.952%"
          y1="24.062%"
          y2="80.954%"
          id="blue-green-gradient"
        >
          <stop stopColor="#89C642" offset="0%" />
          <stop stopColor="#2DACDD" offset="100%" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LogoGradient;
