const getItem = (item, { fallback } = {}) => {
  if (!global.localStorage) return fallback;
  return JSON.parse(global.localStorage.getItem(item)) || fallback;
};

const setItem = ({ item, value }) => {
  if (global.localStorage && value !== undefined) {
    global.localStorage.setItem(item, JSON.stringify(value));
  }
};

export default { getItem, setItem };
