import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

interface BlankStateV2Props {
  background?: string;
  className?: string;
  image: React.ReactNode;
  subTitle?: string;
  title?: string;
  styles?: {
    container?: Record<string, any>;
    title?: Record<string, any>;
    subtitle?: Record<string, any>;
    image?: Record<string, any>;
  };
}

export const BlankStateV2: FC<BlankStateV2Props> = ({
  background = "transparent",
  children,
  image,
  styles,
  subTitle,
  title,
}) => {
  const { palette } = useTheme();

  return (
    <Box
      bgcolor={background}
      height="100%"
      p={10}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      textAlign="center"
      sx={styles?.container}
    >
      {image && <Box sx={styles?.image}>{image}</Box>}
      {title && (
        <Typography
          fontSize={20}
          fontWeight={700}
          color={palette.primary.main ?? "#283C59"}
          my={1}
          sx={styles?.title}
        >
          {title}
        </Typography>
      )}
      {subTitle && (
        <Typography
          fontSize={15}
          fontWeight={400}
          color={palette.primary.main ?? "#283C59"}
          sx={styles?.subtitle}
        >
          {subTitle}
        </Typography>
      )}
      {children}
    </Box>
  );
};
