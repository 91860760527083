import styled from "styled-components";

const BasicButton = styled.button`
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

export default BasicButton;
