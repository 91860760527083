import { useState } from "react";
import { Collapse, List, ListItemButton, styled } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { SequencesContactsSideNavProps } from "./types";
import { contactGridSidebarWidth } from "features/Sequences/components/ContactsDataGrid/constants";

const StyledListItemButton = styled(ListItemButton)({
  fontSize: "14px",
  padding: "0.75rem 1rem 0.75rem 1rem",
});

const StyledNestedListItemButton = styled(ListItemButton)({
  fontSize: "14px",
  padding: "0.75rem 1rem 0.75rem 2.5rem",
});

function SequencesContactsSideNav({
  sequence,
  handleFilterClick,
  handleResponseClick,
  handleStoppedClick,
}: SequencesContactsSideNavProps) {
  const [openCollapse, setOpenCollapse] = useState(false);

  const {
    contactCount,
    failedCount,
    finishedCount,
    noResponseCount,
    omittedCount,
    pendingCount,
    respondedCount,
    stoppedCount,
  } = sequence;

  const handleFinishedClick = () => {
    setOpenCollapse(!openCollapse);
  };

  return (
    <List
      sx={(theme) => {
        return {
          backgroundColor: theme.palette.background.default,
          borderRight: "1px solid",
          borderColor: theme.palette.divider,
          width: contactGridSidebarWidth,
        };
      }}
    >
      <StyledListItemButton
        onClick={handleFilterClick}
        data-testid="filter-all-sequence-contacts"
      >{`All Contacts (${contactCount})`}</StyledListItemButton>
      <StyledListItemButton
        onClick={handleFilterClick}
        data-testid="filter-pending-sequence-contacts"
      >{`Pending (${pendingCount})`}</StyledListItemButton>
      <ListItemButton
        data-testid="toggle-finished-stats"
        onClick={handleFinishedClick}
        sx={{ fontSize: "14px", justifyContent: "space-between" }}
      >
        {`Finished (${finishedCount})`}
        {openCollapse ? (
          <ExpandLess fontSize="medium" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
        ) : (
          <ExpandMore fontSize="medium" sx={{ color: "rgba(0, 0, 0, 0.54)" }} />
        )}
      </ListItemButton>
      <Collapse in={openCollapse} timeout="auto" unmountOnExit>
        <List>
          <StyledNestedListItemButton
            onClick={handleResponseClick}
            data-testid="filter-responded-sequence-contacts"
          >{`Responded (${respondedCount})`}</StyledNestedListItemButton>
          <StyledNestedListItemButton
            onClick={handleResponseClick}
            data-testid="filter-no-response-sequence-contacts"
          >{`No Response (${noResponseCount})`}</StyledNestedListItemButton>
          <StyledNestedListItemButton
            onClick={handleStoppedClick}
            data-testid="filter-stopped-sequence-contacts"
          >{`Stopped (${stoppedCount})`}</StyledNestedListItemButton>
        </List>
      </Collapse>
      <StyledListItemButton
        onClick={handleFilterClick}
        data-testid="filter-failed-sequence-contacts"
      >{`Failed (${failedCount})`}</StyledListItemButton>
      <StyledListItemButton
        onClick={handleFilterClick}
        data-testid="filter-omitted-sequence-contacts"
      >{`Omitted (${omittedCount})`}</StyledListItemButton>
    </List>
  );
}
export { SequencesContactsSideNav };
