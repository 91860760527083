import { Box } from "@mui/material";
import { Formik } from "formik";
import { NewSequenceForm } from "../NewSequenceForm";
import { MODAL_SELECTIONS } from "../../constants";
import { Blueprints } from "../Blueprints";
import { BlueprintsProvider } from "../Blueprints/context";
import { FormsScreenProps } from "./types";
import { NewSequenceValidationSchema } from "formHelpers/validationSchemas";
import { useBlueprints } from "features/Sequences/hooks/useBlueprints";

function FormsScreen({
  close,
  handleBack,
  handleBlueprintsBack,
  handleChooseBlueprint,
  selection,
  sequenceValues,
}: FormsScreenProps) {
  const {
    areBlueprintsLoading,
    blueprints,
    selectedCard,
    getBlueprints,
    setSelectedCard,
  } = useBlueprints();

  return selection === MODAL_SELECTIONS.blueprints ? (
    <BlueprintsProvider
      areBlueprintsLoading={areBlueprintsLoading}
      blueprints={blueprints}
      selectedCard={selectedCard}
      close={close}
      getBlueprints={getBlueprints}
      handleBlueprintsBack={handleBlueprintsBack}
      handleChooseBlueprint={handleChooseBlueprint}
      setSelectedCard={setSelectedCard}
    >
      <Blueprints />
    </BlueprintsProvider>
  ) : (
    <Box padding="1rem">
      <Formik
        initialValues={sequenceValues}
        onSubmit={(values) => {
          close(values)();
        }}
        validationSchema={NewSequenceValidationSchema}
        validateOnMount={NewSequenceValidationSchema}
      >
        <NewSequenceForm
          close={close}
          handleBack={handleBack}
          selection={selection}
        />
      </Formik>
    </Box>
  );
}

export { FormsScreen };
