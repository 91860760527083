import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

import PageContent from "components/Page/PageContent";
import PageWrapper from "components/Page/PageWrapper";
import LegalSidebar from "features/Legal/LegalSidebar";
import PrivacyPolicy from "features/Legal/components/PrivacyPolicy";
import TermsOfUse from "features/Legal/components/TermsOfUse";
import withSidebar from "higherOrderComponents/withSidebar";

function Legal({ showSidebar, toggleSidebar, isSinglePanel }) {
  return (
    <PageWrapper>
      <LegalSidebar toggleSidebar={toggleSidebar} showSidebar={showSidebar} />
      <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
        <Switch>
          <Route
            exact
            path="/legal"
            render={() => {
              return <Redirect to="/legal/privacy-policy" />;
            }}
          />
          <Route
            exact
            path="/legal/privacy-policy"
            render={() => {
              return (
                <PrivacyPolicy
                  toggleSidebar={toggleSidebar}
                  showSidebar={showSidebar}
                />
              );
            }}
          />
          <Route
            exact
            path="/legal/terms-of-use"
            render={() => {
              return (
                <TermsOfUse
                  toggleSidebar={toggleSidebar}
                  showSidebar={showSidebar}
                />
              );
            }}
          />
        </Switch>
      </PageContent>
    </PageWrapper>
  );
}

Legal.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  isSinglePanel: PropTypes.bool.isRequired,
};

export default withSidebar(Legal);
