import { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { matchPath } from "react-router-dom";

import { Box, Fab, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ForumIcon from "@mui/icons-material/Forum";
import ReactTouchEvents from "react-touch-events";

import Typography from "@mui/material/Typography";
import ActiveConversation from "features/ActiveConversation";
import ActiveConversationFilter from "features/Inbox/containers/ActiveConversationFilter";
import BlankState from "components/BlankState";
import ConversationFilterCollection from "features/Inbox/containers/ConversationFilterCollection";
import getNestedId from "utils/getNestedId";
import PageSearchHeader from "components/Page/PageSearchHeader";
import PageSidebar from "components/Page/PageSidebar";
import PageSidebarHeader from "components/Page/PageSidebarHeader";
import PageSidebarHeaderButton from "components/Page/PageSidebarHeaderButton";
import PageWrapper from "components/Page/PageWrapper";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import { handleMainSwipe, handleSidebarSwipe } from "utils/handleSwiping";
import { MobileNavDrawer } from "components/MobileNavDrawer";
import { SearchIcon } from "icons";
import ConversationIcon from "icons/ConversationIcon";
import { NoResultsScreen } from "components/Search/NoResultsScreen";
import getMessagingRestrictedCopy from "utils/getMessagingRestrictedCopy";

export const ResponsiveRootContext = createContext();

function ResponsiveRoot({
  activeConversations,
  currentAccount,
  currentUser,
  inbox,
  history,
  location,
  match,
  setCompose,
}) {
  const screens = {
    mobile: useMediaQuery((theme) => {
      return theme.breakpoints.down("sm");
    }),
    mdAndDown: useMediaQuery((theme) => {
      return theme.breakpoints.down("md");
    }),
    lgAndDown: useMediaQuery((theme) => {
      return theme.breakpoints.down("lg");
    }),
    smAndUp: useMediaQuery((theme) => {
      return theme.breakpoints.up("sm");
    }),
    mdAndUp: useMediaQuery((theme) => {
      return theme.breakpoints.up("md");
    }),
    xl: useMediaQuery((theme) => {
      return theme.breakpoints.up("xl");
    }),
  };

  const [showContactDetailsSidebar, setShowContactDetailsSidebar] =
    useState(false);
  const [showSecondaryNavbar, setShowSecondaryNavbar] = useState(
    !screens.mobile,
  );

  const {
    activeConversationFilter,
    activeConversation,
    conversationFilters,
    modifiedInbox,
    campaignFilters,
    otherFilters,
  } = inbox;

  const theme = useTheme();

  const showActiveConversationSection = screens.mdAndDown
    ? matchPath(window.location.pathname, {
        path: "/:accountSlug/inbox/:activeConversationFilterSlug/:activeConversationSlug",
        exact: true,
        strict: false,
      })
    : true;

  useEffect(() => {
    if (showContactDetailsSidebar) {
      setShowSecondaryNavbar(false);
    }

    if (showSecondaryNavbar) {
      setShowContactDetailsSidebar(false);
    }
  }, [showContactDetailsSidebar, showSecondaryNavbar]);

  useEffect(() => {
    if (screens.mobile) {
      setShowSecondaryNavbar(false);
    }
  }, [screens.mobile]);

  const handleBackClick = () => {
    history.push({
      pathname: `/${currentAccount.slug}/inbox/open`,
    });
  };

  const handleClear = () => {
    const { search } = document.location;
    if (search) {
      history.push({
        pathname: `/${currentAccount.slug}/inbox/search`,
      });
    }
  };

  const handleSearch = (q) => {
    history.push({
      pathname: `/${currentAccount.slug}/inbox/search`,
      search: `?q=${fixedEncodeURIComponent(q)}`,
    });
  };

  const toggleConversationSidebar = () => {
    if (showSecondaryNavbar && !showContactDetailsSidebar) {
      setShowSecondaryNavbar(false);
    }
    setShowContactDetailsSidebar(!showContactDetailsSidebar);
  };

  const toggleSidebar = () => {
    if (showContactDetailsSidebar && !showSecondaryNavbar) {
      setShowContactDetailsSidebar(false);
    }
    setShowSecondaryNavbar(!showSecondaryNavbar);
    return !showSecondaryNavbar;
  };

  const toggleSearch = () => {
    const { pathname } = document.location;
    const nextPathname = pathname.includes("search")
      ? `/${currentAccount.slug}/inbox`
      : `/${currentAccount.slug}/inbox/search`;
    if (screens.mdAndDown) {
      setShowSecondaryNavbar(false);
    }
    history.push({ pathname: nextPathname });
  };

  const searchMode = document.location.pathname.includes("search");
  const conversationCount = activeConversations?.totalItems;
  const noConvoSelected = Boolean(!match.params.activeConversationSlug);

  const { messagingRestricted } = currentAccount;
  const { adminUserSchema } = currentUser ?? {};
  const targeted10dlcNotice = getMessagingRestrictedCopy(
    currentAccount,
    currentUser,
  );

  return (
    <ResponsiveRootContext.Provider value={setShowContactDetailsSidebar}>
      <PageWrapper>
        {screens.mobile ? (
          <MobileNavDrawer
            actionButton={
              <PageSidebarHeaderButton
                clickHandler={() => {
                  return setCompose({ active: true, recipientIds: [] });
                }}
                dataTag="ComposeButton"
                ariaLabel="compose-button"
                dataTestId="compose-button"
                disabled={messagingRestricted}
                icon={<EditIcon sx={{ height: "1.25rem", width: "1.25rem" }} />}
                primary
                text="Compose"
                tooltipText={targeted10dlcNotice}
              />
            }
            open={showSecondaryNavbar}
            toggleDrawer={toggleSidebar}
          >
            <ConversationFilterCollection
              conversationFilterCollectionId={
                typeof conversationFilters === "string"
                  ? conversationFilters
                  : conversationFilters.id
              }
              isActive={showSecondaryNavbar}
              toggleSidebar={toggleSidebar}
              otherFiltersId={
                !otherFilters || typeof otherFilters === "string"
                  ? otherFilters
                  : otherFilters.id
              }
              campaignFiltersId={
                !campaignFilters || typeof campaignFilters === "string"
                  ? campaignFilters
                  : campaignFilters.id
              }
            />
          </MobileNavDrawer>
        ) : (
          <ReactTouchEvents
            style={{ zIndex: 101 }}
            onSwipe={(direction) => {
              return handleSidebarSwipe(direction, toggleSidebar);
            }}
          >
            <PageSidebar>
              <PageSidebarHeader>
                <PageSidebarHeaderButton
                  clickHandler={() => {
                    return setCompose({ active: true, recipientIds: [] });
                  }}
                  dataTag="ComposeButton"
                  ariaLabel="compose-button"
                  dataTestId="compose-button"
                  disabled={messagingRestricted}
                  icon={
                    <EditIcon sx={{ height: "1.25rem", width: "1.25rem" }} />
                  }
                  primary
                  text="Compose"
                  tooltipText={targeted10dlcNotice}
                />
              </PageSidebarHeader>
              <ConversationFilterCollection
                conversationFilterCollectionId={
                  typeof conversationFilters === "string"
                    ? conversationFilters
                    : conversationFilters.id
                }
                isActive={showSecondaryNavbar}
                toggleSidebar={toggleSidebar}
                otherFiltersId={
                  !otherFilters || typeof otherFilters === "string"
                    ? otherFilters
                    : otherFilters.id
                }
                campaignFiltersId={
                  !campaignFilters || typeof campaignFilters === "string"
                    ? campaignFilters
                    : campaignFilters.id
                }
              />
            </PageSidebar>
          </ReactTouchEvents>
        )}
        <ReactTouchEvents
          style={{ zIndex: 101 }}
          onSwipe={(direction) => {
            return handleMainSwipe(direction, toggleSidebar);
          }}
        >
          <Box
            height="100%"
            width={showSecondaryNavbar ? "calc(100% - 15rem)" : "100%"}
            sx={{
              zIndex: 3,
              marginLeft: showSecondaryNavbar ? "15rem" : 0,
              transition: "all ease 0.3s",
              [theme.breakpoints.down("md")]: {
                left: showSecondaryNavbar ? "15rem" : 0,
                position: "absolute",
                marginLeft: 0,
                top: 0,
              },
              [theme.breakpoints.down("sm")]: {
                left: 0,
                marginLeft: 0,
                width: "100%",
              },
            }}
          >
            {searchMode && (
              <Box
                position="relative"
                width="100%"
                className="page-content-search-box-wrapper"
              >
                <Box
                  alignItems="center"
                  color="text.secondary"
                  display="flex"
                  height="100%"
                  left="3rem"
                  position="absolute"
                  px={1}
                  style={{ zIndex: 1 }}
                >
                  <SearchIcon
                    style={{
                      color: "text.secondary",
                    }}
                  />
                </Box>
                <PageSearchHeader
                  autoFocus={screens.mdAndDown}
                  backClickHandler={handleBackClick}
                  clearHandler={handleClear}
                  localStorageKey="recentConversationQueries"
                  placeholder="Search conversations"
                  searchHandler={handleSearch}
                  styles={{
                    input: (provided) => {
                      return {
                        ...provided,
                        paddingLeft: "2rem",
                      };
                    },
                    placeholder: (provided) => {
                      return {
                        ...provided,
                        paddingLeft: "2rem",
                      };
                    },
                  }}
                  type="conversations"
                  value=""
                />
              </Box>
            )}
            <Box
              display="flex"
              flexDirection="row"
              flexWrap="nowrap"
              height={searchMode ? "calc(100% - 55px)" : "100%"}
              sx={{ background: theme.palette.background.paper }}
            >
              <Box
                height="100%"
                width="auto"
                flex="0 0 100%"
                position="relative"
                display={
                  screens.mdAndDown && showActiveConversationSection
                    ? "none"
                    : "block"
                }
                sx={{
                  borderRight: `1px solid ${theme.palette.divider}`,
                  minWidth: "20rem",
                  flex: "0 0 100%",
                  [theme.breakpoints.up("md")]: {
                    minWidth: "20rem",
                    maxWidth: "22.5rem",
                  },
                }}
              >
                {activeConversationFilter && (
                  <ActiveConversationFilter
                    conversationFilterId={
                      typeof activeConversationFilter === "string"
                        ? activeConversationFilter
                        : activeConversationFilter.id
                    }
                    initialInboxLoad={!modifiedInbox}
                    history={history}
                    location={location}
                    match={match}
                    showSecondaryNavbar={showSecondaryNavbar}
                    toggleSidebar={toggleSidebar}
                    toggleSearch={toggleSearch}
                  />
                )}
                {!showSecondaryNavbar && !showContactDetailsSidebar ? (
                  <Tooltip title="Compose message">
                    <Fab
                      aria-label="Compose Message"
                      data-testid="compose-message"
                      color="primary"
                      onClick={() => {
                        return setCompose({ active: true, recipientIds: [] });
                      }}
                      size="medium"
                      sx={{
                        position: "absolute",
                        bottom: "1rem",
                        right: "2rem",
                      }}
                    >
                      <EditIcon />
                    </Fab>
                  </Tooltip>
                ) : null}
              </Box>
              {showActiveConversationSection ? (
                <Box
                  right={
                    screens.lgAndDown && showContactDetailsSidebar ? "20vw" : 0
                  }
                  transition={screens.mdAndDown ? "all ease 0.3s" : "none"}
                  flex="1 0 0%"
                  minWidth={screens.mobile ? 0 : "22.5rem"}
                  maxWidth="100%"
                  zIndex={screens.mdAndDown ? 4 : 0}
                  height="100%"
                  sx={theme.palette.background.paper}
                >
                  {activeConversation &&
                    match.params.activeConversationSlug && (
                      <ActiveConversation // CONVERSATION VIEW
                        conversationId={getNestedId(
                          inbox,
                          "activeConversation",
                        )}
                        initialInboxLoad={!modifiedInbox}
                        isConversationSidebarActive={showContactDetailsSidebar}
                        toggleConversationSidebar={toggleConversationSidebar}
                        showContactDetailsSidebar={showContactDetailsSidebar}
                      />
                    )}
                  {noConvoSelected && conversationCount > 0 && (
                    <BlankState
                      image={<ConversationIcon mode={theme.palette.mode} />}
                      subComponent={
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          align="center"
                          pt="0rem"
                        >
                          {" "}
                          Select a conversation to view.{" "}
                        </Typography>
                      }
                    />
                  )}
                  {conversationCount === 0 && (
                    <NoResultsScreen source="conversations" active={false} />
                  )}
                </Box>
              ) : null}
              {showActiveConversationSection &&
              activeConversation &&
              showContactDetailsSidebar ? (
                <Box
                  id="textus-ConversationSidebar"
                  flex="1 1 100%"
                  height="100%"
                  minWidth="15rem"
                  maxWidth={screens.mobile ? "100%" : "20rem"}
                  zIndex={screens.mdAndDown ? 3 : 0}
                  sx={(theme) => {
                    return {
                      background: theme.palette.background.paper,
                      borderLeft: `1px solid ${theme.palette.divider}`,
                    };
                  }}
                />
              ) : null}
            </Box>
          </Box>
        </ReactTouchEvents>
      </PageWrapper>
    </ResponsiveRootContext.Provider>
  );
}

export default ResponsiveRoot;

ResponsiveRoot.propTypes = {
  activeConversations: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired, // withConnect
  currentUser: PropTypes.object.isRequired, // withConnect
  history: PropTypes.object.isRequired, // parent
  inbox: PropTypes.object.isRequired, // withRecord
  location: PropTypes.object.isRequired, // passed
  match: PropTypes.object.isRequired, // passed
  setCompose: PropTypes.func.isRequired, // withConnect
};
