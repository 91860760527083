import { MenuItem, TextField } from "@mui/material";
import { formatInTimeZone } from "date-fns-tz";
import { camelCase } from "lodash";
import { TimeInMinutesPickerProps } from "./types";
import { useTimeZones } from "hooks";

function TimeInMinutesPicker({
  label,
  value,
  handleChange,
}: TimeInMinutesPickerProps) {
  const { accountTimeZone } = useTimeZones();

  const abbreviatedTimeZone = formatInTimeZone(
    new Date(),
    accountTimeZone,
    "zzz",
  );

  /**
   * Generates an array of time strings in half-hour increments starting from 12:00.
   * For example, ["12:00", "12:30", "1:00", ..., "11:30"].
   */
  const times = Array.from({ length: 24 }, (_, i) => {
    const hour = Math.floor(i / 2) % 12 || 12;
    const minutes = i % 2 === 0 ? "00" : "30";
    return `${hour}:${minutes}`;
  });

  const options = times
    .flatMap((time, index) => {
      return [
        {
          label: `${time} AM ${abbreviatedTimeZone}`,
          value: index * 30, // time in minutes for 12:00 AM to 11:30 AM
        },
        {
          label: `${time} PM ${abbreviatedTimeZone}`,
          value: (index + 24) * 30, // time in minutes for 12:00 PM to 11:30 PM
        },
      ];
    })
    .sort((a, b) => {
      return a.value - b.value;
    });

  return (
    <TextField
      fullWidth
      label={label}
      name={camelCase(label)}
      data-testid={`${camelCase(label)}-time-in-minutes-picker`}
      select
      onChange={handleChange}
      value={value}
      SelectProps={{
        MenuProps: { PaperProps: { sx: { maxHeight: "14rem" } } },
      }}
    >
      {options.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        );
      })}
    </TextField>
  );
}

export { TimeInMinutesPicker };
