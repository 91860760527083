import { Box, Skeleton } from "@mui/material";

const numberConversations = (length: number): number[] => {
  return Array(length)
    .fill(null)
    .map(() => {
      return Math.random() * (6 - 2) + 2;
    });
};

function ConversationListSkeleton() {
  return (
    <Box
      sx={{
        border: "1px #e0e0e0 solid",
        width: "640px",
        display: { xs: "none", sm: "flex" },
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          height: "3rem",
          alignItems: "center",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: 1,
          }}
        >
          <Skeleton animation="wave" variant="rounded" width={13} height={13} />
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1rem", width: "3rem", ml: "13px" }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={13}
            height={13}
            sx={{ m: "4px" }}
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            width={13}
            height={13}
            sx={{ m: "4px" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          border: "1px solid #e0e0e0",
          display: "flex",
          justifyContent: "space-between",
          height: "66px",
          alignItems: "center",
          p: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            p: 1,
          }}
        >
          <Skeleton animation="wave" variant="rounded" width={13} height={13} />
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1rem", width: "5rem", ml: "13px" }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Skeleton
            animation="wave"
            variant="rounded"
            width={13}
            height={13}
            sx={{ m: "4px" }}
          />
        </Box>
      </Box>
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {numberConversations(6).map((nameLength, key) => {
          return (
            <Box
              key={`${key + nameLength}`}
              sx={{
                border: "1px #e0e0e0 solid",
                p: "16px 21px",
                height: "109px",
                width: "100%",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  width={18}
                  height={18}
                  sx={{ marginTop: "10px" }}
                />
                <Box
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                  }}
                >
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{
                      fontSize: "1rem",
                      width: `${nameLength}rem`,
                      ml: "13px",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{
                      fontSize: "0.8rem",
                      width: "3rem",
                      ml: "13px",
                      mt: "-5px",
                    }}
                  />
                  <Skeleton
                    animation="wave"
                    variant="text"
                    sx={{
                      fontSize: "0.7rem",
                      width: "3rem",
                      ml: "13px",
                      mt: "12px",
                    }}
                  />
                </Box>
              </Box>
              <Skeleton
                animation="wave"
                variant="text"
                sx={{ fontSize: "1rem", width: "3rem", ml: "13px" }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default ConversationListSkeleton;
