import Pusher from "pusher-js";
import type { Store } from "redux";
import { channelSubscription } from "./channels/channelSubscription";
import { PusherOption, PusherStatesEnum } from "./PusherInterfaces";

let pusher: Pusher;

export function getPusher() {
  return pusher;
}

export function createPusherInstance({
  pusherKey,
  pusherOptions,
  delay,
  store,
}: {
  pusherKey: string;
  pusherOptions: PusherOption;
  delay: number;
  store: Store
}): Pusher {
  pusher = new Pusher(pusherKey, pusherOptions);
  pusher.connection.bind(
    "state_change",
    (states: { previous: PusherStatesEnum; current: PusherStatesEnum }) => {
      // This callback will run every time is a change of state of Pusher
      if (states.current === "connected") {
        // It will dispatch the Connected event and subscribe to the channels withing a timeout
        store.dispatch({
          type: "app/PusherComponent/PUSHER_CONNECTED",
          data: pusher,
          payload: true,
        });
        setTimeout(() => {
          channelSubscription({ pusher , store});
        }, delay);
      }
      if (
        states.current === "unavailable" ||
        states.current === "failed" ||
        states.current === "initialized" ||
        states.current === "connecting" ||
        states.current === "disconnected"
      ) {
        // Dispatch an action to show the state of Pusher
        store.dispatch({
          type: `app/PusherComponent/PUSHER_${states.current.toUpperCase()}`,
          payload: false,
        });
      }
      if (states.current === "disconnected")
        store.dispatch({
          type: "app/PusherComponent/PUSHER_CHANNEL_SUBSCRIBED",
          data: { socket_id: pusher.connection.socket_id },
          payload: false,
        });
    },
  );
  return pusher;
}

export function disconnectPusher() {
  pusher.disconnect();
}
