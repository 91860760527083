import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import { styled } from "@mui/system";

import H4 from "components/H4";

const StyledLink = styled(Link)({
  fontWeight: "bold",
  textDecoration: "none",

  ":hover": {
    textDecoration: "underline",
  },
});

function ConversationUploadDescription() {
  return (
    <Box marginBottom="40px">
      <Box marginBottom="28px">
        <p>
          Use this tab to import your existing contacts and conversation history
          from your previous texting platform into TextUs using CSV files. For
          more detailed information on requirements and how to format your CSV
          files, see our{" "}
          <StyledLink
            href="https://help.textus.com/s/detail/How-to-Migrate-Your-Business-Texting-Data-into-TextUs"
            target="_blank"
          >
            Help Center
          </StyledLink>{" "}
          article.
        </p>
      </Box>
      <H4 style={{ marginBottom: "-10px" }}>Step 1</H4>
      <p>
        Upload a CSV file of contacts that includes information such as the
        contact name and phone number. You will need to complete this step
        before uploading your conversation history. If you have already uploaded
        your contacts previously, select <strong>Skip Step 1</strong>.
      </p>
      <H4 style={{ marginBottom: "-10px" }}>Step 2</H4>
      <p>
        Once the CSV file of contacts has been uploaded, you will receive a
        notification that you are ready to upload your conversation history.
        Select Skip Step 1, then upload your conversation history using a
        properly formatted CSV file.{" "}
        <StyledLink
          href="https://help.textus.com/s/detail/How-to-Migrate-Your-Business-Texting-Data-into-TextUs"
          target="_blank"
        >
          Learn More
        </StyledLink>
      </p>
      <p>
        You will receive another email notification once the conversation upload
        is complete. Uploaded conversations can be viewed from the Closed folder
        in your inbox until you reopen the conversation.
      </p>
    </Box>
  );
}

export default ConversationUploadDescription;
