import { Component } from "react";
import { SizeMe } from "react-sizeme";

import MainView from "./MainView";

export default class Root extends Component {
  render() {
    return (
      <SizeMe>
        {({ size: { width } }) => {
          return (
            <MainView
              {...this.props}
              containerQueryParams={{ isSmall: width < 250 }}
            />
          );
        }}
      </SizeMe>
    );
  }
}
