import { useMemo, useState } from "react";

export const useLoading = (defaultState = false) => {
  const [isLoading, setIsLoading] = useState(defaultState);

  const { startLoading, stopLoading } = useMemo(() => {
    return {
      startLoading: () => {
        return setIsLoading(true);
      },
      stopLoading: () => {
        return setIsLoading(false);
      },
    };
  }, []);

  return { isLoading, startLoading, stopLoading };
};
