import assert from "assert";
import { Account, userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";

export const finishSequenceRecipients = async (
  currentAccount: Account.Raw,
  sequenceId: string,
  ids: string[],
  all: boolean,
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequences/${sequenceId}/sequence_recipients/finish`;

  const body = all ? JSON.stringify({ all }) : JSON.stringify({ ids });

  const request = new Request(url, {
    method: "PUT",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body,
  });

  return fetch(request);
};
