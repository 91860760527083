import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { saga } from "../state/conversationState";
import { useConversationUpload } from "../hooks/useConversationUpload";
import { RejectedFilesT } from "../models/UploadModels";
import ImportZone from "./ImportZone";
import injectSaga from "utils/injectSaga";
import H3 from "components/H3";
import ImportLoader from "features/ContactImports/containers/NewContactImport/components/ImportLoader";

interface ConversationUploadProps {
  onSkip?: () => void;
}

function ConversationUpload({ onSkip }: ConversationUploadProps) {
  const [error, setError] = useState<string | null>(null);
  const { isUploading, processConversationsFile } = useConversationUpload();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (error) {
      timeout = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => {
      return clearTimeout(timeout);
    };
  }, [error]);

  const handleFilesRejected = (filesWithErrors: RejectedFilesT) => {
    const _error =
      filesWithErrors[0]?.errors[0]?.message ||
      "Something went wrong. Please try again.";
    setError(_error);
  };

  const handleBack = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onSkip && onSkip();
  };

  if (isUploading)
    return (
      <Box margin="0 auto" maxWidth="720px" px={{ xs: 2, sm: 4 }} py={0}>
        <ImportLoader />
      </Box>
    );

  return (
    <Box margin="0 auto" maxWidth="720px" px={{ xs: 2, sm: 4 }} py={0}>
      <ImportZone
        onFileAccepted={processConversationsFile}
        onFileRejected={handleFilesRejected}
        error={error}
        maxFileSize={1_000_000 * 50} // 50MB
        onReset={() => {
          return setError(null);
        }}
      >
        <>
          <H3 style={{ margin: "1rem" }}>Step 2</H3>
          <Typography variant="body1" my="0.2rem">
            Drop your conversation CSV to upload
          </Typography>
          <Typography variant="body2" my="0.2rem">
            or
          </Typography>
          <Button variant="outlined" color="primary" sx={{ my: "0.2rem" }}>
            Select File
          </Button>
          <Typography variant="caption" sx={{ mt: "5rem" }}>
            Back to <Button onClick={handleBack}>Step 1</Button>
          </Typography>
        </>
      </ImportZone>
    </Box>
  );
}

// @ts-ignore
const withSaga = injectSaga({ key: "NewConversationImportContainer", saga });

export default withSaga(ConversationUpload);
