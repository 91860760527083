import { textUsApiV4 } from "services";

const fetchOverages = (getBody: {
  slug: string;
  contactCollection: string;
  contactFilter: string;
  recipients: [];
  type: string;
  scheduledAt: string;
  schedule: string;
}): Promise<Response> => {
  return textUsApiV4.post(`/${getBody.slug}/campaigns/daily_overages`, getBody);
};

export { fetchOverages };
