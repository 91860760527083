import { Component } from "react";
import styled from "styled-components";
import Logo from "components/Logo";

const Root = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  z-index: 3;
`;

export default class LoadingOverlay extends Component {
  render() {
    return (
      <Root>
        <Logo animate width={70} dotColor="primary" color="transparent" />
      </Root>
    );
  }
}
