import get from "lodash/get";
import queryString from "query-string";

export default (baseId, { location } = {}) => {
  const { url, query } = queryString.parseUrl(baseId ?? "");
  const search = get(location, ["search"], document.location.search);
  const currentQueryParams = queryString.parse(search);
  const revisedQuery = queryString
    .stringify({
      ...query,
      ...currentQueryParams,
    })
    .replace(/%20/g, "+")
    .replace(/\*/g, "%2A")
    .replace(/[!'()]/g, (char) => {
      return `%${char.charCodeAt(0).toString(16)}`;
    });
  const revisedSearch = revisedQuery ? `?${revisedQuery}` : "";
  return `${url}${revisedSearch}`;
};
