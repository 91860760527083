export const messageTypes = Object.freeze({
  conversation: "conversationAppendedMessages",
  campaign: "campaignAppendedMessages",
  defaults: "appendedMessageDefaults",
  introduction: "introMessages",
});

export const messageSettings = Object.freeze({
  [messageTypes.defaults]: {
    fields: {
      message: "appendedMessageDefaults.value.message",
      frequency: "appendedMessageDefaults.value.frequency",
      active: "appendedMessageDefaults.active",
    },
    message: "Reply STOP to opt-out.",
    frequency: 30,
    maxChars: 160,
  },
  [messageTypes.introduction]: {
    fields: {
      enabled: "introMessages.value.messageEnabled",
      frequency: "introMessages.value.frequency",
      message: "introMessages.value.message",
      position: "introMessages.value.position",
    },
    maxChars: 1440,
    placeholder: "Type introduction here",
  },
  [messageTypes.conversation]: {
    fields: {
      appendOnEvery: "conversationAppendedMessages.value.appendOnEvery",
      customMessageEnabled:
        "conversationAppendedMessages.value.customMessageEnabled",
      enabled: "conversationAppendedMessages.value.messageEnabled",
      message: "conversationAppendedMessages.value.customMessage",
    },
    maxChars: 160,
    placeholder: "Type appended content here",
  },
  [messageTypes.campaign]: {
    fields: {
      appendOnEvery: "campaignAppendedMessages.value.appendOnEvery",
      customMessageEnabled:
        "campaignAppendedMessages.value.customMessageEnabled",
      enabled: "campaignAppendedMessages.value.messageEnabled",
      message: "campaignAppendedMessages.value.customMessage",
    },
    maxChars: 160,
    placeholder: "Type appended content here",
  },
});

export const introSectionCopy = Object.freeze({
  title: "Introduction message",
  slug: "introduction",
  paragraphs: [
    "The intro message will be included with the first outbound text message sent to a contact from a messaging account. Intro messages can help your organization maintain compliance by clearly stating your company's name.",
    "Your company name helps identify yourself to new contacts, and introduce your team across all conversations in a standard fashion.",
  ],
});

export const appendedSectionCopy = Object.freeze({
  title: "Appended message",
  slug: "appended",
  paragraphs: [
    "The append message will be added at the end of outbound messages sent from a messaging account. Appended messages can be used to easily include information, such as opt-out language, on some or all outbound messages.",
    "Default opt-out language can be used for both conversations and campaigns, or custom language can be set for each.",
    "Note that the character limit for an appended message is 160 characters.",
  ],
});

export const messagePositions = Object.freeze({
  before: "before",
  after: "after",
});

export const stickyFooterHeight = Object.freeze({
  mobile: "6.5rem",
  desktop: "4rem",
});

export const customShadow =
  "5px 1px 10px 0px rgba(0, 0, 0, 0.12), 5px 4px 5px 0px rgba(0, 0, 0, 0.14), 5px 2px 4px -1px rgba(0, 0, 0, 0.20)";
