import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { IconButton, Popover, Tooltip } from "@mui/material";
import { PeopleIcon } from "icons";

import H4 from "components/H4";
import List from "components/List";
import Avatar from "components/Avatar";

const PopoverContent = styled.div`
  border-radius: 4px;
  width: 260px;
`;

const ParticipantName = styled.div`
  margin-left: 20px;
`;

const Participant = styled.li`
  align-items: center;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  flex-flow: row;
  font-size: ${(props) => {
    return props.theme.fonts.messageFontSize;
  }};
  margin: 10px 0;
`;

const ActiveSection = styled.div`
  padding: 15px 20px 5px 20px;

  ${Participant} {
    color: ${(props) => {
      return props.theme.colors.text.primary;
    }};
  }

  &:not(:first-child) {
    border-top: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
  }
`;

const DiscardedSection = styled.div`
  background: ${(props) => {
    return props.theme.colors.action.hover;
  }};
  border-top: ${(props) => {
    return props.theme.mixins.border({
      color: props.theme.colors.divider,
    });
  }};
  padding: 15px 20px 5px 20px;

  ${H4} {
    color: ${(props) => {
      return props.theme.colors.text.primary;
    }};
  }
`;

const EmptyList = styled.p`
  font-size: ${(props) => {
    return props.theme.fonts.secondaryFontSize;
  }};
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 10px;
`;

class ParticipantsOption extends Component {
  static propTypes = {
    participants: PropTypes.array.isRequired,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    const { participants, disabled } = this.props;
    const activeParticipants = participants.filter((participant) => {
      return !participant.discarded && !participant.automatedActor;
    });
    const discardedParticipants = participants.filter((participant) => {
      return participant.discarded && !participant.automatedActor;
    });

    return (
      <>
        <Tooltip title="Participants" disableFocusListener>
          <IconButton
            disabled={disabled === true}
            aria-haspopup="true"
            onClick={this.handleOpen}
            data-product-tour="ConversationOption-Participants"
          >
            <PeopleIcon />
          </IconButton>
        </Tooltip>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={this.handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <PopoverContent>
            {" "}
            <ActiveSection>
              <H4>Participants</H4>
              <List>
                {participants && participants.length ? (
                  activeParticipants.map((participant) => {
                    return (
                      <Participant key={participant.id}>
                        <Avatar subject={participant} />
                        <ParticipantName data-testid="Participant Name">
                          {participant.name}
                        </ParticipantName>
                      </Participant>
                    );
                  })
                ) : (
                  <EmptyList>No Participants</EmptyList>
                )}
              </List>
            </ActiveSection>
            {discardedParticipants.length > 0 && (
              <DiscardedSection>
                <H4>Archived Participants</H4>
                <List>
                  {discardedParticipants.map((participant) => {
                    return (
                      <Participant key={participant.id}>
                        <Avatar subject={participant} />
                        <ParticipantName>{participant.name}</ParticipantName>
                      </Participant>
                    );
                  })}
                </List>
              </DiscardedSection>
            )}
          </PopoverContent>
        </Popover>
      </>
    );
  }
}

export default ParticipantsOption;
