import styled from "styled-components";
import { useDropzone } from "./useDropzone";
import type { Props } from "./types";

const Root = styled.div`
  &.visible {
    visibility: visible;
  }
  &.hidden {
    visibility: hidden;
  }
`;

const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  padding: 20px;
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  border: 2px dashed
    ${(props) => {
      return props.theme.colors.divider;
    }};
  border-radius: ${(props) => {
    return props.theme.defaults.uploadZoneBorderRadius;
  }};

  &:hover {
    border: 2px dashed
      ${(props) => {
        return props.theme.colors.primary.main;
      }};
  }
  &.error {
    border-color: ${(props) => {
      return props.theme.colors.error.main;
    }};
  }
`;

const HiddenInput = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  outline: none;
  pointer-events: none;
`;

export function Dropzone({
  acceptedFileTypes,
  children,
  className,
  error,
  isVisible = true,
  maxFileSize,
  onFileAccepted,
  onFileRejected,
  rootStyles,
  wrapperStyles,
}: Props) {
  const errorStyles = error ? "error" : "";
  const visible = isVisible ? "visible" : "hidden";
  const { getInputProps, getRootProps } = useDropzone({
    accept: acceptedFileTypes,
    maxSize: maxFileSize,
    onFileAccepted,
    onFileRejected,
  });

  return (
    <Root
      aria-label="upload files"
      {...getRootProps({ style: rootStyles })}
      className={[className, visible].join(" ")}
    >
      <Wrapper
        aria-label="dropzone-wrapper"
        className={["dropzone-wrapper", errorStyles].join(" ")}
        style={{ ...wrapperStyles }}
      >
        <HiddenInput
          aria-label="dropzone-input"
          data-testid="dropzone-input"
          {...getInputProps()}
        />
        {children}
      </Wrapper>
    </Root>
  );
}
