import { Component } from "react";
import get from "lodash/get";
import union from "lodash/union";
import PropTypes from "prop-types";
import queryString from "query-string";
import styled from "styled-components";

import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";

import ConversationBatchActions from "./ConversationBatchActions";
import ConversationCollection from "features/Inbox/containers/ConversationCollection";
import getNestedId from "utils/getNestedId";
import PageHeader from "components/Page/PageHeader";

const FILTERS_WITH_SUBFILTERS = new Set(["open", "you", "starred"]);

const Wrapper = styled.div`
  background: ${(props) => {
    return props.fullWidth
      ? props.theme.colors.background.paper
      : props.theme.colors.background.default;
  }};
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  width: 100%;
  position: relative;
`;

const SelectAllSection = styled.div`
  align-items: center;
  border-left: 3px solid transparent;
  display: flex;
  flex: 0 0 55px;
  padding-left: 5px;
  padding-right: 15px;
  position: relative;
  width: 100%;

  ${(props) => {
    return `
    background: ${
      props.isActive
        ? props.theme.colors.message.outboundBackground
        : props.theme.colors.background.paper
    };
    border-bottom: ${
      props.isActive
        ? `1px solid ${props.theme.colors.background.paper}`
        : props.theme.mixins.border({ color: props.theme.colors.divider })
    };
  `;
  }}

  > *:first-child {
    flex: 0 0 auto;
    margin-right: 5px;
  }
`;

const ConversationCount = styled.div`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-size: 0.8rem;
  font-weight: 500;
  flex: 1 1 auto;
  min-width: 0;
  text-align: right;
`;

const SelectAllButton = styled(Button)``;

export default class Root extends Component {
  static propTypes = {
    closeConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    conversationFilter: PropTypes.object.isRequired, // withRecord
    conversationFilterId: PropTypes.string.isRequired, // passed
    currentAccount: PropTypes.object.isRequired, // withConnect
    currentUser: PropTypes.object.isRequired, // withConnect
    fetchConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    assignConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    mediumScreen: PropTypes.bool.isRequired, // withSizes
    history: PropTypes.object.isRequired, // passed
    location: PropTypes.object.isRequired, // passed
    match: PropTypes.object.isRequired, // passed
    readConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    reopenConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    setCompose: PropTypes.func.isRequired, // withConnect
    showSecondaryNavbar: PropTypes.bool, // passed
    starConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    toggleSidebar: PropTypes.func.isRequired,
    toggleSearch: PropTypes.func.isRequired,
    unassignConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    unreadConversationFilterRequest: PropTypes.func.isRequired, // withRecord
    unstarConversationFilterRequest: PropTypes.func.isRequired, // withRecord
  };

  constructor(props) {
    super(props);
    this.state = {
      allConversationsSelected: false,
      selectedConversations: [],
      selectedRecords: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { conversationFilterId, conversationFilter } = this.props;
    if (prevProps.conversationFilterId !== conversationFilterId) {
      this.clearBatchActionState();
    }
    if (
      typeof prevProps.conversationFilter.conversations === "object" &&
      prevProps.conversationFilter.conversations.totalItems !==
        conversationFilter.conversations.totalItems
    ) {
      this.setState((prevState) => {
        return {
          selectedConversations: prevState.selectedConversations.filter(
            (conversationId) => {
              return conversationFilter.conversations.members.some(({ id }) => {
                return id === conversationId;
              });
            },
          ),
        };
      });
    }
  }

  setSelected = (selectedConversations) => {
    this.setState({ allConversationsSelected: false, selectedConversations });
  };

  setSelectedRecords = (selectedRecords) => {
    this.setState({ allConversationsSelected: false, selectedRecords });
  };

  getUniqueRecords = (prevState, visibleRecords) => {
    const recordsSet = new Set();
    const updatedRecords = [...prevState.selectedRecords, ...visibleRecords];
    return updatedRecords.filter((record) => {
      const isDuplicate = recordsSet.has(record?.id);
      recordsSet.add(record?.id);
      return !isDuplicate;
    });
  };

  getRecipientIds = () => {
    const {
      conversationFilter: {
        conversations: { members: conversations },
      },
    } = this.props;

    const { selectedConversations } = this.state;

    const recipientIds = selectedConversations
      .map((selectedId) => {
        const conversation = conversations.find((c) => {
          return c.id === selectedId;
        });
        const { assignedContact } = conversation;
        return get(assignedContact, ["phones", "members"], []).find(
          (contactPhone) => {
            return contactPhone.phoneNumber === conversation.phoneNumber;
          },
        );
      })
      .filter(Boolean)
      .map((contactPhone) => {
        return contactPhone.id;
      });

    return recipientIds;
  };

  setAllConversationsSelected = (allConversationsSelected) => {
    const { conversationFilter } = this.props;

    const visibleConversations = get(
      conversationFilter,
      ["conversations", "members"],
      [],
    );

    const visibleIds = visibleConversations.map((record) => {
      return record?.id;
    });

    const visibleRecords = visibleConversations.map((record) => {
      return record?.assignedContact;
    });

    this.setState((prevState) => {
      const uniqueRecords = this.getUniqueRecords(prevState, visibleRecords);
      const updatedRecords = allConversationsSelected ? uniqueRecords : [];

      return {
        allConversationsSelected,
        selectedConversations: allConversationsSelected
          ? union(prevState.selectedConversations, visibleIds)
          : [],
        selectedRecords: updatedRecords,
      };
    });
  };

  clearBatchActionState = () => {
    this.setState({
      allConversationsSelected: false,
      selectedConversations: [],
    });
  };

  handleSetCompose = () => {
    const { setCompose } = this.props;
    const recipientIds = this.getRecipientIds();
    setCompose({ active: true, recipientIds });
  };

  handleToggleAllVisible = (conversations) => {
    return () => {
      const { selectedConversations } = this.state;

      const conversationIds = conversations.map((conversation) => {
        return conversation.id;
      });

      const contactRecords = conversations.map((conversation) => {
        return conversation.assignedContact;
      });

      return selectedConversations.length > 0
        ? this.setState({
            allConversationsSelected: false,
            selectedConversations: [],
            selectedRecords: [],
          })
        : this.setState({
            selectedConversations: conversationIds,
            selectedRecords: contactRecords,
          });
    };
  };

  handleToggleFilter = (type) => {
    return () => {
      const { history, location } = this.props;
      const queryParams = queryString.parse(location.search, {
        parseBooleans: true,
      });
      const search = queryString.stringify({
        ...queryParams,
        [type]: !queryParams[type] || undefined,
      });
      history.push({
        pathname: location.pathname,
        search,
      });
    };
  };

  showFilters = () => {
    const { match } = this.props;
    const activeConversationFilterSlug = get(match, [
      "params",
      "activeConversationFilterSlug",
    ]);
    return FILTERS_WITH_SUBFILTERS.has(activeConversationFilterSlug);
  };

  render() {
    const {
      currentAccount,
      currentUser,
      conversationFilter,
      mediumScreen,
      toggleSidebar,
      toggleSearch,
      closeConversationFilterRequest,
      assignConversationFilterRequest,
      readConversationFilterRequest,
      fetchConversationFilterRequest,
      reopenConversationFilterRequest,
      showSecondaryNavbar,
      starConversationFilterRequest,
      unassignConversationFilterRequest,
      unreadConversationFilterRequest,
      unstarConversationFilterRequest,
    } = this.props;

    const { allConversationsSelected, selectedConversations, selectedRecords } =
      this.state;
    const conversationCollection = get(conversationFilter, ["conversations"]);
    const conversationCollectionFetched = get(conversationCollection, ["id"]);
    const showSelectAll =
      conversationCollectionFetched && conversationCollection.totalItems > 0;
    const showBatchActions =
      conversationCollectionFetched && selectedConversations.length > 0;
    const showFilters = this.showFilters();
    const conversationCount = `(${conversationCollection?.totalItems ?? "0"})`;

    return (
      <Wrapper fullWidth={mediumScreen}>
        <PageHeader
          className="inbox-page-header"
          title={`${conversationFilter.title} ${conversationCount}`}
          displayUnanswered={showFilters}
          displayUnread={showFilters}
          displaySearch={Boolean(showFilters)}
          showSecondaryNavbar={showSecondaryNavbar}
          toggleSidebar={toggleSidebar}
          toggleUnanswered={this.handleToggleFilter("unanswered")}
          toggleUnread={this.handleToggleFilter("unread")}
          toggleSearch={toggleSearch}
        />
        {showSelectAll && (
          <SelectAllSection isActive={selectedConversations.length > 0}>
            <Checkbox
              color="secondary"
              indeterminate={
                selectedConversations.length > 0 &&
                selectedConversations.length <
                  conversationCollection.members.length
              }
              checked={
                selectedConversations.length ===
                conversationCollection.members.length
              }
              onChange={this.handleToggleAllVisible(
                conversationCollection.members,
              )}
              inputProps={{
                "aria-label": "select all conversations",
                "data-testid": "select-all-conversations",
              }}
            />
            <SelectAllButton
              color="primary"
              onClick={this.handleToggleAllVisible(
                conversationCollection.members,
              )}
            >
              Select All
            </SelectAllButton>
            {showFilters && (
              <ConversationCount
                aria-label="Option to select all Conversations in Total"
                data-testid="option-to-select-all-conversations"
              >
                {conversationCollection.totalItems > 0 &&
                  conversationCollection.totalItems}
              </ConversationCount>
            )}
          </SelectAllSection>
        )}
        {showBatchActions && (
          <ConversationBatchActions
            activeConversationFilter={conversationFilter}
            allConversationsSelected={allConversationsSelected}
            clearBatchActionState={this.clearBatchActionState}
            conversationCollection={conversationCollection}
            currentAccount={currentAccount}
            currentUser={currentUser}
            getRecipientIds={this.getRecipientIds}
            setAllConversationsSelected={this.setAllConversationsSelected}
            handleSetCompose={this.handleSetCompose}
            isRefetching={false}
            closeConversationFilterRequest={closeConversationFilterRequest}
            assignConversationFilterRequest={assignConversationFilterRequest}
            readConversationFilterRequest={readConversationFilterRequest}
            refetchConversationFilterRequest={fetchConversationFilterRequest}
            reopenConversationFilterRequest={reopenConversationFilterRequest}
            selectedConversations={selectedConversations}
            selectedRecords={selectedRecords}
            setSelectedRecords={this.setSelectedRecords}
            starConversationFilterRequest={starConversationFilterRequest}
            unassignConversationFilterRequest={
              unassignConversationFilterRequest
            }
            unreadConversationFilterRequest={unreadConversationFilterRequest}
            unstarConversationFilterRequest={unstarConversationFilterRequest}
          />
        )}
        <ConversationCollection
          conversationFilter={conversationFilter}
          conversationCollectionId={getNestedId(
            conversationFilter,
            "conversations",
          )}
          selectedConversations={selectedConversations}
          selectedRecords={selectedRecords}
          setSelected={this.setSelected}
          setSelectedRecords={this.setSelectedRecords}
        />
      </Wrapper>
    );
  }
}
