import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import { ListItemIcon, Typography, styled } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { getFontAwesomeIcon } from "../utils";

const StyledLink = styled(NavLink)`
  color: ${(props) => {
    return props.theme.palette.text.primary;
  }};
  text-decoration: none;
`;

export default function OverflowMenu({ siteNavigation }: any) {
  // ==== HOOKS ==== //
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // ==== FIELDS ==== //
  const open = Boolean(anchorEl);

  // ==== METHODS ==== //
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ==== RENDER ==== //
  return (
    <div>
      <IconButton
        aria-label="more"
        id="overflow-button"
        aria-controls={open ? "overflow-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuIcon
          sx={{
            color: "#fff",
          }}
        />
      </IconButton>
      <Menu
        id="overflow-menu"
        MenuListProps={{
          "aria-labelledby": "overflow-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {siteNavigation.members.map(
          ({ icon, uiLink, name, title, badgeRecord }: any) => {
            const fontAwesomeIcon = getFontAwesomeIcon(icon);

            // The overflow menu only displays Analytics and Settings.
            if (name !== "analytics" && name !== "settings") {
              return null;
            }

            return (
              <StyledLink
                to={uiLink}
                data-product-tour={`SiteNavigationLink-${title}`}
              >
                <MenuItem key={name} onClick={handleClose}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={fontAwesomeIcon} />
                  </ListItemIcon>
                  <Typography variant="inherit">{title}</Typography>
                </MenuItem>
              </StyledLink>
            );
          },
        )}
      </Menu>
    </div>
  );
}
