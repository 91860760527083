import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { useSnackbar } from "notistack";
import { actionGenerators } from "../state/conversationState";
import { parseCsv } from "features/AccountSettings/utils";
import { useCurrentAccount } from "hooks";

interface useConversationUploadReturn {
  error: any;
  isUploading: boolean;
  processConversationsFile: (file: File) => void;
}

export const useConversationUpload = (): useConversationUploadReturn => {
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const account = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const {
    // @ts-ignore
    uploadConversationImportRequest,
    // @ts-ignore
    importConversationImportRequest,
  } = actionGenerators;

  useEffect(() => {
    if (signedUrl) {
      uploadConversations(signedUrl);
    }
  }, [signedUrl]);

  const parseFile = (csv: File) => {
    return parseCsv({
      csv,
      successCallback: () => {
        setError(null);
      },
      errorCallback: (_error: any) => {
        setError(_error);
      },
    });
  };

  const processConversationsFile = (file: File) => {
    setIsUploading(true);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      parseFile(file);
      uploadFile(file);
    });
    reader.readAsArrayBuffer(file);
  };

  /*
   * The file has been parsed and is being uploaded to S3
   * -> a signedUrl to the bucket is returned
   */
  const uploadFile = (file: File) => {
    dispatch(
      uploadConversationImportRequest(
        account.presignContactImport,
        {
          file,
        },
        {
          successCallback: (_signedUrl: string) => {
            setSignedUrl(_signedUrl);
          },
          errorCallback: (errorUploading: any) => {
            setError(errorUploading);
          },
          cleanup: () => {
            return setIsUploading(false);
          },
        },
      ),
    );
  };

  /*
   * The conversations are now finally being imported into our system
   */
  const uploadConversations = (url: string) => {
    setIsUploading(true);
    dispatch(
      importConversationImportRequest(
        account.conversationImport || `/${account.slug}/messages/import`,
        {
          url,
        },
        {
          successCallback: () => {
            setSignedUrl(null);
            setIsUploading(false);
            enqueueSnackbar(
              "We're working on importing your conversations now. We'll send you an email notification when everything is complete.",
              { variant: "info" },
            );
          },
          errorCallback: (errorImporting: any) => {
            setError(errorImporting);
            setIsUploading(false);
            enqueueSnackbar("Something went wrong. Please try again.", {
              variant: "error",
            });
          },
        },
      ),
    );
  };

  return {
    error,
    isUploading,
    processConversationsFile,
  };
};
