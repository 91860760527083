import { Typography, useTheme } from "@mui/material";
import type { SettingsCardDescriptionProps } from "./types";

export function SettingsCardDescription({
  sx,
  ...props
}: SettingsCardDescriptionProps) {
  const theme = useTheme();
  return (
    <Typography
      variant="body2"
      gutterBottom={false}
      sx={{
        marginX: "16px",
        marginBottom: 2,
        ...(typeof sx === "function" ? sx(theme) : sx),
      }}
      {...props}
    />
  );
}
