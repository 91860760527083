import { Component } from "react";
import { compose } from "redux";
import { normalize } from "normalizr";
import { put } from "redux-saga/effects";

import * as schema from "schema";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import ConversationThread from "components/ConversationThread";
import withRecord from "higherOrderComponents/withRecord";

class Timeline extends Component {
  render() {
    return <ConversationThread {...this.props} />;
  }
}

function* optimisticUpdateFunc(url) {
  const revisedRecord = {
    id: url.replace("/retry", ""),
    deliveryState: "queued",
  };
  const { entities: revisedEntities } = normalize(
    revisedRecord,
    schema.message,
  );
  yield put(updateRecords(revisedEntities));
}

export default compose(
  withRecord({
    actions: ["paginate"],
    container: "ActiveConversation/containers/Timeline",
    type: "timeline",
  }),
  withRecord({
    actions: [{ type: "create", optimisticUpdateFunc }],
    container: "ActiveConversation/containers/Message",
    type: "message",
  }),
)(Timeline);
