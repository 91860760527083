import snakeCase from "lodash/snakeCase";
import toUpper from "lodash/toUpper";
import uniq from "lodash/uniq";

import { accountEvents, userEvents } from "utils/pusherConfig";

const constantized = (val) => {
  return toUpper(snakeCase(val));
};

const pusherActionTypes = uniq([...accountEvents, ...userEvents]).reduce(
  (prev, pusherEvent) => {
    return {
      ...prev,
      [constantized(pusherEvent)]: `app/PusherComponent/${constantized(
        pusherEvent,
      )}`,
    };
  },
  {},
);

export default pusherActionTypes;
