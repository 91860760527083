import snakeCase from "lodash/snakeCase";

export default (strategy) => {
  switch (strategy) {
    case "BullhornNovoStrategy":
      return "bullhorn";
    case "DataAttributeStrategy":
      return "data_attribute_strategy";
    case "FullPageSearchStrategy":
      return "full_page_strategy";
    case "LaborEdgeNexusStrategy":
      return "labor_edge";
    case "MicrosoftDynamicsStrategy":
      return "ms_dynamics";
    case "SalesforceLightningStrategy":
      return "salesforce";
    case "SelectionStrategy":
      return "selection_strategy";
    default:
      return snakeCase(strategy).replace("_strategy", "");
  }
};
