import { TextUsColor } from "./types";

export const green: TextUsColor = {
  50: "#E8F5E9",
  100: "#C8E6C9",
  200: "#A5D6A7",
  300: "#81C784",
  400: "#66BB6A",
  500: "#4CAF50",
  600: "#43A047",
  700: "#388E3C",
  800: "#2E7D32",
  900: "#1B5E20",
  A8: "rgba(46, 125, 50, 0.08)",
  A12: "rgba(46, 125, 50, 0.12)",
  A16: "rgba(46, 125, 50, 0.16)",
  A30: "rgba(46, 125, 50, 0.3)",
  A50: "rgba(46, 125, 50, 0.5)",
};
