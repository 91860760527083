// import InboxSettings from "../components/InboxSettings";

import { SettingsSection } from "models/SettingsSection";

export const getAnalyticsSettings = ({
  isAdmin,
}: {
  isAdmin: boolean;
}): SettingsSection | null => {
  return isAdmin // this entire collection of settings is restricted to admins
    ? {
        title: "Analytics Settings",
        path: "analytics",
        settings: [
          // {
          //   title: "Scheduled Analytics",
          //   to: `/scheduled-analytics`,
          //   OptionComponent: <h1>I dont work</>, // FIXME: just a placeholder component
          // },
        ],
      }
    : null;
};
