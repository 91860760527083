import { useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { useSnackbar } from "notistack";
import AccountPlansSettings from "./components/AccountPlansSettings";
import LicenseDelegationTable from "./components/LicenseDelegationTable";
import withRecord from "higherOrderComponents/withRecord";

import PageHeader from "components/Page/PageHeader";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";

interface AccountConfigurationProps {
  account: Record<string, any>;
  toggleSidebar: () => void;
  fetchAccountPlansRequest: (
    accountPlanId: string,
    body: Record<string, any> | null,
    returnCallbacks: {
      successCallback: (response: Record<string, any>) => void;
      errorCallback: (error: Record<string, any>) => void;
    },
  ) => void;
}

function AccountConfiguration({
  account,
  fetchAccountPlansRequest,
  toggleSidebar,
}: AccountConfigurationProps) {
  const { enqueueSnackbar } = useSnackbar();
  const accountPlansId = `/${account?.slug}/account_plans`;

  const successCallback = () => {};

  const errorCallback = (error: Record<string, any>) => {
    enqueueSnackbar("There was an error retrieving account plans.", {
      variant: "error",
    });
  };

  useEffect(() => {
    fetchAccountPlansRequest(accountPlansId, null, {
      successCallback,
      errorCallback,
    });
  }, [accountPlansId]);

  return (
    <SettingsPageWrapper>
      <PageHeader title="Account Configuration" toggleSidebar={toggleSidebar} />
      <SettingsPageContent maxWidth="1024px" overflow="auto">
        <AccountPlansSettings />
        <LicenseDelegationTable />
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

const mapStateToProps = () => {
  return {};
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withRecord({
    actions: ["fetch"],
    container:
      "features/AccountSettings/components/AccountConfiguration/AccountPlansSettings",
    multiple: false,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "accountPlans",
  }),
  withConnect,
)(AccountConfiguration);
