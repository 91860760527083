let onAbort: ((message: string) => void) | undefined;

export class AbortablePromise<T> extends Promise<T> {
  constructor(
    executor: (
      resolve: (value: T | PromiseLike<T>) => void,
      reject: (reason?: any) => void,
    ) => (message: string) => void,
  ) {
    super((resolve, reject) => {
      onAbort = executor(resolve, reject);
    });
  }

  public abort(message: string) {
    onAbort?.(message);
  }
}
