/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { GridRowId } from "@mui/x-data-grid-pro";
import { AccountPlan } from "../models/AccountPlanModels";

export const useLicenseAssignmentModal = (
  campaignsPro: AccountPlan,
  keywords: AccountPlan,
  accountIds: GridRowId[],
  sequences: AccountPlan,
) => {
  const [alertShowing, setAlertShowing] = useState<boolean>(false);

  const [campaignsProChecked, setCampaignsProChecked] =
    useState<boolean>(false);
  const [campaignsProEnabled, setCampaignsProEnabled] =
    useState<boolean>(false);
  const [campaignsProShowing, setCampaignsProShowing] =
    useState<boolean>(false);

  const [keywordsChecked, setKeywordsChecked] = useState<boolean>(false);
  const [keywordsEnabled, setKeywordsEnabled] = useState<boolean>(false);
  const [keywordsShowing, setKeywordsShowing] = useState<boolean>(false);

  const [sequencesChecked, setSequencesChecked] = useState<boolean>(false);
  const [sequencesEnabled, setSequencesEnabled] = useState<boolean>(false);
  const [sequencesShowing, setSequencesShowing] = useState<boolean>(false);

  const hasLicensesToAssign = (accountPlan: AccountPlan): boolean => {
    return accountPlan.assignments?.length < accountPlan.quantity;
  };

  const accountHasLicense = (
    accountId: GridRowId,
    accountPlan: AccountPlan,
  ): boolean => {
    const foundAccount = accountPlan.assignments?.find((assignment) => {
      return assignment.account === accountId;
    });

    return Boolean(foundAccount);
  };

  /**
   * True in the following conditions:
   * 1. User has licenses to assign.
   * 2. Action is a Bulk Manage.
   * 3. Single account currently has license assigned (to allow for removing).
   */
  const getLicenseEnabled = (accountPlan: AccountPlan): boolean => {
    if (
      hasLicensesToAssign(accountPlan) ||
      accountIds.length > 1 ||
      accountHasLicense(accountIds[0], accountPlan)
    ) {
      return true;
    }

    return false;
  };

  const anyAccountHasLicense = (
    ids: GridRowId[],
    accountPlan: AccountPlan,
  ): boolean => {
    for (let i = 0; i < ids.length; i += 1) {
      if (accountHasLicense(ids[i], accountPlan)) {
        return true;
      }
    }

    return false;
  };

  const allAccountsHaveLicense = (
    ids: GridRowId[],
    accountPlan: AccountPlan,
  ): boolean => {
    let numAccountsWithLicense = 0;
    for (let i = 0; i < ids.length; i += 1) {
      if (accountHasLicense(ids[i], accountPlan)) {
        numAccountsWithLicense += 1;
      }
    }

    if (numAccountsWithLicense === 0) {
      return true;
    }

    return numAccountsWithLicense === ids.length;
  };

  useEffect(() => {
    setCampaignsProChecked(anyAccountHasLicense(accountIds, campaignsPro));
    setCampaignsProEnabled(getLicenseEnabled(campaignsPro));
    setCampaignsProShowing(Boolean(campaignsPro.assignments));

    setKeywordsChecked(anyAccountHasLicense(accountIds, keywords));
    setKeywordsEnabled(getLicenseEnabled(keywords));
    setKeywordsShowing(Boolean(keywords.assignments));

    setSequencesChecked(anyAccountHasLicense(accountIds, sequences));
    setSequencesEnabled(getLicenseEnabled(sequences));
    setSequencesShowing(Boolean(sequences.assignments));

    setAlertShowing(
      !allAccountsHaveLicense(accountIds, campaignsPro) ||
        !allAccountsHaveLicense(accountIds, keywords),
    );
  }, [accountIds, campaignsPro, keywords]);

  return {
    alertShowing,

    campaignsProChecked,
    campaignsProEnabled,
    campaignsProShowing,

    keywordsChecked,
    keywordsEnabled,
    keywordsShowing,

    sequencesChecked,
    sequencesEnabled,
    sequencesShowing,

    setCampaignsProChecked,
    setKeywordsChecked,
    setSequencesChecked,
  };
};
