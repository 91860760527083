export default function NoResultsIcon(displayMode: { mode: string }) {
  const { mode } = displayMode;

  return mode === "light" ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="124"
      viewBox="0 0 121 124"
      fill="none"
      data-testid="no-results-icon"
    >
      <path
        d="M55.8499 26.5304C72.4299 26.5304 85.9199 40.0204 85.9199 56.6004C85.9199 73.1804 72.4299 86.6704 55.8499 86.6704C39.2699 86.6704 25.7799 73.1804 25.7799 56.6004C25.7799 40.0204 39.2699 26.5304 55.8499 26.5304ZM55.8499 19.4004C35.2999 19.4004 18.6499 36.0604 18.6499 56.6004C18.6499 77.1404 35.3099 93.8004 55.8499 93.8004C76.3899 93.8004 93.0499 77.1404 93.0499 56.6004C93.0499 36.0604 76.3899 19.4004 55.8499 19.4004Z"
        fill="#AFBDC3"
      />
      <path
        d="M55.8499 26.5304C72.4299 26.5304 85.9199 40.0204 85.9199 56.6004C85.9199 73.1804 72.4299 86.6704 55.8499 86.6704C39.2699 86.6704 25.7799 73.1804 25.7799 56.6004C25.7799 40.0204 39.2699 26.5304 55.8499 26.5304ZM55.8499 19.4004C35.2999 19.4004 18.6499 36.0604 18.6499 56.6004C18.6499 77.1404 35.3099 93.8004 55.8499 93.8004C76.3899 93.8004 93.0499 77.1404 93.0499 56.6004C93.0499 36.0604 76.3899 19.4004 55.8499 19.4004Z"
        fill="#AFBDC3"
      />
      <path
        d="M55.8499 26.5304C72.4299 26.5304 85.9199 40.0204 85.9199 56.6004C85.9199 73.1804 72.4299 86.6704 55.8499 86.6704C39.2699 86.6704 25.7799 73.1804 25.7799 56.6004C25.7799 40.0204 39.2699 26.5304 55.8499 26.5304ZM55.8499 19.4004C35.2999 19.4004 18.6499 36.0604 18.6499 56.6004C18.6499 77.1404 35.3099 93.8004 55.8499 93.8004C76.3899 93.8004 93.0499 77.1404 93.0499 56.6004C93.0499 36.0604 76.3899 19.4004 55.8499 19.4004Z"
        fill="#94BCF9"
      />
      <path
        d="M55.8499 82.8004C70.3198 82.8004 82.0499 71.0703 82.0499 56.6004C82.0499 42.1305 70.3198 30.4004 55.8499 30.4004C41.38 30.4004 29.6499 42.1305 29.6499 56.6004C29.6499 71.0703 41.38 82.8004 55.8499 82.8004Z"
        fill="#6FA5F8"
      />
      <path
        d="M71.3298 53.8094C70.1598 47.4494 65.1498 42.4294 58.8098 41.2194C57.1898 40.9094 56.0098 39.5294 56.0098 37.8794C56.0098 35.7194 57.9898 34.1394 60.1098 34.5494C69.1898 36.2894 76.3298 43.4594 78.0098 52.5594C78.3998 54.6794 76.8298 56.6394 74.6698 56.6394C73.0098 56.6394 71.6298 55.4394 71.3298 53.8094Z"
        fill="white"
      />
      <path
        d="M118.37 109L89.2698 79.9004C86.6398 83.6604 83.3899 86.9604 79.6599 89.6504L109.24 119.23C110.51 120.5 112.19 121.14 113.86 121.14C115.53 121.14 117.2 120.5 118.48 119.23C118.64 119.07 118.75 118.9 118.89 118.73C118.54 119.4 118.08 120.03 117.53 120.58C116.09 122.02 114.19 122.81 112.16 122.81C110.13 122.81 108.22 122.02 106.79 120.59L77.3898 91.1904C77.0398 91.4104 76.6898 91.6204 76.3398 91.8204L105.95 121.43C107.61 123.09 109.82 124 112.16 124C114.5 124 116.71 123.09 118.37 121.43C120.03 119.77 120.94 117.57 120.94 115.22C120.94 112.87 120.02 110.67 118.37 109.01V109Z"
        fill="#94BCF9"
      />
      <path
        d="M10.2199 24.7C8.41987 24.64 7.02987 22.97 7.43987 21.22C7.43987 21.19 7.44987 21.17 7.45987 21.14C7.69987 20.14 8.17987 19.25 8.86987 18.45C9.56987 17.66 10.5199 16.91 11.7299 16.21C12.9199 15.51 13.8299 14.89 14.4799 14.35C15.1299 13.81 15.5799 13.26 15.8299 12.7C16.0799 12.14 16.2099 11.49 16.2099 10.73V10.68C16.2099 9.96 16.0299 9.34 15.6699 8.82C15.3099 8.3 14.8199 7.88 14.1899 7.58C13.5599 7.28 12.8399 7.12 12.0199 7.12C11.1999 7.12 10.4399 7.3 9.80987 7.66C9.17987 8.02 8.66987 8.5 8.29987 9.11C7.82987 10.1 6.99987 10.88 5.96987 11.26C4.36987 11.85 2.91987 11.6 2.91987 11.6C0.309867 11.46 -0.780133 8.13 0.599867 5.74C0.679867 5.6 0.759867 5.47 0.839867 5.34C1.90987 3.66 3.44987 2.35 5.43987 1.41C7.42987 0.47 9.81987 0 12.6199 0C15.4199 0 17.5799 0.43 19.5499 1.3C21.5199 2.16 23.0399 3.38 24.1399 4.94C25.2299 6.5 25.7799 8.34 25.7799 10.45V10.5C25.7799 11.77 25.5799 12.94 25.1799 13.98C24.7799 15.03 24.1599 15.98 23.3199 16.84C22.4799 17.7 21.4199 18.5 20.1199 19.25C18.9299 19.93 18.0099 20.55 17.3399 21.11C16.6699 21.67 16.2099 22.24 15.9499 22.83C15.9499 22.83 15.9499 22.84 15.9499 22.85C15.4299 24.02 14.3699 24.85 13.0899 24.8L10.2299 24.7H10.2199ZM8.08987 37.12C7.12987 36.21 6.64987 35.09 6.64987 33.77C6.64987 32.45 7.12987 31.35 8.08987 30.44C9.04987 29.53 10.2199 29.08 11.5999 29.08C12.9799 29.08 14.1499 29.53 15.1199 30.44C16.0899 31.35 16.5699 32.46 16.5699 33.77C16.5699 35.08 16.0899 36.22 15.1199 37.12C14.1499 38.03 12.9799 38.48 11.5999 38.48C10.2199 38.48 9.04987 38.03 8.08987 37.12Z"
        fill="#6FA3F6"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="121"
      height="124"
      viewBox="0 0 121 124"
      fill="none"
    >
      <g clipPath="url(#clip0_9700_69933)">
        <path
          d="M55.8499 26.5304C72.4299 26.5304 85.9199 40.0204 85.9199 56.6004C85.9199 73.1804 72.4299 86.6704 55.8499 86.6704C39.2699 86.6704 25.7799 73.1804 25.7799 56.6004C25.7799 40.0204 39.2699 26.5304 55.8499 26.5304ZM55.8499 19.4004C35.2999 19.4004 18.6499 36.0604 18.6499 56.6004C18.6499 77.1404 35.3099 93.8004 55.8499 93.8004C76.3899 93.8004 93.0499 77.1404 93.0499 56.6004C93.0499 36.0604 76.3899 19.4004 55.8499 19.4004Z"
          fill="#AFBDC3"
        />
        <path
          d="M55.8499 26.5304C72.4299 26.5304 85.9199 40.0204 85.9199 56.6004C85.9199 73.1804 72.4299 86.6704 55.8499 86.6704C39.2699 86.6704 25.7799 73.1804 25.7799 56.6004C25.7799 40.0204 39.2699 26.5304 55.8499 26.5304ZM55.8499 19.4004C35.2999 19.4004 18.6499 36.0604 18.6499 56.6004C18.6499 77.1404 35.3099 93.8004 55.8499 93.8004C76.3899 93.8004 93.0499 77.1404 93.0499 56.6004C93.0499 36.0604 76.3899 19.4004 55.8499 19.4004Z"
          fill="#AFBDC3"
        />
        <path
          d="M55.8499 26.5304C72.4299 26.5304 85.9199 40.0204 85.9199 56.6004C85.9199 73.1804 72.4299 86.6704 55.8499 86.6704C39.2699 86.6704 25.7799 73.1804 25.7799 56.6004C25.7799 40.0204 39.2699 26.5304 55.8499 26.5304ZM55.8499 19.4004C35.2999 19.4004 18.6499 36.0604 18.6499 56.6004C18.6499 77.1404 35.3099 93.8004 55.8499 93.8004C76.3899 93.8004 93.0499 77.1404 93.0499 56.6004C93.0499 36.0604 76.3899 19.4004 55.8499 19.4004Z"
          fill="#7B82AA"
        />
        <path
          d="M55.8499 82.8004C70.3198 82.8004 82.0499 71.0703 82.0499 56.6004C82.0499 42.1305 70.3198 30.4004 55.8499 30.4004C41.38 30.4004 29.6499 42.1305 29.6499 56.6004C29.6499 71.0703 41.38 82.8004 55.8499 82.8004Z"
          fill="#52536C"
        />
        <path
          d="M71.3298 53.8094C70.1598 47.4494 65.1498 42.4294 58.8098 41.2194C57.1898 40.9094 56.0098 39.5294 56.0098 37.8794C56.0098 35.7194 57.9898 34.1394 60.1098 34.5494C69.1898 36.2894 76.3298 43.4594 78.0098 52.5594C78.3998 54.6794 76.8298 56.6394 74.6698 56.6394C73.0098 56.6394 71.6298 55.4394 71.3298 53.8094Z"
          fill="#92BAF7"
        />
        <path
          d="M118.37 109L89.2698 79.9004C86.6398 83.6604 83.3899 86.9604 79.6599 89.6504L109.24 119.23C110.51 120.5 112.19 121.14 113.86 121.14C115.53 121.14 117.2 120.5 118.48 119.23C118.64 119.07 118.75 118.9 118.89 118.73C118.54 119.4 118.08 120.03 117.53 120.58C116.09 122.02 114.19 122.81 112.16 122.81C110.13 122.81 108.22 122.02 106.79 120.59L77.3898 91.1904C77.0398 91.4104 76.6898 91.6204 76.3398 91.8204L105.95 121.43C107.61 123.09 109.82 124 112.16 124C114.5 124 116.71 123.09 118.37 121.43C120.03 119.77 120.94 117.57 120.94 115.22C120.94 112.87 120.02 110.67 118.37 109.01V109Z"
          fill="#7B82AA"
        />
        <path
          d="M10.2199 24.7C8.41987 24.64 7.02987 22.97 7.43987 21.22C7.43987 21.19 7.44987 21.17 7.45987 21.14C7.69987 20.14 8.17987 19.25 8.86987 18.45C9.56987 17.66 10.5199 16.91 11.7299 16.21C12.9199 15.51 13.8299 14.89 14.4799 14.35C15.1299 13.81 15.5799 13.26 15.8299 12.7C16.0799 12.14 16.2099 11.49 16.2099 10.73V10.68C16.2099 9.96 16.0299 9.34 15.6699 8.82C15.3099 8.3 14.8199 7.88 14.1899 7.58C13.5599 7.28 12.8399 7.12 12.0199 7.12C11.1999 7.12 10.4399 7.3 9.80987 7.66C9.17987 8.02 8.66987 8.5 8.29987 9.11C7.82987 10.1 6.99987 10.88 5.96987 11.26C4.36987 11.85 2.91987 11.6 2.91987 11.6C0.309867 11.46 -0.780133 8.13 0.599867 5.74C0.679867 5.6 0.759867 5.47 0.839867 5.34C1.90987 3.66 3.44987 2.35 5.43987 1.41C7.42987 0.47 9.81987 0 12.6199 0C15.4199 0 17.5799 0.43 19.5499 1.3C21.5199 2.16 23.0399 3.38 24.1399 4.94C25.2299 6.5 25.7799 8.34 25.7799 10.45V10.5C25.7799 11.77 25.5799 12.94 25.1799 13.98C24.7799 15.03 24.1599 15.98 23.3199 16.84C22.4799 17.7 21.4199 18.5 20.1199 19.25C18.9299 19.93 18.0099 20.55 17.3399 21.11C16.6699 21.67 16.2099 22.24 15.9499 22.83C15.9499 22.83 15.9499 22.84 15.9499 22.85C15.4299 24.02 14.3699 24.85 13.0899 24.8L10.2299 24.7H10.2199ZM8.08987 37.12C7.12987 36.21 6.64987 35.09 6.64987 33.77C6.64987 32.45 7.12987 31.35 8.08987 30.44C9.04987 29.53 10.2199 29.08 11.5999 29.08C12.9799 29.08 14.1499 29.53 15.1199 30.44C16.0899 31.35 16.5699 32.46 16.5699 33.77C16.5699 35.08 16.0899 36.22 15.1199 37.12C14.1499 38.03 12.9799 38.48 11.5999 38.48C10.2199 38.48 9.04987 38.03 8.08987 37.12Z"
          fill="#92BAF7"
        />
      </g>
      <defs>
        <clipPath id="clip0_9700_69933">
          <rect width="120.94" height="124" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
