import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";

// ACTION TYPES
const SET_IS_DRAGGING = "app/DragListener/SET_IS_DRAGGING";
const actionTypes = { SET_IS_DRAGGING };

// ACTION GENERATORS
const setIsDragging = (payload) => {
  return {
    type: SET_IS_DRAGGING,
    payload,
  };
};

const actionGenerators = { setIsDragging };

// REDUCER
const isDragging = (state = false, action) => {
  switch (action.type) {
    case SET_IS_DRAGGING:
      return action.payload;
    default:
      return state;
  }
};
const reducer = combineReducers({ isDragging });

// SELECTORS
const selectDragListenerState = (state) => {
  return state.get("dragListenerContainer");
};
const selectIsDragging = createSelector(
  selectDragListenerState,
  (dragListenerState) => {
    return dragListenerState ? dragListenerState.get("isDragging") : false;
  },
);
const selectors = { selectIsDragging };

export { actionTypes, actionGenerators, reducer, selectors };
