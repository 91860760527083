import { TextUsColor } from "./types";

export const purple: Omit<
  TextUsColor,
  "A8" | "A12" | "A16" | "A30" | "A50" | "A80"
> = {
  50: "#F3E5F5",
  100: "#E1BEE7",
  200: "#CE93D8",
  300: "#BB68C8",
  400: "#AC47BC",
  500: "#B59AB9",
  600: "#98769D",
  700: "#835E88",
  800: "#704B75",
  900: "#66456E",
};
