const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();

const phoneRegex = /[+0-9. \-()]{3,}/gm; // only include regular spaces not "\s" === [\r\n\t\f\v ]

export default text => {
  const countryCode = window.countryCode || "US";
  const matches = text.match(phoneRegex);
  if (matches) {
    return matches
      .map(number => {
        const strippedNumber = number.replace(/[^0-9]/g, "");
        if (strippedNumber.length > 15) {
          return strippedNumber.substring(0, strippedNumber.length / 2).trim();
        }
        return number.trim();
      })
      .filter(number => /\d{3,}/.test(number)) // google-libphonenumber is only capable of parsing text with 3 or more numbers
      .filter(number => {
        try {
          return phoneUtil.isValidNumber(phoneUtil.parse(number, countryCode));
        } catch (error) {
          return false;
        }
      });
  }
  return [];
};
