import queryString from "query-string";
import difference from "lodash/difference";

const getOptimisticallyUpdatedConversationCollection = (action) => {
  return (conversationIds) => {
    return (activeConversationFilter) => {
      const { conversations, slug } = activeConversationFilter;
      const { unread: unreadParam } = queryString.parse(
        conversations.id.split("?")[1],
      );
      const collectionWithoutConversations = {
        id: conversations.id,
        members: difference(conversations.members, conversationIds),
        totalItems: conversations.totalItems - conversationIds.length,
      };
      const uneditedCollection = { id: conversations.id };
      switch (action) {
        case "close":
          return slug === "closed"
            ? uneditedCollection
            : collectionWithoutConversations;
        case "reopen":
          return slug === "closed"
            ? collectionWithoutConversations
            : uneditedCollection;
        case "unstar":
          return slug === "starred"
            ? collectionWithoutConversations
            : uneditedCollection;
        case "read": {
          return unreadParam
            ? collectionWithoutConversations
            : uneditedCollection;
        }
        default:
          return uneditedCollection;
      }
    };
  };
};

export default getOptimisticallyUpdatedConversationCollection;
