/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Typography,
  DialogContent,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { DeleteStepDialogProps } from "./types";
import { DialogHeader } from "components/DialogHeader";
import { DialogFooter } from "components/DialogFooter";
import { DialogWrapper } from "components/DialogWrapper";

export function DeleteStepDialog({ close }: DeleteStepDialogProps) {
  // ==== FIELDS ==== //
  const prefKey = "HIDE_DELETE_DIALOG_PREFERENCE";

  // ==== HOOKS ==== //
  const [userPreference, setUserPreference] = useState<boolean>(false);

  useEffect(() => {
    const preference = localStorage.getItem(prefKey);
    const hideDialog = preference ? JSON.parse(preference) : false;

    if (hideDialog) {
      close(true)();
    }
  }, []);

  // ==== METHODS ==== //
  const updatePreference = (): void => {
    localStorage.setItem(prefKey, JSON.stringify(userPreference));
  };

  // ==== RENDER ==== //
  return (
    <DialogWrapper>
      <DialogHeader
        title="Delete step?"
        onClose={() => {
          close(false)();
        }}
      />

      <DialogContent sx={{ padding: "0.5rem 1rem" }}>
        <Box
          display="flex"
          flexDirection="column"
          gap="1.25rem"
          justifyContent="space-between"
        >
          <Typography>
            Pending contacts in this step will automatically move to the next
            step.
          </Typography>
          <Typography>
            Contacts that responded and exited the sequence at this point
            (flagged as Finished, responded) will have their 'Current step'
            indicator display 'Deleted' on the sequence's contacts page.
          </Typography>
          <Typography variant="body1" fontWeight={700}>
            Are you sure you want to continue? This action cannot be undone.
          </Typography>
        </Box>
      </DialogContent>

      <DialogContent
        aria-label="Preference Message"
        color="text.primary"
        sx={{
          padding: "0.5rem 1rem",
          background: (theme) => {
            return theme.palette.background.default;
          },
          borderTop: "1px solid #0000001F",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              onChange={(event) => {
                return setUserPreference(event.target.checked);
              }}
            />
          }
          label="Do not ask me again about deleting a step"
        />
      </DialogContent>

      <DialogFooter
        cancelText="Cancel"
        confirmText="Delete"
        confirmColor="error"
        confirmVariant="text"
        onCancel={() => {
          close(false)();
        }}
        onConfirm={() => {
          // If user deletes, update dialog show preference.
          updatePreference();
          close(true)();
        }}
      />
    </DialogWrapper>
  );
}
