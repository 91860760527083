import { merge } from "lodash";
import { userManager } from "../../auth";
import { Interceptor } from "../types";

/**
 * Add authorization headers to a request.
 * @param url The URL to request.
 * @param options The options for the request.
 * @returns The URL and options for the request with authorization headers.
 * @throws An error if the user is not logged in.
 */
export const addAuthorization: Interceptor = async (url, options) => {
  const user = await userManager.getUser();

  if (!user?.access_token) {
    throw new Error("User is not logged in");
  }

  return [
    url,
    merge(
      {
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      },
      options,
    ),
  ];
};
