import CalendarThumbnail from "./images/thumbnail-calendar.svg";
import PDFThumbnail from "./images/thumbnail-pdf.svg";
import TextThumbnail from "./images/thumbnail-text.svg";
import VCFThumbnail from "./images/thumbnail-vcard.svg";

export const thumbnailMap = Object.freeze({
  "application/pdf": PDFThumbnail,
  "application/rtf": TextThumbnail,
  "text/rtf": TextThumbnail,
  "text/calendar": CalendarThumbnail,
  "text/vcard": VCFThumbnail,
  "text/x-vcard": VCFThumbnail,
});

/**
 * Returns the thumbnail for a given file type
 */
export default (attachment) => {
  const { file, contentType, url } = attachment;

  if (thumbnailMap[contentType]) {
    return thumbnailMap[contentType];
  }

  return file ?? url;
};
