import { SyntheticEvent, useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { DeliveryState, Reason } from "../../SequencesContactsTab/types";
import { sortStringArray } from "utils/sortStringArray";

const useSequencesContactFilters = () => {
  const [checkedSteps, setCheckedSteps] = useState<string[]>([]);
  const [checkedEnrollers, setCheckedEnrollers] = useState<string[]>([]);
  const [checkedStepsDisplayString, setCheckedStepsDisplayString] = useState<
    string[]
  >([]);
  const [checkedEnrollersDisplayString, setCheckedEnrollersDisplayString] =
    useState<string[]>([]);
  const [sequenceState, setSequenceState] = useState<DeliveryState>("");
  const [reason, setReason] = useState<Reason>("");
  const [respondedTrue, setRespondedTrue] = useState<"1" | "">("");
  const [respondedFalse, setRespondedFalse] = useState<"1" | "">("");

  const handleCheckStep = (
    event: SelectChangeEvent<typeof checkedStepsDisplayString>,
  ) => {
    const {
      target: { value },
    } = event;

    setCheckedStepsDisplayString(
      typeof value === "string" ? value.split(",") : sortStringArray(value),
    );

    const stepNumbersOnly =
      typeof value === "string"
        ? value.replace("Step #", "")
        : value.map((step) => {
            return step.replace("Step #", "");
          });

    setCheckedSteps(
      typeof stepNumbersOnly === "string" ? [stepNumbersOnly] : stepNumbersOnly,
    );
  };

  const handleCheckEnroller = (
    event: SelectChangeEvent<typeof checkedEnrollersDisplayString>,
  ) => {
    const {
      target: { value },
    } = event;

    setCheckedEnrollersDisplayString(
      typeof value === "string" ? value.split(",") : sortStringArray(value),
    );

    setCheckedEnrollers(typeof value === "string" ? [value] : value);
  };

  const stripInnerText = (innerText: string): DeliveryState => {
    const strippedText = innerText
      .replace(/ \(.*\)/, "")
      .trim()
      .toLowerCase();

    return strippedText === "all contacts"
      ? ""
      : (strippedText as DeliveryState);
  };

  const handleFilterClick = (
    event: SyntheticEvent<HTMLElement, MouseEvent>,
  ) => {
    const { innerText } = event.target as HTMLElement;
    const state = stripInnerText(innerText);
    setSequenceState(state);
    setRespondedTrue("");
    setRespondedFalse("");
    setReason("");
  };

  const handleResponseClick = (
    event: SyntheticEvent<HTMLElement, MouseEvent>,
  ) => {
    const { innerText } = event.target as HTMLElement;
    const responseState = stripInnerText(innerText);

    if (responseState === "responded") {
      setRespondedTrue("1");
      setRespondedFalse("");
    } else {
      setRespondedFalse("1");
      setRespondedTrue("");
    }

    setSequenceState("finished");
    setReason("");
  };

  const handleStoppedClick = () => {
    setReason("stopped");
    setRespondedTrue("");
    setRespondedFalse("");
  };

  return {
    checkedSteps,
    checkedEnrollers,
    checkedStepsDisplayString,
    checkedEnrollersDisplayString,
    sequenceState,
    reason,
    respondedFalse,
    respondedTrue,
    handleCheckStep,
    handleCheckEnroller,
    handleFilterClick,
    handleResponseClick,
    handleStoppedClick,
  };
};

export { useSequencesContactFilters };
