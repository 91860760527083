import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import { startOfMonth } from "date-fns";

import { getFormattedMonthAndYear } from "../utils/FormatMonthAndYear";
import { getFirstDayOfMonth } from "../utils/GetFirstDayOfMonth";
import { getLastDayOfMonth } from "../utils/GetLastDayOfMonth";

import { GetQueryStringParams, ReportDates } from "../models/AnalyticsModels";
import { useMountEffect } from "hooks/useMountEffect";
import { formatYearMonthDayUtc } from "utils/date";

const useBillingFilter = () => {
  const history = useHistory();
  const { search } = useLocation();

  const { startDate, endDate } = qs.parse(search);

  const [selectedMonth, setSelectedMonth] = useState(
    getFormattedMonthAndYear(),
  );

  const [reportDates, setReportDates] = useState<ReportDates>({
    reportStartDate: formatYearMonthDayUtc(startOfMonth(new Date())),
    reportEndDate: formatYearMonthDayUtc(new Date()),
  });

  const { reportStartDate, reportEndDate } = reportDates;

  useEffect(() => {
    if (typeof startDate === "string" && typeof endDate === "string") {
      setReportDates({ reportEndDate: endDate, reportStartDate: startDate });
    }
  }, [endDate, startDate]);

  // creates new query string when query is changed
  const getQueryString = ({
    reportEndDate: reportEndDateParams = reportEndDate,
    reportStartDate: reportStartDateParams = reportStartDate,
    slug,
  }: GetQueryStringParams): string => {
    return qs.stringify({
      account: slug ? `/accounts/${slug}` : null,
      endDate: formatYearMonthDayUtc(new Date(reportEndDateParams)),
      startDate: formatYearMonthDayUtc(new Date(reportStartDateParams)),
    });
  };

  const updateQuery = (updatedQueryParams: ReportDates): void => {
    if (history) {
      history.push({
        search: getQueryString(updatedQueryParams),
      });
    }
  };

  // forces the current month to render on initial load, even if queryparams are already set
  useMountEffect(() => {
    updateQuery({
      reportStartDate: getFirstDayOfMonth().toISOString(),
      reportEndDate: getLastDayOfMonth().toISOString(),
    });
  });

  const monthOptions = Array.from({ length: 12 })
    .fill("")
    .map((_, index) => {
      return {
        value: getFormattedMonthAndYear(index),
        startDate: getFirstDayOfMonth(index),
        endDate: getLastDayOfMonth(index),
      };
    });

  const handleSelectMonth = (
    selectedPeriodEvent: ChangeEvent<HTMLInputElement>,
  ): void => {
    const monthAndYear = selectedPeriodEvent.target.value;
    const { startDate: newStartDate, endDate: newEndDate } =
      monthOptions.find(({ value }) => {
        return monthAndYear === value;
      }) ?? {};
    if (newStartDate && newEndDate) {
      updateQuery({
        reportStartDate: newStartDate.toISOString(),
        reportEndDate: newEndDate.toISOString(),
      });
    }
    setSelectedMonth(monthAndYear);
  };

  return {
    getFormattedMonthAndYear,
    monthOptions,
    handleSelectMonth,
    selectedMonth,
    reportDates,
  };
};

export { useBillingFilter };
