import { useSelector } from "react-redux";
import { utcToZonedTime } from "date-fns-tz";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import { getTimeZoneAbbreviation } from "utils/date";

export const useTimeZones = () => {
  const { settings } = useSelector((store) => {
    return selectCurrentAccount(store);
  });

  const ABSOLUTE_TIME_FORMAT = "dd LLL yyyy";
  const TIMESTAMP = "h:mm a zzz";

  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const accountTimeZone: string = settings.timeZone?.value ?? browserTimeZone;

  const abbreviatedAccountTimeZone = getTimeZoneAbbreviation(accountTimeZone);
  const abbreviatedBrowserTimeZone = getTimeZoneAbbreviation(browserTimeZone);

  const currentTime = utcToZonedTime(new Date(), accountTimeZone);

  return {
    abbreviatedAccountTimeZone,
    abbreviatedBrowserTimeZone,
    accountTimeZone,
    browserTimeZone,
    currentTime,
    ABSOLUTE_TIME_FORMAT,
    TIMESTAMP,
    getTimeZoneAbbreviation,
  };
};
