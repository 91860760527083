import { Account, identifyAccount } from "@tesseract/core";
import { IntroMessagePosition } from "../components/IntroAndAppended/types";
import { textUsApiV3 } from "services";

function patchSettings(
  account: Account.Like,
  settings: Partial<{
    automatedSenderId: {
      disabled?: boolean;
      value?: string | number | null;
      locked?: boolean;
    };
    appendedMessageDefaults: {
      value: {
        message: string;
        frequency: number;
      };
    };
    introMessages: {
      value: {
        messageEnabled: boolean;
        position: IntroMessagePosition;
        frequency: number | null;
        message: string;
      };
      locked?: boolean;
    };
    conversationAppendedMessages: {
      value: {
        messageEnabled: boolean;
        appendOnEvery: boolean;
        customMessageEnabled: boolean;
        customMessage: string;
      };
      locked?: boolean;
    };
    campaignAppendedMessages: {
      value: {
        messageEnabled: boolean;
        appendOnEvery: boolean;
        customMessageEnabled: boolean;
        customMessage: string;
      };
      locked?: boolean;
    };
    importRules: {
      value: unknown;
      locked?: boolean;
    };
  }>,
  options?: RequestInit,
) {
  return textUsApiV3.patch(
    `/accounts/${identifyAccount(account)}`,
    {
      settings,
    },
    options,
  );
}

export { patchSettings };
