import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import HelpOutline from "@mui/icons-material/HelpOutline";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AnalyticsCardProps } from "../models/AnalyticsModels";

function AnalyticsCard({
  analytic = 0,
  analyticTitle,
  displayWarning,
  percent,
  subText,
  tooltipText,
  grid = {
    xs: 12,
    sm: 6,
    md: 4,
    lg: 2,
  },
  "data-testid": dataTestId,
}: AnalyticsCardProps) {
  const renderCardTitle = () => {
    /* 
    this function here renders the tooltip that is necessary for the CumulativeBillingAnalytics use case of this component. 
    All other use cases, we will just render the title (2nd half of ternary) 
    */
    return tooltipText ? (
      <Box display="flex" justifyContent="center" alignContent="center">
        <Tooltip title={tooltipText} placement="top">
          <Box display="flex">
            <Typography
              align="center"
              color="textSecondary"
              gutterBottom
              noWrap
              variant="subtitle2"
            >
              {analyticTitle}
            </Typography>
            <IconButton
              sx={{
                paddingTop: 0,
                paddingLeft: "4px",
                "&:hover": {
                  background: "none",
                  cursor: "default",
                },
              }}
            >
              <InfoOutlinedIcon
                sx={{
                  height: "20px",
                  width: "20px",
                }}
              />
            </IconButton>
          </Box>
        </Tooltip>
      </Box>
    ) : (
      <Typography
        align="center"
        color="textSecondary"
        variant="subtitle2"
        gutterBottom
        noWrap
      >
        {analyticTitle}
      </Typography>
    );
  };

  return (
    <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg}>
      <Card aria-label={`${analyticTitle} Analytic Card`}>
        <CardContent
          sx={{
            "&:last-child": {
              paddingBottom: "8px",
            },
          }}
        >
          {renderCardTitle()}
          <Typography variant="h4" align="center" color="primary" noWrap>
            {percent
              ? `${Math.floor(Number(analytic) * 100)}%`
              : analytic.toLocaleString()}
          </Typography>
          {subText && (
            <Box display="flex" justifyContent="center">
              <Typography
                align="center"
                color={displayWarning ? "error" : "textSecondary"}
                display="block"
                fontWeight={displayWarning ? 700 : 400}
                variant="caption"
                data-testid={`${dataTestId}-subtext`}
              >
                {subText.toLocaleString()}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
}

export default AnalyticsCard;
