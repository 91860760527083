import type { AutocompleteProps } from "@mui/material";
import { User } from "@tesseract/core";
import { UsersQuery } from "../api/fetchUsers";

export type UsersAutocompleteOption = User.Raw | "";

export type AutocompleteConfig = {
  option: UsersAutocompleteOption;
  multiple: false;
  disableClearable: false;
  freeSolo: false;
};

type UserAutocompleteProps<
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
> = AutocompleteProps<
  AutocompleteConfig["option"],
  Multiple,
  DisableClearable,
  AutocompleteConfig["freeSolo"]
>;

export type UsersAutocompleteProps<
  Multiple extends boolean | undefined = AutocompleteConfig["multiple"],
  DisableClearable extends
    | boolean
    | undefined = AutocompleteConfig["disableClearable"],
> = {
  renderInput?: UserAutocompleteProps<
    Multiple,
    DisableClearable
  >["renderInput"];
  label: string;
  usersQuery?: UsersQuery;
  required?: boolean;
  loading?: boolean;
} & Omit<
  UserAutocompleteProps<Multiple, DisableClearable>,
  | "disableClearable"
  | "loading"
  | "onClose"
  | "onOpen"
  | "open"
  | "options"
  | "renderInput"
  | "setSelectedCandidate"
>;
