import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Tile from "./Tile";
import Avatar from "components/Avatar";
import MuiInlineBadge from "components/MuiInlineBadge";

const Root = styled(Tile)`
  padding-top: 20px;
`;

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 0 15px;
`;

const AccountItems = styled.ul`
  display: flex;
  flex-flow: column nowrap;
  list-style: none;
  margin: 10px 0;
  padding: 0;
`;

const AccountItem = styled.li`
  margin: 8px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  text-align: left;
  padding: 7px 15px;
  text-decoration: none;
  border-radius: ${(props) => {
    return props.theme.defaults.bubbleBorderRadius;
  }};

  &:hover {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
  }
`;

const AccountInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 0 20px;
`;

const AccountTitle = styled.span`
  font-weight: 700;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
`;

const AccountPhoneNumber = styled.span`
  font-weight: 400;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: 12px;
`;

export default class MessagingAccountsTile extends Component {
  static propTypes = {
    accountNavbarElements: PropTypes.array.isRequired,
    setCurrentAccount: PropTypes.func.isRequired,
  };

  handleAccountLinkClick = (account) => {
    this.props.setCurrentAccount(account);
  };

  renderAccountItems = () => {
    const { accountNavbarElements, setCurrentAccount } = this.props;
    return accountNavbarElements
      .sort((a, b) => {
        const aName = a.title.toLowerCase();
        const bName = b.title.toLowerCase();
        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      })
      .filter((a) => {
        return a.displayPhoneNumber !== "Non-messaging admin account";
      })
      .map((element) => {
        return (
          <AccountItem
            key={element.id}
            onClick={() => {
              return setCurrentAccount(element.account);
            }}
          >
            <Avatar subject={element.title} />
            <AccountInfo>
              <AccountTitle>{element.title}</AccountTitle>
              <AccountPhoneNumber>
                {element.displayPhoneNumber}
              </AccountPhoneNumber>
            </AccountInfo>
            <MuiInlineBadge
              badgeContent={element.badge}
              color="secondary"
              scale={1.2}
            />
          </AccountItem>
        );
      });
  };

  render() {
    return (
      <Root
        dataTag="MessagingAccounts-Root"
        title="Your Messaging Accounts"
        {...this.props}
      >
        <Container>
          <AccountItems>{this.renderAccountItems()}</AccountItems>
        </Container>
      </Root>
    );
  }
}
