import assert from "assert";
import { Account, userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";

export const fetchSubscriptions = async (
  currentAccount: Account.Raw,
): Promise<Response> => {
  const user = await userManager.getUser();
  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/subscriptions`;

  const request = new Request(url, {
    method: "GET",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
  });

  return fetch(request);
};
