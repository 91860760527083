import { useState } from "react";

export const useRefreshTimer = () => {
  const [lastRefreshTimestamp, setLastRefreshTimestamp] = useState<Date>(
    new Date(),
  );

  const updateRefreshTimestamp = () => {
    setLastRefreshTimestamp(new Date());
  };

  return {
    lastRefreshTimestamp,

    updateRefreshTimestamp,
  };
};
