import { ChangeEvent, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";

import { format, sub } from "date-fns";
import { dateOptions } from "../constants/DateOptions";
import {
  AccountOption,
  GetQueryStringParams,
  ReportDates,
  UseFiltersProps,
} from "../models/AnalyticsModels";
import { formatYearMonthDayUtc } from "utils/date";

const useFilters = ({ currentUser }: UseFiltersProps) => {
  const history = useHistory();
  const { search } = useLocation();

  const accountList = [
    {
      descendants: false,
      id: "current account",
      name: "On this account",
      slug: "currentAccount",
    },
    {
      descendants: true,
      id: "descendants",
      name: "On this account and below",
      slug: "currentAccountAndDescendants",
    },
  ];

  const { descendants, startDate, endDate } = qs.parse(search);

  const [reportDates, setReportDates] = useState<ReportDates>({
    reportStartDate: format(sub(new Date(), { weeks: 1 }), "yyyy-MM-dd"),
    reportEndDate: format(new Date(), "yyyy-MM-dd"),
    reportDescendants: "false",
  });

  useEffect(() => {
    if (
      typeof startDate === "string" &&
      typeof endDate === "string" &&
      typeof descendants === "string"
    ) {
      setReportDates({
        reportEndDate: endDate,
        reportStartDate: startDate,
        reportDescendants: descendants,
      });
    }
  }, [endDate, startDate, descendants]);

  const { reportDescendants, reportStartDate, reportEndDate } = reportDates;

  const selectedAccount: AccountOption =
    accountList.find(
      ({ descendants: descendantsParam }: { descendants: boolean }) => {
        const descendantsEnabled = descendants === "true";
        return descendantsEnabled === descendantsParam;
      },
    ) ?? accountList[0];

  const [account, setAccount] = useState<AccountOption>(selectedAccount);

  // creates new query string when query is changed
  const getQueryString = ({
    reportDescendants: descendantsParam = "false",
    reportEndDate: reportEndDateParams = reportEndDate,
    reportStartDate: reportStartDateParams = reportStartDate,
    slug,
  }: GetQueryStringParams): string => {
    return qs.stringify({
      ...(slug ? { account: `/accounts/${slug}` } : {}),
      descendants: descendantsParam,
      endDate: formatYearMonthDayUtc(new Date(reportEndDateParams)),
      startDate: formatYearMonthDayUtc(new Date(reportStartDateParams)),
    });
  };

  const [timePeriod, setTimePeriod] = useState(() => {
    if (startDate || endDate) {
      return "custom";
    }
    return "week";
  });

  const showAnalyticsExportButton =
    !currentUser?.featureFlags?.hideAnalyticsExport;

  const defaultQueryParams: string = getQueryString({
    reportEndDate,
    reportStartDate,
  });

  const updateQuery = (updatedQueryParams: ReportDates): void => {
    if (history) {
      history.push({
        search: getQueryString(updatedQueryParams),
      });
    }
  };

  const handleSelectTimePeriod = (
    selectedPeriodEvent: ChangeEvent<HTMLInputElement>,
  ): void => {
    const timePeriodString = selectedPeriodEvent.target.value;
    setTimePeriod(timePeriodString);
    const { startDate: newStartDate, endDate: newEndDate } =
      dateOptions.find(({ value }) => {
        return timePeriodString === value;
      }) ?? {};
    if (newStartDate && newEndDate) {
      updateQuery({
        reportStartDate: newStartDate.toISOString(),
        reportEndDate: newEndDate.toISOString(),
        reportDescendants,
      });
    }
  };

  const handleUpdateAccount = ({
    value: accountId,
  }: {
    value: string;
  }): void => {
    const accountSelection = accountList.find(({ id }) => {
      return id === accountId;
    });
    if (accountSelection) {
      const { descendants: descendantsParam } = accountSelection;
      setAccount(accountSelection);
      updateQuery({
        reportStartDate,
        reportEndDate,
        reportDescendants: descendantsParam.toString(),
      });
    }
  };

  const handleUpdateEndDate = (newEndDate: Date | null) => {
    if (newEndDate instanceof Date) {
      return updateQuery({
        reportEndDate: newEndDate.toISOString(),
        reportStartDate,
        reportDescendants,
      });
    }
    return null;
  };

  const handleUpdateStartDate = (newStartDate: Date | null) => {
    if (newStartDate instanceof Date) {
      return updateQuery({
        reportEndDate,
        reportStartDate: newStartDate.toISOString(),
        reportDescendants,
      });
    }
    return null;
  };

  return {
    account,
    accountList,
    defaultQueryParams,
    getQueryString,
    handleSelectTimePeriod,
    handleUpdateAccount,
    handleUpdateEndDate,
    handleUpdateStartDate,
    reportDates,
    setAccount,
    setReportDates,
    setTimePeriod,
    showAnalyticsExportButton,
    timePeriod,
    updateQuery,
  };
};

export { useFilters };
