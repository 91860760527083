import { Box, Typography, useTheme } from "@mui/material";
import { NoMembersProps } from "./NoMembersProps";
import EmptyFolderIcon from "icons/EmptyFolderIcon";

function NoMembersScreen({ source }: NoMembersProps) {
  const textOptions: { [key: string]: string } = {
    groups: "No groups created yet.",
  };

  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding="1.5rem 1rem"
      height="100%"
      width="100%"
      sx={{ minHeight: "210px" }}
    >
      <EmptyFolderIcon mode={theme.palette.mode} />
      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        pt="1rem"
      >
        {textOptions[source]}
      </Typography>
    </Box>
  );
}

export { NoMembersScreen };
