import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { selectCurrentAccount } from "../../EntryPoint/containers/App/selectors";
import getOrCreateContacts from "../utils/getOrCreateContact";
import getOrCreateConversationId from "../utils/getOrCreateConversationId";
import { getConversationByPhoneNumberApi } from "../api/getConversationByPhoneNumberApi";

const useClickToText = () => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const currentAccount = useSelector(selectCurrentAccount);

  const gotoConversation = async ({
    phoneNumber,
    name,
  }: {
    phoneNumber: string;
    name: string;
  }) => {
    const contacts = await getOrCreateContacts({
      phoneNumber,
      name,
      accountSlug: currentAccount.slug,
    }).catch((error) => {
      console.log("Failed to import contact", error);

      snackbar?.enqueueSnackbar("Failed to import contact", {
        variant: "error",
      });
    });

    // Return to inbox if there were no contacts found and we failed to import the contact.
    if (!contacts || contacts.length === 0) {
      history.push(`/${currentAccount.slug}`);
      return;
    }

    // If there are multiple contacts, show the list of contacts to allow
    // the end user to assign a contact to the conversation.
    if (contacts.length > 1) {
      const { assignedContact, id } = await getConversationByPhoneNumberApi({
        phoneNumber,
        accountSlug: currentAccount.slug,
      });

      const conversationId = id.replace(/^\/conversations\//, "");

      // Update `isAssigned` prop for showing the assigned badge.
      if (assignedContact) {
        const contactIndex = contacts.findIndex((cont) => {
          return cont.id === assignedContact.replace(/^\/contacts\//, "");
        });


        if (contactIndex > -1) {
          contacts[contactIndex].isAssigned = true;
        }
      }

      // Redirect to contacts match page.
      history.push("contact_match_list", { contacts, conversationId });
      return;
    }

    // If there was a single contact, get or create the conversation.
    const contactId = () => {
      const contactSelected = contacts.find((contact) => {
        return contact.isOnPage;
      });
      if (contactSelected) {
        return contactSelected.id;
      }
      return contacts[0].id;
    };

    // Get or create conversation for that contact.
    const conversationId = await getOrCreateConversationId({
      contactId: contactId(),
      phoneNumber: contacts[0].phoneNumber,
      accountSlug: currentAccount.slug,
    }).catch((error) => {
      console.log("Failed to import contact", error);

      snackbar?.enqueueSnackbar("Failed to import contact", {
        variant: "error",
      });

      history.push(`/${currentAccount.slug}`);
    });

    // Go to that conversation.
    if (conversationId) {
      history.push(`/${currentAccount.slug}/inbox/open/${conversationId}`);
    }
  };

  return { gotoConversation };
};

export { useClickToText };
