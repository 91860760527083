import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import H5 from "components/H5";

const Root = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;

  ${H5} {
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
  }
`;

const Number = styled(H5)``;

const NumberType = styled(H5)``;

const LeadingDots = styled.div`
  flex: 1 1 100%;
  border-bottom: dashed 1px
    ${(props) => {
      return props.theme.colors.divider;
    }};
  margin: 0 15px 5px 15px;
  height: 1px;
`;

export default class DirectoryPhone extends Component {
  static propTypes = {
    number: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  };

  render() {
    const { number, type } = this.props;
    return (
      <Root>
        <Number>{number}</Number>
        <LeadingDots />
        <NumberType>{type}</NumberType>
      </Root>
    );
  }
}
