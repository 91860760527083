import styled from "styled-components";

import Icon from "components/Icon";
import ICONS from "constants/icons";

const Root = styled.div`
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => {
    return props.theme.colors.disabledOverlay;
  }};
  border-radius: 4px;
`;

const LockIcon = styled(Icon)``;

const LockIconWrapper = styled.div`
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  position: absolute;
  top: -15px;
  left: calc(50% - 15px);
  text-align: center;
  background: white;
  border-radius: 15px;

  svg {
    position: relative;
    top: -1px;
    left: 2px;
  }
`;

export default function LockOverlay() {
  return (
    <Root>
      <LockIconWrapper>
        <LockIcon icon={ICONS.LOCK} iconSize={16} />
      </LockIconWrapper>
    </Root>
  );
}
