import { Account, identifyAccount } from "@tesseract/core";
import { textUsApiV3 } from "services";

function fetchAccount(accountId: Account.Identity, options?: RequestInit) {
  return textUsApiV3.get(
    `/accounts/${identifyAccount(accountId)}`,
    undefined,
    options,
  );
}

export { fetchAccount };
