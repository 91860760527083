import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import isNull from "lodash/isNull";
import omit from "lodash/omit";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import UserForm from "pages/UserManagement/UserForm";

const UserModalContent = styled(ModalContent)`
  height: calc(100vh - 60px);
  min-width: 100vw;
  padding: 0;
  position: relative;

  @media (min-width: 900px) {
    min-width: 900px;
  }

  @media (min-width: 900px) and (min-height: 655px) {
    height: 600px;
  }
`;

class UserModal extends Component {
  static propTypes = {
    activeUser: PropTypes.object,
    createUserRequest: PropTypes.func.isRequired,
    fetchUserCollectionRequest: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool,
    setActiveUser: PropTypes.func.isRequired,
    userCollectionId: PropTypes.string.isRequired,
    updateUserRequest: PropTypes.func.isRequired,
  };

  handleClose = () => {
    const { setActiveUser } = this.props;
    setActiveUser(null);
  };

  getCallbacks = (actions, { fetchOnSuccess = false } = {}) => {
    const { fetchUserCollectionRequest, userCollectionId } = this.props;
    return {
      successCallback: () => {
        if (fetchOnSuccess) fetchUserCollectionRequest(userCollectionId);
        actions.setSubmitting(false);
        this.handleClose();
      },
      errorCallback: (errors) => {
        const validationErrors = get(
          errors,
          ["validationErrors"],
          "Something went wrong!",
        );
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
      },
    };
  };

  handleSubmit = (user, values, actions) => {
    const { createUserRequest, updateUserRequest, userCollectionId } =
      this.props;
    if (get(user, "id")) {
      updateUserRequest(
        get(user, "id"),
        {
          ...values,
          memberships: values.memberships.reduce((arr, membership) => {
            return [...arr, omit(membership, "id")];
          }, []),
        },
        this.getCallbacks(actions),
      );
    } else {
      createUserRequest(
        userCollectionId,
        values,
        this.getCallbacks(actions, { fetchOnSuccess: true }),
      );
    }
  };

  getHeaderText = () => {
    const { activeUser } = this.props;
    if (isNull(activeUser)) return "User";
    if (isEmpty(activeUser)) return "Create New User";
    return "Edit User";
  };

  render() {
    const { activeUser = {}, fullScreen } = this.props;
    return (
      <Modal
        transitionIn={Boolean(activeUser)}
        closeModal={this.handleClose}
        fullScreen={fullScreen}
      >
        <ModalHeader closeModal={this.handleClose}>
          {this.getHeaderText()}
        </ModalHeader>
        <UserModalContent>
          <UserForm
            user={activeUser}
            handleFormSubmit={this.handleSubmit}
            handleClose={this.handleClose}
          />
        </UserModalContent>
      </Modal>
    );
  }
}

export default withSizes(({ width }) => {
  return {
    fullScreen: width < 620,
  };
})(UserModal);
