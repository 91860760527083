import Honeybadger from "@honeybadger-io/js";
import MultiContactParser from "phoneExtraction/MultiContactParser";

export default class DataAttributeStrategy {
  constructor({ activeFrameParsedHtml, parsedHtml }) {
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.parsedHtml = parsedHtml;
  }

  parse = () => {
    try {
      const doc = this.activeFrameParsedHtml || this.parsedHtml;
      const contactNodes = doc.querySelectorAll("[data-textus-contact]");
      if (contactNodes.length > 0) {
        const parser = new MultiContactParser({
          doc,
          namesSelector: "[data-textus-contact-name]",
          containerSelector: "[data-textus-contact]",
          canParse: () => true,
        });
        const results = parser.parse();
        return { strategy: "DataAttributeStrategy", results };
      }
      return null;
    } catch (error) {
      Honeybadger.notify(error, "DataAttributeStrategy");
      return null;
    }
  };
}
