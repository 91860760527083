import styled from "styled-components";

const SummaryListItem = styled.header`
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
  font-weight: 700;
  padding: 20px 0;
  text-align: center;
`;

export default SummaryListItem;
