import { useState } from "react";
import { useSnackbar } from "notistack";
import { unlinkKeywordFromSequences } from "features/Sequences/api/unlinkKeywordFromSequences";
import { Snackbar } from "components/Snackbar";
import { SnackbarLinkText } from "components/Snackbar/SnackbarLinkText";
import { linkKeywordToSequence } from "features/Sequences/api/linkKeywordToSequence";
import { useCurrentAccount, useLoading } from "hooks";
import { Sequence } from "models/Sequence";
import { useDialog } from "components/Dialog";
import { Link } from "features/Automation/components/types";
import { Keyword } from "features/Automation/models/AutomationModels";

const useKeywordLinking = () => {
  const { ref: confirmUnlinkRef, open: openConfirmUnlinkDialog } = useDialog<
    any,
    string[] | false
  >();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const currentAccount = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();

  const [links, setLinks] = useState<Link[]>([]);

  const linkKeyword = async (sequence: Sequence, keyword: Link["keyword"]) => {
    if (keyword.id) {
      try {
        startLoading();
        const response = await linkKeywordToSequence(
          currentAccount,
          sequence.id,
          keyword.id,
        );

        if (response.ok) {
          const body: Keyword = await response.json();

          setLinks((prevLinks) => {
            return [
              ...prevLinks,
              {
                sequence: {
                  id: body.sequences[0].id,
                  name: body.sequences[0].name,
                },
                keyword: {
                  id: body.id,
                  keyword: body.keyword,
                },
              },
            ];
          });

          enqueueSnackbar(
            `Linked ${keyword.keyword} to ${sequence.name}. 
            Contacts are enrolled after receiving the keyword auto-response.`,
            {
              content: (key, message) => {
                return (
                  <Snackbar
                    id={key}
                    message={message}
                    variant="info"
                    actionButton={
                      <SnackbarLinkText
                        to={`/${currentAccount.slug}/automation/sequences/${sequence.id}`}
                      >
                        VIEW
                      </SnackbarLinkText>
                    }
                  />
                );
              },
            },
          );
        }
      } catch (error) {
        enqueueSnackbar(
          "There was an error linking the keyword to the sequence. Please try again.",
          {
            variant: "error",
          },
        );
        throw new Error(`Error linking keyword to sequence: ${String(error)}`);
      } finally {
        stopLoading();
      }
    }
  };

  const unlinkKeyword = async (
    sequences: string[],
    keyword: Sequence["linkedKeyword"],
    refreshPage?: () => void,
  ) => {
    if (keyword.id) {
      try {
        startLoading();
        const response = await unlinkKeywordFromSequences(
          currentAccount,
          sequences,
          keyword.id,
        );

        if (response.ok) {
          setLinks((prevLinks) => {
            return prevLinks.filter((link) => {
              return !sequences.includes(link.sequence.id);
            });
          });

          const successText =
            sequences.length > 1
              ? `Unlinked from ${sequences.length} Sequences.`
              : `Unlinked ${keyword.keyword}.`;

          enqueueSnackbar(successText, {
            content: (key, message) => {
              return <Snackbar id={key} message={message} variant="info" />;
            },
          });
        }
      } catch (error) {
        enqueueSnackbar(
          "There was an error unlinking the sequence from the keyword. Please try again.",
          {
            variant: "error",
          },
        );
        throw new Error(
          `Error unlinking keyword from sequence: ${String(error)}`,
        );
      } finally {
        stopLoading();
        if (refreshPage) {
          refreshPage();
        }
      }
    }
  };

  const toggleUnlinkDialog = (keyword: Sequence["linkedKeyword"]) => {
    openConfirmUnlinkDialog({}, (values) => {
      if (values) {
        unlinkKeyword(values, keyword).catch((error) => {
          console.error(error);
        });
      }
    })();
  };

  return {
    confirmUnlinkRef,
    isLoading,
    links,
    linkKeyword,
    toggleUnlinkDialog,
    unlinkKeyword,
    setLinks,
  };
};

export { useKeywordLinking };
