import { Contact } from "@tesseract/core";
import { textUsApiV3 } from "services";

function deleteContactCollection(
  contactCollectionId: string,
  contacts?: Contact.ID[],
) {
  return textUsApiV3.destroy(contactCollectionId, { contacts });
}

export { deleteContactCollection };
