import $ from "jquery";
import getContactDataFromText from "phoneExtraction/utils/getContactDataFromText";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";

export default class SingleContactParser {
  constructor({
    canParse,
    doc,
    getNamesFunction,
    namesSelector,
    namesSelectorType = "textContent",
    searchTextFunction,
    textSelector,
    getMetadataFunction,
  }) {
    this.canParse = canParse;
    this.doc = doc;
    this.getNamesFunction = getNamesFunction;
    this.namesSelector = namesSelector;
    this.namesSelectorType = namesSelectorType;
    this.searchTextFunction = searchTextFunction;
    this.textSelector = textSelector;
    this.getMetadataFunction = getMetadataFunction;
  }

  getNames = () => {
    if (this.getNamesFunction) return this.getNamesFunction();
    if (Array.isArray(this.namesSelector)) {
      return this.namesSelector
        .slice(0, 2)
        .map(selector => $(this.doc).find(selector)[0])
        .filter(node => node)
        .map(node => node[this.namesSelectorType].trim());
    }
    return Array.from($(this.doc).find(this.namesSelector)).map(node =>
      getNamesFromText(node.textContent),
    )[0];
  };

  getSearchText = () => {
    if (this.searchTextFunction) return this.searchTextFunction();
    return Array.from($(this.doc).find(this.textSelector))
      .map(node => {
        const clonedNode = node.cloneNode(true);
        clonedNode
          .querySelectorAll(`[style*="display: none"]`)
          .forEach(n => n.remove());
        return clonedNode.textContent;
      })
      .join(", ");
  };

  parse = () => {
    if (!this.canParse()) return null;
    if (this.getMetadataFunction) return this.getMetadataFunction();
    const names = this.getNames();
    const searchText = this.getSearchText();
    if (names && searchText) {
      return getContactDataFromText(names)(searchText);
    }
    return null;
  };
}
