import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";

import BasicButton from "components/BasicButton";
import Icon from "components/Icon";
import ICONS from "constants/icons";
import H3 from "components/H3";
import List from "components/List";
import P from "components/P";

const Wrapper = styled.div`
  padding: 20px;
`;

const Contacts = styled(List)`
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
`;

const ContactButton = styled(BasicButton)`
  border-radius: ${(props) => {
    return props.theme.defaults.tileBorderRadius;
  }};
  margin-bottom: 20px;
  min-width: 48%;
  text-align: left;

  &:hover {
    background: ${(props) => {
      return props.theme.colors.background.default;
    }};
  }

  @media (max-width: 620px) {
    min-width: 100%;
  }
`;

const ContactHeader = styled.header`
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  border-radius: ${(props) => {
      return props.theme.defaults.tileBorderRadius;
    }}
    ${(props) => {
      return props.theme.defaults.tileBorderRadius;
    }}
    0 0;
  padding: 10px 15px;
`;

const ContactSection = styled.section`
  border-left: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  border-right: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  padding: 10px 15px;
`;

const NewPhoneSection = styled(ContactSection)`
  background: ${(props) => {
    return props.theme.colors.message.outboundBackground;
  }};
  border: none;
  color: ${(props) => {
    return props.theme.colors.message.outboundColor;
  }};
  border-radius: 0 0
    ${(props) => {
      return props.theme.defaults.tileBorderRadius;
    }}
    ${(props) => {
      return props.theme.defaults.tileBorderRadius;
    }};
`;

const Prompt = styled(P)`
  font-size: 14px;
  margin-bottom: 20px;
`;

const Phone = styled.li`
  align-items: center;
  display: flex;
  font-size: 14px;
  padding: 5px;
`;

const ExistingPhone = styled(Phone)``;

const NewPhone = styled(Phone)`
  svg {
    fill: ${(props) => {
      return props.theme.colors.message.outboundColor;
    }};
    margin-right: 5px;
  }
`;

export default class ResolveConflict extends Component {
  static propTypes = {
    newContactParams: PropTypes.object.isRequired,
    partialMatches: PropTypes.array.isRequired,
    updateContactRequest: PropTypes.func.isRequired,
  };

  getMissingPhones = ({ newContactParams, contact }) => {
    return newContactParams.phones.filter((phone) => {
      return !contact.phones.members.find((contactPhone) => {
        return contactPhone.phoneNumber
          .replace(/\D/g, "")
          .endsWith(phone.number.replace(/\D/g, ""));
      });
    });
  };

  getUpdatedParams = ({ newContactParams, contact }) => {
    const missingPhones = this.getMissingPhones({ newContactParams, contact });
    return {
      name: contact.name,
      data: {
        business: get(
          newContactParams,
          ["data", "business"],
          contact.data.business,
        ),
      },
      phones: [
        ...contact.phones.members.map((contactPhone) => {
          return {
            number: contactPhone.phoneNumber,
            type: contactPhone.type,
          };
        }),
        ...missingPhones.map((phone) => {
          return {
            number: phone.number,
          };
        }),
      ],
    };
  };

  handleContactSelection = (selectedContact) => {
    const { newContactParams, updateContactRequest } = this.props;
    updateContactRequest(
      selectedContact.id,
      this.getUpdatedParams({ newContactParams, contact: selectedContact }),
    );
  };

  render() {
    const { newContactParams, partialMatches } = this.props;
    const prompt =
      partialMatches.length === 1
        ? "There is already a contact with similar values. Please select the contact if you would like to update it with the new values."
        : `There were ${partialMatches.length} contacts found with similar values. Please select one to update it with the new values.`;
    return (
      <Wrapper>
        <Prompt>{prompt}</Prompt>
        <Contacts>
          {partialMatches.map((contact) => {
            return (
              <ContactButton
                key={contact.id}
                onClick={() => {
                  return this.handleContactSelection(contact);
                }}
              >
                <ContactHeader>
                  <H3>{contact.name}</H3>
                </ContactHeader>
                <ContactSection>
                  <List>
                    {contact.phones.members.map((phone) => {
                      return (
                        <ExistingPhone key={phone.id}>
                          <span>{phone.formattedPhoneNumber}</span>
                        </ExistingPhone>
                      );
                    })}
                  </List>
                </ContactSection>
                <NewPhoneSection>
                  <List>
                    {this.getMissingPhones({ newContactParams, contact }).map(
                      (phone) => {
                        return (
                          <NewPhone key={phone.number}>
                            <Icon icon={ICONS.PLUS} iconSize={15} />
                            <span>{phone.number}</span>
                          </NewPhone>
                        );
                      },
                    )}
                  </List>
                </NewPhoneSection>
              </ContactButton>
            );
          })}
        </Contacts>
      </Wrapper>
    );
  }
}
