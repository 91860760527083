import { useCallback, useEffect, useMemo, useState } from "react";
import { createPopup } from "./createPopup";

/**
 * Use popup hook
 *
 * @param channel - Channel to communication with popup
 * @param params - Params for `window.open`
 * @returns a handle to open popup and state for errors that may occur
 */
export function usePopup<T>(
  channel: string,
  popupParameters: Readonly<Parameters<typeof createPopup>>,
) {
  /**
   * Create popup context
   */
  const { openWindow } = useMemo(() => {
    return createPopup<T>(...popupParameters);
  }, [popupParameters]);

  /**
   * Popup closing callback
   */
  const [onClose, setOnClose] = useState<(() => void) | undefined>();

  /**
   * Trigger closing events
   */
  useEffect(() => {
    return () => {
      if (onClose) onClose();
    };
  }, [onClose]);

  const open = useCallback(
    ({
      submit,
      post,
    }: {
      submit?: (response: T) => void;
      post?: (...params: unknown[]) => void;
    }) => {
      const popup = openWindow(channel, post);

      if (!onClose) {
        popup
          .then((_response) => {
            submit?.(_response);
          })
          .catch((_error) => {
            setError(_error);
          });

        setOnClose(() => {
          /**
           * On close handler
           */
          return () => {
            popup.abort("aborted by user");

            setOnClose(undefined);
          };
        });
      }
    },
    [channel, onClose, openWindow],
  );

  /**
   * Error state, likely, popup was blocked
   */
  const [error, setError] = useState<
    Record<string, EventListener> | undefined
  >();

  return {
    error,
    open,
  };
}
