import Box from "@mui/material/Box";
import Link from "@mui/material/Link";

import { NavLink } from "react-router-dom";

interface LegalInformationLinksProps {
  closePopover: () => void;
}

function LegalInformationLinks({
  closePopover,
}: LegalInformationLinksProps): JSX.Element {
  return (
    <Box
      fontSize="10px"
      display="flex"
      width="100%"
      p={1}
      justifyContent="space-around"
    >
      <Link
        to="/legal/privacy-policy"
        color="textSecondary"
        onClick={closePopover}
        component={NavLink}
      >
        Privacy Policy
      </Link>
      <Link
        to="/legal/terms-of-use"
        color="textSecondary"
        onClick={closePopover}
        component={NavLink}
      >
        Terms of Use
      </Link>
      <span aria-label="TextUs Copyright">
        &copy; {new Date().getFullYear()} TextUs
      </span>
    </Box>
  );
}

export default LegalInformationLinks;
