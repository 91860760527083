import { combineReducers } from "redux-immutable";
import { takeLatest, all } from "redux-saga/effects";
import { createSelector } from "reselect";
import { OrderedSet } from "immutable";

import { selectRecords } from "features/EntryPoint/containers/App/selectors";
import { actionTypes as createContactActionTypes } from "features/Compose/containers/CreateContact/state";
import { actionTypes as composeActionTypes } from "features/Compose/containers/ComposeRoot/state";
import * as schema from "schema";
import denormalizeWithShape from "utils/denormalizeWithShape";
import generateContainerState from "utils/generateContainerState";

const {
  actionTypes: crudActionTypes,
  actionGenerators: crudActionGenerators,
  sagas,
  reducers: crudReducers,
} = generateContainerState([
  {
    container: "QuickComposer",
    crudActions: ["fetch"],
    idPropsVar: "contactPhoneCollectionId",
    recordType: "contactPhoneCollection",
    schema: schema.contactPhoneCollection,
  },
]);

// ACTION TYPES
const ADD_RECIPIENT = "app/QuickComposer/ADD_RECIPIENT";
const REMOVE_RECIPIENT = "app/QuickComposer/REMOVE_RECIPIENT";
const RESET_SEARCH_RESULTS = "app/QuickComposer/RESET_SEARCH_RESULTS";
const SET_RECIPIENTS = "app/QuickComposer/SET_RECIPIENTS";
const SET_SIGNATURE_STATE = "app/QuickComposer/SET_SIGNATURE_STATE";
const actionTypes = {
  ...crudActionTypes,
  ADD_RECIPIENT,
  REMOVE_RECIPIENT,
  SET_SIGNATURE_STATE,
};

// ACTION GENERATORS
const addRecipient = (id) => {
  return {
    type: ADD_RECIPIENT,
    id,
  };
};

const removeRecipient = (id) => {
  return {
    type: REMOVE_RECIPIENT,
    id,
  };
};

const resetSearchResults = () => {
  return {
    type: RESET_SEARCH_RESULTS,
  };
};

const setRecipients = (ids) => {
  return {
    type: SET_RECIPIENTS,
    ids,
  };
};

const setSignatureState = (payload) => {
  return {
    type: SET_SIGNATURE_STATE,
    payload,
  };
};

const actionGenerators = {
  ...crudActionGenerators,
  addRecipient,
  removeRecipient,
  resetSearchResults,
  setRecipients,
  setSignatureState,
};

// REDUCER
const activateSignature = (state = false, action) => {
  switch (action.type) {
    case ADD_RECIPIENT:
    case REMOVE_RECIPIENT:
    case SET_RECIPIENTS:
    case composeActionTypes.SET_COMPOSE:
      return false;
    case SET_SIGNATURE_STATE:
      return action.payload;
    default:
      return state;
  }
};

const recipients = (state = OrderedSet([]), action) => {
  switch (action.type) {
    case ADD_RECIPIENT:
      return state.add(action.id);
    case REMOVE_RECIPIENT:
      return state.delete(action.id);
    case SET_RECIPIENTS:
      return OrderedSet(action.ids);
    case createContactActionTypes.CREATE_CONTACT_SUCCESS:
      return state.add(action.response.phones.members[0].id);
    default:
      return state;
  }
};

const searchResults = (state = OrderedSet([]), action) => {
  switch (action.type) {
    case crudActionTypes.FETCH_CONTACT_PHONE_COLLECTION_SUCCESS:
      return OrderedSet(
        action.response.members.map((contact) => {
          return contact.id;
        }),
      );
    case RESET_SEARCH_RESULTS:
      return OrderedSet([]);
    default:
      return state;
  }
};

const reducer = combineReducers({
  ...crudReducers,
  activateSignature,
  recipients,
  searchResults,
});

// SAGAS
function* saga() {
  yield all([
    takeLatest(
      actionTypes.FETCH_CONTACT_PHONE_COLLECTION_REQUEST,
      sagas.fetchContactPhoneCollectionRequest,
    ),
  ]);
}

// SELECTORS
const selectQuickComposerState = (state) => {
  return state.get("quickComposerContainer");
};

const selectConversationPreviewContainerState = (state) => {
  return state?.get("conversationPreviewContainer");
};

const selectRecipientIds = createSelector(
  selectQuickComposerState,
  (quickComposerState) => {
    return quickComposerState.get("recipients");
  },
);

const selectRecipients = createSelector(
  selectRecipientIds,
  selectRecords,
  (recipientIds, records) => {
    return recipientIds
      .map((id) => {
        return denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: { contact: {} },
        });
      })
      .toJS();
  },
);

const selectSearchResultIds = createSelector(
  selectQuickComposerState,
  (quickComposerState) => {
    return quickComposerState.get("searchResults");
  },
);

const selectSearchResults = createSelector(
  selectSearchResultIds,
  selectRecords,
  (searchResultIds, records) => {
    return searchResultIds
      .map((id) => {
        return denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: { contact: {} },
        });
      })
      .toJS();
  },
);

const selectQuickComposer = createSelector(
  selectQuickComposerState,
  (quickComposerState) => {
    return {
      substate: quickComposerState.toJS(),
    };
  },
);

const selectConversationPreview = createSelector(
  selectConversationPreviewContainerState,
  (conversationPreviewContainerState) => {
    return conversationPreviewContainerState?.toJS();
  },
);

const selectors = {
  selectQuickComposer,
  selectRecipients,
  selectSearchResults,
  selectConversationPreview,
};
export { actionTypes, actionGenerators, reducer, selectors, saga };
