export function flattenRdfaNode(acc, node) {
  const prop = node.getAttribute("property");
  const { children, textContent } = node;

  const value =
    children.length === 0
      ? textContent
      : Array.from(children).reduce(flattenRdfaNode, {});

  if (!acc[prop]) {
    return {
      ...acc,
      [prop]: value,
    };
  }

  const existing = Array.isArray(acc[prop]) ? acc[prop] : [acc[prop]];
  return {
    ...acc,
    [prop]: [...existing, value],
  };
}
