/**
 * This file contains tokens used in the TextUs Color Palette system.
 * https://www.figma.com/file/sm14NQu6hT0RwVvF6TArfn/TextUs-Design-System?type=design&node-id=1580-4270&mode=design&t=tJsh0cOPa4Q4ah0k-0
 *
 * We can use these tokens directly in our code...
 *  Usage with MUI
 *  @example
 *  sx={{ color: "lightBlue.800;" }}
 *  sx={(theme) => ({ color: theme.palette.lightBlue[800]; })}
 *
 * Or we can use them in our theme...
 *  @example
 *  text: { primary: grey[800] }
 */

import {
  cyan,
  teal,
  lime,
  yellow,
  indigo,
  deepPurple,
  pink,
} from "@mui/material/colors";
import { amber } from "./amber";
import { blue } from "./blue";
import { blueGrey } from "./blueGrey";
import { brand } from "./brand";
import { deepOrange } from "./deepOrange";
import { grayscale } from "./grayscale";
import { green } from "./green";
import { lightBlue } from "./lightBlue";
import { lightGreen } from "./lightGreen";
import { orange } from "./orange";
import { purple } from "./purple";
import { red } from "./red";
import { TextUsColorOptions } from "./types";

const textUsColors: TextUsColorOptions = {
  amber,
  blue,
  blueGrey,
  brand,
  cyan,
  deepOrange,
  deepPurple,
  grayscale,
  green,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  orange,
  pink,
  purple,
  red,
  teal,
  yellow,
};

export { textUsColors };

export { type TextUsColor, type TextUsColorOptions } from "./types";
