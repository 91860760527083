import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Textarea from "react-textarea-autosize";
import emojiRegex from "emoji-regex";
import split from "lodash/split";

import MessageBubbleTip, {
  Root as MessageBubbleTipStyles,
} from "./MessageBubbleTip";
import MessageBody from "./MessageBody";

export const MessageTipWrapper = styled.div`
  ${(props) => {
    return props.direction === "in"
      ? "margin-right: -10px"
      : "margin-left: -10px";
  }};
  min-width: 15px;
`;

export const isEditingStyles = css`
  ${MessageBody} {
    color: ${(props) => {
      return props.theme.colors.message.outboundColor;
    }};
    background:
      linear-gradient(
          ${(props) => {
            return props.theme.colors.background.paper;
          }},
          ${(props) => {
            return props.theme.colors.background.paper;
          }}
        )
        padding-box,
      repeating-linear-gradient(
          -45deg,
          ${(props) => {
              return props.theme.colors.secondary.main;
            }}
            0,
          ${(props) => {
              return props.theme.colors.secondary.main;
            }}
            25%,
          transparent 0,
          transparent 50%
        )
        0 / 0.6em 0.6em;
    animation: ants 30s linear infinite;
    border: solid 1px transparent;
    padding: 0;
  }

  textarea {
    resize: none;
    border: 0;
    outline: 0;
    padding: 10px;
    margin: 0;
    display: block;
    border-radius: ${(props) => {
      return props.theme.defaults.bubbleBorderRadius;
    }};
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }};
    color: ${(props) => {
      return props.theme.colors.message.outboundColor;
    }};
  }

  ${MessageBubbleTipStyles} {
    g {
      display: none;
    }
  }

  @keyframes ants {
    to {
      background-position: 100% 100%;
    }
  }
`;

export const Root = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: ${(props) => {
    return props.direction === "in" ? "row-reverse nowrap" : "row nowrap";
  }};
  justify-content: flex-end;

  transition: all ease-in-out 0.3s;
  ${(props) => {
    return props.isEditing && isEditingStyles;
  }};
`;

export default class MessageBubble extends Component {
  static propTypes = {
    body: PropTypes.string,
    className: PropTypes.string,
    updateBody: PropTypes.func,
    message: PropTypes.object.isRequired,
    shouldCollapse: PropTypes.bool,
    isEditing: PropTypes.bool,
  };

  render() {
    const { message, shouldCollapse, isEditing } = this.props;
    const regexp = emojiRegex();
    const squishedMessageLength = split(
      message.body.replace(/\s/g, ""),
      "",
    ).length;
    const emojiCount = (message.body.match(regexp) || []).length;
    const { body, className } = this.props;
    return (
      <Root
        isEditing={isEditing}
        direction={message.direction}
        className={className}
      >
        {isEditing ? (
          <MessageBody>
            <Textarea
              onChange={({ target: { value } }) => {
                return this.props.updateBody(value);
              }}
              value={body}
            />
          </MessageBody>
        ) : (
          <MessageBody
            onlyEmoji={
              squishedMessageLength <= 3 && emojiCount === squishedMessageLength
            }
            direction={message.direction}
            dangerouslySetInnerHTML={{ __html: message.formattedBody }}
            messageSource={message.source}
          />
        )}

        <MessageTipWrapper direction={message.direction}>
          {!shouldCollapse && (
            <MessageBubbleTip
              direction={message.direction}
              messageSource={message.source}
            />
          )}
        </MessageTipWrapper>
      </Root>
    );
  }
}
