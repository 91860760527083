import { createSelectorCreator, defaultMemoize } from "reselect";
// import isEqual from "fast-deep-equal"; This performed worse than lodash
import isEqual from "lodash/isEqual";

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export default (selector) => {
  return createDeepEqualSelector(selector, (s) => {
    return s;
  });
};
