import capitalize from "lodash/capitalize";
import get from "lodash/get";

const parseValidationError = ({ response, params }) => {
  return {
    title: response.title,
    invalidParams: response.invalidParams.reduce((prev, param) => {
      return {
        ...prev,
        [param.name]: {
          reasons: param.reasons.map((reason) => {
            return `${capitalize(param.name).replace(/_/g, " ")} ${reason}`;
          }),
          value: params[param.name],
        },
      };
    }, {}),
  };
};

const parseError = (error) => {
  if (get(error, ["response", "@type"]) === "ValidationError") {
    return parseValidationError(error);
  }

  return error.response;
};

export default parseError;
