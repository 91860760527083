import { Component } from "react";
import styled from "styled-components";
import Logo from "components/Logo";

const Wrapper = styled.div`
  margin: 0 auto;
  padding-bottom: 20px;
  text-align: center;
  width: 100%;
`;

class Loading extends Component {
  render() {
    return (
      <Wrapper>
        <Logo animate color="transparent" dotColor="primary" width={50} />
      </Wrapper>
    );
  }
}

export default Loading;
