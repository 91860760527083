import get from "lodash/get";
import denormalizeWithShape from "utils/denormalizeWithShape";

const denormalizeAccountCollection = ({ id, records }) => {
  const record = records[id];
  if (!record) {
    return id;
  }
  return {
    ...record,
    members: get(record, ["members"], []).map((accountId) => {
      return denormalizeAccount({ id: accountId, records });
    }),
  };
};

const denormalizeAccount = ({ id, records }) => {
  const record = records[id];
  if (!record) {
    return id;
  }
  return {
    ...record,
    children: denormalizeAccountCollection({ id: record.children, records }),
    users: denormalizeWithShape({ id: record.users, records }),
  };
};

const denormalizeAccountHierarchy = denormalizeAccountCollection;

export default denormalizeAccountHierarchy;
