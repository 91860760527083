import { Component } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { ReactReduxContext } from "react-redux";

import getInjectors from "./reducerInjectors";

/**
 * Dynamically injects a reducer
 *
 * @deprecated
 *
 * @param {object} obj
 * @param {string} obj.key A key of the reducer
 * @param {function} obj.reducer A reducer that will be injected
 *
 */
export default ({ key, reducer }) => {
  return (WrappedComponent) => {
    class ReducerInjector extends Component {
      static displayName = `withReducer(${
        WrappedComponent.displayName || WrappedComponent.name || "Component"
      })`;

      static WrappedComponent = WrappedComponent;

      static contextType = ReactReduxContext;

      constructor(props, context) {
        super(props, context);

        getInjectors(context.store).injectReducer(key, reducer);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(ReducerInjector, WrappedComponent);
  };
};
