import get from "lodash/get";
import styled, { css } from "styled-components";

const labeling = get(window, ["branding", "labeling"], {});

export const styles = css`
  color: ${labeling?.primary_color};
  font-size: ${(props) => {
    return props.theme.fonts?.primaryHeaderFontSize ?? "14px";
  }};
  font-weight: 700;
  margin: 0;
`;

/**
 * @deprecated use MUI <Typography> instead
 */
const H3 = styled.h3`
  ${styles};
  padding: ${(props) => {
    return props.padding && "4px 0";
  }};
`;

export default H3;
