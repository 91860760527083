import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";

export default class JobAdderStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /jobadder/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      getNamesFunction: () =>
        getNamesFromText(
          $(doc)
            .find("#candidateFullName")
            .last()
            .text(),
        ),
      searchTextFunction: () =>
        $(doc)
          .find(".mobile-label")
          .text(),
      canParse: () => !!$(doc).find("#candidateFullName"),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "td.column-CandidateName, td.column-ContactName",
      containerSelector: "tr",
      canParse: () =>
        !!$(doc).find("td.column-CandidateName, td.column-ContactName")[0],
    });
    return [detailPageParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "JobAdderStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "JobAdderStrategy");
      return null;
    }
  };
}
