import {
  Box,
  Button,
  Chip,
  List,
  Radio,
  Typography,
  useTheme,
} from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import ConversationListSkeleton from "../fragments/ConversationListSkeleton";
import ConversationSkeleton from "../fragments/ConversationSkeleton";
import { DialogHeader } from "../../../components/DialogHeader";
import { DialogFooter } from "../../../components/DialogFooter";
import { useCurrentAccount } from "../../../hooks";
import { assignContactToConversationApi } from "../api/assignContactToConversationApi";
import { ResponseContact } from "../interfaces/Contact";

function ContactsMatchListPage() {
  // ==== HOOKS ==== //
  const history = useHistory();
  const snackbar = useSnackbar();
  const theme = useTheme();
  const currentAccount = useCurrentAccount();
  const [contact, setContact] = useState<ResponseContact | null>(null);
  const [loading, setLoading] = useState(false);
  const {
    state,
  }: {
    state: {
      contacts: ResponseContact[];
      conversationId: string;
    };
  } = useLocation();
  const { contacts, conversationId } = state;

  useEffect(() => {
    const assignedContact = contacts.find((c) => {
      return c.isAssigned;
    });

    setContact(assignedContact!);
  }, [contacts]);

  // ==== METHODS ==== //
  const handleClose = () => {
    history.push(`/${currentAccount.slug}`);
  };

  const handleChangeContact = (contactId: string) => {
    const selectedContact = contacts.find((cont) => {
      return cont.id === contactId;
    });
    if (selectedContact) {
      setContact(selectedContact);
    }
  };

  const handleSelectContact = async (): Promise<void> => {
    if (!contact) return;

    // If the the contact is Assigned just go to the conversation
    if (contact.isAssigned) {
      history.push(`/${currentAccount.slug}/inbox/open/${conversationId}`);
      return;
    }

    // If not assigned
    // 1- load the skeleton
    // 2- Do the assignation (wait for it)
    // 3- show the conversation with the contact assigned
    // 4- show the snackbar
    try {
      setLoading(true);
      await assignContactToConversationApi({
        conversationId,
        contactId: contact.id,
      });
      history.push(`/${currentAccount.slug}/inbox/open/${conversationId}`);
      snackbar?.enqueueSnackbar(`Conversation assigned to ${contact.name}`, {
        variant: "success",
      });
    } catch {
      snackbar?.enqueueSnackbar(
        `Error assigned conversation to ${contact.name}`,
        {
          variant: "error",
        },
      );
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <ConversationListSkeleton />
        <ConversationSkeleton />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <DialogHeader
        onClose={handleClose}
        title="Multiple contacts found"
        subtitle="Select the contact you want to text."
        fullScreen
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          paddingTop: "12px",
          paddingBottom: "12px",
        }}
      />
      <Box sx={{ overflowY: "auto", flexGrow: 1 }}>
        <List>
          {contacts &&
            contacts.map((cont) => {
              return (
                <Button
                  key={cont.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    p: 2,
                    borderBottom: "1px #dadada solid",
                    width: "100%",
                    backgroundColor:
                      contact?.id === cont.id
                        ? theme.palette.selected.light
                        : "white",
                  }}
                  onClick={() => {
                    return handleChangeContact(cont.id);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Radio
                      color="secondary"
                      checked={contact?.id === cont.id}
                      value={cont.id}
                      name="radio-buttons"
                      inputProps={{ "aria-label": `Contact: ${cont.name}` }}
                    />
                    <Box
                      sx={{
                        ml: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        color="text.primary"
                        sx={{
                          fontWeight:
                            contact?.id === cont.id ? "bold" : "normal",
                          textTransform: "capitalize",
                        }}
                      >
                        {cont.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {cont.phoneNumber}
                      </Typography>
                    </Box>
                  </Box>
                  {cont.isAssigned && (
                    <Chip
                      label="Assigned"
                      color="primary"
                      size="small"
                      sx={{ textTransform: "capitalize" }}
                    />
                  )}
                </Button>
              );
            })}
        </List>
      </Box>

      <Box
        sx={{
          width: "100%",
          boxShadow: "rgba(0, 0, 0, 0.24) 1px 0px 8px 0px",
        }}
      >
        <DialogFooter
          confirmText="SELECT"
          cancelText="CANCEL"
          isConfirmDisabled={!contact}
          onCancel={handleClose}
          onConfirm={handleSelectContact}
        />
      </Box>
    </Box>
  );
}

export default ContactsMatchListPage;
