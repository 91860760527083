import { Component } from "react";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";

class MuiInlineBadge extends Component {
  static propTypes = {
    badgeContent: PropTypes.any,
    classes: PropTypes.object.isRequired,
    color: PropTypes.string,
    inlineStyles: PropTypes.object,
    scale: PropTypes.number,
    letterColor: PropTypes.string,
  };

  render() {
    const {
      badgeContent,
      color,
      scale,
      classes,
      letterColor,
      inlineStyles = {},
    } = this.props;
    const style = scale
      ? { ...inlineStyles, transform: `scale(${scale})` }
      : { ...inlineStyles };
    return (
      <Badge
        badgeContent={badgeContent}
        classes={classes}
        color={color}
        style={style}
        sx={{
          "& .MuiBadge-root": {
            marginLeft: "auto",
          },
          "& .MuiBadge-badge": {
            color: `${letterColor}`,
            position: "relative",
            transform: "scale(1)",
          },
          "& .MuiBadge-invisible": {
            transform: "scale(0)",
          },
        }}
      >
        <span />
      </Badge>
    );
  }
}

export default MuiInlineBadge;
