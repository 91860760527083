/**
 * Retrieves the dimensions of an image from the given URL.
 *
 * @param url - The URL of the image.
 * @returns A promise that resolves to an array containing the width and height of the image.
 */
export const getImageDimensions = (url: string): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      return resolve([image.width, image.height]);
    };
    image.onerror = reject;
    image.src = url;
  });
};
