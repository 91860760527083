import assert from "assert";
import { userManager } from "@tesseract/core";
import { StepValues } from "../screens/IndividualSequenceOverview/types";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const updateStep = async (
  currentAccount: AccountType,
  body: StepValues,
  stepId: string,
): Promise<Response> => {
  const user = await userManager.getUser();

  const { body: messageBody, delay, attachments, absoluteTime } = body;

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequence_steps/${stepId}`;

  const request = new Request(url, {
    method: "PATCH",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      body: messageBody,
      delay,
      attachments,
      absoluteTime,
    }),
  });

  return fetch(request);
};
