import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import withSizes from "react-sizes";

import { actionGenerators, reducer, selectors } from "./state";
import Root from "./components/Root";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import breakpoints from "utils/styles/breakpoints";
import injectReducer from "utils/injectReducer";
import withRecord from "higherOrderComponents/withRecord";

export class ConversationFilterCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    showOtherFilters: selectors.selectShowOtherFilters(state, props),
    showCampaignFilters: selectors.selectShowCampaignFilters(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "Inbox/containers/ConversationFilterCollectionContainer",
  reducer,
});

export default compose(
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
    };
  }),
  withRecord({
    actions: ["fetch"],
    container:
      "Inbox/containers/ConversationFilterCollection/conversationFilterCollection",
    shape: { members: [] },
    showLoader: (props) => {
      return !get(props, ["conversationFilterCollection", "members", 0]);
    },
    type: "conversationFilterCollection",
  }),
  withReducer,
  withConnect,
)(ConversationFilterCollection);
