import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class SalesforceStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () =>
    (/salesforce/i.test(this.location?.href) ||
      /visualforce/i.test(this.location?.href)) &&
    /chg/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      textSelector: ".detailList",
      getNamesFunction: () =>
        Array.from($(doc).find("[id$='2_ileinner']"))
          .filter(node => /con2_ileinner/i.test(node.id))
          .map(node => {
            const fullName = getNamesFromText(node.textContent);
            return `${fullName[0]} ${fullName[1]}`;
          }),
      searchTextFunction: () =>
        Array.from($(doc).find(".detailList"))
          .map(node => {
            const clonedNode = node.cloneNode(true);
            clonedNode
              .querySelectorAll(`[style*="display: none"]`)
              .forEach(n => n.remove());
            clonedNode.querySelectorAll("td.labelCol").forEach(n => {
              if (n.innerText && n.innerText.includes("Emergency")) {
                n.nextSibling.remove();
              }
            });
            return clonedNode.textContent;
          })
          .join(", "),
      canParse: () =>
        !!$(doc).find("body.detailPage")[0] &&
        !/Call List/i.test(
          $(doc)
            .find("title")
            .text(),
        ) &&
        !/Lead/i.test(
          $(doc)
            .find("h2[class=mainTitle]")
            .text(),
        ),
    });
    const leadsPageParser = new SingleContactParser({
      doc,
      textSelector: ".pbBody",
      getNamesFunction: () =>
        Array.from($(doc).find("[id$='_ileinner']"))
          .filter(node => /dye[OS]_ileinner/i.test(node.id))
          .map(node => {
            const fullName = getNamesFromText(node.textContent);
            return `${fullName}`;
          }),
      canParse: () =>
        /Lead Detail/i.test(
          $(doc)
            .find("h2[class=mainTitle]")
            .text(),
        ),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "div.x-grid3-body td.x-grid3-td-FULL_NAME",
      containerSelector: "tr",
      canParse: () => !!$(doc).find(".listViewportWrapper")[0],
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
    });
    const foxJobsListPageParser = new MultiContactParser({
      doc,
      namesSelector: "td[id$='j_id491']",
      containerSelector: "tr",
      canParse: () =>
        /JOB/i.test(
          $(doc)
            .find("span[class='ui-widget-header'] > span")
            .text(),
        ),
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
    });
    const foxListPageParserSingleName = new MultiContactParser({
      doc,
      namesSelector: "div.x-grid3-body td.x-grid3-td-FULL_NAME",
      containerSelector: "tr",
      canParse: () =>
        !!$(doc).find(".listViewportWrapper")[0] &&
        /FOX/i.test(
          $(doc)
            .find("div[class='menuButtonButton']")
            .text(),
        ) &&
        !/LEADS/i.test(
          $(doc)
            .find("title")
            .text(),
        ),
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
    });
    const foxListPageParserSplitName = new MultiContactParser({
      doc,
      namesSelector: "div.x-grid3-body td.x-grid3-td-CONTACT_FIRST_NAME",
      containerSelector: "tr",
      canParse: () => {
        const hasViewport = !!$(doc).find(".listViewportWrapper")[0];
        const isCHGFox = /FOX/i.test(
          $(doc)
            .find("div[class='menuButtonButton']")
            .text(),
        );
        const isNotLead = $(doc).find("input[title*='Lead']").length === 0;
        return hasViewport && isCHGFox && isNotLead;
      },
      getNamesFunction: node => {
        const firstName = node.textContent.trim();
        const lastName = $(node)
          .closest("tr")
          .find("td.x-grid3-td-CONTACT_LAST_NAME")[0]
          .textContent.trim();
        return [firstName, lastName];
      },
    });
    const foxLeadsListViewParser = new MultiContactParser({
      doc,
      namesSelector: "div.x-grid3-row tr",
      containerSelector: "tr",
      canParse: () =>
        !!$(doc).find(".listViewportWrapper")[0] &&
        /FOX/i.test(
          $(doc)
            .find("div[class='menuButtonButton']")
            .text(),
        ) &&
        $(doc).find("input[title*='Lead']").length !== 0,
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
      getNamesFunction: () => null,
    });
    const foxRecentLeadsListParser = new MultiContactParser({
      doc,
      namesSelector: "div.hotListElement tr.dataRow",
      containerSelector: "tr",
      canParse: () =>
        !!$(doc).find("body.overviewPage")[0] &&
        /Recent Leads/i.test(
          $(doc)
            .find("td.pbTitle")
            .text(),
        ),
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
      getNamesFunction: node => {
        // eslint-disable-next-line no-param-reassign
        node.childNodes[0].innerHTML = ""; // reassign first child node's content so it isn't erroneously pulled
        const firstName = node.childNodes[2].innerText.trim();
        const lastName = node.childNodes[3].innerText.trim();
        return [firstName, lastName];
      },
    });
    const overviewPageParser = new MultiContactParser({
      doc,
      namesSelector: "div.hotListElement tr.dataRow th",
      containerSelector: "tr",
      canParse: () => !!$(doc).find("body.overviewPage")[0],
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
    });
    const callListParser = new MultiContactParser({
      doc,
      namesSelector: "tr.dataRow th a:first-child",
      containerSelector: "tr",
      canParse: () =>
        !!$(doc).find("body.detailPage")[0] &&
        /Call List/i.test(
          $(doc)
            .find("title")
            .text(),
        ),
    });
    return [
      foxJobsListPageParser,
      foxListPageParserSingleName,
      foxListPageParserSplitName,
      foxLeadsListViewParser,
      foxRecentLeadsListParser,
      detailPageParser,
      leadsPageParser,
      listPageParser,
      overviewPageParser,
      callListParser,
    ];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "SalesforceStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "SalesforceStrategy");
      return null;
    }
  };
}
