import { Component } from "react";
import styled from "styled-components";
import Button from "features/Authentication/components/PrimaryButton";

const Root = styled(Button)`
  position: relative;
`;

const LogoWrapper = styled.div`
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default class GoogleButton extends Component {
  render() {
    return (
      <Root {...this.props}>
        <LogoWrapper>
          <svg width="20px" height="30px" viewBox="0 0 412 420" version="1.1">
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="google-(1)" fill="#FFFFFF" fillRule="nonzero">
                <path
                  d="M407.732,170.625 C410.36,184.666 411.795,199.368 411.795,214.723 C411.796,334.688 331.481,420 210.204,420 C94.181,420 0.204,326.023 0.204,210 C0.204,93.977 94.181,0 210.204,0 C266.908,0 314.281,20.867 350.644,54.73 L291.44,113.927 L291.44,113.792 C269.394,92.79 241.44,82.03 210.204,82.03 C140.907,82.03 84.6,140.567 84.6,209.871 C84.6,279.161 140.906,337.839 210.204,337.839 C273.074,337.839 315.857,301.874 324.664,252.527 L210.204,252.527 L210.204,170.625 L407.732,170.625 Z"
                  id="Path"
                />
              </g>
            </g>
          </svg>
        </LogoWrapper>
        <span>Sign in With Google</span>
      </Root>
    );
  }
}
