import { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";

import Root from "./components/Root";
import { selectAppSettings } from "features/EntryPoint/containers/App/selectors";
import { actionGenerators, selectors } from "features/ContactModal/state";
import withRecord from "higherOrderComponents/withRecord";

class ConversationCollection extends Component {
  static propTypes = {
    conversationCollection: PropTypes.object.isRequired,
    contactModalContainer: PropTypes.object.isRequired,
    setConversationCount: PropTypes.func.isRequired,
    setContactModal: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      contactModalContainer,
      conversationCollection,
      setContactModal,
      setConversationCount,
    } = this.props;
    setConversationCount(conversationCollection.totalItems);
    if (
      contactModalContainer.substate.active &&
      !contactModalContainer.substate.activeConversation &&
      conversationCollection.id.includes("?account=")
    ) {
      const activeConversation = get(
        conversationCollection,
        ["members", 0, "id"],
        "Not Found",
      );
      setContactModal({
        activeConversation,
        expanded: "yourConversations",
      });
    }
  }

  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    contactModalContainer: selectors.selectContactModalContainer(state, props),
    appSettings: selectAppSettings(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

export default compose(
  withConnect,
  withRecord({
    actions: ["fetch"],
    container: "ContactSidebar/containers/ConversationCollection",
    loader: null,
    multiple: true,
    shape: {
      members: [
        {
          account: {},
          assignedContact: {},
          latestPreviewableItem: {
            type: "Union",
            schemas: [
              { schema: "Message", attachments: { members: [] } },
            ],
          },
        },
      ],
    },
    type: "conversationCollection",
  }),
)(ConversationCollection);
