import { useEffect } from "react";
import { useHistory } from "react-router";
import { subscribeToRedirect } from "utils/redirect/redirect";

export function RedirectListener() {
  const history = useHistory();

  useEffect(() => {
    const unsubscribe = subscribeToRedirect((url) => {
      history.push(url as string);
    });

    return () => {
      unsubscribe();
    };
  });

  return null;
}
