import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import Icon from "components/Icon";
import ICONS from "constants/icons";
import H3 from "components/H3";
import H4 from "components/H4";
import H5 from "components/H5";
import Logo from "components/Logo";
import BasicButton from "components/BasicButton";
import CreateContact from "features/Compose/containers/CreateContact";
import SearchGraphic from "utils/images/contact-search-blank-state.svg";

const Root = styled.ul`
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  height: 100%;
  width: 100%;
  border-radius: 0 0 10px 10px;
  overflow: auto;
`;

const ResultRow = styled.li`
  list-style: none;

  & > button {
    border-bottom: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 20px;

    ${H3} {
      color: ${(props) => {
        return props.theme.colors.secondary.main;
      }};
      font-size: 16px;
      text-align: right;
    }

    ${H4} {
      font-size: 16px;
      flex: 1 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    ${H5} {
      color: ${(props) => {
        return props.theme.colors.primary.main;
      }};
      font-size: 14px;
    }

    &:hover,
    &:focus {
      background: ${(props) => {
        return props.theme.colors.action.hover;
      }};
    }

    span {
      display: flex;
      align-items: center;
      height: 100%;
      flex: 1 1 auto;
    }

    button {
      ${(props) => {
        return props.theme.mixins.flexCenter();
      }};
      border-radius: 50%;
      height: 18px;
      width: 18px;
      background: ${(props) => {
        return props.theme.colors.primary.main;
      }};

      svg {
        color: ${(props) => {
          return props.theme.colors.primary.contrastText;
        }};
        height: 18px;
        width: 18px;
      }
    }
  }

  @media (max-width: 600px) {
    ${H5} {
      display: none;
    }
  }
`;

const StatusButton = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  height: 22px;
  width: 22px;
  background: ${(props) => {
    return props.isActive
      ? props.theme.colors.accent.main
      : props.theme.colors.primary.main;
  }};
  color: ${(props) => {
    return props.theme.colors.primary.contrastText;
  }};
  margin-right: 10px;
  border-radius: 50%;

  &:hover {
    background: ${(props) => {
      return !props.isActive && props.theme.colors.secondary.main;
    }};
  }
`;

const Overlay = css`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter();
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  z-index: 2;

  div {
    ${(props) => {
      return props.theme.mixins.flexCenter();
    }};
    flex-flow: column nowrap;
  }

  ${H3} {
    font-size: ${(props) => {
      return props.theme.fonts.largeSecondaryHeaderFontSize;
    }};
  }

  ${H4} {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    font-size: ${(props) => {
      return props.theme.fonts.largeSecondaryHeaderFontSize;
    }};
  }
`;

const LoadingScreen = styled.div`
  ${Overlay};
`;

const KeepTyping = styled.div`
  ${Overlay};

  img {
    width: 145px;
    margin-bottom: 20px;
  }
`;

const PhoneInfo = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 0 0 175px;
  text-align: right;
`;

class ContactSearchResults extends Component {
  static propTypes = {
    addRecipient: PropTypes.func.isRequired,
    results: PropTypes.array.isRequired,
    onResultSelect: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
    showContactCreateErrors: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
    recipients: PropTypes.array,
    theme: PropTypes.object.isRequired,
  };

  renderResults() {
    const { theme } = this.props;
    return this.props.results.map((r) => {
      const isQuickComposeRecipient =
        typeof this.props.recipients.find((recipient) => {
          return recipient.id === r.id;
        }) !== "undefined";

      return (
        <ResultRow key={r.id} isQuickComposeRecipient={isQuickComposeRecipient}>
          <BasicButton
            tabIndex={0}
            onClick={() => {
              return this.props.onResultSelect(r);
            }}
          >
            <StatusButton isActive={isQuickComposeRecipient}>
              <Icon
                icon={isQuickComposeRecipient ? ICONS.CHECK : ICONS.ADD}
                iconSize={10}
                iconColor={theme.colors.primary.contrastText}
              />
            </StatusButton>
            <span
              aria-label="contact-search-result"
              data-testid="contact-search-result"
            >
              <H4>{r.contact.name}</H4>
              <PhoneInfo>
                <H3>{r.formattedPhoneNumber}</H3>
                {r.type && <H5>{r.type}</H5>}
              </PhoneInfo>
            </span>
          </BasicButton>
        </ResultRow>
      );
    });
  }

  renderOverlay() {
    const { isFetching, searchQuery } = this.props;

    if (searchQuery.length < 3) {
      const offset = 3 - searchQuery.length;
      const character = offset === 1 ? "character" : "characters";
      return (
        <KeepTyping>
          <div>
            <img src={SearchGraphic} alt="Search" />
            <H4>
              Type {offset} more {character} to search
            </H4>
          </div>
        </KeepTyping>
      );
    }

    if (isFetching) {
      return (
        <LoadingScreen>
          <div>
            <Logo animate width={70} color="transparent" dotColor="primary" />
            <H3>Finding Contacts</H3>
          </div>
        </LoadingScreen>
      );
    }

    return null;
  }

  render() {
    const { addRecipient, showContactCreateErrors, searchQuery } = this.props;
    return (
      <Root>
        {this.renderOverlay()}
        {this.renderResults()}
        {searchQuery.length > 2 && (
          <CreateContact
            addRecipient={addRecipient}
            searchQuery={searchQuery}
            showErrors={showContactCreateErrors}
          />
        )}
      </Root>
    );
  }
}

export default withTheme(ContactSearchResults);
