import { useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { Field, FieldProps, useFormikContext } from "formik";
import { Account } from "@tesseract/core";
import { IntroAndAppendedFormValues } from "../types";
import { messageSettings, messageTypes } from "../constants";
import { useMessageSettingsCard } from "../hooks";
import {
  SettingsCard,
  SettingsCardAlert,
  SettingsCardContent,
  SettingsCardHeader,
} from "features/Settings";
import MessageInputFormRow from "components/MessageInputFormRow";
import { Input } from "components/Input";
import { LockIcon } from "icons";

function DefaultsCard({ account }: { account?: Account.Raw }) {
  const { errors, touched, values, setFieldValue, submitForm } =
    useFormikContext<IntroAndAppendedFormValues>();

  const { appendedMessageDefaults } = account?.settings ?? {};

  const { fields, maxChars, message } = messageSettings[messageTypes.defaults];

  const {
    admin,
    lockedAtAccountName,
    shortenedLink,
    setShortenedLink,
    toggleLock,
  } = useMessageSettingsCard(messageTypes.defaults, account);

  const { active, locked } = values[messageTypes.defaults];

  return (
    <SettingsCard
      sx={(theme) => {
        return {
          [theme.breakpoints.up("lg")]: {
            maxWidth: "23rem",
          },
        };
      }}
    >
      <SettingsCardHeader
        account={account}
        active={active}
        canEditLock={!appendedMessageDefaults?.disabled}
        hideSwitch
        locked={locked || !admin}
        title="Defaults"
        onActiveChange={() => {
          setFieldValue(fields.active, !active);
        }}
        onLockedChange={
          admin
            ? () => {
                toggleLock(values, setFieldValue);
                return submitForm();
              }
            : undefined
        }
        sx={{ marginBottom: 0, padding: "0.5rem" }}
      />
      {admin ? null : (
        <SettingsCardAlert severity="info" icon={<LockIcon />}>
          This setting can only be modified by your administrator.
        </SettingsCardAlert>
      )}
      {locked && admin ? (
        <SettingsCardAlert severity="info" icon={<LockIcon />}>
          {lockedAtAccountName === "" ? (
            <Skeleton
              width="18rem"
              sx={(theme) => {
                return {
                  // TODO: Fix this to connect to new MUI theme values
                  backgroundColor: "rgba(40, 60, 89, 0.3)",
                };
              }}
            />
          ) : (
            <>
              Locked by an admin for accounts below{" "}
              <strong>{lockedAtAccountName}</strong>.
            </>
          )}
        </SettingsCardAlert>
      ) : null}
      {active && account ? (
        <SettingsCardContent sx={{ minWidth: "20rem" }}>
          <Typography variant="h6" marginBottom="1rem">
            Default opt-out language:
          </Typography>
          <MessageInputFormRow
            currentAccount={account}
            errors={errors}
            fieldName={fields.message}
            isDisabled={locked}
            hideUserPersonalization={false}
            maxChars={maxChars}
            placeholder={message}
            setFieldValue={setFieldValue}
            setShortenedLink={setShortenedLink}
            shortenedLink={shortenedLink}
            touched={touched}
            values={values}
          />
          <Field name={fields.frequency}>
            {({ field, form }: FieldProps) => {
              return (
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6">Resend cycle:</Typography>
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="flex-start"
                    sx={(theme) => {
                      return {
                        [theme.breakpoints.down("md")]: {
                          flexDirection: "column",
                          alignItems: "flex-start",
                          marginTop: "0.5rem",
                        },
                      };
                    }}
                  >
                    <Typography variant="body1" margin="0rem 1rem 1rem 0rem">
                      Every X Days:
                    </Typography>
                    <Input
                      name={field.name}
                      label="Days"
                      onChange={(event) => {
                        // Workaround to avoid using known issues with <input type="number" />
                        // More: https://mui.com/material-ui/react-text-field/#type-quot-number-quot
                        const number = Number(event.target.value);
                        if (
                          !Number.isNaN(number) &&
                          number < Number.MAX_SAFE_INTEGER
                        ) {
                          form.setFieldValue(field.name, number);
                        }
                      }}
                      disabled={locked}
                      data-testid="appended-message-default-frequency-input"
                      inputProps={{ pattern: "\\d*", maxLength: 3 }} // Allows numbers only, to a max of 999
                    />
                  </Box>
                </Box>
              );
            }}
          </Field>
        </SettingsCardContent>
      ) : null}
    </SettingsCard>
  );
}

export { DefaultsCard };
