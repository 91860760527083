import styled from "styled-components";
import { rgba } from "polished";
import { getMessageBorderColor } from "./utils/getMessageColors";

export default styled.div`
  background: ${(props) => {
    return getMessageBorderColor(props);
  }};
  border-radius: 50%;
  height: 7px;
  margin-right: 10px;
  width: 7px;
  animation: ${(props) => {
      return `pulse-shadow-${props.status}`;
    }}
    infinite 5s;
  box-shadow: 0 0 0
    ${(props) => {
      return rgba(getMessageBorderColor(props), 0.4);
    }};

  @keyframes pulse-shadow-new {
    0% {
      box-shadow: 0 0 0 0
        ${(props) => {
          return rgba(props.theme.colors.accent.main, 0.8);
        }};
    }
    40% {
      box-shadow: 0 0 0 8px
        ${(props) => {
          return rgba(props.theme.colors.accent.main, 0);
        }};
    }
    100% {
      box-shadow: 0 0 0 0
        ${(props) => {
          return rgba(props.theme.colors.accent.main, 0);
        }};
    }
  }

  @keyframes pulse-shadow-stale {
    0% {
      box-shadow: 0 0 0 0
        ${(props) => {
          return rgba(props.theme.colors.message.staleBorderColor, 0.8);
        }};
    }
    40% {
      box-shadow: 0 0 0 8px
        ${(props) => {
          return rgba(props.theme.colors.message.staleBorderColor, 0);
        }};
    }
    100% {
      box-shadow: 0 0 0 0
        ${(props) => {
          return rgba(props.theme.colors.message.staleBorderColor, 0);
        }};
    }
  }

  @keyframes pulse-shadow-superStale {
    0% {
      box-shadow: 0 0 0 0
        ${(props) => {
          return rgba(props.theme.colors.message.superStaleBorderColor, 0.8);
        }};
    }
    40% {
      box-shadow: 0 0 0 8px
        ${(props) => {
          return rgba(props.theme.colors.message.superStaleBorderColor, 0);
        }};
    }
    100% {
      box-shadow: 0 0 0 0
        ${(props) => {
          return rgba(props.theme.colors.message.superStaleBorderColor, 0);
        }};
    }
  }
`;
