import { Box, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { IntegrationCard } from "../IntegrationCard";
import { CustomIntegrationDialog } from "../CustomIntegrationDialog";
import { OauthIntegrationDialog } from "../OauthIntegrationDialog/OauthIntegrationDialog";
import { AvailableIntegrationsProps } from "./types";
import Loader from "components/Loader";
import { IntegrationProvider } from "models/IntegrationProvider";

export function AvailableIntegrations({
  allIntegrations,
  connectedIntegrations,
  currentAccount,
  selectedIntegration,
  setSelectedIntegration,
}: AvailableIntegrationsProps) {
  const [availableIntegrations, setAvailableIntegrations] = useState<
    IntegrationProvider[]
  >([]);

  const [customAuthorizationDialogOpen, setCustomAuthorizationDialogOpen] =
    useState<boolean>(false);

  const [oauthDialogOpen, setOauthDialogOpen] = useState<boolean>(false);

  useEffect(() => {
    if (allIntegrations.length > 0 && connectedIntegrations.length > 0) {
      const filteredIntegrations = [...allIntegrations];
      connectedIntegrations.forEach((connectedIntegration) => {
        const index = filteredIntegrations.findIndex((integration) => {
          return integration.providerName === connectedIntegration.providerName;
        });
        filteredIntegrations.splice(index, 1);
      });
      return setAvailableIntegrations(filteredIntegrations);
    }
    return setAvailableIntegrations(allIntegrations);
  }, [allIntegrations, connectedIntegrations]);

  return (
    <>
      <Box>
        <Typography variant="h4">Available</Typography>
        <Loader
          isLoading={availableIntegrations.length === 0}
          aria-label="Page is Loading"
        />
        {availableIntegrations.length > 0 ? (
          <Grid
            container
            spacing={2}
            sx={{
              marginTop: "24px",
              marginBottom: "32px",
            }}
          >
            {availableIntegrations.map((integration) => {
              if (integration.enabled === false) return null;

              return (
                <IntegrationCard
                  integration={integration}
                  setCustomAuthorizationDialogOpen={
                    setCustomAuthorizationDialogOpen
                  }
                  setOauthDialogOpen={setOauthDialogOpen}
                  setSelectedIntegration={setSelectedIntegration}
                  key={`${integration.provider}-${integration.category || ""}`}
                />
              );
            })}
            <IntegrationCard
              integration={{
                provider: "webhook",
                providerName: "Add webhook",
                strategy: "custom",
                config: {
                  baseUrl: "",
                },
              }}
              setCustomAuthorizationDialogOpen={
                setCustomAuthorizationDialogOpen
              }
              setSelectedIntegration={setSelectedIntegration}
            />
          </Grid>
        ) : null}
      </Box>

      <CustomIntegrationDialog
        currentAccount={currentAccount}
        integration={selectedIntegration}
        open={customAuthorizationDialogOpen}
        setCustomAuthorizationDialogOpen={setCustomAuthorizationDialogOpen}
        setSelectedIntegration={setSelectedIntegration}
      />

      <OauthIntegrationDialog
        currentAccount={currentAccount}
        integration={selectedIntegration}
        open={oauthDialogOpen}
        setOauthDialogOpen={setOauthDialogOpen}
        setSelectedIntegration={setSelectedIntegration}
      />
    </>
  );
}
