import {
  DialogTitle,
  useMediaQuery,
  useTheme,
  Box,
  Divider,
  IconButton,
  Typography,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DialogHeaderProps } from "./types";

function DialogHeader({
  title,
  subtitle,
  onClose,
  fullScreen,
  isPopover,
  hasTooltip,
  dialogName,
  ...props
}: DialogHeaderProps) {
  const theme = useTheme();
  const mobileScreen =
    useMediaQuery(theme.breakpoints.down("sm")) && Boolean(fullScreen);

  const tooltipTitle = hasTooltip
    ? mobileScreen
      ? "Back"
      : `Close ${dialogName || ""}`
    : "";

  return (
    <>
      <Box
        alignItems="center"
        display="flex"
        width="100%"
        padding={mobileScreen ? "0.5rem" : "0.5rem 1rem"}
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          ...(mobileScreen && {
            [theme.breakpoints.down("sm")]: {
              flexDirection: "row-reverse",
              justifyContent: "flex-end",
            },
          }),
        }}
        {...props}
      >
        <Box
          display="flex"
          flexDirection="column"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
        >
          <DialogTitle
            variant={isPopover && !mobileScreen ? "h6" : "h5"}
            color="text.primary"
            sx={{ padding: "unset" }}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {title}
          </DialogTitle>
          {subtitle ? (
            <Typography
              variant="body2"
              color="text.secondary"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              {subtitle}
            </Typography>
          ) : null}
        </Box>
        {isPopover && !mobileScreen ? null : (
          <Tooltip title={tooltipTitle}>
            <IconButton
              aria-label={mobileScreen ? "Close" : "Back"}
              onClick={onClose}
              data-testid="close-modal"
              sx={{
                ...(mobileScreen
                  ? {
                      marginRight: "0.5rem",
                    }
                  : {
                      marginLeft: "1rem",
                    }),
              }}
              size="large"
            >
              {mobileScreen ? <ArrowBackIcon /> : <CloseIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
      <Divider />
    </>
  );
}
export { DialogHeader };
