import styled from "styled-components";

export default styled.header`
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex: 0 0 auto;
  height: ${(props) => {
    return props.theme.layout.topHeaderHeight;
  }};
  justify-content: space-between;
  padding: 16px;
`;
