export const phoneTypes = Object.freeze([
  {
    value: "landline",
    label: "Landline",
  },
  {
    value: "virtual",
    label: "Virtual",
  },
] as const);
