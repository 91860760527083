import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@mui/material/Button";

import FrownyTexty from "utils/images/frowny-texty.svg";
import H3 from "components/H3";
import ImportSection from "features/ContactImports/containers/NewContactImport/components/ImportSection";

const Error = styled(ImportSection)`
  justify-content: center;
  max-width: 300px;
  padding-top: 0px;
`;

const ErrorHeading = styled(H3)`
  font-size: ${(props) => {
    return props.theme.fonts.xLargeHeaderFontSize;
  }};

  span {
    margin-right: 10px;
  }
`;

export default class ImportError extends Component {
  static propTypes = {
    errorMessage: PropTypes.string.isRequired,
    showImage: PropTypes.bool,
    startNewImport: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showImage: true,
  };

  render() {
    const { errorMessage, showImage, startNewImport } = this.props;
    return (
      <Error>
        {showImage && <img alt="Contact Import Error" src={FrownyTexty} />}
        <ErrorHeading>
          <span role="img" aria-label="warning">
            ⚠️
          </span>
          Oh no!
        </ErrorHeading>
        <p>{errorMessage}</p>
        <Button variant="outlined" color="primary" onClick={startNewImport}>
          Start New Import
        </Button>
      </Error>
    );
  }
}
