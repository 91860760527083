// @ts-check

import Honeybadger from "@honeybadger-io/js";

const ignorePatterns = [
  /*
    This error is benign according to the w3c and is not hitting the react
    ErrorBoundary, so is not causing users to see an error page
    https://github.com/w3c/csswg-drafts/issues/5023
  */
  /ResizeObserver loop limit exceeded/i,
  /*
    This error only occurs occassionally on Edge 18. The team has sent an
    intercom message to customers that use Edge 18 letting them know that they
    should upgrade. There is nothing that we can do about the error, so we are
    no longer going to send it to Honeybadger.
  */
  /Failed to read/i,
  /*
    This error occurs on a network error. "The [fetch] promise does not reject
    on HTTP errors — it only rejects on network errors."[1] Since there is no
    useful information describing the error, we assume there might be a network
    hiccup and automatically retry one time. If it fails again, then there is
    likely a network error with our server and we fail silently to avoid
    exasperating any issue. An example of a network error is when Cloudfare
    went down on July 17, 2020.[2] Since there is nothing that we can do about
    the error, we are no longer going to send it to Honeybadger.
    [1] https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/fetch
    [2] https://blog.cloudflare.com/cloudflare-outage-on-july-17-2020/
  */
  /Failed to fetch/i,
];

/**
 * Get Honeybadger Environment
 *
 * @param {string} hostname
 * @returns {"production" | "staging"}
 */
const getHoneybadgerEnvironment = (hostname) => {
  if (hostname.includes("next.textus.com")) {
    return "production";
  }
  return "staging";
};

export default () => {
  // Honeybadger is Disabled as nothing is ever done with the Frontend errors currently.
  //
  // const { hostname } = document.location;
  // if (hostname.includes("localhost") || hostname.includes("pr-")) return null;
  // return Honeybadger.configure({
  //   apiKey: "784da29c",
  //   environment: getHoneybadgerEnvironment(hostname),
  //   ignorePatterns,
  // });
};
