import { Component } from "react";
import PropTypes from "prop-types";
import { CSSTransition } from "react-transition-group";
import ModalWrapper from "./ModalWrapper";
import Portal from "components/Portal";

/**
 * @deprecated
 * Use MUI Dialog or ui/app/components/Dialog/Dialog.tsx
 */
class Modal extends Component {
  static propTypes = {
    ariaLabel: PropTypes.string,
    children: PropTypes.any,
    closeModal: PropTypes.func,
    navigateBackward: PropTypes.func,
    navigateForward: PropTypes.func,
    fullScreen: PropTypes.bool,
    transitionClassname: PropTypes.string,
    transitionDelay: PropTypes.number,
    transitionDuration: PropTypes.number,
    transitionIn: PropTypes.bool,
  };

  static defaultProps = {
    transitionClassname: "modal",
    transitionDelay: 500,
    transitionDuration: 500,
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyDown, { capture: true });
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyDown, {
      capture: true,
    });
  }

  handleKeyDown = (e) => {
    const { closeModal, transitionIn, navigateBackward, navigateForward } =
      this.props;
    if (e.key === "Escape" && closeModal && transitionIn) {
      closeModal();
    }
    if (e.key === "ArrowLeft" && navigateBackward) {
      navigateBackward();
    }
    if (e.key === "ArrowRight" && navigateForward) {
      navigateForward();
    }
  };

  render() {
    const {
      ariaLabel,
      closeModal,
      children,
      fullScreen,
      transitionIn,
      transitionDuration,
      transitionDelay,
      transitionClassname,
    } = this.props;

    return (
      <CSSTransition
        mountOnEnter
        unmountOnExit
        classNames={transitionClassname}
        timeout={transitionDuration + transitionDelay}
        in={transitionIn}
      >
        <Portal
          root={
            document.querySelector("#textus-ModalContainer") ??
            document.querySelector("body")
          }
        >
          <ModalWrapper
            ariaLabel={ariaLabel}
            transitionClassname={transitionClassname}
            transitionDuration={transitionDuration}
            transitionDelay={transitionDelay}
            fullScreen={fullScreen}
            closeModal={closeModal}
          >
            {children}
          </ModalWrapper>
        </Portal>
      </CSSTransition>
    );
  }
}

export default Modal;
