import { Component } from "react";
import PropTypes from "prop-types";
import Honeybadger from "@honeybadger-io/js";
import get from "lodash/get";
import BlankState from "components/BlankState";

import Texty from "utils/images/frowny-texty.svg";

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  state = {
    hasError: false,
  };

  getReloadMessage() {
    return (
      <div>
        Please <a href="/">refresh</a> and try again.
      </div>
    );
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    Honeybadger.notify(error, {
      ...info,
      context: {
        user_id: get(global, ["TextUs", "currentUser"]),
        user_email: get(global, ["TextUs", "currentUserEmail"]),
        occured_in_extension: window.self !== window.top,
      },
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <BlankState
          image={<img src={Texty} alt="Frowning Texty" />}
          title="Something went wrong"
          subComponent={this.getReloadMessage()}
        />
      );
    }
    return this.props.children;
  }
}
