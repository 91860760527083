import type { FieldValidator } from "formik";
import { useField as useFormikField } from "formik";
import { useEffect, useMemo } from "react";
import { Options } from "./types";

/**
 * Use from field
 */
export const useField = <Value>(
  name: string,
  { defaultValue, required, validate: validateProp }: Options<Value>,
) => {
  const validate: FieldValidator | undefined = useMemo(() => {
    return required
      ? (value: unknown) => {
          if (!value) {
            return required === true ? "required" : required;
          }
          return validateProp ? validateProp(value) : undefined;
        }
      : undefined;
  }, [required, validateProp]);

  const [field, meta, { setValue }] = useFormikField<Value>({
    name,
    validate,
  });

  useEffect(() => {
    if (field.value === undefined && defaultValue !== undefined) {
      setValue(defaultValue);
    }
  }, [defaultValue, field.value, setValue]);

  return [
    { ...field, error: meta.touched && Boolean(meta?.error) },
    setValue,
  ] as const;
};
