import { Component, createRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

const Root = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const Overflow = styled.div`
  width: auto;

  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const PopperContent = styled.div`
  margin: 10px 0;
  padding: 4px 8px;
`;

export default class EllipsisOverflow extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.Root = createRef();
    this.Overflow = createRef();
    this.state = { anchorEl: undefined, open: false };
  }

  setShowTooltip = (open) => {
    return (event) => {
      if (
        this.Root.current.offsetWidth <
        this.Overflow.current.childNodes[0].scrollWidth
      ) {
        this.setState({ anchorEl: event.currentTarget, open });
      }
    };
  };

  render() {
    const { children } = this.props;
    const { anchorEl, open } = this.state;
    return (
      <Root
        ref={this.Root}
        onMouseEnter={this.setShowTooltip(true)}
        onMouseLeave={this.setShowTooltip(false)}
      >
        <Popper anchorEl={anchorEl} open={open} placement="top" transition>
          {({ TransitionProps }) => {
            return (
              <Grow {...TransitionProps} timeout={350}>
                <Paper>
                  <PopperContent>{children}</PopperContent>
                </Paper>
              </Grow>
            );
          }}
        </Popper>
        <Overflow ref={this.Overflow}>{children}</Overflow>
      </Root>
    );
  }
}
