import AccountConfiguration from "../components/AccountConfiguration";
import BrandInformation from "../components/BrandInformation";
import CustomUserSchema from "../components/CustomUserSchema";
import WhiteLabel from "../components/WhiteLabel";
import is from "utils/is";

import { SettingsSection } from "models/SettingsSection";
import { Account } from "models/Account";
import { Setting } from "models/Setting";

export const getOrganizationSettings = ({
  account,
  isAdmin,
}: {
  account: Account;
  isAdmin: boolean;
}): SettingsSection | null => {
  return isAdmin // this collection of settings is restricted to admins
    ? {
        title: "Organization Settings",
        path: "organization",
        settings: [
          account.childrenCount > 0 && account.type !== "partner"
            ? {
                title: "Account Configuration",
                to: `/account-configuration`,
                OptionComponent: AccountConfiguration,
              }
            : null,
          account.adminUserSchema
            ? {
                title: "Brand Registration (10DLC)",
                to: `/brand-registration`,
                OptionComponent: BrandInformation,
              }
            : null,
          account.adminUserSchema
            ? {
                title: "User Personalization",
                to: `/user-personalization`,
                OptionComponent: CustomUserSchema,
              }
            : null,
          account.featureFlags?.whiteLabelEnabled
            ? ({
                title: "White Label",
                to: "/white-label",
                OptionComponent: WhiteLabel,
                linkProps: {
                  sx: { display: { xs: "none", sm: "none", md: "block" } },
                },
              } satisfies Setting)
            : null,
        ].filter(is.truthy), // filters out all nulls
      }
    : null;
};
