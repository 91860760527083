import { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import withSizes from "react-sizes";

import Root from "./components/Root";
import {
  selectAppSettings,
  selectCurrentAccount,
} from "features/EntryPoint/containers/App/selectors";
import breakpoints from "utils/styles/breakpoints";
import withRecord from "higherOrderComponents/withRecord";
import createOptimisticConversationUpdateFunc from "utils/createOptimisticConversationUpdateFunc";

class ConversationCollectionItem extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    appSettings: selectAppSettings(state, props),
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

const actions = ["close", "reopen", "star", "unstar"].map((action) => {
  return {
    type: action,
    optimisticUpdateFunc: createOptimisticConversationUpdateFunc(action),
  };
});

export default compose(
  withRecord({
    actions,
    container: "Inbox/containers/ConversationCollectionItem",
    type: "conversation",
  }),
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
    };
  }),
  withConnect,
)(ConversationCollectionItem);
