import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { StyledSubhead } from "../../styles";
import { CreateOptionCard } from "../CreateOptionCard";
import { CreateScreenProps } from "./types";

function CreateScreen({
  close,
  handleBlueprintsClick,
  handleDuplicateClick,
  handleNewClick,
}: CreateScreenProps) {
  return (
    <>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        margin="1rem"
      >
        <Box display="flex" flexDirection="column">
          <Typography variant="h5">Create a sequence</Typography>
          <StyledSubhead variant="body2">
            Decide how to start your sequence
          </StyledSubhead>
        </Box>
        <IconButton
          onClick={close(false)}
          size="medium"
          data-testid="close-new-sequence-modal"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        margin="0rem 1rem 1rem 1rem"
      >
        <CreateOptionCard
          ariaLabel="Create new sequence card"
          dataTestId="create-new-sequence"
          clickHandler={handleNewClick}
          header="New"
          subhead="Start with a blank sequence then add steps"
        />
        <CreateOptionCard
          ariaLabel="Duplicate existing sequence card"
          dataTestId="duplicate-sequence"
          clickHandler={handleDuplicateClick}
          header="Duplicate"
          subhead="Start from a copy of an existing sequence"
        />
        <CreateOptionCard
          ariaLabel="Create sequence from blueprint button"
          dataTestId="create-sequence-from-blueprint"
          clickHandler={handleBlueprintsClick}
          header="Blueprints"
          subhead="Start from a pre-built sequence template"
        />
      </Box>
    </>
  );
}

export { CreateScreen };
