import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { actionGenerators } from "./state";
import {
  selectAppSettings,
  selectCurrentAccount,
} from "features/EntryPoint/containers/App/selectors";
import useIsExtensionPopout from "hooks/useIsExtensionPopout";
import { Snackbar } from "components/Snackbar";

const validMessageTypes = new Set(["pageContent", "numberClicked"]);

const useExtensionMessageHandler = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentAccount = useSelector(selectCurrentAccount);
  const appSettings = useSelector(selectAppSettings);
  const { enqueueSnackbar } = useSnackbar();

  const isExtensionPopout = useIsExtensionPopout();

  useEffect(() => {
    let lastClickToTextNumber = null;
    const validSources = appSettings?.CHROME_EXTENSION_URLS || [];
    const handler = (event) => {
      const { source, data, origin } = event;

      // Tesseract side panel
      if (data?.type === "findNumbersResponse") {
        // If payload is undefined, there was a problem finding numbers.
        if (event.data.payload === undefined) {
          enqueueSnackbar(
            "We ran into an issue finding numbers, please refresh the tab.",
            {
              content: (key, message) => {
                return <Snackbar id={key} message={message} variant="error" />;
              },
            },
          );
          return;
        }

        history.push(
          `/${currentAccount.slug}/find_numbers`,
          event.data.payload,
        );

        return;
      }

      if (data.type === "clickToText") {
        // The Conversation Matching feature sends this event multiple times automatically as a page's DOM changes.
        if (data.method === "conversationMatching") {
          // Prevent redirects when the user is already in the click-to-text flow.
          const paths = ["click_to_text", "contact_match_list", "inbox/open/"];
          const inClickToTextFlow = paths.some((path) => {
            return window.location.pathname.includes(path);
          });

          if (inClickToTextFlow === true) {
            return;
          }

          // Prevent redirects when the number found has already been used.
          const currentNumber = event.data.payload.number.replaceAll(/\D/g, "");
          if (currentNumber === lastClickToTextNumber) {
            return;
          }

          // Record the last number we received.
          lastClickToTextNumber = currentNumber;
        }

        history.push(
          `/${currentAccount.slug}/click_to_text`,
          event.data.payload,
        );

        return;
      }

      if (data?.type === "getTesseractSessionRequest") {
        dispatch(actionGenerators.getTesseractSessionRequest({ source }));
        return;
      }

      if (
        !validSources.some((validSource) => {
          return origin && origin.match(validSource);
        }) ||
        !validMessageTypes.has(data?.type)
      ) {
        return;
      }

      dispatch(
        actionGenerators.handleExtensionMessageRequest({
          event,
          countryCode: currentAccount?.settings?.countryCode?.value || "US",
        }),
      );
    };

    if (isExtensionPopout) {
      window.addEventListener("message", handler, false);
    }

    return () => {
      if (isExtensionPopout) {
        window.removeEventListener("message", handler, false);
      }
    };
  }, [
    dispatch,
    currentAccount,
    isExtensionPopout,
    history,
    appSettings?.CHROME_EXTENSION_URLS,
    enqueueSnackbar,
  ]);
};

export default useExtensionMessageHandler;
