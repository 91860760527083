import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";

import ResolveConflict from "./ResolveConflict";
import { ContactForm } from "features/ContactForm";

export default class Root extends Component {
  static propTypes = {
    cancelEdit: PropTypes.func,
    contact: PropTypes.any,
    contactFormWrapperContainer: PropTypes.object.isRequired,
    conversationPhoneNumber: PropTypes.string,
    createContactRequest: PropTypes.func.isRequired,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    hideRemoveButton: PropTypes.bool,
    partialMatches: PropTypes.array,
    updateContactRequest: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onContactUnlink: PropTypes.func,
  };

  render() {
    const {
      cancelEdit,
      contact,
      contactFormWrapperContainer,
      conversationPhoneNumber,
      createContactRequest,
      currentAccount,
      hideRemoveButton,
      partialMatches,
      updateContactRequest,
      currentUser,
      onDelete,
      onContactUnlink,
    } = this.props;
    const { errorCreating, isCreating, errorUpdating, isUpdating } =
      contactFormWrapperContainer.substate;
    if (
      partialMatches ||
      (errorCreating && get(errorCreating, ["response", "totalItems"]) > 0)
    ) {
      const matches = partialMatches || errorCreating.response.members;
      const newContactParams = contact || errorCreating.params;
      return (
        <ResolveConflict
          newContactParams={newContactParams}
          partialMatches={matches}
          updateContactRequest={updateContactRequest}
        />
      );
    }
    const { errorSubmitting, isSubmitting, requestUrl, submitHandler } = get(
      contact,
      ["id"],
    )
      ? {
          errorSubmitting: errorUpdating,
          isSubmitting: isUpdating,
          requestUrl: contact.id,
          submitHandler: updateContactRequest,
        }
      : {
          errorSubmitting: errorCreating,
          isSubmitting: isCreating,
          requestUrl: currentAccount.contacts,
          submitHandler: createContactRequest,
        };
    return (
      <ContactForm
        cancelEdit={cancelEdit}
        contact={contact}
        conversationPhoneNumber={conversationPhoneNumber}
        currentAccount={currentAccount}
        currentUser={currentUser}
        errorSubmitting={errorSubmitting}
        hideRemoveButton={hideRemoveButton}
        isSubmitting={isSubmitting}
        onDelete={onDelete}
        requestUrl={requestUrl}
        submitHandler={submitHandler}
        onContactUnlink={onContactUnlink}
      />
    );
  }
}
