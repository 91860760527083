import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import styled from "styled-components";
import { get } from "lodash";
import { useSnackbar } from "notistack";
import getIsClockInaccurate from "../utils/getIsClockInaccurate";
import Button from "features/Authentication/components/PrimaryButton";
import BasicButton from "components/BasicButton";

const labeling = get(window, ["branding", "labeling"], {});

const ResetPasswordButton = styled(BasicButton)`
  font-size: 12px;
  color: ${labeling?.primary_color};
  height: 30px;
  margin: 12px 0 12px auto;
  &:hover {
    color: ${labeling?.secondary_color};
  }
`;

interface MobileNativeAuthFormProps {
  hidePassword?: boolean;
  onEmailChange: () => void;
  onForgotPasswordClick: () => void;
}

function MobileNativeAuthForm({
  hidePassword,
  onEmailChange,
  onForgotPasswordClick,
}: MobileNativeAuthFormProps) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (window.flashError?.detail) {
      enqueueSnackbar(window.flashError.detail, {
        variant: "error",
      });
    }
  }, [window.flashError]);

  const handleEmailChange = (email: string) => {
    // a regex that replaces curly apostrophes [‘,’] with a straight apostrophe ['] for mobile logins
    const replaceCurlyApostrophes = email.replaceAll(/[‘’]/gi, "'");
    setEmail(replaceCurlyApostrophes);
    onEmailChange(replaceCurlyApostrophes);
  };

  return (
    <form
      action={`/auth/login?email=${encodeURIComponent(
        email,
      )}&password=${encodeURIComponent(
        password,
      )}&provider=password&isMobileNative=true`}
      method="POST"
    >
      <TextField
        type="text"
        id="email"
        name="email"
        label="Email"
        autoComplete="email"
        value={email}
        onChange={({ target: { value } }) => {
          handleEmailChange(value);
        }}
        variant="standard"
      />
      {hidePassword && (
        <>
          <TextField
            type="password"
            id="password"
            name="password"
            label="Password"
            autoComplete="current-password"
            value={password}
            onChange={({ target: { value } }) => {
              return setPassword(value);
            }}
            variant="standard"
          />
          <ResetPasswordButton
            aria-label="Reset Password Button"
            type="button"
            onClick={onForgotPasswordClick}
            disabled={getIsClockInaccurate()}
          >
            Forgot your password?
          </ResetPasswordButton>
          <input type="hidden" id="provider" value="password" name="provider" />
        </>
      )}
      <Button aria-label="Sign in" type="submit">
        Sign in
      </Button>
    </form>
  );
}

export default MobileNativeAuthForm;
