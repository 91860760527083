/* eslint-disable class-methods-use-this */
import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { enqueueSnackbar } from "notistack";

function withProfileSubmit(WrappedComponent) {
  class WithProfileSubmit extends Component {
    static propTypes = {
      currentUser: PropTypes.object.isRequired,
      updateUserRequest: PropTypes.func.isRequired,
    };

    errorCallback = (actions) => {
      return (errors) => {
        const validationErrors = get(
          errors,
          ["validationErrors"],
          "Something went wrong!",
        );
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        enqueueSnackbar("There was an error saving your profile", {
          variant: "error",
        });
      };
    };

    successCallback = (actions) => {
      return () => {
        actions.setSubmitting(false);
        enqueueSnackbar("Profile saved successfully!", {
          variant: "info",
        });
      };
    };

    handleSubmit = (values, actions) => {
      const { currentUser, updateUserRequest } = this.props;
      updateUserRequest(currentUser.id, values, {
        successCallback: this.successCallback(actions),
        errorCallback: this.errorCallback(actions),
      });
    };

    render() {
      return (
        <WrappedComponent {...this.props} handleSubmit={this.handleSubmit} />
      );
    }
  }

  return WithProfileSubmit;
}

export default withProfileSubmit;
