import * as EmailValidator from 'email-validator';

const getAllowedLoginMethods = async (email) => {
  if (!EmailValidator.validate(email)) {
    return [];
  }

  const emailDomain = email.split("@")[1];
  const url = `/api/v4/login_domains?domain=${emailDomain}`;
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data || [];
  } catch (error) {
    return [];
  }
};

export default getAllowedLoginMethods;
