import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import Root from "features/ContactImports/containers/ContactImportCollection/components/Root";
import withRecord from "higherOrderComponents/withRecord";

export class ContactImportCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/ContactImports/containers/ContactImportCollection",
    shape: { members: [] },
    type: "contactImportCollection",
  }),
  withConnect,
)(ContactImportCollection);
