import format from "date-fns/format";
import { capitalize } from "lodash";
import { formatInTimeZone } from "date-fns-tz";
import { SEQUENCE_STATES } from "./constants";
import { SequencesContact } from "./types";
import { SequenceStep } from "models/SequenceStep";
import { Sequence } from "models/Sequence";

const { inactive } = SEQUENCE_STATES;

/**
 * Determines whether any step in the sequence has a null absolute time and an empty relative delay.
 *
 * This variable becomes `true` if any step in `sequenceSteps` has `absoluteTime` set to `null`
 * and `relativeDelay` is an empty object. Otherwise, it will be `false`. This flow specifically
 * is used for duplicating an absolute sequence and requiring a user to re-choose send date/times.
 *
 * @param steps Sequence steps to check.
 *
 */
export const checkIfNeedsScheduling = (steps: SequenceStep[]) => {
  return steps.some((step) => {
    return (
      step.absoluteTime === null && Object.keys(step.relativeDelay).length === 0
    );
  });
};

export const checkWithinFiveMinutes = (scheduledTime: string | null) => {
  if (scheduledTime === null) {
    return false;
  }

  return new Date(scheduledTime).valueOf() - Date.now() < 5 * 60 * 1000;
};

export const formatSequenceContactRows = (items: SequencesContact[]) => {
  return items.map((item) => {
    return {
      ...item,
      currentStep: `#${item.currentStep} Auto message`,
      enrolledAt: format(new Date(item.enrolledAt), "L-d-y"),
      state: capitalize(item.state),
    };
  });
};

/**
 * Returns formatted sequence step heading string. Ex: "5. Follow-up nessage - Day 21"
 * @param sequenceStep Sequence step to format.
 * @param needsSchedule Whether or not the sequence step is an absolute duplicate and needs to be scheduled.
 */
export const getSequenceStepAccordionHeading = (
  step: SequenceStep,
  needsSchedule: boolean,
): string => {
  const initialOrFollowUp =
    step.stepNumber === 1 ? "Initial message" : "Follow-up message";
  const compoundedDayString = needsSchedule
    ? ""
    : ` - Day ${step.compoundedDay}`;
  return `${step.stepNumber}. ${initialOrFollowUp}${compoundedDayString}`;
};

/**
 * Returns formatted sequence step delay string. Ex: "1d, 8h, 30m"
 * Or a date if sequence is absolute (scheduled). Ex: "4:15pm on 12/31/2023"
 * @param step Sequence step to format.
 * @param formatType Output format for days, hours, minutes.
 */
export const getSequenceStepDelay = (
  step: SequenceStep,
  timezone: string,
  formatType: "initial" | "short" | "long" = "initial",
): string => {
  // Relative/ongoing sequence
  if (Object.keys(step.relativeDelay).length > 0) {
    const formats: any = {
      initial: {
        days: "d",
        hours: "h",
        minutes: "m",
      },
      short: {
        days: " days",
        hours: " hrs",
        minutes: " mins",
      },
      long: {
        days: " days",
        hours: " hours",
        minutes: " minutes",
      },
    };

    const delayArray: string[] = [];
    Object.entries(step.relativeDelay).forEach((s) => {
      const [delayIncrement, value] = s;
      if (value > 0) {
        let delayString = `${value}${formats[formatType][delayIncrement]}`; // ex: "1 hours"

        // Convert to singular version of format string if value is 1.
        if (value === 1 && formatType !== "initial") {
          delayString = delayString.slice(0, -1); // ex: "1 hour"
        }

        delayArray.push(delayString);
      }
    });
    return delayArray.join(", ");
  }

  // Absolute/scheduled sequence
  return formatInTimeZone(
    step.absoluteTime,
    timezone,
    "eeee, MMMM do, yyyy, h:mm a z",
  );
};

export const renderTooltipTitle = (sequence: Sequence) => {
  if (sequence.stepCount === 0) {
    return "Add step(s) to enroll contacts";
  }

  if (sequence.state === inactive) {
    return "Activate sequence to enroll contacts";
  }

  return "";
};
