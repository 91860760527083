import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";

const iconSpacing = 58;

const StyledTextField = styled(TextField)`
  flex: 1 1 100%;

  &:hover,
  &:focus-within {
    input {
      padding-left: ${iconSpacing}px;
    }
  }

  .MuiInput-formControl {
    position: static;
    height: 100%;
  }

  input {
    transition: all ease 0.333s;
    padding-left: ${iconSpacing + 5}px;
  }

  fieldset {
    border: none;
  }
`;

const StyledIconButton = styled(IconButton)`
  svg {
    font-size: 22px;
  }
`;

const CloseIconButton = styled(IconButton)`
  height: 26px;
  width: 26px;
  border: ${(props) => {
    return props.theme.mixins.border({
      color: props.theme.colors.divider,
    });
  }} !important;
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  z-index: 9;
  position: relative;

  svg {
    font-size: 14px;
    font-weight: 700;
  }
`;

function TopLevelPageSearch({
  clearHandler,
  initialSearchTerm,
  placeholder,
  searchHandler,
}) {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    searchHandler(value);
  };

  const handleClear = () => {
    setSearchTerm("");
    clearHandler("");
  };

  return (
    <Box display="flex" flexGrow={1} flexShrink={1}>
      <Box
        position="absolute"
        top="0"
        left="0"
        zIndex="2"
        height="55px"
        width={`${iconSpacing}px`}
      >
        {!searchTerm ? (
          <StyledIconButton
            aria-label="Search Button"
            data-testid="search-button"
            color="primary"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-10px, -50%)",
            }}
          >
            <SearchIcon />
          </StyledIconButton>
        ) : (
          <Tooltip title="Clear Search">
            <CloseIconButton
              aria-label="Clear Search"
              onClick={handleClear}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-10px, -50%)",
              }}
            >
              <CloseIcon />
            </CloseIconButton>
          </Tooltip>
        )}
      </Box>
      <StyledTextField
        autoFocus
        aria-label="Search"
        onChange={handleSearch}
        placeholder={placeholder}
        value={searchTerm}
      />
    </Box>
  );
}

TopLevelPageSearch.propTypes = {
  clearHandler: PropTypes.func.isRequired,
  initialSearchTerm: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  searchHandler: PropTypes.func.isRequired,
};

export default TopLevelPageSearch;
