import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import CloseIcon from "@mui/icons-material/Close";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import attachmentPreviewUrl from "utils/attachmentPreviewUrl";

import Modal from "components/Modal";
import Logo from "components/Logo";

const buttonStyle = css`
  cursor: pointer;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 50px;
  height: 50px;
  border-radius: 25px;
`;

const muiButtonStyle = css`
  position: absolute;
  top: 20px;
  background: ${(props) => {
    return rgba(props.theme.colors.background.paper, 0.7);
  }};
  border-radius: 100%;

  &:hover {
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }};
  }

  .muiIconButton {
    cursor: pointer !important;
    &:hover {
      background: inherit !important;
    }
  }
`;

const borderButtonStyle = css`
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  border: solid 3px
    ${(props) => {
      return props.theme.colors.primary.contrastText;
    }};
  border-top: none;
  border-right: none;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DisplayImage = styled.img`
  display: ${(props) => {
    return props.isOpening ? "none" : "block";
  }};
  max-width: 80vw;
  max-height: 80vh;
  user-select: none;
  border-radius: 10px;
`;

const LoadingImage = styled.img`
  display: none;
`;

const LoadingIndicator = styled.div`
  position: absolute;
  top: ${(props) => {
    return props.isOpening ? "auto" : "15px";
  }};
  left: ${(props) => {
    return props.isOpening ? "auto" : "20px";
  }};
`;

const DownloadButton = styled.div`
  ${muiButtonStyle};
  right: 74px;
`;

const CloseButton = styled.div`
  ${muiButtonStyle};
  right: 20px;
`;

const PrevButton = styled.div`
  ${buttonStyle};
  right: calc(100% + 20px);

  span {
    ${borderButtonStyle};
    transform: rotate(45deg);
    left: 3px;
  }
`;

const NextButton = styled.div`
  ${buttonStyle};
  left: calc(100% + 20px);

  span {
    ${borderButtonStyle};
    transform: rotate(-135deg);
    right: 3px;
  }
`;

class AttachmentModal extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    visible: PropTypes.bool.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
    initialAttachment: PropTypes.object,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    isOpening: true,
    isLoading: true,
    currentAttachment: undefined,
    loadingAttachment: undefined,
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.visible && this.props.visible) {
      this.setState({
        isOpening: true,
        isLoading: true,
        currentAttachment: undefined,
        loadingAttachment: this.props.initialAttachment,
      });
    }
  };

  currentIndex = () => {
    return this.props.attachments.indexOf(this.state.currentAttachment);
  };

  sizedUrl = (attachment) => {
    const width = Math.round(window.innerWidth * 0.8);
    const height = Math.round(window.innerHeight * 0.8);
    return attachmentPreviewUrl(
      attachment,
      this.props.appSettings,
      width,
      height,
    );
  };

  loadAttachment = (attachment) => {
    if (attachment.url === this.state.currentAttachment.url) {
      this.setState({ currentAttachment: attachment });
      return;
    }
    this.setState({ isLoading: true, loadingAttachment: attachment });
  };

  prevAttachment = () => {
    const { attachments } = this.props;
    const index = this.currentIndex();
    if (index > 0) {
      return attachments[index - 1];
    }
    return undefined;
  };

  nextAttachment = () => {
    const { attachments } = this.props;
    const index = this.currentIndex();
    if (index > -1 && index < attachments.length - 1) {
      return attachments[index + 1];
    }
    return undefined;
  };

  showPrevImage = () => {
    const prevAttachment = this.prevAttachment();
    if (!prevAttachment) {
      return;
    }
    this.loadAttachment(prevAttachment);
  };

  showNextImage = () => {
    const nextAttachment = this.nextAttachment();
    if (!nextAttachment) {
      return;
    }
    this.loadAttachment(nextAttachment);
  };

  handleLoadingImageLoaded = () => {
    this.setState((prevState) => {
      return {
        currentAttachment: prevState.loadingAttachment,
      };
    });
  };

  handleDisplayImageLoaded = () => {
    this.setState({ isOpening: false, isLoading: false });
  };

  renderDownloadButton = () => {
    if (this.state.isOpening) {
      return null;
    }
    return (
      <DownloadButton>
        <Tooltip title="Download">
          <IconButton
            href={this.state.currentAttachment.downloadUrl}
            className="muiIconButton"
            size="large"
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      </DownloadButton>
    );
  };

  renderCloseButton = () => {
    if (this.state.isOpening) {
      return null;
    }
    return (
      <CloseButton>
        <Tooltip title="Close">
          <IconButton
            onClick={this.props.onClose}
            className="muiIconButton"
            size="large"
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
      </CloseButton>
    );
  };

  renderPrevButton = () => {
    if (!this.prevAttachment() || this.state.isOpening) {
      return null;
    }
    return (
      <PrevButton onClick={this.showPrevImage}>
        <span />
      </PrevButton>
    );
  };

  renderNextButton = () => {
    if (!this.nextAttachment() || this.state.isOpening) {
      return null;
    }
    return (
      <NextButton onClick={this.showNextImage}>
        <span />
      </NextButton>
    );
  };

  renderLoadingIndicator = () => {
    if (!this.state.isLoading) {
      return null;
    }
    return (
      <LoadingIndicator isOpening={this.state.isOpening}>
        <Logo animate dotColor="color" color="transparent" width={50} />
      </LoadingIndicator>
    );
  };

  renderDisplayImage = () => {
    if (!this.state.currentAttachment) {
      return null;
    }
    return (
      <DisplayImage
        src={this.sizedUrl(this.state.currentAttachment)}
        isOpening={this.state.isOpening}
        onLoad={this.handleDisplayImageLoaded}
        alt=""
      />
    );
  };

  renderLoadingImage = () => {
    if (!this.state.loadingAttachment) {
      return null;
    }
    return (
      <LoadingImage
        src={this.sizedUrl(this.state.loadingAttachment)}
        onLoad={this.handleLoadingImageLoaded}
        alt=""
      />
    );
  };

  render() {
    return (
      <Modal
        transitionIn={this.props.visible}
        closeModal={this.props.onClose}
        navigateBackward={this.showPrevImage}
        navigateForward={this.showNextImage}
      >
        {this.renderLoadingImage()}
        <Wrapper
          onDoubleClick={() => {
            return false;
          }}
        >
          {this.renderDownloadButton()}
          {this.renderCloseButton()}
          {this.renderPrevButton()}
          {this.renderNextButton()}
          {this.renderDisplayImage()}
          {this.renderLoadingIndicator()}
        </Wrapper>
      </Modal>
    );
  }
}

export default AttachmentModal;
