import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";
import { Account as AccountType } from "models/Account";

import {
  Contact,
  ContactResponse,
  EnrollDrawerContact,
} from "features/Sequences/components/Drawers/EnrollContactsDrawer/types";
import { fetchSearchContacts } from "features/Sequences/api";
import { useCurrentAccount } from "hooks";

const useEnrollContactsDrawer = () => {
  const currentAccount = useCurrentAccount();

  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [contactList, setContactList] = useState<EnrollDrawerContact[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [nextPage, setNextPage] = useState<string | null>(null);
  const [totalItems, setTotalItems] = useState(0);

  const setDebouncedSearchTerm = useMemo(() => {
    return debounce((search: string) => {
      setDebouncedSearch(search);
    }, 300);
  }, []);

  const formatContacts = (members: Contact[]) => {
    return members.map((member) => {
      const { name, phones } = member;
      const { formattedPhoneNumber, id } = phones.members[0];
      const phoneId = id.replace("/contact_phones/", "");

      return {
        contact: member,
        formattedPhoneNumber,
        id: phoneId,
        name,
      };
    });
  };

  const getContacts = useCallback(
    async (account: AccountType, search: string) => {
      setIsLoading(true);
      try {
        const response = await fetchSearchContacts(account, null, search);
        const allContacts: ContactResponse = await response.json();

        const formattedContacts = formatContacts(allContacts.members);

        setContactList(formattedContacts);
        setNextPage(allContacts.view.next);
        setTotalItems(allContacts.totalItems);
      } catch (err) {
        throw new Error(
          `Error fetching contacts in EnrollContactsDrawer: ${err}`,
        );
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const handleSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchTerm(value);
      setDebouncedSearchTerm(value);
    },
    [setDebouncedSearchTerm],
  );

  const handleClear = () => {
    setSearchTerm("");
    setDebouncedSearch("");
    setContactList([]);
    setNextPage(null);
  };

  const fetchNextRow = async (account: AccountType, next: string | null) => {
    if (nextPage) {
      setIsLoadingNextPage(true);
      try {
        const response = await fetchSearchContacts(account, next);
        const nextPageContacts: ContactResponse = await response.json();

        const formattedNextPageContacts = formatContacts(
          nextPageContacts.members,
        );

        setContactList([...contactList, ...formattedNextPageContacts]);
        setNextPage(nextPageContacts.view.next);
      } catch (err) {
        throw new Error(`Error fetching next row: ${err}`);
      } finally {
        setIsLoadingNextPage(false);
      }
    }
  };

  return {
    contactList,
    currentAccount,
    debouncedSearch,
    isLoading,
    isLoadingNextPage,
    nextPage,
    searchTerm,
    totalItems,
    fetchNextRow,
    getContacts,
    handleClear,
    handleSearch,
  };
};

export { useEnrollContactsDrawer };
