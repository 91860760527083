import { NavLink } from "react-router-dom";
import styled from "styled-components";

export default styled(NavLink)`
  align-items: center;
  background: none;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-weight: 600;
  font-size: ${(props) => {
    return props.theme.fonts.primaryListItemFontSize;
  }};
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  text-decoration: none;

  &.active,
  &:hover {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
  }
`;

export const SectionHeaderLink = styled(NavLink)`
  align-items: center;
  background: none;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.primaryListItemFontSize;
  }};
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  text-decoration: none;

  &.active {
    font-weight: 600;
  }

  &:hover {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
  }
`;

export const SettingsLink = styled(NavLink)`
  align-items: center;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.primaryListItemFontSize;
  }};
  display: flex;
  padding: 12px 50px;
  text-decoration: none;

  &.active,
  &:hover {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
  }
`;
