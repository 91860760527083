export default [
  {
    type: "test_message",
    icon: "📲",
    title: "Send yourself a test message",
    subTitle: "Let's confirm that your account is working properly",
  },
  {
    type: "set_your_avatar",
    icon: "📸",
    title: "Upload a profile picture",
    subTitle:
      "Bring a little personality and help your colleagues identify you easily!",
  },
  {
    type: "update_account_settings",
    icon: "⚙",
    title: "Account settings",
    subTitle:
      "Setup your office hours, MMS preferences, intro messages and more!",
  },
];
