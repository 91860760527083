export const putSignedUrl = async (
  url: string,
  file: File,
): Promise<Response> => {
  const request = new Request(url, {
    method: "PUT",
    body: file,
  });

  return fetch(request);
};
