import PropTypes from "prop-types";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

function MenuIcon({ isOpen }) {
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      alignItems="baseline"
      height="24px"
      width="24px"
    >
      <Tooltip title={isOpen ? "Close menu" : "Open menu"}>
        <MenuOpenIcon
          data-testid="menu-icon"
          fontSize="small"
          color="primary"
          style={{ transform: isOpen ? "" : "scaleX(-1)" }}
        />
      </Tooltip>
    </Box>
  );
}

export default MenuIcon;

MenuIcon.propTypes = {
  isOpen: PropTypes.bool,
};
