import { textUsApiV3 } from "services";

function fetchGroup(
  groupId: `/groups/${string}`,
  groupQuery?: { excludeContacts?: boolean },
  options?: RequestInit,
) {
  return textUsApiV3.get(groupId, groupQuery, options);
}

export { fetchGroup };
