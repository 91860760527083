import { textUsApiV3 } from "services";

export type UsersQuery = {
  q?: string;
  orgAdmin?: boolean;
  accountSlug?: string;
};

const keyMap = {
  q: "q",
  orgAdmin: "org_admin",
  accountSlug: "accounts[]slug",
};

export function fetchUsers(query?: UsersQuery, options?: RequestInit) {
  return textUsApiV3.get(
    "/users",
    query
      ? Object.fromEntries(
          Object.entries(query).map(([key, value]) => {
            return [keyMap[key as keyof UsersQuery], value];
          }),
        )
      : undefined,
    options,
  );
}
