import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import Root from "./components/Root";
import { actionGenerators, saga, reducer, selectors } from "./state";
import { selectAppColors } from "features/EntryPoint/containers/App/selectors";
import { updateAppColors } from "features/EntryPoint/containers/App/actions";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import withAccountSettingsSubmit from "higherOrderComponents/withAccountSettingsSubmit";

class WhiteLabel extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    appColors: selectAppColors(state, props),
    whiteLabelContainer: selectors.selectWhiteLabelContainer(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  updateAppColors,
});

const withReducer = injectReducer({
  key: "WhiteLabelContainer",
  reducer,
});
const withSaga = injectSaga({ key: "WhiteLabelContainer", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withAccountSettingsSubmit,
)(WhiteLabel);
