import styled from "styled-components";
import breakpoints from "utils/styles/breakpoints";
import H3 from "components/H3";

export default styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  padding-bottom: 20px;
  margin: 55px 0 10px;
  flex: 0 0 auto;
  height: auto;

  ${H3} {
    margin: 0 0 0 20px;
    font-size: 33px;
    font-weight: 400;
    color: ${(props) => {
      return props.theme.colors.navigation.hoverColor;
    }};
  }

  &.transition-appear {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
  }

  &.transition-appear-active {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transition: all cubic-bezier(0.6, -0.28, 0.735, 0.045)
      ${(props) => {
        return props.animationDuration;
      }}ms;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    margin: calc(20px + env(safe-area-inset-top) / 2) 0 0 0;

    ${H3} {
      color: ${(props) => {
        return props.theme.colors.primary.main;
      }};
      margin: 0 0 0 12px;
      font-size: 22px;
    }
  }
`;
