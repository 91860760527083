import { all, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";
import { push } from "connected-react-router";

import {
  selectCurrentAccount,
  selectRecords,
} from "features/EntryPoint/containers/App/selectors";
import { updateCurrentAccount } from "features/EntryPoint/containers/App/actions";
import * as schema from "schema";
import deepEqualSelector from "utils/deepEqualSelector";
import denormalizeWithShape from "utils/denormalizeWithShape";
import generateContainerState from "utils/generateContainerState";

const {
  actionTypes: accountNavbarActionTypes,
  actionGenerators: accountNavbarActionGenerators,
  sagas,
  reducers,
  selectors: accountNavbarSelectors,
} = generateContainerState([
  {
    container: "AccountNavbar",
    crudActions: ["fetch"],
    idPropsVar: "accountNavbarId",
    recordType: "accountNavbar",
    schema: schema.accountNavbar,
    shape: {
      members: [
        {
          account: {},
          badges: { members: [] },
          siteNavigation: { members: [{ badgeRecord: {} }] },
        },
      ],
    },
  },
]);

// ACTION TYPES
const actionTypes = {
  ...accountNavbarActionTypes,
  SET_CURRENT_ACCOUNT: "app/AccountNavbar/SET_CURRENT_ACCOUNT",
};

// ACTION GENERATORS
export const setCurrentAccount = (
  currentAccountId: string,
  { pathname }: { pathname?: string } = {},
): {
  type: "app/AccountNavbar/SET_CURRENT_ACCOUNT";
  currentAccountId: string;
  pathname: string | undefined;
} => {
  return {
    type: actionTypes.SET_CURRENT_ACCOUNT,
    currentAccountId,
    pathname,
  };
};
const actionGenerators = {
  ...accountNavbarActionGenerators,
  setCurrentAccount,
};

// SAGAS
function* setCurrentAccountSaga({ currentAccountId, pathname }) {
  yield put(updateCurrentAccount(currentAccountId));
  const currentAccount = yield select(selectCurrentAccount);

  if (!pathname || !(pathname ?? "").startsWith("/c/")) {
    const newPathname = pathname || `/${currentAccount.slug}`;
    yield put(push({ pathname: newPathname }));
  }
}

function* saga() {
  yield all([
    takeLatest(
      actionTypes.FETCH_ACCOUNT_NAVBAR_REQUEST,
      sagas.fetchAccountNavbarRequest,
    ),
    takeLatest(actionTypes.SET_CURRENT_ACCOUNT, setCurrentAccountSaga),
  ]);
}

// REDUCERS
const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ACCOUNT_NAVBAR_REQUEST:
      return true;
    case actionTypes.FETCH_ACCOUNT_NAVBAR_SUCCESS:
      return false;
    case actionTypes.FETCH_ACCOUNT_NAVBAR_FAILURE:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({ ...reducers, isFetching });

// SELECTORS
const selectCurrentAccountNavbarElementId = createSelector(
  selectCurrentAccount,
  (account) => {
    return `/navbar/${account.slug}`;
  },
);

export const selectCurrentAccountNavbarElement = deepEqualSelector(
  createSelector(
    selectRecords,
    selectCurrentAccountNavbarElementId,
    (records, id) => {
      return records.get(id)
        ? denormalizeWithShape({
            id,
            records: records.toJS(),
            shape: {
              badges: { members: [] },
              siteNavigation: { members: [{ badgeRecord: {} }] },
            },
          })
        : {};
    },
  ),
);

const selectors = {
  ...accountNavbarSelectors,
  selectCurrentAccountNavbarElement,
};

export { actionGenerators, saga, reducer, selectors };
