import { Component } from "react";
import styled from "styled-components";
import Logo from "components/Logo";
import H3 from "components/H3";

const Root = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter();
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  z-index: 2;

  div {
    ${(props) => {
      return props.theme.mixins.flexCenter();
    }};
    flex-flow: column nowrap;
  }
`;

class ConversationPreviewLoading extends Component {
  render() {
    return (
      <Root>
        <div>
          <Logo animate color="transparent" dotColor="primary" />
          <H3>Loading</H3>
        </div>
      </Root>
    );
  }
}

export default ConversationPreviewLoading;
