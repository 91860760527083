import { SvgIcon, SvgIconProps, useTheme } from "@mui/material";
import ICONS from "constants/icons";

function IntegrationIcon(props: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon {...props} width="1.5rem" height="1.5rem" viewBox="0 0 24 24">
      <path d={ICONS.INTEGRATION_LINKED} fill={theme.palette.secondary.main} />
    </SvgIcon>
  );
}

export default IntegrationIcon;
