import { Subscriber } from "./types";

/**
 * Dispatch actions
 *
 * Listen in on promises
 */
export const createDispatcher = <
  Events extends Readonly<
    Record<string, (...params: any[]) => Promise<Response>>
  >,
>(
  events: Events,
) => {
  const eventMap = new Map<
    keyof Events,
    Set<
      Subscriber<
        [
          promise: Promise<Response>,
          ...params: Parameters<Events[keyof Events]>,
        ]
      >
    >
  >();

  /**
   * Dispatcher
   */
  return {
    /**
     * Subscribe
     * @param eventName - unique event name
     * @param subscriber - callback when event occurs
     */
    subscribe: <EventName extends keyof Events>(
      eventName: EventName,
      subscriber: Subscriber<
        [promise: Promise<Response>, ...params: Parameters<Events[EventName]>]
      >,
    ) => {
      const subscribers = eventMap.get(eventName) ?? new Set();

      subscribers.add(subscriber);

      eventMap.set(eventName, subscribers);

      /**
       * Unsubscribe
       */
      return () => {
        subscribers.delete(subscriber);

        if (eventMap.get(eventName)?.size === 0) {
          eventMap.delete(eventName);
        }
      };
    },
    /**
     * Dispatch events
     */
    dispatch: <EventName extends keyof Events>(
      eventName: EventName,
      ...params: Parameters<Events[EventName]>
    ) => {
      const event = events[eventName];

      const subscribers = eventMap.get(eventName);

      const promise = event(...params);

      /**
       * Produce
       */
      subscribers?.forEach((subscriber) => {
        subscriber(promise, ...params);
      });
    },
  };
};
