/* eslint-disable no-param-reassign */
import * as Yup from "yup";
const phoneUtil =
  require("google-libphonenumber").PhoneNumberUtil.getInstance();
const countryCode = window.countryCode || "US";

const getYupValue = (type) => {
  switch (type) {
    case "calendar_day":
      return Yup.array();
    case "email":
      return Yup.string().email("Please enter a valid email");
    case "number":
      return Yup.string().test(
        "isNum",
        "Please enter a valid number",
        (val) => {
          return val.replace(/[0-9.]/g, "").length === 0;
        },
      );
    case "url":
      return Yup.string().url("Please enter a full, valid URL");
    case "telephone":
      return Yup.string().test(
        "isPhoneNumber",
        "Please enter a valid phone number",
        (val) => {
          if (!val) return true;
          const phoneRegex = /[+0-9. \-()]{3,}/gm;
          const cleanedVal = val.match(phoneRegex);
          return (
            /\d{3,}/.test(cleanedVal) &&
            phoneUtil.isValidNumber(phoneUtil.parse(val, countryCode))
          );
        },
      );
    default:
      return Yup.string().max(70, "Maximum of 70 characters");
  }
};

export default (values) => {
  const innerObject = values.reduce((schema, value) => {
    const { title, type } = value;
    if (!schema[title]) {
      schema[title] = Yup.object().shape({
        id: Yup.string(),
        value: getYupValue(type),
      });
    }
    return schema;
  }, {});
  return Yup.object(innerObject);
};
