import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { Props } from "./types";
import Logo from "components/Logo";
import H3 from "components/H3";
import { operator, useOpener } from "utils/popup";

const Root = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 999;
`;

const Wrapper = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  flex-flow: column;

  ${H3} {
    margin: 10px 0;
  }

  img {
    margin: 40px 0;
    width: 380px;
  }
`;

/**
 * Handle OAuth callback
 *
 * Inside an oauth popup, after authorization is complete,
 * and the oauth provider redirects back to our provided redirect_uri.
 *
 * @returns {ReactElement}
 */
export function ProviderCallback<T>({
  channel,
  strategy,
}: Props<T>): ReactElement {
  const { opener, error } = useOpener();

  useEffect(() => {
    if (!error) {
      opener.dispatchEvent(operator<T>(channel, strategy));
    }
  }, [channel, error, opener, strategy]);

  return (
    <Root>
      {(() => {
        if (error) {
          return (
            <Wrapper>
              <H3>Failed to Authorized</H3>
            </Wrapper>
          );
        }

        return <Logo animate color="transparent" dotColor="primary" />;
      })()}
    </Root>
  );
}
