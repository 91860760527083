import { useCallback } from "react";

const replaceValueInTree = (tree, { view, members, id }) => {
  if (id.includes(tree.id)) {
    /* eslint-disable no-param-reassign */
    tree.children.members = members;
    tree.children.view = view;
    /* eslint-enable no-param-reassign */
  } else {
    tree.children.members.forEach((child) => {
      return replaceValueInTree(child, { view, members, id });
    });
  }
};

/* The treeWalker is a generator function that is called by computeTree
  inside of the react-vtree component to build up the account tree. It is
  called whenever the treeWalker changes or recomputeTree is triggered. When a
  value is consumed by calling the generator's next method, the treeWalker
  executes until it encounters the yield keyword. As long as there are still
  "open" nodes in the tree, computeTree will continue to build it up. To
  prevent it from creating unneeded objects, the computeTree function simply
  uses the nodes already in memory unless the refresh param is set to true. */
const useTreeWalker = (
  primaryNodes,
  fetchAccountCollectionRequest,
  nameOnly,
  brandInformation,
  setCurrentAccount,
  setPrimaryNodes,
  fetchAccountHierarchyRequest,
  accountHierarchyId,
) => {
  return useCallback(
    // eslint-disable-next-line func-names
    function* (refresh) {
      // Remember all the necessary data of the primary node in the stack.
      const stack = primaryNodes.map((node) => {
        return {
          nestingLevel: 0,
          node,
        };
      });

      const injectedProps = {
        fetchAccountCollectionRequest: (id) => {
          return fetchAccountCollectionRequest(id, null, {
            successCallback: (newValues) => {
              const newNodes = [...primaryNodes];
              newNodes.forEach((node) => {
                return replaceValueInTree(node, newValues);
              });
              setPrimaryNodes(newNodes);
            },
          });
        },
        nameOnly,
        brandInformation,
        setCurrentAccount,
        fetchAccountHierarchyRequest: (id) => {
          return fetchAccountHierarchyRequest(id, null, {
            successCallback: (newValues) => {
              const newNodes = [...primaryNodes];
              newNodes.forEach((node) => {
                return replaceValueInTree(node, newValues);
              });
              setPrimaryNodes(newNodes);
            },
          });
        },
        accountHierarchyId,
      };

      // Walk through the tree until we have no nodes available.
      while (stack.length !== 0) {
        const { node, nestingLevel, parentRole } = stack.pop();
        const adjustedNode = node.effectiveRole
          ? node
          : { ...node, effectiveRole: parentRole };
        const { children, id, effectiveRole } = adjustedNode;
        const childNodes = children?.members ?? [];

        /* This sends information about the node to the computeTree function,
    which then returns whether or not that node is open. The `refresh`
    parameter is used to determine whether or not the node is completely new
    or different and therefore needs to get added or updated by the
    computeTree function. */
        const isOpened = yield refresh
          ? {
              isOpenByDefault: childNodes.length > 0,
              nestingLevel,
              ...injectedProps,
              ...adjustedNode,
            }
          : id;

        /* If the current node is open and it has children, then they need to be
    added to the stack in order for computeTree to add them to the displayed
    tree. */
        if (node?.children?.view?.next) {
          const { children: childInfo } = node;

          stack.push({
            nestingLevel: nestingLevel + 1,
            node: {
              type: "load_more",
              nextPage: childInfo?.view?.next,
              ...injectedProps,
            },
            parentRole: effectiveRole,
          });
        }

        if (childNodes.length !== 0 && isOpened) {
          /* The nodes that are going to be rendered first need to be added to
      the end of the stack */
          // eslint-disable-next-line no-plusplus
          for (let i = childNodes.length - 1; i >= 0; i--) {
            stack.push({
              nestingLevel: nestingLevel + 1,
              node: childNodes[i],
              parentRole: effectiveRole,
            });
          }
        }
      }
    },
    [
      primaryNodes,
      nameOnly,
      brandInformation,
      setCurrentAccount,
      fetchAccountCollectionRequest,
      setPrimaryNodes,
      fetchAccountHierarchyRequest,
      accountHierarchyId,
    ],
  );
};

export default useTreeWalker;
