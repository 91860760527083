import assert from "assert";
import { userManager } from "@tesseract/core";
import { SequenceValues } from "../hooks/useSequences/types";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const duplicateSequence = async (
  currentAccount: AccountType,
  sequenceId: string,
  body: SequenceValues,
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequences/${sequenceId}/duplicate`;

  const request = new Request(url, {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: body.sequenceName,
      scheduleType: body.scheduleType,
      finishedOnReply: body.finishedOnReply === "true", // converts to boolean for BE
    }),
  });

  return fetch(request);
};
