import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { actionGenerators } from "./state";

const useTesseractAuthReady = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionGenerators.sendTesseractAuthReadyMessage());
  }, [dispatch]);
};

export default useTesseractAuthReady;
