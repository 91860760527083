import { Account } from "@tesseract/core";
import { addMinutes, isAfter, startOfDay } from "date-fns";

export const getCutoffError = ({ settings }: Account.Raw, date: Date) => {
  const cutoffTimeEnabled = settings.campaignDeliveryCutoffTimeEnabled?.value;
  const campaignCutoffTimeMinutes = settings?.campaignDeliveryCutoffTime?.value;

  if (!cutoffTimeEnabled || !campaignCutoffTimeMinutes) return false;

  const campaignDeliveryCutoffTime = addMinutes(
    startOfDay(date),
    campaignCutoffTimeMinutes,
  );

  return isAfter(date, campaignDeliveryCutoffTime);
};
