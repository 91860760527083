import { useEffect } from "react";

const ONLINE = "online";
const OFFLINE = "offline";

const useReconnectEffect = (onReConnect, onDisconnect) => {
  useEffect(() => {
    const handleConnectivityChange = ({ type }) => {
      if (type === ONLINE) {
        onReConnect();
      } else {
        onDisconnect();
      }
    };

    window.addEventListener(ONLINE, handleConnectivityChange);
    window.addEventListener(OFFLINE, handleConnectivityChange);

    return () => {
      window.removeEventListener(ONLINE, handleConnectivityChange);
      window.removeEventListener(OFFLINE, handleConnectivityChange);
    };
  }, [onReConnect, onDisconnect]);
};

export default useReconnectEffect;
