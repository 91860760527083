import { compose } from "redux";
import {
  Box,
  Button,
  IconButton,
  Theme,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { CampaignOverviewCollection } from "../CampaignOverviewCollection";
import { CampaignFilterProps } from "./types";
import withRecord from "higherOrderComponents/withRecord";
import { campaignFilterSlugs } from "features/Campaigns/constants";
import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import { useCampaignExport } from "features/Campaigns/hooks";
import { CloudDownloadIcon } from "icons";

function CampaignFilter({
  campaignFilter,
  currentAccount,
  toggleSidebar,
}: CampaignFilterProps) {
  // ==== FIELDS ==== //
  const baseId = getNestedId(campaignFilter, "campaigns");
  const { slug, title } = campaignFilter;

  // ==== HOOKS ==== //
  const { isLoading, handleExport } = useCampaignExport(campaignFilter);

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // ==== RENDER ==== //
  return (
    <Box
      display="flex"
      flexDirection="column"
      fontSize="14px"
      height="100%"
      margin="0 auto"
      position="relative"
      color="text.primary"
    >
      <PageHeader title={title} toggleSidebar={toggleSidebar}>
        <Box flex="0 0 auto" pr="10px">
          {mobileScreen ? (
            <Tooltip title="Export data">
              <IconButton onClick={handleExport}>
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Button
              data-test="ExportCampaignFilterButton"
              color="primary"
              onClick={handleExport}
              variant="outlined"
            >
              <Loader isLoading={isLoading}>Export Data</Loader>
            </Button>
          )}
        </Box>
      </PageHeader>
      <Box
        display="flex"
        flex="1 1 auto"
        flexDirection="column"
        position="relative"
        sx={{ overflowX: "auto" }}
      >
        <Box aria-label={title}>
          <CampaignOverviewCollection
            campaignFilter={campaignFilter}
            ariaLabel="ariaLabel"
            campaignCollectionId={`${currentAccount.campaignFilters}/${slug}/campaigns`}
            currentAccount={currentAccount}
            displayCampaignType
            emptyState="No campaigns scheduled"
            header={title}
            showAvatar={slug !== campaignFilterSlugs.myScheduled}
            key={slug}
            sendVerbiage="Sending"
          />
        </Box>
      </Box>
    </Box>
  );
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "Campaigns/containers/CampaignFilter",
    shape: { campaigns: {} },
    type: "campaignFilter",
  }),
)(CampaignFilter);
