import { Color } from "@mui/material";

export const orange: Color = {
  50: "#FFF3E0",
  100: "#FFE0B2",
  200: "#FFCC80",
  300: "#FFB74D",
  400: "#FFA726",
  500: "#FF9800",
  600: "#FB8C00",
  700: "#F57C00",
  800: "#EF6C00",
  900: "#E65100",
  A100: "#FFD180",
  A200: "#FFAB40",
  A400: "#FF9100",
  A700: "#FF6D00",
};
