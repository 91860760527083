import { BadgeProps, Badge } from "@mui/material";
import styled from "styled-components";

export const StyledBadge = styled((props: BadgeProps) => {
  return <Badge variant="dot" {...props} />;
})({
  "& .MuiBadge-badge": {
    marginTop: "0.75rem",
    "&:not(.MuiBadge-colorSecondary)": {
      backgroundColor: "rgba(0, 0, 0, 0.26)",
    },
  },
});
