import { useState } from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import {
  AccountOption,
  AnalyticReport,
  EditAnalyticsReportModalProps,
  ReportMember,
} from "../models/AnalyticsModels";
import { formatValues } from "../utils/FormatDate";
import H3 from "components/H3";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

import createUUID from "utils/uuid";

const style = {
  display: "flex",
  flex: "0 0 auto",
};

const paragraphStyle = {
  fontSize: ".8rem",
  margin: ".25rem 0",
};

function EditAnalyticsReportModal({
  accountList,
  title,
  existingReports,
  currentAccount,
  fullScreen,
  deleteReport,
  editReport,
  handleEditModalToggle,
  open,
}: EditAnalyticsReportModalProps) {
  const [challengeScreen, setChallengeScreen] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<AnalyticReport>();

  const { name: currentAccountName } = currentAccount ?? {};

  const renderChallengeScreen = (report: AnalyticReport) => {
    setChallengeScreen(true);
    setSelectedReport(report);
  };
  return (
    <Modal
      aria-label={`Edit a ${title} Report Scheduling Modal`}
      closeModal={handleEditModalToggle}
      fullScreen={fullScreen}
      transitionIn={open}
    >
      <ModalHeader closeModal={handleEditModalToggle}>{`${
        challengeScreen ? "Delete" : "Edit"
      } a ${title} Report`}</ModalHeader>
      <ModalContent maxWidth="100%">
        {challengeScreen ? (
          <Box minWidth="400px">
            <IconButton
              onClick={() => {
                return setChallengeScreen(false);
              }}
              aria-label={`Navigate to Scheduled ${title} Reports`}
              style={{ padding: 0 }}
              size="large"
            >
              <ArrowBackIcon color="primary" style={style} />
            </IconButton>
            <Typography color="primary" style={{ textAlign: "center" }}>
              Are you sure you want to delete this report?
            </Typography>
            <Box
              display="flex"
              flex="0 0 auto"
              justifyContent="flex-end"
              mt={{ xs: "0px", sm: "40px" }}
            >
              <Button
                aria-label="Cancel"
                color="primary"
                onClick={() => {
                  return setChallengeScreen(false);
                }}
                style={{ marginRight: ".5rem" }}
              >
                Cancel
              </Button>
              <Button
                aria-label="Delete"
                color="primary"
                onClick={() => {
                  if (selectedReport) {
                    deleteReport(selectedReport);
                  }
                  setChallengeScreen(false);
                  return;
                }}
                type="submit"
                variant="contained"
              >
                Delete
              </Button>
            </Box>
          </Box>
        ) : (
          <Box minWidth="400px">
            {existingReports?.length &&
              existingReports.map((report: ReportMember) => {
                const { descendants = true, schedule, timePeriod } = report;
                const { name } =
                  accountList.find((account: AccountOption) => {
                    return account.descendants === descendants;
                  }) ?? {};
                const {
                  formattedTimePeriod,
                  formattedSendFrequency,
                  formattedDayToSend,
                } = formatValues(schedule, timePeriod);
                return (
                  <Box
                    key={createUUID()}
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box my={2}>
                      <H3>
                        {currentAccountName}: {name}
                      </H3>
                      <Box display="flex">
                        <Typography color="primary" style={paragraphStyle}>
                          <b>Frequency:</b> {formattedSendFrequency}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography color="primary" style={paragraphStyle}>
                          <b>Time Period:</b> {formattedTimePeriod}
                        </Typography>
                      </Box>
                      <Box display="flex">
                        <Typography color="primary" style={paragraphStyle}>
                          <b>Day to Send: </b>
                          {formattedDayToSend}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => {
                          return editReport(report);
                        }}
                        aria-label={`Edit ${title} Report Settings`}
                        size="large"
                      >
                        <EditIcon color="primary" style={style} />
                      </IconButton>
                      <IconButton
                        onClick={() => {
                          return renderChallengeScreen(report);
                        }}
                        aria-label={`Delete ${title} Report`}
                        size="large"
                      >
                        <DeleteOutlineIcon color="primary" style={style} />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
}

export default EditAnalyticsReportModal;
