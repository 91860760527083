import PropTypes from "prop-types";

import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import H3 from "components/H3";

function ReportHeader({ openScheduleModal }) {
  return (
    <Box display="flex" justifyContent="space-between" mb={4}>
      <Box>
        <H3 padding>Scheduled Reports</H3>
        <Box mt={0.5}>
          <Typography>Edit and Delete Scheduled Reports</Typography>
        </Box>
      </Box>
      <Box flex="0 0 auto">
        <Button
          color="primary"
          onClick={openScheduleModal}
          variant="contained"
          aria-label="Add Report"
        >
          <Box paddingRight="5px">
            <AddIcon />
          </Box>
          Add Report
        </Button>
      </Box>
    </Box>
  );
}

ReportHeader.propTypes = {
  openScheduleModal: PropTypes.func.isRequired,
};

export default ReportHeader;
