import TextField from "@mui/material/TextField";
import { useField } from "formik";
import type { FieldValidator } from "formik";
import { ChangeEvent, useCallback, useMemo } from "react";
import { Props } from "./types";

export function Input({
  required,
  name,
  label,
  validate: validateProp,
  ...props
}: Props) {
  const validate: FieldValidator | undefined = useMemo(() => {
    return required
      ? (value) => {
          if (!value) {
            return required === true ? "required" : required;
          }
          return validateProp ? validateProp(value) : undefined;
        }
      : undefined;
  }, [required, validateProp]);

  const [{ value, ...field }, { error, touched }, { setValue }] = useField<
    string | undefined
  >({
    name,
    validate,
  });

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value === "" ? undefined : event.target.value);
    },
    [setValue],
  );

  return (
    <TextField
      {...{
        ...field,
        error: touched && Boolean(error),
        label,
        helperText: touched && error ? error : " ",
        onChange: handleOnChange,
        required: Boolean(required),
        type: "text",
        value: value ?? "",
        ...props,
      }}
    />
  );
}
