import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import PageHeader from "components/Page/PageHeader";

function PrivacyPolicy({ showSidebar, toggleSidebar }) {
  return (
    <Box
      aria-label="Privacy Policy"
      height="100%"
      display="flex"
      flexDirection="column"
      flex="0 0 auto"
    >
      <PageHeader
        aria-label="Privacy Policy Header"
        title="Privacy Policy"
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
      />
      <Box p={{ xs: 2, sm: 4 }} flex="1 1 auto" overflow="auto">
        <Box
          aria-label="Privacy Policy Content"
          margin="0 auto"
          maxWidth="960px"
        >
          <Typography variant="h4" align="center" gutterBottom>
            Text Us Services, Inc.
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            – PRIVACY POLICY –
          </Typography>
          <Typography variant="body1" gutterBottom>
            Text Us Services, Inc. (<b>“Company“</b>, <b>“we”</b>, <b>“us”</b>,
            or <b>“our”</b>) has prepared this Privacy Policy to describe what
            information we collect, why we collect it, and what we do with it.
            It applies to the information we collect from users of our website
            located at https://www.textus.com/ (the <b>“Site“</b>), our
            Application Program Interfaces (<b>“API“</b>), our downloadable
            software applications (to include mobile applications) (each, an{" "}
            <b>“Application“</b>), and online services available therein
            (collectively the <b>“Services“</b>).
          </Typography>
          <Typography variant="h6" gutterBottom>
            1. Information We Collect
          </Typography>
          <Typography variant="body1" gutterBottom>
            We collect information when you use our Services, contact us, use
            our products, create an account, or interact with us for any other
            purpose.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may collect the following personal information from you from our
            Services:
          </Typography>
          <ul style={{ fontSize: "14px" }}>
            <li>
              Contact information such as name, email address, mailing address,
              and phone number;
            </li>
            <li>
              Billing information such as payment card number and billing
              address;
            </li>
            <li>
              Unique identifiers such as user name, account number, and
              password;
            </li>
            <li>Preference information such as marketing preferences;</li>
            <li>
              Account information including information entered in website forms
              or when creating or using accounts;
            </li>
            <li>Information associated with your inquiries to us;</li>
            <li>
              Information associated with your web transactions with us, or our
              affiliates such as information (via “pixel tags”) about how emails
              we have sent have been accessed. Third party advertisers or data
              analytics providers also gather similar information and/or match
              this information to that from other sources;
            </li>
            <li>
              Basic data passed during text message transmission (including, but
              not limited to, message content, photos, files, and message
              count/volume);
            </li>
            <li>
              Mobile device information including mobile device identification,
              service provider, and location;
            </li>
            <li>
              Information about your mobile device, use of our app, or app
              performance;
            </li>
            <li>
              Information or feedback provided through e-mail, voice, letter, or
              online posting;
            </li>
            <li>
              Data (including phone number, transmission, and message data) from
              general text messaging users who send messages to users of our
              Services.
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            We collect other information relating to your interactions with our
            Services including:
          </Typography>
          <ul style={{ fontSize: "14px" }}>
            <li>
              Clicking, browsing, and use data/patterns (including, but not
              limited to, information gathered through “cookies” and “pixel
              tags”); and
            </li>
            <li>
              Information about your IP address, browser, internet service
              provider, pages, operating system, date/time stamps, and
              clickstream data.
            </li>
          </ul>
          <Typography variant="h6" gutterBottom>
            2. How We Use Your Information
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may use your information for any lawful purpose, including the
            following:
          </Typography>
          <ul style={{ fontSize: "14px" }}>
            <li>To create and maintain your account;</li>
            <li>
              To process and fulfill your orders, including to send you emails
              to confirm your order status, and to provide other related
              communications;
            </li>
            <li>To respond to your inquiries or support tickets;</li>
            <li>
              To provide products and services you request, including to provide
              publications to which you subscribe;
            </li>
            <li>
              To operate, personalize, and improve the Services, including for
              analytics purposes;
            </li>
            <li>
              To send TextUs promotions and marketing materials that may be of
              interest to you;
            </li>
            <li>
              To contact you for phone number verification,
              enrollment/activation activities, encouraging or enabling your use
              of the Services including, but not limited to, specific features,
              to address possible inappropriate use, or at our Company’s
              discretion;
            </li>
            <li>
              For our other business purposes, such as research, payment
              processing, and operational purposes;
            </li>
            <li>
              To comply with the law and to maintain the security of our
              Services;
            </li>
            <li>
              With your consent, or as otherwise disclosed at the time
              information is collected.
            </li>
          </ul>
          <Typography variant="h6" gutterBottom>
            3. Information Sharing
          </Typography>
          <Typography variant="body1" gutterBottom>
            Text Us will share your information with other parties in the ways
            that are described in this Privacy Policy, including the following:
          </Typography>
          <ul style={{ fontSize: "14px" }}>
            <li>
              With our affiliate vendors, consultants, agents, contractors, and
              other service providers that we use to support our business;
            </li>
            <li>To operate, maintain, and improve our Services;</li>
            <li>To fulfill your orders;</li>
            <li>
              With third party vendors who process data on our behalf such as
              payment processors, customer service providers, shipment vendors,
              and email service providers;
            </li>
            <li>To enable message transmission;</li>
            <li>To conduct quality assurance testing or audits;</li>
            <li>For our own, or our Partner’s, marketing purposes;</li>
            <li>To facilitate enrollment and creation of accounts;</li>
            <li>To provide technical support;</li>
            <li>To facilitate data storage;</li>
            <li>
              To manage sales accounts and/or to provide other services to your
              Company;
            </li>
            <li>
              With third parties designated by you. When you use the Services,
              the personal information you provide will be shared with the third
              parties that you designate to receive it (for example, the person
              you are texting);
            </li>
            <li>
              As part of a corporate sale, merger, or acquisition, or other
              transfer of all or part of our assets;
            </li>
            <li>
              Pursuant to a subpoena, court order, governmental inquiry, or
              other legal process or as otherwise required by law, or to protect
              our rights or the rights of third parties;
            </li>
            <li>
              To protect or defend the rights or property of Company or users of
              the Services;
            </li>
            <li>
              To investigate or assist in preventing any violation or potential
              violation of the law, this Privacy Policy, or our Terms of Use;
              and
            </li>
          </ul>
          <Typography variant="body1" gutterBottom>
            With your consent or as otherwise disclosed at the time of data
            collection or sharing.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may work with third parties that collect data about your use of
            the Services and other sites or apps over time for non-advertising
            purposes. TextUs uses Google Analytics and other third-party
            services to improve the performance of the Site and Services and for
            analytics and marketing purposes. For more information about how
            Google Analytics collects and uses data when you use our Site and
            Services, visit https://www.google.com/policies/privacy/partners/,
            and to opt out of Google Analytics, visit
            https://tools.google.com/dlpage/gaoptout/.
          </Typography>
          <Typography variant="body1" gutterBottom>
            We may create anonymous or aggregated data records from personal
            information by excluding information that makes the data personally
            identifiable to you or your business. We reserve the right to use
            anonymous data for any purpose and disclose anonymous data to third
            parties in our sole discretion.
          </Typography>
          <Typography variant="h6" gutterBottom>
            4. Third-Party Links and Tools
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our Services may provide links to third-party websites or apps. We
            do not control the privacy practices of those websites or apps, and
            they are not covered by this privacy notice. You should review the
            privacy notices of other websites or apps that you use to learn
            about their data practices.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Our Services may also include integrated social media tools or
            “plug-ins,” such as social networking tools offered by third
            parties. If you use these tools to share personal information or you
            otherwise interact with these features on the Services, those third
            parties may collect information about you and may use and share such
            information in accordance with your account settings, including by
            sharing such information with the general public. Your interactions
            with third parties and your use of their features are governed by
            the privacy notices of the companies that provide those features. We
            encourage you to carefully read the privacy notices of any accounts
            you create and use.
          </Typography>
          <Typography variant="h6" gutterBottom>
            5. Your Choices Regarding Your Information.
          </Typography>
          <Typography variant="body1" gutterBottom>
            You have several choices regarding use of information on our
            Services:
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>(a) Email Communications.</b> You may indicate a preference to
            stop receiving further marketing e-mail from us and may “opt-out” by
            following the unsubscribe instructions provided in the e-mail you
            receive or by contacting us directly (please see contact information
            below). Despite your indicated e-mail preferences, we may send you
            service-related communications.
          </Typography>
          <Typography variant="body1" gutterBottom>
            <b>(b) Changing Your Personal Information.</b> You may change
            certain elements of personal information in your Account by logging
            into the Site. You may request deletion of your personal information
            by us by contacting us at the email address below, and at our
            discretion, we may use commercially reasonable efforts to honor your
            request; but, please note that we may be required to retain such
            information for legal or compliance purposes. We may also retain
            your information for fraud prevention or similar purposes.
          </Typography>
          <Typography variant="h6" gutterBottom>
            6. Security of Your Personal information.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your security matters to us. We use a variety of security
            technologies and procedures designed to help protect your personal
            information from unauthorized access, use, or disclosure.
          </Typography>
          <Typography variant="h6" gutterBottom>
            7. Changes to This Privacy Policy.
          </Typography>
          <Typography variant="body1" gutterBottom>
            This Privacy Policy is subject to revision. When changes are made,
            the Company will make a new copy of the Privacy Policy available at
            the Site. Any changes to this Privacy Policy will be effective upon
            thirty (30) calendar days following our posting of the changes on
            our Site. These changes will be effective immediately for new users
            of our Service. Continued use of our Services, following notice of
            such changes shall indicate your acknowledgement of such changes and
            agreement to be bound by the terms and conditions of such changes.
          </Typography>
          <Typography variant="h6" gutterBottom>
            8. Contact
          </Typography>
          <Typography variant="body1" gutterBottom>
            If you have questions, comments, or concerns about this Privacy
            Policy, please contact us at:{" "}
            <Link color="secondary" href="mailto:support@textus.com">
              support@textus.com
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

PrivacyPolicy.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
};

export default PrivacyPolicy;
