import { Fragment } from "react";
import {
  useRouteMatch,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";

import { Chip, Stack, Link } from "@mui/material";
import fonts from "utils/theme/fonts";

import { SettingsSection } from "models/SettingsSection";

function AccountSettingsNavigation({
  options,
}: {
  options: SettingsSection[];
}) {
  const location = useLocation();
  const [, , , activeParentRoute, ...restOfPathnameArray] =
    location.pathname.split("/");
  const match = useRouteMatch();

  return (
    <Stack role="navigation" aria-label="Account settings menu">
      {options.map(({ title, path, settings, linkProps }) => {
        return (
          <Fragment key={title}>
            <Link
              sx={(theme) => {
                return {
                  alignItems: "center",
                  color: theme.palette.text.primary,
                  display: "flex",
                  fontSize: fonts.primaryListItemFontSize,
                  justifyContent: "space-between",
                  padding: "12px 20px",
                  width: "100%",
                  "&:hover": {
                    background: theme.palette.action.hover,
                  },
                  ...(path === activeParentRoute && {
                    fontWeight: "600",
                  }),
                };
              }}
              component={RouterLink}
              key={title}
              to={`${match.path}/${path}`.replace("//", "/")}
              underline="none"
              {...linkProps}
            >
              {title}
            </Link>
            {path === activeParentRoute && (
              <Stack>
                {settings.map((setting) => {
                  return (
                    <Link
                      component={RouterLink}
                      key={setting.title}
                      to={`${match.url}/${path}${setting.to}`.replace(
                        "//",
                        "/",
                      )}
                      underline="none"
                      sx={(theme) => {
                        return {
                          alignItems: "center",
                          color: theme.palette.text.primary,
                          display: "flex",
                          fontSize: fonts.primaryListItemFontSize,
                          justifyContent: "space-between",
                          padding: "12px 50px",
                          width: "100%",
                          "&:hover": {
                            background: theme.palette.action.hover,
                          },
                          ...(setting.to ===
                            `/${restOfPathnameArray.join("/")}` && {
                            background: theme.palette.action.hover,
                          }),
                        };
                      }}
                      {...setting.linkProps}
                    >
                      {setting.title}
                    </Link>
                  );
                })}
              </Stack>
            )}
          </Fragment>
        );
      })}
    </Stack>
  );
}

export default AccountSettingsNavigation;
