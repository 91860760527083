import SvgIcon from "@mui/material/SvgIcon";
import { Props } from "./types";
import ICONS from "constants/icons";

export default function SequencesIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1024 1024">
      <path d={ICONS.QUICK_PHASES} />
    </SvgIcon>
  );
}
