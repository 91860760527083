import { Link as ReactRouterDomLink } from "react-router-dom";
import { Link as MaterialLink } from "@mui/material";
import { Props } from "./types";

/**
 * Material UI Link as a React Router Link
 */
export function Link(props: Props) {
  return <MaterialLink component={ReactRouterDomLink} {...props} />;
}
