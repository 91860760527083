import styled from "styled-components";

export default styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: flex;
  flex-flow: column;
  font-size: 14px;
  height: 100%;
  margin: 0 auto;
`;
