import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import AssignmentSectionFooter from "./AssignmentSectionFooter";
import Success from "./Success";
import successTransitions from "utils/successTransitions";
import H4 from "components/H4";
import P from "components/P";
import ModalHeader from "components/ModalHeader";
import ModalContent from "components/ModalContent";
import ContactCard, { Root as ContactCardStyles } from "components/ContactCard";

const { assignSuccessful } = successTransitions;

const fullScreenCSS = css`
  height: 100%;
  width: 100vw;
  border-radius: 0;
`;

const Root = styled.div`
  height: auto;
  max-height: 80vh;
  max-width: 100%;
  width: 500px;
  display: flex;
  flex-flow: column nowrap;
  overflow: hidden;

  ${(props) => {
    return props.fullScreen && fullScreenCSS;
  }};
`;

const Cards = styled.div`
  flex: 1 1 100%;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  margin: 10px auto 60px;
  padding: 0 20px;
  width: 100%;
  overflow: auto;

  header {
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }};
    border-bottom: ${(props) => {
      return props.theme.mixins.border({ color: props.theme.colors.divider });
    }};
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    font-size: ${(props) => {
      return props.theme.fonts.primaryFontSize;
    }};
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 20px 0 10px;
    z-index: 2;

    ${(props) => {
      return !props.hasAssignedContact && `padding-top: 0px;`;
    }};
  }

  ${ContactCardStyles} {
    margin-bottom: 10px;
  }

  & > div {
    ${ContactCardStyles}:first-child {
      border-top: 0;
    }
  }
`;

const Hero = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 0 20px;
  position: relative;
  color: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  ${(props) => {
    return !props.hasAssignedContact && `padding-bottom: 50px;`;
  }};

  ${H4} {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    font-size: ${(props) => {
      return props.theme.fonts.largeHeaderFontSize;
    }};
    text-align: left;
  }

  ${P} {
    text-align: left;
  }
`;

const AssignedContactContainer = styled.div`
  width: 100%;
  height: auto;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  transform: translateY(25px);
  z-index: 5;
`;

export default class ContactAssignmentMainView extends Component {
  static propTypes = {
    associatedContacts: PropTypes.array.isRequired,
    assignedContact: PropTypes.object,
    contactAssignmentContainer: PropTypes.object.isRequired,
    conversationId: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    assignConversationRequest: PropTypes.func.isRequired,
  };

  containerQuery = {
    isSinglePanel: {
      maxWidth: 700,
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      associatedContacts: props.associatedContacts,
      assignedContact: props.assignedContact,
      hasChanges: false,
      wasSuccessful: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      assignSuccessful({
        container: "contactAssignmentContainer",
        nextProps,
        props: this.props,
      })
    ) {
      this.setState(
        {
          wasSuccessful: true,
          hasChanges: false,
        },
        () => {
          this.successTimeout = setTimeout(() => {
            this.props.close();
          }, 3000);
        },
      );
    }
  }

  assignContact = (assignedContact) => {
    this.setState({
      assignedContact,
      hasChanges: true,
    });
  };

  handleSave = () => {
    const { conversationId } = this.props;
    const contactId = this.state.assignedContact.id;

    this.props.assignConversationRequest(`${conversationId}/assign_contact`, {
      assignedContact: contactId,
    });
  };

  renderAssignedContact = () => {
    const { assignedContact } = this.state;

    return (
      assignedContact && (
        <AssignedContactContainer>
          <ContactCard
            key={assignedContact.id}
            contact={assignedContact}
            isSelected
            handleSelect={() => {
              return this.assignContact(null);
            }}
          />
        </AssignedContactContainer>
      )
    );
  };

  renderAssociatedContacts = () => {
    return (
      this.state.associatedContacts &&
      this.state.associatedContacts
        .filter((c) => {
          return this.state.assignedContact
            ? c.id !== this.state.assignedContact.id
            : true;
        })
        .map((c) => {
          return (
            <ContactCard
              key={c.id}
              contact={c}
              handleSelect={() => {
                return this.assignContact(c);
              }}
            />
          );
        })
    );
  };

  render() {
    const {
      close,
      fullScreen,
      contactAssignmentContainer: {
        substate: { isAssigning },
      },
    } = this.props;
    const { hasChanges, assignedContact, wasSuccessful } = this.state;

    return (
      <Root fullScreen={fullScreen}>
        <ModalHeader close={this.props.close}>
          {assignedContact ? "Assigned Contact" : "Assign a Contact"}
        </ModalHeader>
        <ModalContent>
          <Success isActive={wasSuccessful} close={close} />
          {assignedContact && (
            <Hero hasAssignedContact={assignedContact}>
              <P>
                {assignedContact.name}
                &apos;s information will be displayed and used in Templates
                whenever interacting with this conversation.
              </P>
              {this.renderAssignedContact()}
            </Hero>
          )}

          <Cards hasAssignedContact={assignedContact}>
            <header>
              <p>
                {assignedContact
                  ? "Other contacts with this number"
                  : "Please select a contact"}
              </p>
            </header>
            <div>{this.renderAssociatedContacts()}</div>
          </Cards>
        </ModalContent>

        <AssignmentSectionFooter
          close={close}
          hasChanges={hasChanges}
          save={this.handleSave}
          isAssigning={isAssigning}
        />
      </Root>
    );
  }
}
