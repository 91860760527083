import { styled } from "@mui/system";

export default styled("div")({
  height: "100%",
  flex: "1 1 auto",
  position: "relative",
  "& img": {
    "@media (max-height: 500px)": {
      display: "none",
    },
  },
});
