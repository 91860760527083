import { createBus } from "@tesseract/core";

const redirectBus = createBus();

/** Redirect publisher. */
export const redirectTo = (url: string) => {
  redirectBus.produce(url);
};

/** Redirect subscriber. */
export const subscribeToRedirect = (
  callback: (...params: unknown[]) => void,
) => {
  return redirectBus.subscribe(callback);
};
