import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useCallback, useMemo, useState } from "react";
import { ContentCopy, Visibility, VisibilityOff } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import type { IntegrationProps } from "./types";
import { StyledExpansionPanel, StyledExpansionPanelDetails } from "./common";
import IntegrationSubmitSection from "./IntegrationSubmitSection";
import { Link } from "components/Link";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import { GreenhouseV2ValidationSchema } from "formHelpers/validationSchemas";
import { Section } from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import { Input } from "components/Input";
import { Switch } from "components/Switch";
import { Select } from "components/Select";
import { InfoOutlineIcon } from "icons";

export const Logo = styled("img")({
  height: "62px",
  margin: "0 auto",
});

type Config = {
  apiKey: string;
  authorizingUserEmail?: string;
  subdomain: string;
  endpointUrl?: string;
  endpointSecretKey?: string;
};

type Settings = {
  batching: {
    enabled?: boolean;
    noteDuration?: string;
  };
  contactSchemasEnabled?: boolean;
  initial_contact_import?: {
    initiated?: string;
  };
};

const switchOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

/**
 * Renders Greenhouse integration settings
 */
export default function GreenhouseV3({
  handleSubmit: submit,
  provider,
  toggleActive,
}: IntegrationProps<
  Config,
  {
    batching?: {
      enabled?: boolean;
      note_duration?: string;
    };
    contact_schemas_enabled?: boolean;
    initial_contact_import?: {
      initiated?: string;
    };
  }
>) {
  const [expandedPanel, setExpandedPanel] = useState<string | undefined>(
    provider?.integration?.config?.apiKey ? "settings" : "config",
  );
  const [showSecretKey, setShowSecretKey] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  /**
   * Handle submit
   */
  const handleSubmit = useCallback(
    (
      {
        config,
        settings,
      }: {
        config: Config;
        settings: Settings;
      },
      closeModal: boolean,
    ) => {
      return submit(
        {
          config,
          provider,
          settings,
        },
        closeModal,
      );
    },
    [provider, submit],
  );

  return (
    <>
      <ModalHeader closeModal={provider.closeModal}>
        {provider.name}
      </ModalHeader>
      <ModalContent noPadding>
        <Box
          sx={{
            width: "500px",
            maxWidth: "100%",
            padding: 2,
            textAlign: "center",
          }}
        >
          <Logo src={provider.logo} />
        </Box>

        <Formik
          onSubmit={(props) => {
            handleSubmit(props, true);
          }}
          initialValues={{
            config: {
              apiKey: provider?.integration?.config?.apiKey ?? "",
              authorizingUserEmail:
                provider?.integration?.config?.authorizingUserEmail ?? "",
              subdomain: provider?.integration?.config?.subdomain ?? "",
              endpointUrl: provider?.integration?.config?.endpointUrl ?? "",
              endpointSecretKey:
                provider?.integration?.config?.endpointSecretKey ?? "",
            },
            settings: {
              batching: {
                enabled: provider?.integration?.settings?.batching?.enabled,
                noteDuration:
                  provider?.integration?.settings?.batching
                    ?.note_duration,
              },
              contactSchemasEnabled:
                provider?.integration?.settings?.contact_schemas_enabled,
              initial_contact_import:
                provider?.integration?.settings?.initial_contact_import,
            },
          }}
          validationSchema={GreenhouseV2ValidationSchema}
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => {
            return (
              <Form>
                <StyledExpansionPanel
                  expanded={expandedPanel === "config"}
                  onChange={() => {
                    setExpandedPanel(
                      expandedPanel === "config" ? undefined : "config",
                    );
                  }}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Configuration
                  </ExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <Section>
                      <Stack
                        sx={{
                          gap: 2,
                        }}
                      >
                        <Input
                          name="config.authorizingUserEmail"
                          label="Email"
                        />
                        <Input
                          name="config.apiKey"
                          label="API key"
                          onBlur={() => {
                            // quality of life: expand the settings panel once the user has entered the required fields
                            if (
                              values.config?.apiKey &&
                              values.config?.authorizingUserEmail
                            ) {
                              setExpandedPanel("settings");
                            }
                          }}
                        />
                        <Typography variant="subtitle2">
                          Greenhouse subdomain
                        </Typography>
                        <Input name="config.subdomain" label="Subdomain" />
                        <Typography variant="body2">
                          To use candidate IDs in TextUs as hyperlinks, please
                          enter your Greenhouse URL subdomain. You can determine
                          what your subdomain is by signing into Greenhouse and
                          looking at your browser's address bar.
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            fontStyle: "italic",
                          }}
                        >
                          Example: The subdomain in https://app4.greenhouse.io/
                          would be <b>app4</b>
                        </Typography>
                      </Stack>
                    </Section>
                  </StyledExpansionPanelDetails>
                </StyledExpansionPanel>
                <StyledExpansionPanel
                  expanded={expandedPanel === "incomingWebhooks"}
                  onChange={() => {
                    setExpandedPanel(
                      expandedPanel === "incomingWebhooks"
                        ? undefined
                        : "incomingWebhooks",
                    );
                  }}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Incoming Webhooks
                  </ExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <Section>
                      <Stack
                        sx={{
                          gap: 2,
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            marginBottom: "16px",
                          }}
                        >
                          Use these credentials to keep TextUs up to date with
                          Greenhouse.
                        </Typography>
                        <Input
                          name="config.endpointUrl"
                          label="Endpoint URL"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <Button
                          color="primary"
                          variant="text"
                          sx={{
                            alignSelf: "flex-end",
                            marginTop: "-24px",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              provider?.integration?.config?.endpointUrl,
                            );
                            enqueueSnackbar("Endpoint URL copied.", {
                              variant: "info",
                            });
                          }}
                        >
                          <ContentCopy
                            sx={{
                              marginRight: "8px",
                            }}
                          />
                          Copy URL
                        </Button>
                        <Input
                          name="config.endpointSecretKey"
                          label="Secret key"
                          type={showSecretKey ? "text" : "password"}
                          InputProps={{
                            readOnly: true,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() => {
                                    setShowSecretKey(!showSecretKey);
                                  }}
                                  edge="end"
                                >
                                  {showSecretKey ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        <Button
                          color="primary"
                          variant="text"
                          sx={{
                            alignSelf: "flex-end",
                            marginTop: "-24px",
                          }}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              provider?.integration?.config?.endpointSecretKey,
                            );
                            enqueueSnackbar("Secret key copied.", {
                              variant: "info",
                            });
                          }}
                        >
                          <ContentCopy
                            sx={{
                              marginRight: "8px",
                            }}
                          />
                          Copy Secret
                        </Button>
                      </Stack>
                    </Section>
                  </StyledExpansionPanelDetails>
                </StyledExpansionPanel>
                <StyledExpansionPanel
                  expanded={expandedPanel === "settings"}
                  onChange={() => {
                    setExpandedPanel(
                      expandedPanel === "settings" ? undefined : "settings",
                    );
                  }}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Settings
                  </ExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <Section>
                      <Stack
                        sx={{
                          gap: 2,
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle2">
                            Batch conversation logging
                          </Typography>
                          <Switch
                            name="settings.batching.enabled"
                            color="secondary"
                            options={switchOptions}
                            onChange={(event) => {
                              if (
                                values.settings.batching
                                  .noteDuration === undefined
                              ) {
                                setFieldValue(
                                  "settings.batching.noteDuration",
                                  event.target.checked ? "daily" : undefined,
                                );
                              }
                            }}
                          />
                        </Box>
                        <Select
                          variant="outlined"
                          {...{
                            label: "Conversation duration",
                            name: "settings.batching.noteDuration",
                            options: [
                              {
                                label: "One Day",
                                value: "daily",
                              },
                              {
                                label: "One Week",
                                value: "weekly",
                              },
                            ],
                          }}
                        />
                      </Stack>
                      <Stack
                        sx={{
                          gap: 2,
                          marginTop: 3,
                        }}
                      >
                        <Box>
                          <Typography variant="subtitle2">
                            Contact sync
                          </Typography>
                          {provider?.integration?.active ? (
                            <Alert
                              severity="info"
                              icon={<InfoOutlineIcon />}
                              sx={{
                                marginBottom: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <Typography variant="body2">
                                Sync remains active unless the integration is
                                paused or removed.
                              </Typography>
                            </Alert>
                          ) : (
                            <Alert
                              severity="warning"
                              sx={{
                                marginBottom: "8px",
                                marginTop: "8px",
                              }}
                            >
                              <Typography variant="body2">
                                Integration paused. Resume to continue syncing
                                contacts.
                              </Typography>
                            </Alert>
                          )}
                          <Typography variant="body2">
                            Candidates can be brought in from Greenhouse to
                            create TextUs contacts on an ongoing basis.
                          </Typography>
                        </Box>
                        <Alert severity="info" icon={<InfoOutlineIcon />}>
                          <Typography variant="body2">
                            Any phone numbers without a country code will be{" "}
                            <Link
                              to="../account-details"
                              target="_blank"
                              sx={{
                                color: "text.hyperlink",
                              }}
                            >
                              assigned this account&apos;s country code.
                            </Link>
                          </Typography>
                        </Alert>
                      </Stack>
                    </Section>
                  </StyledExpansionPanelDetails>
                </StyledExpansionPanel>
                <IntegrationSubmitSection
                  isSubmitting={isSubmitting}
                  isValid={Boolean(isValid)}
                  provider={provider}
                  toggleActive={toggleActive}
                />
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </>
  );
}
