import { all, put, takeLatest } from "redux-saga/effects";

import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";
import { makeRequest } from "utils/generateContainerState/generateSagas";

// ACTION TYPES
const actionTypes = generateActionTypes({
  container: "AttachmentsOptionContainer",
  actions: ["upload"],
  recordType: "attachment",
} as const);

// ACTION GENERATORS
const actionGenerators = generateActionGenerators(actionTypes);

const sagas = {
  *uploadAttachmentRequest({
    url,
    params,
    options = {},
  }: {
    url: string;
    params: { file: File };
    options: {
      successCallback?: (response: { signedUrl: string }) => void;
    };
  }) {
    try {
      const response: { signedUrl: string } = yield makeRequest({
        url,
        params: { contentType: params.file.type },
        method: "POST",
      });
      yield fetch(response.signedUrl, {
        method: "PUT",
        body: params.file,
      });
      if (options.successCallback) options.successCallback(response);
      yield put(actionGenerators.uploadAttachmentSuccess(response));
    } catch (error) {
      yield put(actionGenerators.uploadAttachmentFailure(error));
    }
  },
};

function* saga() {
  yield all([
    takeLatest(
      // @ts-expect-error
      actionTypes.UPLOAD_ATTACHMENT_REQUEST,
      sagas.uploadAttachmentRequest,
    ),
  ]);
}

export { actionTypes, actionGenerators, saga, sagas };
