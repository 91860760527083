import { useDispatch } from "react-redux";

import { useSnackbar } from "notistack";
import { useAccountSettingsActions } from "./useAccountSettingsActions";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { useCurrentAccount } from "hooks";
import { swapLinksInBody } from "components/MessageInputOptions/ShortenedLinkOption/utils/swapLinksInBody";

export const useAccountSettingsSubmit = (
  messageSettingType = "",
  shortenedLink = {},
) => {
  const dispatch = useDispatch();
  const account = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { updateAccountRequest } = useAccountSettingsActions();

  const errorCallback = (actions) => {
    return (errors) => {
      const validationErrors = errors.validationErrors?.settings || {
        message: ["There was an error saving your account settings"],
      };

      const errorsKey = Object.keys(validationErrors)[0];
      const errorMessage = validationErrors[errorsKey][0];
      actions.setSubmitting(false);
      actions.setErrors(validationErrors);
      enqueueSnackbar(
        errorMessage || "Something went wrong. Please try again.",
        { variant: "error" },
      );
    };
  };

  const successCallback = (
    actions,
    message = "Account settings saved successfully!",
  ) => {
    return () => {
      actions.setSubmitting(false);

      enqueueSnackbar(message, { variant: "info" });
    };
  };

  const handleShortLinkInMessageSetting = (values) => {
    const { fullLink, shortLink } = shortenedLink;
    const formValues = values;
    const isAutoReply = Boolean(messageSettingType === "autoReply");
    const formBody = isAutoReply
      ? formValues.settings.autoReplyMessage.value.message
      : formValues.settings[`${messageSettingType}Message`].value;
    const bodyWithReplacedLinks = swapLinksInBody(
      shortLink,
      `{{ "${fullLink}" | shortlink }}`,
      formBody,
    );
    if (isAutoReply) {
      formValues.settings.autoReplyMessage.value.message =
        bodyWithReplacedLinks;
    } else {
      formValues.settings[`${messageSettingType}Message`].value =
        bodyWithReplacedLinks;
    }
    return formValues;
  };

  const handleSubmit = (values, actions, successMessage) => {
    const { fullLink } = shortenedLink || "";
    const formValues = fullLink
      ? handleShortLinkInMessageSetting(values)
      : values;
    updateAccountRequest(account.id, formValues, {
      successCallback: successCallback(actions, successMessage),
      errorCallback: errorCallback(actions),
    });
  };

  return { handleSubmit, updateAccountRequest };
};
