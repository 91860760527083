import { textUsApiV3 } from "services";

function fetchCampaignAnalytics(
  accountSlug: string,
  filter?: "mine" | "all",
  options?: RequestInit,
) {
  return textUsApiV3.get(
    `/${accountSlug}/campaigns/analytics`,
    { filter },
    options,
  );
}

export { fetchCampaignAnalytics };
