import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class BullhornStrategy {
  constructor({
    activeFrameAttributes,
    activeFrameParsedHtml,
    parsedHtml,
    location,
  }) {
    this.activeFrameAttributes = activeFrameAttributes;
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () =>
    /bullhorn/i.test(this.location?.href) &&
    !this.parsedHtml.getElementById("novo-body");

  getParsers = () => {
    const doc = this.activeFrameParsedHtml;
    const {
      id: activeFrameId,
      src: activeFrameSrc,
    } = this.activeFrameAttributes;
    const detailPageParser = new SingleContactParser({
      doc,
      getNamesFunction: () =>
        Array.from($(doc).find("td.ThemeHeadingText span"))
          .filter(
            node =>
              /name/i.test($(node).attr("title")) &&
              !/middle|nick/i.test($(node).attr("title")),
          )
          .map(node => node.textContent.trim())
          .slice(0, 2),
      textSelector: `[data-automation-id*="Phone"]`,
      canParse: () => /Win(?!d)|Wnd|ResumeWizard/i.test(activeFrameId),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "tr td.name",
      containerSelector: "tr",
      canParse: () => /core|\/Candidates|\/Clients/i.test(activeFrameSrc),
    });
    const tearsheetParser = new MultiContactParser({
      doc,
      namesSelector: `td.DBListCell:nth-child(4) a`,
      containerSelector: "tr",
      canParse: () => /TearSheet/i.test(activeFrameSrc),
    });
    return [detailPageParser, listPageParser, tearsheetParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "BullhornStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "BullhornStrategy");
      return null;
    }
  };
}
