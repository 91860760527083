import type { FormikContextType } from "formik";
import { FormikErrors } from "formik";

export interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  sendWelcome: boolean;
}

export interface FormValues {
  users: Record<string, undefined | Partial<User>>;
}

export type UsersFormErrors = FormikErrors<FormValues>;

export type UsersFormProps = FormikContextType<FormValues> & {
  serverErrors: UsersFormErrors | undefined;
};
