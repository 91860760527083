import { Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

class Portal extends Component {
  static propTypes = {
    children: PropTypes.any,
    root: PropTypes.any.isRequired,
  };

  constructor(props) {
    super(props);
    this.el = document.createElement("span");
  }

  componentDidMount() {
    this.props.root.appendChild(this.el);
  }

  componentWillUnmount() {
    this.props.root.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

export default Portal;
