import Honeybadger from "@honeybadger-io/js";
import some from "lodash/some";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";
import getPhones from "../utils/getPhones";

export default class MetadataStrategy {
  constructor({ name, location, parsedHtml }) {
    this.name = name;
    this.location = location;
    this.parsedHtml = parsedHtml;
  }

  sanitizeProps = ({ name, familyName, telephone }) => ({
    phone_numbers: Array.isArray(telephone)
      ? telephone.map(phone => getPhones(phone)).flat()
      : getPhones(telephone),
    first_name: name || "",
    last_name: familyName || "",
  });

  canParse = () => this.contacts?.length > 0;

  getParsers = () => {
    const { contacts, parsedHtml: doc } = this;
    const detailPageParser = new SingleContactParser({
      doc,
      getMetadataFunction: () => contacts,
      canParse: () => contacts?.length === 1,
    });
    const listPageParser = new MultiContactParser({
      doc,
      getMetadataFunction: () => contacts,
      canParse: () => contacts?.length > 1,
    });
    return [listPageParser, detailPageParser];
  };

  parse = () => {
    try {
      const isDomainSupported = Boolean(this.canParse());
      const strategy = this.name;
      if (!isDomainSupported) {
        return null;
      }

      const parsers = this.getParsers();
      const canParse = some(parsers, parser => parser.canParse());
      if (!canParse) {
        return null;
      }

      const results = findAndReturn(parsers)(parser => parser.parse()) || [];
      return { strategy, results };
    } catch (error) {
      Honeybadger.notify(error, this.name);
      return null;
    }
  };
}
