import { SyntheticEvent, useState, useCallback } from "react";
import { useSnackbar } from "notistack";
import { createRecipients, fetchSequence } from "features/Sequences/api";
import { Account as AccountType } from "models/Account";
import { useDialog } from "components/Dialog";
import { FetchSequenceRecipientsResponse } from "features/Sequences/components/ContactsDataGrid/types";
import { SequencesContact } from "features/Sequences/screens/IndividualSequenceOverview/types";
import { formatSequenceContactRows } from "features/Sequences/screens/IndividualSequenceOverview/utils";
import type { Sequence } from "models/Sequence";
import { Snackbar } from "components/Snackbar";
import { useLoading } from "hooks/useLoading";
import { SnackbarLinkText } from "components/Snackbar/SnackbarLinkText";

const useIndividualSequence = (
  currentAccount: AccountType,
  sequenceId: string,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const [individualSequence, setIndividualSequence] = useState<Sequence>({
    contactCount: 0,
    createdBy: "",
    daySpan: 0,
    deliveryRate: 0,
    engagementRate: 0,
    failedCount: 0,
    finishedCount: 0,
    finishedOnReply: true,
    id: "",
    name: "",
    noResponseCount: 0,
    pendingCount: 0,
    omittedCount: 0,
    respondedCount: 0,
    scheduleType: "",
    settings: {},
    state: "",
    stepCount: 0,
    stoppedCount: 0,
    updatedAt: "",
  });
  const [isEnrollDrawerOpen, setIsEnrollDrawerOpen] = useState(false);
  const [rows, setRows] = useState<SequencesContact[]>([]);
  const [selectedEnrollees, setSelectedEnrollees] = useState<string | string[]>(
    [],
  );
  const [selectedTab, setSelectedTab] = useState(0);

  const { ref: addStepDialogRef, open: openAddStepDialog } = useDialog<
    any,
    any
  >();

  const rowState = {
    rows,
    setRows,
  };

  const getSequence = useCallback(async () => {
    try {
      startLoading();
      const response = await fetchSequence(currentAccount, sequenceId);
      const sequence: Sequence = await response.json();
      setIndividualSequence(sequence);
    } catch (err) {
      throw new Error(`Error fetching sequences: ${err}`);
    } finally {
      stopLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAccount, sequenceId]);

  const handleChange = (event: SyntheticEvent, newSelectedTab: number) => {
    setSelectedTab(newSelectedTab);
  };

  const toggleEnrollDrawer = () => {
    setIsEnrollDrawerOpen(!isEnrollDrawerOpen);
  };

  const addRecipients = async () => {
    try {
      startLoading();
      const response = await createRecipients(
        currentAccount,
        sequenceId,
        selectedEnrollees,
      );
      const { items }: FetchSequenceRecipientsResponse = await response.json();
      const newRows = items ? formatSequenceContactRows(items) : [];

      const snackbarCopy = `Successfully enrolled ${newRows.length} ${
        newRows.length === 1 ? "contact" : "contacts"
      } to ${individualSequence.name}.`;

      enqueueSnackbar(snackbarCopy, {
        content: (key, message) => {
          return (
            <Snackbar
              id={key}
              message={message}
              variant="info"
              actionButton={
                <SnackbarLinkText
                  to={`/${currentAccount.slug}/automation/sequences/${sequenceId}`}
                >
                  VIEW
                </SnackbarLinkText>
              }
            />
          );
        },
      });

      setRows([...rows, ...newRows]);
    } catch (err) {
      enqueueSnackbar(
        "We ran into an issue enrolling your contacts - please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="error" />;
          },
        },
      );
      throw new Error(`Error enrolling recipients: ${err}`);
    } finally {
      stopLoading();
    }
  };

  const handleConfirmEnroll = () => {
    addRecipients();
    toggleEnrollDrawer();
    setSelectedEnrollees([]);
  };

  return {
    addStepDialogRef,
    individualSequence,
    isEnrollDrawerOpen,
    isLoadingIndividualSequence: isLoading,
    rowState,
    selectedEnrollees,
    selectedTab,

    addRecipients,
    getSequence,
    handleChange,
    handleConfirmEnroll,
    openAddStepDialog,
    setSelectedEnrollees,
    setIndividualSequence,
    toggleEnrollDrawer,
  };
};

export { useIndividualSequence };
