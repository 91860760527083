import type { TextFieldProps } from "@mui/material/TextField";
import { FieldHookConfig } from "formik";

export type Props = Pick<
  FieldHookConfig<string | undefined>,
  "name" | "validate"
> &
  Omit<TextFieldProps, "required" | "select"> & {
    /**
     * HTML <input> name attribute
     */
    name: string;

    /**
     * Label for the input element
     */
    label: string;

    /**
     * Label for the validation error
     */
    required?: string | boolean;
  };
