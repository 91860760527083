import SvgIcon from "@mui/material/SvgIcon";
import { Props } from "./types";
import ICONS from "constants/icons";

export default function BullhornIcon(props: Props) {
  return (
    <SvgIcon {...props} viewBox="0 0 20 21" fill="none">
      <path d={ICONS.BULLHORN} fill="#FF6B00" />
    </SvgIcon>
  );
}
