import {
  useRouteMatch,
  useLocation,
  Link as RouterLink,
} from "react-router-dom";
import { Stack, Link } from "@mui/material";
import get from "lodash/get";
import capitalize from "lodash/capitalize";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { User } from "@tesseract/core";
import { SettingsSectionWithSubSections } from "models/SettingsSection";
import fonts from "utils/theme/fonts";
import { Account } from "models/Account";

function SectionTitle({
  linkProps,
  title,
  path,
}: {
  linkProps: any;
  path: string;
  title: string;
}) {
  const match = useRouteMatch();

  return (
    <Link
      sx={(theme) => {
        return {
          alignItems: "center",
          color: theme.palette.text.primary,
          display: "flex",
          fontSize: fonts.primaryListItemFontSize,
          justifyContent: "space-between",
          padding: "12px 20px",
          width: "100%",
          "&:hover": {
            background: theme.palette.action.hover,
          },
        };
      }}
      component={RouterLink}
      key={title}
      to={`${match.path}/${path}`.replace("//", "/")}
      underline="none"
      {...linkProps}
    >
      {title}
    </Link>
  );
}

export default function AccountSettingsSidebar({
  account,
  currentUser,
  sections,
}: {
  account: Account;
  currentUser: User.Raw;
  sections: SettingsSectionWithSubSections[];
}) {
  const location = useLocation();
  const activeParentRoute = location.pathname.split("/")[3];
  const subRoute = location.pathname.split("/")[4];
  const match = useRouteMatch();
  const memberships = get(currentUser, ["memberships", "members"], []);
  const navMembership = memberships.find((membership) => {
    return get(membership, ["account", "id"]) === account.id;
  });
  const membershipRole = get(navMembership, ["role"]);

  const typeOrNumber = account.phoneNumbers.length === 0 ? "Non-messaging" : 
    (account.phoneNumbers.length > 1 ? "Multiple Numbers" : account.phoneNumbers[0]);

  return (
    <nav 
        style={{
        overflowY: "scroll",
      }} 
      aria-label="Account settings menu"
    >
      <Stack
        role="navigation"
        aria-label="Account settings menu"
        component="ul"
        style={{
          listStyleType: "none",
          margin: 0,
          padding: 0,
        }}
      >
        <div
          style={{
            borderBottom: "1px solid #e0e0e0",
            marginBottom: "10px",
          }}  
        >
          <Link
            component={RouterLink}
            to={`${match.url}/account-details`}
            sx={(theme) => {
              return {
                alignItems: "center",
                color: theme.palette.text.primary,
                display: "flex",
                fontSize: fonts.primaryListItemFontSize,
                justifyContent: "space-between",
                textDecoration: "none",
                padding: "12px 30px 12px 20px",
                width: "100%",
                "&:hover": {
                  background: theme.palette.action.hover,
                },
              };
            }}>
              <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}>
                <div>
                  Account Details
                </div>
                <Typography 
                    sx={(theme) => {
                    return {
                      color: theme.palette.text.secondary,
                      fontSize: fonts.primaryListItemFontSize,
                    };
                }}>
                  {typeOrNumber}
                </Typography>
              </div>
              <Chip size="small" label={capitalize(membershipRole)} />
            </Link>
        </div>
        {sections.map(({ sectionTitle, subsections }) => {
          return (
            <div  key={sectionTitle}>
              <Typography 
                sx={(theme) => {
                  return {
                    color: theme.palette.text.primary,
                    fontSize: fonts.primaryListItemFontSize,
                    padding: "12px 20px",
                    fontWeight: "600",
                  };
              }}>
                {sectionTitle}
              </Typography>
              {subsections?.map(({ linkProps, path, settings, title }) => {
                return (
                  <div key={path}>
                    {SectionTitle({
                      linkProps,
                      path,
                      title,
                    })}
                    {activeParentRoute === path && (
                      <ul
                        style={{
                          listStyleType: "none",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {settings.map((setting) => {
                          return (
                            <li key={setting.title}>
                              <Link
                                underline="none"
                                component={RouterLink}
                                to={`${match.url}/${path}${settings.length > 0 ? setting.to : null}`.replace(
                                  "//",
                                  "/",
                                )}
                                {...linkProps}
                                sx={(theme) => {
                                  return {
                                    alignItems: "center",
                                    color: theme.palette.text.primary,
                                    display: "flex",
                                    fontSize: fonts.primaryListItemFontSize,
                                    justifyContent: "space-between",
                                    padding: "12px 50px",
                                    width: "100%",
                                    "&:hover": {
                                      background: theme.palette.action.hover,
                                    },
                                    ...(subRoute ===
                                      setting.to.replace("/", "") && {
                                      background: theme.palette.action.hover,
                                    }),
                                  };
                                }}
                              >
                                {setting.title}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </Stack>
    </nav>
  );
}
