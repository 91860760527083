import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Component } from "react";

import { selectUserCanHover } from "features/EntryPoint/containers/App/selectors";

class TooltipContainer extends Component {
  static propTypes = {
    userCanHover: PropTypes.bool.isRequired,
  };

  render() {
    const { userCanHover } = this.props;
    return userCanHover ? <div id="textus-TooltipContainer" /> : null;
  }
}

const mapStateToProps = (state, props) => {
  return {
    userCanHover: selectUserCanHover(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default withConnect(TooltipContainer);
