import { useState } from "react";
import { useSnackbar } from "notistack";
import {
  UseDetailReportProps,
  UseDetailReportReturn,
  AnalyticsReportsResponse,
  AnalyticReportData,
  AggregatePlanData,
  AggregatePlanCard,
} from "../models/AnalyticsModels";
import * as api from "utils/api";

const useDetailReport = ({
  currentAccount,
  currentUser,
  reportName,
}: UseDetailReportProps): UseDetailReportReturn => {
  const [analyticReport, setAnalyticReport] = useState<AnalyticReportData>();
  const [analyticsError, setAnalyticsError] = useState<Error>(
    null as unknown as Error,
  );

  const [aggregatePlanCards, setAggregatePlanCards] = useState<
    AggregatePlanCard[]
  >([]);

  const [loading, setLoading] = useState<boolean>(true);

  const { enqueueSnackbar } = useSnackbar();

  const analyticsReports = currentUser?.settings?.analyticsReports ?? [];

  const existingReports =
    Array.isArray(analyticsReports) &&
    analyticsReports.filter(({ reports, account: slug }) => {
      return (
        slug === currentAccount?.id &&
        reports.find((report: string) => {
          return report === reportName;
        })
      );
    });

  const fetchAnalyticsReportsSuccessCallback = (
    response: AnalyticsReportsResponse,
  ) => {
    setAnalyticReport(response);
    setLoading(false);
  };

  const fetchAnalyticsReportsErrorCallback = (error: Error) => {
    enqueueSnackbar("Something went wrong trying to get analytic reports.", {
      variant: "error",
    });
    setAnalyticsError(error);
    setLoading(false);
  };

  /**
   * Fetches the aggregate plan details for the current account.
   * @param aggregatePlanUrl Aggregate plan endpoint to call.
   */
  const fetchAggregatePlan = async (aggregatePlanUrl: string) => {
    try {
      const headers = await api.getHeaders();
      const response = await fetch(aggregatePlanUrl, { headers });
      const json = (await response.json()) as AggregatePlanData;
      const cards: AggregatePlanCard[] = [
        {
          title: "Aggregate Term",
          value: json.planTerms,
        },
        {
          title: "Aggregate Plan",
          value: json.aggregatePlan,
        },
        {
          title: "Outbound SMS Messages",
          value: json.outboundSmsMessages,
        },
      ];
      setAggregatePlanCards(cards);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  return {
    analyticReport: analyticReport as AnalyticReportData,
    analyticsError,
    analyticsReports,
    existingReports,
    loading,
    aggregatePlanCards,
    fetchAggregatePlan,
    fetchAnalyticsReportsErrorCallback,
    fetchAnalyticsReportsSuccessCallback,
  };
};

export { useDetailReport };
