/**
 * This hook manages interactions between components and the activate/deactivate sequence endpoints.
 */

import { useSnackbar } from "notistack";
import { updateSequenceState } from "features/Sequences/api";
import { Sequence } from "models/Sequence";
import { SEQUENCE_STATES } from "features/Sequences/screens/IndividualSequenceOverview/constants";
import { Snackbar } from "components/Snackbar";
import { useCurrentAccount, useLoading } from "hooks";

export const useActivateSequence = () => {
  const snackbar = useSnackbar();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const currentAccount = useCurrentAccount();

  const handleActivateSequence = async (
    sequence: Sequence,
    options?: {
      showSnackbar?: boolean;
      finallyCallback?: () => void;
    },
  ) => {
    const { showSnackbar, finallyCallback } = options ?? {};
    startLoading();
    try {
      if (!sequence) {
        return;
      }

      const response = await updateSequenceState(
        currentAccount,
        sequence.id,
        SEQUENCE_STATES.active,
      );

      if (response.ok && showSnackbar) {
        snackbar.enqueueSnackbar("Successfully activated sequence.", {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        });
      }
    } catch (err) {
      throw new Error(`Error activating sequence: ${err}`);
    } finally {
      stopLoading();
      if (finallyCallback) finallyCallback();
    }
  };

  const handleDeactivateSequence = async (
    sequence: Sequence,
    options?: {
      showSnackbar?: boolean;
      finallyCallback?: () => void;
    },
  ) => {
    const { showSnackbar, finallyCallback } = options ?? {};
    startLoading();
    try {
      if (!sequence) {
        return;
      }

      const response = await updateSequenceState(
        currentAccount,
        sequence.id,
        SEQUENCE_STATES.inactive,
      );

      if (response.ok && showSnackbar) {
        snackbar.enqueueSnackbar("Successfully deactivated sequence.", {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        });
      }
    } catch (err) {
      throw new Error(`Error deactivating sequence: ${err}`);
    } finally {
      stopLoading();
      if (finallyCallback) finallyCallback();
    }
  };

  return {
    isLoadingActivate: isLoading,
    handleActivateSequence,
    handleDeactivateSequence,
  };
};
