import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";

// Action Types
const SET_IS_HEADER_TRANSITIONING =
  "app/Animations/SET_IS_HEADER_TRANSITIONING";
const actionTypes = { SET_IS_HEADER_TRANSITIONING };

// Action Generators
const setIsHeaderTransitioning = (isHeaderTransitioning) => {
  return {
    type: SET_IS_HEADER_TRANSITIONING,
    isHeaderTransitioning,
  };
};

const actionGenerators = { setIsHeaderTransitioning };

// REDUCER
const isHeaderTransitioning = (state = false, action) => {
  switch (action.type) {
    case SET_IS_HEADER_TRANSITIONING:
      return action.isHeaderTransitioning;
    default:
      return state;
  }
};
const reducer = combineReducers({ isHeaderTransitioning });

// SELECTORS
const selectAnimationState = (state) => {
  return state.get("animationContainer");
};
const selectIsHeaderTransitioning = createSelector(
  selectAnimationState,
  (animationContainer) => {
    return animationContainer.get("isHeaderTransitioning");
  },
);
const selectors = { selectIsHeaderTransitioning };

export { actionTypes, actionGenerators, reducer, selectors };
