import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import HelpIcon from "@mui/icons-material/Help";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Theme } from "@mui/material";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ChangeEvent, MouseEvent, useState } from "react";
import withPopover from "higherOrderComponents/withPopover";
import { useCurrentAccount } from "hooks";
import { InfoOutlinedIcon } from "icons";

import { timeOptions } from "features/AccountSettings/components/FilterContactSettings";

type StyledProps = {
  mobileView: boolean;
};

type RecipientExclusionSectionProps = {
  handleExclusionTimeChange: (timeInput: number) => void;
  anchorEl: Element | null;
  closeHandler: () => void;
  exclusionTimeInput: number;
  openHandler: (event: MouseEvent<HTMLElement>) => void;
};

const Options = styled(Box)(({ mobileView }: StyledProps) => {
  return {
    position: "relative",
    width: mobileView ? "100%" : "16rem",
  };
});

const Helper = styled(Box)({
  position: "absolute",
  right: "-1.25rem",
  top: "0.75rem",
});

const PopoverContent = styled(Box)(({ theme }) => {
  return {
    borderRadius: "0.25rem",
    color: theme.palette.text.primary,
    fontSize: "0.875rem",
    padding: "1rem 1.25rem",
    maxWidth: "18.75rem",
  };
});

function RecipientsExclusionSection(props: RecipientExclusionSectionProps) {
  const [isMobileTooltipOpen, setIsMobileTooltipOpen] = useState(false);

  const account = useCurrentAccount();
  // gets the exclusion time set by the organization admin
  // since only org admins can adjust this setting in campaign settings
  const { exclusionTime, exclusionTimeEnabled } = account?.settings;

  const handleExclusionTime = (event: ChangeEvent<HTMLInputElement>) => {
    return props.handleExclusionTimeChange(
      Number.parseInt(event.target.value, 10),
    );
  };

  const mobileView = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const toggleMobileTooltip = () => {
    setIsMobileTooltipOpen(!isMobileTooltipOpen);
  };

  const { anchorEl, closeHandler, exclusionTimeInput, openHandler } = props;
  const getWidth = mobileView ? "auto" : "14rem";
  const handleMobileTooltipClose = () => {
    closeHandler();
    toggleMobileTooltip();
  };

  const handleMobileTooltipOpen = (event: MouseEvent<HTMLElement>) => {
    openHandler(event);
    toggleMobileTooltip();
  };

  return (
    <Box
      alignItems={mobileView ? "flex-start" : "center"}
      display="flex"
      fontSize="16px"
      flexDirection={mobileView ? "column" : "row"}
      justifyContent="space-between"
      paddingBottom="0"
      paddingInline="0"
      sx={{
        margin: { xs: "0 auto 16px auto" },
        maxWidth: { xs: "288px", sm: "536px", md: "712px" },
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        fontSize="0.875rem"
        paddingTop="0.5rem"
        paddingBottom="0.5rem"
        paddingRight={mobileView ? "0rem" : "0.5rem"}
      >
        Filter out contacts that already received a campaign in the last:
      </Typography>

      <Options mobileView={mobileView}>
        <Box display="flex" marginRight="2.5rem" width={getWidth}>
          <FormControl fullWidth>
            <TextField
              label="Time since"
              onChange={handleExclusionTime}
              variant="outlined"
              select
              SelectProps={{ native: true }}
              value={exclusionTimeInput || ""}
              data-testid="exclusion-time-selector"
              sx={{
                width: "100%",
              }}
            >
              {timeOptions.map((option) => {
                return (
                  <option
                    key={option.label}
                    value={option.value}
                    disabled={
                      exclusionTimeEnabled?.value &&
                      option.value > exclusionTime?.value
                    }
                  >
                    {option.label}
                  </option>
                );
              })}
            </TextField>
          </FormControl>
          <Helper
            onMouseEnter={mobileView ? undefined : openHandler}
            onMouseLeave={mobileView ? undefined : closeHandler}
            onClick={
              isMobileTooltipOpen
                ? handleMobileTooltipClose
                : handleMobileTooltipOpen
            }
            aria-label="help-popover"
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height="2.875rem"
              width={mobileView ? "4.75rem" : "2.875rem"}
              paddingBottom="0.625rem"
            >
              <InfoOutlinedIcon
                sx={(muiTheme) => {
                  return {
                    color: muiTheme.palette.text.secondary,
                  };
                }}
              />
            </Box>

            <Popover
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              onClose={closeHandler}
              style={{ pointerEvents: "none" }}
              open={Boolean(anchorEl)}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <PopoverContent>
                Allows you to avoid sending your campaign message to a phone
                number if it has received another campaign message from anyone
                on your account within the last hour, day, etc.
              </PopoverContent>
            </Popover>
          </Helper>
        </Box>
      </Options>
    </Box>
  );
}

RecipientsExclusionSection.propTypes = {
  anchorEl: PropTypes.any, // withPopover
  closeHandler: PropTypes.func.isRequired, // withPopover
  exclusionTimeInput: PropTypes.any,
  handleExclusionTimeChange: PropTypes.func.isRequired,
  openHandler: PropTypes.func.isRequired, // withPopover
};

export default withPopover(RecipientsExclusionSection);
