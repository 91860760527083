import { css } from "styled-components";
import { lighten } from "polished";

export default css`
  align-items: center;
  background: none;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.primaryListItemFontSize;
  }};
  letter-spacing: 0.25px;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  text-decoration: none;

  &:hover {
    background: ${(props) => {
      return lighten(0.75, props.theme.colors.action.hover);
    }};
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;
