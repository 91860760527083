/* eslint-disable unicorn/no-array-reduce */
import PropTypes from "prop-types";
import { Formik, Form, getIn } from "formik";
import useMediaQuery from "@mui/material/useMediaQuery";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Tooltip from "@mui/material/Tooltip";

function LockForm({ account, handleSubmit, settings }) {
  const showButtonText = useMediaQuery((theme) => {
    return theme.breakpoints.up("sm");
  });

  const initiallyLocked = settings.some((setting) => {
    return account.settings[setting]?.locked;
  });

  if (!initiallyLocked && account.childrenCount === 0) return null;

  const lockFormSubmit = (values, actions) => {
    const updatedSettings = settings.reduce((prev, setting) => {
      return {
        ...prev,
        [setting]: {
          locked: !values.settings[setting]?.locked,
        },
      };
    }, {});
    handleSubmit({ settings: updatedSettings }, actions);
  };

  const initialSettings = settings.reduce((prev, setting) => {
    return {
      ...prev,
      [setting]: {
        locked: account.settings[setting]?.locked,
      },
    };
  }, {});

  const initialValues = { settings: initialSettings };

  const disabled = settings.some((setting) => {
    return account.settings[setting]?.disabled;
  });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      isInitialValid
      onSubmit={lockFormSubmit}
      validate={() => {
        return undefined;
      }}
    >
      {({ isSubmitting, values }) => {
        const locked = settings.some((setting) => {
          return getIn(values, `settings.${setting}.locked`);
        });
        const lockedAtAccount =
          settings
            .filter((setting) => {
              return getIn(values, `settings.${setting}.locked`);
            })
            .map((setting) => {
              return account.settings[setting]?.lockedAtAccount;
            })[0] || {};

        const tooltipContent = locked
          ? `Unlock these settings for ${lockedAtAccount.name}`
          : `Lock these settings for ${account.name} and its ${
              account.familyCount - 1
            } sub-accounts`;

        return (
          <Form>
            <Tooltip title={disabled ? "" : tooltipContent}>
              <Box mr={2}>
                {showButtonText ? (
                  <Button
                    aria-label="Lock Form Submit"
                    color="primary"
                    data-testid="account-settings-lock-form-submit"
                    disabled={disabled || isSubmitting}
                    startIcon={locked ? <LockIcon /> : <LockOpenIcon />}
                    type="submit"
                  >
                    {locked ? (
                      <Box style={{ textTransform: "none" }}>
                        Locked below <strong>{lockedAtAccount.name}</strong>
                      </Box>
                    ) : (
                      <Box style={{ textTransform: "none" }}>
                        Prevent modifications below{" "}
                        <strong>{account.name}</strong>
                      </Box>
                    )}
                  </Button>
                ) : (
                  <IconButton
                    aria-label="Lock Form Submit"
                    color="primary"
                    data-testid="account-settings-lock-form-submit"
                    disabled={disabled || isSubmitting}
                    type="submit"
                    size="large"
                  >
                    {locked ? (
                      <LockIcon data-testid="account-settings-locked-icon" />
                    ) : (
                      <LockOpenIcon data-testid="account-settings-unlocked-icon" />
                    )}
                  </IconButton>
                )}
              </Box>
            </Tooltip>
          </Form>
        );
      }}
    </Formik>
  );
}

LockForm.propTypes = {
  account: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  settings: PropTypes.array.isRequired,
};

export default LockForm;
