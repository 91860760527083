import { SummaryItem } from "../models/AnalyticsModels";

export const summaryItems: SummaryItem[] = [
  { title: "Number of Accounts", type: "totalAccounts" },
  { title: "Total Conversations", type: "activeConversations" },
  { title: "Messages Sent", type: "totalSent" },
  { title: "Messages Received", type: "totalReceived" },
  { title: "Response Rate", percent: true, type: "responseRate" },
  { title: "Campaigns Sent", type: "totalCampaignsSent" },
];
