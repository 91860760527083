import { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Box from "@mui/material/Box";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ContactCollectionSearch from "components/ContactCollectionSearch";

import {
  getColumns,
  getCollapsedColumns,
} from "features/Groups/constants/contactColumns";
import BatchActionToolbar from "components/BatchActionToolbar";
import Table from "components/Table";
import withRecord from "higherOrderComponents/withRecord";

function ContactCollection({
  contactCollection,
  contactFilter,
  currentPage,
  handleAddContacts,
  pages,
  setCurrentPage,
  setPages,
  handleSearch,
}) {
  const [selected, setSelected] = useState([]);
  const handleSelected = (values) => {
    setSelected(values);
  };

  const handleChangePage = (event, nextPage) => {
    if (nextPage > currentPage) {
      const nextId = get(contactCollection, ["view", "next"]);
      const updatedPages = pages[nextPage]
        ? pages.map((page, index) => {
            return index === nextPage ? nextId : page;
          })
        : [...pages, nextId];
      setPages(updatedPages);
    }
    setCurrentPage(nextPage);
  };

  const batchActions = [
    {
      title: "Add Contacts",
      icon: <PersonAddIcon />,
      clickHandler: () => {
        handleAddContacts(selected);
      },
      showExpanded: true,
    },
  ];

  const columns = getColumns();
  const collapsedColumns = getCollapsedColumns();
  return (
    <>
      <Box
        alignItems="center"
        border={1}
        borderColor="divider"
        borderLeft={0}
        borderRight={0}
        borderTop={0}
        color="primary.main"
        component="header"
        display="flex"
        flex="0 0 55px"
        px="20px"
      >
        <Box component="h4" flex="0 0 auto" margin={0}>
          {contactFilter.title}
        </Box>
        <Box display="flex" flex="1 1 auto" justifyContent="flex-end">
          <ContactCollectionSearch handleSearch={handleSearch} />
        </Box>
      </Box>
      {selected.length > 0 && (
        <BatchActionToolbar
          actions={batchActions}
          position={{ top: "55px", left: "64px" }}
          selectedCount={selected.length}
        />
      )}
      <Table
        collapsedColumns={collapsedColumns}
        collection={contactCollection}
        columns={columns}
        currentPage={currentPage}
        dataTag="GroupMembers-AddContacts"
        handleChangePage={handleChangePage}
        selected={selected}
        setSelected={handleSelected}
        withBatchActions
      />
    </>
  );
}
ContactCollection.propTypes = {
  contactCollection: PropTypes.object.isRequired,
  contactFilter: PropTypes.object.isRequired,
  currentPage: PropTypes.number.isRequired,
  handleAddContacts: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  pages: PropTypes.array.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPages: PropTypes.func.isRequired,
};
export default withRecord({
  actions: ["fetch"],
  container: "features/Groups/pages/Group/AddContactsModal/ContactCollection",
  shape: { members: [{ phones: { members: [] } }] },
  showLoader: () => {
    return false;
  },
  type: "contactCollection",
})(ContactCollection);
