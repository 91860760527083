import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";

const PopoverContent = styled.div`
  border-radius: 4px;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  font-size: 14px;
  padding: 15px 20px;
  max-width: 260px;
`;

const HelperText = styled.div``;

const TextFieldContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 10px;
`;

export default class VariablePopover extends Component {
  static propTypes = {
    anchorEl: PropTypes.any,
    contentState: PropTypes.object,
    decoratedText: PropTypes.string,
    entityKey: PropTypes.string,
    handleCloseVariablePopover: PropTypes.func,
    handleEntityUpdate: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      value: "",
    };
  }

  componentDidUpdate = (prevProps) => {
    if (Boolean(prevProps.decoratedText) && !this.props.decoratedText) {
      this.setState({ value: "" });
    }
    if (
      prevProps.decoratedText !== this.props.decoratedText &&
      Boolean(this.props.decoratedText) &&
      this.hasDefault()
    ) {
      this.setState({ value: this.getValue() });
    }
  };

  getEntityData = () => {
    const { contentState, entityKey } = this.props;
    return contentState.getEntity(entityKey).getData();
  };

  getText = () => {
    const { decoratedText } = this.props;
    const [resource, attribute] = decoratedText
      .replace(/[{}]/g, "")
      .split("|")[0]
      .trim()
      .split(".");
    const text = `${
      resource === "user" ? "Your" : `The ${resource}'s`
    } ${attribute} will be entered here`;
    return this.hasDefault()
      ? `${text}. Please add a default to use when the ${resource} does not have a ${attribute}.`
      : text;
  };

  getUpdatedText = (newValue) => {
    return this.props.decoratedText.replace(/".*"/, `"${newValue}"`);
  };

  getValue = () => {
    return this.props.decoratedText
      .replace(/[{}"]/g, "")
      .split("|")[1]
      .replace("default:", "")
      .trim();
  };

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleClose = (event) => {
    this.handleSubmit();
    this.props.handleCloseVariablePopover(event);
  };

  handleSubmit = () => {
    this.props.handleEntityUpdate(this.getUpdatedText(this.state.value));
  };

  hasDefault = () => {
    const { decoratedText } = this.props;
    return Boolean(decoratedText) && decoratedText.includes("default");
  };

  render() {
    const { anchorEl, decoratedText } = this.props;
    const { value } = this.state;
    const hasDefault = this.hasDefault();
    return (
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={this.handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        {Boolean(decoratedText) && (
          <PopoverContent>
            <HelperText>{this.getText()}</HelperText>
            {hasDefault && (
              <TextFieldContainer>
                <TextField
                  autoFocus
                  fullWidth
                  label="Default"
                  name="default"
                  onChange={this.handleChange}
                  placeholder="there"
                  type="text"
                  value={value}
                  variant="standard"
                />
              </TextFieldContainer>
            )}
          </PopoverContent>
        )}
      </Popover>
    );
  }
}
