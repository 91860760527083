import { all, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import * as schema from "schema";
import generateContainerState from "utils/generateContainerState";

const { actionTypes, actionGenerators, sagas, reducers, selectors } =
  generateContainerState([
    {
      container: "JobAdderCallback",
      crudActions: ["create"],
      recordType: "integration",
      schema: schema.integration,
    },
  ]);

function* saga() {
  yield all([
    takeLatest(
      actionTypes.CREATE_INTEGRATION_REQUEST,
      sagas.createIntegrationRequest,
    ),
  ]);
}

const reducer = combineReducers(reducers);

export { actionTypes, actionGenerators, saga, reducer, selectors };
