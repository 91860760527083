import { all, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";
import get from "lodash/get";

import {
  selectCurrentAccount,
  selectCurrentUser,
  selectRecords,
} from "features/EntryPoint/containers/App/selectors";
import * as schema from "schema";
import generateContainerState from "utils/generateContainerState";
import {
  makeRequest,
  normalizeAndUpdateRecords,
} from "utils/generateContainerState/generateSagas";
import deepEqualSelector from "utils/deepEqualSelector";
import globalNavigationRoutes from "constants/globalNavigationRoutes";
import shallowDenormalize from "utils/shallowDenormalize";
import getPathnameArray from "utils/getPathnameArray";

const {
  actionTypes,
  actionGenerators,
  reducers,
  selectors: bootstrapSelectors,
} = generateContainerState([
  {
    container: "Bootstrap",
    crudActions: ["fetch"],
    idPropsVar: "bootstrapId",
    recordType: "bootstrap",
    schema: schema.bootstrap,
  },
]);

const getCurrentAccountId = (bootstrap) => {
  const [, accountSlug] = getPathnameArray();
  const { accountNavbar, currentUser } = bootstrap;
  const accountsInNavbar = get(accountNavbar, ["members"], []).map(
    ({ account }) => {
      return account;
    },
  );
  const defaultAccountId = `/accounts/${currentUser.settings?.defaultMessagingAccount}`;
  const defaultAccount =
    accountsInNavbar.find((account) => {
      return account === defaultAccountId;
    }) || accountsInNavbar[0];
  return accountSlug && !globalNavigationRoutes.includes(accountSlug)
    ? `/accounts/${accountSlug}`
    : defaultAccount;
};

function* fetchAccountNavbarElement(bootstrap) {
  const [, accountSlug] = getPathnameArray();
  const currentAccountId = getCurrentAccountId(bootstrap);
  const { accountNavbar } = bootstrap;
  const accountsInNavbar = get(accountNavbar, ["members"], []).map(
    ({ account }) => {
      return account;
    },
  );
  const accountNavbarElementNotFound = !accountsInNavbar.find((account) => {
    return account === currentAccountId;
  });
  if (accountNavbarElementNotFound) {
    const account = yield makeRequest({
      url: currentAccountId,
      method: "GET",
    });
    if (account)
      yield normalizeAndUpdateRecords({
        record: account,
        schema: schema.account,
      });
    const accountNavbarElement = yield makeRequest({
      url: `/navbar/${accountSlug}`,
      method: "GET",
    });
    if (accountNavbarElement)
      yield normalizeAndUpdateRecords({
        record: accountNavbarElement,
        schema: schema.accountNavbarElement,
      });
  }
}

function* fetchBootstrapRequestSaga({ url, params }) {
  try {
    const record = yield makeRequest({ url, params, method: "GET" });
    if (record)
      yield normalizeAndUpdateRecords({ record, schema: schema.bootstrap });
    yield fetchAccountNavbarElement(record);
    yield put(actionGenerators.fetchBootstrapSuccess(record));
  } catch (error) {
    yield put(
      actionGenerators.fetchBootstrapFailure(error.response, url, params),
    );
  }
}

function* setTimeZones({ response: bootstrap }) {
  const { currentUser } = bootstrap;
  const accountsToUpdate = get(currentUser, ["memberships", "members"], [])
    .map(({ account }) => {
      return account;
    })
    .filter((account) => {
      return !get(account, ["settings", "timeZone", "value"]);
    });
  yield all(
    accountsToUpdate.map((account) => {
      return makeRequest({
        url: account.id,
        params: {
          settings: {
            timeZone: {
              value: Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
          },
        },
        method: "PATCH",
      });
    }),
  );
}

function* setUpOneSignal() {
  if (global.OneSignal) {
    const user = yield select(selectCurrentUser);
    global.OneSignal.push(() => {
      global.OneSignal.sendTags({
        user: user.id,
      });
    });
  }
}

function* setUserIdInGlobal() {
  const user = yield select(selectCurrentUser);
  const textUs = global.TextUs || {};
  global.TextUs = {
    ...textUs,
    currentUser: user.id,
    currentUserEmail: user.email,
  };
}

const sagas = {
  fetchBootstrapRequestSaga,
  setTimeZones,
  setUpOneSignal,
  setUserIdInGlobal,
};

function* saga() {
  yield all([
    takeLatest(actionTypes.FETCH_BOOTSTRAP_REQUEST, fetchBootstrapRequestSaga),
    takeLatest(actionTypes.FETCH_BOOTSTRAP_SUCCESS, setTimeZones),
    takeLatest(actionTypes.FETCH_BOOTSTRAP_SUCCESS, setUpOneSignal),
    takeLatest(actionTypes.FETCH_BOOTSTRAP_SUCCESS, setUserIdInGlobal),
  ]);
}

const reducer = combineReducers(reducers);

const selectCurrentAccountSiteNavigationId = createSelector(
  selectCurrentAccount,
  (account) => {
    return `/navbar/${account.slug}/site_nav`;
  },
);

const selectCurrentAccountSiteNavigation = deepEqualSelector(
  createSelector(
    selectRecords,
    selectCurrentAccountSiteNavigationId,
    (records, id) => {
      return records.get(id)
        ? shallowDenormalize({ id, records: records.toJS() })
        : {};
    },
  ),
);

const selectors = { ...bootstrapSelectors, selectCurrentAccountSiteNavigation };

export {
  actionGenerators,
  actionTypes,
  fetchAccountNavbarElement,
  reducer,
  saga,
  sagas,
  selectors,
};
