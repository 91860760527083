import styled from "styled-components";

export default styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: 100%;
  overflow-x: auto;
  position: relative;
`;
