import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { SizeMe } from "react-sizeme";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import GettingStarted from "../containers/GettingStarted";
import MessagingAccountsTile from "./MessagingAccountsTile";
import breakpoints from "utils/styles/breakpoints";
import Tile from "components/TopLevelPage/Tile";

import AccountAnalyticRow from "containers/AccountAnalyticRow";
import buildAnalyticsUrl from "utils/buildAnalyticsUrl";

const StyledMessagingAccountsTile = styled(MessagingAccountsTile)``;

const Root = styled.div`
  flex: 1 1 100%;
  width: 100%;
  display: flex;
  flex-flow: column;
  padding-right: 20px;
  overflow: hidden;

  @media (max-width: ${breakpoints.medium - 1}px) {
    padding: 0;

    ${StyledMessagingAccountsTile} {
      order: -1;
    }
  }
`;

const AccountAnalyticWrapper = styled(Tile)`
  min-height: 120px;
`;

export default class MainColumn extends Component {
  static propTypes = {
    accountNavbarElements: PropTypes.array.isRequired,
    animationDuration: PropTypes.number,
    animationStagger: PropTypes.number,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    setCompose: PropTypes.func.isRequired,
    setCurrentAccount: PropTypes.func.isRequired,
  };

  renderAccountAnalyticRow = (account, stagger) => {
    return (
      <SizeMe>
        {({ size: { width } }) => {
          return (
            <AccountAnalyticWrapper {...this.props} isSmall={width < 640}>
              <AccountAnalyticRow
                animationStagger={stagger}
                animationDuration={this.props.animationDuration}
                account={account}
                accountAnalyticsId={buildAnalyticsUrl(account)}
                isSmall={width < 640}
              />
            </AccountAnalyticWrapper>
          );
        }}
      </SizeMe>
    );
  };

  renderContent() {
    const {
      animationDuration,
      animationStagger,
      currentAccount,
      currentUser,
      setCompose,
      setCurrentAccount,
      accountNavbarElements,
    } = this.props;

    const { topLevelAccounts } = currentUser;

    const accountRows = topLevelAccounts.map((account) => {
      return {
        key: `${account.id}-analytics`,
        shouldRender: true,
        render: (stagger) => {
          return this.renderAccountAnalyticRow(account, stagger);
        },
      };
    });

    const messagingEnabled =
      get(currentAccount, ["phoneNumbers"], []).length > 0;

    const gettingStartedElements = get(
      currentUser,
      ["gettingStartedElements", "members"],
      [],
    ).filter(({ elementType }) => {
      return messagingEnabled || elementType !== "test_message";
    });

    const tiles = [
      {
        key: "getting-started",
        shouldRender: gettingStartedElements.length > 0,
        render: (stagger) => {
          return (
            <GettingStarted
              accountNavbarElements={accountNavbarElements}
              animationDuration={animationDuration}
              animationStagger={stagger}
              setCompose={setCompose}
            />
          );
        },
      },
      ...accountRows,
      {
        key: "messaging-accounts",
        shouldRender: accountNavbarElements.length > 1,
        render: (stagger) => {
          return (
            <StyledMessagingAccountsTile
              animationStagger={stagger}
              animationDuration={animationDuration}
              accountNavbarElements={accountNavbarElements}
              setCurrentAccount={setCurrentAccount}
            />
          );
        },
      },
    ];

    return tiles
      .filter((t) => {
        return t.shouldRender;
      })
      .map((t, i) => {
        const tileStagger = (i + 1) * animationDuration;
        return (
          <CSSTransition
            key={t.key}
            timeout={animationDuration + animationStagger + tileStagger}
            classNames="transition"
          >
            {t.render(tileStagger)}
          </CSSTransition>
        );
      });
  }

  render() {
    return (
      <Root>
        <TransitionGroup appear component={null}>
          {this.renderContent()}
        </TransitionGroup>
      </Root>
    );
  }
}
