import { Button, Card, Switch, Typography, styled } from "@mui/material";

export const HeaderText = styled(Typography)({
  color: "#000000E5",
  fontSize: "1.25rem",
  fontWeight: 300,
  padding: "0.1rem",
});

export const LinkText = styled("a")(({ theme }) => {
  return {
    color: theme.palette.primary.contrastText,
    fontWeight: 500,
    textDecoration: "none",
  };
});

export const ModalText = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.text.primary,
    fontSize: "1rem",
    fontWeight: 400,
    marginTop: "0.5rem",
  };
});

export const SubmitButton = styled(Button)({
  marginLeft: "1rem",
});

export const StyledCard = styled(Card)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "7rem",
  boxShadow: "none",
  border: "1px solid #0000001F",
});

export const SubHeaderText = styled(Typography)({
  fontSize: "1rem",
  fontWeight: 500,
  padding: "0.1rem",
});
