import { useEffect, useCallback } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { subscribe, unsubscribe } from "pusher-redux";
import Honeybadger from "@honeybadger-io/js";
import PropTypes from "prop-types";

import get from "lodash/get";
import snakeCase from "lodash/snakeCase";
import toUpper from "lodash/toUpper";

import PusherBatchAuthorizer from "pusher-js-auth";

import actionTypes from "./actionTypes";
import saga from "./saga";
import {
  authEndpoint,
  accountEvents,
  userEvents,
  conversationEvents,
} from "utils/pusherConfig";
import {
  selectAppSettings,
  selectAllAccounts,
  selectCurrentUser,
  selectOidc,
} from "features/EntryPoint/containers/App/selectors";
import { setUpPusher } from "store";
import injectSaga from "utils/injectSaga";
import sessionStorageHelper from "utils/sessionStorageHelper";
import useReconnectEffect from "hooks/useReconnectEffect";

import { CLIENT_VERSION, TESSERACT, POPOUT } from "utils/constants";

const constantized = (val) => {
  return toUpper(snakeCase(val));
};

const getChannel = ({ slug }) => {
  return `private-${slug}@updates`;
};

const getConversationChannel = ({ slug }) => {
  return `private-v2;conversations-${slug}@updates`;
};

const subscribeToChannel = (channel) => {
  accountEvents.forEach((event) => {
    subscribe(channel, event, actionTypes[constantized(event)]);
  });
};

const subscribeToConversationChannel = (channel) => {
  conversationEvents.forEach((event) => {
    subscribe(channel, event, actionTypes[constantized(event)]);
  });
};

const subscribeToUserChannel = (currentUser) => {
  const channel = `private${currentUser.id.replace(/\//g, "-")}@updates`;
  userEvents.forEach((event) => {
    subscribe(channel, event, actionTypes[constantized(event)]);
  });
};

const unsubscribeToChannel = (channel) => {
  accountEvents.forEach((event) => {
    unsubscribe(channel, event, actionTypes[constantized(event)]);
  });
};

const unsubscribeToConversationChannel = (channel) => {
  conversationEvents.forEach((event) => {
    unsubscribe(channel, event, actionTypes[constantized(event)]);
  });
};

const unsubscribeToUserChannel = (currentUser) => {
  const channel = `private${currentUser.id.replace(/\//g, "-")}@updates`;
  userEvents.forEach((event) => {
    unsubscribe(channel, event, actionTypes[constantized(event)]);
  });
};

const sendHoneybadgerEvent = (message, tags) => {
  return Honeybadger.notify(message, {
    context: {
      user_id: get(window, ["TextUs", "currentUser"], "User id not found."),
      user_email: get(
        window,
        ["TextUs", "currentUserEmail"],
        "User email not found.",
      ),
      occured_in_extension: window.self !== window.top,
      url: get(window, ["location", "href"], "Unknown url"),
    },
    tags,
  });
};

function PusherComponent({ allAccounts, appSettings, currentUser, oidc }) {
  const isValid = useCallback(() => {
    if (!oidc?.user || !oidc?.user?.expires_at || !oidc?.user?.access_token)
      return false;
    return new Date(oidc.user.expires_at * 1000) > new Date();
  }, [oidc.user]);

  const connectPusher = useCallback(() => {
    if (isValid()) {
      if (currentUser) {
        unsubscribeToUserChannel(currentUser);
      }
      allAccounts.forEach((account) => {
        const channel = getChannel(account);
        const conversationChannel = getConversationChannel(account);
        unsubscribeToChannel(channel);
        unsubscribeToConversationChannel(conversationChannel);
      });
      if (currentUser) {
        subscribeToUserChannel(currentUser);
      }
      allAccounts.forEach((account) => {
        const channel = getChannel(account);
        const conversationChannel = getConversationChannel(account);
        subscribeToChannel(channel);
        subscribeToConversationChannel(conversationChannel);
      });
    }
  }, [allAccounts, currentUser, isValid]);

  const disconnect = useCallback(() => {
    return null;
  }, []);

  const reconnect = useCallback(() => {
    connectPusher();
  }, [connectPusher]);

  useEffect(() => {
    if (process.env.NODE_ENV === "test") return;
    const accessToken =
      oidc?.user?.access_token || sessionStorageHelper.getItem("access_token");

    const inPopout = window.self !== window.top;
    if (accessToken) {
      setUpPusher(appSettings.PUSHER_KEY, {
        activityTimeout: appSettings.PUSHER_ACTIVITY_TIMEOUT,
        pongTimeout: appSettings.PUSHER_PONG_TIMEOUT,
        authorizer: PusherBatchAuthorizer,
        authDelay: 100,
        cluster: "mt1",
        authEndpoint,
        auth: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "X-TextUs-Client": inPopout ? POPOUT : TESSERACT,
            "X-TextUs-Client-Version": CLIENT_VERSION,
          },
        },
      });
      connectPusher();
    }
  }, [
    appSettings.PUSHER_ACTIVITY_TIMEOUT,
    appSettings.PUSHER_KEY,
    appSettings.PUSHER_PONG_TIMEOUT,
    connectPusher,
    disconnect,
    oidc,
  ]);

  useReconnectEffect(reconnect, disconnect);

  return null;
}

PusherComponent.propTypes = {
  allAccounts: PropTypes.array.isRequired,
  appSettings: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  oidc: PropTypes.object,
};

const mapStateToProps = (state, props) => {
  return {
    allAccounts: selectAllAccounts(state, props),
    appSettings: selectAppSettings(state, props),
    currentUser: selectCurrentUser(state, props),
    oidc: selectOidc(state, props),
  };
};

const withConnect = connect(mapStateToProps);

const withSaga = injectSaga({ key: "pusherComponent", saga });

export default compose(withSaga, withConnect)(PusherComponent);
