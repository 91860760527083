import { Box, Button, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { isEmpty, isEqual } from "lodash";
import { SequenceFormInputs } from "../SequenceFormInputs";
import { SettingsTabContentProps, SequenceFormValues } from "./types";
import { DeliveryWindowSection } from "./components/DeliveryWindowSection";
import { DELIVERY_WINDOW_OPTIONS } from "./constants";
import { KeywordSubscribersSection } from "./components/KeywordSubscribersSection";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";
import { useSequenceSettings } from "features/Sequences/hooks/useSequenceSettings";

function SettingsTabContent({
  sequence,
  refreshPage,
  unlinkKeyword,
}: SettingsTabContentProps) {
  const { radioSelection, handleChangeRadio, handleSubmit } =
    useSequenceSettings(sequence, refreshPage);

  const { deliveryWindow } = sequence.settings;

  const initialValues: SequenceFormValues = {
    sequenceName: sequence.name,
    scheduleType: sequence.scheduleType,
    finishedOnReply: sequence.finishedOnReply,
    settings: {
      deliveryWindow: {
        startDay: deliveryWindow ? deliveryWindow.startDay : "",
        endDay: deliveryWindow ? deliveryWindow.endDay : "",
        startTime: deliveryWindow ? deliveryWindow.startTime : "",
        endTime: deliveryWindow ? deliveryWindow.endTime : "",
      },
    },
  };

  const validate = (values: SequenceFormValues) => {
    const { anytime, restricted } = DELIVERY_WINDOW_OPTIONS;

    const hasDeliveryWindowValues = Object.values(
      values.settings.deliveryWindow,
    ).every((value) => {
      return typeof value === "number";
    });

    const needsDeliveryWindowValues = Object.values(
      values.settings.deliveryWindow,
    ).some((value) => {
      return typeof value === "string";
    });

    /**
     * If a user clicks radio button to change from anytime to restricted,
     * but has not selected values, return true to disable the save button.
     */
    if (radioSelection === restricted && needsDeliveryWindowValues) {
      return true;
    }

    /**
     * If a user has selected the restricted radio button and has Delivery Window values,
     * disable the Save button unless the form values have changed.
     */
    if (radioSelection === restricted && hasDeliveryWindowValues) {
      return (
        isEqual(values.settings, sequence.settings) &&
        sequence.name === values.sequenceName
      );
    }

    /**
     * If a user is switching from restricted to anytime, do not disable the save button.
     * Preserves form values for if a user clicks back to restricted.
     */
    if (radioSelection === anytime && hasDeliveryWindowValues) {
      return isEmpty(sequence.settings)
        ? sequence.name === values.sequenceName
        : false;
    }

    /**
     * Default return handles absolute and anytime sequences without a Delivery Window.
     */
    return sequence.name === values.sequenceName;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="flex-start"
      marginTop="2rem"
      marginBottom="2rem"
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ values }) => {
          return (
            <Form placeholder="Sequence settings form">
              <Typography variant="body1" fontWeight={700}>
                Basic Details
              </Typography>
              <SequenceFormInputs userFlow="settings" />
              {sequence.scheduleType === SCHEDULE_TYPES.relative ? (
                <>
                  <Typography
                    variant="body1"
                    fontWeight={700}
                    marginTop="2rem"
                    marginBottom="1rem"
                  >
                    Delivery Window
                  </Typography>
                  <DeliveryWindowSection
                    radioSelection={radioSelection}
                    handleChangeRadio={handleChangeRadio}
                  />
                </>
              ) : null}

              <KeywordSubscribersSection
                sequence={sequence}
                refreshPage={refreshPage}
                unlinkKeyword={unlinkKeyword}
              />

              <Box
                display="flex"
                flexDirection="row"
                justifyContent="end"
                marginTop="2rem"
              >
                <Button
                  aria-label="Save Sequence Settings"
                  color="primary"
                  size="medium"
                  data-testid="save-sequence-settings-button"
                  disabled={validate(values)}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}

export { SettingsTabContent };
