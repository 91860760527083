import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { actionGenerators, saga, reducer, selectors } from "./state";
import Root from "./components/Root";
import { selectExtractedPhones } from "features/EntryPoint/containers/App/selectors";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";

class ExtensionContactImport extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    contactImport: selectors.selectContactImport(state, props),
    extractedPhones: selectExtractedPhones(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "ExtensionContactImportContainer",
  reducer,
});

const withSaga = injectSaga({ key: "ExtensionContactImportContainer", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(ExtensionContactImport);
