import assert from "assert";
import { useCallback, useState } from "react";

import { Chip, Theme, useMediaQuery } from "@mui/material";
import {
  GridRowSelectionModel,
  useGridApiRef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import { GridStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";

import { useSnackbar } from "notistack";

import Avatar from "components/Avatar";
import { useCurrentAccount } from "hooks";
import { useDialog } from "components/Dialog";
import { SequencesContact } from "features/Sequences/screens/IndividualSequenceOverview/types";
import { finishSequenceRecipients } from "features/Sequences/api";
import { formatSequenceContactRows } from "features/Sequences/screens/IndividualSequenceOverview/utils";

import { RowState } from "features/Sequences/components/SequencesContactsTab/types";
import {
  FetchSequenceRecipientsResponse,
  SequenceRecipientsPage,
} from "features/Sequences/components/ContactsDataGrid/types";

const useContactsDataGrid = (
  sequenceId: string,
  rowState: RowState,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  pagination: SequenceRecipientsPage | null,
) => {
  const fullScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("md");
  });
  const apiRef = useGridApiRef();
  const { ref: dialogRef, open: openQuickCompose } = useDialog<
    string,
    boolean
  >();
  const currentAccount = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();

  const [allSelected, setAllSelected] = useState(false);
  const [isAddGroupModalOpen, setIsAddGroupModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<SequencesContact[]>([]);

  const { rows, setRows } = rowState;
  const selectedRowCount = selectedRows.length;
  const isSelectionMade = selectedRowCount > 0;
  const rowCount = rows.length;
  const isEmpty = rowCount === 0;

  const renderStatusChip = (params: GridRenderCellParams) => {
    const { state, reason } = params.row;
    return <Chip label={`${state}${reason ? `, ${reason}` : ""}`} />;
  };

  const renderEnrollerAvatar = (params: GridRenderCellParams) => {
    const { row }: { row: SequencesContact } = params;
    const { enroller, enrollerAvatar, type } = row.user;
    // Reformatting to fit the expected shape in the Avatar component
    const subject = {
      name: enroller,
      avatar: enrollerAvatar,
      "@type": type,
    };
    return <Avatar subject={subject} tooltip={enroller} />;
  };

  const formattedRecipients = selectedRows.map((row) => {
    return {
      ...row,
      formattedPhoneNumber: row.phoneNumber,
      id: `/contact_phones/${row.id}`,
      contact: { name: row.name },
    };
  });

  const formattedGroupContacts = selectedRows.map((row) => {
    return `/contacts/${row.contactId}`;
  });

  const handleCheck = (contactId: GridRowSelectionModel) => {
    const selected = rows.filter((row) => {
      return contactId.includes(row.id);
    });
    setSelectedRows(selected);
  };

  const gridSelectAll = useCallback(() => {
    const rowContactIds = rows.map((row) => {
      return row.id;
    });
    apiRef.current.setState((prevState: GridStatePro) => {
      return { ...prevState, rowSelection: rowContactIds };
    });
  }, [apiRef, rows]);

  const handleSelectAll = () => {
    setSelectedRows(rows);
    gridSelectAll();
  };

  const handleUnselectAll = useCallback(() => {
    setSelectedRows([]);
    setAllSelected(false);
    apiRef.current.setState((prevState: GridStatePro) => {
      return { ...prevState, rowSelection: [] };
    });
  }, [apiRef]);

  const toggleAllSelected = () => {
    handleSelectAll();
    setAllSelected(!allSelected);
    return allSelected ? handleUnselectAll() : handleSelectAll();
  };

  const toggleAddGroupModal = () => {
    setIsAddGroupModalOpen(!isAddGroupModalOpen);
  };

  const toggleConfirmationModal = () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
  };

  const getUpdatedRowValue = (
    items: SequencesContact[],
    row: SequencesContact,
    property: "state" | "reason",
  ) => {
    const updatedRow = items.find((item) => {
      return item.id === row.id;
    });
    return updatedRow ? updatedRow[property] : row[property];
  };

  const getUpdatedRows = useCallback(
    (allRows: SequencesContact[], formattedItems: SequencesContact[]) => {
      return allRows.map((row) => {
        const itemIds = formattedItems.map((item) => {
          return item.id;
        });
        if (itemIds.includes(row.id)) {
          const updatedRow = row;
          updatedRow.state = getUpdatedRowValue(formattedItems, row, "state");
          updatedRow.reason = getUpdatedRowValue(formattedItems, row, "reason");
          return updatedRow;
        }
        return row;
      });
    },
    [],
  );

  const markAsFinished = useCallback(
    async (allRows: SequencesContact[], selected: SequencesContact[]) => {
      const recipientIds = selected.map((row) => {
        return row.id;
      });

      try {
        const response = await finishSequenceRecipients(
          currentAccount,
          sequenceId,
          recipientIds,
          allSelected,
        );

        assert(response.ok);

        if (allSelected) {
          const finishedRows = rows.map((row) => {
            return { ...row, state: "Finished", reason: "stopped" };
          });

          setRows(finishedRows);

          enqueueSnackbar(
            `Successfully marked ${pagination?.count} ${
              pagination?.count === 1 ? "contact" : "contacts"
            } finished, stopped.`,
            {
              variant: "info",
            },
          );
        } else {
          const { items }: FetchSequenceRecipientsResponse =
            await response.json();

          enqueueSnackbar(
            `Successfully marked ${items.length} ${
              items.length === 1 ? "contact" : "contacts"
            } finished, stopped.`,
            {
              variant: "info",
            },
          );

          const formattedItems = formatSequenceContactRows(items);
          const updatedRows = getUpdatedRows(allRows, formattedItems);
          setRows([...updatedRows]);
        }
      } catch (error) {
        enqueueSnackbar("Failed to mark contacts as finished, stopped.", {
          variant: "error",
        });
        throw new Error(
          `Error marking contacts as finished: ${error as string}`,
        );
      }
    },
    [
      allSelected,
      currentAccount,
      enqueueSnackbar,
      getUpdatedRows,
      pagination,
      rows,
      sequenceId,
      setRows,
    ],
  );

  return {
    allSelected,
    apiRef,
    dialogRef,
    formattedGroupContacts,
    formattedRecipients,
    fullScreen,
    isAddGroupModalOpen,
    isConfirmationModalOpen,
    isEmpty,
    isSelectionMade,
    rowCount,
    selectedRows,
    selectedRowCount,

    gridSelectAll,
    handleCheck,
    handleSelectAll,
    handleUnselectAll,
    markAsFinished,
    openQuickCompose,
    renderEnrollerAvatar,
    renderStatusChip,
    setAllSelected,
    toggleAddGroupModal,
    toggleAllSelected,
    toggleConfirmationModal,
  };
};

export { useContactsDataGrid };
