import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Root from "./components/Root";
import {
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import withRecord from "higherOrderComponents/withRecord";

class SavedReply extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withRecord({
    actions: ["fetch"],
    container:
      "features/SavedReplies/containers/SavedReply/savedReplyCollection",
    noFetch: true,
    shape: { members: [{ user: {} }] },
    showLoader: () => {
      return false;
    },
    type: "savedReplyCollection",
  }),
  withConnect,
)(SavedReply);
