import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import { useState } from "react";
import { NavListItemButtonProps } from "./types";
import { StyledNavLink } from "./styles";

export default function NavListItemButton({
  primary,
  secondary,
  to,
  icon,
  nested = false,
  showSecondaryOnActive = false,
  showSecondaryOnHover = false,
  secondaryAction,
  handleClick,
}: NavListItemButtonProps) {
  const [isHovering, setIsHovering] = useState(false);

  const showSecondary = (!showSecondaryOnActive && !showSecondaryOnHover)
    || showSecondaryOnActive && window.location.href.includes(to)
    || showSecondaryOnHover && isHovering;

  const listItem = (
    <ListItem
      disablePadding
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
      secondaryAction={showSecondary ? secondaryAction : undefined}
    >
      <ListItemButton
        onClick={handleClick}
        selected={window.location.href.includes(to)}
        sx={(theme) => {
          return {
            paddingLeft: nested === false ? "8px" : "40px",

            "&.Mui-selected": {
              background: theme.palette.lightBlue.A12,

              "& .MuiTypography-root": {
                fontWeight: 700,
              },
            },
          };
        }}
      >
        {icon}
        <ListItemText primary={primary} secondary={secondary} />
      </ListItemButton>
    </ListItem>
  );

  // Render as a link.
  if (to) {
    return <StyledNavLink to={to}>{listItem}</StyledNavLink>;
  }

  // Render as a standard list item button.
  return listItem;
}
