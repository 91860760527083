import { Box, Divider, Grid, Skeleton } from "@mui/material";
import PageHeader from "components/Page/PageHeader";

type CumulativeBillingLoadingProps = {
  hasOverage: boolean;
  toggleSidebar: () => void;
};

function CumulativeBillingLoading({
  hasOverage,
  toggleSidebar,
}: CumulativeBillingLoadingProps) {
  return (
    <Box
      data-testid="cumulative-billing-skeleton"
      display="flex"
      flexDirection="column"
      height="100%"
      overflow="scroll"
    >
      <PageHeader
        aria-label="Billing Analytics"
        title="Billing"
        toggleSidebar={toggleSidebar}
      ></PageHeader>
      <Box
        display="flex"
        flexDirection="column"
        maxWidth="100%"
        height="1500px"
      >
        <Box
          display="flex"
          flexDirection="column"
          m="0 auto"
          maxWidth="1280px"
          height="100px"
          width={{ md: "100%", lg: "75%" }}
          paddingRight={4}
          paddingLeft={4}
          paddingTop={1}
          paddingBottom={0}
          marginRight={{ xs: "100%", lg: "12.5%" }}
        >
          <Skeleton
            height="100px"
            sx={{ width: { xs: "195px", md: "220px" } }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          m="0 auto"
          maxWidth="1280px"
          height="80px"
          width={{ md: "100%", lg: "75%" }}
          padding={4}
          sx={{
            mr: {
              xs: "100%",
              lg: "12.5%",
            },
            pt: {
              xs: "36px",
              md: "10px",
            },
            pb: {
              xs: "60px",
              md: "0px",
            },
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            flexDirection={{ xs: "row", sm: "row" }}
            flex="0 0 auto"
            justifyContent="space-between"
            height="60px"
          >
            <Box
              display="flex"
              alignItems="center"
              width="100%"
              flexDirection={{
                xs: "column",
                md: "row",
              }}
              justifyContent={{
                xs: "flex-start",
                md: "space-between",
              }}
              paddingTop={{
                xs: "55px",
                md: "0px",
              }}
            >
              <Skeleton
                height="50px"
                width="129px"
                sx={{ alignSelf: { xs: "flex-start", md: "center" } }}
              />
              <Box
                display="flex"
                alignItems="center"
                width="265px"
                paddingTop="0px"
                paddingBottom={{
                  xs: "30px",
                  md: "0px",
                }}
              >
                <Skeleton height="24px" width="38px" />
                <Skeleton
                  height="80px"
                  sx={{
                    width: { xs: "190px", sm: "220px" },
                    ml: "8px",
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          m="0 auto"
          maxWidth="1280px"
          minHeight="0px"
          width={{ xs: "100%", lg: "75%" }}
          padding={4}
          paddingTop={{ xs: "50px", md: "0px" }}
          paddingBottom={{ xs: "0px", md: "0px" }}
        >
          <Grid alignContent="center" container spacing={2}>
            <Skeleton
              variant="rectangular"
              height="110px"
              width="100%"
              sx={{ mt: "20px", ml: "17px" }}
            />
            <Box
              display="flex"
              flexDirection={{ xs: "column", md: "row" }}
              width="100%"
              marginLeft="17px"
            >
              <Skeleton
                variant="rectangular"
                height="110px"
                sx={{
                  mt: "20px",
                  width: { xs: "100%", md: "50%" },
                }}
              />
              <Skeleton
                variant="rectangular"
                height="110px"
                sx={{
                  mt: "20px",
                  ml: { xs: "0px", md: "17px" },
                  width: { xs: "100%", md: "50%" },
                }}
              />
            </Box>
            {hasOverage && (
              <Box
                data-testid="alert-skeleton"
                height="85px"
                width="100%"
                paddingTop="15px"
                marginLeft="17px"
              >
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  sx={{
                    height: { xs: "80px", sm: "70px", xl: "55px" },
                  }}
                />
              </Box>
            )}

            <Box
              display="flex"
              flexDirection="column"
              sx={{
                marginTop: { xs: "20px", sm: "15px" },
              }}
            >
              <Skeleton
                variant="rectangular"
                width="176px"
                height="24px"
                sx={{ ml: "17px" }}
              />
              <Box display="flex" flexDirection="row">
                <Skeleton
                  variant="rectangular"
                  width="135px"
                  height="26px"
                  sx={{ ml: "17px", mt: "5px" }}
                />
                <Skeleton
                  variant="rectangular"
                  width="114px"
                  height="26px"
                  sx={{ ml: "8px", mt: "5px" }}
                />
              </Box>
            </Box>
          </Grid>

          <Divider
            sx={{
              mt: "16px",
              mb: "25px",
            }}
          />

          <Box
            display="flex"
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="center"
            flexDirection={{ xs: "column", sm: "row" }}
            height={{ xs: "175px", sm: "56px" }}
          >
            <Skeleton variant="rectangular" width="137px" height="28px" />
            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent={{ xs: "flex-start", sm: "flex-end" }}
              marginTop={{ xs: "15px", sm: "0px" }}
            >
              <Skeleton
                variant="circular"
                height="24px"
                width="24px"
                sx={{ mr: "24px" }}
              />
              <Skeleton
                variant="circular"
                height="24px"
                width="24px"
                sx={{ mr: "16px" }}
              />
              <Skeleton variant="rectangular" width="220px" height="56px" />
            </Box>
          </Box>

          <Skeleton
            variant="rectangular"
            height="700px"
            sx={{ mt: "16px", mb: "128px" }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default CumulativeBillingLoading;
