const getCountryFlag = (isoCode: string) => {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode.toUpperCase().replace(/./g, (char) => {
        return String.fromCodePoint(char.charCodeAt(0) + 127397);
      })
    : isoCode;
};

const countries = [
  { code: "ad", label: "Andorra", phone: "376" },
  { code: "ae", label: "United Arab Emirates", phone: "971" },
  { code: "af", label: "Afghanistan", phone: "93" },
  { code: "ag", label: "Antigua and Barbuda", phone: "1-268" },
  { code: "ai", label: "Anguilla", phone: "1-264" },
  { code: "al", label: "Albania", phone: "355" },
  { code: "am", label: "Armenia", phone: "374" },
  { code: "ao", label: "Angola", phone: "244" },
  { code: "aq", label: "Antarctica", phone: "672" },
  { code: "ar", label: "Argentina", phone: "54" },
  { code: "as", label: "American Samoa", phone: "1-684" },
  { code: "at", label: "Austria", phone: "43" },
  { code: "au", label: "Australia", phone: "61", suggested: true },
  { code: "aw", label: "Aruba", phone: "297" },
  { code: "ax", label: "Alland Islands", phone: "358" },
  { code: "az", label: "Azerbaijan", phone: "994" },
  { code: "ba", label: "Bosnia and Herzegovina", phone: "387" },
  { code: "bb", label: "Barbados", phone: "1-246" },
  { code: "bd", label: "Bangladesh", phone: "880" },
  { code: "be", label: "Belgium", phone: "32" },
  { code: "bf", label: "Burkina Faso", phone: "226" },
  { code: "bg", label: "Bulgaria", phone: "359" },
  { code: "bh", label: "Bahrain", phone: "973" },
  { code: "bi", label: "Burundi", phone: "257" },
  { code: "bj", label: "Benin", phone: "229" },
  { code: "bl", label: "Saint Barthelemy", phone: "590" },
  { code: "bm", label: "Bermuda", phone: "1-441" },
  { code: "bn", label: "Brunei Darussalam", phone: "673" },
  { code: "bo", label: "Bolivia", phone: "591" },
  { code: "br", label: "Brazil", phone: "55" },
  { code: "bs", label: "Bahamas", phone: "1-242" },
  { code: "bt", label: "Bhutan", phone: "975" },
  { code: "bv", label: "Bouvet Island", phone: "47" },
  { code: "bw", label: "Botswana", phone: "267" },
  { code: "by", label: "Belarus", phone: "375" },
  { code: "bz", label: "Belize", phone: "501" },
  { code: "ca", label: "Canada", phone: "1", suggested: true },
  { code: "cc", label: "Cocos (Keeling) Islands", phone: "61" },
  { code: "cd", label: "Congo, Democratic Republic of the", phone: "243" },
  { code: "cf", label: "Central African Republic", phone: "236" },
  { code: "cg", label: "Congo, Republic of the", phone: "242" },
  { code: "ch", label: "Switzerland", phone: "41" },
  { code: "ci", label: "Cote d'Ivoire", phone: "225" },
  { code: "ck", label: "Cook Islands", phone: "682" },
  { code: "cl", label: "Chile", phone: "56" },
  { code: "cm", label: "Cameroon", phone: "237" },
  { code: "cn", label: "China", phone: "86" },
  { code: "co", label: "Colombia", phone: "57" },
  { code: "cr", label: "Costa Rica", phone: "506" },
  { code: "cu", label: "Cuba", phone: "53" },
  { code: "cv", label: "Cape Verde", phone: "238" },
  { code: "cw", label: "Curacao", phone: "599" },
  { code: "cx", label: "Christmas Island", phone: "61" },
  { code: "cy", label: "Cyprus", phone: "357" },
  { code: "cz", label: "Czech Republic", phone: "420" },
  { code: "de", label: "Germany", phone: "49", suggested: true },
  { code: "dj", label: "Djibouti", phone: "253" },
  { code: "dk", label: "Denmark", phone: "45" },
  { code: "dm", label: "Dominica", phone: "1-767" },
  { code: "do", label: "Dominican Republic", phone: "1-809" },
  { code: "dz", label: "Algeria", phone: "213" },
  { code: "ec", label: "Ecuador", phone: "593" },
  { code: "ee", label: "Estonia", phone: "372" },
  { code: "eg", label: "Egypt", phone: "20" },
  { code: "eh", label: "Western Sahara", phone: "212" },
  { code: "er", label: "Eritrea", phone: "291" },
  { code: "es", label: "Spain", phone: "34" },
  { code: "et", label: "Ethiopia", phone: "251" },
  { code: "fi", label: "Finland", phone: "358" },
  { code: "fj", label: "Fiji", phone: "679" },
  { code: "fk", label: "Falkland Islands (Malvinas)", phone: "500" },
  { code: "fm", label: "Micronesia, Federated States of", phone: "691" },
  { code: "fo", label: "Faroe Islands", phone: "298" },
  { code: "fr", label: "France", phone: "33", suggested: true },
  { code: "ga", label: "Gabon", phone: "241" },
  { code: "gb", label: "United Kingdom", phone: "44" },
  { code: "gd", label: "Grenada", phone: "1-473" },
  { code: "ge", label: "Georgia", phone: "995" },
  { code: "gf", label: "French Guiana", phone: "594" },
  { code: "gg", label: "Guernsey", phone: "44" },
  { code: "gh", label: "Ghana", phone: "233" },
  { code: "gi", label: "Gibraltar", phone: "350" },
  { code: "gl", label: "Greenland", phone: "299" },
  { code: "gm", label: "Gambia", phone: "220" },
  { code: "gn", label: "Guinea", phone: "224" },
  { code: "gp", label: "Guadeloupe", phone: "590" },
  { code: "gq", label: "Equatorial Guinea", phone: "240" },
  { code: "gr", label: "Greece", phone: "30" },
  {
    code: "gs",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
  },
  { code: "gt", label: "Guatemala", phone: "502" },
  { code: "gu", label: "Guam", phone: "1-671" },
  { code: "gw", label: "Guinea-Bissau", phone: "245" },
  { code: "gy", label: "Guyana", phone: "592" },
  { code: "hk", label: "Hong Kong", phone: "852" },
  { code: "hm", label: "Heard Island and McDonald Islands", phone: "672" },
  { code: "hn", label: "Honduras", phone: "504" },
  { code: "hr", label: "Croatia", phone: "385" },
  { code: "ht", label: "Haiti", phone: "509" },
  { code: "hu", label: "Hungary", phone: "36" },
  { code: "id", label: "Indonesia", phone: "62" },
  { code: "ie", label: "Ireland", phone: "353" },
  { code: "il", label: "Israel", phone: "972" },
  { code: "im", label: "Isle of Man", phone: "44" },
  { code: "in", label: "India", phone: "91" },
  { code: "io", label: "British Indian Ocean Territory", phone: "246" },
  { code: "iq", label: "Iraq", phone: "964" },
  { code: "ir", label: "Iran, Islamic Republic of", phone: "98" },
  { code: "is", label: "Iceland", phone: "354" },
  { code: "it", label: "Italy", phone: "39" },
  { code: "je", label: "Jersey", phone: "44" },
  { code: "jm", label: "Jamaica", phone: "1-876" },
  { code: "jo", label: "Jordan", phone: "962" },
  { code: "jp", label: "Japan", phone: "81", suggested: true },
  { code: "ke", label: "Kenya", phone: "254" },
  { code: "kg", label: "Kyrgyzstan", phone: "996" },
  { code: "kh", label: "Cambodia", phone: "855" },
  { code: "ki", label: "Kiribati", phone: "686" },
  { code: "km", label: "Comoros", phone: "269" },
  { code: "kn", label: "Saint Kitts and Nevis", phone: "1-869" },
  { code: "kp", label: "Korea, Democratic People's Republic of", phone: "850" },
  { code: "kr", label: "Korea, Republic of", phone: "82" },
  { code: "kw", label: "Kuwait", phone: "965" },
  { code: "ky", label: "Cayman Islands", phone: "1-345" },
  { code: "kz", label: "Kazakhstan", phone: "7" },
  { code: "la", label: "Lao People's Democratic Republic", phone: "856" },
  { code: "lb", label: "Lebanon", phone: "961" },
  { code: "lc", label: "Saint Lucia", phone: "1-758" },
  { code: "li", label: "Liechtenstein", phone: "423" },
  { code: "lk", label: "Sri Lanka", phone: "94" },
  { code: "lr", label: "Liberia", phone: "231" },
  { code: "ls", label: "Lesotho", phone: "266" },
  { code: "lt", label: "Lithuania", phone: "370" },
  { code: "lu", label: "Luxembourg", phone: "352" },
  { code: "lv", label: "Latvia", phone: "371" },
  { code: "ly", label: "Libya", phone: "218" },
  { code: "ma", label: "Morocco", phone: "212" },
  { code: "mc", label: "Monaco", phone: "377" },
  { code: "md", label: "Moldova, Republic of", phone: "373" },
  { code: "me", label: "Montenegro", phone: "382" },
  { code: "mf", label: "Saint Martin (French part)", phone: "590" },
  { code: "mg", label: "Madagascar", phone: "261" },
  { code: "mh", label: "Marshall Islands", phone: "692" },
  {
    code: "mk",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
  },
  { code: "ml", label: "Mali", phone: "223" },
  { code: "mm", label: "Myanmar", phone: "95" },
  { code: "mn", label: "Mongolia", phone: "976" },
  { code: "mo", label: "Macao", phone: "853" },
  { code: "mp", label: "Northern Mariana Islands", phone: "1-670" },
  { code: "mq", label: "Martinique", phone: "596" },
  { code: "mr", label: "Mauritania", phone: "222" },
  { code: "ms", label: "Montserrat", phone: "1-664" },
  { code: "mt", label: "Malta", phone: "356" },
  { code: "mu", label: "Mauritius", phone: "230" },
  { code: "mv", label: "Maldives", phone: "960" },
  { code: "mw", label: "Malawi", phone: "265" },
  { code: "mx", label: "Mexico", phone: "52" },
  { code: "my", label: "Malaysia", phone: "60" },
  { code: "mz", label: "Mozambique", phone: "258" },
  { code: "na", label: "Namibia", phone: "264" },
  { code: "nc", label: "New Caledonia", phone: "687" },
  { code: "ne", label: "Niger", phone: "227" },
  { code: "nf", label: "Norfolk Island", phone: "672" },
  { code: "ng", label: "Nigeria", phone: "234" },
  { code: "ni", label: "Nicaragua", phone: "505" },
  { code: "nl", label: "Netherlands", phone: "31" },
  { code: "no", label: "Norway", phone: "47" },
  { code: "np", label: "Nepal", phone: "977" },
  { code: "nr", label: "Nauru", phone: "674" },
  { code: "nu", label: "Niue", phone: "683" },
  { code: "nz", label: "New Zealand", phone: "64" },
  { code: "om", label: "Oman", phone: "968" },
  { code: "pa", label: "Panama", phone: "507" },
  { code: "pe", label: "Peru", phone: "51" },
  { code: "pf", label: "French Polynesia", phone: "689" },
  { code: "pg", label: "Papua New Guinea", phone: "675" },
  { code: "ph", label: "Philippines", phone: "63" },
  { code: "pk", label: "Pakistan", phone: "92" },
  { code: "pl", label: "Poland", phone: "48" },
  { code: "pm", label: "Saint Pierre and Miquelon", phone: "508" },
  { code: "pn", label: "Pitcairn", phone: "870" },
  { code: "pr", label: "Puerto Rico", phone: "1" },
  { code: "ps", label: "Palestine, State of", phone: "970" },
  { code: "pt", label: "Portugal", phone: "351" },
  { code: "pw", label: "Palau", phone: "680" },
  { code: "py", label: "Paraguay", phone: "595" },
  { code: "qa", label: "Qatar", phone: "974" },
  { code: "re", label: "Reunion", phone: "262" },
  { code: "ro", label: "Romania", phone: "40" },
  { code: "rs", label: "Serbia", phone: "381" },
  { code: "ru", label: "Russian Federation", phone: "7" },
  { code: "rw", label: "Rwanda", phone: "250" },
  { code: "sa", label: "Saudi Arabia", phone: "966" },
  { code: "sb", label: "Solomon Islands", phone: "677" },
  { code: "sc", label: "Seychelles", phone: "248" },
  { code: "sd", label: "Sudan", phone: "249" },
  { code: "se", label: "Sweden", phone: "46" },
  { code: "sg", label: "Singapore", phone: "65" },
  { code: "sh", label: "Saint Helena", phone: "290" },
  { code: "si", label: "Slovenia", phone: "386" },
  { code: "sj", label: "Svalbard and Jan Mayen", phone: "47" },
  { code: "sk", label: "Slovakia", phone: "421" },
  { code: "sl", label: "Sierra Leone", phone: "232" },
  { code: "sm", label: "San Marino", phone: "378" },
  { code: "sn", label: "Senegal", phone: "221" },
  { code: "so", label: "Somalia", phone: "252" },
  { code: "sr", label: "Suriname", phone: "597" },
  { code: "ss", label: "South Sudan", phone: "211" },
  { code: "st", label: "Sao Tome and Principe", phone: "239" },
  { code: "sv", label: "El Salvador", phone: "503" },
  { code: "sx", label: "Sint Maarten (Dutch part)", phone: "1-721" },
  { code: "sy", label: "Syrian Arab Republic", phone: "963" },
  { code: "sz", label: "Swaziland", phone: "268" },
  { code: "tc", label: "Turks and Caicos Islands", phone: "1-649" },
  { code: "td", label: "Chad", phone: "235" },
  { code: "tf", label: "French Southern Territories", phone: "262" },
  { code: "tg", label: "Togo", phone: "228" },
  { code: "th", label: "Thailand", phone: "66" },
  { code: "tj", label: "Tajikistan", phone: "992" },
  { code: "tk", label: "Tokelau", phone: "690" },
  { code: "tl", label: "Timor-Leste", phone: "670" },
  { code: "tm", label: "Turkmenistan", phone: "993" },
  { code: "tn", label: "Tunisia", phone: "216" },
  { code: "to", label: "Tonga", phone: "676" },
  { code: "tr", label: "Turkey", phone: "90" },
  { code: "tt", label: "Trinidad and Tobago", phone: "1-868" },
  { code: "tv", label: "Tuvalu", phone: "688" },
  { code: "tw", label: "Taiwan, Province of China", phone: "886" },
  { code: "tz", label: "United Republic of Tanzania", phone: "255" },
  { code: "ua", label: "Ukraine", phone: "380" },
  { code: "ug", label: "Uganda", phone: "256" },
  {
    code: "us",
    label: "United States of America",
    phone: "1",
    suggested: true,
  },
  { code: "uy", label: "Uruguay", phone: "598" },
  { code: "uz", label: "Uzbekistan", phone: "998" },
  { code: "va", label: "Holy See (Vatican City State)", phone: "379" },
  { code: "vc", label: "Saint Vincent and the Grenadines", phone: "1-784" },
  { code: "ve", label: "Venezuela", phone: "58" },
  { code: "vg", label: "British Virgin Islands", phone: "1-284" },
  { code: "vi", label: "US Virgin Islands", phone: "1-340" },
  { code: "vn", label: "Vietnam", phone: "84" },
  { code: "vu", label: "Vanuatu", phone: "678" },
  { code: "wf", label: "Wallis and Futuna", phone: "681" },
  { code: "ws", label: "Samoa", phone: "685" },
  { code: "xk", label: "Kosovo", phone: "383" },
  { code: "ye", label: "Yemen", phone: "967" },
  { code: "yt", label: "Mayotte", phone: "262" },
  { code: "za", label: "South Africa", phone: "27" },
  { code: "zm", label: "Zambia", phone: "260" },
  { code: "zw", label: "Zimbabwe", phone: "263" },
];

const countriesWithFlags = countries.map((country) => {
  return {
    ...country,
    flag: getCountryFlag(country.code),
  };
});

export default countriesWithFlags;
