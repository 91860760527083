import { TextUsColor } from "./types";

export const red: TextUsColor = {
  50: "#FFECF0",
  100: "#FFCFD6",
  200: "#FC9D9E",
  300: "#E97778",
  400: "#F45856",
  500: "#D93535",
  600: "#D42E2D",
  700: "#CF2625",
  800: "#C21E1E",
  900: "#A50003",
  A8: "rgba(194, 30, 30, 0.08)",
  A12: "rgba(194, 30, 30, 0.12)",
  A16: "rgba(194, 30, 30, 0.16)",
  A30: "rgba(194, 30, 30, 0.3)",
  A50: "rgba(194, 30, 30, 0.5)",
};
