import { useContext } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import { BlueprintsContext } from "../../context";
import MessageBubble from "components/ConversationThread/MessageBubble";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";
import { StepDelay } from "features/Sequences/screens/IndividualSequenceOverview/types";
import { DialogFooter } from "components/DialogFooter";

function BlueprintsTemplatesPreview() {
  const {
    areBlueprintsLoading,
    isSmallScreen,
    selectedCard,
    handleBlueprintsBack,
    handleChooseBlueprint,
  } = useContext(BlueprintsContext);

  const isRelativeSequence =
    selectedCard?.scheduleType === SCHEDULE_TYPES.relative;

  const formatDelay = (delay: StepDelay) => {
    const formatted = [];

    if (delay.days && delay.days !== 0) {
      formatted.push(`${delay.days} ${delay.days === 1 ? "day" : "days"}`);
    }

    if (delay.hours && delay.hours !== 0) {
      formatted.push(`${delay.hours} ${delay.hours === 1 ? "hour" : "hours"}`);
    }

    if (delay.minutes && delay.minutes !== 0) {
      formatted.push(
        `${delay.minutes} ${delay.minutes === 1 ? "minute" : "minutes"}`,
      );
    }

    return `Delay ${formatted.join(", ")}`;
  };

  const renderCards = () => {
    return selectedCard?.steps.map((step, index) => {
      return (
        <Box margin="1rem 0rem 1rem 0rem" key={step.id}>
          <Typography variant="overline">
            {isRelativeSequence
              ? `Day ${step.compoundedDay}`
              : `Step ${index + 1}`}
          </Typography>
          <MessageBubble
            message={{
              ...step,
              formattedBody: step.body,
              direction: "outbound",
            }}
            body={step.body}
          />
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            marginTop="0.5rem"
          >
            {isRelativeSequence ? (
              <Typography variant="caption">
                {formatDelay(step.relativeDelay)}
              </Typography>
            ) : (
              <Typography variant="caption">Delay not scheduled</Typography>
            )}
          </Box>
        </Box>
      );
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      borderLeft="solid 1px"
      borderColor="divider"
      sx={(theme) => {
        return {
          [theme.breakpoints.up("md")]: {
            width: "23.25rem",
          },
        };
      }}
    >
      <Box
        width="100%"
        padding="1rem"
        height="33rem"
        sx={(theme) => {
          return {
            overflowY: "scroll",
            backgroundColor: theme.palette.action.hover,
            [theme.breakpoints.down("md")]: {
              height: "29rem",
            },
          };
        }}
      >
        <Typography variant="h6" color="primary">
          {areBlueprintsLoading ? (
            <Skeleton />
          ) : (
            `${selectedCard?.name} details`
          )}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {areBlueprintsLoading ? (
            <Skeleton />
          ) : (
            `${selectedCard?.stepCount} steps ${
              isRelativeSequence ? `| ${selectedCard.daySpan} days` : ""
            }`
          )}
        </Typography>
        {areBlueprintsLoading ? (
          <Box>
            <Skeleton width="100%" height="12rem" />
            <Skeleton width="100%" height="12rem" />
          </Box>
        ) : (
          renderCards()
        )}
      </Box>
      {!isSmallScreen ? (
        <DialogFooter
          confirmText="Choose"
          cancelText="Back"
          onCancel={handleBlueprintsBack}
          onConfirm={() => {
            if (selectedCard) {
              handleChooseBlueprint(selectedCard);
            }
          }}
        />
      ) : null}
    </Box>
  );
}

export { BlueprintsTemplatesPreview };
