import { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { SizeMe } from "react-sizeme";
import get from "lodash/get";

import withSizes from "react-sizes";

import Root from "./components/Root";
import { actionGenerators as modalActionGenerators } from "features/ContactModal/state";

import {
  selectActiveConversationFilter,
  selectAppSettings,
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import { selectors as typingIndicatorsSelectors } from "features/EntryPoint/containers/TypingIndicators/state";
import breakpoints from "utils/styles/breakpoints";
import withRecord from "higherOrderComponents/withRecord";
import createOptimisticConversationUpdateFunc from "utils/createOptimisticConversationUpdateFunc";

function Conversation(props) {
  const { children, ...rootProps } = props;

  return typeof children === "function" ? (
    children(rootProps)
  ) : (
    <SizeMe monitorHeight>
      {({ size: { height, width } }) => {
        return (
          <Root
            {...rootProps}
            containerQueryParams={{
              short: height < 700,
              superSmall: width < 320,
              small: width < 420,
              large: width > 420,
            }}
          />
        );
      }}
    </SizeMe>
  );
}

Conversation.propTypes = {
  setContactModal: PropTypes.func.isRequired,
  children: PropTypes.func,
};

const mapStateToProps = (state, props) => {
  return {
    activeConversationFilter: selectActiveConversationFilter({
      conversations: { members: [] },
    })(state, props),
    appSettings: selectAppSettings(state, props),
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
    typingIndicatorsContainer: typingIndicatorsSelectors.selectTypingIndicators(
      state,
      props,
    ),
  };
};

const mapDispatchToProps = {
  ...modalActionGenerators,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const actions = [
  "fetch",
  "close",
  "reopen",
  "star",
  "unstar",
  "assign",
  "unassign",
  "read",
  "unread",
  "export",
].map((action) => {
  return {
    type: action,
    optimisticUpdateFunc: ["fetch", "export"].includes(action)
      ? undefined
      : createOptimisticConversationUpdateFunc(action),
  };
});

export default compose(
  withConnect,
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
    };
  }),
  withRecord({
    actions,
    container: "ActiveConversation/containers/Conversation",
    shape: {
      account: {},
      assignedContact: { notes: {}, phones: { members: [] } },
      associatedContacts: { members: [{ notes: {}, phones: { members: [] } }] },
      conversationAssignmentsCollection: { members: [] },
      participants: { members: [] },
      requestingUserInfo: {},
      timeline: {
        members: [
          { schema: "ConversationEvent", user: {}, assignee: {} },
          { schema: "Message", attachments: { members: [] }, sender: {} },
        ],
      },
    },
    showLoader: (props) => {
      return (
        !get(props, ["conversation", "requestingUserInfo"]) ||
        (!props.contactModal &&
          !get(props, ["activeConversationFilter", "conversations"]))
      );
    },
    type: "conversation",
  }),
)(Conversation);
