import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import Chip from "@mui/material/Chip";

export default class VariableSpan extends Component {
  static propTypes = {
    children: PropTypes.any,
    contentState: PropTypes.object.isRequired,
    entityKey: PropTypes.string.isRequired,
    offsetKey: PropTypes.string,
  };

  getEntityData = () => {
    const { contentState, entityKey } = this.props;
    return contentState.getEntity(entityKey).getData();
  };

  getEntitySelection = () => {
    const {
      selection,
      start = 0,
      text = "",
    } = get(this.props, ["children", 0, "props"], {});
    if (!selection) {
      return this.getEntityData().initialSelection;
    }
    return selection.merge({
      anchorOffset: start,
      focusOffset: start + text.length,
    });
  };

  getHandleEntityUpdate = () => {
    const entitySelection = this.getEntitySelection();
    const { entityKey } = this.props;
    const { handleEntityUpdate } = this.getEntityData();
    return handleEntityUpdate(entitySelection, entityKey);
  };

  handleClick = (event) => {
    const {
      handleCloseVariablePopover,
      handleOpenVariablePopover,
      toggleShortLinkModal,
      isShortLink,
    } = this.getEntityData();

    if (isShortLink) {
      toggleShortLinkModal();
    } else {
      handleOpenVariablePopover(event, {
        ...this.props,
        handleCloseVariablePopover,
        handleEntityUpdate: this.getHandleEntityUpdate(),
      });
    }
  };

  handleDelete = () => {
    const entitySelection = this.getEntitySelection();
    const { handleDelete, isShortLink, shortenedLink, setShortenedLink } =
      this.getEntityData();
    if (isShortLink) setShortenedLink({ ...shortenedLink, fullLink: "" });
    handleDelete(entitySelection);
  };

  render() {
    const { text } = this.props.children[0].props;
    const { disabled, shortenedLink } = this.getEntityData() || "";
    const chipText = text.includes("shortlink")
      ? shortenedLink.shortLink
      : this.props.children;

    return (
      <Chip
        data-offset-key={this.props.offsetKey}
        label={chipText}
        onClick={this.handleClick}
        onDelete={this.handleDelete}
        disabled={disabled}
      />
    );
  }
}
