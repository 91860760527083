/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-floating-promises */
import {
  Autocomplete,
  Box,
  Checkbox,
  Chip,
  DialogContent,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useSnackbar } from "notistack";
import { postJobImport } from "../../api/postJobImport";
import { GreenhouseJobDialogProps, Job } from "./types";
import { DialogHeader } from "components/DialogHeader";
import { DialogFooter } from "components/DialogFooter";
import {
  getGreenhouseJobs,
  getGreenhouseJobStages,
} from "features/FindNumbers/api";

export function GreenhouseJobDialog({
  close,
  currentAccountSlug,
  findNumbersResponse,
  contactImport,
}: GreenhouseJobDialogProps) {
  const [allJobs, setAllJobs] = useState<Job[]>([]);
  const [allJobStages, setAllJobStages] = useState<any[]>([]);
  const [selectedJob, setSelectedJob] = useState<Job | null>(null);
  const [selectedJobStages, setSelectedJobStages] = useState<Job[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleFetchGreenhouseJobs = async () => {
    try {
      const response = await getGreenhouseJobs(currentAccountSlug);
      if (!response.ok) {
        throw new Error("Failed to fetch Greenhouse jobs");
      }
      const data = await response.json();
      setAllJobs(data);
    } catch {
      enqueueSnackbar(
        "Unable to connect to Greenhouse. Please reach out to your administrator to reconnect your integration.",
        {
          variant: "error",
        },
      );
      close(false);
    }
  };

  const handleFetchGreenhouseJobStages = async () => {
    try {
      const response = await getGreenhouseJobStages(
        currentAccountSlug,
        selectedJob?.id ?? "",
      );
      if (!response.ok) {
        throw new Error("Failed to fetch Greenhouse job stages");
      }
      const data = await response.json();
      setAllJobStages(data.stages);
    } catch {
      enqueueSnackbar(
        "Unable to connect to Greenhouse. Please try again later.",
        {
          variant: "error",
        },
      );
    }
  };

  const handleContactImport = async () => {
    try {
      const jobStageNames = selectedJobStages.map((jobStage) => {
        return jobStage.name;
      });
      const response = await postJobImport(
        contactImport,
        "job_submission",
        selectedJob?.id ?? "",
        "greenhouse",
        jobStageNames,
      );
      if (!response.ok) {
        throw new Error("Failed to import contacts");
      }
    } catch {
      enqueueSnackbar("Import failed.", {
        variant: "error",
      });
    }
  };

  /*
    Fetch all jobs associated with current account
  */
  useEffect(() => {
    if (currentAccountSlug) {
      handleFetchGreenhouseJobs();
    }
  }, [currentAccountSlug]);

  /*
    Fetch all job stages associated with selected job
  */
  useEffect(() => {
    if (selectedJob) {
      handleFetchGreenhouseJobStages();
    }
  }, [selectedJob]);

  /*
    Sets selected job to job that Find Numbers was ran on
  */
  useEffect(() => {
    if (allJobs.length > 0) {
      const initialSelectedJob = allJobs.find((job: Job) => {
        return job.name === findNumbersResponse.id;
      });
      setSelectedJob(initialSelectedJob ?? null);
    }
  }, [allJobs]);

  return (
    <>
      <DialogHeader
        title="Find numbers"
        onClose={() => {
          return close(false);
        }}
      />
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography textAlign="center" sx={{ marginBottom: "24px" }}>
            {" "}
            Select the Job and Job stage you want to pull contacts from.
          </Typography>
          <Autocomplete
            options={allJobs}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField {...params} label="Job name" />;
            }}
            onChange={(event, newValue: Job | null) => {
              return setSelectedJob(newValue ?? null);
            }}
            value={selectedJob}
            sx={{ width: "100%", marginBottom: "16px" }}
          />
          <Autocomplete
            multiple
            disabled={!selectedJob}
            disableCloseOnSelect
            options={allJobStages}
            getOptionLabel={(option) => {
              return option.name;
            }}
            renderInput={(params) => {
              return <TextField {...params} label="Job stage" />;
            }}
            renderOption={(props, option, { selected }) => {
              const { ...optionProps } = props;
              return (
                <li {...optionProps} style={{ paddingLeft: "8px" }}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlankIcon fontSize="medium" />}
                    checkedIcon={
                      <CheckBoxIcon fontSize="medium" color="secondary" />
                    }
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.name}
                </li>
              );
            }}
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={key}
                    label={option.name}
                    size="small"
                    {...tagProps}
                  />
                );
              });
            }}
            onChange={(event, newValue) => {
              return setSelectedJobStages(newValue);
            }}
            sx={{ width: "100%" }}
          />
        </Box>
      </DialogContent>
      <DialogFooter
        onCancel={() => {
          return close(false);
        }}
        onConfirm={() => {
          handleContactImport();
          close("greenhouseJob");
        }}
        confirmText="Continue"
        isConfirmDisabled={!selectedJob || selectedJobStages.length === 0}
      />
    </>
  );
}
