import { useState, useEffect } from "react";
import { fetchUserData } from "features/SavedReplies/api";

export const useUserData = (user: string) => {
  const [savedReplyOwnerName, setSavedReplyOwnerName] = useState("");

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetchUserData(user);
        if (!response.ok) {
          throw new Error("Error");
        }
        const userData = await response.json();
        setSavedReplyOwnerName(userData);
      } catch (error) {
        throw new Error(`Error fetching user data: ${error as string}`);
      }
    };

    fetchUser().catch((error) => {
      throw new Error(`Error fetching user data: ${error as string}`);
    });
  }, [user]);

  return savedReplyOwnerName;
};
