/*
 * Designed to work with the components/SearchBox.tsx component.
 */

import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { debounce } from "lodash";

export const useDebouncedSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");

  const debounceSearch = useMemo(() => {
    return debounce((search: string) => {
      setDebouncedSearchTerm(search);
    }, 300);
  }, []);

  const handleSearch = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchTerm(value);
      debounceSearch(value);
    },
    [debounceSearch, setSearchTerm],
  );

  const handleClear = () => {
    setSearchTerm("");
    setDebouncedSearchTerm("");
  };

  return { searchTerm, debouncedSearchTerm, handleSearch, handleClear };
};
