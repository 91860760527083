import styled from "styled-components";
import H3 from "components/H3";

export default styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  ${(props) => {
    return props.isActive
      ? props.theme.mixins.slideIn()
      : props.theme.mixins.slideUpOut();
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  flex-flow: column nowrap;
  padding: 40px;
  text-align: center;
  transition: all ease 0.4s;
  z-index: 20;

  ${H3} {
    margin: 10px 0;
  }

  form {
    align-items: center;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
    justify-content: center;
    padding: 50px 0;

    > * {
      flex: 0 0 auto;
    }
  }

  span[role="img"] {
    font-size: 45px;
  }
`;
