import { all, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import * as schema from "schema";
import generateContainerState from "utils/generateContainerState";

const { actionTypes, actionGenerators, sagas, reducers, selectors } =
  generateContainerState([
    {
      container: "CreateContact",
      crudActions: ["create"],
      recordType: "contact",
      schema: schema.contact,
    },
  ]);

function* saga() {
  yield all([
    takeLatest(actionTypes.CREATE_CONTACT_REQUEST, sagas.createContactRequest),
  ]);
}

// REDUCERS
const reducer = combineReducers(reducers);

export { actionTypes, actionGenerators, saga, reducer, selectors };
