import { Button, Typography, Box, Divider } from "@mui/material";
import { Link } from "react-router-dom";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { styled } from "@mui/material/styles";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

type OverageDialogProps = {
  dailyCampaignRecipients: number;
  recipientCount: number;
  accountSlug: string;
  campaignType: string;
  close: (data: boolean) => () => void;
  remainingRecipients: number;
};

function OverageDialog({
  dailyCampaignRecipients,
  recipientCount,
  accountSlug,
  campaignType,
  close,
  remainingRecipients,
}: OverageDialogProps) {
  const StyledLink = styled(Link)(({ theme }) => {
    return {
      color: theme.palette.secondary.main,
    };
  });

  const DialogWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "space-between",
  }) as typeof Box;

  const TitleTypography = styled(Typography)(({ theme }) => {
    return {
      fontFamily: "Helvetica Neue",
      fontSize: "1.25rem",
      fontStyle: "normal",
      lineHeight: "138%",
      letterSpacing: "0.2px",
      color: theme.palette.text.primary,
      fontWeight: 500,
    };
  }) as typeof Typography;

  const ContentTypography = styled(Typography)({
    fontSize: "16px",
    paddingLeft: "20px",
    paddingRight: "10px",
    fontFamily: "Helvetica Neue",
    fontWeight: "400px",
    lineHeight: "150%",
  }) as typeof Typography;

  const copy = {
    account: " account's",
    campaign: " campaign's",
  };

  const isRemainingSingular = remainingRecipients === 1;

  const isRemainingZero = remainingRecipients === 0;

  return (
    <DialogWrapper width={{ xs: "auto", sm: "500px" }}>
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{
          pl: "20px",
          pr: "0px",
          py: "10px",
        }}
      >
        <TitleTypography> Unable to send </TitleTypography>
        <IconButton sx={{ marginRight: "8px" }} onClick={close(false)}>
          <CloseIcon
            style={{ fontSize: 28 }}
            sx={(theme) => {
              return {
                color: theme.palette.text.secondary,
              };
            }}
          />
        </IconButton>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent sx={{ p: "0px" }}>
        <Box
          sx={{ pb: "20px", pr: "15px" }}
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
        >
          <ul>
            <li>
              {" "}
              This campaign has <b> {`${recipientCount}`} </b> planned
              recipients{" "}
            </li>
            {campaignType === "single" ? (
              <li>
                There {isRemainingSingular ? "is " : "are "}{" "}
                {isRemainingZero ? "" : "only"}
                <b> {remainingRecipients} </b>
                {isRemainingSingular ? "recipient " : "recipients "}
                left in this
                {copy.account} daily campaign message limit of{" "}
                <b> {`${dailyCampaignRecipients}`} </b> for this date.
              </li>
            ) : (
              <li>
                Due to other scheduled campaigns, this exceeds the
                {copy.account} daily campaign message limit of
                <b> {`${dailyCampaignRecipients}`} </b> for one or more days.{" "}
              </li>
            )}
          </ul>
          <ContentTypography>
            Please review any{" "}
            <StyledLink
              to={`/${accountSlug}/campaigns/mine`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              currently scheduled campaigns{" "}
              <OpenInNewIcon
                sx={(theme) => {
                  return {
                    fontSize: "23px",
                    color: theme.palette.secondary.main,
                  };
                }}
              ></OpenInNewIcon>
            </StyledLink>{" "}
            or reschedule this {copy.campaign} send time to another day.
          </ContentTypography>
        </Box>
      </DialogContent>
      <Divider></Divider>
      <DialogActions sx={{ height: "70px", pr: "17px" }}>
        <Button variant="contained" autoFocus onClick={close(false)}>
          Ok
        </Button>
      </DialogActions>
    </DialogWrapper>
  );
}

export default OverageDialog;
