import camelCase from "lodash/camelCase";

import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tooltip from "@mui/material/Tooltip";
import { visuallyHidden } from "@mui/utils";

import { AnalyticsTableHeaderProps } from "../models/AnalyticsModels";

function AnalyticsTableHeader({
  columnHeads,
  order,
  orderBy,
  onRequestSort,
}: AnalyticsTableHeaderProps) {
  const createSortHandler = (property: string) => {
    return (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };
  };

  return (
    <TableHead>
      <TableRow sx={{ height: 28 }}>
        {columnHeads.map(({ description, title, slug }) => {
          const camelizedSlug = camelCase(slug);
          return (
            <TableCell
              key={camelizedSlug}
              align="center"
              sortDirection={orderBy === camelizedSlug ? order : false}
              sx={{
                fontSize: "12px",
                fontWeight: "bold",
                border: "none",
                paddingLeft: "8px",
                textAlign: "left",
              }}
            >
              <Tooltip title={description}>
                <TableSortLabel
                  active={orderBy === camelizedSlug}
                  direction={orderBy === camelizedSlug ? order : "asc"}
                  onClick={createSortHandler(camelizedSlug)}
                >
                  {title}
                  {orderBy === camelizedSlug ? (
                    <Box component="span" sx={visuallyHidden as SxProps<Theme>}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

export default AnalyticsTableHeader;
