import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AccountSetting from "./AccountSetting";
import { LinkStrippingValidationSchema } from "formHelpers/validationSchemas";

function LinkStripping(props) {
  const getDescription = () => {
    return (
      <p>
        {`TextUs can remove links from SMS messages to help prevent
      inbound phishing attacks or limit your users' ability to send
      links to contacts. When configured, detected links will be
      replaced with [LINK REDACTED] before being sent or received.`}
      </p>
    );
  };

  const getRender = (label) => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, checked);
                }}
                color="secondary"
              />
            }
            label={label}
          />
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      settings={[
        {
          default: false,
          name: "stripInboundLinks",
          render: getRender("Remove Inbound Links"),
        },
        {
          default: false,
          name: "stripOutboundLinks",
          render: getRender("Remove Outbound Links"),
        },
      ]}
      title="Link Removal"
      validationSchema={LinkStrippingValidationSchema}
    />
  );
}

LinkStripping.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default LinkStripping;
