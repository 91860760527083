import PropTypes from "prop-types";
import {
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import MessageQuality from "./MessageQuality";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import MessageInputFormRow from "components/MessageInputFormRow";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { campaignVariantMap } from "features/Campaigns/constants";

function MessageBodyVariants({
  additionalRecipientsRequired,
  currentAccount,
  currentUser,
  editCampaign,
  errors,
  handleAddVariant,
  handleDeleteVariant,
  SavedRepliesOption,
  setFieldValue,
  touched,
  values,
}) {
  const mobileView = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const { campaignMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    campaignMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const { messageTemplates } = values ?? [];

  const isAddMessageDisabled =
    messageTemplates.length > 2 || additionalRecipientsRequired;

  const renderMessageTemplates = messageTemplates.map(
    ({ body, signatureActive, id }, index) => {
      const currentTemplate = values.messageTemplates[index];
      return (
        <Box key={id}>
          {index > 0 && (
            <Box mb={4}>
              <Divider />
            </Box>
          )}
          <Typography variant="h5" mb="1rem">
            Message {campaignVariantMap[index + 1]}
          </Typography>
          <MessageInputFormRow
            attachmentField={currentTemplate.attachments}
            attachmentFieldName={`messageTemplates[${index}].attachments`}
            currentAccount={currentAccount}
            currentUser={currentUser}
            editCampaign={editCampaign}
            errors={errors}
            fieldName={`messageTemplates[${index}].body`}
            isCampaignProMember
            isDisabled={false}
            isSignatureActive={signatureActive}
            label="Message"
            linksFieldName={`messageTemplates[${index}].links`}
            maxChars={maxCharacterLength}
            SavedRepliesOption={SavedRepliesOption}
            setFieldValue={setFieldValue}
            setShortenedLink={(value) => {
              return setFieldValue(`messageTemplates[${index}].links`, value);
            }}
            shortenedLink={currentTemplate.links}
            signature={currentUser.signature}
            toggleSignature={() => {
              return setFieldValue(
                `messageTemplates[${index}].signatureActive`,
                !signatureActive,
              );
            }}
            touched={touched}
            values={values}
            withSignature
          />
          <Box>
            <AutomaticMessageIndicator />
            <MessageQuality message={body} />
            {messageTemplates.length > 1 && (
              <Box
                display="flex"
                justifyContent="space-around"
                mb={2}
                pt="16px"
              >
                <Button
                  aria-label="Delete Message"
                  sx={(theme) => {
                    return { color: theme.palette.error.main };
                  }}
                  onClick={() => {
                    return handleDeleteVariant({ id });
                  }}
                >
                  Delete Message
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      );
    },
  );
  return (
    <Box>
      {renderMessageTemplates}
      {mobileView ? (
        <Tooltip
          title={
            isAddMessageDisabled
              ? "Additional recipients are needed to include another message variant"
              : ""
          }
        >
          <Box
            display="flex"
            flexDirection="column"
            paddingTop={messageTemplates.length > 1 ? "0" : "16px"}
          >
            <Button
              sx={{
                alignSelf: "center",
              }}
              onClick={() => {
                return handleAddVariant({
                  messageTemplates,
                  campaignTitle: values?.title,
                });
              }}
              aria-label="Add Message"
              disabled={isAddMessageDisabled}
            >
              Add Message
            </Button>
          </Box>
        </Tooltip>
      ) : null}
    </Box>
  );
}

MessageBodyVariants.propTypes = {
  additionalRecipientsRequired: PropTypes.bool,
  currentAccount: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  editCampaign: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  handleAddVariant: PropTypes.func.isRequired,
  handleDeleteVariant: PropTypes.func.isRequired,
  SavedRepliesOption: PropTypes.any,
  setFieldValue: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default MessageBodyVariants;
