import { Box, Card, Typography, styled } from "@mui/material";

export const StyledHeader = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.primary.main,
  };
});

export const StyledSubhead = styled(Typography)(({ theme }) => {
  return {
    color: theme.palette.text.secondary,
  };
});

export const StyledCard = styled(Card)(({ theme }) => {
  return {
    width: "12.5rem",
    gap: "1rem",
    ":hover": {
      backgroundColor: theme.palette.action.hover,
    },
  };
});

export const StyledTextContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: "0.75rem",
  height: "100%",
});
