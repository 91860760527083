import { ListItem, ListItemText } from "@mui/material";
import { StyledBadge } from "../../../components";
import { Sequence } from "models/Sequence";

function SequencePreview({ sequence }: { sequence: Sequence }) {
  const secondaryText = (seq: Sequence): string => {
    const scheduleType =
      seq.scheduleType === "relative" ? "Ongoing" : "Scheduled";
    const stepCount =
      seq.stepCount === 1 ? `${seq.stepCount} step` : `${seq.stepCount} steps`;

    return `${scheduleType} | ${seq.engagementRate}% engagement | ${seq.pendingCount} pending | ${stepCount}`;
  };

  return (
    <ListItem
      divider
      sx={{
        alignItems: "flex-start",
        flexFlow: "column",
        padding: "0.75rem 1rem",
      }}
    >
      <StyledBadge
        color={sequence.state === "active" ? "secondary" : "default"}
      >
        <ListItemText
          primary={sequence.name}
          primaryTypographyProps={{
            fontSize: "16px",
          }}
          sx={{
            margin: "0 10px 0 0",
            padding: "0",
          }}
        />
      </StyledBadge>

      <ListItemText
        secondary={secondaryText(sequence)}
        sx={{
          margin: "0",
          padding: "0",
        }}
      />
    </ListItem>
  );
}

export { SequencePreview };
