import { memo } from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const ViewMoreRow = memo(({ data, style }) => {
  const { id, nestingLevel, nextPage, fetchAccountCollectionRequest } = data;

  const getNextPage = () => {
    if (nextPage) {
      fetchAccountCollectionRequest(nextPage);
    }
  };

  return (
    <ListItem
      key={id}
      style={{ ...style, paddingLeft: `${(nestingLevel + 1) * 26}px` }}
      label="View More"
      button
      onClick={getNextPage}
    >
      <ListItemText style={{ paddingRight: 0, paddingLeft: "26px" }}>
        View More
      </ListItemText>
    </ListItem>
  );
});

ViewMoreRow.propTypes = {
  data: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
};

export default ViewMoreRow;
