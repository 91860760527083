import PersonalizationCalendarDayField from "../components/PersonalizationCalendarDayField";
import PersonalizationDatePicker from "../components/PersonalizationDatePicker";
import PersonalizationTextField from "../components/PersonalizationTextField";
import PersonalizationTimePicker from "../components/PersonalizationTimePicker";

export default function (fieldProps) {
  const { personalization } = fieldProps;
  const { title, type } = personalization;
  if (type === "calendar_day") {
    return <PersonalizationCalendarDayField key={title} {...fieldProps} />;
  }
  if (type === "date") {
    return <PersonalizationDatePicker key={title} {...fieldProps} />;
  }
  if (type === "time") {
    return <PersonalizationTimePicker key={title} {...fieldProps} />;
  }
  return <PersonalizationTextField key={title} {...fieldProps} />;
}
