import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import withSizes from "react-sizes";

import Root from "./components/Root";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import {
  selectAppSettings,
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import breakpoints from "utils/styles/breakpoints";
import withRecord from "higherOrderComponents/withRecord";
import createOptimisticConversationUpdateFunc from "utils/createOptimisticConversationUpdateFunc";

class ActiveConversationFilter extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    appSettings: selectAppSettings(state, props),
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  setCompose: composeActionGenerators.setCompose,
});

const actions = [
  "fetch",
  "close",
  "reopen",
  "star",
  "unstar",
  "assign",
  "unassign",
  "read",
  "unread",
].map((action) => {
  return {
    type: action,
    optimisticUpdateFunc:
      action === "fetch"
        ? undefined
        : createOptimisticConversationUpdateFunc(action),
  };
});

export default compose(
  withRecord({
    actions,
    container: "Inbox/containers/ActiveConversationFilter",
    shape: {
      conversations: {
        members: [
          {
            assignedContact: { phones: { members: [] } },
          },
        ],
      },
    },
    showLoader: (props) => {
      return !get(props, ["conversationFilter", "conversations"]);
    },
    type: "conversationFilter",
  }),
  withSizes(({ width }) => {
    return {
      mediumScreen: width < breakpoints.md,
    };
  }),
  withConnect,
)(ActiveConversationFilter);
