/**
 *
 * ConversationSidebar
 *
 */

import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { reducer, selectors, actionGenerators, saga } from "./state";
import Root from "./components/Root";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";

import PageLoader from "components/PageLoader";
import Portal from "components/Portal";
import { actionGenerators as contactModalActionGenerators } from "features/ContactModal/state";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

class ConversationSidebar extends Component {
  state = {
    showLoader: true,
  };

  componentDidMount() {
    this.loaderTimeout = setTimeout(() => {
      return this.setState({ showLoader: false });
    }, 300);
  }

  componentWillUnmount() {
    clearTimeout(this.loaderTimeout);
  }

  render() {
    const conversationSidebarNode = document.getElementById(
      "textus-ConversationSidebar",
    );
    if (!conversationSidebarNode) return null;
    return (
      <Portal root={conversationSidebarNode}>
        {this.state.showLoader ? <PageLoader /> : <Root {...this.props} />}
      </Portal>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    conversationSidebarContainer: selectors.selectConversationSidebar(
      state,
      props,
    ),
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withReducer = injectReducer({
  key: "conversationSidebarContainer",
  reducer,
});
const withSaga = injectSaga({ key: "conversationSidebarContainer", saga });

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  setContactModal: contactModalActionGenerators.setContactModal,
});

export default compose(withReducer, withSaga, withConnect)(ConversationSidebar);
