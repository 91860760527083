import { List, Divider, IconButton, Tooltip } from "@mui/material";
import CollapsibleNavListItem from "./CollapsibleNavListItem";
import { ContactsSideNavProps, NavListItemProps } from "./types";
import ContactImportsListItem from "./ContactImportsListItem";
import NavListItemButton from "./NavListItemButton";
import PreviousImportsSection from "./PreviousImportsSection";
import { DeleteIcon } from "icons";

export default function ContactsSideNav({
  currentAccount,
  previousImports = [],
  savedFilters,
  handleDelete,
}: ContactsSideNavProps) {
  // ==== NAV DATA ==== //
  const accountNavListItems: NavListItemProps[] = [
    {
      to: "blocked",
      primary: "Blocked contacts",
    },
    {
      to: "opted-out",
      primary: "Opted out contacts",
    },
    {
      to: "never_messaged",
      primary: "Never messaged",
    },
    {
      to: "unmessageable",
      primary: "Invalid recipients",
    },
  ];

  const { restoreContacts } = currentAccount.featureFlags;
  if (restoreContacts) {
    accountNavListItems.push({
      to: "recently-deleted",
      primary: "Recently removed",
    });
  }

  // ==== RENDER ==== //
  return (
    <List>
      {/* All contacts */}
      <NavListItemButton primary="All contacts" to="all" />

      {/* On this account */}
      <CollapsibleNavListItem primary="On this account" to="account">
        {accountNavListItems.map((item) => {
          return (
            <NavListItemButton key={item.primary as string} {...item} nested />
          );
        })}
      </CollapsibleNavListItem>

      {/* Saved searches */}
      {savedFilters.length > 0 && (
        <CollapsibleNavListItem isOpen={false} primary="Saved searches">
          {savedFilters.map((filter: any) => {
            return (
              <NavListItemButton
                key={filter.primary}
                primary={filter.title}
                to={decodeURIComponent(filter.slug)}
                nested
                showSecondaryOnActive
                showSecondaryOnHover
                secondaryAction={
                  <Tooltip title="Delete Saved Search">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={(event) => {
                        handleDelete(filter)(event);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                }
              />
            );
          })}
        </CollapsibleNavListItem>
      )}

      <Divider />

      {/* Contact imports */}
      {currentAccount.contactsEnabled && (
        <ContactImportsListItem currentAccount={currentAccount} />
      )}

      {/* Previous imports */}
      <PreviousImportsSection
        isOpen={false}
        imports={previousImports}
        currentAccount={currentAccount}
      />
    </List>
  );
}
