import { ReactNode } from "react";
import { Box } from "@mui/material";

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  sx?: Record<string, any>;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, sx = {}, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tab-${index}`}
      aria-labelledby={`tab-${index}`}
      sx={sx}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

export { TabPanel };
