/* eslint-disable class-methods-use-this */
import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { CSSTransition } from "react-transition-group";
import BoltOutlinedIcon from "@mui/icons-material/BoltOutlined";
import { Avatar as MuiAvatar } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {
  addMinutes,
  format,
  isSameDay,
  isThisWeek,
  isThisYear,
  isToday,
  isTomorrow,
  isWithinInterval,
  isYesterday,
} from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import MessageWrapper from "./MessageWrapper";
import MessageEditWrapper from "./MessageEditWrapper";
import { Root as MessageBubbleStyles } from "./MessageBubble";
import { Root as MessageAttachmentStyles } from "./MessageAttachment";
import Avatar from "components/Avatar";
import { getAccountTimeZone } from "utils/date";

const DAY_BREAK_HEIGHT = 44;
const MESSAGE_TRANSITION_DURATION = 600;

const DayBreak = styled.div`
  background: ${(props) => {
    return props.scheduled
      ? `
      linear-gradient(
      0deg,
      rgba(250, 250, 250, 0),
      ${props.theme.colors.background.default},
      rgba(250, 250, 250, 0)
    );
    `
      : `
    linear-gradient(
      0deg,
      rgba(255, 255, 255, 0),
      ${props.theme.colors.background.paper},
      rgba(255, 255, 255, 0)
    );
    `;
  }};
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  display: table;
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
  font-weight: 500;
  height: ${DAY_BREAK_HEIGHT}px;
  line-height: ${DAY_BREAK_HEIGHT}px;
  margin: 0px auto ${DAY_BREAK_HEIGHT}px auto;
  padding: 0px 15px;
  position: -webkit-sticky;
  position: sticky;
  text-align: center;
  top: 0px;
  z-index: ${(props) => {
    return props.zIndex + 2;
  }};
`;

const DayBreakLine = styled.div`
  background: ${(props) => {
    return props.scheduled
      ? props.theme.colors.background.default
      : props.theme.colors.background.paper;
  }};
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  min-height: ${DAY_BREAK_HEIGHT + 1}px;
  position: -webkit-sticky;
  position: sticky;
  top: -${DAY_BREAK_HEIGHT / 2}px;
  transform: translateY(${DAY_BREAK_HEIGHT / 2}px);
  width: 100%;
  z-index: ${(props) => {
    return props.zIndex + 1;
  }};

  &::before {
    background: ${(props) => {
      return props.scheduled
        ? `
    linear-gradient(
      0deg,
      ${props.theme.colors.background.default},
      rgba(250, 250, 250, 0)
    );
    `
        : `linear-gradient(
      0deg,
      ${props.theme.colors.background.paper},
      rgba(255, 255, 255, 0)
    );`;
    }};
    content: "";
    display: block;
    height: 30px;
    position: absolute;
    top: -30px;
    width: 100%;
  }
`;

const ConversationEvent = styled.div`
  align-items: center;
  color: ${(props) => {
    return props.theme.colors.text.disabled;
  }};
  display: flex;
  font-size: 12px;
  font-weight: 500;
  justify-content: center;
  margin: 20px 0;
  text-align: center;
`;

const AvatarWrapper = styled.div`
  min-width: 33px;
`;

export const MessageSection = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 0 0 auto;
  flex-flow: row nowrap;
  justify-content: ${(props) => {
    return props.direction === "in" ? "flex-start" : "flex-end";
  }};
  margin: ${(props) => {
    return props.isSmall ? "10px 15px" : "15px";
  }};
  margin-bottom: ${(props) => {
    return props.shouldCollapse && (props.isSmall ? "-5px" : "-10px");
  }};
  transition: ${(props) => {
    return props.theme.mixins.transition({ property: "padding" });
  }};

  &.message-enter {
    ${MessageBubbleStyles} {
      opacity: 0.01;
      transform: translateX(
          ${(props) => {
            return props.direction === "in" ? -50 : 50;
          }}px
        )
        scale(0);
    }
  }

  &.message-enter-active {
    ${MessageBubbleStyles} {
      opacity: 1;
      transition:
        opacity ease-in-out
          ${(props) => {
            return props.duration;
          }}ms,
        transform cubic-bezier(0.175, 0.885, 0.32, 1)
          ${(props) => {
            return props.duration;
          }}ms;
      transform: translateX(0px) scale(1);
      transform-origin: bottom right;
    }
  }

  ${MessageAttachmentStyles} {
    text-align: ${(props) => {
      return props.direction === "in" ? "left" : "right";
    }};
  }
`;

const ScheduledRootStyles = css`
  margin: 0;
`;

const Root = styled.div`
  flex: 1 0 auto;
  padding: 10px;
  position: relative;
  z-index: 1;
  ${(props) => {
    return (
      props.hasScheduledMessages && !props.scheduled && "margin-bottom: 20px;"
    );
  }};
  ${(props) => {
    return props.scheduled && ScheduledRootStyles;
  }};
`;

const HubspotAvatar = styled(MuiAvatar)`
  background-color: #b39ddb;
  width: 33px;
  height: 33px;
`;

export function MessageSource({ message, shouldCollapse }) {
  return (
    <AvatarWrapper>
      {!shouldCollapse && message.source !== "hubspot" && (
        <Avatar subject={message.sender} tooltip={message.sender.name} />
      )}
      {!shouldCollapse && message.source === "hubspot" && (
        <Tooltip title="HubSpot">
          <HubspotAvatar>
            <BoltOutlinedIcon />
          </HubspotAvatar>
        </Tooltip>
      )}
    </AvatarWrapper>
  );
}

MessageSource.propTypes = {
  message: PropTypes.object.isRequired,
  shouldCollapse: PropTypes.bool.isRequired,
};

export default class TimelineMembers extends Component {
  static propTypes = {
    appSettings: PropTypes.object.isRequired,
    containerQueryParams: PropTypes.object,
    currentAccount: PropTypes.object,
    currentUser: PropTypes.object,
    retryMessageRequest: PropTypes.func,
    scheduled: PropTypes.bool,
    timeline: PropTypes.object.isRequired,
    updateMessageRequest: PropTypes.func,
    deleteMessageRequest: PropTypes.func,
    showModal: PropTypes.func,
    renderMessage: PropTypes.func,
  };

  getTimelineMembers = (timeline) => {
    const { scheduled } = this.props;
    if (!scheduled)
      return timeline.members.filter((member) => {
        return (
          member["@type"] !== "Message" || member.deliveryState !== "scheduled"
        );
      });
    return timeline.members.filter((member) => {
      return (
        member["@type"] === "Message" && member.deliveryState === "scheduled"
      );
    });
  };

  formatDayBreak = (date) => {
    if (isToday(date)) {
      return "Today";
    }
    if (isTomorrow(date)) {
      return "Tomorrow";
    }
    if (isYesterday(date)) {
      return "Yesterday";
    }
    if (isThisWeek(date)) {
      return format(date, "eeee, MMMM do");
    }
    if (isThisYear(date)) {
      return format(date, "eeee, MMMM do");
    }
    return format(date, "MMMM d, yyyy");
  };

  shouldCollapse({ timelineMember, nextTimelineMember }) {
    return (
      nextTimelineMember &&
      timelineMember["@type"] === "Message" &&
      nextTimelineMember["@type"] === "Message" &&
      (timelineMember.sender === nextTimelineMember.sender ||
        (timelineMember.sender &&
          nextTimelineMember.sender &&
          timelineMember.sender.id === nextTimelineMember.sender.id)) &&
      timelineMember.deliveryState === nextTimelineMember.deliveryState &&
      isWithinInterval(nextTimelineMember.timelinePosition, {
        start: new Date(timelineMember.timelinePosition),
        end: addMinutes(new Date(timelineMember.timelinePosition), 10),
      })
    );
  }

  renderTimeline() {
    const { timeline } = this.props;
    const timelineMembers = this.getTimelineMembers(timeline).reverse();
    return timelineMembers.reduce((accumulator, timelineMember, index) => {
      const nextTimelineMember = timelineMembers[index + 1];
      const shouldCollapse = this.shouldCollapse({
        timelineMember,
        nextTimelineMember,
      });
      const prevTimelineMember = index === 0 ? {} : timelineMembers[index - 1];
      const showDayBreak = !isSameDay(
        new Date(prevTimelineMember.timelinePosition),
        new Date(timelineMember.timelinePosition),
      );
      const isFirstTimelineMember = !timeline.view.next && index === 0;
      if (showDayBreak) {
        return [
          ...accumulator,

          this.renderDayBreakLine(
            new Date(timelineMember.timelinePosition),
            index,
          ),
          this.renderDayBreak(new Date(timelineMember.timelinePosition), index),
          this.renderTimelineMember(timelineMember, {
            shouldCollapse,
            isFirstTimelineMember,
            isLastTimelineMember: !nextTimelineMember,
          }),
        ];
      }
      return [
        ...accumulator,
        this.renderTimelineMember(timelineMember, {
          shouldCollapse,
          isFirstTimelineMember,
          isLastTimelineMember: !nextTimelineMember,
        }),
      ];
    }, []);
  }

  renderDayBreakLine(date, index) {
    const { scheduled } = this.props;
    return (
      <CSSTransition
        key={`DayBreakLine--${format(date, "MM_dd_yyyy")}`}
        classNames="dayBreakLine"
        enter={false}
        exit={false}
        timeout={0}
      >
        <DayBreakLine scheduled={scheduled} zIndex={index} />
      </CSSTransition>
    );
  }

  renderDayBreak(date, index) {
    const { scheduled } = this.props;

    return (
      <CSSTransition
        key={`DayBreak--${format(date, "MM_dd_yyyy")}`}
        classNames="dayBreak"
        enter={false}
        exit={false}
        timeout={0}
      >
        <DayBreak scheduled={scheduled} zIndex={index}>
          {this.formatDayBreak(date)}
        </DayBreak>
      </CSSTransition>
    );
  }

  renderTimelineMember(
    timelineMember,
    { shouldCollapse, isFirstTimelineMember, isLastTimelineMember },
  ) {
    if (timelineMember["@type"] === "Message") {
      return this.renderMessage(timelineMember, {
        shouldCollapse,
        isLastMessage: isLastTimelineMember,
      });
    }
    return this.renderConversationEvent(timelineMember, {
      isFirstTimelineMember,
    });
  }

  renderConversationEvent(event, { isFirstTimelineMember }) {
    if (isFirstTimelineMember) return null;
    const action = ((type) => {
      switch (type) {
        case "open":
          return "opened";
        case "close":
          return "closed";
        case "block":
          return "blocked";
        case "unblock":
          return "unblocked";
        case "subscribe":
          return "subscribed to";
        case "unsubscribe":
          return "unsubscribed from";
        case "assign":
          return "assigned";
        case "unassign":
          return "unassigned";
        default:
          return type;
      }
    })(event.type);

    const content = this.renderEventPhrasing(action, event);

    return (
      <CSSTransition
        key={event.id}
        classNames="conversationEvent"
        enter={false}
        exit={false}
        timeout={0}
      >
        <ConversationEvent data-testid="conversation-event">
          {content}
        </ConversationEvent>
      </CSSTransition>
    );
  }

  renderEventPhrasing(action, event) {
    const { currentAccount, currentUser } = this.props;
    const { user, assignee } = event;

    const timeZone = getAccountTimeZone(currentAccount);

    const time = formatInTimeZone(
      new Date(event.displayTimestamp),
      timeZone,
      "h:mm a z",
    );
    const userName =
      currentUser && user && currentUser.id !== user.id ? user.name : "You";
    let assigneeName;

    if (action === "assigned" || action === "unassigned") {
      if (currentUser && currentUser.id === assignee.id && userName === "You") {
        assigneeName = "yourself";
      } else if (currentUser && currentUser.id === assignee.id) {
        assigneeName = "you";
      } else {
        assigneeName = assignee.name;
      }
      return `${userName} ${action} this conversation ${
        action === "assigned" ? "to" : "from"
      } ${assigneeName} at ${time}`;
    }

    if (action === "provider_phone_change") {
      return `This account's phone number was changed at ${time}`;
    }
    return event.user
      ? `${
          currentUser && currentUser.id === user.id ? "You" : user.firstName
        } ${action} this conversation at ${time}`
      : `This conversation was ${action} at ${time}`;
  }

  renderMessage(message, { shouldCollapse, isLastMessage }) {
    const {
      containerQueryParams,
      currentAccount,
      currentUser,
      appSettings,
      retryMessageRequest,
      scheduled,
      updateMessageRequest,
      deleteMessageRequest,
      showModal,
    } = this.props;
    const MessageWrapperTag = scheduled ? MessageEditWrapper : MessageWrapper;

    const timeZone = getAccountTimeZone(currentAccount);

    return (
      <CSSTransition
        key={message.id}
        classNames="message"
        timeout={MESSAGE_TRANSITION_DURATION}
        enter={["requested", "received"].includes(message.deliveryState)}
        exit={false}
      >
        {this.props.renderMessage ? (
          this.props.renderMessage({
            message,
            shouldCollapse,
            isLastMessage,
            retryMessageRequest,
            showModal,
            updateMessageRequest,
            deleteMessageRequest,
            appSettings,
            containerQueryParams,
            transitionDuration: MESSAGE_TRANSITION_DURATION,
          })
        ) : (
          <MessageSection
            direction={message.direction}
            data-message-id={message.id}
            data-testid="message"
            isSmall={containerQueryParams.small || containerQueryParams.short}
            shouldCollapse={shouldCollapse}
            duration={MESSAGE_TRANSITION_DURATION}
          >
            <MessageWrapperTag
              timeZone={timeZone}
              appSettings={appSettings}
              currentUser={currentUser}
              message={message}
              shouldCollapse={shouldCollapse}
              isLastMessage={isLastMessage}
              retryMessageRequest={retryMessageRequest}
              showModal={showModal}
              {...(scheduled && {
                updateMessageRequest,
                deleteMessageRequest,
              })}
            />
            {message.direction === "out" &&
              !containerQueryParams.superSmall && (
                <MessageSource
                  message={message}
                  shouldCollapse={shouldCollapse}
                />
              )}
          </MessageSection>
        )}
      </CSSTransition>
    );
  }

  render() {
    const { timeline, scheduled } = this.props;
    const hasScheduledMessages = timeline.members.some((member) => {
      return (
        member["@type"] === "Message" && member.deliveryState === "scheduled"
      );
    });
    return (
      <Root hasScheduledMessages={hasScheduledMessages} scheduled={scheduled}>
        {this.renderTimeline()}
      </Root>
    );
  }
}
