import assert from "assert";
import { userManager } from "@tesseract/core";
import { Account } from "../types";
import { headers, accountKeyMap, userKeyMap } from "./constants";
import { Account as AccountType } from "models/Account";

export const createMany = async (
  currentAccount: AccountType,
  accounts: Record<string, Account>,
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);
  assert(currentAccount.slug);

  const request = new Request(`/${currentAccount.slug}/provision/accounts`, {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/vnd.textus+jsonld",
    },
    body: JSON.stringify({
      accounts: Object.entries(accounts).map(([id, account]) => {
        return {
          id,
          [accountKeyMap.parent]: account.parent,
          [accountKeyMap.name]: account.name,
          [accountKeyMap.phoneType]: account.phoneType,
          [accountKeyMap.phone]: account.phone,
          users: Object.entries(account.users).map(([userId, _user]) => {
            return {
              [userKeyMap.id]: userId,
              [userKeyMap.name]: _user.name,
              [userKeyMap.email]: _user.email,
              [userKeyMap.role]: _user.role,
              [userKeyMap.sendWelcome]: _user.sendWelcome,
            };
          }),
        };
      }),
    }),
  });

  return fetch(request);
};
