import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import withSizes from "react-sizes";

import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";

import MoreVertIcon from "@mui/icons-material/MoreVert";

import BasicButton from "components/BasicButton";

const Toolbar = styled.div`
  align-items: center;
  background: ${(props) => {
    return (
      props.backgroundColor || props.theme.colors.message.outboundBackground
    );
  }};
  display: flex;
  font-size: 0.9rem;
  font-weight: 700;
  height: 56px;
  justify-content: space-between;
  left: 0px;
  padding: 0 10px;
  position: absolute;
  top: 0px;
  width: calc(100% - 64px);
  z-index: 10;

  ${({ position }) => {
    return `
    left: ${position.left || "0px"};
    top: ${position.top || "0px"};
    width: calc(100% - ${position.left || "0px"});
    `;
  }}

  > *:first-child {
    flex: 1 1 auto;
  }

  @media (min-width: 960px) {
    padding: 0 16px;
  }
`;

const ToolbarActions = styled.div`
  display: flex;
  flex: 0 0 auto;
`;

const SelectAllButton = styled(BasicButton)`
  display: flex;
  flex-flow: row wrap;
  font-weight: 700;
  text-align: left;

  > span:first-child {
    margin-right: 10px;
  }
`;

const SelectAllText = styled.span`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  font-weight: 700;
  text-decoration: underline;
`;

/**
 * @deprecated
 * - Replaced with GridActionToolbar.tsx (functional + TS component)
 * - ui/app/components/GridActionToolbar/GridActionToolbar.tsx
 */

class BatchActionToolbar extends Component {
  static propTypes = {
    actions: PropTypes.array.isRequired,
    allSelected: PropTypes.bool,
    backgroundColor: PropTypes.string,
    position: PropTypes.object,
    selectedCount: PropTypes.number.isRequired,
    selectAllCount: PropTypes.number,
    setAllSelected: PropTypes.func,
    setSelectedContacts: PropTypes.func,
    setSelectedRecords: PropTypes.func,
    showExpandedActions: PropTypes.bool.isRequired,
    showSelectAll: PropTypes.bool,
    styleOverrides: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleMoreOptionsClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuItemClick = (clickHandler) => {
    return () => {
      clickHandler();
      this.handleMoreOptionsClose();
    };
  };

  handleMoreOptionsClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelectAllClick = () => {
    const {
      allSelected,
      setAllSelected,
      setSelectedContacts,
      setSelectedRecords,
    } = this.props;
    setAllSelected(!allSelected);
    // setSelectedContacts check required for ActiveConversation flow from Inbox,
    // which does not get passed a setSelectedContacts prop.
    if (allSelected && Boolean(setSelectedContacts)) {
      setSelectedContacts([]);
      setSelectedRecords([]);
    }
  };

  render() {
    const {
      actions,
      backgroundColor,
      allSelected,
      position,
      selectedCount,
      selectAllCount,
      showExpandedActions,
      showSelectAll,
      styleOverrides,
    } = this.props;
    const { anchorEl } = this.state;
    const expandedActions = actions.filter((action) => {
      return action.showExpanded && showExpandedActions;
    });
    const otherActions = actions.filter((action) => {
      return !showExpandedActions || !action.showExpanded;
    });
    return (
      <Toolbar
        position={position}
        style={styleOverrides}
        backgroundColor={backgroundColor}
      >
        {showSelectAll ? (
          <SelectAllButton
            aria-label="Select all conversations total"
            onClick={this.handleSelectAllClick}
          >
            <span>
              {allSelected
                ? `All ${selectAllCount.toLocaleString()} selected`
                : `${selectedCount} selected`}
            </span>
            <SelectAllText aria-label="select all conversations total">
              {allSelected
                ? "Clear selection"
                : `Select all ${selectAllCount.toLocaleString()}`}
            </SelectAllText>
          </SelectAllButton>
        ) : (
          <div>{`${selectedCount} selected`}</div>
        )}
        <ToolbarActions>
          {expandedActions.map((action) => {
            if (action.button) {
              return action.button;
            }

            return (
              <Tooltip key={action.title} title={action.title}>
                <IconButton
                  onClick={action.clickHandler}
                  aria-label={action.title}
                  size="large"
                >
                  {action.icon}
                </IconButton>
              </Tooltip>
            );
          })}
          {otherActions.length > 0 && (
            <IconButton
              aria-owns={anchorEl ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="Open Additional Batch Options"
              onClick={this.handleMoreOptionsClick}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          )}
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            disableAutoFocusItem
            getContentAnchorEl={null}
            id="simple-menu"
            MenuListProps={{ disablePadding: true }}
            onClose={this.handleMoreOptionsClose}
            open={Boolean(anchorEl)}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            {otherActions.map((action) => {
              return (
                <MenuItem
                  key={action.title}
                  aria-label={action.title}
                  onClick={this.handleMenuItemClick(action.clickHandler)}
                >
                  <ListItemIcon>{action.icon}</ListItemIcon>
                  <ListItemText>{action.title}</ListItemText>
                </MenuItem>
              );
            })}
          </Menu>
        </ToolbarActions>
      </Toolbar>
    );
  }
}

export default withSizes(({ width }) => {
  return {
    showExpandedActions: width > 600,
  };
})(BatchActionToolbar);
