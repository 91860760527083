import styled from "styled-components";

export default styled.div`
  margin-bottom: 40px;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 20px;
  }
`;
