import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const unlinkKeywordFromSequences = async (
  currentAccount: Account.Raw,
  sequenceIds: string[],
  keywordGroupId: string,
): Promise<Response> => {
  return textUsApiV4.patch(
    `/${currentAccount.slug}/automation_keywords/${keywordGroupId}/unlink`,
    {
      sequenceIds,
    },
  );
};
