import { put, select } from "redux-saga/effects";
import { normalize } from "normalizr";
import get from "lodash/get";
import getOptimisticallyUpdatedConversation from "./getOptimisticallyUpdatedConversation";
import getOptimisticallyUpdatedConversationCollection from "./getOptimisticallyUpdatedConversationCollection";
import * as schema from "schema";

import { selectActiveConversationFilter } from "features/EntryPoint/containers/App/selectors";
import { updateRecords } from "features/EntryPoint/containers/App/actions";

export default (action) => {
  return function* optimisticUpdateFunc(url, params = {}) {
    const conversationIds = get(params, "conversations", [
      url.split("/", 3).join("/"),
    ]);
    const activeConversationFilter = yield select(
      selectActiveConversationFilter({
        conversations: {},
      }),
    );
    const activeConversationCollectionId = get(activeConversationFilter, [
      "conversations",
      "id",
    ]);
    const updatedConversationCollections = activeConversationCollectionId
      ? {
          [activeConversationCollectionId]:
            getOptimisticallyUpdatedConversationCollection(action)(
              conversationIds,
            )(activeConversationFilter),
        }
      : {};
    const updatedConversations = conversationIds
      .map((conversationId) => {
        return getOptimisticallyUpdatedConversation(action)(conversationId);
      })
      .map((revisedConversation) => {
        return normalize(revisedConversation, schema.conversation).entities;
      })
      .reduce(
        (prev, normalizedEntity) => {
          return {
            ...prev,
            conversations: {
              ...prev.conversations,
              ...normalizedEntity.conversations,
            },
            requestingUserInfo: {
              ...prev.requestingUserInfo,
              ...normalizedEntity.requestingUserInfo,
            },
          };
        },
        {
          conversationCollections: updatedConversationCollections,
        },
      );
    yield put(updateRecords(updatedConversations));
  };
};
