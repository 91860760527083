import { Collapse, IconButton, List } from "@mui/material";
import { useState } from "react";
import NavListItemButton from "./NavListItemButton";
import { CollapsibleNavListItemProps } from "./types";
import { ExpandLessIcon, ExpandMoreIcon } from "icons";

export default function CollapsibleNavListItem({
  isOpen = true,
  primary,
  to,
  children,
}: CollapsibleNavListItemProps) {
  const [open, setOpen] = useState(isOpen);

  const toggleButton = (
    <IconButton
      edge="end"
      onClick={(event) => {
        setOpen(!open);
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
  );

  return (
    <>
      <NavListItemButton
        handleClick={() => {
          setOpen(!open);
        }}
        icon={toggleButton}
        primary={primary}
        to={to}
      />

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </>
  );
}
