import { Dispatch, SetStateAction, useState } from "react";
import { useSequences } from "../useSequences";
import { useSequenceSteps } from "../useSequenceSteps";
import { useCurrentAccount, useLoading } from "hooks";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";
import { Sequence } from "models/Sequence";
import {
  CampaignContact,
  Contact,
  SelectedRecord,
} from "features/Sequences/components/Drawers/EnrollContactsDrawer/types";
import type { Contact as AutomationContact } from "features/Automation/models/AutomationModels";
import { fetchContacts } from "features/Sequences/api";

const useSequenceEnroller = (
  contactCollectionId: string,
  setSelected?: Dispatch<SetStateAction<string[]>>,
  setSelectedRecords?: Dispatch<
    SetStateAction<(Contact | AutomationContact)[]>
  >,
) => {
  const currentAccount = useCurrentAccount();
  const { allSequences, fetchAllSequences } = useSequences(
    currentAccount,
    SECONDARY_NAV_LINKS.mine,
  );
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { sequenceSteps, getSequenceSteps } = useSequenceSteps(currentAccount);

  const [selectSequenceDrawerIsOpen, setSelectSequenceDrawerIsOpen] =
    useState<boolean>(false);
  const [confirmEnrollmentDrawerIsOpen, setConfirmEnrollmentDrawerIsOpen] =
    useState<boolean>(false);
  const [selectedSequence, setSelectedSequence] = useState<Sequence>();
  const [allContacts, setAllContacts] = useState<SelectedRecord[]>([]);
  const [allContactsCount, setAllContactsCount] = useState(0);
  const [pagination, setPagination] = useState({
    id: "",
    first: "",
    next: "",
    prev: "",
  });

  const fetchAllContacts = async () => {
    try {
      startLoading();

      const url = pagination.next ? pagination.next : contactCollectionId;

      const response = await fetchContacts(url);
      const body = await response.json();
      const fromCampaigns = body.id?.includes("campaign_recipients") ?? false;
      const contacts: SelectedRecord[] = body.members.map(
        (record: Contact & CampaignContact) => {
          return fromCampaigns
            ? {
                name: record.contactPhone.contact.name,
                id: record.contactPhone.id,
                formattedPhoneNumber: record.contactPhone.formattedPhoneNumber,
                deliverabilityStatus: record.contactPhone.deliverabilityStatus,
              }
            : {
                name: record.name,
                id: record.phones.members[0].id,
                formattedPhoneNumber:
                  record.phones.members[0].formattedPhoneNumber,
                deliverabilityStatus:
                  record.phones.members[0].deliverabilityStatus,
              };
        },
      );

      // Initial fetch (first 25 contacts)
      if (!pagination.next && allContacts.length === 0) {
        setAllContacts(contacts);
        setAllContactsCount(body.totalItems);
        setPagination({
          id: body.view.id,
          first: body.view.first,
          next: body.view.next,
          prev: body.view.prev,
        });
        // Subsequent fetches (in batches of 25 contacts)
      } else {
        setAllContacts([...allContacts, ...contacts]);
        setPagination({
          ...pagination,
          next: body.view.next,
        });
      }
    } catch (err) {
      throw new Error(`Error fetching contacts: ${err}`);
    } finally {
      stopLoading();
    }
  };

  const openSelectSequenceDrawer = (): void => {
    setSelectSequenceDrawerIsOpen(true);
    fetchAllSequences();
  };

  const closeSelectSequenceDrawer = (): void => {
    setSelectSequenceDrawerIsOpen(false);
    setSelectedSequence(undefined);
  };

  const backToSelectSequenceDrawer = (): void => {
    setSelectSequenceDrawerIsOpen(true);
    setSelectedSequence(undefined);
    setConfirmEnrollmentDrawerIsOpen(false);
  };

  const openConfirmEnrollmentDrawer = (sequence: Sequence): void => {
    setSelectSequenceDrawerIsOpen(false);
    setConfirmEnrollmentDrawerIsOpen(true);
    getSequenceSteps(sequence.id);
  };

  const closeConfirmEnrollmentDrawer = (): void => {
    setConfirmEnrollmentDrawerIsOpen(false);
    setSelectedSequence(undefined);
  };

  const selectSequence = (sequence: Sequence): void => {
    setSelectedSequence(sequence);
    openConfirmEnrollmentDrawer(sequence);
  };

  const confirmEnrollment = (): void => {
    closeConfirmEnrollmentDrawer();
    if (setSelected && setSelectedRecords) {
      setSelected([]);
      setSelectedRecords([]);
    }
    setAllContacts([]);
  };

  return {
    allContacts,
    allContactsCount,
    allSequences,
    confirmEnrollmentDrawerIsOpen,
    isLoadingContacts: isLoading,
    selectedSequence,
    selectSequenceDrawerIsOpen,
    sequenceSteps,
    backToSelectSequenceDrawer,
    closeConfirmEnrollmentDrawer,
    closeSelectSequenceDrawer,
    confirmEnrollment,
    fetchAllContacts,
    openSelectSequenceDrawer,
    selectSequence,
  };
};

export { useSequenceEnroller };
