import { Box, Typography } from "@mui/material";
import { ConfirmUnlinkSequenceModalProps } from "./types";
import { DialogHeader } from "components/DialogHeader";
import { DialogFooter } from "components/DialogFooter";

function ConfirmUnlinkSequenceModal({
  sequence,
  keyword,
  close,
}: ConfirmUnlinkSequenceModalProps) {
  const copy = {
    body: `${keyword} keyword will no longer be linked to the sequence. Contacts will no longer be automatically enrolled 
    in the sequence after receiving the keyword auto-response.`,
    cancel: "Nevermind",
    confirm: "Unlink",
    title: "Unlink sequence?",
  } as const;

  const { body, cancel, confirm, title } = copy;

  return (
    <Box
      width="37.5rem"
      height="auto"
      sx={(theme) => {
        return {
          [theme.breakpoints.down("md")]: {
            width: "18rem",
          },
        };
      }}
    >
      <DialogHeader title={title} onClose={close(false)} />
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        padding="1rem"
      >
        <Typography marginBottom="0.5rem">{body}</Typography>
      </Box>
      <DialogFooter
        cancelText={cancel}
        confirmText={confirm}
        onCancel={close(false)}
        onConfirm={close([sequence.id])}
      />
    </Box>
  );
}

export default ConfirmUnlinkSequenceModal;
