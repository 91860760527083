import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class SalesforceStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () =>
    (/salesforce/i.test(this.location?.href) ||
      /visualforce/i.test(this.location?.href)) &&
    !/chg/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      textSelector: ".detailList",
      getNamesFunction: () =>
        Array.from($(doc).find("[id$='2_ileinner']"))
          .filter(node => /con2_ileinner/i.test(node.id))
          .map(node => {
            const fullName = getNamesFromText(node.textContent);
            return `${fullName[0]} ${fullName[1]}`;
          }),
      searchTextFunction: () =>
        Array.from($(doc).find(".detailList"))
          .map(node => {
            const clonedNode = node.cloneNode(true);
            clonedNode
              .querySelectorAll(`[style*="display: none"]`)
              .forEach(n => n.remove());
            clonedNode.querySelectorAll("td.labelCol").forEach(n => {
              if (n.innerText && n.innerText.includes("Emergency")) {
                n.nextSibling.remove();
              }
            });
            return clonedNode.textContent;
          })
          .join(", "),
      canParse: () =>
        !!$(doc).find("body.detailPage")[0] &&
        !/Call List/i.test(
          $(doc)
            .find("title")
            .text(),
        ),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "div.x-grid3-body td.x-grid3-td-FULL_NAME",
      containerSelector: "tr",
      canParse: () => !!$(doc).find(".listViewportWrapper")[0],
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
    });
    const overviewPageParser = new MultiContactParser({
      doc,
      namesSelector: "div.hotListElement tr.dataRow th",
      containerSelector: "tr",
      canParse: () => !!$(doc).find("body.overviewPage")[0],
      searchTextFunction: node =>
        Array.from(node.closest("tr").children)
          .map(n => {
            const content = n.innerHTML; // Separates the phone numbers in the adjacent nodes when click to dial is enabled so the numbers can be parsed and do not end up as one long string of numbers
            return content.trim();
          })
          .join(", "),
    });
    const callListParser = new MultiContactParser({
      doc,
      namesSelector: "tr.dataRow th a:first-child",
      containerSelector: "tr",
      canParse: () =>
        !!$(doc).find("body.detailPage")[0] &&
        /Call List/i.test(
          $(doc)
            .find("title")
            .text(),
        ),
    });
    return [
      detailPageParser,
      listPageParser,
      overviewPageParser,
      callListParser,
    ];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "SalesforceStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "SalesforceStrategy");
      return null;
    }
  };
}
