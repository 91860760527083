export const acceptedFileTypes = Object.freeze({
  "application/pdf": "pdf",
  "application/rtf": "rtf",
  "image/gif": "gif",
  "image/jpeg": "jpg",
  "image/png": "png",
  "text/calendar": "ics",
  "text/vcard": "vcf",
  "text/x-vcard": "vcf",
});
