import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import withSizes from "react-sizes";

import breakpoints from "utils/styles/breakpoints";
import Avatar from "components/Avatar";
import H3 from "components/H3";

const GradientDisc = styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation: rotate 4s infinite;
  background: linear-gradient(to top, #8cc63f, #5cb5bd);
  border-radius: 50%;
  height: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all ease-in-out 0.3s;
  width: 0;
  z-index: -1;

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const AvatarWrapper = styled(Link)`
  margin-right: 20px;
  position: relative;

  &:hover {
    filter: saturate(1.5);

    ${GradientDisc} {
      height: 112%;
      width: 112%;
    }
  }
`;

class UserInfoHeader extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
    isSmall: PropTypes.bool.isRequired,
  };

  render() {
    const { currentUser, isSmall } = this.props;

    return (
      <>
        <AvatarWrapper to="/profile">
          <Avatar size={isSmall ? 40 : 60} subject={currentUser} />
          <GradientDisc />
        </AvatarWrapper>
        <H3 data-product-tour="DashboardHeader">Welcome, {currentUser.name}</H3>
      </>
    );
  }
}

export default withSizes(({ width }) => {
  return {
    isSmall: width < breakpoints.medium,
  };
})(UserInfoHeader);
