import "./index.css";
import "core-js/stable";
import "sanitize.css/sanitize.css";
import React from "react";
import { createRoot } from "react-dom/client";
import { loadUser } from "redux-oidc";
import { Provider } from "react-redux";
import queryString from "query-string";
import { login, userManager } from "@tesseract/core";
import { store } from "@tesseract/legacy/app/store";
import areLoginQueryParamsPresent from "@tesseract/legacy/app/utils/areLoginQueryParamsPresent";
import configureHoneybadger from "@tesseract/legacy/app/utils/configureHoneybadger";
import ErrorBoundary from "@tesseract/legacy/app/components/ErrorBoundary";
import history from "@tesseract/legacy/app/utils/history";
import localStorageHelper from "@tesseract/legacy/app/utils/localStorageHelper";
import LoginError from "@tesseract/legacy/app/features/LoginError";
import "@tesseract/legacy/app/utils/smaller-console-error-stack-traces";
import App from "./App";

declare global {
  interface Window {
    error: unknown;
  }
}

const render = async () => {
  const container = document.querySelector("#app");

  const root = createRoot(container!);

  const loginError = window.error;

  if (loginError) {
    root.render(
      <React.StrictMode>
        <ErrorBoundary>
          <Provider store={store}>
            <LoginError loginError={loginError} />
          </Provider>
        </ErrorBoundary>
      </React.StrictMode>,
    );
  } else {
    await login();

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadUser(store, userManager);

    root.render(<App />);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const conversationPath = localStorageHelper.getItem("conversationPath");
    if (conversationPath) {
      localStorage.removeItem("conversationPath");
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      history.push(conversationPath);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-floating-promises, unicorn/prefer-top-level-await
(async () => {
  const { extensionUrl } = queryString.parse(window.location.search);

  if (extensionUrl) {
    return window.location.assign(decodeURIComponent(extensionUrl as string));
  }

  // Redirect the user to "/" if they do not have the proper login query params
  if (
    document.location.pathname === "/login" &&
    !areLoginQueryParamsPresent()
  ) {
    localStorage.removeItem("oldauth");
    return window.location.assign("/");
  }

  // Configure HB if not in test mode
  if (process.env.NODE_ENV !== "test") configureHoneybadger();

  return render();
})();
