import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import get from "lodash/get";
import startCase from "lodash/startCase";
import { compactInteger } from "humanize-plus";
import Avatar from "components/Avatar";
import H4 from "components/H4";
import H5 from "components/H5";
import EllipsisOverflow from "components/EllipsisOverflow";

const AccountInfo = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  height: auto;
  padding: 20px;
  flex: 0 0 auto;
  width: 200px;
  border-right: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  overflow: hidden;

  section {
    margin-left: 15px;
    display: flex;
    flex: 1 1 100%;
    flex-flow: column nowrap;
    overflow: hidden;
  }
`;

const AccountInfoSmall = css`
  height: 70px;
  width: 100%;
  border: 0;
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
`;

const AccountName = styled(H4)`
  font-size: ${(props) => {
    return props.theme.fonts.primaryHeaderFontSize;
  }};
  font-weight: 700;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
`;

const AccountPhone = styled(H5)`
  font-size: ${(props) => {
    return props.theme.fonts.secondaryHeaderFontSize;
  }};
  font-weight: 500;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
`;

const NotEnabled = styled.p`
  margin: 0;
  font-size: ${(props) => {
    return props.theme.fonts.secondaryFontSize;
  }};
  font-weight: 500;
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
`;

const Metrics = styled.div`
  flex: 1 1 100%;
  height: 100%;
  min-height: 130px;
  display: flex;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  position: relative;
`;

const MetricsSmall = css`
  width: 100%;
  flex-flow: column;
  align-items: flex-start;
`;

const Stat = styled.p`
  font-size: 66px;
  font-weight: 700;
  margin: 0;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
`;

const Operator = styled.span`
  font-size: 33px;
  margin-left: 10px;
`;

const MetricCell = styled.div`
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  padding: 15px;
  flex: 1 1 120px;
  flex-flow: column nowrap;
  text-align: center;

  ${H4} {
    font-weight: 300;
    white-space: nowrap;
  }
`;

const MetricCellSmall = css`
  flex: 1 1 100%;
  padding: 5px 20px;
  flex-flow: row;
  justify-content: space-between;
  width: 100%;
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};

  ${H4} {
    white-space: normal;
    font-weight: 600;
    flex: 0 1 300px;
    text-align: left;
  }

  ${Stat} {
    font-size: 40px;
  }

  ${Operator} {
    font-size: 15px;
    margin-left: 3px;
    margin-top: -10px;
  }
`;

const Root = styled.div`
  display: flex;
  width: 100%;
  flex-flow: ${(props) => {
    return props.isSmall ? "column" : "row";
  }};

  ${(props) => {
    if (props.isSmall) {
      return css`
        ${AccountInfo} {
          ${AccountInfoSmall};
        }

        ${Metrics} {
          ${MetricsSmall};
        }

        ${MetricCell} {
          ${MetricCellSmall};
        }
      `;
    }
    return null;
  }};
`;

export default class MainView extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    className: PropTypes.string,
    isSmall: PropTypes.bool.isRequired,
    accountAnalytics: PropTypes.object.isRequired,
  };

  renderMetrics() {
    const { accountAnalytics } = this.props;

    const metrics = [
      "activeConversations",
      "responseRate",
      "messagesPerConversation",
    ];

    return metrics.map((metric) => {
      return (
        <MetricCell key={metric}>
          <H4>{startCase(metric)}</H4>

          <Stat>
            <span>
              {compactInteger(
                metric === "responseRate"
                  ? accountAnalytics[metric] * 100
                  : accountAnalytics[metric],
                1,
              )}
            </span>
            {metric === "responseRate" && <Operator>%</Operator>}
          </Stat>
        </MetricCell>
      );
    });
  }

  render() {
    const { account, isSmall } = this.props;
    return (
      <Root {...this.props} isSmall={isSmall} className={this.props.className}>
        <AccountInfo>
          <Avatar subject={account} />
          <section>
            <EllipsisOverflow>
              <AccountName>{account.name}</AccountName>
            </EllipsisOverflow>
            {get(account, ["phoneNumbers"], []).length > 0 ? (
              <AccountPhone>{account.phoneNumbers[0]}</AccountPhone>
            ) : (
              <NotEnabled>Non-messaging admin account</NotEnabled>
            )}
          </section>
        </AccountInfo>
        <Metrics>{this.renderMetrics()}</Metrics>
      </Root>
    );
  }
}
