import { useCallback, useEffect, useMemo, useState } from "react";
import { Formik, Form, Field, getIn } from "formik";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Stack, Button, TextField, Typography, Box } from "@mui/material";
import IntegrationSubmitSection from "./IntegrationSubmitSection";
import { getWindowFeatures } from "./utils";
import { IntegrationProps } from "./types";
import { StyledExpansionPanel, StyledExpansionPanelDetails } from "./common";
import { Logo } from "./Logo/Logo";
import ModalHeader from "components/ModalHeader";
import ModalContent from "components/ModalContent";
import { Section, Row } from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import H4 from "components/H4";
import { Switch } from "components/Switch";
import { NativeSelect } from "components/NativeSelect";
import { MsDynamicsValidationSchema } from "formHelpers/validationSchemas";
import { usePopup } from "utils/popup";

interface OAuthConfig {
  consentUrl: string;
  clientId: string;
  redirectUri: string;
}

/**
 * Get url for MS Dynamic authorization
 *
 * @param {OAuthConfig}
 * @returns MS Dynamic url
 */
const getUrl = ({ consentUrl, clientId, redirectUri }: OAuthConfig) => {
  return `${consentUrl}?client_id=${clientId}&redirect_uri=${redirectUri}` as const;
};

/**
 *  <MsDynamics /> component
 *
 * @param {Props} - <MsDynamics /> props
 * @returns React element
 */
export default function MsDynamics({
  account,
  appSettings,
  deleteIntegrationRequest,
  fetchIntegrationCollectionRequest,
  handleSubmit: submit,
  provider,
  toggleActive,
}: IntegrationProps<
  {
    clientId: string;
    clientSecret: string;
    organizationUrl: string;
    tenantId: string;
  },
  {
    batching?: {
      enabled: boolean;
      note_duration: string;
      update_frequency_minutes: number;
    };
  }
>) {
  const isAuthenticated = useMemo(() => {
    const _isAuthenticated = provider.integration;

    return _isAuthenticated;
  }, [provider.integration]);

  const [expandedPanel, setExpandedPanel] = useState<string | undefined>(() => {
    // if batching is enabled expand panel on mount
    return provider.integration?.settings?.batching?.enabled === true
      ? "settings"
      : undefined;
  });

  /**
   * Handle de-authorize
   */
  const handleDeauthorize = useCallback(() => {
    deleteIntegrationRequest(provider?.integration?.id, null, {
      successCallback: () => {
        fetchIntegrationCollectionRequest(account.integrations);
        provider.closeModal();
      },
    });
  }, [
    account.integrations,
    deleteIntegrationRequest,
    fetchIntegrationCollectionRequest,
    provider,
  ]);

  /**
   * Popup
   */
  const { open: openAuthorizer } = usePopup<string>(
    "MicrosoftDynamicsChannel",
    useMemo(() => {
      return [
        getUrl({
          consentUrl: appSettings.DYNAMICS_CONSENT_URL,
          clientId: appSettings.DYNAMICS_CLIENT_ID,
          redirectUri: `${window.location.origin}/auth/dynamics_callback`,
        }),
        "MS Dynamics Window",
        getWindowFeatures({ width: 800, height: 800 }),
      ] as const;
    }, [appSettings.DYNAMICS_CLIENT_ID, appSettings.DYNAMICS_CONSENT_URL]),
  );

  /**
   * Handle submit
   */
  const handleSubmit = useCallback(
    ({ config, settings }, closeModal: boolean) => {
      return submit(
        {
          config,
          provider,
          settings,
        },
        closeModal,
      );
    },
    [provider, submit],
  );

  /**
   * Handle click
   */
  const handleClick = useCallback(
    (values) => {
      openAuthorizer({
        /**
         * Submit callback
         *
         * @param _tenantId tenant is sent to us from MS Dynamics
         */
        submit: (_tenantId) => {
          handleSubmit(values, false);
        },
      });
    },
    [handleSubmit, openAuthorizer],
  );

  return (
    <>
      <ModalHeader closeModal={provider.closeModal}>
        {provider.name}
      </ModalHeader>
      <ModalContent noPadding>
        <Stack>
          <Formik
            onSubmit={(props) => {
              handleSubmit(props, true);
            }}
            initialValues={{
              config: {
                clientId: provider.integration?.config?.clientId,
                clientSecret: provider.integration?.config?.clientSecret,
                organizationUrl: provider.integration?.config?.organizationUrl,
                tenantId: provider.integration?.config?.tenantId,
              },
              settings: {
                batching: {
                  enabled:
                    provider.integration?.settings?.batching?.enabled ?? false,
                  noteDuration:
                    provider.integration?.settings?.batching?.note_duration,
                  updateFrequencyMinutes:
                    provider.integration?.settings?.batching
                      ?.update_frequency_minutes,
                },
              },
            }}
            validationSchema={MsDynamicsValidationSchema}
          >
            {({ isSubmitting, isValid, values, touched, errors, dirty }) => {
              return (
                <Form>
                  <Section>
                    <Logo src={provider.logo} width="252" height="100" />
                    <Stack spacing={2} marginBottom={3}>
                      <Field name="config.clientId" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.clientId") &&
                                Boolean(getIn(errors, "config.clientId"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.clientId") &&
                                getIn(errors, "config.clientId")
                                  ? "Required for Authorization" || " "
                                  : " "
                              }
                              label="Client ID"
                              type="text"
                              variant="outlined"
                              size="small"
                            />
                          );
                        }}
                      </Field>
                      <Field name="config.clientSecret" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.clientSecret") &&
                                Boolean(getIn(errors, "config.clientSecret"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.clientSecret") &&
                                getIn(errors, "config.clientSecret")
                                  ? "Required for Authorization" || " "
                                  : " "
                              }
                              label="Client Secret"
                              type="text"
                              variant="outlined"
                              size="small"
                            />
                          );
                        }}
                      </Field>
                      <Field name="config.tenantId" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.tenantId") &&
                                Boolean(getIn(errors, "config.tenantId"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.tenantId") &&
                                getIn(errors, "config.tenantId")
                                  ? "Required for Authorization" || " "
                                  : " "
                              }
                              label="Tenant ID"
                              type="text"
                              variant="outlined"
                              size="small"
                            />
                          );
                        }}
                      </Field>
                      <Field name="config.organizationUrl" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.organizationUrl") &&
                                Boolean(getIn(errors, "config.organizationUrl"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.organizationUrl") &&
                                getIn(errors, "config.organizationUrl")
                                  ? "Required for Authorization" || " "
                                  : " "
                              }
                              label="Organization URL"
                              type="text"
                              variant="outlined"
                              size="small"
                            />
                          );
                        }}
                      </Field>
                      <Typography>
                        <Box
                          sx={{
                            lineHeight: 1.43,
                            letterSpacing: 0.17,
                          }}
                          textAlign="center"
                        >
                          Need help? Check out{" "}
                          <a
                            href="https://help.textus.com/s/detail/TextUs-MSDynamics-Integration-Setup-Guide"
                            target="_blank"
                            style={{ color: "#283C59" }}
                            rel="noreferrer"
                          >
                            MS Dynamics Integration Setup
                          </a>
                        </Box>
                      </Typography>
                      <Row>
                        {isAuthenticated ? (
                          <Button
                            fullWidth
                            variant="outlined"
                            onClick={() => {
                              handleDeauthorize();
                            }}
                            color="error"
                            aria-label="Deauthorize"
                          >
                            Deauthorize
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            disabled={!isValid || !dirty}
                            variant="outlined"
                            onClick={() => {
                              handleClick(values);
                            }}
                            color="primary"
                            aria-label="Authorize"
                          >
                            Authorize
                          </Button>
                        )}
                      </Row>
                    </Stack>
                  </Section>
                  <StyledExpansionPanel
                    expanded={expandedPanel === "settings"}
                    onChange={() => {
                      setExpandedPanel(
                        expandedPanel === "settings" ? undefined : "settings",
                      );
                    }}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      Settings
                    </ExpansionPanelSummary>
                    <StyledExpansionPanelDetails>
                      <Section>
                        <Stack>
                          <H4>Batch Conversation Logging</H4>
                          <Switch
                            name="settings.batching.enabled"
                            color="secondary"
                            options={[
                              {
                                value: true,
                                label: "Active",
                              },
                              {
                                value: false,
                                label: "Inactive",
                              },
                            ]}
                          />
                          {values.settings?.batching?.enabled ? (
                            <>
                              <Row>
                                <NativeSelect
                                  {...{
                                    defaultValue: "daily",
                                    label: "Conversation Duration",
                                    name: "settings.batching.noteDuration",
                                    options: [
                                      {
                                        label: "One Day",
                                        value: "daily",
                                      },
                                      {
                                        label: "One Week",
                                        value: "weekly",
                                      },
                                    ],
                                  }}
                                />
                              </Row>
                              <Row>
                                <NativeSelect
                                  {...{
                                    defaultValue: 0,
                                    label: "Refresh Frequency",
                                    name: "settings.batching.updateFrequencyMinutes",
                                    options: [
                                      {
                                        label: "Every Message",
                                        value: 0,
                                      },
                                      {
                                        label: "15 Minutes",
                                        value: 15,
                                      },
                                      {
                                        label: "1 Hour",
                                        value: 60,
                                      },
                                      {
                                        label: "1 Day",
                                        value: 1440,
                                      },
                                      {
                                        label: "1 Week",
                                        value: 10_080,
                                      },
                                    ],
                                  }}
                                />
                              </Row>
                            </>
                          ) : undefined}
                        </Stack>
                      </Section>
                    </StyledExpansionPanelDetails>
                  </StyledExpansionPanel>
                  <IntegrationSubmitSection
                    isSubmitting={isSubmitting}
                    isValid={Boolean(isValid && isAuthenticated)}
                    provider={provider}
                    toggleActive={toggleActive}
                  />
                </Form>
              );
            }}
          </Formik>
        </Stack>
      </ModalContent>
    </>
  );
}
