import { createContactApi } from "../api/createContactApi";
import { getContactsByPhoneApi } from "../api/getContactsByPhoneApi";
import { ResponseContact, Contact } from "../interfaces/Contact";

const getOrCreateContacts = async ({
  phoneNumber,
  name,
  accountSlug,
}: {
  phoneNumber: string;
  name: string;
  accountSlug: string;
}): Promise<ResponseContact[]> => {
  // Get contacts by phone number.
  const contacts = await getContactsByPhoneApi({
    phoneNumber,
    accountSlug,
  });

  // If contact(s) found, return array with contact details.
  if (contacts.length > 0) {
    return contacts.map((cont: Contact) => {
      return {
        id: cont.id.replace(/^\/contacts\//, ""),
        name: cont.name || "Unknown Name",
        phoneNumber,
        isOnPage: cont.name?.toLowerCase().trim() === name.toLowerCase().trim(),
      };
    });
  }

  // Create a new contact if no contact was found.
  const newContact = await createContactApi({
    phoneNumber,
    accountSlug,
    name,
  });

  // Return an array with single contact.
  return [
    {
      id: newContact.id.replace(/^\/contacts\//, ""),
      name,
      phoneNumber: newContact.phones.members[0].phoneNumber,
      isOnPage: true,
    },
  ];
};

export default getOrCreateContacts;
