import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import { actionGenerators, saga } from "./state";
import {
  selectCurrentAccount,
  selectExtractedPhones,
} from "features/EntryPoint/containers/App/selectors";
import { updateExtractedPhones } from "features/EntryPoint/containers/App/actions";
import injectSaga from "utils/injectSaga";
import Root from "features/ContactImports/containers/NewContactImport/components/Root";

class NewContactImport extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    extractedPhones: selectExtractedPhones(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  updateExtractedPhones,
});

const withSaga = injectSaga({ key: "NewContactImportContainer", saga });

export default compose(withSaga, withConnect)(NewContactImport);
