import PropTypes from "prop-types";
import Box from "@mui/material/Box";

function SearchBar({ children }) {
  return (
    <Box
      position="relative"
      width="100%"
      height="55px"
      display="flex"
      flex="0 0 55px"
      border={1}
      borderRight={0}
      borderLeft={0}
      borderTop={0}
      borderColor="divider"
      zIndex="99"
    >
      {children}
    </Box>
  );
}

SearchBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SearchBar;
