import { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Logo from "components/Logo";
import BlankState from "components/BlankState";

const gradient = css`
  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.45));
  }
`;

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${(props) => {
    return props.inverse
      ? props.theme.colors.navigation.background
      : "transparent";
  }};
  z-index: 10;

  ${(props) => {
    return props.inverse && gradient;
  }};
`;

class PageLoader extends Component {
  static propTypes = {
    title: PropTypes.string,
    logoWidth: PropTypes.number,
    inverse: PropTypes.bool,
  };

  static defaultProps = {
    logoWidth: 50,
  };

  render() {
    const { title, logoWidth, inverse } = this.props;
    return (
      <Wrapper inverse={inverse} aria-label="Loading">
        <BlankState
          image={
            <Logo
              animate
              width={logoWidth}
              dotColor={inverse ? "contrast" : "primary"}
              color="transparent"
            />
          }
          title={title}
        />
      </Wrapper>
    );
  }
}

export default PageLoader;
