import camelCase from "lodash/camelCase";
import compose from "lodash/fp/compose";
import endsWith from "lodash/endsWith";
import snakeCase from "lodash/snakeCase";
import toUpper from "lodash/toUpper";

export default ({ actionTypes, crudActions, recordType }: any) => {
  return crudActions.reduce((prev: any, crudAction: any) => {
    const presentParticipleAction = endsWith(crudAction, "e")
      ? `${crudAction.substring(0, crudAction.length - 1)}ing`
      : `${crudAction}ing`;
    const isReducerName = camelCase(`is ${presentParticipleAction}`);
    const isReducerInitialState = isReducerName === "isFetching";
    // eslint-disable-next-line default-param-last
    const isReducer = (state = isReducerInitialState, action: any) => {
      switch (action.type) {
        case actionTypes[
          compose(toUpper, snakeCase)(`${crudAction} ${recordType} Request`)
        ]:
          return true;
        case actionTypes[
          compose(toUpper, snakeCase)(`${crudAction} ${recordType} Success`)
        ]:
          return false;
        case actionTypes[
          compose(toUpper, snakeCase)(`${crudAction} ${recordType} Failure`)
        ]:
          return false;
        default:
          return state;
      }
    };
    const errorReducerName = camelCase(`error ${presentParticipleAction}`);
    // eslint-disable-next-line default-param-last
    const errorReducer = (state = null, action: any) => {
      switch (action.type) {
        case actionTypes[
          compose(toUpper, snakeCase)(`${crudAction} ${recordType} Request`)
        ]:
          return null;
        case actionTypes[
          compose(toUpper, snakeCase)(`${crudAction} ${recordType} Success`)
        ]:
          return null;
        case actionTypes[
          compose(toUpper, snakeCase)(`${crudAction} ${recordType} Failure`)
        ]:
          return {
            response: action.response,
            url: action.url,
            params: action.params,
          };
        default:
          return state;
      }
    };
    return {
      ...prev,
      [isReducerName]: isReducer,
      [errorReducerName]: errorReducer,
    };
  }, {});
};
