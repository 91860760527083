import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Formik, Field, Form, getIn } from "formik";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { ContactValidationSchema } from "formHelpers/validationSchemas";
import H3 from "components/H3";
import withSubmit from "higherOrderComponents/withSubmit";

const Card = styled.section`
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex-flow: column;
  padding: 10px 20px;
`;

const FormRow = styled.div`
  align-items: flex-end;
  display: flex;
  flex-flow: column;

  > *:last-child {
    flex: 0 0 auto;
  }

  @media (min-width: 600px) {
    align-items: center;
    flex-flow: row;

    > div {
      margin-right: 20px;
    }
  }
`;

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled.div`
  padding-top: 10px;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
  }
`;

export class Root extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    searchQuery: PropTypes.string.isRequired,
  };

  getInitalValues = () => {
    const { searchQuery } = this.props;
    const { name, number } = /^[0-9|+|(]/.test(searchQuery)
      ? { name: "", number: searchQuery }
      : { name: searchQuery, number: "" };
    return {
      name,
      phones: [{ number }],
      method: "ui",
      source: "manual",
    };
  };

  render() {
    const { handleSubmit } = this.props;
    const initialValues = this.getInitalValues();
    return (
      <Card>
        <H3>Create New Contact</H3>
        <FormWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            isInitialValid={ContactValidationSchema.isValidSync(initialValues)}
            validationSchema={ContactValidationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors = {}, touched = {}, isSubmitting, isValid }) => {
              return (
                <Form>
                  <FormRow>
                    <Field type="text" name="name">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={touched.name && Boolean(errors.name)}
                            fullWidth
                            variant="outlined"
                            helperText={touched.name ? errors.name || " " : " "}
                            inputProps={{
                              "data-lpignore": true,
                              "aria-label": "Contact Name",
                              "data-testid": "contact-name",
                            }}
                            label="Name"
                            placeholder="Bugs Bunny"
                            type="text"
                          />
                        );
                      }}
                    </Field>
                    <Field name="phones[0].number">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, `phones[0].number`) &&
                              Boolean(getIn(errors, `phones[0].number`))
                            }
                            fullWidth
                            variant="outlined"
                            helperText={
                              getIn(touched, `phones[0].number`)
                                ? getIn(errors, `phones[0].number`) || " "
                                : " "
                            }
                            inputProps={{
                              "data-lpignore": true,
                              "aria-label": "Contact Number",
                            }}
                            label="Number"
                            placeholder="(555) 555-5555"
                            type="text"
                          />
                        );
                      }}
                    </Field>
                    <Button
                      aria-label="Create Contact"
                      data-testid="create-contact"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      Create
                    </Button>
                  </FormRow>
                </Form>
              );
            }}
          </Formik>
        </FormWrapper>
      </Card>
    );
  }
}

export default withSubmit(Root);
