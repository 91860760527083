import { connect } from "react-redux";
import { compose } from "redux";
import withSizes from "react-sizes";

import { actionGenerators, reducer, selectors } from "./state";
import { ContactModal } from "./ContactModal";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import injectReducer from "utils/injectReducer";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import breakpoints from "utils/styles/breakpoints";

const ContactModalWithSizes = withSizes(({ width }) => {
  return {
    fullScreen: width < breakpoints.sm,
    isSinglePanel: width < breakpoints.sm,
  };
})(ContactModal);

const mapStateToProps = (state, props) => {
  return {
    contactModalContainer: selectors.selectContactModalContainer(state, props),
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  setCompose: composeActionGenerators.setCompose,
});

const withReducer = injectReducer({ key: "contactModalContainer", reducer });

export default compose(
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.sm };
  }),
  withReducer,
  withConnect,
)(ContactModalWithSizes);
