import { Component } from "react";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class ConversationStats extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "features/Inbox/containers/ConversationStats",
  shape: {},
  type: "conversationStats",
})(ConversationStats);
