import CampaignSettings from "../components/CampaignSettings";
import CharacterLimit from "../components/CharacterLimit";
import FilterContactSettings from "../components/FilterContactSettings";
import RegisteredLinks from "../components/RegisteredLinks";
import { Account } from "models/Account";

import { SettingsSection } from "models/SettingsSection";

export const getCampaignSettings = ({
  account,
  isAdmin,
}: {
  account: Account;
  isAdmin: boolean;
}): SettingsSection | null => {
  const organizationAccount = account?.type === "organization";
  const legalEntity = !!account?.settings?.legalEntity?.value;

  const settings = [
    {
      title: "Cutoff Time",
      to: `/campaign-delivery-cutoff-time`,
      OptionComponent: CampaignSettings,
    },
  ]
    .concat(
      organizationAccount || legalEntity
        ? {
            title: "Shortened Links",
            to: `/registered-links`,
            OptionComponent: RegisteredLinks,
          }
        : [],
    )
    .concat(
      organizationAccount
        ? {
            title: "Filter Out Contacts",
            to: `/filter-out-contacts`,
            OptionComponent: FilterContactSettings,
          }
        : [],
    )
    .concat(
      {
        title: "Character Limit",
        to: `/character-limit`,
        OptionComponent: CharacterLimit,
      }
    );

  return isAdmin // this collection of settings is restricted to admins
    ? {
        title: "Campaign Settings",
        path: "campaign",
        settings,
      }
    : null;
};
