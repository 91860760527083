/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Formik, Field, Form } from "formik";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import CircularProgress from "@mui/material/CircularProgress";
import SavedReplyDeleteDialog from "./SavedReplyDeleteDialog";
import {
  createSavedReply,
  fetchSavedReplyCollection,
  updateSavedReply,
  fetchUserData,
  deleteSavedReply,
} from "features/SavedReplies/api";
import { SavedReplyValidationSchema } from "formHelpers/validationSchemas";
import getUserRole from "utils/getUserRole";
import MessageInputFormRow from "components/MessageInputFormRow";
import { DEFAULT_MAX_MESSAGE_LENGTH } from "constants/defaults";
import { swapLinksInBody } from "components/MessageInputOptions/ShortenedLinkOption/utils/swapLinksInBody";
import { StickyFooter } from "components/StickyFooter";
import { useCurrentUser } from "hooks";

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled(Box)((props) => {
  const { templatesMaintenance } = props;
  return {
    fontSize: "0.8rem",
    padding: templatesMaintenance ? "0px" : "20px",
    width: "100%",
    input: {
      "-webkit-autofill": {
        "-webkit-transition-delay": "99999s",
      },
      "-webkit-autofill:hover": {
        "-webkit-transition-delay": "99999s",
      },
      "-webkit-autofill:focus": {
        "-webkit-transition-delay": "99999s",
      },
      "-webkit-autofill:active": {
        "-webkit-transition-delay": "99999s",
      },
    },
    form: {
      display: "flex",
      width: "100%",
      flexFlow: "column",
      maxHeight: templatesMaintenance ? "417px" : "500px",
      justifyContent: templatesMaintenance ? "flex-start" : "center",
    },
  };
});

function SavedReplyForm({
  currentAccount,
  currentUser,
  handleCloseModal,
  handleSavedReplySelected,
  handleSelect,
  hideList,
  savedReplyCollectionId,
  savedReply,
  setActiveSavedReply,
  setSavedReplies,
  settings,
  settingsAccount,
  templatesMaintenance,
}) {
  const user = useCurrentUser();
  const userRole = getUserRole(user, currentAccount);
  const isAdmin = userRole === "admin";

  const [templateOwner, setTemplateOwner] = useState(null);
  const [submitType, setSubmitType] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [shortenedLink, setShortenedLink] = useState({
    fullLink: "",
    shortLink: currentAccount.shortenedLinkPreviewUrl,
  });
  const [open, setOpen] = useState(false);

  const getFooterWidth = () => {
    if (hideList) {
      return "100%";
    }

    if (settings) {
      return "100%";
    }

    return "53%";
  };

  useEffect(() => {
    const handleFetchSavedReplyOwner = async () => {
      try {
        if (!savedReply) {
          return;
        }
        if (savedReply.user.id) {
          setTemplateOwner(savedReply.user.name);
          return;
        }
        const response = await fetchUserData(
          savedReply.user.id ?? savedReply.user,
        );
        if (!response.ok) {
          throw new Error("Failed to fetch template owner");
        }
        const owner = await response.json();
        setTemplateOwner(owner.name);
      } catch (error) {
        enqueueSnackbar("Failed to fetch template owner", {
          variant: "error",
        });
        console.log(error);
      }
    };

    handleFetchSavedReplyOwner().catch((error) => {
      console.log(error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedReply]);

  const getIsShareable = (isDisabled) => {
    const account = settingsAccount || currentAccount;
    return (
      !isDisabled &&
      getUserRole(currentUser, account) === "admin" &&
      (account.multiUser || account.childrenCount > 0)
    );
  };

  const handleSubmit = async (values, actions) => {
    const { shortLink, fullLink } = shortenedLink;
    const { content } = values;
    const templateBody = fullLink
      ? swapLinksInBody(shortLink, `{{ "${fullLink}" | shortlink }}`, content)
      : content;
    const { requestUrl, submitHandler } = savedReply
      ? { requestUrl: savedReply.id, submitHandler: updateSavedReply }
      : {
          requestUrl: savedReplyCollectionId,
          submitHandler: createSavedReply,
        };
    const templateParams = { ...values, content: templateBody };

    try {
      const response = await submitHandler(requestUrl, templateParams);
      const data = await response.json();

      const flaggedCopy = savedReply ? "Template saved." : "Template created.";
      const unflaggedCopy = savedReply
        ? "You successfully edited a template."
        : "You successfully created a template.";

      if (response.ok) {
        enqueueSnackbar(templatesMaintenance ? flaggedCopy : unflaggedCopy, {
          variant: "info",
          width: "auto",
        });
        const flaggedFetch = () => {
          fetchSavedReplyCollection(currentUser.savedReplies, "mine")
            .then(async (res) => {
              const newSavedReplies = await res.json();
              return setSavedReplies(newSavedReplies);
            })
            .catch((error) => {
              console.error(error);
            });
        };
        flaggedFetch();
      }

      if (handleCloseModal) {
        handleCloseModal();
      }

      if (submitType === "saveAndUse") {
        return handleSavedReplySelected(data);
      }
      return setActiveSavedReply({ ...data, user: currentUser });
    } catch {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error",
      });
    }
    return undefined;
  };

  const { conversationMaxMessageLength } = currentAccount.settings;
  const maxCharacterLength =
    conversationMaxMessageLength?.value ?? DEFAULT_MAX_MESSAGE_LENGTH;
  const initialValues = {
    title: get(savedReply, ["title"], ""),
    content: get(savedReply, ["content"], ""),
    shared: get(savedReply, ["shared"], false),
  };
  const savedReplyUserId = savedReply?.user;

  const isDisabled = savedReply && savedReplyUserId !== currentUser.id;
  const isShareable = getIsShareable(isDisabled);

  useEffect(() => {
    setShortenedLink((prevState) => {
      return {
        ...prevState,
        fullLink: "",
      };
    });
  }, [savedReply]);

  useEffect(() => {
    setShowLoader(true);
    setTimeout(() => {
      setShowLoader(false);
    }, 100);
  }, [savedReply]);

  const deleteTemplate = async () => {
    try {
      await deleteSavedReply(savedReply.id);
      const response = await fetchSavedReplyCollection(savedReplyCollectionId);
      const newSavedReplyCollection = await response.json();
      setSavedReplies(newSavedReplyCollection);
      setActiveSavedReply(
        templatesMaintenance ? newSavedReplyCollection.members[0] : undefined,
      );
      enqueueSnackbar(
        templatesMaintenance
          ? "Template deleted."
          : "You successfully deleted a template.",
        {
          variant: "info",
          includeCloseButton: true,
        },
      );
    } catch (error) {
      enqueueSnackbar(
        `There was an error deleting your template: ${String(error)}`,
        {
          variant: "error",
        },
      );
    }
  };

  const handleDeleteTemplate = () => {
    deleteTemplate(savedReply.id).catch((error) => {
      throw new Error(error);
    });
  };

  const handleDelete = () => {
    if (savedReplyUserId === currentUser.id) {
      handleDeleteTemplate();
    } else {
      setOpen(true);
    }
  };

  return (
    <FormWrapper templatesMaintenance={templatesMaintenance}>
      {showLoader ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          width="100%"
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          initialErrors={SavedReplyValidationSchema(
            maxCharacterLength,
          ).isValidSync(initialValues)}
          validationSchema={SavedReplyValidationSchema(maxCharacterLength)}
          onSubmit={handleSubmit}
        >
          {({
            errors = {},
            touched = {},
            isSubmitting,
            isValid,
            values,
            setFieldValue,
            submitForm,
          }) => {
            return (
              <Form>
                <Box
                  display="flex"
                  width="auto"
                  height="100%"
                  mb={templatesMaintenance ? 0 : 2}
                  sx={{
                    paddingX: templatesMaintenance ? "16px" : "0px",
                    paddingY: templatesMaintenance ? "16px" : "0px",
                  }}
                >
                  <Field type="text" name="title">
                    {({ field }) => {
                      return (
                        <TextField
                          disabled={isDisabled}
                          {...field}
                          autoFocus
                          variant="outlined"
                          error={touched.title && Boolean(errors.title)}
                          fullWidth
                          helperText={
                            (touched.title && errors.title) ||
                            `${values.title.length} of 100 characters`
                          }
                          inputProps={{ "data-lpignore": true }}
                          label="Title"
                          placeholder="Best Template!"
                          data-testid="create-a-template-title"
                          type="text"
                        />
                      );
                    }}
                  </Field>
                </Box>
                <MessageInputFormRow
                  currentAccount={currentAccount}
                  errors={errors}
                  fieldName="content"
                  isDisabled={isDisabled}
                  label="Content"
                  maxChars={maxCharacterLength}
                  setFieldValue={setFieldValue}
                  touched={touched}
                  values={values}
                  setShortenedLink={setShortenedLink}
                  shortenedLink={shortenedLink}
                  formPadding={templatesMaintenance ? "0px 16px" : "0"}
                  settings={settings}
                  templates
                />
                <Box
                  width="100%"
                  display="flex"
                  flexDirection="column"
                  padding="0"
                >
                  {isShareable ? (
                    <Box
                      color="text.secondary"
                      display="flex"
                      flex="0 0 auto"
                      mb={2}
                      padding={templatesMaintenance ? "0 16px" : "0"}
                    >
                      <Field name="shared">
                        {({ field, form }) => {
                          return (
                            <FormControlLabel
                              control={
                                <Switch
                                  disabled={isDisabled}
                                  {...field}
                                  checked={field.value}
                                  onChange={(event) => {
                                    const { checked } = event.target;
                                    form.setFieldValue(field.name, checked);
                                  }}
                                  color="secondary"
                                />
                              }
                              label="Share with account"
                            />
                          );
                        }}
                      </Field>
                    </Box>
                  ) : (
                    <Box height="65px" />
                  )}
                  {savedReply && templatesMaintenance && templateOwner ? (
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      padding={templatesMaintenance ? "0 16px" : "0 20px"}
                      paddingTop="0px"
                      paddingBottom="16px"
                      display="flex"
                      alignItems="flex-end"
                      height="auto"
                    >
                      {`Template created by ${typeof templateOwner === "string" && templateOwner}`}
                    </Typography>
                  ) : null}
                </Box>
                {templatesMaintenance && !settings ? (
                  <StickyFooter
                    alignItems="center"
                    display="flex"
                    justifyContent={hideList ? "flex-end" : "space-between"}
                    height="52px"
                    padding="0 16px"
                    boxShadow="none"
                    sx={(theme) => {
                      return {
                        position: settings ? "static" : "fixed",
                        width: getFooterWidth(),
                        borderTop: `1px solid ${theme.palette.divider}`,
                      };
                    }}
                  >
                    {hideList ? null : (
                      <Button
                        color="error"
                        onClick={() => {
                          handleDelete();
                        }}
                        disabled={
                          !isAdmin && savedReplyUserId !== currentUser.id
                        }
                      >
                        Delete
                      </Button>
                    )}
                    <Box>
                      {savedReplyUserId === currentUser.id && !settings ? (
                        <>
                          <Button
                            type="button"
                            color="primary"
                            aria-label="Save"
                            data-testid="save-button"
                            disabled={!isValid || isSubmitting}
                            onClick={() => {
                              setSubmitType("save");
                              submitForm().catch((error) => {
                                return console.error(error);
                              });
                            }}
                            style={settings ? {} : { marginRight: "10px" }}
                            variant={settings ? "contained" : undefined}
                          >
                            {savedReply ? "Save" : "Create"}
                          </Button>
                          {!settings && (
                            <Button
                              variant="contained"
                              color="primary"
                              aria-label="Save and use"
                              data-testid="save-and-use-button"
                              type="button"
                              disabled={!isValid || isSubmitting}
                              onClick={() => {
                                setSubmitType("saveAndUse");
                                submitForm().catch((error) => {
                                  return console.error(error);
                                });
                              }}
                            >
                              {savedReply ? "Save & Use" : "Create & Use"}
                            </Button>
                          )}
                        </>
                      ) : null}
                      {savedReply &&
                      savedReplyUserId !== currentUser.id &&
                      !settings ? (
                        <Button
                          type="button"
                          color="primary"
                          aria-label="Use"
                          data-testid="use-button"
                          disabled={!isValid || isSubmitting}
                          onClick={() => {
                            handleSelect(savedReply);
                            handleCloseModal();
                          }}
                          style={settings ? {} : { marginRight: "10px" }}
                          variant="contained"
                        >
                          Use
                        </Button>
                      ) : null}
                      {!savedReply && (
                        <>
                          <Button
                            type="button"
                            color="primary"
                            aria-label="Save"
                            data-testid="save-button"
                            disabled={!isValid || isSubmitting}
                            onClick={() => {
                              setSubmitType("save");
                              submitForm().catch((error) => {
                                return console.error(error);
                              });
                            }}
                            style={settings ? {} : { marginRight: "10px" }}
                            variant={settings ? "contained" : undefined}
                          >
                            Create
                          </Button>
                          {!settings && (
                            <Button
                              variant="contained"
                              color="primary"
                              aria-label="Create and use"
                              data-testid="create-and-use-button"
                              type="button"
                              disabled={!isValid || isSubmitting}
                              onClick={() => {
                                setSubmitType("saveAndUse");
                                submitForm().catch((error) => {
                                  return console.error(error);
                                });
                              }}
                            >
                              Create & Use
                            </Button>
                          )}
                        </>
                      )}
                    </Box>
                  </StickyFooter>
                ) : null}
                {!templatesMaintenance && (
                  <Box
                    alignItems="center"
                    display="flex"
                    flex="0 0 auto"
                    justifyContent="flex-end"
                  >
                    <Box>
                      <Button
                        type="button"
                        color="primary"
                        aria-label="Save"
                        data-testid="save-button"
                        disabled={!isValid || isSubmitting}
                        onClick={() => {
                          setSubmitType("save");
                          submitForm().catch((error) => {
                            return console.error(error);
                          });
                        }}
                        style={settings ? {} : { marginRight: "10px" }}
                        variant={settings ? "contained" : undefined}
                      >
                        {savedReply ? "Save" : "Create"}
                      </Button>
                      {!settings && (
                        <Button
                          variant="contained"
                          color="primary"
                          type="button"
                          data-testid="save-and-use-button"
                          disabled={!isValid || isSubmitting}
                          onClick={() => {
                            setSubmitType("saveAndUse");
                            submitForm().catch((error) => {
                              return console.error(error);
                            });
                          }}
                        >
                          {savedReply ? "Save & Use" : "Create & Use"}
                        </Button>
                      )}
                    </Box>
                  </Box>
                )}
                {settings && templatesMaintenance ? (
                  <Box
                    minHeight="100vh"
                    width="100%"
                    display="flex"
                    alignItems="flex-end"
                  >
                    <StickyFooter
                      alignItems="center"
                      display="flex"
                      justifyContent={hideList ? "flex-end" : "space-between"}
                      height="52px"
                      padding="0 16px"
                      boxShadow="none"
                      sx={(theme) => {
                        return {
                          width: getFooterWidth(),
                          borderTop: `1px solid ${theme.palette.divider}`,
                        };
                      }}
                    >
                      {hideList ? null : (
                        <Button
                          color="error"
                          onClick={() => {
                            handleDelete();
                          }}
                          disabled={
                            (!isAdmin && savedReplyUserId !== currentUser.id) ||
                            !savedReply
                          }
                        >
                          Delete
                        </Button>
                      )}
                      <Box>
                        <Button
                          type="button"
                          color="primary"
                          aria-label="Save"
                          data-testid="save-button"
                          disabled={!isValid || isSubmitting}
                          onClick={() => {
                            setSubmitType("save");
                            submitForm().catch((error) => {
                              return console.error(error);
                            });
                          }}
                          style={settings ? {} : { marginRight: "10px" }}
                          variant={settings ? "contained" : undefined}
                        >
                          {savedReply ? "Save" : "Create"}
                        </Button>
                      </Box>
                    </StickyFooter>
                  </Box>
                ) : null}
                <SavedReplyDeleteDialog
                  open={open}
                  setOpen={setOpen}
                  templateOwner={templateOwner}
                  templateTitle={savedReply?.title}
                  handleDeleteTemplate={handleDeleteTemplate}
                />
              </Form>
            );
          }}
        </Formik>
      )}
    </FormWrapper>
  );
}

export default SavedReplyForm;

SavedReplyForm.propTypes = {
  currentAccount: PropTypes.object.isRequired, // withConnect
  currentUser: PropTypes.object.isRequired, // withConnect
  fetchSavedReplyCollectionRequest: PropTypes.func.isRequired, // withRecord
  handleCloseModal: PropTypes.func, // passed
  handleSelect: PropTypes.func, // passed
  handleSavedReplySelected: PropTypes.func.isRequired, // passed
  hideList: PropTypes.bool, // passed
  isLoading: PropTypes.bool, // passed
  savedReplies: PropTypes.object, // passed
  savedReply: PropTypes.object, // passed
  savedReplyCollectionId: PropTypes.string.isRequired, // passed
  setActiveSavedReply: PropTypes.func.isRequired, // passed
  settings: PropTypes.bool, // passed
  settingsAccount: PropTypes.object, // passed
  setSavedReplies: PropTypes.func, // passed
  templatesMaintenance: PropTypes.bool, // passed
};
