import { Component } from "react";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import styled from "styled-components";
import Logo, { Root as LogoStyles } from "components/Logo";

const labeling = get(window, ["branding", "labeling"], {});

const Root = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${labeling?.primary_color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  padding: 0 16px;

  ${LogoStyles} {
    opacity: 0;
    animation: fadeAndScaleIn cubic-bezier(0.175, 0.885, 0.32, 1.275) 2s 0.5s
      forwards;
  }

  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.45));
    z-index: -1;
    animation: fadeIn cubic-bezier(0.175, 0.885, 0.32, 1.275) 3s;
  }

  ${(props) => {
    return props.theme.mixins.fadeInAnimation;
  }};
  ${(props) => {
    return props.theme.mixins.fadeAndSlideUpInAnimation;
  }};
  ${(props) => {
    return props.theme.mixins.fadeAndScaleInAnimation;
  }};
`;

const Modal = styled.div`
  align-items: center;
  animation: fadeIn ease-in-out 1s 0.999s forwards;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  background: 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: ${(props) => {
    return props.theme.defaults.modalBorderRadius;
  }};
  display: flex;
  flex-flow: column nowrap;
  height: auto;
  margin-top: 60px;
  opacity: 0;
  overflow: hidden;
  padding: 25px 33px 33px;
  position: relative;
  text-align: center;
  width: 100%;
  max-width: 500px;

  span[role="img"] {
    font-size: 45px;
  }

  button {
    span[role="img"] {
      font-size: 18px;
    }
  }

  form {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 100%;
    width: 100%;
  }
`;

export default class Page extends Component {
  static propTypes = {
    children: PropTypes.any,
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{labeling.company_name}</title>
          <link
            rel="icon"
            type="image/png"
            href={labeling.favicon || "/favicon.ico"}
            sizes="16x16"
          />
        </Helmet>
        <Root>
          {labeling.login_icon ? (
            <img
              src={labeling.login_icon}
              width="220px"
              alt={labeling.company_name}
            />
          ) : (
            <Logo showName width={220} />
          )}
          <Modal>{this.props.children}</Modal>
        </Root>
      </>
    );
  }
}
