import BaseNativeSelect from "@mui/material/NativeSelect";
import { InputLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ReactElement, useCallback, useMemo } from "react";
import type { Option, Props, SupportedValueTypes } from "./types";
import useId from "utils/uuid";
import { useField } from "components/hooks/useField";

const Label = styled(InputLabel)(({ theme }) => {
  return {
    color: theme.palette.text.primary,
    letterSpacing: "0.15px",
    lineHeight: "12px",
    fontSize: "12px",
  };
});

/**
 * <NativeSelect /> element
 *
 * @param {Props}
 * @returns {ReactElement} Native select element
 * @example
 * ```jsx
 * <NativeSelect label="Select option" name="name" options={[{ value: 1, label: "Option 1" }]} />
 * ```
 */
export function NativeSelect<
  Options extends Array<Option<ValueOfOptions>>,
  ValueOfOptions extends SupportedValueTypes,
>({
  name,
  label,
  options,
  defaultValue,
  onChange,
  ...props
}: Props<Options, ValueOfOptions>): ReactElement {
  const id = useId();

  const [field, setValue] = useField<ValueOfOptions>(name, { defaultValue });

  const optionsMap = useMemo(() => {
    return Object.fromEntries(
      options.map((option) => {
        return [`${option.value ?? ""}`, option];
      }),
    );
  }, [options]);

  const onChangeHandler = useCallback(
    (event) => {
      const { value } = optionsMap[event.target.value];
      if (onChange) {
        onChange(value, field.value);
      }
      setValue(value);
    },
    [field.value, onChange, optionsMap, setValue],
  );

  return (
    <>
      <Label htmlFor={id}>{label}</Label>
      <BaseNativeSelect
        {...field}
        {...props}
        fullWidth
        inputProps={{ id }}
        onChange={onChangeHandler}
      >
        {options.map(({ label: optionLabel, value }) => {
          return (
            <option value={`${value ?? ""}`} key={`${value ?? ""}`}>
              {optionLabel}
            </option>
          );
        })}
      </BaseNativeSelect>
    </>
  );
}
