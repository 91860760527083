import { useDeleteStepService } from "./useDeleteStepService";
import { useDialog } from "components/Dialog";
import { Account as AccountType } from "models/Account";
import { SequenceStep } from "models/SequenceStep";

export const useDeleteStepDialog = (currentAccount: AccountType) => {
  const { ref: deleteStepDialogRef, open: openDialog } = useDialog<
    any,
    boolean
  >();

  const { handleDeleteStep } = useDeleteStepService(currentAccount);

  const openDeleteStepDialog = (
    sequenceStep: SequenceStep,
    refreshPage: () => void,
    startLoadingSteps: () => void,
    stopLoadingSteps: () => void,
  ): void => {
    openDialog({}, async (doDelete: boolean | undefined) => {
      if (doDelete) {
        await handleDeleteStep(
          sequenceStep,
          startLoadingSteps,
          stopLoadingSteps,
        );
        refreshPage();
      }
    })();
  };

  return {
    deleteStepDialogRef,

    openDeleteStepDialog,
  };
};
