import { Box, Tooltip } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// eslint-disable-next-line react/display-name, func-names
export default function (description, title) {
  return (
    <Tooltip
      title={
        <Box
          aria-label={`Description for ${title}`}
          color="text.secondary"
          fontSize={12}
          p={2}
          maxWidth="320px"
        >
          {description}
        </Box>
      }
    >
      <Box
        aria-label={`Tooltip for ${title}`}
        display="flex"
        height="100%"
        px={1}
        py={2}
        style={{ zIndex: 1 }}
      >
        <InfoOutlinedIcon
          color="inherit"
          style={{
            cursor: "help",
          }}
        />
      </Box>
    </Tooltip>
  );
}
