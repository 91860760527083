import Accordion from "@mui/material/Accordion";
import { styled } from "@mui/system";

export default styled(Accordion)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderTop: `1px solid ${theme.palette.divider}`,
    boxShadow: "none",
    "&:first-child": {
      borderTop: 0,
    },
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&.Mui-expanded": {
      marginTop: 0,
      marginBottom: 0,
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
      padding: "8px"
    }
  }
});

export const Row = styled("div")({
  marginBottom: "24px",
  width: "100%",
});

export const Section = styled("div")({
  padding: "0 20px",
  display: "grid",
  width: "100%",
});
