import { useEffect } from "react";
import qs from "query-string";

import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";

import { AlertBannerEst } from "../components/AlertBannerEst";
import AnalyticsDetailReport from "../components/AnalyticsDetailReport";
import BillingFilter from "../components/BillingFilter";

import { useAnalytics } from "../hooks/useAnalytics";
import { useFilters } from "../hooks/useFilters";
import { BillingAnalyticsProps } from "../models/AnalyticsModels";

import { getFirstDayOfMonth } from "../utils/GetFirstDayOfMonth";
import withRecord from "higherOrderComponents/withRecord";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";

function BillingAnalytics({
  currentUser,
  currentAccount,
  isSinglePanel,
  reports,
  reportType,
  scheduledReports,
  setScheduledReports,
  showSidebar,
  toggleSidebar,
}: BillingAnalyticsProps) {
  const history = useHistory();
  const { search } = useLocation();
  const { getQueryString, reportDates, setReportDates } = useFilters({
    currentUser,
  });
  const { getBillingReportIds, getAggregatePlanUrl } = useAnalytics();

  const { slug } = currentAccount;
  const { startDate, endDate } = qs.parse(search);
  const { reportDescendants } = reportDates;

  const billingReportsIds = getBillingReportIds({
    endDate,
    reports,
    slug,
    startDate,
  });

  // ex: `textus/statistics/aggregate_plan?endDate=2023-01-26&startDate=2023-01-01
  const aggregatePlanUrl = getAggregatePlanUrl({
    endDate,
    startDate,
    slug,
  });

  useEffect(() => {
    if (typeof startDate === "string" && typeof endDate === "string") {
      setReportDates({
        reportEndDate: endDate,
        reportStartDate: startDate,
        reportDescendants,
      });
    }
  }, [endDate, startDate, setReportDates, reportDescendants]);

  if (!startDate || !endDate) {
    const startDateParams = getFirstDayOfMonth();
    const endDateParams = new Date();
    history.replace({
      pathname: `/${slug}/analytics/billing`,
      search: getQueryString({
        reportStartDate: startDateParams.toISOString(),
        reportEndDate: endDateParams.toISOString(),
        reportDescendants,
      }),
    });
  }

  const loading = billingReportsIds.length > 0 && !startDate && !endDate;

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <Box display="flex" flexDirection="column" height="100%">
        <PageHeader
          aria-label="Billing Analytics"
          title="Billing"
          toggleSidebar={toggleSidebar}
        />
        <Box
          aria-label="Account Analytics"
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          m="0 auto"
          maxWidth="1280px"
          minHeight="0px"
          overflow="auto"
          padding={4}
          width="100%"
        >
          <AlertBannerEst />
          <BillingFilter label="Filter by Month" />
          {!loading &&
            billingReportsIds.map(({ id: analyticReportId, reportName }) => {
              return (
                <AnalyticsDetailReport
                  billingReport
                  analyticsReportId={analyticReportId}
                  aggregatePlanUrl={aggregatePlanUrl}
                  currentAccount={currentAccount}
                  currentUser={currentUser}
                  key={analyticReportId}
                  reportName={reportName}
                  reportType={reportType}
                  scheduledReports={scheduledReports}
                  setScheduledReports={setScheduledReports}
                />
              );
            })}
        </Box>
      </Box>
    </PageContent>
  );
}

const mapStateToProps = (): Record<string, unknown> => {
  return {};
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withRecord({
    actions: ["create"],
    container: "features/AccountAnalytics/Billing/export",
    type: "analyticExport",
  }),
  withConnect,
)(BillingAnalytics);
