import { useEffect, useState } from "react";

/**
 * Verifies connection with `window.opener`
 */
export function useOpener(): { opener: Window; error: Error | undefined } {
  const [error, setError] = useState<Error | undefined>();

  /**
   * Verify the connection with the window that opened the popup
   */
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    const ping = () => {
      return setTimeout(() => {
        timer = ping();

        if (!window.opener) {
          setError(new Error("Connection lost"));
        }
      }, 1000);
    };
    timer = ping();

    return () => {
      return clearTimeout(timer);
    };
  }, []);

  return {
    opener: window.opener,
    error,
  };
}
