import { subtractMonths } from "./SubtractMonths";

export const adjustDate = (monthsToSubtract?: number) => {
  const adjustedDate = monthsToSubtract
    ? subtractMonths(monthsToSubtract)
    : new Date();
  const adjustedYear = adjustedDate.getFullYear();
  const adjustedMonth = adjustedDate.getMonth();
  return { adjustedYear, adjustedMonth };
};
