import styled from "styled-components";
import { textUsColors } from "@tesseract/theme/src/palettes/colors";

export default styled.div`
  background: ${(props) => {
    return props.theme.colors.mode === "dark"
      ? props.theme.colors.background.default
      : textUsColors.grayscale[50];
  }};
  border-right: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex-flow: column;
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: width ease-in-out 0.3s;
  width: ${(props) => {
    return props.width || props.theme.layout.sidebarWidth;
  }};
  z-index: 2;
`;
