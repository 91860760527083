import assert from "assert";
import { userManager } from "@tesseract/core";
import { headers } from "./constants";

export const deleteAccount = async (id: string): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const request = new Request(`${id}`, {
    method: "DELETE",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return fetch(request);
};
