import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useMemo,
} from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { Blueprint, Context } from "./types";
import { SequenceValues } from "features/Sequences/hooks/useSequences/types";

export const BlueprintsContext = createContext<Context>({});

interface BlueprintsProviderProps {
  areBlueprintsLoading: boolean;
  blueprints: Blueprint[];
  children: ReactNode;
  close: (data: false | SequenceValues) => () => void;
  getBlueprints: () => Promise<void>;
  handleBlueprintsBack: () => void;
  handleChooseBlueprint: (blueprint: Blueprint) => void;
  setSelectedCard: Dispatch<SetStateAction<Blueprint | undefined>>;
  selectedCard?: Blueprint;
}

export function BlueprintsProvider({
  areBlueprintsLoading,
  blueprints,
  children,
  close,
  getBlueprints,
  handleBlueprintsBack,
  handleChooseBlueprint,
  setSelectedCard,
  selectedCard,
}: BlueprintsProviderProps) {
  const isSmallScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("md");
  });

  const memoizedBlueprintsContext = useMemo(() => {
    return {
      areBlueprintsLoading,
      blueprints,
      isSmallScreen,
      selectedCard,
      close,
      handleBlueprintsBack,
      handleChooseBlueprint,
      getBlueprints,
      setSelectedCard,
    };
  }, [
    areBlueprintsLoading,
    blueprints,
    isSmallScreen,
    selectedCard,
    close,
    handleBlueprintsBack,
    handleChooseBlueprint,
    setSelectedCard,
    getBlueprints,
  ]);

  return (
    <BlueprintsContext.Provider value={memoizedBlueprintsContext}>
      {children}
    </BlueprintsContext.Provider>
  );
}
