import { useState } from "react";
import PropTypes from "prop-types";

import Button from "@mui/material/Button";
import { useSnackbar } from "notistack";
import Loader from "components/Loader";

function OptOutImport(props) {
  const [isLoading, setIsLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const successCallback = () => {
    setIsLoading(false);
    enqueueSnackbar(
      "We're exporting your opt outs. You should receive an email shortly!",
      { variant: "info" },
    );
  };

  const errorCallback = () => {
    setIsLoading(false);
    enqueueSnackbar("Something went wrong. Please try again!", {
      variant: "error",
    });
  };

  const handleClick = () => {
    const { currentAccount, exportOptOutsRequest } = props;
    setIsLoading(true);
    exportOptOutsRequest(
      currentAccount.exportOptOuts,
      {},
      {
        successCallback,
        errorCallback,
      },
    );
  };

  return (
    <Button
      data-test="ExportOptOutsButton"
      color="primary"
      onClick={handleClick}
    >
      <Loader isLoading={isLoading}>Export Opt Outs</Loader>
    </Button>
  );
}

OptOutImport.propTypes = {
  exportOptOutsRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
};

export default OptOutImport;
