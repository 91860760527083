import LinkStripping from "../components/LinkStripping";
import MmsSettings from "../components/MmsSettings";
import { SettingsSection } from "models/SettingsSection";

export const getPrivacySettings = ({
  isAdmin,
}: {
  isAdmin: boolean;
}): SettingsSection | null => {
  return isAdmin
    ? {
        title: "Privacy Settings",
        path: "privacy",
        settings: [
          {
            title: "MMS Settings",
            to: `/mms-settings`,
            OptionComponent: MmsSettings,
          },
          {
            title: "Link Removal",
            to: `/link-removal`,
            OptionComponent: LinkStripping,
          },
        ],
      }
    : null;
};
