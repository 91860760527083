import createUUID from "./uuid";

/**
 * Create object with unique keys and undefined values
 *
 * @param length - number of records in object
 * @returns blank object
 */
export const createBlankRecords = (
  length: number,
): Record<string, undefined> => {
  return Object.fromEntries(
    Array.from({ length }).map(() => {
      return [createUUID(), undefined];
    }),
  );
};
