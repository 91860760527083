import { User } from "@tesseract/core";
import { useCallback, useState } from "react";
import { fetchUser } from "../api/fetchUser";

/**
 * States
 */
const states = Object.freeze({
  waiting: "waiting",
  loading: "loading",
  error: "error",
  done: "done",
});

/**
 * Hook to fetch users.
 */
export const useUser = () => {
  /**
   * Users
   */
  const [user, setUser] = useState<User.Raw | undefined>(undefined);

  /**
   * Current state
   */
  const [state, setState] = useState<(typeof states)[keyof typeof states]>(
    states.waiting,
  );

  /**
   * Error
   */
  const [error, setError] = useState<Error | undefined>(undefined);

  /**
   * Load user
   */
  const load = useCallback((userId: User.Like) => {
    let ignore = false;

    setState(states.loading);

    setError(undefined);

    const controller = new AbortController();

    fetchUser(userId, {
      signal: controller.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(
        (
          responseData:
            | { id: string; members: [User.Raw]; totalItems: 1 }
            | User.Raw,
        ) => {
          if (!ignore) {
            setUser(
              "members" in responseData
                ? responseData.members[0]
                : responseData,
            );
            setState(states.done);
          }

          return responseData;
        },
      )

      .catch((error_) => {
        setState(states.error);
        setError(error_);
      });

    return () => {
      controller.abort();

      ignore = true;
    };
  }, []);

  /**
   * Reset hook
   */
  const reset = useCallback(() => {
    setUser(undefined);
    setState(states.waiting);
    setError(undefined);
  }, []);

  return {
    error,
    state,
    load,
    user,
    reset,
  };
};
