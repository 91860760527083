import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import LocalOfferIcon from "@mui/icons-material/LocalOffer";

function TagCreationInfo() {
  return (
    <Box display="flex" maxWidth="300px">
      <List>
        <ListItem alignItems="flex-start">
          <ListItemIcon>
            <LocalOfferIcon />
          </ListItemIcon>
          <ListItemText primary="Select tags from the dropdown menu or create new tags" />
        </ListItem>
      </List>
    </Box>
  );
}

export default TagCreationInfo;
