import { ChangeEvent } from "react";
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import { useFormikContext } from "formik";
import type { SequenceFormValues } from "../../types";
import { DELIVERY_WINDOW_OPTIONS } from "../../constants";
import { TimeInMinutesPicker } from "../TimeInMinutesPicker";
import { DeliveryWindowSectionProps } from "./types";
import { DropdownDayPicker } from "components/DropdownDayPicker";

const SelectContainer = styled(Box)({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

const SpanBody2 = styled(Typography)({
  component: "span",
  margin: "0rem 0.25rem 0rem 0.25rem",
  variant: "body2",
});

function DeliveryWindowSection({
  radioSelection,
  handleChangeRadio,
}: DeliveryWindowSectionProps) {
  const { anytime, restricted } = DELIVERY_WINDOW_OPTIONS;

  const { values, setFieldValue } = useFormikContext<SequenceFormValues>();

  const { startDay, startTime, endDay, endTime } =
    values.settings?.deliveryWindow ?? {};

  const handleChangeSettings = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setFieldValue("settings", {
      ...values.settings,
      deliveryWindow: {
        ...values.settings.deliveryWindow,
        [event.target.name]: event.target.value,
      },
    });
  };

  return (
    <>
      <RadioGroup value={radioSelection} onChange={handleChangeRadio}>
        <FormControlLabel
          value={anytime}
          control={<Radio color="secondary" size="medium" />}
          label="Send messages on any day and time"
        />
        <FormControlLabel
          value={restricted}
          control={<Radio color="secondary" size="medium" />}
          label="Send messages only during these days and times"
        />
      </RadioGroup>
      {radioSelection === restricted ? (
        <Box display="flex" flexDirection="column" gap={2} marginTop="1rem">
          <SelectContainer>
            <DropdownDayPicker
              handleChange={handleChangeSettings}
              label="Start day"
              value={startDay}
            />
            <SpanBody2>to</SpanBody2>
            <DropdownDayPicker
              handleChange={handleChangeSettings}
              label="End day"
              value={endDay}
            />
          </SelectContainer>
          <SelectContainer>
            <TimeInMinutesPicker
              label="Start time"
              value={startTime}
              handleChange={handleChangeSettings}
            />
            <SpanBody2>to</SpanBody2>
            <TimeInMinutesPicker
              label="End time"
              value={endTime}
              handleChange={handleChangeSettings}
            />
          </SelectContainer>
        </Box>
      ) : null}
    </>
  );
}

export { DeliveryWindowSection };
