import get from "lodash/get";

export default (user) => {
  const totalMemberships = get(user, ["memberships", "totalItems"]);
  if (totalMemberships > 1) return true;

  const membershipsChildrenCount = get(user, [
    "memberships",
    "members",
    0,
    "account",
    "childrenCount",
  ]);
  if (membershipsChildrenCount > 0) return true;

  return false;
};
