/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Box, Divider, Skeleton } from "@mui/material";
import { useSnackbar } from "notistack";
import { Contact } from "@tesseract/core";
import { GroupPageContentProps } from "./types";
import {
  getColumns,
  getCollapsedColumns,
} from "features/Groups/constants/contactColumns";
import BatchActionToolbar from "components/BatchActionToolbar";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import Table from "components/Table";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import {
  deleteContactCollection,
  fetchContactCollection,
} from "features/Contacts/api";
import { renderSkeletonRows } from "utils/renderSkeletonRows";

function GroupPageContent({
  contactCollectionId,
  setIsSendDisabled,
  selected,
  selectedRecords,
  setSelected,
  setSelectedRecords,
}: GroupPageContentProps) {
  const [deleteConfirmationVisibility, setDeleteConfirmationVisibility] =
    useState(false);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const toggleDeleteConfirmationVisibility = () => {
    setDeleteConfirmationVisibility(!deleteConfirmationVisibility);
  };

  const handleSelected = (values: Contact.ID[]) => {
    setSelected(values);
  };

  const pluralizedContact = selected.length === 1 ? "contact" : "contacts";

  const getGroupContacts = async () => {
    const response = await fetchContactCollection(contactCollectionId);
    return response.json();
  };

  const { data: contactCollection, isLoading } = useQuery({
    queryKey: [contactCollectionId],
    queryFn: getGroupContacts,
    throwOnError: (error) => {
      enqueueSnackbar(
        "Something went wrong retrieving contacts. Please try again.",
        {
          variant: "error",
        },
      );
      console.error(error);
      return false;
    },
  });

  const deleteGroupContacts = () => {
    return deleteContactCollection(contactCollection?.id, selected);
  };

  const deleteContacts = useMutation({
    mutationFn: deleteGroupContacts,
    onSuccess: () => {
      queryClient
        .invalidateQueries({
          queryKey: [contactCollectionId],
        })
        .catch((error) => {
          console.error(error);
        });

      enqueueSnackbar(
        `Successfully removed ${selected.length} ${pluralizedContact} from the group.`,
        {
          variant: "info",
        },
      );

      setSelected([]);
      setSelectedRecords([]);
    },
    onError: (error) => {
      enqueueSnackbar(
        "Something went wrong removing contacts. Please try again.",
        {
          variant: "error",
        },
      );
      console.error(error);
    },
  });

  const handleDelete = () => {
    deleteContacts.mutate();
    toggleDeleteConfirmationVisibility();
  };

  const batchActions = [
    {
      title: "Remove Contacts",
      icon: (
        <DeleteIcon
          aria-label="Remove Group Contacts"
          data-testid="remove-group-contacts"
        />
      ),
      clickHandler: toggleDeleteConfirmationVisibility,
      showExpanded: true,
    },
  ];

  const { totalItems = 0 } = contactCollection ?? {};

  useEffect(() => {
    selected.length === 0 ||
    selected.length === totalItems
      ? setIsSendDisabled(false)
      : setIsSendDisabled(true);
  }, [selected, contactCollection]);

  return (
    <Box
      aria-label="Group Page Content"
      display="flex"
      flexDirection="column"
      flexGrow={1}
      flexShrink={1}
      overflow="auto"
      position="relative"
    >
      <ConfirmationModal
        cancellationHandler={toggleDeleteConfirmationVisibility}
        confirmationHandler={handleDelete}
        isOpen={deleteConfirmationVisibility}
        message={`You are about to remove ${selected.length} ${pluralizedContact} from this group.`}
        title={`Remove ${selected.length} ${pluralizedContact} from group?`}
      />
      {selected.length > 0 && (
        <BatchActionToolbar
          // @ts-expect-error - Type error on deprecated component, fixing would require a refactor
          actions={batchActions}
          position={{ top: "0px", left: "64px" }}
          selectedCount={selected.length}
          setSelectedRecords={setSelectedRecords}
          selectAllCount={totalItems}
        />
      )}
      {isLoading || !contactCollection ? (
        <Box display="flex" flexDirection="column" width="100%">
          <Box padding="0.5rem">
            <Skeleton height="3.5rem" />
          </Box>
          <Divider />
          <Box padding="0.5rem">{renderSkeletonRows(30, "3rem")}</Box>
        </Box>
      ) : (
        <Table
          blankState={
            <BlankState
              image={<Logo color="disabled" />}
              title="This group is empty"
              subTitle="Click on the button below to add some contacts"
            />
          }
          collection={contactCollection}
          collapsedColumns={getCollapsedColumns()}
          columns={getColumns()}
          notExpandable
          selected={selected}
          selectedRecords={selectedRecords}
          setSelected={handleSelected}
          setSelectedRecords={setSelectedRecords}
          withBatchActions
        />
      )}
    </Box>
  );
}

export default GroupPageContent;
