import get from "lodash/get";
import styled, { css } from "styled-components";

const labeling = get(window, ["branding", "labeling"], {});

export const styles = css`
  color: ${labeling?.secondary_color};
  font-size: ${(props) => {
    return props.theme.fonts.tertiaryHeaderFontSize;
  }};
  font-weight: 700;
  margin: 0;
`;

const H5 = styled.h5`
  ${styles};
`;

export default H5;
