import {
  lastDayOfMonth,
  lastDayOfYear,
  lastDayOfQuarter,
  startOfMonth,
  startOfQuarter,
  startOfYear,
  sub,
  subQuarters,
} from "date-fns";

export const dateOptions = [
  {
    name: "Previous 7 days",
    value: "week",
    startDate: sub(new Date(), { weeks: 1 }),
    endDate: new Date(),
  },
  {
    name: "Previous 30 days",
    value: "thirty days",
    startDate: sub(new Date(), { days: 30 }),
    endDate: new Date(),
  },
  {
    name: "Current Month to Date",
    value: "current month to date",
    startDate: startOfMonth(new Date()),
    endDate: new Date(),
  },
  {
    name: "Previous Full Month",
    value: "previous full month",
    startDate: sub(startOfMonth(new Date()), { months: 1 }),
    endDate: lastDayOfMonth(sub(new Date(), { months: 1 })),
  },
  {
    name: "Current Quarter to Date",
    value: "current quarter to date",
    startDate: startOfQuarter(new Date()),
    endDate: new Date(),
  },
  {
    name: "Previous Full Quarter",
    value: "previous full quarter",
    startDate: startOfQuarter(subQuarters(new Date(), 1)),
    endDate: lastDayOfQuarter(subQuarters(new Date(), 1)),
  },
  {
    name: "Current Year to Date",
    value: "current year to date",
    startDate: startOfYear(new Date()),
    endDate: new Date(),
  },
  {
    name: "Previous Full Year",
    value: "previous full year",
    startDate: sub(startOfYear(new Date()), { years: 1 }),
    endDate: sub(lastDayOfYear(new Date()), { years: 1 }),
  },
];

export const timePeriods = [
  { name: "Daily", slug: "daily" },
  {
    name: "Previous 7 Days",
    slug: "previous_week",
  },
  {
    name: "Previous 30 Days",
    slug: "previous_30_days",
  },
  {
    name: "Month to Date",
    slug: "month_to_date",
  },
  {
    name: "Previous Full Month",
    slug: "previous_month",
  },
  {
    name: "Quarter to Date",
    slug: "quarter_to_date",
  },
  {
    name: "Previous Full Quarter",
    slug: "previous_quarter",
  },
  {
    name: "Year to Date",
    slug: "year_to_date",
  },
  {
    name: "Previous Full Year",
    slug: "previous_year",
  },
];

export const sendFrequencyOptions = {
  daily: [{ name: "Daily", slug: "FREQ=DAILY;" }],
  previous_week: [
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
  ],
  previous_30_days: [
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  month_to_date: [
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  previous_month: [{ name: "Monthly", slug: "FREQ=MONTHLY;" }],
  quarter_to_date: [
    { name: "Daily", slug: "FREQ=DAILY;" },
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  previous_quarter: [
    { name: "Daily", slug: "FREQ=DAILY;" },
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  year_to_date: [
    { name: "Daily", slug: "FREQ=DAILY;" },
    { name: "Weekly", slug: "FREQ=WEEKLY;" },
    {
      name: "Biweekly",
      slug: "FREQ=WEEKLY;INTERVAL=2;",
    },
    { name: "Monthly", slug: "FREQ=MONTHLY;" },
  ],
  previous_year: [{ name: "Yearly", slug: "FREQ=YEARLY;" }],
};

export const dayToSendOptions = {
  daily: [{ name: "Daily (M-F)", slug: "BYDAY=MO,TU,WE,TH,FR" }],
  weekly: [
    { name: "Monday", slug: "BYDAY=MO" },
    { name: "Tuesday", slug: "BYDAY=TU" },
    { name: "Wednesday", slug: "BYDAY=WE" },
    { name: "Thursday", slug: "BYDAY=TH" },
    { name: "Friday", slug: "BYDAY=FR" },
  ],
  biweekly: [
    { name: "Monday", slug: "BYDAY=MO" },
    { name: "Tuesday", slug: "BYDAY=TU" },
    { name: "Wednesday", slug: "BYDAY=WE" },
    { name: "Thursday", slug: "BYDAY=TH" },
    { name: "Friday", slug: "BYDAY=FR" },
  ],
  monthly: [
    { name: "1st of the Month", slug: "BYMONTHDAY=1" },
    { name: "Last of the Month", slug: "BYMONTHDAY=-1" },
  ],
  yearly: [
    { name: "1st of the Year", slug: "BYYEARDAY=1" },
    { name: "Last of the Year", slug: "BYYEARDAY=-1" },
  ],
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const tzDateRegex =
  /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|-)(?:\d{2}):?(?:\d{2}))/;
