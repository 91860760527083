import { useEffect, useMemo, useState } from "react";
import snakeCase from "lodash/snakeCase";

import PropTypes from "prop-types";
import { Formik, Form } from "formik";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {
  dayToSendOptions,
  sendFrequencyOptions,
  timePeriodOptions,
} from "../constants/analytics";
import FormikSelect from "components/FormikSelect";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

import createUUID from "utils/uuid";
import { AnalyticsReportsSchema } from "formHelpers/validationSchemas";

function ScheduleAnalyticsReportModal({
  accountList = [],
  descendantsList,
  updateReport,
  fullScreen,
  handleClose,
  initialValues,
  open,
  reports,
  scheduleReport,
}) {
  const [sendFrequency, setSendFrequency] = useState([]);
  const [dayToSend, setDayToSend] = useState([]);
  const [reportNames, setReportNames] = useState([]);
  const [timePeriods, setTimePeriods] = useState([]);
  const [descendantsOptions, setDescendantsOptions] = useState([]);
  const isEditForm = Object.values(initialValues).find(Boolean);

  const reportTypes = reports.map(({ title, slug }) => {
    return {
      name: title,
      slug,
    };
  });

  const billingReports = useMemo(() => {
    return reports
      .filter(({ title }) => {
        return title === "Billing";
      })
      .map((report) => {
        return report.reports;
      })
      .map((billingReport) => {
        return billingReport.members;
      })
      .flat()
      .map((member) => {
        return { ...member, reportType: "billing" };
      });
  }, [reports]);

  const overviewReports = useMemo(() => {
    return reports
      .filter(({ title }) => {
        return title === "Overview";
      })
      .map((report) => {
        return report.reports;
      })
      .map((overviewReport) => {
        return overviewReport.members;
      })
      .flat()
      .map((member) => {
        return { ...member, reportType: "overview" };
      });
  }, [reports]);

  const getReportNames = (reportList) => {
    return reportList.flat().map(({ title, slug }) => {
      return {
        name: title,
        slug,
      };
    });
  };

  const reportNameOptions = {
    billing: getReportNames(billingReports),
    overview: getReportNames(overviewReports),
  };

  const updateDependentFields = (parentField, value) => {
    if (parentField === "reportType") {
      const fieldOptionsReportNames = reportNameOptions[value];
      const fieldOptionsTimePeriods = timePeriodOptions[value];
      const fieldOptionsDescendants = descendantsList[value];
      setReportNames(fieldOptionsReportNames);
      setTimePeriods(fieldOptionsTimePeriods);
      setDescendantsOptions(fieldOptionsDescendants);
    } else if (parentField === "timePeriod") {
      const fieldOptions = sendFrequencyOptions[value];
      setSendFrequency(fieldOptions);
      setDayToSend([]);
    } else if (parentField === "sendFrequency") {
      const dayToSendKey = snakeCase(value.match(/FREQ=(\w+);/)[1]);
      const fieldOptions =
        dayToSendOptions[snakeCase(value)] ?? dayToSendOptions[dayToSendKey];
      setDayToSend(fieldOptions);
    }
  };

  useEffect(() => {
    if (isEditForm) {
      const {
        reportType,
        timePeriod,
        sendFrequency: sendFrequencyValue,
      } = initialValues;
      updateDependentFields("timePeriod", timePeriod);
      updateDependentFields("sendFrequency", sendFrequencyValue);
      updateDependentFields("reportType", reportType);
    }
  }, [initialValues]);

  const handleSubmit = (values) => {
    if (isEditForm) {
      updateReport(values);
    } else scheduleReport(values);
  };

  const fields = [
    {
      dependentFieldOptions: ["report", "timePeriod"],
      parentField: true,
      items: reportTypes,
      label: "Report Type",
      name: "reportType",
      required: true,
    },
    {
      dependentFieldOptions: [],
      items: reportNames,
      label: "Report",
      name: "reports",
      required: true,
    },
    {
      dependentFieldOptions: [],
      items: accountList,
      label: "Account",
      name: "account",
      required: true,
    },
    {
      dependentFieldOptions: [],
      items: descendantsOptions,
      label: "Descendants",
      name: "descendants",
      required: true,
    },
    {
      dependentFieldOptions: ["sendFrequency", "dayToSend"],
      parentField: true,
      items: timePeriods,
      label: "Time Period",
      name: "timePeriod",
      required: true,
    },
    {
      dependentFieldOptions: ["dayToSend"],
      parentField: true,
      items: sendFrequency,
      label: "Send Frequency",
      name: "sendFrequency",
      required: true,
    },
    {
      dependentFieldOptions: [],
      items: dayToSend,
      label: "Day to Send",
      name: "dayToSend",
      required: true,
    },
  ];

  return (
    <Modal
      ariaLabel="Analytics Report Scheduling Modal"
      closeModal={handleClose}
      fullScreen={fullScreen}
      transitionIn={open}
    >
      <ModalHeader closeModal={handleClose}>{`${
        isEditForm ? "Edit" : "Schedule"
      } an Analytics Report`}</ModalHeader>
      <ModalContent maxWidth="100%">
        <Box minWidth="400px">
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={AnalyticsReportsSchema}
          >
            {({ dirty, isValid, values, setFieldValue }) => {
              return (
                <Form
                  aria-label={`${
                    isEditForm ? "Update" : "Schedule"
                  } Analytic Report Form`}
                >
                  <Box>
                    {fields
                      .filter(Boolean)
                      .map(
                        ({
                          dependentFieldOptions,
                          name,
                          items,
                          label,
                          required,
                          parentField,
                        }) => {
                          return (
                            <Box my={2} key={createUUID()}>
                              <FormikSelect
                                dependentFieldOptions={dependentFieldOptions}
                                handleChange={
                                  parentField ? updateDependentFields : false
                                }
                                items={items}
                                label={label}
                                name={name}
                                required={required}
                                setFieldValue={setFieldValue}
                                values={values}
                              />
                            </Box>
                          );
                        },
                      )}
                    <Box
                      display="flex"
                      flex="0 0 auto"
                      justifyContent="flex-end"
                      mt={{ xs: "0px", sm: "40px" }}
                    >
                      <Button
                        aria-label={`${
                          isEditForm ? "Update" : "Schedule"
                        } Analytics Report`}
                        color="primary"
                        disabled={!dirty || !isValid}
                        type="submit"
                        variant="contained"
                      >
                        {isEditForm ? "Update" : "Schedule"}
                      </Button>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  );
}

ScheduleAnalyticsReportModal.propTypes = {
  accountList: PropTypes.array.isRequired,
  descendantsList: PropTypes.object.isRequired,
  updateReport: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  reports: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  scheduleReport: PropTypes.func.isRequired,
};

export default ScheduleAnalyticsReportModal;
