import Box from "@mui/material/Box";
import PropTypes from "prop-types";

import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import Logo from "components/Logo";
import PageHeader from "components/Page/PageHeader";

function ListElement({ number, children }) {
  return (
    <ListItemText>
      <Box display="flex">
        <Box flex="0 0 50px" fontWeight={700}>
          {number}
        </Box>
        <Box>{children}</Box>
      </Box>
    </ListItemText>
  );
}

function TermsOfUse({ showSidebar, toggleSidebar, noTitle }) {
  return (
    <Box
      aria-label="Terms of Use"
      height="100%"
      display="flex"
      flexDirection="column"
      flex="0 0 auto"
      color="text.primary"
    >
      {!noTitle && (
        <PageHeader
          aria-label="Terms of Use Header"
          title="Terms of Use"
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
        />
      )}
      <Box p={{ xs: 2, sm: 4 }} flex="1 1 auto" overflow="auto">
        <Box aria-label="Terms of Use Content" margin="0 auto" maxWidth="960px">
          <Typography variant="h4" align="center" gutterBottom>
            <Logo />
            <b>Text</b>Us
          </Typography>
          <Typography variant="h5" align="center" gutterBottom>
            ACCEPTABLE USE POLICY
          </Typography>
          <Typography variant="body1" gutterBottom>
            Thank you for choosing TextUs as your business partner. We’ve
            developed this Acceptable Use Policy (“AUP”) to help you use the
            TextUs business texting software applications, analytics, tools,
            platforms and services (“Services”), and ensure that you understand
            policies and procedures we have developed to comply with certain
            laws and regulations. This AUP applies to all customers and users of
            the Services and all users of the Services must comply with this
            AUP. If you use the Services, you agree to comply with this AUP. If
            you have agreed to another agreement with TextUs related to the
            Services (“Agreement”), this AUP is incorporated by reference into
            and becomes part of that agreement. To the extent of any conflict
            between the provisions of this AUP and the provisions of any other
            Agreement, the provisions of such other Agreement shall take
            precedence and govern. Capitalized terms used in this AUP but not
            defined will have the meaning given to such term in the applicable
            Agreement.
          </Typography>
          <Typography variant="body1" gutterBottom>
            Your use and/or continuation of the Service constitutes your
            acceptance of this AUP. It is your responsibility, and contractual
            obligation, to ensure that your affiliates, agents, customers and or
            users comply with this AUP.
          </Typography>
          <Typography variant="body1" gutterBottom>
            TextUs reserves the right to refuse to provide the Services to
            anyone that violates this AUP at our sole discretion. This AUP may
            not represent all possible ways in which you or a user engage in
            unacceptable behavior. We reserve the right to determine, in our
            sole and exclusive judgment, what activities are unacceptable. The
            version of this AUP, available here, is the most recent version.
          </Typography>
          <List disablePadding>
            <ListItem alignItems="flex-start">
              <ListElement number="1.">
                <b>Acceptable Use Policies</b>
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="1.1.">
                Violations of Law. You shall not use the Services to violate any
                Applicable Law. “Applicable Law” includes all applicable laws,
                rules and regulations applicable to you, your business or the
                subject matter of the Agreement including without limitation,
                laws governing the use of individual information, deceptive and
                misleading advertising, electronic commercial communications,
                telemarketing and other similar laws, which include without
                limitation the U.S. Telephone Consumer Protection Act of 1991,
                U.S. Controlling the Assault of Non-Solicited Pornography and
                Marketing Act of 2003 and the Canada Anti-SPAM Legislation, if
                applicable, and each as amended.
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="1.2.">
                Prohibited Uses. you shall not use the Services for, or in
                connection with, the following:
                <ul
                  style={{
                    fontSize: "14px",
                    padding: "0 0 0 1em",
                    listStyleType: "disc",
                  }}
                >
                  <li>
                    Theft or infringement of copyrights, trademarks, trade
                    secrets, or other types of intellectual property.
                  </li>
                  <li>
                    Fraud; forgery; or theft or misappropriation of funds,
                    credit cards, or personal information.
                  </li>
                  <li>
                    Impersonation of any person or entity, including, but not
                    limited to, TextUs personnel, or falsely state or otherwise
                    misrepresent your affiliation with a person or entity.
                  </li>
                  <li>Harm minors in any way.</li>
                  <li>
                    Making available any Content that (i) is unlawful, tortious,
                    defamatory, vulgar, obscene, libelous, or racially,
                    ethnically or otherwise objectionable; (ii) violates, or
                    encourages any conduct that would violate, any Applicable
                    Law or would give rise to civil liability; (iii) promotes
                    discrimination, bigotry, racism, hatred, harassment or harm
                    against any individual or group; (iv) is violent or
                    threatening, or promotes violence or actions that are
                    threatening to any other person; or (v) promotes illegal or
                    harmful activities.
                  </li>
                  <li>
                    Making available any Content that you do not have a right to
                    make available under any law or under contractual or
                    fiduciary relationships (such as inside information,
                    proprietary and confidential information learned or
                    disclosed as part of employment relationships or under
                    non-disclosure agreements).
                  </li>
                  <li>Stalking or otherwise harassing any person or entity.</li>
                  <li>
                    Export, re-export, or transfer of restricted software,
                    algorithms or other data in violation of applicable export
                    control laws.
                  </li>
                  <li>
                    Intentionally or unintentionally violate any applicable
                    local, state, provincial, national or international law,
                    treaty, or regulation, or any order of a court.
                  </li>
                  <li>
                    Deceptive practices such as posing as another service for
                    the purposes of phishing or pharming.
                  </li>
                  <li>
                    Distributing any materials of a threatening or harmful
                    nature, including without limitation threats of death or
                    physical harm, or materials that are malicious, harassing,
                    libelous, defamatory, or which facilitate extortion or
                    harmful action.{" "}
                  </li>
                  <li>
                    Distributing any offensive materials, including without
                    limitation obscene, pornographic, indecent or hateful
                    materials and materials which promote gambling or
                    discrimination based on race, sex, religion, nationality,
                    disability, sexual orientation, or age.{" "}
                  </li>
                  <li>
                    Sending unwanted telemarketing, promotional or informational
                    messages without having procured the necessary consents,
                    right and license from the recipient(s) of your messages.{" "}
                  </li>
                  <li>
                    Sending messages in violation of the U.S. National Do Not
                    Call Registry or related considerations under Applicable
                    Law.{" "}
                  </li>
                  <li>
                    Send Protected Health Information unless allowed under
                    Applicable Law.{" "}
                  </li>
                  <li>
                    Registering for more Accounts or associated Admin Logins or
                    User Logins than for which fees have been paid or register
                    for an Account on behalf of an individual other than
                    yourself.
                  </li>
                </ul>
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="2.">
                <b>Other AUPs.</b> You shall not use the Services to violate the
                acceptable use policy or terms of service of any other service
                provider, including, without limitation, any Internet service
                provider, telecommunication provider, or messaging service
                provider.
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="3.">
                <b>Other’s Use of the Services.</b> You shall not use the
                Services in a manner that interferes with any other party’s
                ability to use and enjoy the Services, that interferes with
                TextUs’ or its service partners’ ability to provide the
                Services, or that otherwise may create legal liability for
                TextUs or its service partners in TextUs’ sole discretion.
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="4.">
                {" "}
                <b>Security.</b> You may not use the Services to violate,
                attempt to violate, or knowingly facilitate the violation of the
                security or integrity of any network, electronic service, or
                other system that is accessible through, or in connection with,
                the Services. Without limiting the foregoing, you shall not use
                the Services for, or in connection with, the following:
                <ul
                  style={{
                    fontSize: "14px",
                    padding: "0 0 0 1em",
                    listStyleType: "disc",
                  }}
                >
                  <li>
                    Hacking, cracking into, or otherwise using the non-public
                    areas of the Services or any other system without
                    authorization.
                  </li>
                  <li>
                    Unauthorized probes or port scans for vulnerabilities.
                  </li>
                  <li>
                    Unauthorized penetration tests, traffic that circumvents
                    authentication systems or other unauthorized attempts to
                    gain entry into any system.
                  </li>
                  <li>
                    Web crawling which is not restricted to a rate so as not to
                    impair or otherwise disrupt the servers being crawled.
                  </li>
                  <li>Unauthorized network monitoring or packet capture.</li>
                  <li>
                    Forged or non-standard protocol headers, such as altering
                    source addresses.
                  </li>
                  <li>Flooding.</li>
                  <li>Denial of Service (DoS) attacks of any kind.</li>
                  <li>
                    Distributing unauthorized data, malware, viruses, Trojan
                    horses, spyware, worms, or other malicious or harmful code.
                  </li>
                  <li>
                    Deceptive practices such as posing as another service for
                    the purposes of phishing or pharming.
                  </li>
                  <li>
                    Operating network services such as: open proxies; open mail
                    relays; or open, recursive domain name servers.
                  </li>
                  <li>
                    Sharing or publishing content from the Services to cause, or
                    have the consequence of causing, the user of the content to
                    be in violation of the Terms and this Policy.
                  </li>
                  <li>
                    Advocate, encourage or assist any third party in doing any
                    of the foregoing activities in this section.
                  </li>
                </ul>
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="5.">
                <b>Unsolicited Messages.</b> You shall not use the Services for
                purposes of distributing text messaging “spam,” bulk unsolicited
                messages, or any other form of unsolicited electronic
                communications distributed on a bulk basis to recipients with
                which you have no preexisting business or personal relationship.
                You shall not use the Services to collect responses from spam.
                Additionally, you shall not use the Services to send unwanted
                messages to individuals who have asked to stop receiving
                messages through any medium. To the extent required by
                Applicable Law, you must track and record all such requests
                specific to your business. You must also provide recipients of
                those MMS/SMS messages you send via the Services with
                conspicuous notice of their ability to opt-out from receiving
                any future text messages, by texting STOP in a stand-alone
                message with no additional characters or punctuation.{" "}
                <b>
                  TextUs’ platform responds programmatically to the keyword
                  STOP, and it works with a market leading digital security firm
                  to monitor for and prevent spam.
                </b>{" "}
                Without limiting the foregoing, you shall not use the Services
                for, or in connection with, the following:
                <ul
                  style={{
                    fontSize: "14px",
                    padding: "0 0 0 1em",
                    listStyleType: "disc",
                  }}
                >
                  <li>Sending pyramid schemes.</li>
                  <li>Sending chain letters.</li>
                  <li>Sending any mail in contravention of Applicable Law.</li>
                  <li>
                    Altering or obscuring mail headers or assuming the identity
                    of a sender without the explicit permission of that sender.
                  </li>
                  <li>
                    Advocate, encourage or assist any third party in doing any
                    of the foregoing activities in this section.
                  </li>
                </ul>
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="6.">
                <b>Data Collection.</b> You shall not harvest, collect, gather
                or assemble information or data of users, including e-mail
                addresses, without their consent.
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="7.">
                <b>Responsibility.</b> You are solely responsible for obtaining
                all necessary and appropriate rights, licenses and consents from
                those persons and entities with whom you message or otherwise
                communicate with via the Services, prior to commencing any such
                messaging or communication.
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="8.">
                <b>Changes to this AUP.</b> You expressly understand that the
                evolving nature of the internet and online commerce makes it
                necessary for us to reserve the right to make changes to this
                AUP at any time, and without notice. This AUP is subject to
                occasional revision at the sole discretion of TextUs. We will
                take reasonable steps to notify you of any material changes or
                modifications to this AUP by way of prominent notice on our
                website or by email, but you agree to review the website
                periodically to be aware of any changes or modifications. If you
                do not agree to the changes in this AUP, you must discontinue
                use of the Services. Your continued use of the Services shall be
                deemed your conclusive acceptance of any such revisions.
              </ListElement>
            </ListItem>
            <ListItem alignItems="flex-start">
              <ListElement number="9.">
                <b>Questions.</b> If you have any questions, please feel free to
                reach out to us at{" "}
                <Link color="secondary" href="mailto:support@textus.com">
                  support@textus.com{" "}
                </Link>
              </ListElement>
            </ListItem>
          </List>
        </Box>
      </Box>
    </Box>
  );
}

TermsOfUse.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  noTitle: PropTypes.bool,
};

ListElement.propTypes = {
  number: PropTypes.string.isRequired,
  children: PropTypes.any,
};

export default TermsOfUse;
