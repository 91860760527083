import { Link } from "react-router-dom";
import type { LinkProps } from "react-router-dom";
import { useMemo, useState } from "react";
import styled from "styled-components";
import { Account } from "@tesseract/core";
import Logo from "components/Logo";

const LogoLink = styled(Link)`
  padding: 0 12px;
`;

const WhiteLabelLogo = styled.img`
  max-height: 45px;
`;

function _Logo({
  isSinglePanel,
  currentAccount,
  ...props
}: {
  isSinglePanel: boolean;
  currentAccount: Account.Raw;
} & LinkProps) {
  const [animateLogo, setAnimateLogo] = useState(false);
  const mobileLogo = currentAccount?.settings?.mobileLogo?.value;

  const whiteLabelLogoSrc = useMemo(() => {
    if (!!mobileLogo && isSinglePanel) {
      return mobileLogo;
    }
    if (currentAccount?.logo) {
      return currentAccount?.logo;
    }
    return mobileLogo;
  }, [currentAccount?.logo, isSinglePanel, mobileLogo]);

  return (
    <LogoLink
      onMouseEnter={() => {
        setAnimateLogo(true);
      }}
      onMouseLeave={() => {
        setAnimateLogo(false);
      }}
      {...props}
    >
      {currentAccount?.logo || mobileLogo ? (
        <WhiteLabelLogo
          src={whiteLabelLogoSrc}
          max-width={isSinglePanel ? 50 : 120}
          alt={currentAccount.settings.product?.value ?? "TextUs"}
        />
      ) : (
        <Logo
          showName={!isSinglePanel}
          width={isSinglePanel ? 50 : 120}
          animate={animateLogo}
        />
      )}
    </LogoLink>
  );
}

export { _Logo as Logo };
