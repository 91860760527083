import { TextUsColor } from "./types";

export const deepOrange: TextUsColor = {
  50: "#FBE9E7",
  100: "#FFCCBC",
  200: "#FFAB91",
  300: "#FF8A65",
  400: "#FF7043",
  500: "#FF5722",
  600: "#F4511E",
  700: "#D83C0B",
  800: "#D54012",
  900: "#BF360C",
  A8: "rgba(213, 64, 18, 0.08)",
  A12: "rgba(213, 64, 18, 0.12)",
  A16: "rgba(213, 64, 18, 0.16)",
  A30: "rgba(213, 64, 18, 0.3)",
  A50: "rgba(213, 64, 18, 0.5)",
};
