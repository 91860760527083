import get from "lodash/get";

import {
  faAddressBook,
  faBullhorn,
  faChartBar,
  faCog,
  faInbox,
  faRobot,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

export const getAccountUnansweredCount = (accountNavbarElement) => {
  return get(accountNavbarElement, ["siteNavigation", "members"], [])
    .filter((siteNavigationElement) => {
      return siteNavigationElement.name === "inbox";
    })
    .map((inboxElement) => {
      return get(inboxElement, ["badgeRecord", "value"], 0);
    })
    .reduce((acc, value) => {
      return acc + value;
    }, 0);
};

export const getTotalUnansweredCount = (accountNavbar) => {
  return get(accountNavbar, ["members"], [])
    .map((accountNavbarElement) => {
      return getAccountUnansweredCount(accountNavbarElement);
    })
    .reduce((acc, value) => {
      return acc + value;
    }, 0);
};

export const getFontAwesomeIcon = (icon) => {
  return {
    analytics: faChartBar,
    automation: faRobot,
    inbox: faInbox,
    contacts: faAddressBook,
    campaign: faBullhorn,
    groups: faUsers,
    settings: faCog,
  }[icon];
};
