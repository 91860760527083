import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@mui/material/Button";

import Loader from "components/Loader";
import P from "components/P";

const Root = styled.div`
  position: absolute;
  bottom: 0;
  height: 60px;
  width: 100%;
  border-top: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  border-radius: 0 0 10px 10px;
  z-index: 2;

  .buttons {
    margin-left: auto;

    button {
      margin-left: 10px;
      height: 36px;
    }
  }

  ${P} {
    color: ${(props) => {
      return props.theme.colors.text.secondary;
    }};
  }
`;

const CancelButton = styled(Button)``;

const SaveButton = styled(Button)``;

const UnsavedChanges = styled(P)``;

export default class AssignmentSectionFooter extends Component {
  static propTypes = {
    hasChanges: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    isAssigning: PropTypes.bool.isRequired,
  };

  render() {
    const { hasChanges, close, isAssigning } = this.props;
    return (
      <Root>
        {hasChanges && (
          <UnsavedChanges>You have unsaved changes</UnsavedChanges>
        )}
        <span className="buttons">
          <CancelButton
            color="primary"
            onClick={close}
            style={{ marginRight: "10px" }}
          >
            Cancel
          </CancelButton>
          <SaveButton
            color="primary"
            variant="contained"
            disabled={!hasChanges}
            onClick={this.props.save}
          >
            <Loader isLoading={isAssigning}>Save</Loader>
          </SaveButton>
        </span>
      </Root>
    );
  }
}
