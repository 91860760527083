import { useState, useMemo, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { compose } from "redux";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";

import {
  Box,
  Card,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";

import GroupTable from "./GroupTable";
import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import withRecord from "higherOrderComponents/withRecord";

import Loader from "components/Loader";

function Groups({
  currentAccount,
  groupFilterCollection,
  setGroup,
  setGroupRecipients,
}) {
  const [groupFilter, setGroupFilter] = useState(
    groupFilterCollection?.members?.find(({ slug }) => {
      return slug === "mine";
    }),
  );
  const [searchIsOpen, toggleSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const tabletScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("md");
  });

  const debouncedSearch = useMemo(() => {
    return debounce((query) => {
      const accountsGroupFilter = groupFilterCollection.members.find(
        ({ slug }) => {
          return slug === "accounts";
        },
      );

      if (accountsGroupFilter.groups) {
        accountsGroupFilter.groups = accountsGroupFilter.groups.concat(
          `?q=${encodeURIComponent(query.toLowerCase())}`,
        );
        setGroupFilter(accountsGroupFilter);
      }
    }, 300);
  }, [groupFilterCollection, setGroupFilter]);

  const handleSearch = useCallback(
    (query) => {
      debouncedSearch(query);
    },
    [debouncedSearch],
  );

  const handleClear = () => {
    handleSearch("");
    setSearchValue("");
  };

  const location = useLocation();

  if (isEmpty(groupFilterCollection)) {
    return <Loader isLoading />;
  }

  const responsivePadding = mobileScreen ? "1rem" : "0.5rem 1rem";

  const getSearchField = () => {
    return mobileScreen ? (
      <Box
        sx={(theme) => {
          return {
            border: `1px solid ${theme.palette.divider}`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "2.5rem",
            borderRadius: "0.25rem",
            width: "100%",
          };
        }}
      >
        <SearchIcon
          fontSize="medium"
          sx={(theme) => {
            return {
              color: theme.palette.action.active,
              marginRight: "0.5rem",
              marginLeft: "0.5rem",
            };
          }}
        />
        <TextField
          display="flex"
          onChange={(event) => {
            const { value } = event.target;
            setSearchValue(value);
            handleSearch(value);
          }}
          value={searchValue}
          placeholder="Search groups"
          InputProps={{
            disableUnderline: true,
          }}
          minWidth="18rem"
          variant="standard"
          sx={{
            alignSelf: "center",
            width: "100%",
          }}
        />
        <IconButton
          aria-label="Search Button"
          color="action.active"
          onClick={searchValue === "" ? null : handleClear}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    ) : (
      <Box display="flex">
        <TextField
          data-testid="search-groups-field"
          onChange={(event) => {
            const { value } = event.target;
            setSearchValue(value);
            handleSearch(value);
          }}
          value={searchValue}
          placeholder="Search groups"
          InputProps={{
            disableUnderline: true,
          }}
          style={{ alignSelf: "center" }}
          variant="standard"
          width="100%"
        />
        <IconButton
          aria-label="Search Button"
          onClick={() => {
            setSearchValue("");
            toggleSearch(!searchIsOpen);
            return searchValue === "" ? null : handleSearch("");
          }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>
    );
  };

  return (
    <Card
      variant="outlined"
      overflow="none"
      width="100%"
      sx={{
        borderRadius: tabletScreen ? 0 : "0.25rem",
        margin: "0 auto",
        maxWidth: { xs: "100%", sm: "536px", md: "712px" },
        width: "100%",
      }}
    >
      <Box
        borderBottom={1}
        color="grey.300"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding={responsivePadding}
        height="3.375rem"
      >
        {searchIsOpen && mobileScreen ? null : (
          <Typography
            variant={mobileScreen ? "h6" : "h5"}
            color="text.primary"
            fontSize="0.875rem"
          >
            Groups
          </Typography>
        )}
        {searchIsOpen ? (
          getSearchField()
        ) : (
          <IconButton
            aria-label="Search Button"
            color="primary"
            onClick={() => {
              toggleSearch(!searchIsOpen);
            }}
            size="large"
          >
            <SearchIcon
              sx={(theme) => {
                return {
                  color: theme.palette.action.active,
                };
              }}
            />
          </IconButton>
        )}
      </Box>
      <GroupTable
        groupFilter={groupFilter}
        groupCollectionId={getPaginatedId(getNestedId(groupFilter, "groups"), {
          location,
        })}
        setGroup={setGroup}
        setGroupRecipients={setGroupRecipients}
      />
    </Card>
  );
}

Groups.propTypes = {
  groupFilterCollection: PropTypes.object.isRequired,
  setGroup: PropTypes.func.isRequired,
  setGroupRecipients: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container:
      "features/Campaigns/containers/SendCampaign/NewCampaign/components/Groups/group",
    shape: { contacts: {} },
    type: "group",
    noFetch: true,
    showLoader: () => {
      return false;
    },
  }),
  withRecord({
    actions: ["fetch"],
    container:
      "features/Campaigns/containers/SendCampaign/NewCampaign/components/Groups",
    shape: { members: [] },
    type: "groupFilterCollection",
  }),
)(Groups);
