import XLSX from "xlsx";

export const convertToCsv = ({
  data,
  fileName,
}: {
  data: any;
  fileName: string;
}) => {
  const workbook = XLSX.read(data, { type: "array" });
  const firstWorksheet = workbook.Sheets[workbook.SheetNames[0]];
  const csv = XLSX.utils.sheet_to_csv(firstWorksheet, {
    header: 1,
  });
  const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  return new File([csvBlob], `${fileName}.csv`);
};
