import { useCallback, useMemo, useState } from "react";
import styled from "styled-components";
import { Formik, Field, getIn } from "formik";
import TextField from "@mui/material/TextField";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Typography } from "@mui/material";
import IntegrationForm from "./IntegrationForm";
import IntegrationSubmitSection from "./IntegrationSubmitSection";
import type { IntegrationProps } from "./types";
import H4 from "components/H4";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import ExpansionPanelDetails from "components/ExpansionPanel/Details";

import { BullhornValidationSchema } from "formHelpers/validationSchemas";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import DATACENTERS from "features/AccountSettings/containers/Integrations/constants/bullhornDatacenters";
import { Checkbox } from "components/Checkbox";
import { NativeSelect } from "components/NativeSelect";
import { Switch } from "components/Switch";

const Logo = styled.div<{ src: string }>`
  width: 100%;
  max-width: 320px;
  height: 100px;
  margin: 20px;
  background: url("${(props) => {
    return props.src;
  }}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const Text = styled.p`
  font-size: 14px;
  letter-spacing: 0.17px;
  line-height: 143%;
  margin-bottom: 16px;
  margin-left: 8px;
  width: 100%;
`;

const FormRow = styled.div`
  width: 100%;
  & + & {
    margin-top: 20px;
  }
`;

const StyledExpansionPanel = styled(ExpansionPanel)`
  width: 100%;
`;

const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  display: flex;
  flex-flow: column nowrap;
  padding: 15px 22px !important;

  ${H4} {
    margin-bottom: 15px;
  }
`;

const switchOptions = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" },
];

const contactImportOptions = [
  { value: 30, label: "30" },
  { value: 60, label: "60" },
  { value: 90, label: "90" },
];

type Props = IntegrationProps<
  {
    username: string;
    password: string;
    clientId: string;
    clientSecret: string;
    apiHost: string;
    authHost: string;
  },
  {
    batching: {
      enabled: boolean;
      note_duration: string;
      update_frequency_minutes: number;
    };
    custom_object: {
      active: boolean;
      entity_name: string;
    };
    enabled_phones?: ("phone2" | "phone3" | "workPhone")[];
    contact_schemas_enabled: boolean;
    initial_contact_import: {
      created_within_days: number;
      updated_within_days: number;
      owned: boolean;
    };
  }
>;

function Bullhorn({
  provider,
  handleSubmit: submit,
  toggleActive,
  account,
}: Props) {
  const [expandedPanel, setExpandedPanel] = useState<string | undefined>(
    "configuration",
  );

  const isInitialContactImportPerviouslyEnabled = useMemo(() => {
    const initialContactImport =
      provider.integration?.settings?.initial_contact_import;

    return (
      Boolean(initialContactImport?.created_within_days) ||
      Boolean(initialContactImport?.updated_within_days)
    );
  }, [provider.integration?.settings?.initial_contact_import]);

  const handlePanelChange = useCallback((panel: string | undefined) => {
    return () => {
      setExpandedPanel(panel);
    };
  }, []);

  const handleSubmit = useCallback(
    (values: {
      config: {
        //
      };
      settings: {
        enabledPhones: [
          "phone2" | undefined,
          "phone3" | undefined,
          "workPhone" | undefined,
        ];
        initialContactImport: Record<
          "createdWithinDays" | "updatedWithinDays",
          {
            enabled: boolean;
            value: number;
          }
        > & {
          owned: boolean;
        };
        customObject: {
          active: boolean;
          entityName: string;
        };
        contactSchemasEnabled: boolean;
        batching: {
          enabled: boolean;
          noteDuration: string | undefined;
          updateFrequencyMinutes: number | undefined;
        };
      };
    }) => {
      const {
        initialContactImport: { owned, ...initialContactImport },
        enabledPhones,
        ...settings
      } = values.settings;

      const isInitialContactImportEnabled = Object.entries(
        initialContactImport,
      ).some(([, { enabled }]) => {
        return enabled === true;
      });

      submit({
        provider,
        config: values.config,
        settings: {
          ...settings,
          ...(enabledPhones &&
            enabledPhones.filter(Boolean).length && {
              enabledPhones: enabledPhones.filter(Boolean),
            }),
          ...(isInitialContactImportEnabled &&
            !isInitialContactImportPerviouslyEnabled && {
              initialContactImport: {
                enabled: isInitialContactImportEnabled,
                ...(initialContactImport.createdWithinDays.enabled === true && {
                  createdWithinDays:
                    initialContactImport.createdWithinDays.value,
                }),
                ...(initialContactImport.updatedWithinDays.enabled === true && {
                  updatedWithinDays:
                    initialContactImport.updatedWithinDays.value,
                }),
                owned,
              },
            }),
        },
      });
    },
    [isInitialContactImportPerviouslyEnabled, provider, submit],
  );
  const config = provider?.integration?.config;
  const settingsProp = provider?.integration?.settings;

  return (
    <>
      <ModalHeader closeModal={provider.closeModal}>
        {provider.name}
      </ModalHeader>
      <ModalContent noPadding>
        <Formik
          enableReinitialize
          initialValues={{
            config: {
              username: config?.username ?? "",
              password: config?.password ?? "",
              clientId: config?.clientId ?? "",
              clientSecret: config?.clientSecret ?? "",
              apiHost: config?.apiHost ?? "",
              authHost: config?.authHost ?? "",
            },
            settings: {
              batching: {
                enabled: settingsProp?.batching?.enabled ?? false,
                noteDuration:
                  settingsProp?.batching?.note_duration ?? undefined,
                updateFrequencyMinutes:
                  settingsProp?.batching?.update_frequency_minutes ?? undefined,
              },
              customObject: {
                active: settingsProp?.custom_object?.active ?? false,
                entityName: settingsProp?.custom_object?.entity_name ?? "",
              },
              enabledPhones: settingsProp?.enabled_phones
                ? [
                    settingsProp.enabled_phones.includes("phone2")
                      ? "phone2"
                      : undefined,
                    settingsProp.enabled_phones.includes("phone3")
                      ? "phone3"
                      : undefined,
                    settingsProp.enabled_phones.includes("workPhone")
                      ? "workPhone"
                      : undefined,
                  ]
                : (Array.from({ length: 3 }, () => {
                    return undefined;
                  }) as [undefined, undefined, undefined]),
              contactSchemasEnabled:
                settingsProp?.contact_schemas_enabled ?? false,
              initialContactImport: {
                createdWithinDays: {
                  enabled: Boolean(
                    settingsProp?.initial_contact_import?.created_within_days,
                  ),
                  value:
                    settingsProp?.initial_contact_import?.created_within_days ??
                    30,
                },
                updatedWithinDays: {
                  enabled: Boolean(
                    settingsProp?.initial_contact_import?.updated_within_days,
                  ),
                  value:
                    settingsProp?.initial_contact_import?.updated_within_days ??
                    30,
                },
                owned: settingsProp?.initial_contact_import?.owned ?? false,
              },
            },
          }}
          onSubmit={handleSubmit}
          validationSchema={BullhornValidationSchema}
        >
          {({
            setFieldValue,
            values,
            errors,
            touched,
            isSubmitting,
            isValid,
          }) => {
            return (
              <IntegrationForm>
                <Logo src={provider.logo} />
                <StyledExpansionPanel
                  expanded={expandedPanel === "configuration"}
                  onChange={handlePanelChange("configuration")}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Configuration
                  </ExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <FormRow>
                      <Field name="config.username" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.username") &&
                                Boolean(getIn(errors, "config.username"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.username")
                                  ? getIn(errors, "config.username") || " "
                                  : " "
                              }
                              id="bullhorn_username"
                              label="Username"
                              type="text"
                              variant="outlined"
                            />
                          );
                        }}
                      </Field>
                    </FormRow>
                    <FormRow>
                      <Field name="config.password" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.password") &&
                                Boolean(getIn(errors, "config.password"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.password")
                                  ? getIn(errors, "config.password") || " "
                                  : " "
                              }
                              id="bullhorn_password"
                              label="Password"
                              type="password"
                              variant="outlined"
                            />
                          );
                        }}
                      </Field>
                    </FormRow>
                    <FormRow>
                      <Field name="config.clientId" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.clientId") &&
                                Boolean(getIn(errors, "config.clientId"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.clientId")
                                  ? getIn(errors, "config.clientId") || " "
                                  : " "
                              }
                              id="bullhorn_client_id"
                              label="Client ID"
                              type="text"
                              variant="outlined"
                            />
                          );
                        }}
                      </Field>
                    </FormRow>
                    <FormRow>
                      <Field name="config.clientSecret" type="text">
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              error={
                                getIn(touched, "config.clientSecret") &&
                                Boolean(getIn(errors, "config.clientSecret"))
                              }
                              fullWidth
                              helperText={
                                getIn(touched, "config.clientSecret")
                                  ? getIn(errors, "config.clientSecret") || " "
                                  : " "
                              }
                              id="bullhorn_client_secret"
                              label="Client Secret"
                              type="text"
                              variant="outlined"
                            />
                          );
                        }}
                      </Field>
                    </FormRow>
                    <FormRow>
                      <Field name="config.apiHost" type="select">
                        {({ field }: any) => {
                          return (
                            <TextField
                              label="Datacenter"
                              name={field.name}
                              onChange={(event) => {
                                const index = Number(event.target.value);
                                setFieldValue(
                                  "config.apiHost",
                                  DATACENTERS[index].apiHost,
                                );
                                setFieldValue(
                                  "config.authHost",
                                  DATACENTERS[index].authHost,
                                );
                              }}
                              select
                              SelectProps={{
                                native: true,
                                inputProps: { "aria-label": "Datacenter" },
                              }}
                              value={DATACENTERS.findIndex((datacenter) => {
                                return datacenter.apiHost === field.value;
                              })}
                              variant="outlined"
                            >
                              <option
                                key="placeholder"
                                aria-label="Empty"
                                value=""
                              ></option>
                              {DATACENTERS.map((option, i) => {
                                return (
                                  <option key={option.apiHost} value={i}>
                                    {option.name}
                                  </option>
                                );
                              })}
                            </TextField>
                          );
                        }}
                      </Field>
                    </FormRow>
                  </StyledExpansionPanelDetails>
                </StyledExpansionPanel>
                <StyledExpansionPanel
                  expanded={expandedPanel === "settings"}
                  onChange={handlePanelChange("settings")}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    Settings
                  </ExpansionPanelSummary>
                  <StyledExpansionPanelDetails>
                    <FormRow>
                      <H4>Batch Conversation Logging</H4>
                      <Switch
                        name="settings.batching.enabled"
                        color="secondary"
                        options={switchOptions}
                        disabled={values.settings?.customObject?.active}
                      />
                    </FormRow>
                    {values.settings?.batching?.enabled ? (
                      <>
                        <FormRow>
                          <NativeSelect
                            {...{
                              defaultValue: "daily",
                              label: "Conversation Duration",
                              name: "settings.batching.noteDuration",
                              options: [
                                {
                                  label: "One Day",
                                  value: "daily",
                                },
                                {
                                  label: "One Week",
                                  value: "weekly",
                                },
                              ],
                            }}
                          />
                        </FormRow>
                        <FormRow>
                          <NativeSelect
                            {...{
                              defaultValue: 0,
                              label: "Refresh Frequency",
                              name: "settings.batching.updateFrequencyMinutes",
                              options: [
                                {
                                  label: "Every Message",
                                  value: 0,
                                },
                                {
                                  label: "15 Minutes",
                                  value: 15,
                                },
                                {
                                  label: "1 Hour",
                                  value: 60,
                                },
                                {
                                  label: "1 Day",
                                  value: 1440,
                                },
                                {
                                  label: "1 Week",
                                  value: 10080,
                                },
                              ],
                            }}
                          />
                        </FormRow>
                      </>
                    ) : undefined}
                    <FormRow>
                      <H4>Contact Sync</H4>
                      <Switch
                        name="settings.contactSchemasEnabled"
                        color="secondary"
                        options={switchOptions}
                      />
                    </FormRow>
                    {values.settings?.contactSchemasEnabled && (
                      <>
                        <Text>Choose which contacts to sync to TextUs.</Text>
                        <Text>
                          Once you sync your data it will be the same in TextUs.
                          All duplicates that are in Bullhorn will be shown in
                          TextUs.
                        </Text>
                        {isInitialContactImportPerviouslyEnabled && (
                          <Text style={{ marginBottom: "-20px" }}>
                            Initial import is only done on first setup.
                          </Text>
                        )}
                        <Grid
                          container
                          alignItems="flex-end"
                          rowSpacing={2}
                          style={
                            isInitialContactImportPerviouslyEnabled
                              ? {
                                  opacity: 0.1,
                                  pointerEvents: "none",
                                }
                              : undefined
                          }
                          mb={2}
                        >
                          <Grid item mb="-6px" xs={8}>
                            <Checkbox
                              name="settings.initialContactImport.createdWithinDays.enabled"
                              options={[
                                {
                                  value: true,
                                  label: "All updated in the last",
                                },
                                {
                                  value: false,
                                  label: "All updated in the last",
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NativeSelect
                              defaultValue={30}
                              label="Days"
                              name="settings.initialContactImport.createdWithinDays.value"
                              options={contactImportOptions}
                              onChange={() => {
                                setFieldValue(
                                  "settings.initialContactImport.createdWithinDays.enabled",
                                  true,
                                );
                              }}
                            />
                          </Grid>
                          <Grid item mb="-6px" xs={8}>
                            <Checkbox
                              name="settings.initialContactImport.updatedWithinDays.enabled"
                              options={[
                                {
                                  value: true,
                                  label: "All created in the last",
                                },
                                {
                                  value: false,
                                  label: "All created in the last",
                                },
                              ]}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <NativeSelect
                              defaultValue={30}
                              label="Days"
                              name="settings.initialContactImport.updatedWithinDays.value"
                              options={contactImportOptions}
                              onChange={() => {
                                setFieldValue(
                                  "settings.initialContactImport.updatedWithinDays.enabled",
                                  true,
                                );
                              }}
                            />
                          </Grid>
                          <Grid item mt="6px" xs={12}>
                            <Checkbox
                              name="settings.initialContactImport.owned"
                              options={[
                                {
                                  value: true,
                                  label: "All with a matching TextUs owner",
                                },
                                {
                                  value: false,
                                  label: "All with a matching TextUs owner",
                                },
                              ]}
                            />
                          </Grid>
                        </Grid>
                      </>
                    )}
                    <FormRow>
                      <H4>Additional Phone Mapping</H4>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                        }}
                        mb={2}
                      >
                        If messaging candidates using a non-standard phone
                        field, note logging can be enabled for that mapping from
                        the list below. <br /> <br />
                        The standard phone mapping fields <br />
                        (&rsquo;Mobile&rsquo; and &rsquo;Phone&rsquo;) are
                        always enabled.
                      </Typography>

                      {[
                        ["phone2", "Phone 2"],
                        ["phone3", "Phone 3"],
                        ["workPhone", "Work Phone"],
                      ].map(([value, label], index) => {
                        return (
                          <Checkbox
                            formControlLabelProps={{
                              sx: {
                                display: "flex",
                                marginBottom: 1,
                              },
                            }}
                            key={value}
                            name={`settings.enabledPhones.[${index}]`}
                            options={[
                              {
                                value,
                                label,
                              },
                              {
                                value: undefined,
                                label,
                              },
                            ]}
                          />
                        );
                      })}
                    </FormRow>
                    <FormRow>
                      <H4>Custom Object</H4>
                      <Switch
                        name="settings.customObject.active"
                        color="secondary"
                        options={switchOptions}
                        disabled={values.settings?.batching?.enabled}
                      />
                      <br />
                      <br />
                      <Field
                        name="settings.customObject.entityName"
                        type="text"
                      >
                        {({ field }: any) => {
                          return (
                            <TextField
                              {...field}
                              disabled={values.settings?.batching?.enabled}
                              error={
                                getIn(
                                  touched,
                                  "settings.customObject.entityName",
                                ) &&
                                Boolean(
                                  getIn(
                                    errors,
                                    "settings.customObject.entityName",
                                  ),
                                )
                              }
                              fullWidth
                              helperText={
                                getIn(
                                  touched,
                                  "settings.customObject.entityName",
                                )
                                  ? getIn(
                                      errors,
                                      "settings.customObject.entityName",
                                    ) || " "
                                  : " "
                              }
                              id="bullhorn_custom_object_entity_name"
                              label="Entity Name"
                              type="text"
                              variant="outlined"
                            />
                          );
                        }}
                      </Field>
                    </FormRow>
                  </StyledExpansionPanelDetails>
                </StyledExpansionPanel>
                <IntegrationSubmitSection
                  isSubmitting={isSubmitting}
                  isValid={isValid}
                  provider={provider}
                  toggleActive={toggleActive}
                />
              </IntegrationForm>
            );
          }}
        </Formik>
      </ModalContent>
    </>
  );
}

export default Bullhorn;
