import { useContext } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { capitalize } from "lodash";
import { BlueprintsContext } from "../../context";
import { BlueprintsTemplatesFilterProps } from "./types";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

function BlueprintsTemplatesFilter({
  filters,
  handleFilterChange,
}: BlueprintsTemplatesFilterProps) {
  const { blueprints } = useContext(BlueprintsContext);

  const { absolute, relative } = SCHEDULE_TYPES;

  const useCases = [
    ...new Set(
      blueprints.map((blueprint) => {
        return blueprint.useCase;
      }),
    ),
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={(theme) => {
        return {
          [theme.breakpoints.down("md")]: {
            width: "24.75rem",
          },
          [theme.breakpoints.between("md", "xl")]: {
            width: "18.75rem",
          },
          [theme.breakpoints.up("xl")]: {
            flexDirection: "row",
            width: "33.25rem",
          },
        };
      }}
      padding="1rem"
      gap="1rem"
    >
      <TextField
        select
        name="scheduleType"
        label="Sequence type"
        value={filters.scheduleType}
        onChange={handleFilterChange}
        size="small"
        fullWidth
        sx={(theme) => {
          return {
            [theme.breakpoints.up("xl")]: {
              width: "66%",
            },
          };
        }}
      >
        <MenuItem value={relative}>Ongoing days and times</MenuItem>
        <MenuItem value={absolute}>Scheduled date and time</MenuItem>
      </TextField>
      <TextField
        select
        name="useCase"
        label="Use case"
        value={filters.useCase}
        onChange={handleFilterChange}
        size="small"
        fullWidth
        sx={(theme) => {
          return {
            [theme.breakpoints.up("xl")]: {
              width: "33%",
            },
          };
        }}
      >
        <MenuItem value="all">All</MenuItem>
        {useCases.map((blueprint) => {
          return (
            <MenuItem key={blueprint} value={blueprint}>
              {capitalize(blueprint)}
            </MenuItem>
          );
        })}
      </TextField>
    </Box>
  );
}

export { BlueprintsTemplatesFilter };
