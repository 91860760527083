import styled from "styled-components";

const Label = styled.label`
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.secondaryFontSize;
  }};
  font-weight: 700;
  text-transform: uppercase;
`;

export default Label;
