import { useCallback, useState } from "react";

export const useToggle = (initialState = false) => {
  const [is, setIs] = useState<boolean>(initialState);

  const toggle = useCallback(() => {
    setIs(!is);
  }, [is]);

  return [is, toggle] as const;
};
