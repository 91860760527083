import { Component } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import breakpoints from "utils/styles/breakpoints";

function withSidebar(WrappedComponent) {
  class WithSidebar extends Component {
    static propTypes = {
      isLarge: PropTypes.bool.isRequired,
      isSinglePanel: PropTypes.bool.isRequired,
    };

    constructor(props) {
      super(props);
      this.state = {
        showSidebar: props.isLarge,
      };
    }

    componentDidUpdate(prevProps) {
      const isBreakpointTransition =
        (prevProps.isLarge && !this.props.isLarge) ||
        (!prevProps.isLarge && this.props.isLarge);
      if (isBreakpointTransition) {
        this.setState({ showSidebar: this.props.isLarge });
      }
    }

    toggleSidebar = () => {
      const { showSidebar } = this.state;
      this.setState({ showSidebar: !showSidebar });
      return !showSidebar;
    };

    render() {
      const { isSinglePanel, isLarge } = this.props;
      const { showSidebar } = this.state;
      return (
        <WrappedComponent
          isSinglePanel={isSinglePanel}
          isLarge={isLarge}
          showSidebar={showSidebar}
          toggleSidebar={this.toggleSidebar}
          {...this.props}
        />
      );
    }
  }

  return withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
      isLarge: width >= breakpoints.large,
    };
  })(WithSidebar);
}

export default withSidebar;
