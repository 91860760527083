import generateActionTypes from "./generateActionTypes";
import generateActionGenerators from "./generateActionGenerators";
import generateSagas from "./generateSagas";
import generateReducers from "./generateReducers";
import generateSelectors from "./generateSelectors";

/**
 * The shape of a container state object
 */
type ContainerState = {
  actionTypes: { [key: string]: string };
  actionGenerators: { [key: string]: string };
  sagas: object;
  reducers: { [key: string]: string };
  selectors: { [key: string]: unknown };
};

/**
 * Generate a container state object from a list of definitions
 *
 * @deprecated This function is part of the old state management system
 *
 * @param definitions - A list of definitions for the container state
 * @param definitions.actions - A list of actions for the container
 * @param definitions.container - The name of the container
 * @param definitions.crudActions - A list of CRUD actions for the container
 * @param definitions.idPropsVar - The name of the variable that contains the ID props
 * @param definitions.recordType - The name of the record type
 * @param definitions.schema - The name of the schema
 * @param definitions.shape - The name of the shape
 *
 * @returns A container state object
 *
 */
export default (
  definitions: Readonly<{
    actions: string[];
    container: string;
    crudActions: string[];
    idPropsVar: string;
    recordType: string;
    schema: string;
    shape: string;
  }>[],
) => {
  return definitions.reduce(
    (
      result,
      {
        actions,
        container,
        crudActions,
        idPropsVar,
        recordType,
        schema,
        shape,
      },
    ) => {
      const actionTypes = generateActionTypes({
        container,
        actions: crudActions,
        recordType,
      });
      const actionGenerators = generateActionGenerators(actionTypes);
      const sagas = generateSagas({
        actionGenerators,
        schema,
        actions,
      });
      const reducers = generateReducers({
        actionTypes,
        crudActions,
        recordType,
      });
      const selectors = generateSelectors({
        container,
        idPropsVar,
        schema,
        shape,
      });
      return {
        actionTypes: { ...result.actionTypes, ...actionTypes },
        actionGenerators: { ...result.actionGenerators, ...actionGenerators },
        sagas: { ...result.sagas, ...sagas },
        reducers: { ...result.reducers, ...reducers },
        selectors: { ...result.selectors, ...selectors },
      } satisfies ContainerState;
    },
    {} as ContainerState,
  );
};
