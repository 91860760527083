import { useCallback, useState } from "react";

export const usePromise = <T, R>() => {
  const [resolve, setResolve] = useState<
    undefined | ((value: R | undefined) => void)
  >(undefined);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [parameters, setParameters] = useState<T | undefined>(undefined);

  const handleOpen = useCallback((_props: T): Promise<R> => {
    setOpen(true);
    setParameters(_props);

    return new Promise((_resolve, _reject) => {
      setResolve(() => {
        return _resolve;
      });
    });
  }, []);

  const handleClose = useCallback(
    (data: R | undefined) => {
      return () => {
        if (resolve) {
          resolve(data ?? undefined);
          setResolve(undefined);
        }

        setOpen(false);
      };
    },
    [resolve],
  );

  return {
    isOpen,
    open: handleOpen,
    close: handleClose,
    parameters,
  };
};
