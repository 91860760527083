import { Component } from "react";
import PropTypes from "prop-types";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import Tooltip from "@mui/material/Tooltip";

export default class DictationOption extends Component {
  static propTypes = {
    addSpeechTranscriptToBody: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isSpeechRecognitionActive: false,
    };

    if ("webkitSpeechRecognition" in window) {
      // eslint-disable-next-line no-undef, new-cap
      this.speechRecognition = new webkitSpeechRecognition();
      this.speechRecognition.continuous = false;
      this.speechRecognition.interimResults = true;
      this.speechRecognition.onstart = this.handleSpeechRecognitionStart;
      this.speechRecognition.onend = this.handleSpeechRecognitionEnd;
      this.speechRecognition.onresult = this.handleSpeechRecognitionResult;
    }
  }

  handleSpeechRecognitionClick = () => {
    return this.state.isSpeechRecognitionActive
      ? this.speechRecognition.stop()
      : this.speechRecognition.start();
  };

  handleSpeechRecognitionStart = () => {
    this.setState({ isSpeechRecognitionActive: true });
  };

  handleSpeechRecognitionEnd = () => {
    this.setState({ isSpeechRecognitionActive: false });
  };

  handleSpeechRecognitionResult = (event) => {
    const result = event.results[0];
    if (!result.isFinal) return;
    this.props.addSpeechTranscriptToBody(result[0].transcript);
  };

  render() {
    if (!this.speechRecognition) return null;
    const { isSpeechRecognitionActive } = this.state;
    return (
      <Tooltip title={this.props.disabled ? undefined : "Dictation"}>
        <Badge
          badgeContent={isSpeechRecognitionActive ? "REC" : null}
          color="error"
        >
          <IconButton
            disabled={this.props.disabled === true}
            onClick={this.handleSpeechRecognitionClick}
            aria-label="dictation"
            size="large"
          >
            {isSpeechRecognitionActive ? <MicIcon /> : <MicOffIcon />}
          </IconButton>
        </Badge>
      </Tooltip>
    );
  }
}
