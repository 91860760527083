import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { actionGenerators, saga } from "./state";
import Main from "./components/Main";
import injectSaga from "utils/injectSaga";

class ResetPassword extends Component {
  render() {
    return <Main {...this.props} />;
  }
}

const withConnect = connect(
  () => {
    return {};
  },
  {
    ...actionGenerators,
  },
);

const withSaga = injectSaga({ key: "ResetPasswordContainer", saga });

export default compose(withConnect, withSaga)(ResetPassword);
