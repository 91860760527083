import { differenceInSeconds } from "date-fns";

export default () => {
  const { server_time: serverTime = "" } = window;

  const serverTimeDate = new Date(serverTime);
  const currentTime = new Date();

  const difference = differenceInSeconds(serverTimeDate, currentTime);
  const clockedServerDiff = Math.abs(difference);

  return clockedServerDiff > 300;
};
