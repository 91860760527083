import microdataParser from "microdata-node";
import MetadataStrategy from "phoneExtraction/MetadataStrategy";
import { flattenMicrodata } from "./getMicrodata";

export default class MicrodataStrategy extends MetadataStrategy {
  constructor({ parsedHtml, location }) {
    super({ name: "MicrodataStrategy", parsedHtml, location });

    this.location = location;
    this.parsedHtml = parsedHtml;
    this.contacts = this.getContacts(parsedHtml, location);
  }

  getContacts = (doc, location = null) => {
    const { items: parsedMicrodata } = microdataParser.toJson(
      doc.body.innerHTML,
      location?.href || "",
    );

    const contacts = parsedMicrodata
      .map(flattenMicrodata)
      .filter(
        ({ type, telephone }) =>
          type === "https://schema.org/Person" && telephone?.length > 0,
      );

    const sanitizedContacts = contacts.map(this.sanitizeProps);
    return sanitizedContacts;
  };
}
