import { Component } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";
import get from "lodash/get";

import ProfileSetting from "./ProfileSetting";
import P from "components/P";

import {
  ChangePasswordValidationSchema,
  CreatePasswordValidationSchema,
} from "formHelpers/validationSchemas";

const Message = styled.div`
  display: flex;

  [role="img"] {
    font-size: 24px;
    margin-right: 15px;
  }
`;

export default class ChangePassword extends Component {
  static propTypes = {
    currentUser: PropTypes.object.isRequired,
  };

  getDescription = () => {
    const { currentUser } = this.props;
    const hasPassword = get(currentUser, ["hasPassword"]);
    const message = hasPassword
      ? "You can update your password here anytime. Just confirm your old password and enter a new one. If you forgot your password you can log out and request to reset it on the login form."
      : "It looks like you have not setup a password yet! Once you create one you can always come back to this page to change it.";
    return (
      <Message>
        <span role="img" aria-label="information">
          ℹ️
        </span>
        <P>{message}</P>
      </Message>
    );
  };

  getRender = ({ label, disabled }) => {
    return ({ touched, errors }) => {
      return function ({ field, form }) {
        const { name } = field;
        return (
          <TextField
            {...field}
            fullWidth
            variant="outlined"
            error={touched[name] && Boolean(errors[name])}
            helperText={touched[name] && errors[name]}
            id={`Profile-ChangePassword-${camelCase(label)}`}
            data-testid={`profile-changePassword-${camelCase(label)}`}
            type="password"
            label={label}
            inputProps={{ "data-lpignore": true }}
            disabled={disabled}
            onChange={({ target: { value } }) => {
              form.setFieldValue(name, value);
            }}
          />
        );
      };
    };
  };

  render() {
    const { currentUser } = this.props;
    const hasPassword = get(currentUser, ["hasPassword"]);
    const settings = [
      ...(hasPassword
        ? [
            {
              name: "currentPassword",
              default: "",
              render: this.getRender({
                label: "Current Password",
              }),
            },
          ]
        : []),
      {
        name: "newPassword",
        default: "",
        render: this.getRender({
          label: "New Password",
        }),
      },

      {
        name: "confirmNewPassword",
        default: "",
        render: this.getRender({
          label: "Confirm New Password",
        }),
      },
    ];
    return (
      <ProfileSetting
        {...this.props}
        description={this.getDescription()}
        settings={settings}
        title="Change Password"
        validationSchema={
          hasPassword
            ? ChangePasswordValidationSchema
            : CreatePasswordValidationSchema
        }
      />
    );
  }
}
