import defaults from "./defaults";

const fadeHide = `
    visibility: hidden;
    opacity: 0;
`;

const fadeShow = `
    visibility: visible;
    opacity: 1;
`;

const fadeInAnimation = `
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
`;

const fadeAndSlideUpInAnimation = `
  @keyframes fadeAndSlideUpIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

const fadeAndScaleInAnimation = `
  @keyframes fadeAndScaleIn {
    from {
      opacity: 0;
      transform: scale(1.2);
    }

    to {
      opacity: 1;
      transform: scale(1);
    }
  }
`;

const slideIn = ({ delay = 0 } = {}) => {
  return `
    ${fadeShow};
    transform: translateX(0px);
    transition-delay: ${delay}ms;
`;
};

const slideOut = ({ direction = "left", delay = 0 } = {}) => {
  return `
    ${fadeHide};
    transform: translateX(${direction === "left" ? "-20" : "20"}px);
    transition-delay: ${delay}ms;
`;
};

const slideUpOut = ({ delay = 0 } = {}) => {
  return `
    ${fadeHide};
    transform: translateY(20px);
    transition-delay: ${delay}ms;
`;
};

const coverParent = `
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const flexCenter = ({ inline } = { inline: false }) => {
  return `
    display: ${inline ? "inline-flex" : "flex"};
    justify-content: center;
    align-items: center;
`;
};

const absoluteCenter = `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const ellipsis = `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const border = ({ color, width = defaults.borderWidth } = {}) => {
  return `${width}px solid ${color}`;
};

const boxShadow = ({ color = "rgba(0, 0, 0, 0.25)" } = {}) => {
  return `0 1px 10px ${color}`;
};

const transition = ({
  property = defaults.transitionProperty,
  easing = "ease",
  timing = "0.3s",
} = {}) => {
  return `${property} ${easing} ${timing}`;
};

const darkBgOnHover = `
  &:before {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 0;
  }
`;

export default {
  absoluteCenter,
  border,
  boxShadow,
  coverParent,
  darkBgOnHover,
  ellipsis,
  fadeHide,
  fadeShow,
  fadeInAnimation,
  fadeAndSlideUpInAnimation,
  fadeAndScaleInAnimation,
  flexCenter,
  transition,
  slideIn,
  slideOut,
  slideUpOut,
};
