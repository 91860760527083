import { Box, Grid, Theme, useMediaQuery } from "@mui/material";
import CampaignMetric from "./CampaignMetric";
import { CampaignVariantCardProps } from "./types";
import CampaignMessage from "./CampaignMessage";

const METRICS = ["recipients", "responseRate"] as const;

function CampaignVariantCard({
  campaign,
  campaignTemplate,
}: CampaignVariantCardProps) {
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const {
    attachments: { members: attachments = [] } = {},
    deliveryStatistics,
    registeredLinks: { members: registeredLinks = [] } = {},
  } = campaignTemplate;

  const { user } = campaign;
  const { replied, total } = deliveryStatistics;

  const totalClickCount = registeredLinks.reduce((sum, { clickCount }) => {
    return sum + clickCount;
  }, 0);

  const campaignAnalytics = {
    recipients: total || 0,
    responseRate: replied / total || 0,
    totalClicks: totalClickCount || 0,
    clickThroughRate: totalClickCount / total || 0,
  };

  const metrics = [
    ...METRICS,
    ...(registeredLinks.length > 0
      ? (["totalClicks", "clickThroughRate"] as const)
      : []),
  ] as const;

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={mobileScreen ? "column" : "row"}
    >
      <Box display="flex" justifyContent="flex-start" padding="1rem 0.5rem">
        <CampaignMessage
          campaign={{ ...campaign, ...campaignTemplate }}
          campaignsProUser={typeof user === "string" ? undefined : user}
          attachment={attachments?.length ? attachments[0] : undefined}
        />
      </Box>
      <Box
        sx={(theme) => {
          return {
            backgroundColor: theme.palette.background.paper,
            width: "40%",
            maxWidth: "40rem",
          };
        }}
        p={2}
      >
        <Grid container>
          {metrics.map((metric) => {
            return (
              <Grid item xs={12} md={6} key={metric} maxWidth="600px">
                <CampaignMetric
                  campaignAnalytics={campaignAnalytics}
                  metric={metric}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
}

export default CampaignVariantCard;
