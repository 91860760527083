import { all, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import get from "lodash/get";

import * as schema from "schema";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import { actionGenerators as quickComposerActionGenerators } from "features/Compose/containers/QuickComposer/state";
import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";
import generateContainerState from "utils/generateContainerState";
import generateSagas from "utils/generateContainerState/generateSagas";

const {
  actionTypes: crudActionTypes,
  actionGenerators: crudActionGenerators,
  sagas: crudSagas,
  reducers,
  selectors,
} = generateContainerState([
  {
    container: "ConversationPreview",
    crudActions: ["fetch"],
    idPropsVar: "conversationId",
    recordType: "conversation",
    schema: schema.conversation,
    shape: {
      account: {},
      assignedContact: { notes: {}, phones: { members: [] } },
      associatedContacts: { members: [{ notes: {}, phones: { members: [] } }] },
      followers: { members: [] },
      participants: { members: [] },
      requestingUserInfo: {},
      timeline: {
        members: [
          { schema: "ConversationEvent", user: {}, assignee: {} },
          { schema: "Message", attachments: { members: [] }, sender: {} },
        ],
      },
    },
  },
]);

// ACTION TYPES
const timelineActionTypes = generateActionTypes({
  container: "ConversationPreview",
  actions: ["paginate"],
  recordType: "timeline",
});
const actionTypes = {
  ...crudActionTypes,
  ...timelineActionTypes,
};

// ACTION GENERATORS
const timelineActionGenerators = generateActionGenerators(timelineActionTypes);
const actionGenerators = {
  ...crudActionGenerators,
  ...timelineActionGenerators,
};

// SAGAS
const timelineSagas = generateSagas({
  actionGenerators: timelineActionGenerators,
  getRequestMethod: () => {
    return "GET";
  },
  schema: schema.timeline,
  shouldOptimisticallyUpdate: () => {
    return false;
  },
});

function* fetchConversationFailureSaga() {
  yield put(quickComposerActionGenerators.setSignatureState(true));
}

function* fetchConversationSuccessSaga({ response }) {
  const currentUser = yield select(selectCurrentUser);
  const getSender = (id) => {
    return response.participants.members.find((participant) => {
      return participant.id === id;
    });
  };
  const latestOutboundMessage = response.timeline.members.find((member) => {
    return (
      !!member.sender && !get(getSender(member.sender), ["automatedActor"])
    );
  });
  const payload = get(latestOutboundMessage, ["sender"]) !== currentUser.id;
  yield put(quickComposerActionGenerators.setSignatureState(payload));
}

const sagas = {
  ...crudSagas,
  ...timelineSagas,
};

function* saga() {
  yield all([
    takeLatest(
      actionTypes.FETCH_CONVERSATION_REQUEST,
      sagas.fetchConversationRequest,
    ),
    takeLatest(
      actionTypes.FETCH_CONVERSATION_FAILURE,
      fetchConversationFailureSaga,
    ),
    takeLatest(
      actionTypes.FETCH_CONVERSATION_SUCCESS,
      fetchConversationSuccessSaga,
    ),
    takeLatest(
      actionTypes.PAGINATE_TIMELINE_REQUEST,
      sagas.paginateTimelineRequest,
    ),
  ]);
}

// REDUCERS
const returnedConversationId = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONVERSATION_REQUEST:
      return null;
    case actionTypes.FETCH_CONVERSATION_SUCCESS:
      return action.response.id;
    default:
      return state;
  }
};

const isUnsubscribed = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CONVERSATION_REQUEST:
      return null;
    case actionTypes.FETCH_CONVERSATION_SUCCESS:
      return action.response.unsubscribed;
    default:
      return state;
  }
};

const isPaginating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.PAGINATE_TIMELINE_REQUEST:
      return true;
    case actionTypes.PAGINATE_TIMELINE_SUCCESS:
      return false;
    case actionTypes.PAGINATE_TIMELINE_FAILURE:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  ...reducers,
  returnedConversationId,
  isUnsubscribed,
  isPaginating,
});

export { actionTypes, actionGenerators, saga, sagas, reducer, selectors };
