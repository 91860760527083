/**
 * Create the store with dynamic reducers
 */
import { createStore, applyMiddleware, compose } from "redux";
import { fromJS } from "immutable";
import { routerMiddleware } from "connected-react-router/immutable";
import createSagaMiddleware from "redux-saga";
import createReducer from "./reducers";

// eslint-disable-next-line default-param-last
export default function configureStore(initialState = {}, history) {
  const sagaMiddleware = createSagaMiddleware();
  // Create the store with two middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  const defaultMiddlewares = [sagaMiddleware, routerMiddleware(history)];

  // Only add tracker middleware if not in test mode
  const middlewares =
    process.env.NODE_ENV === "test"
      ? defaultMiddlewares
      : [...defaultMiddlewares];

  const enhancers = [applyMiddleware(...middlewares)];

  // If Redux DevTools Extension is installed use it, otherwise use Redux compose

  const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Prevent recomputing reducers for `replaceReducer`
          shouldHotReload: false,
          trace: process.env.NODE_ENV !== "production",
        })
      : compose;

  const store = createStore(
    createReducer(),
    fromJS(initialState),
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  return store;
}
