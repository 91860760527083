import assert from "assert";
import { userManager, SequenceStates } from "@tesseract/core";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const updateSequenceState = async (
  currentAccount: AccountType,
  sequenceId: string,
  state: SequenceStates,
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequences/${sequenceId}/state`;

  const request = new Request(url, {
    method: "PUT",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      state,
    }),
  });

  return fetch(request);
};
