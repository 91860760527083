import MetadataStrategy from "phoneExtraction/MetadataStrategy";

export default class JsonLinkedDataStrategy extends MetadataStrategy {
  constructor({ parsedHtml, location }) {
    super({ name: "JsonLinkedDataStrategy" });

    this.location = location;
    this.parsedHtml = parsedHtml;
    this.contacts = this.getContacts(parsedHtml);
  }

  getContacts = doc => {
    const jsonldScripts = doc.querySelectorAll(
      'script[type="application/ld+json"]',
    );

    const linkedDataCollection = Array.from(jsonldScripts)
      .map(({ textContent }) => {
        try {
          return JSON.parse(textContent);
        } catch (error) {
          return null;
        }
      })
      .filter(i => i);

    const contacts = linkedDataCollection.reduce((acc, item) => {
      const { "@type": type, itemListElement } = item;
      if (type === "Person") {
        return [...acc, item];
      }
      if (type === "ItemList") {
        const itemList = itemListElement.filter(
          ({ "@type": itemType }) => itemType === "Person",
        );
        return [...acc, ...itemList];
      }
      return acc;
    }, []);

    const sanitizedContacts = contacts.map(this.sanitizeProps);
    return sanitizedContacts;
  };
}
