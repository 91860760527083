import { SvgIcon, SvgIconProps } from "@mui/material";

export default function CampaignIcon(props: SvgIconProps) {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M18.8333 9.53125C18.8333 8.83896 18.4586 8.24102 17.9074 7.91641V3.43779C17.9074 3.18145 17.7054 2.5 16.9814 2.5C16.7754 2.5 16.5709 2.56973 16.4033 2.70566L13.943 4.69873C12.7071 5.69893 11.1556 6.25 9.57403 6.25H4.01848C2.99562 6.25 2.16663 7.08936 2.16663 8.125V10.9375C2.16663 11.9731 2.99562 12.8125 4.01848 12.8125H4.99359C4.95337 13.1195 4.93052 13.4318 4.93052 13.75C4.93052 14.9151 5.19845 16.0161 5.6701 17.0002C5.82027 17.3134 6.14811 17.5 6.49186 17.5H8.64116C9.39493 17.5 9.84747 16.6258 9.39058 16.0187C8.91605 15.3883 8.63393 14.602 8.63393 13.75C8.63393 13.4245 8.6808 13.1116 8.76153 12.8125H9.57403C11.1556 12.8125 12.7071 13.3636 13.9427 14.3638L16.403 16.3568C16.5671 16.4898 16.771 16.5623 16.9812 16.5625C17.7022 16.5625 17.9071 15.8951 17.9071 15.625V11.1464C18.4586 10.8215 18.8333 10.2235 18.8333 9.53125ZM16.0555 13.6744L15.0992 12.8998C13.5367 11.6342 11.574 10.9375 9.57403 10.9375V8.125C11.574 8.125 13.5367 7.42832 15.0992 6.1627L16.0555 5.38809V13.6744Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
