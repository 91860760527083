const getItem = (item, { fallback } = {}) => {
  if (!global.sessionStorage) return fallback;
  return JSON.parse(global.sessionStorage.getItem(item)) || fallback;
};

const setItem = ({ item, value }) => {
  if (global.sessionStorage && value !== undefined) {
    global.sessionStorage.setItem(item, JSON.stringify(value));
  }
};

export default { getItem, setItem };
