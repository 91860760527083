import { Button, Grid } from "@mui/material";
import type { BullhornCandidate } from "@tesseract/core";

import { useState } from "react";
import { Props } from "./types";
import {
  BullhornCandidateDetails,
  BullhornCandidateAutocomplete,
} from "features/ContactSync";

export function BullhornCandidate({
  contact,
  currentAccount,
  onChanged,
  onContactUnlink,
}: Props) {
  const [selectedCandidate, setSelectedCandidate] =
    useState<BullhornCandidate.Raw>(contact.data.bullhorn!.Candidate!);

  return contact.data.bullhorn!.Candidate ? (
    <>
      <Grid
        sx={{
          alignItems: "flex-start",
          display: "grid",
          gap: 2,
          gridTemplateColumns: "1fr auto",
          marginBottom: 2,
          paddingTop: 1,
        }}
      >
        <BullhornCandidateAutocomplete
          account={currentAccount.id}
          contact={contact}
          fullWidth
          value={selectedCandidate}
          onChange={(_, value) => {
            setSelectedCandidate(value ?? selectedCandidate);
            onChanged(value ?? selectedCandidate);
          }}
        />
        <Button
          sx={{
            marginTop: "13px",
          }}
          color="error"
          variant="outlined"
          onClick={() => {
            return onContactUnlink("bullhorn");
          }}
        >
          Unlink
        </Button>
      </Grid>

      <BullhornCandidateDetails candidate={selectedCandidate} />
    </>
  ) : null;
}
