import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ConversationFilterOptionStyle from "features/Inbox/styles/ConversationFilterOptionStyle";

export default styled(NavLink)`
  ${ConversationFilterOptionStyle};
  max-height: 40px;

  padding: 0.75rem 1rem;

  &.active {
    background: ${(props) => {
      return props.theme.colors.action.hover;
    }};
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }

  &.emphasized {
    font-weight: 600;
  }
`;
