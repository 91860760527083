import SvgIcon from "@mui/material/SvgIcon";

export default function (props) {
  return (
    <SvgIcon {...props} viewBox="0 0 80 68">
      <path d="M38.9879748,0.0274537815 C17.453395,0.0274537815 0.0119243697,14.8239328 0.0119243697,33.0901261 C0.0119243697,44.1345882 6.41503361,53.9206134 16.2152017,59.9304958 C16.2152017,60.6662017 15.975605,64.9256975 9.84148739,67.0188403 C9.84148739,67.0188403 18.4963613,69.6141933 25.3548151,64.0818403 C29.588521,65.4223613 34.1882773,66.1658319 38.9879748,66.1658319 C60.4784622,66.1658319 77.933521,51.3416218 77.933521,33.0904034 C77.933521,14.8239328 60.4784622,0.0274537815 38.9879748,0.0274537815 Z" />
      <path
        d="M17.8760168,40.2228403 C14.5122353,40.2228403 11.7843277,37.5104622 11.7843277,34.1447395 C11.7843277,30.8073025 14.5122353,28.0915966 17.8760168,28.0915966 C21.1962605,28.0915966 23.9186218,30.8070252 23.9186218,34.1447395 C23.9186218,37.5104622 21.1962605,40.2228403 17.8760168,40.2228403 Z"
        fill="white"
      />
      <path
        d="M39.2605714,40.2228403 C35.9142605,40.2228403 33.2174118,37.5104622 33.2174118,34.1447395 C33.2174118,30.8073025 35.9142605,28.0915966 39.2605714,28.0915966 C42.5930168,28.0915966 45.3278571,30.8070252 45.3278571,34.1447395 C45.3278571,37.5104622 42.5930168,40.2228403 39.2605714,40.2228403 Z"
        fill="white"
      />
      <path
        d="M60.6983697,40.2228403 C57.3345882,40.2228403 54.615,37.5104622 54.615,34.1447395 C54.615,30.8073025 57.3345882,28.0915966 60.6983697,28.0915966 C64.0269328,28.0915966 66.7448571,30.8070252 66.7448571,34.1447395 C66.7448571,37.5104622 64.0269328,40.2228403 60.6983697,40.2228403 Z"
        fill="white"
      />
    </SvgIcon>
  );
}
