import styled from "styled-components";

export default styled.div`
  ${(props) => {
    return props.theme.mixins.coverParent;
  }};
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  flex-flow: column nowrap;
  padding: 40px;
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  text-align: center;
  z-index: 100;

  span {
    font-size: 60px;
    display: block;
    text-align: center;
    animation: fadeAndSlideUpIn cubic-bezier(0.175, 0.885, 0.32, 1.275) 1s;
  }

  p {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
    animation: fadeIn cubic-bezier(0.175, 0.885, 0.32, 1.275) 2s;
  }

  ${(props) => {
    return props.theme.mixins.fadeInAnimation;
  }};
  ${(props) => {
    return props.theme.mixins.fadeAndSlideUpInAnimation;
  }};
`;
