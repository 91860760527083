import PropTypes from "prop-types";
import queryString from "query-string";
import debounce from "lodash/debounce";
import get from "lodash/get";

import TopLevelPageSearch from "components/TopLevelPage/Search";
import TopLevelPageSearchBar from "components/TopLevelPage/SearchBar";

export function AccountSearch({ history, location }) {
  const { q: initialSearchTerm = "" } = queryString.parse(
    get(location, ["search"], ""),
  );

  const searchHandler = debounce((searchTerm) => {
    const search = searchTerm
      ? queryString.stringify({
          q: searchTerm,
        })
      : "";
    history.push({
      pathname: location.pathname,
      search,
    });
  }, 1000);

  const clearHandler = () => {
    searchHandler("");
  };

  return (
    <TopLevelPageSearchBar>
      <TopLevelPageSearch
        initialSearchTerm={initialSearchTerm}
        placeholder="Search accounts by name or phone number"
        clearHandler={clearHandler}
        searchHandler={searchHandler}
      />
    </TopLevelPageSearchBar>
  );
}

AccountSearch.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default AccountSearch;
