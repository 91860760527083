import H3 from "components/H3";
import H5 from "components/H5";
import StatusOverlay from "features/Authentication/components/Overlay";

export default function (props) {
  return (
    <StatusOverlay {...props}>
      <span role="img" aria-label="successfully sent mail">
        ✨
      </span>
      <H3>Your password has been updated</H3>
      <H5>
        You will be redirected to the login screen shortly. Enter your new
        password to be logged in. Enjoy using TextUs!
      </H5>
    </StatusOverlay>
  );
}
