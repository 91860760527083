import { Component } from "react";
import MainView from "./components/MainView";
import withRecord from "higherOrderComponents/withRecord";

class AccountAnalyticRow extends Component {
  render() {
    return <MainView {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "containers/AccountAnalyticRow",
  type: "accountAnalytics",
  multiple: true,
})(AccountAnalyticRow);
