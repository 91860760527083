import { useEffect } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Menu,
  Tooltip,
  Typography,
} from "@mui/material";
import { FilterList, Edit } from "@mui/icons-material";
import { LicenseGridToolbarProps } from "../models/AccountPlanModels";
import { useLicenseGridToolbar } from "../hooks/useLicenseGridToolbar";
import { SearchBox } from "components/SearchBox";

function LicenseGridToolbar({
  isChecked,
  getSearchedRows,
  handleCheck,
  multipleAccountsSelected,
  searchTerm,
  setSearchTerm,
  toggleModal,
}: LicenseGridToolbarProps) {
  const {
    anchorEl,
    checkedCount,
    checkedString,
    debouncedSearch,
    open,
    handleClearSearch,
    handleCloseMenu,
    handleOpenMenu,
    handleSearch,
  } = useLicenseGridToolbar(isChecked, setSearchTerm);

  useEffect(() => {
    if (debouncedSearch) {
      getSearchedRows(debouncedSearch);
    }
  }, [getSearchedRows, debouncedSearch]);

  const menuItems = [
    {
      label: "Campaigns Pro",
      id: "campaigns",
      checked: isChecked.campaigns,
    },
    {
      label: "Keywords",
      id: "keywords",
      checked: isChecked.keywords,
    },
    {
      label: "Sequences",
      id: "sequences",
      checked: isChecked.sequences,
    },
    {
      label: "None",
      id: "none",
      checked: isChecked.none,
    },
  ];

  const renderMenuItems = () => {
    return menuItems.map((item) => {
      return (
        <FormControlLabel
          key={item.id}
          control={
            <Checkbox
              checked={item.checked}
              onChange={handleCheck}
              id={item.id}
              data-testid={`${item.id}-license-grid-filter`}
            />
          }
          label={item.label}
        />
      );
    });
  };

  const getTooltipText = () => {
    return checkedCount > 0 ? (
      <Box>
        <Typography>
          {checkedCount}{" "}
          {`active filter ${checkedCount === 1 ? "license" : "licenses"}:`}
        </Typography>
        <Typography>{checkedString}</Typography>
      </Box>
    ) : (
      ""
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      width="100%"
    >
      <Box padding={1}>
        <Tooltip title={getTooltipText()}>
          <Button
            onClick={handleOpenMenu}
            startIcon={
              <Badge badgeContent={checkedCount} color="secondary">
                <FilterList />
              </Badge>
            }
            variant="text"
            size="small"
            data-testid="open-filter-licenses-grid-menu"
          >
            Filter Licenses
          </Button>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          slotProps={{
            root: {
              slotProps: {
                backdrop: {
                  sx: { backgroundColor: "transparent", boxShadow: "none" },
                },
              },
            },
          }}
        >
          <FormGroup sx={{ padding: "0rem 2.75rem 0rem 1.25rem" }}>
            <Box display="flex" flexDirection="column">
              {renderMenuItems()}
            </Box>
          </FormGroup>
        </Menu>
        <Button
          disabled={multipleAccountsSelected}
          startIcon={<Edit />}
          sx={{ marginLeft: "0.25rem" }}
          variant="text"
          onClick={toggleModal}
          data-testid="bulk-manage-licenses"
        >
          Bulk Manage
        </Button>
      </Box>
      <Box padding={1}>
        <Box width="25rem" margin={1}>
          <SearchBox
            onClear={handleClearSearch}
            onChange={handleSearch}
            value={searchTerm}
            placeholder="Search accounts by name or phone number"
            variant="standard"
            sx={{ height: "1rem" }}
            data-testid="license-grid-search"
          />
        </Box>
      </Box>
    </Box>
  );
}
export { LicenseGridToolbar };
