import { Component } from "react";
import styled, { css, withTheme } from "styled-components";
import PropTypes from "prop-types";

const GradientStyles = css`
  path {
    fill: url("#BlueGreenGradient");
  }
`;

const Root = styled.svg`
  fill: ${(props) => {
    return props.fill;
  }};
  ${(props) => {
    return props.gradient && GradientStyles;
  }};
`;

class Icon extends Component {
  static propTypes = {
    className: PropTypes.string,
    gradient: PropTypes.bool,
    icon: PropTypes.string.isRequired,
    iconColor: PropTypes.string,
    iconSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    theme: PropTypes.object.isRequired,
    viewBox: PropTypes.string,
  };

  static defaultProps = {
    iconSize: "26",
    viewBox: "0 0 1024 1024",
  };

  render() {
    const {
      theme,
      icon,
      iconColor = theme.colors.text.secondary,
      iconSize,
      viewBox,
      gradient,
      className,
    } = this.props;
    return (
      <Root
        width={iconSize}
        height={iconSize}
        viewBox={viewBox}
        gradient={gradient}
        fill={iconColor}
        className={className}
      >
        <defs>
          <linearGradient id="BlueGreenGradient">
            <stop offset="5%" stopColor="#8AC833" />
            <stop offset="95%" stopColor="#5CB5BD" />
          </linearGradient>
        </defs>
        <path d={icon} />
      </Root>
    );
  }
}

export default withTheme(Icon);
