import { Component } from "react";
import Root from "features/ContactModal/containers/ContactFormTags/components/Root";
import withRecord from "higherOrderComponents/withRecord";

class ContactFormTags extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch"],
  container: "/containers/ContactFormTags",
  noFetch: true,
  shape: { members: [] },
  showLoader: () => {
    return false;
  },
  type: "contactTags",
})(ContactFormTags);
