import type { Contact } from "@tesseract/core";
import { identifyContact } from "@tesseract/core/src/models/Contact";
import { textUsApiV3 } from "services";

export const fetchContact = (
  contact: Contact.Like,
  options?: RequestInit,
): Promise<Response> => {
  return textUsApiV3.get(
    `/contacts/${identifyContact(contact)}`,
    undefined,
    options,
  );
};
