import { useSnackbar } from "notistack";
import { useState } from "react";
import { exportCampaignFilter } from "../api";

export const useCampaignExport = (campaignFilter: any) => {
  // ==== HOOKS ==== //
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  // ==== METHODS ==== //
  const handleExport = async () => {
    try {
      setIsLoading(true);

      await exportCampaignFilter(campaignFilter.export);

      enqueueSnackbar(
        "We're exporting your campaign filter. You should receive an email shortly!",
        { variant: "info" },
      );

      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar("Something went wrong, please try again.", {
        variant: "error",
      });

      setIsLoading(false);

      console.error("Error exporting campaign filter", error);
    }
  };

  return {
    isLoading,
    handleExport,
  };
};
