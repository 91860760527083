import { isEqual, snakeCase } from "lodash";
import {
  dayToSendOptions,
  sendFrequencyOptions,
  timePeriods,
} from "../constants/DateOptions";
import { DayToSendKeys, SendFrequencyKeys } from "../models/AnalyticsModels";

export const formatValues = (
  schedule: string,
  timePeriod: SendFrequencyKeys,
): {
  formattedDayToSend: string;
  formattedSendFrequency: string;
  formattedTimePeriod: string;
} => {
  const [frequency, sendDate] = schedule.split(";BY").map((param) => {
    return param.split(/=(.+)/)[1];
  });
  const { name: formattedSendFrequency = "" } =
    sendFrequencyOptions[timePeriod].find(({ slug }: { slug: string }) => {
      return slug.includes(frequency);
    }) ?? {};
  const sendFrequency = snakeCase(formattedSendFrequency) as DayToSendKeys;
  const { name: formattedDayToSend = "" } =
    dayToSendOptions[sendFrequency].find(({ slug }: { slug: string }) => {
      return slug.includes(sendDate);
    }) ?? {};
  const { name: formattedTimePeriod = "" } =
    timePeriods.find(({ slug }: { slug: string }) => {
      return isEqual(slug, timePeriod);
    }) ?? {};
  return {
    formattedDayToSend,
    formattedSendFrequency,
    formattedTimePeriod,
  };
};
