import styled from "styled-components";

export default styled.div`
  background: ${(props) => {
    if (
      props.direction === "out" &&
      (props.messageSource === "hubspot" ||
        props.messageSource === "campaignsAppendedMessagePreview" ||
        props.messageSource === "campaigns")
    ) {
      return props.theme.colors.message.hubspotOutboundColor;
    }
    return props.direction === "in"
      ? props.theme.colors.message.inboundBackground
      : props.theme.colors.message.outboundBackground;
  }};
  border-radius: ${(props) => {
    return props.theme.defaults.bubbleBorderRadius;
  }};
  color: ${(props) => {
    return props.direction === "in"
      ? props.theme.colors.message.inboundColor
      : props.theme.colors.message.outboundColor;
  }};
  font-size: ${(props) => {
    return props.onlyEmoji
      ? props.theme.fonts.xLargeHeaderFontSize
      : props.theme.fonts.messageFontSize;
  }};
  height: 100%;
  padding: 8px
    ${(props) => {
      return props.theme.defaults.bubbleBorderRadius;
    }};
  word-wrap: anywhere;
  p {
    margin-top: 0;
    overflow-wrap: anywhere;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      color: ${(props) => {
        return props.direction === "in"
          ? props.theme.colors.message.inboundColor
          : props.theme.colors.message.outboundColor;
      }};
    }
  }
`;
