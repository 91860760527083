import { call, cancelled, put, select } from "redux-saga/effects";
import { normalize } from "normalizr";
import get from "lodash/get";
import camelCase from "lodash/camelCase";
import snakeCase from "lodash/snakeCase";

import * as schemaDefinitions from "schema";
import { baseUrl, getOptions } from "utils/apiConfig";
import {
  selectRecords,
  selectUser,
} from "features/EntryPoint/containers/App/selectors";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import request from "utils/request";

/**
 * Checks if the given schema is a nested update.
 * @param {object} schema - The schema object to check.
 * @returns {boolean} - True if the schema is a nested update, false otherwise.
 */
const isNestedUpdate = (schema = {}) => {
  return ["contacts", "account", "users", "messages"].includes(schema._key);
};

/**
 * Returns an array of request keys from the given action generators object.
 * @param {Object} actionGenerators - The action generators object.
 * @returns {Array} - An array of request keys.
 */
const getRequestKeys = (actionGenerators) => {
  return Object.keys(actionGenerators).filter((actionGeneratorKey) => {
    return actionGeneratorKey.includes("Request");
  });
};

/**
 * Returns the action generator type that matches the given lifecycle and request type.
 * @param {string} lifecycle - The lifecycle of the action generator.
 * @returns {function} - The action generator type.
 */
const getType = (lifecycle) => {
  return ({ actionGenerators, requestType }) => {
    return Object.keys(actionGenerators).find((actionGeneratorKey) => {
      return actionGeneratorKey.match(
        new RegExp(`^${requestType}.+${lifecycle}`, "gi"),
      );
    });
  };
};

/**
 * Returns the error response object.
 * @param {Error} error - The error object.
 * @returns {Object} - The error response object.
 */
const getErrorResponse = (error) => {
  return (
    error.response || {
      title: "Uh Oh! Something went wrong! Please try again.",
    }
  );
};

/**
 * Determines whether the CRUD operation should optimistically update the state.
 * @param {string} requestType - The type of the CRUD operation.
 * @param {object} schema - The schema object.
 * @returns {boolean} - True if the operation should optimistically update the state, false otherwise.
 */
const crudShouldOptimisticallyUpdate = (requestType, { schema } = {}) => {
  if (isNestedUpdate(schema)) return false;
  return requestType === "update";
};

/**
 * Determines whether a collection should be fetched based on the request type and schema key.
 * @param {Object} options - The options object.
 * @param {string} options.requestType - The type of request.
 * @param {Object} options.schema - The schema object.
 * @returns {boolean} - True if the collection should be fetched, false otherwise.
 */
const shouldFetchCollection = ({ requestType, schema }) => {
  return ["contactFilter"].includes(schema._key) && requestType === "create";
};

/**
 * Returns the HTTP request method based on the given request type.
 *
 * @param {"schedule"|"create"|"export"|"cancel"|"accept"|"fetch"|"refetch"|"update"|"delete"|"add"|"assign"|"block"|"close"|"read"|"reopen"|"unblock"|"unassign"|"unread"|"unstar"|"unsubscribe"|"star"|"subscribe"} requestType - The request type.
 * @returns {"POST"|"GET"|"PATCH"|"DELETE"|"PUT"} - The HTTP request method.
 */
const crudGetRequestMethod = (requestType) => {
  switch (requestType) {
    case "schedule":
    case "create":
    case "export":
    case "cancel":
    case "accept":
      return "POST";
    case "fetch":
    case "refetch":
      return "GET";
    case "update":
      return "PATCH";
    case "delete":
    case "unblock":
    case "unassign":
    case "unread":
    case "unstar":
    case "unsubscribe":
      return "DELETE";
    case "add":
    case "assign":
    case "block":
    case "close":
    case "read":
    case "reopen":
    case "star":
    case "subscribe":
      return "PUT";
    default:
      return "GET";
  }
};

/**
 * Generates revised attributes based on the provided parameters.
 * @param {Object} params - The parameters object.
 * @returns {Object} - The revised attributes object.
 */
const revisedAttributes = (params) => {
  const attributes = Object.keys(params).filter((k) => {
    return params[k];
  });
  return attributes.reduce((prev, attribute) => {
    return {
      ...prev,
      [camelCase(attribute)]: params[attribute],
    };
  }, {});
};

/**
 * Makes a request to the specified URL with the given parameters and method.
 * @param {Object} options - The options for the request.
 * @param {string} options.url - The URL to make the request to.
 * @param {Object} options.params - The parameters to include in the request.
 * @param {string} options.method - The HTTP method to use for the request.
 * @param {AbortSignal} options.signal - The signal object used to abort the request.
 * @returns {Promise} - A promise that resolves with the response from the request.
 */
export function* makeRequest({ url, params, method, signal }) {
  const user = yield select(selectUser);
  const requestUrl = `${baseUrl}${url}`;
  const options = yield getOptions({ method, params, user });
  const requestOptions = { ...options, ...(signal && { signal }) };
  return yield call(request, requestUrl, requestOptions);
}

/**
 * Retrieves the original record with the specified id from the records state.
 * @param {string} id - The id of the record to retrieve.
 * @returns {Object} - The original record object.
 */
export function* crudGetOriginalRecord(id) {
  const records = yield select(selectRecords);
  return records.get(id);
}

/**
 * Performs an optimistic update for CRUD operations.
 *
 * @param {Object} options - The options for the optimistic update.
 * @param {Object} options.originalRecord - The original record to be updated.
 * @param {Object} options.params - The updated parameters for the record.
 * @param {Object} options.schema - The schema for normalizing the updated record.
 * @returns {Generator} A generator function.
 */
export function* crudOptimisticUpdate({ originalRecord, params, schema }) {
  const revisedRecord = {
    id: originalRecord?.get("id"),
    ...revisedAttributes(params),
  };
  const { entities } = normalize(revisedRecord, schema);
  yield put(updateRecords(entities));
}

/**
 * Normalizes and updates records.
 *
 * @param {Object} options - The options for normalizing and updating records.
 * @param {Object} options.record - The record to be normalized.
 * @param {Object} options.schema - The schema for normalizing the record.
 * @returns {Generator} A generator function.
 */
export function* normalizeAndUpdateRecords({ record, schema }) {
  const { entities } = normalize(record, schema);
  yield put(updateRecords(entities));
}

/**
 * Reverts an optimistic update by updating the records with the original data.
 * @param {Object} options - The options for reverting the update.
 * @param {Immutable.Record} options.originalRecord - The original record before the update.
 * @param {Object} options.schema - The schema used for normalizing the record.
 * @returns {Generator} A generator function.
 */
export function* revertOptimisticUpdate({ originalRecord, schema }) {
  if (originalRecord) {
    const { entities } = normalize(originalRecord.toJS(), schema);
    yield put(updateRecords(entities));
  }
}

/* eslint-disable func-names */
/**
 * Generates sagas for handling asynchronous requests.
 *
 * @param {Object} options - The options for generating sagas.
 * @param {Array} options.actions - The array of actions.
 * @param {Object} options.actionGenerators - The action generators.
 * @param {Function} [options.getOriginalRecord] - The function for getting the original record.
 * @param {Function} [options.getRequestMethod] - The function for getting the request method.
 * @param {Function} [options.optimisticUpdate] - The function for optimistic update.
 * @param {Object} options.schema - The schema object.
 * @param {Function} [options.shouldOptimisticallyUpdate] - The function for determining if optimistic update should be performed.
 * @returns {Object} - The generated sagas.
 */
export default ({
  actions = [],
  actionGenerators,
  getOriginalRecord = crudGetOriginalRecord,
  getRequestMethod = crudGetRequestMethod,
  optimisticUpdate = crudOptimisticUpdate,
  schema,
  shouldOptimisticallyUpdate = crudShouldOptimisticallyUpdate,
}) => {
  return getRequestKeys(actionGenerators).reduce((prev, requestKey) => {
    const requestType = snakeCase(requestKey).split("_", 1)[0];
    const method = getRequestMethod(requestType);
    const successType = getType("Success")({ actionGenerators, requestType });
    const failureType = getType("Failure")({ actionGenerators, requestType });
    const saga = function* ({ url, params, options = {} }) {
      const abortController = global.AbortController
        ? new global.AbortController()
        : {};
      let originalRecord;
      try {
        const currentAction = actions.find((action) => {
          return typeof action === "object" && action.type === requestType;
        });
        if (get(currentAction, "optimisticUpdateFunc")) {
          yield* currentAction.optimisticUpdateFunc(url, params);
        }
        if (shouldOptimisticallyUpdate(requestType, { schema })) {
          originalRecord = yield* getOriginalRecord(url);
          yield* optimisticUpdate({
            originalRecord,
            params,
            schema,
            url,
            method,
          });
        }
        const record = yield* makeRequest({
          url,
          params,
          method,
          signal: abortController.signal,
        });
        if (record) yield* normalizeAndUpdateRecords({ record, schema });
        if (shouldFetchCollection({ requestType, schema })) {
          const collectionSchema =
            schemaDefinitions[`${schema._key}Collection`];
          const collection = yield* makeRequest({ url, method: "GET" });
          if (collection)
            yield* normalizeAndUpdateRecords({
              record: collection,
              schema: collectionSchema,
            });
        }
        yield put(actionGenerators[successType](record));
        if (options.successCallback) options.successCallback(record);
      } catch (error) {
        if (shouldOptimisticallyUpdate(requestType))
          yield* revertOptimisticUpdate({ originalRecord, schema });
        const errorResponse = getErrorResponse(error);
        if (options.errorCallback) options.errorCallback(errorResponse);
        yield put(actionGenerators[failureType](errorResponse, url, params));
      } finally {
        if (yield cancelled()) {
          if (typeof abortController.abort === "function") {
            abortController.abort();
          }
        }
      }
    };
    return { ...prev, [requestKey]: saga };
  }, {});
};
