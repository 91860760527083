import isEmpty from "lodash/isEmpty";
import { isAfter } from "date-fns";
import { getCampaignSchedule } from "../sharedComponents/constants/timeOptions";
import { createMessageBody } from "utils/createMessageBody";

const getRecipients = (recipients) => {
  if (!Array.isArray(recipients) || recipients.length === 0) return undefined;

  if (recipients[0]?.["@type"] === "CampaignRecipient") {
    return recipients
      .filter((recipient) => {
        return !!recipient.contactPhone;
      })
      .map((recipient) => {
        return recipient.contactPhone.id;
      });
  }

  if (recipients[0]?.["@type"] === "Contact") {
    return recipients.map(({ phones }) => {
      return phones?.members[0]?.id;
    });
  }

  return recipients.filter((recipient) => {
    return !!recipient.phones.members[0]?.id;
  });
};

export const getMultipleVariantCampaignParams = ({
  autoAssign,
  campaignContactFilter,
  campaignRecipients,
  campaignScheduledAt,
  contactCollection,
  contactImport,
  exclusionTime,
  groupId,
  messageTemplates,
  signatureContent,
  title,
  type,
}) => {
  const contactFilter = isEmpty(campaignContactFilter)
    ? contactImport?.contactFilter?.id
    : campaignContactFilter?.id;
  const scheduledAt = isAfter(campaignScheduledAt, new Date())
    ? campaignScheduledAt.toISOString()
    : undefined;
  const recipients = getRecipients(campaignRecipients);

  const sharedParams = {
    autoAssign,
    contactCollection,
    contactFilter,
    exclusionTime,
    recipients,
    scheduledAt,
    title,
    type,
  };
  if (messageTemplates.length > 1) {
    const formattedMessageTemplates = messageTemplates.map(
      ({ attachments, body, links, signatureActive }) => {
        const campaignMessageBody = createMessageBody(
          body,
          signatureActive,
          signatureContent,
          links,
        );
        return { attachments, body: campaignMessageBody };
      },
    );
    return {
      ...sharedParams,
      contactCollection: groupId ? `${groupId}/contacts` : contactCollection,
      messageTemplates: formattedMessageTemplates,
    };
  }
  if (messageTemplates.length === 1) {
    const [{ attachments, body, links, signatureActive }] = messageTemplates;
    const campaignMessageBody = createMessageBody(
      body,
      signatureActive,
      signatureContent,
      links,
    );
    return {
      ...sharedParams,
      attachments,
      contactCollection: groupId ? `${groupId}/contacts` : contactCollection,
      messageBody: campaignMessageBody,
    };
  }
  return {};
};

export const getSingleCampaignParams = ({
  attachments: campaignAttachments,
  autoAssign,
  campaignContactFilter,
  campaignRecipients,
  campaignScheduledAt,
  contactCollection,
  contactImport,
  exclusionTime,
  groupId,
  messageBody,
  isSignatureActive,
  signatureContent,
  shortenedLink,
  title,
  type,
}) => {
  const contactFilter = isEmpty(campaignContactFilter)
    ? contactImport?.contactFilter?.id
    : campaignContactFilter?.id;
  const campaignMessageBody = createMessageBody(
    messageBody,
    isSignatureActive,
    signatureContent,
    shortenedLink,
  );
  const scheduledAt = isAfter(campaignScheduledAt, new Date())
    ? campaignScheduledAt.toISOString()
    : undefined;
  const recipients = getRecipients(campaignRecipients);
  const attachments = campaignAttachments?.length
    ? campaignAttachments
    : undefined;

  return {
    attachments,
    autoAssign,
    contactCollection: groupId ? `${groupId}/contacts` : contactCollection,
    contactFilter,
    exclusionTime,
    messageBody: campaignMessageBody,
    recipients: groupId ? null : recipients,
    scheduledAt,
    title,
    type,
  };
};

export const getRecurringCampaignParams = ({
  attachments: campaignAttachments,
  autoAssign,
  campaignEndDate,
  dayToSend,
  groupId,
  isSignatureActive,
  messageBody,
  shortenedLink,
  runsIndefinitely,
  sendFrequency,
  sendTime,
  signatureContent,
  title,
  type,
}) => {
  const campaignMessageBody = createMessageBody(
    messageBody,
    isSignatureActive,
    signatureContent,
    shortenedLink,
  );
  const schedule = getCampaignSchedule({
    campaignEndDate,
    runsIndefinitely,
    dayToSend,
    sendFrequency,
    sendTime,
  });
  const attachments = campaignAttachments?.length
    ? campaignAttachments
    : undefined;

  return {
    attachments,
    autoAssign,
    contactCollection: `${groupId}/contacts`,
    messageBody: campaignMessageBody,
    schedule,
    title,
    type,
  };
};
