import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import LaunchIcon from "@mui/icons-material/Launch";
import MuiInlineBadge from "components/MuiInlineBadge";
import ConversationFilterOption from "features/Inbox/components/ConversationFilterOption";

import P from "components/P";

const EmptyState = styled(P)`
  font-size: 13px;
  padding: 20px 20px 20px 0;
  text-align: center;
`;

const Title = styled.span`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export default class Root extends Component {
  static propTypes = {
    conversationFilterCollection: PropTypes.object.isRequired,
    currentAccount: PropTypes.object.isRequired,
    emptyText: PropTypes.string.isRequired,
    handleFilterClick: PropTypes.func.isRequired,
  };

  getCampaignUrl = (slug) => {
    const { currentAccount } = this.props;
    const id = decodeURIComponent(slug).split(":")[1];
    return `${window.location.origin}/${currentAccount.slug}/campaigns/mine/${id}/total`;
  };

  render() {
    const {
      currentAccount,
      emptyText,
      handleFilterClick,
      conversationFilterCollection: { members: filters },
    } = this.props;
    return (
      <>
        {filters.length === 0 ? (
          <EmptyState>{emptyText}</EmptyState>
        ) : (
          filters.map((filter) => {
            const pathname = `/${
              currentAccount.slug
            }/inbox/${decodeURIComponent(filter.slug)}`;
            const campaignLink =
              filter.slug.startsWith("campaign") &&
              this.getCampaignUrl(filter.slug);
            const isactive = (match) => {
              return match ? (match.url === pathname).toString() : "";
            };
            return (
              <Box position="relative" key={filter.slug} width="100%">
                <ConversationFilterOption
                  key={filter.slug}
                  to={{ pathname }}
                  onClick={handleFilterClick}
                  isactive={isactive()}
                  style={{ paddingRight: "46px" }}
                >
                  <Title>{filter.title}</Title>
                  {filter.unreadCount > 0 ? (
                    <MuiInlineBadge
                      badgeContent={filter.unreadCount}
                      color="secondary"
                    />
                  ) : (
                    <MuiInlineBadge
                      badgeContent={filter.totalCount}
                      classes={{ badge: "total-count-badge" }}
                    />
                  )}
                </ConversationFilterOption>
                <Box position="absolute" top="3px" right="12px">
                  {campaignLink && (
                    <IconButton
                      key={`${filter.slug}-campaignLink`}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        return e.stopPropagation();
                      }}
                      target="_blank"
                      href={campaignLink}
                      size="large"
                    >
                      <LaunchIcon />
                    </IconButton>
                  )}
                </Box>
              </Box>
            );
          })
        )}
      </>
    );
  }
}
