import { useMemo } from "react";
import { format, sub } from "date-fns";
import qs from "query-string";

import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useSnackbar } from "notistack";
import { AlertBannerEst } from "../components/AlertBannerEst";
import AnalyticsDetailReport from "../components/AnalyticsDetailReport";
import AnalyticsFilters from "../components/AnalyticsFilters";
import AnalyticsSummary from "../components/AnalyticsSummary";

import { useAnalytics } from "../hooks/useAnalytics";
import { useFilters } from "../hooks/useFilters";
import { OverviewAnalyticsProps } from "../models/AnalyticsModels";
import { formatYearMonthDayUtc } from "utils/date";
import withRecord from "higherOrderComponents/withRecord";
import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";

function OverviewAnalytics({
  createAnalyticExportRequest,
  currentUser,
  currentAccount,
  isSinglePanel,
  reports,
  reportType,
  scheduledReports,
  setScheduledReports,
  showSidebar,
  toggleSidebar,
}: OverviewAnalyticsProps) {
  const history = useHistory();
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { showAnalyticsExportButton, reportDates } = useFilters({
    currentUser,
  });
  const { getOverviewReportIds } = useAnalytics();

  const { slug } = currentAccount;
  const { descendants, startDate, endDate } = qs.parse(search);
  const { reportDescendants, reportStartDate, reportEndDate } = reportDates;

  const overviewReportsIds = getOverviewReportIds({
    descendants,
    endDate: reportEndDate,
    reports,
    slug,
    startDate: reportStartDate,
  });

  const successCallback = () => {
    enqueueSnackbar(
      "Data export successful! Data will be emailed to you shortly.",
      {
        variant: "info",
      },
    );
  };

  const errorCallback = (error: Error) => {
    const { name = "", message = "" } = error;
    enqueueSnackbar(
      `Something went wrong trying to export the data. ${name}: ${message}`,
      {
        variant: "error",
      },
    );
  };

  const exportData = () => {
    return createAnalyticExportRequest(
      "/accounts/analytics_export",
      {
        startDate: formatYearMonthDayUtc(new Date(reportStartDate)),
        endDate: formatYearMonthDayUtc(new Date(reportEndDate)),
      },
      {
        successCallback,
        errorCallback,
      },
    );
  };

  if (!startDate || !endDate) {
    history.replace({
      pathname: `/${slug}/analytics/overview`,
      search: qs.stringify({
        startDate: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
        endDate: format(new Date(), "yyyy-MM-dd"),
        descendants: reportDescendants,
      }),
    });
  }

  const accountAggregateStatsId = useMemo(() => {
    return `/accounts/analytics?${qs.stringify({
      account: `/accounts/${slug}`,
      startDate: reportStartDate,
      endDate: reportEndDate,
      descendants: reportDescendants,
    })}`;
  }, [reportDescendants, reportEndDate, reportStartDate, slug]);

  const loading =
    overviewReportsIds.length > 0 && !reportStartDate && !reportEndDate;

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <Box display="flex" flexDirection="column" height="100%">
        <PageHeader
          aria-label="Analytics"
          title="Analytics Overview Reports"
          toggleSidebar={toggleSidebar}
        >
          {showAnalyticsExportButton && (
            <Box flex="0 0 auto" mr={2}>
              <Button
                color="primary"
                data-testid="export-data-button"
                data-product-tour="AnalyticsReports-Export"
                onClick={exportData}
                variant="contained"
              >
                Export Data
                <Box paddingLeft="5px">
                  <CloudDownloadIcon />
                </Box>
              </Button>
            </Box>
          )}
        </PageHeader>
        <Box
          aria-label="Account Analytics"
          display="flex"
          flex="1 1 auto"
          flexDirection="column"
          m="0 auto"
          maxWidth="1280px"
          minHeight="0px"
          overflow="auto"
          padding={4}
          width="100%"
        >
          <AlertBannerEst />
          <AnalyticsFilters currentUser={currentUser} />
          <AnalyticsSummary
            currentAccount={currentAccount}
            accountAggregateStatsId={accountAggregateStatsId}
          />
          {!loading &&
            overviewReportsIds.map(({ id: analyticReportId, reportName }) => {
              return (
                <AnalyticsDetailReport
                  analyticsReportId={analyticReportId}
                  currentAccount={currentAccount}
                  currentUser={currentUser}
                  key={analyticReportId}
                  reportName={reportName}
                  reportType={reportType}
                  scheduledReports={scheduledReports}
                  setScheduledReports={setScheduledReports}
                />
              );
            })}
        </Box>
      </Box>
    </PageContent>
  );
}

const mapStateToProps = (): Record<string, unknown> => {
  return {};
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withRecord({
    actions: ["create"],
    container: "features/AccountAnalytics/Overview/export",
    type: "analyticExport",
  }),
  withConnect,
)(OverviewAnalytics);
