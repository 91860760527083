import PropTypes from "prop-types";
import { Box, Button, Typography } from "@mui/material";
import { ExpandLessIcon, ExpandMoreIcon } from "icons";
import OtherConversationFilterCollection from "features/Inbox/containers/OtherConversationFilterCollection";

export default function OtherConversationFilters({
  isActive,
  conversationFilterCollectionId,
  emptyText,
  handleFilterClick,
  title,
  toggleShow,
}) {
  return (
    <Box width="100%" paddingTop={0}>
      <Box display="flex" flexDirection="row" alignItems="center" width="100%">
        <Button
          variant="text"
          startIcon={
            isActive ? (
              <ExpandLessIcon color="text.primary" />
            ) : (
              <ExpandMoreIcon color="text.primary" />
            )
          }
          onClick={toggleShow}
          fullWidth
          sx={{
            textTransform: "none",
            justifyContent: "flex-start",
            padding: "1rem",
          }}
          data-testid="toggle-other-conversation-filters"
        >
          <Typography variant="body1" color="text.primary">
            {title}
          </Typography>
        </Button>
      </Box>
      {isActive && (
        <Box minHeight="3.75rem" position="relative" paddingLeft="1.25rem">
          <OtherConversationFilterCollection
            conversationFilterCollectionId={conversationFilterCollectionId}
            emptyText={emptyText}
            handleFilterClick={handleFilterClick}
            data-testid="other-conversation-filter-collection"
          />
        </Box>
      )}
    </Box>
  );
}

OtherConversationFilters.propTypes = {
  conversationFilterCollectionId: PropTypes.string,
  emptyText: PropTypes.string.isRequired,
  handleFilterClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleShow: PropTypes.func.isRequired,
};
