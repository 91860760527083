export const AUTOMATION_PATHS = Object.freeze({
  automation: "automation",
  automationKeywords: "automation_keywords",
  sequences: "sequences",
  keywordsMain: "keywords",
  keywordGroups: "keywords_groups",
});

export const SECONDARY_NAV_LINKS = Object.freeze({
  mine: "Mine",
  others: "Others",
});
