/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import capitalize from "lodash/capitalize";
import pluralize from "pluralize";
import ArchiveIcon from "@mui/icons-material/Archive";
import CloseIcon from "@mui/icons-material/Close";
import DraftsIcon from "@mui/icons-material/Drafts";
import IconButton from "@mui/material/IconButton";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import SendIcon from "@mui/icons-material/Send";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import UndoIcon from "@mui/icons-material/Undo";
import PersonAdd from "@mui/icons-material/PersonAdd";
import PersonAddDisabled from "@mui/icons-material/PersonAddDisabled";
import {
  Box,
  Button,
  Snackbar,
  SnackbarContent,
  Typography,
} from "@mui/material";
import {
  ActiveConversationFilter,
  ContactWithoutPhonesData,
  ConversationBatchActionsProps,
} from "./types";
import Modal from "components/Modal";
import getUserRole from "utils/getUserRole";
import { formatSelectedRecords } from "utils/formatSelectedRecords";
import { GridActionToolbar } from "components/GridActionToolbar";
import SequencesIcon from "icons/SequencesIcon";
import {
  ConfirmEnrollmentDrawer,
  SelectSequenceDrawer,
} from "features/Sequences/components/Drawers";
import { useSequenceEnroller } from "features/Sequences/hooks/useSequenceEnroller";
import { fetchV3Accounts } from "features/Accounts/api/fetchV3";
import {
  Contact as ContactWithPhonesData,
  SelectedRecord,
} from "features/Sequences/components/Drawers/EnrollContactsDrawer/types";
import { Action, ActionType } from "components/GridActionToolbar/types";

const BATCH_MESSAGE_LIMIT = 10;

function ConversationBatchActions(props: ConversationBatchActionsProps) {
  const {
    activeConversationFilter,
    allConversationsSelected,
    clearBatchActionState,
    conversationCollection,
    currentAccount,
    currentUser,
    handleSetCompose,
    isRefetching,
    selectedConversations,
    selectedRecords,
    setAllConversationsSelected,
    setSelectedRecords,
    refetchConversationFilterRequest,
  } = props;

  const [confirmationModalAction, setConfirmationModalAction] =
    useState<Exclude<ActionType, "message" | "enroller">>("");
  const [notificationMessage, setNotificationMessage] = useState<
    string | undefined
  >(undefined);
  const [preFetchTotalItems, setPreFetchTotalItems] = useState<number>(0);
  const [displayedContacts, setDisplayedContacts] = useState<SelectedRecord[]>(
    [],
  );
  const [pagination, setPagination] = useState({
    // v3 endpoint with "view" instead of v4's "page" object
    id: "",
    first: "",
    next: "",
    previous: "",
    "@type": "",
    "@context": "",
  });

  const {
    allSequences,
    confirmEnrollmentDrawerIsOpen,
    isLoadingContacts,
    selectedSequence,
    selectSequenceDrawerIsOpen,
    sequenceSteps,
    backToSelectSequenceDrawer,
    closeConfirmEnrollmentDrawer,
    closeSelectSequenceDrawer,
    confirmEnrollment,
    openSelectSequenceDrawer,
    selectSequence,
  } = useSequenceEnroller(activeConversationFilter.conversations.id);

  const formatRecords = (records: ContactWithoutPhonesData[]) => {
    const assignedContacts = conversationCollection.members.map(
      (conversation) => {
        return conversation.assignedContact;
      },
    );

    const selectedRecordsWithPhonesData = records
      .map((record) => {
        const matchingObj = assignedContacts.find((contact) => {
          return contact?.id === record?.id;
        });
        return matchingObj ? { ...record, phones: matchingObj.phones } : record;
      })
      .filter((record) => {
        return record !== null;
      }) as ContactWithPhonesData[];

    return formatSelectedRecords(selectedRecordsWithPhonesData);
  };

  const fetchNextPage = async () => {
    if (!pagination?.next) {
      return null;
    }

    const response = await fetchV3Accounts(
      currentAccount.slug,
      pagination.next,
    );
    const body = await response.json();
    const newFormattedContacts = formatSelectedRecords(body.members);
    setPagination(body.view);
    return setDisplayedContacts([
      ...displayedContacts,
      ...newFormattedContacts,
    ]);
  };

  const getBatchActions = (): Action[] => {
    const batchActions: Action[] = [
      {
        key: "message",
        title: "Send message",
        icon: <SendIcon />,
        clickHandler: handleSendMessageClick,
      },
      {
        key: "enroller",
        title: "Enroll to sequence",
        icon: <SequencesIcon data-testid="enroll-to-sequence" />,
        clickHandler: openSelectSequenceDrawer,
      },
      {
        key: "close",
        title: "Close conversations",
        icon: <ArchiveIcon />,
        clickHandler: createClickHandler("close"),
      },
      {
        key: "reopen",
        title: "Reopen conversations",
        icon: <UndoIcon />,
        clickHandler: createClickHandler("reopen"),
      },
      {
        key: "star",
        title: "Star conversations",
        icon: <StarIcon data-testid="star-conversations" />,
        clickHandler: createClickHandler("star"),
      },
      {
        key: "unstar",
        title: "Unstar conversations",
        icon: <StarBorderIcon data-testid="unstar-conversations" />,
        clickHandler: createClickHandler("unstar"),
      },
      {
        key: "assign",
        title: "Assign yourself",
        icon: <PersonAdd data-testid="assign-yourself" />,
        clickHandler: createClickHandler("assign"),
      },
      {
        key: "unassign",
        title: "Unassign yourself",
        icon: <PersonAddDisabled data-testid="unassign-yourself" />,
        clickHandler: createClickHandler("unassign"),
      },
      {
        key: "read",
        title: "Mark read",
        icon: <DraftsIcon data-testid="mark-read" />,
        clickHandler: createClickHandler("read"),
      },
      {
        key: "unread",
        title: "Mark unread",
        icon: <MarkunreadIcon data-testid="mark-unread" />,
        clickHandler: createClickHandler("unread"),
      },
    ];

    return batchActions;
  };

  const getActionPastParticiple = (action: ActionType) => {
    switch (action) {
      case "close":
        return "closed";
      case "reopen":
      case "assign":
      case "unassign":
        return `${action}ed`;
      case "star":
      case "unstar":
        return `${action}red`;
      case "read":
      case "unread":
        return `marked as ${action}`;
      default:
        return "";
    }
  };

  const getRequestUrl = ({
    action,
    filter,
    withTimestamp,
  }: {
    action: Exclude<ActionType, "message" | "enroller" | "">;
    filter: ActiveConversationFilter;
    withTimestamp: boolean;
  }) => {
    return withTimestamp
      ? filter[`${action}Conversations`]
      : filter[`${action}Conversations`].substring(
          0,
          filter[`${action}Conversations`].includes("?")
            ? filter[`${action}Conversations`].indexOf("?")
            : undefined,
        );
  };

  const createClickHandler = (
    action: Exclude<ActionType, "message" | "enroller" | "">,
  ) => {
    return () => {
      if (allConversationsSelected) {
        setConfirmationModalAction(action);
        setPreFetchTotalItems(conversationCollection.totalItems);
        return null;
      }
      const requestUrl = getRequestUrl({
        action,
        filter: activeConversationFilter,
        withTimestamp: false,
      });
      const totalCount = selectedConversations.length;
      props[`${action}ConversationFilterRequest`](requestUrl, {
        conversations: selectedConversations,
      });
      resetBatchActionState(action);
      setNotificationMessage(
        `${totalCount.toLocaleString()} conversations ${getActionPastParticiple(
          action,
        )}`,
      );
      return null;
    };
  };

  const handleNotificationClose = () => {
    setNotificationMessage(undefined);
  };

  const handleSendMessageClick = () => {
    handleSetCompose();
  };

  const handleConfirmAll = () => {
    if (!confirmationModalAction) {
      return;
    }

    const requestUrl = getRequestUrl({
      action: confirmationModalAction,
      filter: activeConversationFilter,
      withTimestamp: true,
    });

    const totalCount = conversationCollection.totalItems;
    props[`${confirmationModalAction}ConversationFilterRequest`](
      requestUrl,
      null,
    );
    resetBatchActionState(confirmationModalAction);
    setNotificationMessage(
      `${totalCount.toLocaleString()} conversations ${getActionPastParticiple(
        confirmationModalAction,
      )}`,
    );
  };

  const shouldClearBatchActionState = (action: ActionType) => {
    return (
      (activeConversationFilter.id.includes("you") && action === "unassign") ||
      (activeConversationFilter.id.includes("unassigned") &&
        action === "assign") ||
      (activeConversationFilter.id.includes("starred") &&
        action === "unstar") ||
      (activeConversationFilter.id.includes("closed") && action === "reopen") ||
      (!activeConversationFilter.id.includes("closed") && action === "close")
    );
  };

  const resetBatchActionState = (action: ActionType) => {
    setConfirmationModalAction("");
    if (shouldClearBatchActionState(action)) {
      clearBatchActionState();
    }
  };

  const filterBatchActions = () => {
    const selectAllCount = conversationCollection.totalItems;
    const selectedCount = selectedConversations.length;
    const totalSelected = allConversationsSelected
      ? selectAllCount
      : selectedCount;
    return getBatchActions()
      .filter(({ key }) => {
        return !(key === "message" && totalSelected > BATCH_MESSAGE_LIMIT);
      })
      .filter(({ key }) => {
        return (
          (getUserRole(currentUser, currentAccount) &&
            currentAccount.multiUser) ||
          !["assign", "unassign"].includes(key)
        );
      })
      .filter(({ key }) => {
        return activeConversationFilter.id.includes("closed")
          ? key !== "close"
          : key !== "reopen";
      })
      .filter(({ key }) => {
        return !(
          activeConversationFilter.id.includes("unassigned") &&
          key === "unassign"
        );
      })
      .filter(({ key }) => {
        return !(
          activeConversationFilter.id.includes("you") && key === "assign"
        );
      })
      .filter(({ key }) => {
        return !(
          activeConversationFilter.id.includes("starred") && key === "star"
        );
      });
  };

  const selectAllCount = conversationCollection.totalItems;
  const selectedCount = selectedConversations.length;
  const actions = filterBatchActions();

  const formattedSelectedRecords = formatRecords(selectedRecords);

  const selectedContactPhoneIds = formattedSelectedRecords.map(
    ({ contactPhoneId }) => {
      return contactPhoneId.replace("/contact_phones/", "");
    },
  );

  useEffect(() => {
    if (confirmationModalAction !== null && preFetchTotalItems !== null) {
      refetchConversationFilterRequest(activeConversationFilter.id);
    }
  }, [confirmationModalAction, preFetchTotalItems]);

  useEffect(() => {
    if (selectedRecords) {
      setDisplayedContacts(formattedSelectedRecords);
    }
  }, [selectedRecords]);

  return (
    <>
      <GridActionToolbar
        actions={actions}
        allSelected={allConversationsSelected}
        position={{ top: "55px", left: "45px" }}
        selectedCount={selectedCount}
        selectAllCount={selectAllCount}
        setAllSelected={setAllConversationsSelected}
        setSelectedRecords={setSelectedRecords}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(notificationMessage)}
        autoHideDuration={5000}
        onClose={handleNotificationClose}
      >
        <SnackbarContent
          message={<span>{notificationMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleNotificationClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <Modal transitionIn={Boolean(confirmationModalAction)}>
        <Box component="section" padding="30px 40px">
          {isRefetching ? (
            <section>
              <Typography
                variant="subtitle2"
                fontWeight={700}
                mb="15px"
                textAlign="center"
              >
                Verifying selection...
              </Typography>
            </section>
          ) : (
            <Box
              component="section"
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="center"
            >
              <Typography
                variant="h6"
                fontWeight={700}
                color="primary"
              >{`Confirm Bulk ${capitalize(
                confirmationModalAction,
              )}`}</Typography>
              <Box
                component="p"
                lineHeight="22px"
                margin={0}
                sx={(theme) => {
                  return {
                    color: theme.palette.text.primary,
                    fontSize: theme.typography.fontSize,
                  };
                }}
              >
                <span>
                  {`This will ${confirmationModalAction} all ${conversationCollection.totalItems} conversations in ${activeConversationFilter.title}. `}
                </span>
                {conversationCollection.totalItems > preFetchTotalItems && (
                  <strong>
                    {`This includes ${
                      conversationCollection.totalItems - preFetchTotalItems
                    } new ${pluralize(
                      "conversation",
                      conversationCollection.totalItems - preFetchTotalItems,
                    )} you have not seen since you made your selection. `}
                  </strong>
                )}
                <span>Are you sure you want to continue?</span>
              </Box>
              <Box textAlign="center" marginTop={1}>
                <Button
                  color="primary"
                  onClick={() => {
                    return resetBatchActionState(confirmationModalAction);
                  }}
                  sx={{ marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="Confirm bulk action"
                  onClick={handleConfirmAll}
                >
                  OK
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
      <SelectSequenceDrawer
        isOpen={selectSequenceDrawerIsOpen}
        sequences={allSequences}
        handleSelectSequence={selectSequence}
        handleClose={closeSelectSequenceDrawer}
      />
      {selectedSequence && (
        <ConfirmEnrollmentDrawer
          allContactsCount={activeConversationFilter.totalCount}
          allSelected={allConversationsSelected}
          contactCollectionId={activeConversationFilter.conversations.id}
          currentAccount={currentAccount}
          displayedContacts={displayedContacts}
          fetchNextPage={fetchNextPage}
          isEmpty={activeConversationFilter.totalCount === 0}
          isLoading={isLoadingContacts}
          isOpen={confirmEnrollmentDrawerIsOpen}
          selectedContactPhoneIds={selectedContactPhoneIds}
          sequence={selectedSequence}
          sequenceStepOne={sequenceSteps[0]}
          handleBack={backToSelectSequenceDrawer}
          handleConfirm={confirmEnrollment}
          handleClose={closeConfirmEnrollmentDrawer}
        />
      )}
    </>
  );
}

export default ConversationBatchActions;
