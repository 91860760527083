import { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { withRouter } from "react-router";
import { get, isEmpty, union } from "lodash";
import { Campaign } from "@tesseract/core";
import { Box, Tooltip, Typography } from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { addSeconds, formatDistance } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { parse, toSeconds } from "iso8601-duration";
import {
  CampaignRecipientsBatchActions,
  CampaignRecipientsFailures,
} from "./components";
import { CheckIcon, HelpOutlineIcon } from "icons";
import withRecord from "higherOrderComponents/withRecord";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import { useCurrentAccount, useTimeZones } from "hooks";
import { TextUsTable } from "components/TextUsTable";
import { NoResultsScreen } from "components/Search/NoResultsScreen";

export type CampaignRecipientCollectionProps = {
  campaignRecipientCollection: Campaign.RecipientCollection;
  location: Location;
  match: {
    isExact: boolean;
    params: {
      [key: string]: string;
    };
    path: string;
    url: string;
  };
  setCompose: (...args: any) => void;
};

export function CampaignRecipientCollection(
  props: CampaignRecipientCollectionProps,
) {
  const { campaignRecipientCollection, location, match, setCompose } = props;
  const { accountTimeZone } = useTimeZones();

  // ==== DATA GRID ==== //
  const columns: readonly GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      valueGetter: ({ row }: GridCellParams) => {
        return get(row, ["contactPhone", "contact", "name"]);
      },
    },
    {
      field: "number",
      headerName: "Number",
      flex: 1,
      valueGetter: ({ row }: GridCellParams) => {
        return get(row, ["contactPhone", "formattedPhoneNumber"]);
      },
    },
    {
      field: "sentAt",
      flex: 1,
      renderHeader() {
        const sentAtDate = get(campaignRecipientCollection, [
          "members",
          0,
          "sentAt",
        ]);
        const formattedDate = `(${sentAtDate ? formatInTimeZone(new Date(sentAtDate), accountTimeZone, "MM/dd/yyyy") : ""})`;
        return (
          <Typography
            variant="body2"
            fontWeight={500}
          >{`Sent at ${(sentAtDate && formattedDate) || ""}`}</Typography>
        );
      },
      valueGetter: ({ row }: GridCellParams) => {
        const sentAt = get(row, ["sentAt"]);
        return sentAt
          ? formatInTimeZone(new Date(sentAt), accountTimeZone, "hh:mm:ss a z")
          : "-";
      },
    },
    {
      field: "delivered",
      headerName: "Delivered",
      align: "center",
      headerAlign: "center",
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const delivered = get(row, ["delivered"]);
        const unknown = get(row, ["unknown"]);
        const tooltipContent = "Did not receive a delivery confirmation";

        if (delivered) {
          return <CheckIcon />;
        }

        if (unknown) {
          return (
            <Tooltip
              title={tooltipContent}
              placement="bottom"
              sx={{
                cursor: "help",
              }}
            >
              <HelpOutlineIcon fontSize="medium" />
            </Tooltip>
          );
        }

        return "-";
      },
      valueGetter: ({ row }: GridCellParams) => {
        if (row.unknown) {
          return 1;
        }

        if (row.delivered) {
          return 2;
        }

        return 0;
      },
      sortComparator: (v1: any, v2: any) => {
        return v1 - v2;
      },
    },
    {
      field: "repliedIn",
      flex: 1,
      headerName: "Replied in",
      align: "center",
      headerAlign: "center",
      valueGetter: ({ row }: GridCellParams) => {
        const { replyLag } = row;

        if (!replyLag) {
          return 0;
        }

        const replyLagInSeconds = toSeconds(parse(replyLag));
        return replyLagInSeconds;
      },
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        if (value === 0) {
          return "-";
        }

        const repliedAtDate = addSeconds(new Date(), value);

        const humanReadableDistance = formatDistance(
          new Date(),
          repliedAtDate,
        ).replace("about ", "");

        return humanReadableDistance;
      },
    },
    {
      field: "failure",
      flex: 2,
      headerClassName: "full-width",
      renderHeader() {
        return (
          <CampaignRecipientsFailures
            match={match}
            location={location}
            redesign
          />
        );
      },
      renderCell: ({ row }: GridRenderCellParams) => {
        const state = get(row, ["state"]);
        const error = get(row, ["errorDescription"]);

        if (state === "cutoff") {
          const tooltipContent =
            "Message not sent because delivery was attempted after the provided cutoff time";
          return (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              minWidth="80px"
            >
              <Typography variant="body2">Cutoff</Typography>
              <Tooltip
                title={tooltipContent}
                placement="bottom"
                sx={{
                  cursor: "help",
                }}
              >
                <HelpOutlineIcon fontSize="medium" />
              </Tooltip>
            </Box>
          );
        }

        if (state === "canceled") {
          const tooltipContent =
            "Message not sent because campaign was canceled";
          return (
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              minWidth="80px"
            >
              <Typography variant="body2">Canceled</Typography>
              <Tooltip
                title={tooltipContent}
                placement="bottom"
                sx={{
                  cursor: "help",
                }}
              >
                <HelpOutlineIcon fontSize="small" />
              </Tooltip>
            </Box>
          );
        }

        return (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            minWidth="80px"
          >
            {error || "-"}
          </Box>
        );
      },
      valueGetter: ({ row }: GridCellParams) => {
        return row.state;
      },
      sortComparator: (v1: any, v2: any) => {
        return v1 > v2 ? 1 : -1;
      },
    },
    {
      field: "nameMobile",
      headerName: "Name",
      flex: 1,
      renderCell: ({ row }: GridRenderCellParams) => {
        const contactName = get(row, ["contactPhone", "contact", "name"]);

        const contactNumber = get(row, [
          "contactPhone",
          "formattedPhoneNumber",
        ]);

        return (
          <Box>
            <Typography variant="body2" color="text.primary">
              {contactName}
            </Typography>

            {contactNumber && (
              <Typography variant="caption" color="text.secondary">
                {contactNumber}
              </Typography>
            )}
          </Box>
        );
      },
    },
  ];

  // ==== HOOKS ==== //
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<Campaign.Recipient[]>(
    [],
  );
  const [
    previousCampaignRecipientCollectionId,
    setPreviousCampaignRecipientCollectionId,
  ] = useState<string | null>(null);

  const currentAccount = useCurrentAccount();

  useEffect(() => {
    if (
      allSelected &&
      previousCampaignRecipientCollectionId !== campaignRecipientCollection.id
    ) {
      const visibleIds = get(campaignRecipientCollection, ["members"], []).map(
        (recipient) => {
          return recipient.id;
        },
      );

      setSelected(union(selected, visibleIds));
    }

    return () => {
      setPreviousCampaignRecipientCollectionId(campaignRecipientCollection.id);
    };
  }, [
    allSelected,
    campaignRecipientCollection,
    previousCampaignRecipientCollectionId,
    selected,
  ]);

  // ==== METHODS ==== //
  const handleSetAllSelected = (allSelected_: boolean) => {
    const visibleIds = get(campaignRecipientCollection, ["members"], []).map(
      (recipient) => {
        return recipient.id;
      },
    );

    setSelected(allSelected_ ? union(selected, visibleIds) : []);
    setAllSelected(allSelected_);
    setSelectedRecords([]);
  };

  const handleSetSelected = (selected_: string[]) => {
    setAllSelected(false);
    setSelected(selected_);
  };

  const handleSetSelectedRecords = (selected_: Campaign.Recipient[]) => {
    setAllSelected(false);
    setSelectedRecords(selected_);
  };

  const handleSetCompose = () => {
    const recipientIds = selected.map((selectedId) => {
      return campaignRecipientCollection.members.find((recipient) => {
        return recipient.id === selectedId;
      })?.contactPhone.id;
    });

    setCompose({ active: true, recipientIds });
  };

  // ==== RENDER ==== //
  return (
    <Box
      sx={(theme) => {
        return {
          border: "1px solid",
          borderColor: theme.palette.divider,
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
          position: "relative",
          width: "100%",
        };
      }}
    >
      {!isEmpty(campaignRecipientCollection) && selected.length > 0 && (
        <CampaignRecipientsBatchActions
          allSelected={allSelected}
          currentAccount={currentAccount}
          handleSetCompose={handleSetCompose}
          left="50px"
          selected={selected}
          selectedRecords={selectedRecords}
          setAllSelected={handleSetAllSelected}
          setSelected={handleSetSelected}
          setSelectedRecords={handleSetSelectedRecords}
          {...props}
        />
      )}
      <TextUsTable
        className="campaign-recipients-collection-table"
        collection={campaignRecipientCollection}
        columns={columns}
        hideColumnsOnMobile={[
          "name",
          "number",
          "sentAt",
          "delivered",
          "repliedIn",
          "failure",
        ]}
        hideColumnsOnDesktop={["nameMobile"]}
        noRowsOverlay={<NoResultsScreen source="campaigns" active />}
        selected={selected}
        setSelected={handleSetSelected}
        setSelectedRecords={handleSetSelectedRecords}
      />
    </Box>
  );
}

const withConnect = connect(null, {
  setCompose: composeActionGenerators.setCompose,
});

export default compose(
  withRecord({
    actions: ["fetch", "subscribe", "unsubscribe", "block", "unblock"],
    container: "CampaignRecipientCollection",
    shape: { members: [{ contactPhone: { contact: {} } }] },
    showLoader: () => {
      return false;
    },
    type: "campaignRecipientCollection",
  }),
  withConnect,
  withRouter,
)(CampaignRecipientCollection);
