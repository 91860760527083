import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function GridButton({
  clickHandler = () => {},
  href,
  icon,
  title,
  to,
  ...otherButtonProps
}) {
  const buttonProps = href
    ? { href, target: href === "#" ? undefined : "_blank" }
    : { component: NavLink, to };
  return (
    <Button
      color="primary"
      onClick={clickHandler}
      fullWidth
      style={{ height: "100%" }}
      {...buttonProps}
      {...otherButtonProps}
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {icon}
        <Box component="span" mt={1} style={{ textTransform: "none" }}>
          {title}
        </Box>
      </Box>
    </Button>
  );
}

GridButton.propTypes = {
  clickHandler: PropTypes.func,
  href: PropTypes.string,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
};

export default GridButton;
