import PropTypes from "prop-types";
import styled from "styled-components";
import H3 from "components/H3";

export const Root = styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  align-self: stretch;
  overflow: auto;
  flex: 0 0 auto;
  box-shadow: 0px 7px 20px -5px rgba(0, 0, 0, 0.075);
  border: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  border-radius: ${(props) => {
    return props.theme.defaults.tileBorderRadius;
  }};
  margin-bottom: 20px;
  position: relative;

  &.transition-appear {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
    transform: translateY(20px);
  }

  &.transition-appear-active {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateY(0px);
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1)
      ${(props) => {
        return props.duration;
      }}ms;
    transition-delay: ${(props) => {
      return props.delay;
    }}ms;
  }

  &.transition-enter {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
    transform: translateY(20px);
  }

  &.transition-enter-active {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateY(0px);
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1)
      ${(props) => {
        return props.duration;
      }}ms;
    transition-delay: ${(props) => {
      return props.delay;
    }}ms;
  }

  &.transition-enter-done {
    transition: all cubic-bezier(0.6, -0.88, 0.735, 0.045) 0.3s;
  }

  &.transition-exit {
    ${(props) => {
      return props.theme.mixins.fadeShow;
    }};
    transform: translateX(0px);
  }

  &.transition-exit-active {
    ${(props) => {
      return props.theme.mixins.fadeHide;
    }};
    transform: translateX(500px);
    transition: all cubic-bezier(0.39, 0.575, 0.565, 1)
      ${(props) => {
        return props.duration;
      }}ms;
    transition-delay: ${(props) => {
      return props.delay;
    }}ms;
  }

  ${H3} {
    text-align: center;
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 10px;
  }
`;

const SubTitle = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-top: 30px;
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
`;

function Tile({
  animationDuration,
  animationStagger,
  className,
  children,
  dataTag,
  subTitle,
  title,
}) {
  return (
    <Root
      className={className}
      duration={animationDuration}
      delay={animationStagger}
      data-product-tour={dataTag}
    >
      {title && <H3>{title}</H3>}
      {children}
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </Root>
  );
}

Tile.defaultProps = {
  animationDuration: 500,
  animationStagger: 0,
};

Tile.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.any,
  subTitle: PropTypes.string,
  animationDuration: PropTypes.number,
  animationStagger: PropTypes.number,
  dataTag: PropTypes.string,
};

export default Tile;
