import { CardContent, useTheme } from "@mui/material";
import type { SettingsCardContentProps } from "./types";

export function SettingsCardContent({
  sx,
  ...props
}: SettingsCardContentProps) {
  const theme = useTheme();
  return (
    <CardContent
      sx={{
        paddingX: "16px",
        paddingY: 1,
        ...(typeof sx === "function" ? sx(theme) : sx),
      }}
      {...props}
    />
  );
}
