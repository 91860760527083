import { all, takeLatest } from "redux-saga/effects";
import { UPDATE_APP_COLORS } from "./constants";
import localStorageHelper from "utils/localStorageHelper";

function* setAppColors({ appColors }) {
  localStorageHelper.setItem({ item: "colorTheme", value: appColors });
}

function* saga() {
  yield all([takeLatest(UPDATE_APP_COLORS, setAppColors)]);
}

export default saga;
