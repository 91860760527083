import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";

// ACTION TYPES
const SET_COMPOSE = "app/ComposeRoot/SET_COMPOSE";
const actionTypes = { SET_COMPOSE };

// ACTION GENERATORS
const setCompose = (payload) => {
  return {
    type: SET_COMPOSE,
    payload,
  };
};

const actionGenerators = { setCompose };

// REDUCER
const substate = (state = fromJS({}), action) => {
  switch (action.type) {
    case actionTypes.SET_COMPOSE:
      return fromJS(action.payload);
    default:
      return state;
  }
};
const reducer = combineReducers({ substate });

// SELECTORS
const selectComposeRootContainer = (state) => {
  return state.get("composeRootContainer").toJS();
};
const selectors = { selectComposeRootContainer };

export { actionTypes, actionGenerators, reducer, selectors };
