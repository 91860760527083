/* eslint-disable class-methods-use-this */
import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import debounce from "lodash/debounce";
import flatten from "lodash/flatten";
import get from "lodash/get";

import elementResizeDetectorMaker from "element-resize-detector";
import { TransitionGroup } from "react-transition-group";

import AttachmentModal from "./AttachmentModal";
import NewMessageNotification from "./NewMessageNotification";
import TimelineMembers from "./TimelineMembers";
import BlankState from "components/BlankState";
import Logo from "components/Logo";
import PaginationLoader from "components/PaginationLoader";

const BOTTOM_SCROLL_BUFFER = 50;
const TOP_SCROLL_BUFFER = 300;

export const ConversationThreadWrapper = styled.div`
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 0;
  overflow-y: auto;
  padding-top: 0;
  position: relative;
`;

const Beginning = styled.div`
  flex: 0 0 auto;
  font-size: 14px;
  text-align: center;
  margin: 40px 0;
  font-weight: 500;
  color: ${(props) => {
    return props.theme.colors.text.secondary;
  }};
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  span {
    margin-left: 10px;
  }
`;

const TypingIndicator = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  flex: 0 0 38px;
  font-size: 12px;
  padding: 10px 30px;
  text-align: right;
`;

const Typer = styled.span`
  font-weight: 700;
`;

class ConversationThread extends Component {
  static propTypes = {
    activeConversation: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
    containerQueryParams: PropTypes.object,
    currentAccount: PropTypes.object,
    currentUser: PropTypes.object,
    deleteMessageRequest: PropTypes.func,
    paginateTimelineRequest: PropTypes.func.isRequired,
    readConversationRequest: PropTypes.func,
    createMessageRequest: PropTypes.func.isRequired,
    typingIndicators: PropTypes.array,
    updateMessageRequest: PropTypes.func,
  };

  debouncedHandleScroll = debounce(
    // eslint-disable-next-line unicorn/consistent-function-scoping
    () => {
      if (!this.Thread) return null;
      if (this.isUserNearBottom()) return this.checkAndUpdateReadStatus();
      if (this.isUserNearTop()) return this.checkAndPaginateTimeline();
      return null;
    },
    100,
    { trailing: true },
  );

  constructor(props) {
    super(props);
    this.state = {
      newMessageCount: 0,
      threadHeight: 0,
      modalVisible: false,
      modalAttachment: undefined,
    };
  }

  componentDidMount() {
    if (this.Thread) {
      this.scrollToBottom();
      this.setupResizeListener();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentPosition =
      this.props.activeConversation.timeline.latestPosition;
    const { latestPosition } = nextProps.activeConversation.timeline;

    if (currentPosition !== latestPosition) {
      this.checkForNewMessages({ props: nextProps });
    }
  }

  // Loosely based on http://blog.vjeux.com/2013/javascript/scroll-position-with-react.html
  UNSAFE_componentWillUpdate() {
    if (this.Thread) {
      this.shouldScrollBottom = this.isUserNearBottom();
      this.scrollHeight = this.Thread.scrollHeight;
      this.scrollTop = this.Thread.scrollTop;
    }
  }

  componentDidUpdate(prevProps) {
    if (
      get(prevProps, ["activeConversation", "id"]) !==
      get(this.props, ["activeConversation", "id"])
    ) {
      this.checkAndUpdateReadStatus();
    }
    if (this.Thread && this.shouldScrollBottom) {
      this.scrollToBottom();
    } else if (this.Thread) {
      this.Thread.scrollTop =
        this.scrollTop + (this.Thread.scrollHeight - this.scrollHeight);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.forceUpdateTimeout);
    this.erd.uninstall(this.Thread);
  }

  setupResizeListener() {
    /*
    We need to listen to the resize event due to the nature of our layout.
    When the message area grows, we need to know about it so we can preserve
    user scroll position. This calls setState, which subsequently triggers
    componentWillUpdate, setting this.shouldScrollBottom
    */
    this.erd = elementResizeDetectorMaker({ strategy: "scroll" });
    this.erd.listenTo(
      this.Thread,
      debounce((el) => {
        if (this.state.threadHeight !== el.offsetHeight) {
          this.setState({ threadHeight: el.offsetHeight });
        }
      }, 100),
    );
  }

  showModal = (attachment, event) => {
    event.preventDefault();
    this.setState({
      modalVisible: true,
      modalAttachment: attachment,
    });
  };

  hideModal = () => {
    this.setState({ modalVisible: false });
  };

  checkForNewMessages = ({ props }) => {
    const currentPosition =
      this.props.activeConversation.timeline.latestPosition;
    const currentPositionIndex =
      props.activeConversation.timeline.members.findIndex((item) => {
        return item.timelinePosition <= currentPosition;
      });

    const newTimelineMembers = props.activeConversation.timeline.members.slice(
      0,
      currentPositionIndex,
    );

    const newMessageCount = newTimelineMembers.filter((m) => {
      return (
        m["@type"] === "Message" &&
        get(m.sender, "id", null) !== get(this.props.currentUser, "id")
      );
    }).length;
    if (newMessageCount > 0 && !this.isUserNearBottom()) {
      this.setState((prevState) => {
        return {
          newMessageCount: prevState.newMessageCount + newMessageCount,
        };
      });
    }
  };

  attachmentsFromProps = () => {
    const messages = this.props.activeConversation.timeline.members.filter(
      (timelineMember) => {
        return timelineMember["@type"] === "Message";
      },
    );
    const attachments = flatten(
      [...messages]
        .sort((a, b) => {
          return new Date(a.timelinePosition) - new Date(b.timelinePosition);
        })
        .map((message) => {
          return message.attachments.members.map((attachment) => {
            return attachment;
          });
        }),
    ).filter((attachment) => {
      return attachment.contentType.startsWith("image");
    });
    return attachments.flat();
  };

  scrollToBottom = () => {
    if (this.Thread) {
      this.Thread.scrollTop = this.Thread.scrollHeight;
    }
  };

  checkAndUpdateReadStatus() {
    const { activeConversation, readConversationRequest } = this.props;
    const { requestingUserInfo } = activeConversation;
    if (readConversationRequest && !requestingUserInfo.read) {
      readConversationRequest(`${activeConversation.id}/read`, {
        up_to: new Date().toISOString(),
      });
    }
  }

  checkAndPaginateTimeline() {
    const {
      activeConversation: { timeline },
      paginateTimelineRequest,
    } = this.props;
    if (timeline.view.next) paginateTimelineRequest(timeline.view.next);
  }

  isUserNearBottom() {
    const { Thread } = this;
    return (
      Thread.scrollTop + Thread.offsetHeight + BOTTOM_SCROLL_BUFFER >
      Thread.scrollHeight
    );
  }

  isUserNearTop() {
    return this.Thread.scrollTop < TOP_SCROLL_BUFFER;
  }

  renderBlankState() {
    return (
      <BlankState
        image={<Logo color="disabled" />}
        title="This Conversation is Empty"
        subTitle="Send a message below to get started"
      />
    );
  }

  renderTypingIndicator = (typingIndicators) => {
    if (typingIndicators.length > 2) {
      return <TypingIndicator>Several people are typing</TypingIndicator>;
    }
    if (typingIndicators.length === 2) {
      return (
        <TypingIndicator>
          <Typer>{typingIndicators[0]}</Typer> and{" "}
          <Typer>{typingIndicators[1]}</Typer> are typing
        </TypingIndicator>
      );
    }
    return (
      <TypingIndicator>
        <Typer>{typingIndicators[0]}</Typer> is typing
      </TypingIndicator>
    );
  };

  render() {
    const {
      activeConversation: { timeline },
      appSettings,
      containerQueryParams,
      createMessageRequest: retryMessageRequest,
      currentAccount,
      currentUser,
      deleteMessageRequest,
      typingIndicators,
      updateMessageRequest,
    } = this.props;
    const { newMessageCount } = this.state;
    return (
      <>
        <AttachmentModal
          appSettings={appSettings}
          visible={this.state.modalVisible}
          attachments={this.attachmentsFromProps()}
          initialAttachment={this.state.modalAttachment}
          onClose={this.hideModal}
        />
        <NewMessageNotification
          closeHandler={() => {
            return this.setState({ newMessageCount: 0 });
          }}
          jumpHandler={() => {
            return this.setState({ newMessageCount: 0 }, () => {
              return this.scrollToBottom();
            });
          }}
          newMessageCount={newMessageCount}
        />
        <ConversationThreadWrapper
          ref={(c) => {
            this.Thread = c;
          }}
          onScroll={this.debouncedHandleScroll}
        >
          {timeline.members.length > 0 ? (
            <>
              {timeline.view.next ? (
                <PaginationLoader />
              ) : (
                <Beginning>
                  <Logo width={25} color="disabled" />
                  <span>This is the beginning of your conversation</span>
                </Beginning>
              )}
              <TransitionGroup component={null}>
                <TimelineMembers
                  appSettings={appSettings}
                  containerQueryParams={containerQueryParams}
                  currentAccount={currentAccount}
                  currentUser={currentUser}
                  deleteMessageRequest={deleteMessageRequest}
                  retryMessageRequest={retryMessageRequest}
                  scrollToBottom={this.scrollToBottom}
                  showModal={this.showModal}
                  timeline={timeline}
                  updateMessageRequest={updateMessageRequest}
                />
              </TransitionGroup>
            </>
          ) : (
            this.renderBlankState()
          )}
          {typingIndicators &&
            typingIndicators.length > 0 &&
            this.renderTypingIndicator(typingIndicators)}
        </ConversationThreadWrapper>
      </>
    );
  }
}

export default ConversationThread;
