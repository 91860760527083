import { Component } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";
import { Dialog } from "@mui/material";
import ContactFilterForm from "./ContactFilterForm";
import { DialogHeader } from "components/DialogHeader";


class Root extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    contactFilterId: PropTypes.string.isRequired,
    currentAccount: PropTypes.object.isRequired,
    createContactFilterRequest: PropTypes.func.isRequired,
  };

  render() {
    const {
      closeModal,
      contactFilterId,
      createContactFilterRequest,
      currentAccount,
      visible,
    } = this.props;
    return (
      <Dialog open={visible} fullScreen={false} fullWidth={false} maxWidth="sm">
        <DialogHeader onClose={closeModal} title="New saved search" />
        <ContactFilterForm
          cancelHandler={closeModal}
          closeModal={closeModal}
          savedPath={contactFilterId}
          requestUrl={currentAccount.contactFilters}
          submitHandler={createContactFilterRequest}
        />
      </Dialog>
    );
  }
}

export default withSizes(({ width }) => {
  return { fullScreen: width < 620 };
})(Root);
