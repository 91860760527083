/* eslint-disable react-hooks/exhaustive-deps */
import {
  ReactNode,
  useEffect,
  useState,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from "react";
import { Link as RouterLink } from "react-router-dom";
import { Location } from "history";
import { format } from "date-fns";

import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";

import { useSnackbar } from "notistack";
import { StyledGroupTableCell } from "../components/styles";
import { SequencesLinkedChip } from "../components/SequencesLinkedChip";
import { fetchAutomationKeywords } from "../api/fetchAutomationKeywords";
import stripKeywordId from "../utils/stripKeywordId";
import getKeywordSequenceLink from "../utils/getKeywordSequenceLink";
import { Link as TLink } from "../components/types";
import Avatar from "components/Avatar";
import createUUID from "utils/uuid";
import { useCurrentAccount } from "hooks";
import { Snackbar } from "components/Snackbar";
import { Keyword, KeywordResponse } from "models/Keyword";

const useKeywordGroups = (
  keywords: KeywordResponse | null,
  setKeywords: Dispatch<SetStateAction<KeywordResponse | null>>,
) => {
  // ==== HOOKS ==== //
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const currentAccount = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();

  const rowsPerPage = 10;

  // ==== METHODS ==== //
  const handleFetchAutomationKeywords = async ({ items = 10 } = {}) => {
    try {
      // Fetch keywords.
      const response = await fetchAutomationKeywords(
        currentAccount,
        `items=${items}&page=${page}`,
      );
      const json = await response.json();

      setKeywords(json);
    } catch {
      enqueueSnackbar(
        "There was a problem fetching keyword groups. Please try again.",
        {
          content: (key, message) => {
            return <Snackbar id={key} message={message} variant="info" />;
          },
        },
      );
    }
  };

  const handlePageChange = (
    event: MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => {
    setPage(newPage + 1);
  };

  const keywordGroupCell = (keyword: Keyword): ReactNode => {
    const keywordGroupId = stripKeywordId(keyword?.id);
    return (
      <Box display="flex" alignItems="center" py="4px">
        <Tooltip title={keyword?.createdBy} placement="bottom">
          <Box flex="0 0 auto" mr={2}>
            <Avatar subject={keyword?.createdBy} />
          </Box>
        </Tooltip>
        <Link
          component={RouterLink}
          to={(location: Location) => {
            return `${location.pathname}/${keywordGroupId}`;
          }}
        >
          {keyword.keyword}
        </Link>
      </Box>
    );
  };

  const getRow = (row: Keyword, links: TLink[]) => {
    const currentLink = getKeywordSequenceLink(links, row.id);
    return (
      <TableRow key={createUUID()} style={{ padding: 2 }}>
        <StyledGroupTableCell>{keywordGroupCell(row)}</StyledGroupTableCell>
        <StyledGroupTableCell>
          {currentLink ? (
            <SequencesLinkedChip sequence={currentLink.sequence} />
          ) : null}
        </StyledGroupTableCell>
        <StyledGroupTableCell>{row?.contactCount}</StyledGroupTableCell>
        <StyledGroupTableCell>{row?.createdBy}</StyledGroupTableCell>
        <StyledGroupTableCell>
          {format(new Date(row?.createdAt), "MM-dd-yyyy")}
        </StyledGroupTableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    const getPage = async () => {
      setLoading(true);
      await handleFetchAutomationKeywords({
        items: rowsPerPage,
      });
      setLoading(false);
    };

    getPage().catch((error_) => {
      console.error(error_);
    });
  }, [page]);

  // ==== RETURN ==== //
  return {
    keywords,
    rowsPerPage,
    loading,
    page,
    getRow,
    handlePageChange,
  };
};

export default useKeywordGroups;
