import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class GreenhouseStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /greenhouse/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    return [
      new SingleContactParser({
        doc,
        namesSelector: "h1.person-name",
        textSelector: "ul.person-contact-list",
        canParse: () => !!$(doc).find("body.candidate-profile-background")[0],
      }),
    ];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "GreenhouseStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "GreenhouseStrategy");
      return null;
    }
  };
}
