/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Button, IconButton, Tooltip } from "@mui/material";
import { ConfirmEnrollmentDrawer, SelectSequenceDrawer } from "../Drawers";
import { getFromInbox } from "./utils";
import { SequenceEnrollerProps } from "models/SequenceEnrollerProps";
import SequencesIcon from "icons/SequencesIcon";
import { useSequenceEnroller } from "features/Sequences/hooks/useSequenceEnroller/useSequenceEnroller";

export function SequenceEnroller({
  allSelected,
  buttonType,
  currentAccount,
  contactCollectionId,
  contactFilter,
  formattedSelectedRecords,
  setSelected,
  setSelectedRecords,
}: SequenceEnrollerProps) {
  const {
    allContacts,
    allContactsCount,
    allSequences,
    confirmEnrollmentDrawerIsOpen,
    isLoadingContacts,
    selectedSequence,
    selectSequenceDrawerIsOpen,
    sequenceSteps,
    backToSelectSequenceDrawer,
    closeConfirmEnrollmentDrawer,
    closeSelectSequenceDrawer,
    confirmEnrollment,
    fetchAllContacts,
    openSelectSequenceDrawer,
    selectSequence,
  } = useSequenceEnroller(contactCollectionId, setSelected, setSelectedRecords);

  const selectedContactPhoneIds = formattedSelectedRecords.map((record) => {
    return record.contactPhoneId?.replace("/contact_phones/", "");
  });

  const fromInbox = getFromInbox(contactCollectionId);

  useEffect(() => {
    if (allSelected && !fromInbox) {
      fetchAllContacts();
    }
  }, [allSelected]);

  // ==== RENDER ==== //
  return (
    <>
      {buttonType === "text" && (
        <Button
          aria-label="Enroll in Sequence"
          color="primary"
          data-testid="enroll-in-sequence-button"
          disabled={formattedSelectedRecords.length === 0 && !allSelected}
          onClick={openSelectSequenceDrawer}
          variant="contained"
          style={{ marginLeft: "8px" }}
        >
          Enroll in sequence
        </Button>
      )}

      {buttonType === "icon" && (
        <Tooltip key="enroll" title="Enroll in sequence">
          <IconButton
            aria-label="Enroll in Sequence"
            data-testid="enroll-in-sequence-icon"
            onClick={openSelectSequenceDrawer}
            size="large"
          >
            <SequencesIcon />
          </IconButton>
        </Tooltip>
      )}

      {/* ==== DRAWERS ==== */}
      <SelectSequenceDrawer
        isOpen={selectSequenceDrawerIsOpen}
        sequences={allSequences}
        handleSelectSequence={selectSequence}
        handleClose={closeSelectSequenceDrawer}
      />

      {selectedSequence && (
        <ConfirmEnrollmentDrawer
          allContactsCount={allContactsCount}
          allSelected={allSelected}
          contactCollectionId={contactCollectionId}
          contactFilter={contactFilter}
          currentAccount={currentAccount}
          displayedContacts={
            allSelected && !fromInbox ? allContacts : formattedSelectedRecords
          }
          fetchNextPage={fetchAllContacts}
          isLoading={isLoadingContacts}
          isOpen={confirmEnrollmentDrawerIsOpen}
          selectedContactPhoneIds={selectedContactPhoneIds}
          sequence={selectedSequence}
          sequenceStepOne={sequenceSteps[0]}
          handleBack={backToSelectSequenceDrawer}
          handleConfirm={confirmEnrollment}
          handleClose={closeConfirmEnrollmentDrawer}
        />
      )}
    </>
  );
}
