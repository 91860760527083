export default {
  navBar: {
    bg: "#212121",
  },
  conversationFilter: {
    bg: "#3B3B3B",
    header: {
      bg: "#2A2B29",
      borderColor: "transparent",
    },
    listItem: {
      colorActive: "#fff",
      color: "#ADADA3",
      hover: {
        color: "#fff",
      },
    },
  },
};
