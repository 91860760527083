import { textUsApiV4 } from "services";

export const fetchBillingAnalytics = async (
  accountSlug: string,
  contractTermStartDate: string,
  asOfDate: string,
): Promise<Response> => {
  return textUsApiV4.get(
    `/${accountSlug}/billing/term?contract-term-start-date=${contractTermStartDate}&as-of=${asOfDate}`,
  );
};
