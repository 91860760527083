/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable camelcase */
import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useGreenhouseCandidates } from "../hooks/useGreenhouseCandidates";
import { Props } from "./types";

export function GreenhouseCandidateAutocomplete({
  account,
  contact,
  value,
  ...props
}: Props) {
  const [open, setOpen] = useState(false);

  const { candidates, state, load, abort } = useGreenhouseCandidates(
    account,
    contact,
  );

  const handleOpen = useCallback(() => {
    if (["waiting", "error"].includes(state)) {
      load();
    }

    setOpen(true);
  }, [state, load]);

  const handleClose = useCallback(() => {
    abort?.();

    setOpen(false);
  }, [abort]);

  return (
    <>
      <Autocomplete
        disableClearable
        getOptionLabel={({ id, first_name, last_name }) => {
          return `${id} - ${first_name} ${last_name}`;
        }}
        isOptionEqualToValue={(a, b) => {
          return a.id === b.id;
        }}
        loading={state === "loading"}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open && !(state === "error")}
        options={Object.values(candidates ?? {})}
        renderInput={(renderInputParams) => {
          return (
            <TextField
              {...renderInputParams}
              label="Candidate ID"
              helperText={
                state === "error"
                  ? "No IDs found, please try again later."
                  : undefined
              }
              FormHelperTextProps={{
                error: state === "error",
              }}
              InputProps={{
                ...renderInputParams.InputProps,
                endAdornment: (
                  <>
                    {state === "loading" ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {renderInputParams.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          );
        }}
        value={value}
        {...props}
      />
    </>
  );
}
