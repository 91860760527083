import Honeybadger from "@honeybadger-io/js";
import getContactDataFromNode from "phoneExtraction/utils/getContactDataFromNode";

export default class SelectionStrategy {
  constructor({ activeFrameParsedHtml, nodeSelected, parsedHtml }) {
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.nodeSelected = nodeSelected;
    this.parsedHtml = parsedHtml;
  }

  parse = () => {
    try {
      const doc = this.activeFrameParsedHtml || this.parsedHtml;
      if (this.nodeSelected) {
        const results = getContactDataFromNode(
          doc.querySelector("[data-textus-selection]"),
        );
        return { strategy: "SelectionStrategy", results };
      }
      return false;
    } catch (error) {
      Honeybadger.notify(error, "SelectionStrategy");
      return null;
    }
  };
}
