import { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Link from "@mui/material/Link";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";

import { useSnackbar } from "notistack";
import ProfileSetting from "./ProfileSetting";
import { NotificationsValidationSchema } from "formHelpers/validationSchemas";

function Notifications(props) {
  const [subscribed, setSubscription] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    async function updateSubscription() {
      const isPushEnabled = await global.OneSignal.isPushNotificationsEnabled();
      const isOptedOut = await global.OneSignal.isOptedOut();
      setSubscription(!isOptedOut && isPushEnabled);
    }
    global.OneSignal.push(() => {
      if (!global.OneSignal.isPushNotificationsSupported()) {
        setIsDisabled(true);
        return;
      }
      updateSubscription();
      global.OneSignal.on("subscriptionChange", updateSubscription);
    });
  }, [global.OneSignal]);

  const registerPushNotifications = async () => {
    const isPushEnabled = await global.OneSignal.isPushNotificationsEnabled();
    const isOptedOut = await global.OneSignal.isOptedOut();
    if (isPushEnabled) {
      global.OneSignal.setSubscription(false);
    } else if (isOptedOut) {
      global.OneSignal.setSubscription(true);
    } else {
      global.OneSignal.registerForPushNotifications();
    }
    setSubscription(!subscribed);
  };

  const getDescription = () => {
    return "📣 Setup notifications to personalize your TextUs experience.";
  };

  const copyLink = (event) => {
    event.preventDefault();
    navigator.clipboard.writeText(
      "chrome://settings/content/notifications?search=notifications",
    );
    enqueueSnackbar("Link has been successfully copied.", {
      variant: "info",
    });
  };

  /* eslint-disable react/prop-types */
  const getRenderDesktopNotifications = ({ label, helperText }) => {
    return () => {
      return function ({ field }) {
        return (
          <>
            <FormControlLabel
              control={
                <Switch
                  data-testid="desktop-notifications"
                  {...field}
                  disabled={isDisabled}
                  checked={subscribed}
                  onChange={registerPushNotifications}
                  color="secondary"
                />
              }
              label={label}
            />
            <FormHelperText>{helperText}</FormHelperText>
            <Box my={1}>
              <FormHelperText>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  component="button"
                  style={{
                    color: "#8cc63f",
                    fontWeight: 600,
                    verticalAlign: "text-bottom",
                  }}
                  onClick={copyLink}
                >
                  Click here to copy the link&nbsp;
                </Link>
                to update preferences. After it has been copied, open a new tab
                and paste the link. When in Chrome&apos;s notification
                preferences, make sure next.textus.com is not blocked.
              </FormHelperText>
            </Box>
          </>
        );
      };
    };
  };

  const getRenderToggle = ({
    label,
    disabled = false,
    helperText = undefined,
    checkbox = false,
  }) => {
    return () => {
      return function ({ field, form }) {
        const toggleProps = {
          ...field,
          disabled,
          checked: field.value,
          onChange: (event) => {
            const { checked } = event.target;
            form.setFieldValue(field.name, checked);
          },
        };
        return (
          <>
            <FormControlLabel
              data-testid={label}
              control={
                checkbox ? (
                  <Checkbox {...toggleProps} color="secondary" />
                ) : (
                  <Switch {...toggleProps} color="secondary" />
                )
              }
              label={label}
            />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </>
        );
      };
    };
  };

  const getRenderSelect = ({
    label,
    helperText = undefined,
    topDescriptionText = undefined,
    /**
     * TODO: Organize this type into form.types.ts when doing larger TS conversion
     * @type {
     *   Array<{
     *     label: string;
     *     value: string | number;
     *   }>
     * }
     */
    selectOptions,
  }) => {
    return () => {
      return function ({ field }) {
        return (
          <Box my={2}>
            {topDescriptionText && (
              // Nothing smaller than subtitle3 at time of writing:
              <Typography variant="subtitle3">{topDescriptionText}</Typography>
            )}
            <TextField
              {...field}
              style={{ marginTop: "10px" }}
              select
              variant="outlined"
              SelectProps={{
                native: true,
              }}
              fullWidth
              label={label}
            >
              {selectOptions.map((option) => {
                return (
                  <option key={option.label} value={option.value}>
                    {option.label}
                  </option>
                );
              })}
            </TextField>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
          </Box>
        );
      };
    };
  };
  /* eslint-enable react/prop-types */

  return (
    <ProfileSetting
      {...props}
      description={getDescription()}
      settings={[
        {
          name: "notifications.desktop",
          default: false,
          render: getRenderDesktopNotifications({
            label: "Desktop Notifications",
            helperText: "Having trouble receiving notifications?",
          }),
        },
        {
          reactKey: "Divider1",
          render: () => {
            return (
              <Box my={2}>
                <Divider />
              </Box>
            );
          },
        },
        {
          name: "notifications.emailSettings.enabled",
          default: false,
          render: getRenderToggle({
            label: "Email Notifications",
            helperText:
              "Receive email notifications for any new inbound messages for:",
          }),
        },
        {
          name: "notifications.emailSettings.filters.you",
          default: false,
          shouldShow: ({ values }) => {
            return values.notifications.emailSettings.enabled;
          },
          render: getRenderToggle({
            checkbox: true,
            label: "Conversations I'm assigned to",
          }),
        },
        {
          name: "notifications.emailSettings.filters.otherUser",
          default: false,
          shouldShow: ({ values }) => {
            return values.notifications.emailSettings.enabled;
          },
          render: getRenderToggle({
            checkbox: true,
            label: "Conversations others are assigned to",
          }),
        },
        {
          name: "notifications.emailSettings.filters.unassigned",
          default: false,
          shouldShow: ({ values }) => {
            return values.notifications.emailSettings.enabled;
          },
          render: getRenderToggle({
            checkbox: true,
            label: "Conversations not assigned to anyone",
          }),
        },
        {
          name: "notifications.emailSettings.messageDelayMinutes",
          default: 5,
          shouldShow: ({ values }) => {
            return values.notifications.emailSettings.enabled;
          },
          render: getRenderSelect({
            label: "Wait Time",
            topDescriptionText:
              "Send me an email for an unread message after this chosen amount of time since receiving it.",
            selectOptions: [0, 5, 10, 15, 30, 45, 60].map((minutes) => {
              return {
                label: `${minutes} Minutes`,
                value: minutes,
              };
            }),
          }),
        },
        {
          reactKey: "Divider2",
          render: () => {
            return (
              <Box my={2}>
                <Divider />
              </Box>
            );
          },
        },
        {
          name: "notifications.audio",
          default: false,
          render: getRenderToggle({
            label: "Audio Notifications",
            helperText:
              "Play a sound whenever you receive a new message in a conversation to which you're subscribed.",
          }),
        },
        {
          reactKey: "Divider3",
          render: () => {
            return (
              <Box my={2}>
                <Divider />
              </Box>
            );
          },
        },
        {
          name: "notifications.analytics",
          default: "never",
          render: getRenderSelect({
            label: "Analytics Emails",
            helperText:
              "How often do you want to receive summary emails with analytics for your account(s)?",
            selectOptions: ["Never", "Daily", "Weekly"].map((label) => {
              return {
                label,
                value: label.toLowerCase(),
              };
            }),
          }),
        },
      ]}
      title="Notifications"
      validationSchema={NotificationsValidationSchema}
    />
  );
}

Notifications.propTypes = {};

export default Notifications;
