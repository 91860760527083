import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Redirect } from "react-router-dom";
import Papa from "papaparse";

import PageLoader from "components/PageLoader";

export default class Root extends Component {
  static propTypes = {
    clearContactImport: PropTypes.func.isRequired,
    contactImport: PropTypes.object.isRequired,
    createContactImportRequest: PropTypes.func.isRequired,
    extractedPhones: PropTypes.array.isRequired,
    location: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { createContactImportRequest, extractedPhones, location } =
      this.props;
    if (extractedPhones.length === 0) return;
    const csv = Papa.unparse(extractedPhones);
    const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const file = new File([csvBlob], "extension.csv");
    const { state: { source } = {} } = location;
    createContactImportRequest(null, { file, source });
  }

  componentWillUnmount() {
    this.props.clearContactImport();
  }

  render() {
    const { contactImport } = this.props;
    if (get(contactImport, ["state"]) !== "processed") return <PageLoader />;
    return (
      <Redirect
        to={get(contactImport, ["contactFilter"], "").replace(
          "contact_filters",
          "contacts",
        )}
      />
    );
  }
}
