import { ReactNode } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { Box, Grid, Typography } from "@mui/material";

import { AccountPlansSettingsProps } from "../models/AccountPlanModels";
import { useAccountPlans } from "../hooks/useAccountPlans";
import { HeaderText } from "../styles/styles";
import { selectAccountPlans } from "features/EntryPoint/containers/App/selectors";
import createUUID from "utils/uuid";

function AccountPlansSettings({
  accountPlans = [],
}: AccountPlansSettingsProps) {
  const { formattedAccountPlans } = useAccountPlans({ accountPlans });

  const licenseCards = formattedAccountPlans.map((accountPlan: ReactNode) => {
    return (
      <Grid item xs={4} key={createUUID()} sx={{ minWidth: "18.75rem" }}>
        {accountPlan}
      </Grid>
    );
  });

  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Box mb={4}>
        <Typography align="center" color="textPrimary" variant="h4">
          Licenses
        </Typography>
        <HeaderText align="center" color="textPrimary">
          Assign licenses to your messaging accounts.
        </HeaderText>
      </Box>
      <Grid
        aria-label="Account Plans"
        container
        spacing={4}
        alignContent="center"
      >
        {licenseCards}
      </Grid>
    </Box>
  );
}

const mapStateToProps = (state: never, props: any) => {
  return {
    accountPlans: selectAccountPlans(state),
  };
};

const withConnect = connect(() => {
  return mapStateToProps;
}, {});

export default compose(withConnect)(AccountPlansSettings);
