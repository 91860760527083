import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";

import get from "lodash/get";
import { Field, Form, Formik } from "formik";
import withSizes from "react-sizes";

import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import breakpoints from "utils/styles/breakpoints";
import { UserSchemaVariableValidationSchema } from "formHelpers/validationSchemas";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import withRecord from "higherOrderComponents/withRecord";

function ConditionalTooltip({ condition, wrapper, children }) {
  return condition ? wrapper(children) : children;
}

function CustomUserSchemaModal({
  closeModal,
  createUserSchemaRequest,
  fetchUserSchemaRequest,
  fullScreen,
  modalAction,
  open,
  updateCustomFieldRequest,
  userSchemaId,
  variableToEdit,
  variableTypes,
}) {
  const { enqueueSnackbar } = useSnackbar();

  const modalActionText = modalAction === "Add" ? "Add" : "Save";
  const successMessage = `Variable ${
    modalAction === "Add" ? "added" : "saved"
  } successfully`;
  const failureMessage = `We were unble to ${modalActionText.toLowerCase()} this variable`;
  const disabledOnEdit = modalAction === "Edit";

  const handleSubmit = (values, actions) => {
    if (modalAction === "Add") {
      handleCreateUserSchema(values, actions);
    } else if (modalAction === "Edit") {
      handleUpdateVariable(values, actions);
    }
  };

  const handleCreateUserSchema = (values, actions) => {
    const { type, title, description } = values;
    const valuesToSend = {
      type: type.value,
      title: title.trim(),
      ...(description && { description: description.trim() }),
    };
    createUserSchemaRequest(userSchemaId, valuesToSend, {
      successCallback: () => {
        fetchUserSchemaRequest(userSchemaId);
        actions.setSubmitting(false);
        closeModal();
        enqueueSnackbar(successMessage, {
          variant: "info",
        });
      },
      errorCallback: (errors) => {
        const validationErrors = get(errors, ["validationErrors"], {
          name: "Something went wrong!",
        });
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        enqueueSnackbar(
          `${failureMessage}${
            validationErrors[""] ? ` because ${validationErrors[""]}.` : `.`
          }`,
          { variant: "error" },
        );
      },
    });
  };

  const handleUpdateVariable = (values, actions) => {
    const { description, slug } = values;
    const valuesToSend = {
      ...(description && { description: description.trim() }),
    };
    updateCustomFieldRequest(`${userSchemaId}/${slug}`, valuesToSend, {
      successCallback: () => {
        actions.setSubmitting(false);
        enqueueSnackbar("Personalization successfully updated.", {
          variant: "info",
        });
      },
      errorCallback: (errors) => {
        const validationErrors = get(errors, ["validationErrors"], {
          name: "Something went wrong!",
        });
        actions.setSubmitting(false);
        actions.setErrors(validationErrors);
        enqueueSnackbar("We were unable to edit this personalization.", {
          variant: "error",
        });
      },
    });
    closeModal();
  };

  return (
    <Modal
      fullScreen={fullScreen}
      transitionIn={open}
      ariaLabel="Custom User Schema Modal"
      closeModal={closeModal}
    >
      <ModalHeader>{`${modalAction} User Personalization`}</ModalHeader>
      <ModalContent maxWidth="100%">
        <Box minWidth={{ xs: "280px", sm: "600px", md: "600px" }}>
          <Formik
            initialValues={
              variableToEdit || {
                type: { name: "", value: "" },
                title: "",
                description: "",
              }
            }
            validationSchema={UserSchemaVariableValidationSchema}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions);
            }}
          >
            {({ errors, touched, isSubmitting, isValid, values }) => {
              return (
                <Form>
                  <Field type="select" name="type">
                    {({ form, field }) => {
                      return (
                        <ConditionalTooltip
                          condition={disabledOnEdit}
                          wrapper={(children) => {
                            return (
                              <Tooltip
                                placement="top"
                                title="This field cannot be edited."
                              >
                                {children}
                              </Tooltip>
                            );
                          }}
                        >
                          <Autocomplete
                            disableClearable
                            disabled={disabledOnEdit}
                            fullWidth
                            getOptionLabel={(option) => {
                              return option.name;
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return value.title === option.title;
                            }}
                            id="personalization-type"
                            label="Type"
                            onChange={(event, newValue) => {
                              form.setFieldValue(field.name, {
                                name: newValue.name,
                                value: newValue.value,
                              });
                            }}
                            options={variableTypes}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  aria-label="personalization type input"
                                  error={touched.type && Boolean(errors.type)}
                                  id="type"
                                  label={
                                    variableToEdit
                                      ? variableToEdit.type.name
                                      : "Select a personalization type"
                                  }
                                  variant="outlined"
                                />
                              );
                            }}
                            variant="outlined"
                          />
                        </ConditionalTooltip>
                      );
                    }}
                  </Field>
                  <Box mt={6}>
                    <Field type="text" name="title">
                      {({ form, field }) => {
                        return (
                          <ConditionalTooltip
                            condition={disabledOnEdit}
                            wrapper={(children) => {
                              return (
                                <Tooltip
                                  placement="top"
                                  title="This field cannot be edited."
                                >
                                  {children}
                                </Tooltip>
                              );
                            }}
                          >
                            <TextField
                              {...field}
                              aria-label="personalization name input"
                              disabled={disabledOnEdit}
                              error={touched.title && Boolean(errors.title)}
                              FormHelperTextProps={{
                                style: { textAlign: "right" },
                              }}
                              fullWidth
                              id="title"
                              helperText={
                                (touched.title && errors.title) ||
                                `${values.title.length} of 70 characters`
                              }
                              inputProps={{ "data-lpignore": true }}
                              label="Personalization Name"
                              onChange={(e) => {
                                form.setFieldValue(field.name, e.target.value);
                              }}
                              placeholder="Enter a personalization name"
                              type="text"
                              variant="outlined"
                            />
                          </ConditionalTooltip>
                        );
                      }}
                    </Field>
                  </Box>
                  <Box mt={4}>
                    <Field type="text" name="description">
                      {({ form, field }) => {
                        return (
                          <TextField
                            {...field}
                            aria-label="personalization description input"
                            disabled={false}
                            variant="outlined"
                            error={
                              touched.description && Boolean(errors.description)
                            }
                            FormHelperTextProps={{
                              style: { textAlign: "right" },
                            }}
                            fullWidth
                            id="description"
                            helperText={
                              (touched.description && errors.description) ||
                              `${
                                values.description?.length || 0
                              } of 1000 characters`
                            }
                            inputProps={{ "data-lpignore": true }}
                            label="Personalization Description"
                            multiline
                            onChange={(e) => {
                              return form.setFieldValue(
                                field.name,
                                e.target.value,
                              );
                            }}
                            placeholder="Enter a personalization description (optional)"
                            type="text"
                          />
                        );
                      }}
                    </Field>
                  </Box>
                  <Box
                    alignItems="center"
                    display="flex"
                    flex="0 0 auto"
                    justifyContent="flex-end"
                    mt={2}
                    px={4}
                  >
                    <Button
                      aria-label="cancel"
                      color="primary"
                      onClick={closeModal}
                      style={{ margin: "0 2em 0 0" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      aria-label="submit button"
                      color="primary"
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      variant="contained"
                    >
                      {modalActionText}
                    </Button>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </ModalContent>
    </Modal>
  );
}

ConditionalTooltip.propTypes = {
  condition: PropTypes.bool,
  wrapper: PropTypes.func,
  children: PropTypes.node,
};

CustomUserSchemaModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  createUserSchemaRequest: PropTypes.func.isRequired,
  fetchUserSchemaRequest: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  modalAction: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  updateCustomFieldRequest: PropTypes.func.isRequired,
  userSchemaId: PropTypes.string.isRequired,
  variableToEdit: PropTypes.object,
  variableTypes: PropTypes.array.isRequired,
};

export default compose(
  withSizes(({ width }) => {
    return {
      fullScreen: width < breakpoints.medium,
    };
  }),
  withRecord({
    actions: ["create"],
    container:
      "features/AccountSettings/components/CustomUserSchema/CustomUserSchemaModal",
    type: "userSchema",
  }),
  withRecord({
    actions: ["update"],
    container:
      "features/AccountSettings/components/CustomUserSchema/customField/update",
    type: "customField",
  }),
)(CustomUserSchemaModal);
