import { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import uniqBy from "lodash/uniqBy";
import IconButton from "@mui/material/IconButton";
import MoreVert from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

function AccountRowMenu({ account, selected, setSelectedAccounts }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectDescendants = () => {
    const subAccounts = get(account, ["children", "members"]);
    setSelectedAccounts(uniqBy([...selected, ...subAccounts], "id"));
    handleClose();
  };

  const handleDeselectDescendants = () => {
    const subAccountIds = get(account, ["children", "members"], []).map(
      (acc) => {
        return acc.id;
      },
    );
    setSelectedAccounts(
      selected.filter((acc) => {
        return !subAccountIds.includes(acc.id);
      }),
    );
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleClick} size="large">
        <MoreVert color="primary" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        MenuListProps={{ disablePadding: true }}
      >
        <MenuItem onClick={handleSelectDescendants}>
          Select All Children
        </MenuItem>
        <MenuItem onClick={handleDeselectDescendants}>
          Deselect All Children
        </MenuItem>
      </Menu>
    </>
  );
}

AccountRowMenu.propTypes = {
  account: PropTypes.object.isRequired,
  selected: PropTypes.array,
  setSelectedAccounts: PropTypes.func.isRequired,
};

export default AccountRowMenu;
