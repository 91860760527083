import { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import TextField from "@mui/material/TextField";
import ResetPasswordSuccess from "./ResetPasswordSuccess";
import { RequestResetPasswordValidationSchema } from "formHelpers/validationSchemas";
import H3 from "components/H3";
import H5 from "components/H5";
import Button from "features/Authentication/components/PrimaryButton";
import SecondaryButton from "features/Authentication/components/SecondaryButton";
import Overlay from "features/Authentication/components/Overlay";

export default class ResetPasswordOverlay extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { isActive } = this.props;
    return (
      <Overlay isActive={isActive}>
        {isActive && (
          <Formik
            initialValues={{ email: "" }}
            validationSchema={RequestResetPasswordValidationSchema}
            onSubmit={this.props.onSubmit}
          >
            {({ errors, touched, isSubmitting }) => {
              return (
                <Form>
                  {isSubmitting ? (
                    <ResetPasswordSuccess />
                  ) : (
                    <>
                      <span role="img" aria-label="Email Inbox Emoji">
                        🔑
                      </span>
                      <H3>Reset your password</H3>
                      <H5>
                        You will receive an email containing a link to reset
                        your password
                      </H5>
                      <Field name="email">
                        {({ field }) => {
                          return (
                            <TextField
                              {...field}
                              data-testid="reset-password-email"
                              id="reset-password-email"
                              type="email"
                              error={touched.email && errors.email}
                              fullWidth
                              label="Email"
                              placeholder="Please enter your email"
                              helperText={
                                touched.email ? errors.email || "" : ""
                              }
                              variant="standard"
                            />
                          );
                        }}
                      </Field>
                      <Button type="submit">Send me a link</Button>
                      <SecondaryButton type="button" onClick={this.props.close}>
                        Cancel
                      </SecondaryButton>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </Overlay>
    );
  }
}
