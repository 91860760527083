import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { UnlinkKeywordSubscriberDialogProps } from "./types";

export default function UnlinkKeywordSubscriberDialog({
  open,
  handleClose,
  handleUnlink,
  keywordSubscriberName,
  sequenceName,
}: UnlinkKeywordSubscriberDialogProps) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        alignItems="center"
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
      >
        <DialogTitle
          component="div"
          aria-label="Dialog Title"
          padding="0.5rem 1rem"
          children={
            <Typography variant="h6" fontWeight={500} color="text.primary">
              Unlink keyword?
            </Typography>
          }
        />

        <IconButton
          data-testid="close-unlink-dialog"
          sx={{ marginRight: "1rem", marginTop: "-0.5rem" }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="medium" />
        </IconButton>
      </Box>

      <DialogContent dividers>
        {keywordSubscriberName} keyword will no longer be linked to the{" "}
        {sequenceName} sequence. Contacts will no longer be automatically
        enrolled in this sequence after receiving the keyword auto-response.
      </DialogContent>

      <DialogActions sx={{ padding: "16px" }}>
        <Button
          aria-label="Cancel"
          onClick={handleClose}
          data-testid="nevermind-button"
        >
          Nevermind
        </Button>

        <Button
          aria-label="Confirm"
          variant="contained"
          onClick={handleUnlink}
          data-testid="unlink-button"
        >
          Unlink
        </Button>
      </DialogActions>
    </Dialog>
  );
}
