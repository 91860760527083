import { ListItem, IconButton, ListItemText, Tooltip } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ContactImportsListItemProps } from "./types";
import { AddIcon } from "icons";

export default function ContactImportsListItem({
  currentAccount,
}: ContactImportsListItemProps) {
  return (
    <ListItem
      secondaryAction={
        <NavLink
          className="import-contacts-button"
          to={`/${currentAccount.slug}/contact_imports/new`}
        >
          <Tooltip title="Import contacts">
            <IconButton
              edge="end"
              aria-label="delete"
              data-product-tour="ImportContactsButton"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        </NavLink>
      }
    >
      <ListItemText
        primary="Contact Imports"
        sx={{
          "& .MuiTypography-root": {
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "700",
          },
        }}
      />
    </ListItem>
  );
}
