export function getParentsUntil(doc, el, parentSelector = "document") {
  const parents = [];

  let p;
  for (
    p = el.parentNode;
    p.parentNode?.matches && !p.parentNode.matches(parentSelector);
    p = p.parentNode
  ) {
    parents.push(p);
  }

  parents.push(p.parentNode);
  return parents;
}

export function getParentsMatching(doc, el, parentSelector) {
  const parents = getParentsUntil(doc, el);
  const matching = parents.filter((parent) => {
    return parent?.matches?.(parentSelector);
  });
  return matching;
}
