/**
 * The official hook for the <TextUsTable /> component.
 * Handles:
 * - Server-side Pagination.
 * - Row selection.
 * - Column visibility based on size of screen.
 * - Caching rows for selected records.
 * - Loading state.
 */
import {
  GridPaginationModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router";
import queryString from "query-string";
import { useTextUsTableProps } from "./types";
import { useResize } from "features/Sequences/hooks/useResize";

export const useTextUsTable = ({
  collection,
  hideColumnsOnMobile,
  hideColumnsOnDesktop,
  setSelected,
  setSelectedRecords,
}: useTextUsTableProps) => {
  // ==== HOOKS ==== //
  const [rows, setRows] = useState<any[]>([]);
  const [rowsCached, setRowsCached] = useState<Map<string, any>>(new Map());
  const [rowCount, setRowCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationModel, setPaginationModel] = useState({
    page: Number(new URLSearchParams(window.location.search).get("page")) || 0,
    pageSize: 30,
  });
  const history = useHistory();
  const { windowWidth } = useResize();

  const columnVisibilityModel = useMemo(() => {
    const visibilityModel: Record<string, boolean> = {};

    if (windowWidth >= 600) {
      hideColumnsOnDesktop?.forEach((column) => {
        visibilityModel[column] = false;
      });
      return visibilityModel;
    }

    hideColumnsOnMobile?.forEach((column) => {
      visibilityModel[column] = false;
    });
    return visibilityModel;
  }, [hideColumnsOnDesktop, hideColumnsOnMobile, windowWidth]);

  useEffect(() => {
    if (!collection.members) {
      setLoading(true);
      return;
    }

    setRows(collection.members);
    setRowCount(collection.totalItems);

    // When pagination changes, we get a new collection and new members array.
    // In order to perform lookups for selected records on previous pages,
    // we need to cache the rows as we paginate.
    // We use a Map to avoid duplicates.
    setRowsCached((prev) => {
      const cache = new Map();
      collection.members.forEach((member: any) => {
        cache.set(member.id, member);
      });
      return new Map([...prev, ...cache]);
    });

    setLoading(false);
  }, [collection]);

  // ==== TABLE EVENT HANDLERS ==== //
  const onPaginationModelChange = (model: GridPaginationModel) => {
    const collectionView = collection.view;

    if (!collectionView) {
      return;
    }

    const updateUrl = (url: string): string => {
      const currentSearchParams = queryString.parse(history.location.search);

      const {
        query: { cursor },
      } = queryString.parseUrl(url);

      return queryString.stringify({
        ...currentSearchParams,
        cursor,
        page: model.page,
      });
    };

    // Update pagination UI.
    setPaginationModel(model);

    if (model.page < paginationModel.page) {
      history.goBack();
      return;
    }

    const nextSearch = updateUrl(collectionView.next);

    history.push({
      ...history.location,
      search: `?${nextSearch}`,
    });
  };

  const onRowSelectionModelChange = (ids: GridRowSelectionModel) => {
    setSelected(ids as string[]);

    const rowRecords = ids.map((memberId: any) => {
      return rowsCached.get(memberId);
    });

    setSelectedRecords(rowRecords);
  };

  // ==== RETURN ==== //
  return {
    rows,
    rowCount,
    loading,
    paginationModel,
    columnVisibilityModel,

    onPaginationModelChange,
    onRowSelectionModelChange,
  };
};
