/* eslint-disable func-names */
import SvgIcon from "@mui/material/SvgIcon";
import ICONS from "constants/icons";

export default function (props) {
  return (
    <SvgIcon {...props} viewBox="0 -960 960 960">
      <path d={ICONS.INFO_OUTLINE} />
    </SvgIcon>
  );
}
