import { useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";

function ContactCollectionSearch({ handleSearch }) {
  const [showSearch, setShowSearch] = useState(false);

  const handleChange = debounce((value) => {
    handleSearch(value);
  }, 1000);

  const handleClick = () => {
    if (showSearch) {
      handleSearch(undefined);
      return setShowSearch(false);
    }
    return setShowSearch(true);
  };

  return (
    <Box alignItems="center" display="flex" flexGrow={0}>
      {showSearch && (
        <TextField
          autoFocus
          hiddenLabel
          margin="dense"
          onChange={(event) => {
            return handleChange(event.target.value);
          }}
          placeholder="Search Contacts"
          variant="standard"
        />
      )}
      <IconButton
        onClick={handleClick}
        aria-label="Search Contacts"
        size="large"
      >
        {showSearch ? <ClearIcon /> : <SearchIcon />}
      </IconButton>
    </Box>
  );
}

ContactCollectionSearch.propTypes = {
  handleSearch: PropTypes.func.isRequired,
};

export default ContactCollectionSearch;
