import type { ReactNode } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import get from "lodash/get";
import styled from "styled-components";

import isEmpty from "lodash/isEmpty";
import withSizes from "react-sizes";
import { Box, Divider } from "@mui/material";
import Helmet from "react-helmet";
import { Theme, lightTheme } from "@tesseract/theme";
import { Account, User } from "@tesseract/core";
import { useLocation } from "react-router";
import { actionGenerators } from "features/EntryPoint/containers/Bootstrap/state";
import { Logo } from "features/MainNavigation/Logo";
import { FindNumbersButton } from "features/MainNavigation/FindNumbersButton";
import {
  selectCurrentAccount,
  selectCurrentUser,
  selectAppColors,
} from "features/EntryPoint/containers/App/selectors";
import { selectors as animationSelectors } from "features/EntryPoint/containers/Animation/state";
import { selectors as navigationSelectors } from "features/MainNavigation/state";
import breakpoints from "utils/styles/breakpoints";
import LoginFailedPage from "components/LoginFailedPage";
import MainNavigation from "features/MainNavigation";
import NotFoundPage from "features/NotFoundPage";
import PageLoader from "components/PageLoader";
import TermsOfUseAgreementModal from "features/Legal/TermsOfUseAgreementModal";
import { MobileBottomNavigation } from "features/MobileBottomNavigation";
import globalNavigationRoutes from "constants/globalNavigationRoutes";
import { RedirectListener } from "components/RedirectListener";
import { updateAppColors } from "features/EntryPoint/containers/App/actions";
import NavButtons from "features/MainNavigation/NavButtons";
import SiteNavigation from "features/MainNavigation/SiteNavigation/SiteNavigation";
import UserMenu from "features/MainNavigation/UserMenu/UserMenu";
import { SingleAccount } from "features/MainNavigation/AccountMenu/SingleAccount";
import { MultiAccountMenu } from "features/MainNavigation/AccountMenu/MultiAccountMenu";

const MainNavigationWrapper = styled.div`
  flex: 0 0
    ${(props) => {
      return props.theme.layout.navHeight;
    }};
  z-index: 3;

  @media (min-width: ${breakpoints.medium}px) {
    z-index: unset;
  }
`;

const UserOptionDrawer = styled.div`
  flex: 0 0 auto;
  position: relative;
  z-index: 1;

  @media (max-width: ${breakpoints.medium - 1}px) {
    z-index: 2;
  }
`;

const Root = styled.div<{
  expandHeader: boolean;
}>`
  position: relative;
  background: ${(props) => {
    return props.theme.colors.navigation.background;
  }};
  transition: height cubic-bezier(0.785, 0.135, 0.15, 0.86) 0.5s;
  height: ${(props) => {
    return props.expandHeader
      ? props.theme.layout.navExpandedHeight
      : props.theme.layout.navHeight;
  }};

  &:after {
    content: " ";
    display: block;
    opacity: ${(props) => {
      return props.expandHeader ? 1 : 0;
    }};
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: all ease-in-out 0.333s;
    background: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0.333)
    );
    z-index: 0;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    height: ${(props) => {
      return props.theme.layout.navHeight;
    }};

    &:after {
      display: none;
    }
  }
`;

const getEnvColor = ({
  theme,
}: {
  theme: {
    colors: {
      mode: string;
      primary: {
        main: string;
      };
    };
  };
}) => {
  if (theme.colors.mode === "dark") return "transparent";

  const { hostname } = window?.location || undefined;

  if (theme?.colors?.primary?.main?.toUpperCase() === lightTheme.primaryColor) {
    // primary color has not been changed via whitelabel settings
    if (hostname?.includes("localhost")) return "#468f15";
    if (hostname?.includes("textus-staging")) return "#dc6326";
  }

  return "transparent";
};

const Nav = styled.nav<{
  expandHeader: boolean;
}>`
  height: ${(props) => {
    return props.theme.layout.navHeight;
  }};
  color: ${(props) => {
    return props.theme.colors.navigation.color;
  }};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 10;
  width: 100vw;
  background: ${(props) => {
    return getEnvColor(props);
  }};

  @media (min-width: ${breakpoints.medium}px) {
    ${(props) => {
      return (
        props.expandHeader &&
        `
          box-shadow: 0px 0px 16px ${props.theme.colors.navigation.background};
        `
      );
    }};
  }
`;

const NavigationItems = styled.div<{
  expandHeader: boolean;
}>`
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  position: relative;

  > * {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
    width: ${(props) => {
      return props.theme.layout.navHeight;
    }};
    position: relative;

    &:hover {
      background: ${(props) => {
        return !props.expandHeader && props.theme.colors.action.hover;
      }};
    }
  }

  @media (min-width: ${breakpoints.medium}px) {
    > a {
      height: 100%;
      min-width: ${(props) => {
        return props.theme.layout.navHeight;
      }};
      width: auto;
    }
  }
`;

export type ComposedProps = {
  appColors: Theme;
  currentUser: User.Raw;
  currentAccount: Account.Raw;
  currentAccountNavbarElement: {
    siteNavigation: {
      members: {
        icon: string;
        uiLink: string;
        name: string;
        title: string;
        badgeRecord: unknown;
      }[];
    };
  };
  isSinglePanel: boolean;
  hideBottomNav: boolean;
  updateAppColors: (colors: Theme) => void;
};

export type Props = {
  children: ReactNode;
  bootstrapContainer: {
    denormalizedRecord: {
      accountNavbar: "/navbar";
    };
    substate: {
      isFetching: boolean;
      errorFetching: boolean;
    };
  };
  currentAccountSiteNavigation: object;
};

const shouldHideMobileNavigation = (pathname: string) => {
  // Pathname is in format "/<accountSlug>/inbox/<conversationFilter>/<conversationId>"
  // A <conversationId> will only be present if a conversation is selected
  const segments = pathname.split("/");

  if (segments.includes("inbox") && segments.length === 5) {
    // segments.length is 5 if a convo is selected, 4 if not
    const conversationId = segments.at(-1);
    return /^[a-zA-Z0-9]{6}$/.test(conversationId || ""); // e.g. "9UpREj"
  }

  return false;
};

function Layout(props: Props & ComposedProps): ReactNode {
  const {
    bootstrapContainer: {
      denormalizedRecord: bootstrap,
      substate: { isFetching, errorFetching },
    },
    currentAccountSiteNavigation,
    currentAccountNavbarElement,
    currentUser,
    hideBottomNav,
    isSinglePanel,
    updateAppColors: updateThemeMode,
  } = props;

  const location = useLocation();

  const hideMobileNavigation =
    hideBottomNav || shouldHideMobileNavigation(location.pathname);

  if (
    isFetching ||
    get(errorFetching, ["response", "title"], undefined) === "Unauthorized"
  ) {
    return <PageLoader logoWidth={70} inverse />;
  }
  if (
    get(errorFetching, ["response", "title"]) === "Forbidden" &&
    get(errorFetching, ["response", "url"], "").includes("/boot")
  )
    return <LoginFailedPage />;
  if (errorFetching) {
    return <NotFoundPage />;
  }

  const viewingGlobalNavigationRoute = globalNavigationRoutes.includes(
    location.pathname.split("/")[1],
  );

  const membershipAccounts = get(currentUser, ["memberships", "members"], []);
  const multiAccountUser = membershipAccounts.length > 1;

  const hideSiteNavigation = multiAccountUser && viewingGlobalNavigationRoute;

  const expandHeader = ["/dashboard", "/accounts"].includes(location.pathname);

  return (
    <>
      <Helmet>
        <title>
          {get(
            props.currentAccount,
            ["settings", "product", "value"],
            "TextUs",
          )}
        </title>
        <link
          rel="icon"
          type="image/png"
          href={
            get(
              props.currentAccount,
              ["settings", "mobileLogo", "value"],
              "/favicon.ico",
            ) as string
          }
          sizes="16x16"
        />
      </Helmet>
      <RedirectListener />

      {get(props.currentUser, ["featureFlags", "acceptTerms"]) && (
        <TermsOfUseAgreementModal />
      )}
      {!isEmpty(currentAccountSiteNavigation) && (
        <>
          <MainNavigationWrapper>
            <MainNavigation
              location={location}
              accountNavbarId={bootstrap.accountNavbar}
            >
              {({ accountNavbar, setCurrentAccount, handleTransitionEnd }) => {
                return (
                  <Root
                    aria-label="Account Navbar"
                    expandHeader={expandHeader}
                    onTransitionEnd={handleTransitionEnd}
                  >
                    <Nav expandHeader={expandHeader}>
                      <NavigationItems expandHeader={expandHeader}>
                        <Logo
                          aria-label="Company Logo"
                          currentAccount={props.currentAccount}
                          data-product-tour="LogoLink"
                          isSinglePanel={isSinglePanel}
                          to="/dashboard"
                        />

                        {!hideSiteNavigation && (
                          <SiteNavigation
                            siteNavigation={
                              currentAccountNavbarElement.siteNavigation
                            }
                          />
                        )}
                      </NavigationItems>
                      <Box
                        alignItems="center"
                        display="flex"
                        flex="0 1 auto"
                        minWidth="0"
                      >
                        <NavButtons isSinglePanel={isSinglePanel} />

                        <FindNumbersButton />

                        <Divider
                          orientation="vertical"
                          variant="middle"
                          flexItem
                          sx={{
                            borderColor: "#949eac",
                            margin: "8px",
                          }}
                        />

                        {currentUser.memberships.members.length === 1 ? (
                          <SingleAccount
                            currentAccount={props.currentAccount}
                          />
                        ) : (
                          <MultiAccountMenu
                            {...{
                              currentAccount: props.currentAccount,
                              accountNavbar,
                              currentUser,
                              onAccountChanged: setCurrentAccount,
                              viewingGlobalNavigationRoute,
                            }}
                          />
                        )}

                        <UserMenu
                          accountNavbar={accountNavbar}
                          appColors={props.appColors}
                          currentAccount={props.currentAccount}
                          currentUser={props.currentUser}
                          updateAppColors={updateThemeMode}
                        />
                      </Box>
                    </Nav>
                  </Root>
                );
              }}
            </MainNavigation>
          </MainNavigationWrapper>
          <UserOptionDrawer id="textus-UserOptionDrawer" />
          {props.children}
          <MobileBottomNavigation
            hideSiteNavigation={hideSiteNavigation || hideMobileNavigation}
            siteNavigation={currentAccountNavbarElement.siteNavigation}
          />
        </>
      )}
    </>
  );
}

const mapStateToProps = (state: unknown) => {
  return {
    appColors: selectAppColors(state),
    currentAccount: selectCurrentAccount(state),
    currentUser: selectCurrentUser(state),
    isHeaderTransitioning:
      animationSelectors.selectIsHeaderTransitioning(state),
    currentAccountNavbarElement:
      navigationSelectors.selectCurrentAccountNavbarElement(state),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  updateAppColors,
});

export default compose(
  withConnect,
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.lg,
      hideBottomNav: width >= breakpoints.sm, // 600px+
    };
  }),
)(Layout) as (p: Props) => JSX.Element;
