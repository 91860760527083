import { Props } from "./types";
import { validate, pattern } from "./validate";
import { Input } from "components/Input";
/**
 *  <Phone /> component
 *
 *  Text input with phone number validation
 *
 * @param {Props} props
 * @example <Phone name='phone' />
 * @returns HTML Input Element
 */
export function Phone({ inputProps, label, ...props }: Props) {
  return (
    <Input
      label={label ?? "Phone Number"}
      inputProps={{
        ...inputProps,
        type: "tel",
        pattern: pattern.source,
      }}
      placeholder="(555) 555-5555"
      {...props}
      type="tel"
      validate={validate}
    />
  );
}
