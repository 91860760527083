import isPrimitive from "utils/isPrimitive";

export function flattenMicrodata(element) {
  if (isPrimitive(element)) {
    return element;
  }

  const isArray = Array.isArray(element);
  if (isArray && element.length === 0) {
    return null;
  }

  if (isArray && element.length === 1) {
    const [only] = element;
    return flattenMicrodata(only);
  }

  if (isArray && element.length > 1) {
    return element.map(flattenMicrodata);
  }

  const { properties, type } = element;
  const singleLevel = { type, ...properties };
  const flatten = Object.entries(singleLevel).reduce(
    (acc, [prop, value]) => ({ ...acc, [prop]: flattenMicrodata(value) }),
    {},
  );
  return flatten;
}
