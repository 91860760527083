import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Typography from "@mui/material/Typography";
import { AnalyticsInfoModalProps, Headers } from "../models/AnalyticsModels";
import H3 from "components/H3";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

import createUUID from "utils/uuid";

const style = {
  display: "flex",
  flex: "0 0 auto",
};

function AnalyticsInfoModal({
  fullScreen,
  headers,
  title,
  handleInfoModalToggle,
  openInfoModal,
}: AnalyticsInfoModalProps) {
  const getDescription = (text: string) => {
    const caseSafeText = text.replace(/\s\s+/g, " ").toLowerCase();
    const includesFormula = caseSafeText.includes("formula for this rate is");
    if (includesFormula) {
      const [description, formula] = text.split(":");
      const [numerator, denominator] = formula.split("/");
      return (
        <>
          <Typography align="center" color="primary">
            {description}:
          </Typography>
          <Box mx="auto" width="fit-content">
            <Typography align="center" color="primary">
              {numerator}
            </Typography>
            <hr />
            <Typography align="center" color="primary">
              {denominator}
            </Typography>
          </Box>
        </>
      );
    }
    return <Typography color="primary">{text}</Typography>;
  };

  return (
    <>
      <IconButton
        onClick={handleInfoModalToggle}
        aria-label={`Open ${title} Report Modal`}
        size="large"
      >
        <InfoOutlinedIcon color="primary" fontSize="small" style={style} />
      </IconButton>
      <Modal
        ariaLabel={`${title} Report Modal`}
        closeModal={handleInfoModalToggle}
        fullScreen={fullScreen}
        maxHeight="500px"
        transitionIn={openInfoModal}
      >
        <ModalHeader closeModal={handleInfoModalToggle}>{title}</ModalHeader>
        <ModalContent maxWidth="100%">
          <Box minWidth="400px">
            {headers.map(({ title: header, description }: Headers) => {
              return (
                <Box key={createUUID()}>
                  <H3>{header}</H3>
                  {getDescription(description)}
                </Box>
              );
            })}
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AnalyticsInfoModal;
