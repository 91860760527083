/* eslint-disable react-hooks/exhaustive-deps */
import {
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { FilterKeys, SequenceCounts, SequenceValues, TabStates } from "./types";
import { AutomationNavValues } from "features/Automation/models/AutomationModels";
import { SECONDARY_NAV_LINKS } from "features/Automation/constants/AutomationConstants";
import type { Sequence } from "models/Sequence";
import {
  createSequence,
  fetchSequences,
  fetchSequenceCounts,
} from "features/Sequences/api";
import { useCurrentUser, useLoading, useDebouncedSearch } from "hooks";
import { Account as AccountType } from "models/Account";
import { SEQUENCE_STATES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

const useSequences = (
  currentAccount: AccountType,
  selectedNavLink: AutomationNavValues,
) => {
  const { mine } = SECONDARY_NAV_LINKS;

  const [selectedTab, setSelectedTab] = useState<TabStates>(0);
  const [sequenceValues, setSequenceValues] = useState<SequenceValues>({
    sequenceName: "",
    finishedOnReply: "",
    scheduleType: "",
  });
  const [sequenceList, setSequenceList] = useState<Sequence[]>([]);
  const [sequenceCounts, setSequenceCounts] = useState<SequenceCounts>({
    active: 0,
    archive: 0,
    inactive: 0,
  });
  const [allSequences, setAllSequences] = useState<Sequence[]>([]);

  const history = useHistory();
  const currentUser = useCurrentUser();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { searchTerm, debouncedSearchTerm, handleSearch, handleClear } =
    useDebouncedSearch();

  const strippedUserId = currentUser.id.replace("/users/", "");
  const selectedNavLinkKey: FilterKeys =
    selectedNavLink === mine ? "userIdEq" : "userIdNotEq";

  const getSearchFilters = useCallback(() => {
    return {
      searchTerm,
      selectedNavLinkKey,
      strippedUserId,
    };
  }, [searchTerm, selectedNavLinkKey, strippedUserId]);

  const fetchSequenceList = useCallback(
    async (filterResults = false) => {
      const response = await fetchSequences(
        currentAccount,
        filterResults ? getSearchFilters() : undefined,
      );
      const body = await response.json();

      return body;
    },
    [currentAccount, getSearchFilters],
  );

  /* Fetches all sequences using the GET /sequences endpoint without filters. */
  const fetchAllSequences = useCallback(async () => {
    const sequences = await fetchSequenceList();
    setAllSequences(sequences);
  }, [fetchSequenceList]);

  const getSequences = useCallback(async () => {
    startLoading();
    try {
      const body = await fetchSequenceList(true);
      setSequenceList(body);
    } catch (err) {
      throw new Error(`Error fetching Sequence List: ${err}`);
    }
    stopLoading();
  }, [fetchSequenceList]);

  const getSequenceCounts = useCallback(async () => {
    const filter = {
      selectedNavLinkKey,
      strippedUserId,
    };

    const response = await fetchSequenceCounts(currentAccount, filter);
    const body = await response.json();

    setSequenceCounts(body);
  }, [currentAccount, selectedNavLinkKey, strippedUserId]);

  useEffect(() => {
    getSequences();
    getSequenceCounts();
  }, [selectedNavLink, debouncedSearchTerm]);

  const handleCreateSequence = async (values: SequenceValues) => {
    try {
      startLoading();
      const response = await createSequence(currentAccount, values);
      if (!response.ok) {
        throw new Error("Bad network response");
      }
      const newSequence: Sequence = await response.json();
      setSequenceValues(values);
      history.push(`${history.location.pathname}/${newSequence.id}`);
    } catch (err) {
      throw new Error(`Error creating sequence: ${err}`);
    } finally {
      stopLoading();
    }
  };

  const handleTabChange = (
    _: SyntheticEvent,
    newValue: SetStateAction<TabStates>,
  ) => {
    setSelectedTab(newValue);
  };

  const getDisplayedSequences = useCallback(() => {
    switch (selectedTab) {
      case 0:
        return sequenceList.filter((sequence) => {
          return sequence.state === SEQUENCE_STATES.active;
        });
      case 1:
        return sequenceList.filter((sequence) => {
          return sequence.state === SEQUENCE_STATES.inactive;
        });
      case 2:
        return sequenceList.filter((sequence) => {
          return sequence.state === SEQUENCE_STATES.archive;
        });
      default:
        return sequenceList;
    }
  }, [selectedTab, sequenceList]);

  return {
    allSequences,
    debouncedSearchTerm,
    isLoadingSequence: isLoading,
    searchTerm,
    selectedTab,
    sequenceCounts,
    sequenceValues,
    fetchAllSequences,
    getDisplayedSequences,
    getSequences,
    getSequenceCounts,
    handleClear,
    handleCreateSequence,
    handleSearch,
    handleTabChange,
    setSequenceValues,
  };
};

export { useSequences };
