import { parse } from "query-string";
import { SetStateAction, useEffect, useState } from "react";
import assert from "assert";
import { get, isObject } from "lodash";
import { Query, fetchAccounts } from "../api";
import { Account } from "./types";
import type { Props } from "./types";

import { AccountCollection } from "features/AccountSettings/components/AccountConfiguration/models/AccountPlanModels";

type pageView = {
  lastUrl: string | null;
  pageUrl: string | null;
  next: SetStateAction<string | null>;
  count: number;
  firstUrl: string;
  nextUrl: null | string;
  previousUrl: null | string;
};

export const useAccounts = ({ slug }: Props, query: Query = {}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [accounts, setAccounts] = useState<
    Record<string, Account.Model> | undefined
  >(undefined);
  const [accountCollection, setAccountCollection] =
    useState<AccountCollection | null>(null);
  const [members, setMembers] = useState<AccountCollection["items"]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [view, setView] = useState<pageView>();

  // Reference valid query parameters.
  const { cursor, q } = query;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setAccounts(undefined);

      const response = await fetchAccounts(slug, query);

      const body: AccountCollection = await response.json();

      assert(isObject(body), "body must be an object");
      assert(Array.isArray(body?.items), "items must be defined");
      assert(Number.isInteger(body.page?.count), "count must be defined");
      assert(isObject(body?.page), "page must be an object");

      const data = Object.fromEntries(
        body.items.map((item): [Account.Model["id"], Account.Model] => {
          const id = get(item, "id");
          const accountSlug = get(item, "slug");
          const name = get(item, "name");

          assert(typeof id === "string", "item must have an id");
          assert(typeof accountSlug === "string", "item must have a slug");
          assert(typeof name === "string", "item must have a name");

          return [
            id,
            {
              id,
              slug: accountSlug,
              name,
            },
          ];
        }),
      );

      setAccounts(data);
      setAccountCollection(body);
      setMembers(body.items);
      setTotalItems(body.page.count);
      setView(body.page);
      setIsLoading(false);
    })();
  }, [slug, cursor, q]);

  const fetchNextPage = async () => {
    if (view)
      try {
        const response = await fetchAccounts(
          slug,
          view.nextUrl ? parse(view.nextUrl.split("?")[1]) : undefined,
        );

        const body: AccountCollection = await response.json();

        const data = Object.fromEntries(
          body.items.map((item): [Account.Model["id"], Account.Model] => {
            const id = get(item, "id");
            const accountSlug = get(item, "slug");
            const name = get(item, "name");

            assert(typeof id === "string", "item must have an id");
            assert(typeof accountSlug === "string", "item must have a slug");
            assert(typeof name === "string", "item must have a name");

            return [
              id,
              {
                id,
                slug: accountSlug,
                name,
              },
            ];
          }),
        );

        setAccounts({ ...accounts, ...data });
        setAccountCollection(body);
        setMembers(body.items);
        setView(body.page);
      } catch (err) {
        throw new Error(`Error fetching accounts: ${err}`);
      } finally {
        setIsLoading(false);
      }
  };

  return {
    isLoading,
    accounts,
    accountCollection,
    members,
    totalItems,
    fetchNextPage,
    view,
  };
};
