import { Identifiable } from "../types";
import { Account } from "./Account";
import { Contact } from "./Contact";
import { Participant } from "./Participant";
import { identify } from "./identify";

const root = "/conversations/";

export namespace Conversation {
  export interface Model {
    id: string;
  }

  export interface Raw {
    reopenConversation: unknown;
    participants: {
      id: string;
      members: Participant[];
      totalItems: number;
    };
    timeline: {
      id: string;
      members: [
        {
          retryAllowed: boolean;
          body: string;
          conversation: string;
          latestEventAt: string;
          flags: [];
          retry: null;
          timelinePosition: string;
          attachments: {
            id: string;
            members: unknown[];
            totalItems: number;
          };
          displayTimestamp: string;
          sender: {
            savedReplies: string;
            lastName: string;
            settings: { customSettings: object };
            automatedActor: boolean;
            name: string;
            hasSubAccounts: boolean;
            featureFlags: object;
            tosAcceptedAt: null;
            gettingStartedElements: string;
            topLevelAccounts: [string];
            hasPassword: boolean;
            uiConfig: object;
            signature: null;
            avatar: null;
            id: string;
            firstName: string;
            email: string;
            discarded: boolean;
            memberships: string;
          };
          friendlyStateDescription: string;
          source: string;
          id: string;
          deliveryState: string;
          formattedBody: string;
          direction: string;
        },
        {
          conversation: string;
          timelinePosition: string;
          user: {
            savedReplies: string;
            lastName: string;
            settings: { customSettings: object };
            automatedActor: boolean;
            name: string;
            hasSubAccounts: boolean;
            featureFlags: object;
            tosAcceptedAt: null;
            gettingStartedElements: string;
            topLevelAccounts: [string];
            hasPassword: boolean;
            uiConfig: object;
            signature: null;
            avatar: null;
            id: string;
            firstName: string;
            email: string;
            discarded: boolean;
            memberships: string;
          };
          assignee: {
            savedReplies: string;
            lastName: string;
            settings: { customSettings: object };
            automatedActor: boolean;
            name: string;
            hasSubAccounts: boolean;
            featureFlags: object;
            tosAcceptedAt: null;
            gettingStartedElements: string;
            topLevelAccounts: [string];
            hasPassword: boolean;
            uiConfig: object;
            signature: null;
            avatar: null;
            id: string;
            firstName: string;
            email: string;
            discarded: boolean;
            memberships: string;
          };
          displayTimestamp: string;
          type: string;
          id: `/conversation_events/${string}`;
        },
      ];

      totalItems: number;
      view: {
        id: string;
        first: string;
        next: string | null;
        previous: string | null;
      };
    };
    assignments: `/conversations/${string}/assignments`;
    slug: string;
    blocked: boolean;
    requestingUserInfo:
      | {
          id: string;
          read: boolean;
          starred?: boolean;
        }
      | string;
    account: Account.Raw;
    subscribeConversation: null;
    unstarConversation: `${typeof root}${string}/star`;
    latestPreviewableItem: { id: string; schema: string };
    blockConversation: `${typeof root}${string}/block`;
    assignContact: `${typeof root}${string}/assign_contact`;
    unreadConversation: `${typeof root}${string}/read`;
    assignedContact: Contact.Raw;
    unsubscribed: boolean;
    unsubscribeConversation: `${typeof root}${string}/subscribe`;
    starConversation: `${typeof root}${string}/star`;
    unanswered: boolean;
    unblockConversation: null;
    phoneNumber: string;
    currentState: string;
    readConversation: `${typeof root}${string}/read`;
    associatedContacts: {
      id: `${typeof root}${string}/associated_contacts`;
      members: Contact.Raw[];
      totalItems: number;
    };
    id: `${typeof root}${string}`;
    closeConversation: `${typeof root}${string}/close`;
    formattedPhoneNumber: string;
    stats: `${typeof root}${string}/stats`;
  }

  export type Like = Identifiable<Model>;

  export type Identifier = "id";

  export type Identity = Model[Identifier];
}

export const sanitizeConversationId = (id: Conversation.Identity) => {
  if (id.startsWith(root)) {
    return id.split("/")[2];
  }

  return id;
};

export const identifyConversation = (conversation: Conversation.Like) => {
  const id = identify(conversation);

  return sanitizeConversationId(id);
};
