import styled from "styled-components";
import { Props } from "./types";

export default styled.section<Props>`
  padding: ${(props) => {
    return props.noPadding ? 0 : 20;
  }}px;
  width: ${(props) => {
    return `${props.width}px` || "auto";
  }};
  max-width: ${(props) => {
    return props.maxWidth || 767;
  }}px;
  max-height: calc(
    100vh -
      ${(props) => {
        return props.theme.layout?.modalHeaderHeight ?? "60px";
      }}
  );
  overflow-y: ${(props) => {
    return props.overflowY || "scroll";
  }};
`;
