export const cumulativeBillingCards = {
  contractTermCard: {
    title: "Contract term",
    tooltip:
      "The length of time your organization is signed up for TextUs functionality.",
  },
  allowanceCard: {
    title: "SMS message allowance",
    tooltip:
      "The total number of SMS messages you can send throughout your contract term.",
  },
  usedCard: {
    title: "SMS messages used",
    tooltip:
      "The total number of SMS messages used by all of your accounts. An SMS message is 160 characters of basic text. An MMS message counts as 3 SMS messages.",
  },
} as const;
