import { assert } from "liquidjs";
import { userManager } from "@tesseract/core";
import { CLIENT_VERSION } from "utils/constants";

export const getHeaders = async () => {
  const user = await userManager.getUser();
  assert(user?.access_token);

  return {
    Accept: "application/vnd.textus+jsonld",
    "X-TextUs-Client": "tesseract",
    "X-TextUs-Client-Version": CLIENT_VERSION,
    Authorization: `Bearer ${user?.access_token}`,
  };
};
