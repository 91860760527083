import type { CSSProperties } from "react";

export const nameStyles: CSSProperties = {
  flex: "auto",
  flexGrow: 1,
  flexShrink: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
};

export const numbersStyles: CSSProperties = {
  flex: "192px",
  flexGrow: 0,
  flexShrink: 0,
};

export const childrenStyles: CSSProperties = {
  flex: "112px",
  flexGrow: 0,
  flexShrink: 0,
  textAlign: "center",
};

export const usersStyles: CSSProperties = {
  flex: "112px",
  flexGrow: 0,
  flexShrink: 0,
  textAlign: "center",
};

export const manageStyles: CSSProperties = {
  flex: "48px",
  flexGrow: 0,
  flexShrink: 0,
  textAlign: "center",
};
