/**
 * Is number odd?
 *
 * @param number
 * @returns {boolean}
 */
export const isOdd = (number: number): boolean => {
  return number % 2 === 1;
};

/**
 * Validate email
 *
 * @param {number} count
 * @param {string} suffix
 * @returns {string}
 */
export const s = (count: number, suffix = "s"): string => {
  return count === 1 ? "" : suffix;
};
