import styled, { withTheme } from "styled-components";
import classNames from "classnames";
import PropTypes from "prop-types";

export const Root = styled.svg`
  width: ${(props) => {
    return props.width;
  }}px;
  fill: ${(props) => {
    return props.fill;
  }};

  &.animate {
    [id^="dot"] {
      animation: fadeInOut 1.4s infinite ease-in-out both;
      transform-origin: center center;
    }

    #dot-1 {
      animation-delay: -0.32s;
    }

    #dot-2 {
      animation-delay: -0.16s;
    }
  }

  @keyframes fadeInOut {
    0%,
    80%,
    100% {
      opacity: 0;
    }

    40% {
      opacity: 1;
    }
  }
`;

const getFillColorMain = () => {
  return "url(#blue-green-gradient)";
};

const getFillColor = ({ color, theme }) => {
  if (color === "disabled") {
    return theme.colors.text.secondary;
  }
  if (["primary", "secondary"].includes(color)) {
    return theme.colors[color].main;
  }
  if (!color) {
    return theme.colors.accent.main === "#8CC63F"
      ? getFillColorMain()
      : theme.colors.accent.main;
  }
  return color;
};

const getDotsColor = ({ color, dotColor, theme }) => {
  if (dotColor === "contrast" || (!color && !dotColor)) {
    return theme.colors.accent.contrastText;
  }
  if (["primary", "secondary", "accent"].includes(dotColor)) {
    return theme.colors[dotColor].main;
  }
  if (color === "disabled" && !dotColor) {
    return theme.colors.background.paper;
  }
  if (["primary", "secondary", "accent"].includes(color) && !dotColor) {
    return theme.colors[color].contrastText;
  }
  return dotColor;
};

function Logo(props) {
  const {
    animate,
    className,
    color,
    dotColor,
    showName,
    theme,
    width = 45,
  } = props;
  const fillColor = getFillColor({ color, theme });
  const dotsColor = getDotsColor({ color, dotColor, theme });
  return (
    <Root
      className={classNames({ animate }, className)}
      width={width}
      animate={animate}
      fill={fillColor}
      viewBox={`0 0 ${showName ? 902.21 : 300} 248.77`}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="logo">
          <path
            id="Path"
            d="M140.55,0C62.89,0,0,53.36,0,119.23,0,159.05,23.09,194.34,58.43,216c0,2.66-.86,18-23,25.57,0,0,31.21,9.35,55.94-10.6a162.72,162.72,0,0,0,49.16,7.52C218.05,238.5,281,185,281,119.23S218,0,140.55,0Z"
            fill={fillColor}
          />
          <path
            id="dot-1"
            fill={dotsColor}
            d="M64.42,145A21.88,21.88,0,1,1,86.21,123,21.93,21.93,0,0,1,64.42,145Z"
          />
          <path
            id="dot-2"
            fill={dotsColor}
            d="M141.53,145A21.88,21.88,0,1,1,163.41,123,21.81,21.81,0,0,1,141.53,145Z"
          />
          <path
            id="dot-3"
            d="M218.84,145A21.88,21.88,0,1,1,240.64,123,21.9,21.9,0,0,1,218.84,145Z"
            fill={dotsColor}
          />
        </g>
        {showName && (
          <g id="Group-4" fill="#FFFFFF">
            <g>
              <path
                id="Path"
                d="M330.85,63.88h91.33V88.22H390.89V188.58H362.13V88.22H330.85Z"
              />
              <path
                id="Path"
                d="M478.35,190c-27.58,0-47.43-19.54-47.43-51s19.51-51,47.43-51c27.42,0,46.76,19.18,46.76,49.38a82.6,82.6,0,0,1-.5,8.88h-65.1c1,13,8.58,19,17.83,19,8.08,0,12.62-4.27,15-9.59h30.62C518.38,175.08,501.73,190,478.35,190Zm-18.67-60.57h36c0-10.84-8.08-17.05-17.66-17.05C468.6,112.38,461.36,118.41,459.68,129.43Z"
                fillRule="nonzero"
              />
              <path
                id="Path"
                d="M577.76,161l-15.14,27.54H531.5l31-50.63L530.66,89.46H563l18,27.36,15.14-27.36h31.12l-31.46,49.91,32.3,49.21h-32.3Z"
              />
              <path
                id="Path"
                d="M643.69,114.68H632.25V89.46h11.44V65.31h28.76V89.46h18.84v25.22H672.45v39.79c0,5.86,2.36,8.35,8.75,8.35h10.26v25.76H676.82c-19.51,0-33.13-8.71-33.13-34.46Z"
              />
              <path
                id="Path"
                d="M706.93,63.88h28.76v74.61c0,14.74,6.9,23.26,20.52,23.26s20.86-8.52,20.86-23.26V63.88h28.76v74.43c0,34.46-22.87,51.51-50.12,51.51-27.42,0-48.78-17-48.78-51.51Z"
              />
              <path
                id="Path"
                d="M864.19,190c-25.06,0-41.88-14.75-43.22-33.93h28.42c.67,6.93,6.56,11.55,14.47,11.55,7.4,0,11.27-3.56,11.27-8,0-16-51.14-4.44-51.14-40.85C824,101.9,837.62,88,862.17,88c24.22,0,37.68,14.21,39.53,33.75H875.13c-.85-6.75-5.72-11.19-13.8-11.19-6.73,0-10.43,2.84-10.43,7.64,0,15.8,50.8,4.61,51.31,41.56C902.21,177,887.74,190,864.19,190Z"
              />
            </g>
          </g>
        )}
      </g>
    </Root>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
  dotColor: PropTypes.string,
  width: PropTypes.number,
  animate: PropTypes.bool,
  className: PropTypes.string,
  showName: PropTypes.bool,
  theme: PropTypes.object.isRequired,
};

export default withTheme(Logo);
