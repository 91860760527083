/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux-immutable";
import { connectRouter } from "connected-react-router/immutable";
import { immutableReducer } from "redux-oidc";
import pusherReducer from "./features/PusherComponentV2/pusherReducer";
import history from "utils/history";

import globalReducer from "features/EntryPoint/containers/App/reducer";
import { reducer as animationReducer } from "features/EntryPoint/containers/Animation/state";
import { reducer as typingIndicatorsReducer } from "features/EntryPoint/containers/TypingIndicators/state";

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
  return combineReducers({
    router: connectRouter(history),
    global: globalReducer,
    oidc: immutableReducer,
    pusher: pusherReducer,
    animationContainer: animationReducer,
    typingIndicatorsContainer: typingIndicatorsReducer,
    ...injectedReducers,
  });
}
