import { useLocation } from "react-router";
import queryString from "query-string";
import get from "lodash/get";
import getPaginatedId from "utils/getPaginatedId";
import UserManagement from "pages/UserManagement/Loadable";
import { Accounts } from "pages/_account/settings/team-management/accounts";
import { Create } from "pages/_account/settings/team-management/accounts/create";
import { Account } from "models/Account";
import { SettingsSection } from "models/SettingsSection";
import { User } from "models/User";
import { OptionComponentProps } from "props/OptionComponentProps";

export const getTeamManagementSettings = ({
  role,
  account,
}: {
  role: string;
  account: Account;
}): SettingsSection | null => {
  const canSeeTeamManagement =
    ["organization", "root"].includes(account.type) &&
    ["admin", "support"].includes(role);

  const canSeeUsers = ["admin"].includes(role);

  if (!canSeeTeamManagement && !canSeeUsers) {
    return null;
  }

  return {
    title: "Team Management",
    path: "team-management",
    linkProps: {
      mt: "1rem",
    },
    settings: [
      ...(canSeeTeamManagement
        ? [
            {
              title: "Accounts",
              to: `/accounts`,
              OptionComponent: ({ toggleSidebar }: OptionComponentProps) => {
                return <Accounts toggleSidebar={toggleSidebar} />;
              },
            },
            {
              title: "Create Account",
              to: `/accounts/create`,
              linkProps: {
                sx: {
                  display: "none",
                },
              },
              OptionComponent: ({ toggleSidebar }: OptionComponentProps) => {
                return (
                  <Create account={account} toggleSidebar={toggleSidebar} />
                );
              },
            },
          ]
        : []),
      ...(canSeeUsers
        ? [
            {
              title: "Users",
              to: `/users`,
              OptionComponent: ({ toggleSidebar }: OptionComponentProps) => {
                const location = useLocation();
                return (
                  <UserManagement
                    toggleSidebar={toggleSidebar}
                    accountId={get(
                      queryString.parse(location.search, {
                        arrayFormat: "bracket",
                      }),
                      ["accounts", 0],
                    )}
                    userCollectionId={getPaginatedId("/users", {
                      location,
                    })}
                  />
                );
              },
            },
          ]
        : []),
    ],
  };
};
