import { useContext } from "react";
import { AppContext } from "contexts";
import { AppState } from "contexts/AppContext";

export const useAppContext = (): AppState => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("No context provided.");
  }

  return context;
};
