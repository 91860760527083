import { useState } from "react";
import { Link } from "react-router-dom";
import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MenuButtonProps } from "props/MenuBottonProps";
import { SEQUENCE_STATES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

/** Overflow menu rendered for each sequence data grid row. */
export function MenuButton({
  sequence,
  handleArchiveSequenceClick,
  handleDuplicateSequenceClick,
}: MenuButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (ev: any): void => {
    setAnchorEl(ev.currentTarget);
    ev.stopPropagation();
  };

  const handleMenuClose = (ev: any): void => {
    setAnchorEl(null);
    ev.stopPropagation();
  };

  return (
    <>
      <IconButton
        aria-controls={menuOpen ? `sequence-${sequence.id}` : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        onClick={handleMenuClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id={`sequence-${sequence.id}`}
        aria-labelledby={`sequence-${sequence.id}`}
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        slotProps={{
          root: {
            slotProps: {
              backdrop: {
                sx: {
                  backgroundColor: "transparent",
                  boxShadow: "none",
                },
              },
            },
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            width: "200px",
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          onClick={(ev: any) => {
            handleDuplicateSequenceClick(sequence);
            handleMenuClose(ev);
          }}
          data-testid="menu-duplicate-sequence"
        >
          Duplicate sequence
        </MenuItem>
        {sequence.state !== SEQUENCE_STATES.archive ? (
          <MenuItem
            onClick={(ev: any) => {
              handleArchiveSequenceClick(sequence);
              handleMenuClose(ev);
            }}
            data-testid="menu-archive-sequence"
          >
            Archive sequence
          </MenuItem>
        ) : null}
      </Menu>
    </>
  );
}

export const StyledCellLink = styled(Link)({
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
  textDecoration: "none",
  color: "inherit",
  ":hover": {
    textDecoration: "underline",
  },
});
