import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";

import { useAccountSettingsSubmit } from "../hooks";
import BasicSettings from "./BasicSettings";

import { AccountSettingsRouterProps } from "props/AccountSettingsRouterProps";

function AccountSettingsRouter({
  account,
  currentUser,
  isNavbarOpen,
  routes,
  isAdmin,
  toggleSidebar,
}: AccountSettingsRouterProps) {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const { updateAccountRequest, handleSubmit } = useAccountSettingsSubmit();

  return (
    <Switch>
      {account.featureFlags.settingsSidebarRedesign && (
        <Route exact path={`${match.path}/account-settings`}>
         <BasicSettings
           handleSubmit={handleSubmit}
           account={account}
           toggleSidebar={toggleSidebar}
         />
       </Route>
      )}
      {routes.map(({ path, title, settings }) => {
        return (
          <Route key={title} path={`${match.path}/${path}`}>
            <Switch>
              {settings.map(({ OptionComponent, to }) => {
                return (
                  <Route key={to} exact path={`${match.path}/${path}${to}`}>
                    <OptionComponent
                      updateAccountRequest={updateAccountRequest}
                      handleSubmit={handleSubmit}
                      currentUser={currentUser}
                      history={history}
                      isNavbarOpen={isNavbarOpen}
                      location={location}
                      account={account}
                      isAdmin={isAdmin}
                      optOutsCollectionId={account.optOuts}
                      integrationCollectionId={
                        typeof account.integrations === "string"
                          ? account.integrations
                          : account.integrations?.id
                      }
                      toggleSidebar={toggleSidebar}
                      userSchemaId={account.userSchema}
                    />
                  </Route>
                );
              })}
              <Route
                path={`${match.path}/${path}`}
                render={() => {
                  return (
                    <Redirect to={`${match.path}/${path}${settings[0].to}`} />
                  );
                }}
              />
            </Switch>
          </Route>
        );
      })}
      {account.featureFlags.settingsSidebarRedesign ? (
        <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`${match.path}/account-settings`} />;
        }}
      />
      ): (
        <Route
        path={`${match.path}`}
        render={() => {
          return <Redirect to={`${match.path}/${routes[0].path}`} />;
        }}
      />
      )}
    </Switch>
  );
}

export default AccountSettingsRouter;
