import {
  Box,
  Breadcrumbs,
  Divider,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { IntegrationDetailsActions } from "../IntegrationDetailsActions/IntegrationDetailsActions";
import { IntegrationDetailsFooter } from "../IntegrationDetailsFooter";
import { IntegrationDetailsGeneralTab } from "../IntegrationDetailsGeneralTab";
import { IntegrationDetailsMessageLoggingTab } from "../IntegrationDetailsMessageLoggingTab/IntegrationDetailsMessageLoggingTab";
import { IntegrationDetailsContactsTab } from "../IntegrationDetailsContactsTab";
import { integrationDetailsTabs } from "./constants";
import { IntegrationDetailsProps } from "./types";
import { TabPanel } from "components/TabPanel";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import PageHeader from "components/Page/PageHeader";
import { IntegrationProvider } from "models/IntegrationProvider";

export function IntegrationDetails({
  allIntegrations,
  selectedIntegration,
  setSelectedIntegration,
  toggleSidebar,
}: IntegrationDetailsProps) {
  const [currentTab, setCurrentTab] = useState(0);

  // Represents the selected integration's updated config and settings values
  const [updatedIntegrationData, setUpdatedIntegrationData] =
    useState(selectedIntegration);

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const { general, noteLogging, contacts } = integrationDetailsTabs;

  // Represents the selected integration's initial config and settings values
  const initialIntegrationValues = Object.freeze(selectedIntegration);

  const getIntegrationState = () => {
    if (selectedIntegration?.provider === "webhook") {
      const webhookConfigState = {
        baseUrl: {
          label: "Webhook URL",
          type: "text",
        },
        webhookSecret: {
          label: "Webhook Secret",
          type: "text",
        },
      };
      return {
        provider: "webhook",
        providerName: "Webhook",
        strategy: "custom",
        config: webhookConfigState,
        settings: {},
      };
    }
    return allIntegrations.find((integration: IntegrationProvider) => {
      const matchingIntegration =
        integration.providerName === selectedIntegration?.providerName;
      return Object.freeze(matchingIntegration);
    });
  };
  
  // Represents the selected integration's config and settings fields, strategy, and formatted name.
  const integrationState = getIntegrationState();

  const handleTabChange = (event: SyntheticEvent, newTabValue: number) => {
    setCurrentTab(newTabValue);
  };

  return (
    <Box flexDirection="column" height="80%">
      <PageHeader
        aria-label="Integration's Detail Page"
        title={
          <Breadcrumbs>
            <Typography
              onClick={() => {
                return setSelectedIntegration(null);
              }}
              sx={{
                color: "text.secondary",
                fontSize: "1rem",
                lineHeight: "1.5rem",
                textDecoration: "none",
                ":hover": { textDecoration: "underline" },
              }}
            >
              Integrations
            </Typography>
            <Typography
              fontSize="1rem"
              lineHeight="1.5rem"
              color="text.hyperlink"
              fontWeight={800}
            >
              {integrationState?.providerName ?? ""}
            </Typography>
          </Breadcrumbs>
        }
        toggleSidebar={toggleSidebar}
      />
      <Box
        display="flex"
        flexDirection={mobileScreen ? "column" : "row"}
        alignContent="center"
        justifyContent="space-between"
        paddingX="16px"
        paddingTop="16px"
        paddingBottom="8px"
      >
        <Typography
          sx={{
            paddingBottom: mobileScreen ? "8px" : "0",
          }}
          variant="h3"
        >
          {integrationState?.providerName ?? ""}
        </Typography>
        <IntegrationDetailsActions
          setSelectedIntegration={setSelectedIntegration}
          setUpdatedIntegrationData={setUpdatedIntegrationData}
          updatedIntegrationData={updatedIntegrationData}
        />
      </Box>
      <Tabs
        aria-label="General, note logging, and contacts tabs"
        data-testid="IntegrationDetailsTabs"
        indicatorColor="primary"
        onChange={handleTabChange}
        textColor="primary"
        scrollButtons={mobileScreen}
        variant="scrollable"
        allowScrollButtonsMobile
        value={currentTab}
      >
        {integrationState?.strategy === "custom" && (
          <Tab aria-label="General-tab" label="General" value={general} />
        )}
        {integrationState?.settings.individualNoteLogging && (
          <Tab
            aria-label="Messag-logging-tab"
            label="Message Logging"
            value={noteLogging}
          />
        )}
        {integrationState?.settings.contactSync && (
          <Tab aria-label="Contacts-tab" label="Contacts" value={contacts} />
        )}
      </Tabs>
      <Divider />
      <SettingsPageContent
        aria-label="Integration Details"
        maxWidth={mobileScreen ? "90%" : "80%"}
      >
        <TabPanel value={currentTab} index={0}>
          <IntegrationDetailsGeneralTab
            initialIntegrationValues={initialIntegrationValues}
            integrationState={integrationState}
            setUpdatedIntegrationData={setUpdatedIntegrationData}
            updatedIntegrationData={updatedIntegrationData}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <IntegrationDetailsMessageLoggingTab
            initialIntegrationValues={initialIntegrationValues}
            integrationState={integrationState}
            setUpdatedIntegrationData={setUpdatedIntegrationData}
            updatedIntegrationData={updatedIntegrationData}
          />
        </TabPanel>
        <TabPanel value={currentTab} index={2}>
          <IntegrationDetailsContactsTab
            initialIntegrationValues={initialIntegrationValues}
            integrationState={integrationState}
            setUpdatedIntegrationData={setUpdatedIntegrationData}
            updatedIntegrationData={updatedIntegrationData}
          />
        </TabPanel>
      </SettingsPageContent>
      <IntegrationDetailsFooter
        initialIntegrationValues={initialIntegrationValues}
        setSelectedIntegration={setSelectedIntegration}
        updatedIntegrationData={updatedIntegrationData}
      />
    </Box>
  );
}
