import queryString from "query-string";
import { endOfDay, startOfDay, subDays } from "date-fns";

export default (
  account,
  startDate = subDays(startOfDay(new Date()), 8).toISOString(),
  endDate = subDays(endOfDay(new Date()), 1).toISOString(),
) => {
  const params = {
    startDate,
    endDate,
  };

  return `${account.analytics}?${queryString.stringify(params)}`;
};
