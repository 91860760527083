import { Box, Typography } from "@mui/material";
import { DeleteModalProps } from "../models/AutomationModels";
import { Bubble, MessageTipWrapper, MessageBody } from "./styles";
import AttachmentPreview from "features/Campaigns/components/AttachmentPreview";
import MessageBubbleTip from "components/ConversationThread/MessageBubbleTip";
import { SimpleDialog } from "components/SimpleDialog";
import { DialogFooter } from "components/DialogFooter";

function DeleteModal({
  handleClose,
  keywordValues,
  open,
  handleDeleteKeyword,
}: DeleteModalProps) {
  const {
    autoResponse = "",
    id = "",
    keyword = "",
    attachments,
  } = keywordValues;

  const handleSubmit = () => {
    return handleDeleteKeyword(id);
  };

  return (
    <SimpleDialog title="Delete keyword?" open={open} handleClose={handleClose}>
      <Box display="flex" justifyContent="space-around" margin="1rem">
        <Box>
          <Typography fontWeight={400}>
            Are you sure you want to delete keyword: <strong>{keyword}</strong>,
          </Typography>
          <Typography fontWeight={400}>with the auto-response:</Typography>
          <Box my={2} display="flex" flexDirection="column" alignItems="end">
            <Bubble>
              <MessageBody>
                {!!attachments?.length && (
                  <AttachmentPreview attachment={attachments[0]} />
                )}
                <p>{autoResponse}</p>
              </MessageBody>
              <MessageTipWrapper>
                <MessageBubbleTip direction="out" />
              </MessageTipWrapper>
            </Bubble>
          </Box>
        </Box>
      </Box>
      <DialogFooter onConfirm={handleSubmit} onCancel={handleClose} />
    </SimpleDialog>
  );
}

export default DeleteModal;
