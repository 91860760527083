import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import { actionGenerators, saga, reducer, selectors } from "./state";
import Root from "./components/Root";
import Loading from "./components/Loading";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import {
  selectAppSettings,
  selectCurrentAccount,
} from "features/EntryPoint/containers/App/selectors";
import { selectors as typingIndicatorsSelectors } from "features/EntryPoint/containers/TypingIndicators/state";

class ConversationPreview extends Component {
  static propTypes = {
    conversationId: PropTypes.string.isRequired,
    conversationPreviewContainer: PropTypes.object.isRequired,
    fetchConversationRequest: PropTypes.func.isRequired,
    handleConversationNotFound: PropTypes.func.isRequired,
    handleReturnedConversationId: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.fetchConversationRequest(this.props.conversationId);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { errorFetching } = nextProps.conversationPreviewContainer.substate;
    if (errorFetching) this.props.handleConversationNotFound();
    const { returnedConversationId } =
      nextProps.conversationPreviewContainer.substate;
    if (
      returnedConversationId !==
      this.props.conversationPreviewContainer.substate.returnedConversationId
    ) {
      this.props.handleReturnedConversationId(returnedConversationId);
    }
    if (
      nextProps.conversationId.includes("+") &&
      nextProps.conversationId !== this.props.conversationId
    ) {
      this.props.fetchConversationRequest(nextProps.conversationId);
    }
  }

  render() {
    const { isFetching } = this.props.conversationPreviewContainer.substate;
    if (isFetching) {
      return <Loading />;
    }
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    appSettings: selectAppSettings(state, props),
    currentAccount: selectCurrentAccount(state, props),
    conversationPreviewContainer: selectors.selectConversationPreview(
      state,
      props,
    ),
    typingIndicatorsContainer: typingIndicatorsSelectors.selectTypingIndicators(
      state,
      props,
    ),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);
const withReducer = injectReducer({
  key: "conversationPreviewContainer",
  reducer,
});
const withSaga = injectSaga({ key: "conversationPreviewContainer", saga });

export default compose(withReducer, withSaga, withConnect)(ConversationPreview);
