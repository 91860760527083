import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import Button from "@mui/material/Button";
import queryString from "query-string";

import CONSTANTS from "./constants";
import ModalHeader from "components/ModalHeader";
import ModalContent from "components/ModalContent";

const Logo = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  background: url("${(props) => {
    return props.src;
  }}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const StyledModalContent = styled(ModalContent)`
  min-width: 300px;
`;

const Footer = styled.footer`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 20px;
`;

class JobAdder extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    appSettings: PropTypes.object.isRequired,
    provider: PropTypes.object.isRequired,
    fetchIntegrationCollectionRequest: PropTypes.func.isRequired,
    deleteIntegrationRequest: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    if (this.windowInterval) {
      clearInterval(this.windowInterval);
      localStorage.removeItem(CONSTANTS.localStorageKey);
    }
  }

  openAuthWindow = () => {
    const width = 800;
    const height = 800;
    const { JOB_ADDER_KEY } = this.props.appSettings;
    const accountIntegrationId = get(this.props.account, ["integrations"]);

    localStorage.setItem(CONSTANTS.localStorageKey, accountIntegrationId);

    const query = queryString.stringify({
      client_id: JOB_ADDER_KEY,
      response_type: "code",
      redirect_uri: CONSTANTS.url,
      scope: "offline_access write read",
    });
    const jobAdderUrl = `https://id.jobadder.com/connect/authorize?${query}`;

    const left = (window.screen.width - width) / 2 - 16;
    const top = (window.screen.height - height) / 2 - 50;
    const windowFeatures = [
      "menubar=no",
      "toolbar=no",
      "status=no",
      `width=${width}`,
      `height=${height}`,
      `left=${left}`,
      `top=${top}`,
    ];

    this.window = window.open(jobAdderUrl, null, windowFeatures);

    // https://stackoverflow.com/questions/15769514/window-onclose-function
    this.windowInterval = setInterval(() => {
      if (this.window.closed) {
        this.props.provider.closeModal();
        this.props.fetchIntegrationCollectionRequest(
          this.props.account.integrations.id,
        );
        clearInterval(this.windowInterval);
      }
    }, 1000);
  };

  deleteIntegration = () => {
    this.props.deleteIntegrationRequest(
      this.props.provider.integration.id,
      null,
      {
        successCallback: () => {
          this.props.fetchIntegrationCollectionRequest(
            this.props.account.integrations.id,
          );
          this.props.provider.closeModal();
        },
      },
    );
  };

  render() {
    const { provider } = this.props;
    return (
      <>
        <ModalHeader closeModal={provider.closeModal}>
          {provider.name}
        </ModalHeader>
        <StyledModalContent>
          <Logo src={provider.logo} />
        </StyledModalContent>
        <Footer>
          <Button
            variant="contained"
            color="primary"
            onClick={
              provider.integration
                ? this.deleteIntegration
                : this.openAuthWindow
            }
          >
            {provider.integration ? "Deauthorize" : "Authorize"}
          </Button>
        </Footer>
      </>
    );
  }
}

export default JobAdder;
