import { Component } from "react";
import { compose } from "redux";
import { saga, reducer } from "./state";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";

class TypingIndicators extends Component {
  render() {
    return null;
  }
}

const withReducer = injectReducer({
  key: "typingIndicatorsContainer",
  reducer,
});
const withSaga = injectSaga({ key: "typingIndicatorsContainer", saga });

export default compose(withReducer, withSaga)(TypingIndicators);
