import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import TextField from "@mui/material/TextField";
import { enqueueSnackbar } from "notistack";
import SuccessOverlay from "./SuccessOverlay";
import Page from "features/Authentication/components/Page";
import H3 from "components/H3";
import { ResetPasswordValidationSchema } from "formHelpers/validationSchemas";
import Button from "features/Authentication/components/PrimaryButton";
import LoadingOverlay from "features/Authentication/components/LoadingOverlay";

const Root = styled(Page)``;

const MainContent = styled.div`
  ${(props) => {
    return props.isActive
      ? props.theme.mixins.fadeShow
      : props.theme.mixins.fadeHide;
  }};
`;

const Fields = styled.div`
  margin: 20px 0 0;
`;

export default class Main extends Component {
  static propTypes = {
    performResetPasswordRequest: PropTypes.func.isRequired,
  };

  constructor() {
    super();

    this.state = {
      wasSuccessful: false,
      redirectUrl: false,
    };
  }

  componentDidMount() {
    this.checkForErrors();
  }

  checkForErrors = () => {
    const error = get(window, ["error"]);
    if (error) {
      this.showError(error.detail);
    }
  };

  showError = (error) => {
    return enqueueSnackbar(error, { variant: "error" });
  };

  successCallback = (actions) => {
    actions.setSubmitting(false);

    this.setState({ wasSuccessful: true });

    setTimeout(() => {
      this.setState({
        redirectUrl: "/",
      });
    }, 5000);
  };

  errorCallback = (actions, error) => {
    actions.setSubmitting(false);
    this.showError(error);
  };

  handleSubmit = ({ password }, actions) => {
    return this.props.performResetPasswordRequest({
      password,
      successCallback: () => {
        return this.successCallback(actions);
      },
      errorCallback: (error) => {
        return this.errorCallback(actions, error);
      },
    });
  };

  renderForm = () => {
    const { wasSuccessful } = this.state;

    return (
      <Formik
        initialValues={{ password: "", passwordConfirm: "" }}
        validationSchema={ResetPasswordValidationSchema}
        onSubmit={this.handleSubmit}
      >
        {({ errors, touched, isSubmitting }) => {
          return (
            <Form>
              <SuccessOverlay isActive={wasSuccessful} />
              <MainContent isActive={!wasSuccessful}>
                <span role="img" aria-label="key emoji">
                  🔑
                </span>
                <H3>Reset Your Password</H3>

                {isSubmitting && <LoadingOverlay />}

                <Fields>
                  <Field name="password">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="password"
                          type="password"
                          error={touched.password && errors.password}
                          fullWidth
                          label="Password"
                          placeholder="Please enter your password"
                          helperText={
                            touched.password ? errors.password || "" : ""
                          }
                          variant="standard"
                        />
                      );
                    }}
                  </Field>

                  <Field name="passwordConfirm">
                    {({ field }) => {
                      return (
                        <TextField
                          {...field}
                          id="passwordConfirm"
                          type="password"
                          error={
                            touched.passwordConfirm && errors.passwordConfirm
                          }
                          fullWidth
                          label="Confirm Password"
                          placeholder="Re-type your new password"
                          helperText={
                            touched.passwordConfirm
                              ? errors.passwordConfirm || ""
                              : ""
                          }
                          variant="standard"
                        />
                      );
                    }}
                  </Field>
                </Fields>

                <br />

                <Button type="submit" disabled={isSubmitting}>
                  Reset Password
                </Button>
              </MainContent>
            </Form>
          );
        }}
      </Formik>
    );
  };

  render() {
    const { redirectUrl } = this.state;
    return (
      <Root>
        {redirectUrl && <Redirect to={redirectUrl} />}
        {this.renderForm()}
      </Root>
    );
  }
}
