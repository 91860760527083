import { useState } from "react";

import AddLinkIcon from "@mui/icons-material/AddLink";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { ShortenedLinkOptionProps } from "../models";
import ShortenedLinkModal from "./ShortenedLinkModal";
import { isDefined } from "utils/isDefined";

function ShortenedLinkOption({
  closeExpandedActionsMenu,
  selectionHandler,
  setShortenedLink,
  shortenedLink,
  disabled,
}: ShortenedLinkOptionProps) {
  const [modalOpen, setModalOpen] = useState(false);

  const iconDisabled = shortenedLink?.shortLink === null || disabled;

  const handleToggle = () => {
    setModalOpen(() => {
      return !modalOpen;
    });

    if (modalOpen && isDefined(closeExpandedActionsMenu)) {
      closeExpandedActionsMenu();
    }
  };

  return (
    <>
      <ShortenedLinkModal
        shortenedLink={shortenedLink}
        handleToggle={handleToggle}
        open={modalOpen}
        selectionHandler={selectionHandler}
        setShortenedLink={setShortenedLink}
      />
      <Tooltip
        title={
          iconDisabled ? "Shorten Link (Enable in Settings)" : "Shorten Link"
        }
      >
        <span>
          {/* span wrapper required for tooltip to display on disabled button */}
          <IconButton
            aria-label="Shortened Link Icon Button"
            disabled={iconDisabled}
            onClick={handleToggle}
            size="large"
            data-testid="short-link-icon-button"
          >
            <AddLinkIcon color="inherit" />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}

export default ShortenedLinkOption;
