import { Grayscale } from "./types";

export const grayscale: Grayscale = {
  0: "#FFFFFF",
  50: "#F7FBFF",
  100: "#F3F7FA",
  200: "#EEF2F5",
  300: "#E2E6E9",
  400: "#BFC3C7",
  500: "#A1A5A8",
  600: "#787B7E",
  700: "#63676A",
  800: "#44484A",
  900: "#232629",
  1000: "#000000",
  alphas: {
    black: {
      A8: "rgba(0, 0, 0, 0.08)",
      A12: "rgba(0, 0, 0, 0.12)",
      A16: "rgba(0, 0, 0, 0.16)",
      A30: "rgba(0, 0, 0, 0.30)",
      A50: "rgba(0, 0, 0, 0.50)",
    },
    white: {
      A8: "rgba(255, 255, 255, 0.08)",
      A12: "rgba(255, 255, 255, 0.12)",
      A16: "rgba(255, 255, 255, 0.16)",
      A30: "rgba(255, 255, 255, 0.30)",
      A50: "rgba(255, 255, 255, 0.50)",
    },
  },
};
