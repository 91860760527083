import { styled, tooltipClasses, Tooltip as BaseTooltip } from "@mui/material";
import { Props } from "./types";

export const Tooltip = styled(({ className, ...props }: Props) => {
  return <BaseTooltip {...props} classes={{ popper: className }} />;
})(({ theme }) => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[1],
      color: theme.palette.text.primary,
      fontSize: 16,
      fontWeight: theme.typography.body1.fontWeight,
      maxWidth: 444,
      padding: 16,
    },
  };
});
