import BlueSkyStrategy from "phoneExtraction/BlueSkyStrategy";
import BullhornStrategy from "phoneExtraction/BullhornStrategy";
import BullhornNovoStrategy from "phoneExtraction/BullhornNovoStrategy";
import CareerBuilderStrategy from "phoneExtraction/CareerBuilderStrategy";
import CHGFoxStrategy from "phoneExtraction/CHGFoxStrategy";
import DataAttributeStrategy from "phoneExtraction/DataAttributeStrategy";
import ErecruitStrategy from "phoneExtraction/ErecruitStrategy";
import FullPageSearchStrategy from "phoneExtraction/FullPageSearchStrategy";
import GreenhouseStrategy from "phoneExtraction/GreenhouseStrategy";
import HealthcareSourceStrategy from "phoneExtraction/HealthcareSourceStrategy";
import JobAdderStrategy from "phoneExtraction/JobAdderStrategy";
import JobDivaStrategy from "phoneExtraction/JobDivaStrategy";
import LaborEdgeStrategy from "phoneExtraction/LaborEdgeStrategy";
import LaborEdgeNexusStrategy from "phoneExtraction/LaborEdgeNexusStrategy";
import MicrosoftDynamicsStrategy from "phoneExtraction/MicrosoftDynamicsStrategy";
import PulseStrategy from "phoneExtraction/PulseStrategy";
import SalesforceLightningStrategy from "phoneExtraction/SalesforceLightningStrategy";
import SalesforceStrategy from "phoneExtraction/SalesforceStrategy";
import SelectionStrategy from "phoneExtraction/SelectionStrategy";
import MicrodataStrategy from "phoneExtraction/MicrodataStrategy";
import RdfaStrategy from "phoneExtraction/RdfaStrategy";
import JsonLinkedDataStrategy from "phoneExtraction/JsonLinkedDataStrategy";
import findAndReturn from "phoneExtraction/utils/findAndReturn";

export default class PhoneExtraction {
  constructor({
    activeFrameAttributes,
    activeFrameHtml,
    countryCode,
    html,
    location,
    nodeSelected,
  }) {
    this.activeFrameAttributes = activeFrameAttributes;
    this.activeFrameHtml = activeFrameHtml;
    this.html = html;
    this.location = location;
    this.nodeSelected = nodeSelected;
    this.parser = new DOMParser();
    window.countryCode = countryCode;
  }

  strategies = [
    BullhornNovoStrategy,
    BullhornStrategy,
    SelectionStrategy,
    CHGFoxStrategy,
    SalesforceLightningStrategy,
    SalesforceStrategy,
    DataAttributeStrategy,
    RdfaStrategy,
    MicrodataStrategy,
    JsonLinkedDataStrategy,
    BlueSkyStrategy,
    ErecruitStrategy,
    GreenhouseStrategy,
    HealthcareSourceStrategy,
    JobAdderStrategy,
    JobDivaStrategy,
    LaborEdgeStrategy,
    LaborEdgeNexusStrategy,
    MicrosoftDynamicsStrategy,
    CareerBuilderStrategy,
    PulseStrategy,
    FullPageSearchStrategy,
  ];

  parse = () =>
    findAndReturn(this.strategies)(Strategy =>
      new Strategy({
        ...(this.activeFrameHtml && {
          activeFrameAttributes: this.activeFrameAttributes,
          activeFrameParsedHtml: this.parser.parseFromString(
            this.activeFrameHtml,
            "text/html",
          ),
        }),
        parsedHtml: this.parser.parseFromString(this.html, "text/html"),
        location: this.location,
        nodeSelected: this.nodeSelected,
      }).parse(),
    );
}
