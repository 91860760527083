import styled from "styled-components";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelDetails from "components/ExpansionPanel/Details";
import H4 from "components/H4";

export const StyledExpansionPanel = styled(ExpansionPanel)`
  width: 100%;
`;

export const StyledExpansionPanelDetails = styled(ExpansionPanelDetails)`
  padding: 16px 0 !important;

  ${H4} {
    margin-bottom: 15px;
  }
`;

export const InputDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 14px;
  margin-top: 4px;
  margin-bottom: -6px;
`;
