import styled from "styled-components";
import BaseButton from "./BaseButton";

const SecondaryButton = styled(BaseButton)`
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  border-color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  background-color: "transparent";

  &:hover,
  &:active {
    color: ${(props) => {
      return props.theme.colors.primary.contrastText;
    }};
    border-color: ${"transparent"};
    background-color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

export default SecondaryButton;
