import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import { NewKeywordProps } from "features/Keywords/types";

function NewKeyword({
  isSinglePanel,
  showSidebar,
  toggleSidebar,
}: NewKeywordProps) {
  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader title="Create Keyword" toggleSidebar={toggleSidebar} />
    </PageContent>
  );
}

export default NewKeyword;
