import get from "lodash/get";
import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import withSizes from "react-sizes";
import { compose } from "redux";
import { Theme } from "@tesseract/theme";
import { Location } from "history";
import { Account, User } from "@tesseract/core";
import { actionGenerators, reducer, saga, selectors } from "./state";

import { updateAppColors } from "features/EntryPoint/containers/App/actions";
import {
  selectAppSettings,
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import { getTotalUnansweredCount } from "features/MainNavigation/utils";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import breakpoints from "utils/styles/breakpoints";

type ComposedProps = {
  accountNavbarContainer: {
    denormalizedRecord: {
      members: {
        id: string;
        account: Account.Raw;
        displayPhoneNumber: string;
      }[];
    };
  };
  appColors: Theme;
  appSettings: {
    CHROME_EXTENSION_URLS: string[];
  };
  currentAccount: Account.Raw;
  currentAccountNavbarElement: {
    siteNavigation: {
      members: {
        icon: string;
        uiLink: string;
        name: string;
        title: string;
        badgeRecord: unknown;
      }[];
    };
  };
  currentUser: User.Raw;
  isSinglePanel: boolean;
  setCurrentAccount: () => void;
  setIsHeaderTransitioning?: (is: boolean) => void;
  updateAppColors: () => void;
};

type Props = {
  accountNavbarId: "/navbar";
  location: Location<unknown>;
  children: (props: {
    accountNavbar: ComposedProps["accountNavbarContainer"]["denormalizedRecord"];
    setCurrentAccount: ComposedProps["setCurrentAccount"];
    handleTransitionEnd: () => void;
  }) => ReactNode;
};

class MainNavigation extends Component<Props & ComposedProps> {
  transitionEndTimeout: string | number | NodeJS.Timeout | undefined;

  constructor(props: Props & ComposedProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    if (window.self !== window.top) {
      const updatedCount = this.getTotalUnansweredCount(this.props);
      this.sendUpdateBadgeMessage(updatedCount);
    }

    this.setHeaderTransitionState(false);
  }

  componentDidUpdate(prevProps: Props & ComposedProps) {
    if (
      window.self !== window.top &&
      this.getTotalUnansweredCount(prevProps) !==
        this.getTotalUnansweredCount(this.props)
    ) {
      const updatedCount = this.getTotalUnansweredCount(this.props);
      this.sendUpdateBadgeMessage(updatedCount);
    }

    if (
      prevProps.location.pathname !== this.props.location.pathname &&
      !this.props.isSinglePanel &&
      [this.props.location.pathname, prevProps.location.pathname].includes(
        "/dashboard",
      )
    ) {
      this.setHeaderTransitionState(true);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.transitionEndTimeout);
  }

  getTotalUnansweredCount = (props: ComposedProps) => {
    return getTotalUnansweredCount(
      get(props, ["accountNavbarContainer", "denormalizedRecord"], {}),
    );
  };

  setHeaderTransitionState = (isHeaderTransitioning: boolean) => {
    this.props.setIsHeaderTransitioning?.(isHeaderTransitioning);
  };

  sendUpdateBadgeMessage = (updatedCount: unknown) => {
    this.props.appSettings.CHROME_EXTENSION_URLS.forEach(
      (chromeExtensionUrl) => {
        window.parent.postMessage(
          { type: "updateBadge", updatedCount },
          chromeExtensionUrl,
        );
      },
    );
  };

  handleTransitionEnd = () => {
    this.transitionEndTimeout = setTimeout(() => {
      return this.setHeaderTransitionState(false);
    }, 200);
  };

  render() {
    const {
      accountNavbarContainer: { denormalizedRecord: accountNavbar },
      setCurrentAccount,
    } = this.props;

    return this.props.children({
      accountNavbar,
      setCurrentAccount,
      handleTransitionEnd: this.handleTransitionEnd,
    });
  }
}

const mapStateToProps = (state: unknown, props: unknown) => {
  return {
    accountNavbarContainer: selectors.selectAccountNavbar(state, props),
    appSettings: selectAppSettings(state),
    currentAccount: selectCurrentAccount(state),
    currentAccountNavbarElement: selectors.selectCurrentAccountNavbarElement(
      state,
      props,
    ),
    currentUser: selectCurrentUser(state),
  };
};

const withConnect = connect(mapStateToProps, {
  ...actionGenerators,
  updateAppColors,
});

const withReducer = injectReducer({
  key: "accountNavbarContainer",
  reducer,
});

export const withSaga = injectSaga({ key: "accountNavbarContainer", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
    };
  }),
)(MainNavigation) as (p: Props) => JSX.Element;
