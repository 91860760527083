import { textUsApiV3 } from "services";

function fetchCampaignDeliveryStats(
  accountSlug: string,
  options?: RequestInit,
) {
  return textUsApiV3.get(
    `/${accountSlug}/deliveries/stats`,
    undefined,
    options,
  );
}

export { fetchCampaignDeliveryStats };
