import { useState } from "react";
import PropTypes from "prop-types";
import withSizes from "react-sizes";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";

import Typography from "@mui/material/Typography";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";
import breakpoints from "utils/styles/breakpoints";

function SubdomainModal({
  fullScreen,
  openModal,
  registeredLink,
  submitSubdomainModal,
  toggleModal,
  updatedAccountSettings,
}) {
  const closeModal = () => {
    toggleModal();
    updatedAccountSettings.actions.setSubmitting(false);
  };

  const [customUrlLink, setCustomUrlLink] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    if (customUrlLink === registeredLink) {
      submitSubdomainModal(customUrlLink);
    } else {
      setError(true);
      updatedAccountSettings.actions.setSubmitting(false);
    }
  };

  const handleChange = ({ target }) => {
    if (error) setError(false);
    setCustomUrlLink(target?.value);
  };

  return (
    <Modal
      ariaLabel="Shortened Branded Link Modal"
      closeModal={closeModal}
      fullScreen={fullScreen}
      maxHeight="500px"
      transitionIn={openModal}
    >
      <ModalHeader closeModal={closeModal}>Confirm Subdomain</ModalHeader>
      <ModalContent maxWidth={400}>
        <Box display="flex" flexDirection="column" px={4}>
          <Typography
            align="center"
            fontSize=".8rem"
            fontWeight={400}
            color="textSecondary"
          >
            You will not be able to change your subdomain once saved. Please
            enter your selection again.
          </Typography>
          <Box my={3}>
            <FormGroup>
              <TextField
                error={error}
                helperText={error ? "Subdomains don’t match" : ""}
                label="Custom Branded Base URL"
                onChange={handleChange}
                variant="outlined"
              />
            </FormGroup>
          </Box>
        </Box>
        <Box display="flex" justifyContent="center">
          <Button color="primary" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={!customUrlLink}
            onClick={handleSubmit}
            variant="contained"
          >
            Save
          </Button>
        </Box>
      </ModalContent>
    </Modal>
  );
}

SubdomainModal.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  registeredLink: PropTypes.string,
  submitSubdomainModal: PropTypes.func.isRequired,
  toggleModal: PropTypes.func.isRequired,
  updatedAccountSettings: PropTypes.object.isRequired,
};

export default withSizes(({ width }) => {
  return {
    fullScreen: !!(width < breakpoints.medium),
  };
})(SubdomainModal);
