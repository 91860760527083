import { AccountOption, SelectValue } from "../models/AnalyticsModels";

export const getFields = ({
  accountList,
  dayToSend,
  sendFrequency,
  timePeriods,
}: {
  accountList: AccountOption[];
  dayToSend: SelectValue[];
  sendFrequency: SelectValue[];
  timePeriods: SelectValue[];
}) => {
  return [
    {
      dependentFieldOptions: [],
      items: accountList,
      label: "Account",
      name: "account",
      required: true,
    },
    {
      dependentFieldOptions: ["sendFrequency", "dayToSend"],
      items: timePeriods,
      label: "Time Period",
      name: "timePeriod",
      parentField: true,
      required: true,
    },
    {
      dependentFieldOptions: ["dayToSend"],
      items: sendFrequency,
      label: "Send Frequency",
      name: "sendFrequency",
      parentField: true,
      required: true,
    },
    {
      dependentFieldOptions: [],
      items: dayToSend,
      label: "Day to Send",
      name: "dayToSend",
      required: true,
    },
  ];
};
