import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import Root from "./components/Root";
import { actionGenerators, saga, reducer, selectors } from "./state";
import {
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import injectReducer from "utils/injectReducer";
import injectSaga from "utils/injectSaga";
import withRecord from "higherOrderComponents/withRecord";

function EditCampaign(props) {
  return <Root {...props} />;
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
    deliveryStats: selectors.selectDeliveryStats(state, props),
    editCampaignContainer: selectors.selectEditCampaign(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "editCampaignContainer",
  reducer,
});
const withSaga = injectSaga({ key: "editCampaignContainer", saga });

export default compose(
  withRecord({
    actions: ["fetch", "update"],
    container: "Campaigns/containers/EditCampaign",
    shape: {
      user: {},
      totalRecipients: { members: [{ contactPhone: { contact: {} } }] },
      skippedRecipients: { members: [{ contactPhone: { contact: {} } }] },
      sentRecipients: { members: [{ contactPhone: { contact: {} } }] },
      deliveredRecipients: { members: [{ contactPhone: { contact: {} } }] },
      failedRecipients: { members: [{ contactPhone: { contact: {} } }] },
      repliedRecipients: { members: [{ contactPhone: { contact: {} } }] },
    },
    showLoader: (props) => {
      return !get(props, ["campaign", "totalRecipients", "members"]);
    },
    type: "campaign",
  }),
  withReducer,
  withSaga,
  withConnect,
)(EditCampaign);
