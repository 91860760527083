import type { ConnectEvent } from "./types";

/**
 * Popup operator
 *
 * @param connect — callback function to be used as a connection between windows
 * @returns Connection event
 */
export const operator = <T>(
  channel: string,
  connect: ConnectEvent<T>["detail"]["connect"],
): ConnectEvent<T> => {
  const detail: ConnectEvent<T>["detail"] = {
    connect,
  };

  return new CustomEvent(channel, {
    detail,
  });
};
