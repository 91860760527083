import { Component } from "react";
import styled from "styled-components";

import ImportSection from "features/ContactImports/containers/NewContactImport/components/ImportSection";
import Logo from "components/Logo";

const Loader = styled(ImportSection)`
  justify-content: center;
`;

class ImportLoader extends Component {
  render() {
    return (
      <Loader>
        <Logo animate width={50} dotColor="primary" color="transparent" />
      </Loader>
    );
  }
}

export default ImportLoader;
