import { Box, Typography } from "@mui/material";
import { Action, Location } from "history";
import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SimpleDialog } from "components/SimpleDialog";
import { DialogFooter } from "components/DialogFooter";

interface Props {
  showWarning: boolean;
}

function LeavePageWarning({ showWarning }: Props) {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [nextLocation, setNextLocation] = useState<Location | undefined>();
  const [action, setAction] = useState<Action | undefined>();

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open && showWarning) {
      return history.block((next: Location, newAction: Action) => {
        setOpen(true);
        setNextLocation(next);
        setAction(newAction);
        return false;
      });
    }
    return undefined;
  }, [history, open, showWarning]);

  const confirmLeavePage = useCallback(() => {
    closeModal();
    if (nextLocation && action) {
      history.push(nextLocation.pathname);
    }
  }, [action, history, nextLocation]);

  return (
    <SimpleDialog title="Leave page?" open={open} handleClose={closeModal}>
      <Box>
        <Box margin="1rem" maxWidth="20rem">
          <Typography variant="body1">
            Changes you have made to these settings have not been saved.
          </Typography>
        </Box>
        <DialogFooter
          cancelText="Leave"
          confirmText="Stay"
          onCancel={confirmLeavePage}
          onConfirm={closeModal}
        />
      </Box>
    </SimpleDialog>
  );
}

export { LeavePageWarning };
