import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import capitalize from "lodash/capitalize";
import get from "lodash/get";

import Grow from "@mui/material/Grow";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import DiscardConfirmation from "./DiscardConfirmation";
import H4 from "components/H4";
import P from "components/P";
import Table from "components/Table";
import Avatar from "components/Avatar";

import Modal from "components/Modal";

const AccountCellContent = styled.div`
  padding: 10px 0;
  cursor: pointer;

  &:hover {
    background: ${(props) => {
      return props.theme.colors.background.default;
    }};
  }
`;

const UserAvatar = styled(Avatar)`
  margin: 3px auto;
`;

const AccountInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 20px 10px 0px;
  font-size: ${(props) => {
    return props.theme.fonts.primaryListItemFontSize;
  }};

  ${H4} {
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

const AccountList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px 0;

  li {
    padding: 0 32px 0 16px;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    ${AccountInfo} {
      justify-content: flex-start;
    }
  }
`;

const UserButton = styled(Button)``;

class UserManagementTable extends Component {
  static propTypes = {
    deleteUserRequest: PropTypes.func.isRequired,
    fetchUserCollectionRequest: PropTypes.func.isRequired,
    setActiveUser: PropTypes.func.isRequired,
    userCollection: PropTypes.object.isRequired,
    userCollectionId: PropTypes.string.isRequired,
  };

  constructor() {
    super();

    this.state = {
      accountsPopperAnchorEl: null,
      accountsPopperMemberships: null,
      userToDelete: null,
    };
  }

  renderAccountNames = (memberships) => {
    return (
      <AccountList>
        {memberships.map((m) => {
          return (
            <li key={m.id}>
              <AccountInfo>
                <Box>
                  <H4>{m.account.name}</H4>
                  <P>{capitalize(m.role)}</P>
                </Box>
              </AccountInfo>
            </li>
          );
        })}
      </AccountList>
    );
  };

  showAccountsPopper = (e, memberships) => {
    this.setState({
      accountsPopperAnchorEl: e.target,
      accountsPopperMemberships: memberships,
    });
  };

  hideAccountsPopper = () => {
    return this.setState({
      accountsPopperAnchorEl: null,
      accountsPopperMemberships: null,
    });
  };

  getAccountCellContent = (user) => {
    const memberships = get(user, ["memberships", "members"], []);

    if (memberships.length === 0) {
      return <span>No Active Memberships</span>;
    }

    if (memberships.length > 1) {
      return (
        <AccountCellContent
          key={get(user, ["memberships", "id"])}
          onMouseOver={(e) => {
            return this.showAccountsPopper(e, memberships);
          }}
          onFocus={(e) => {
            return this.showAccountsPopper(e, memberships);
          }}
          onMouseOut={this.hideAccountsPopper}
          onBlur={this.hideAccountsPopper}
        >
          {memberships.length} Accounts
        </AccountCellContent>
      );
    }

    const { account, role } = memberships[0];
    return (
      <AccountInfo>
        <Box>
          <H4>{account.name}</H4>
          <P>{capitalize(role)}</P>
        </Box>
      </AccountInfo>
    );
  };

  getColumns = () => {
    return [
      {
        title: "Name",
        titleContent: <Box style={{ marginLeft: 10 }}>Name</Box>,
        align: "left",
        getTableCellContent: (user) => {
          return (
            <UserButton
              style={{
                textTransform: "none",
                width: "100%",
              }}
              onClick={() => {
                return this.props.setActiveUser(user);
              }}
            >
              <Box style={{ marginRight: 16 }}>
                <UserAvatar subject={user} size={33} />
              </Box>
              <Box style={{ textAlign: "left", width: "100%" }}>
                <H4>{user.name}</H4>
              </Box>
            </UserButton>
          );
        },
      },
      {
        title: "Email",
        align: "left",
        getTableCellContent: (user) => {
          return <div>{user.email}</div>;
        },
      },
      {
        title: "Memberships",
        align: "left",
        getTableCellContent: this.getAccountCellContent,
      },
      {
        title: "Manage",
        align: "center",
        getTableCellContent: (user) => {
          return (
            <div style={{ width: "85px" }}>
              <Tooltip title="Edit User">
                <IconButton
                  aria-label="Edit User"
                  onClick={() => {
                    return this.props.setActiveUser(user);
                  }}
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete User">
                <IconButton
                  aria-label="Delete User"
                  onClick={() => {
                    this.setState({
                      userToDelete: user,
                    });
                  }}
                  size="large"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    ];
  };

  getCollapsedColumns = () => {
    return [
      {
        title: "Name",
        titleContent: <Box style={{ marginLeft: 10 }}>Name</Box>,
        align: "left",
        getTableCellContent: (user) => {
          return (
            <UserButton
              style={{
                textTransform: "none",
                width: "100%",
                height: 66,
              }}
              onClick={() => {
                return this.props.setActiveUser(user);
              }}
            >
              <Box style={{ marginRight: 16 }}>
                <UserAvatar subject={user} size={33} />
              </Box>
              <Box
                style={{
                  textAlign: "left",
                  width: "100%",
                  display: "flex",
                  flexFlow: "column",
                }}
              >
                <H4>{user.name}</H4>
                <P>{user.email}</P>
              </Box>
            </UserButton>
          );
        },
      },
    ];
  };

  handleClose = () => {
    this.setState({
      userToDelete: null,
    });
  };

  render() {
    const { accountsPopperAnchorEl, accountsPopperMemberships, userToDelete } =
      this.state;
    const { deleteUserRequest, fetchUserCollectionRequest, userCollectionId } =
      this.props;
    return (
      <>
        {accountsPopperAnchorEl && (
          <Popper
            open
            anchorEl={accountsPopperAnchorEl}
            placement="left"
            transition
          >
            {({ TransitionProps }) => {
              return (
                <Grow {...TransitionProps} timeout={400}>
                  <Paper style={{ marginRight: -10 }}>
                    {this.renderAccountNames(accountsPopperMemberships)}
                  </Paper>
                </Grow>
              );
            }}
          </Popper>
        )}
        <Modal
          transitionIn={Boolean(userToDelete)}
          closeModal={this.handleClose}
        >
          <DiscardConfirmation
            activeUser={userToDelete}
            deleteUserRequest={deleteUserRequest}
            fetchUserCollectionRequest={fetchUserCollectionRequest}
            handleClose={this.handleClose}
            userCollectionId={userCollectionId}
          />
        </Modal>
        <style
          // eslint-disable: There's no security risk to this css hack
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `div[] { height: 460px !important; }`,
          }}
        />
        <Table
          dataTag="User-Management-Table"
          collection={this.props.userCollection}
          columns={this.getColumns()}
          collapsedColumns={this.getCollapsedColumns()}
          notExpandable
          paginationStyles={{
            paddingRight: 60,
          }}
        />
      </>
    );
  }
}

export default UserManagementTable;
