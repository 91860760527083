import { MenuItem, TextField } from "@mui/material";
import { camelCase } from "lodash";
import { DropdownDayPickerProps } from "./types";

function DropdownDayPicker({
  handleChange,
  label,
  value,
}: DropdownDayPickerProps) {
  const DAYS_OF_WEEK = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ] as const;

  const options = DAYS_OF_WEEK.map((day, index) => {
    return {
      label: day,
      value: index,
    };
  });

  const renderDayOptions = () => {
    return options.map((day) => {
      return (
        <MenuItem key={day.value} value={day.value}>
          {day.label}
        </MenuItem>
      );
    });
  };

  return (
    <TextField
      select
      fullWidth
      onChange={handleChange}
      value={value}
      label={label}
      name={camelCase(label)}
    >
      {renderDayOptions()}
    </TextField>
  );
}

export { DropdownDayPicker };
