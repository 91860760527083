import { Box, Button } from "@mui/material";
import { useState } from "react";
import { ClickToUploadSelectGroupResponsiveProps } from "../types";
import NewContactImport from "features/ContactImports/containers/NewContactImport";
import SelectGroupIcon from "icons/SelectGroupIcon";

export function ClickToUploadSelectGroupResponsive({
  contactImport,
  oneTimeCampaign,
  mode,
  setSelectGroup,
}: ClickToUploadSelectGroupResponsiveProps) {
  // ==== HOOKS ==== //
  const [fileUploaded, setFileUploaded] = useState(false);

  // ==== RENDER ==== //
  return (
    <Box
      alignItems="center"
      className="click-to-upload-select-group"
      display="flex"
      justifyContent="center"
      width="100%"
      sx={{
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      {oneTimeCampaign && (
        <Box
          flexGrow="1"
          maxWidth="100%"
          sx={{ marginLeft: { xs: 0, sm: "1.875rem" } }}
        >
          <NewContactImport
            contactImport={contactImport}
            fromCampaign
            setFileUploaded={setFileUploaded}
          />
        </Box>
      )}

      {!contactImport && !fileUploaded && (
        <Box
          flexGrow="1"
          display="flex"
          sx={{
            justifyContent: { xs: "center", sm: "flex-start" },
            marginRight: { xs: 0, sm: "1.25rem" },
            paddingBottom: { xs: 0, sm: ".75rem" },
          }}
          width="12.8rem"
        >
          <Box justifyContent="center" alignSelf="center">
            <Box textAlign="center" sx={{ p: "0.375rem" }}>
              <SelectGroupIcon mode={mode} />
            </Box>
            <Button
              color="primary"
              data-testid="select-group-button"
              onClick={() => {
                return setSelectGroup(true);
              }}
              variant="contained"
            >
              Select Group
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
