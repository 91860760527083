export const supportedIntegrations = [
  {
    provider: "7shifts",
    providerName: "7shifts",
  },
  {
    provider: "accelo",
    providerName: "Accelo",
  },
  {
    provider: "activecampaign",
    providerName: "Activecampaign",
  },
  {
    provider: "adp_workforce_now",
    providerName: "Adp Workforce Now",
  },
  {
    provider: "affinity",
    providerName: "Affinity",
  },
  {
    provider: "alexishr",
    providerName: "Alexishr",
  },
  {
    provider: "alliancehcm",
    providerName: "Alliancehcm",
  },
  {
    provider: "altera_payroll",
    providerName: "Altera Payroll",
  },
  {
    provider: "applicantstack",
    providerName: "Applicantstack",
  },
  {
    provider: "ashby",
    providerName: "Ashby",
  },
  {
    provider: "avature",
    providerName: "Avature",
  },
  {
    provider: "bamboohr",
    providerName: "Bamboohr",
  },
  {
    provider: "blue_sky",
    providerName: "Blue Sky",
  },
  {
    provider: "breathe",
    providerName: "Breathe",
  },
  {
    provider: "breezy",
    providerName: "Breezy",
  },
  {
    provider: "bullhorn",
    providerName: "Bullhorn",
  },
  {
    provider: "capsule",
    providerName: "Capsule",
  },
  {
    provider: "cats",
    providerName: "Cats",
  },
  {
    provider: "cezanne_hr",
    providerName: "Cezanne Hr",
  },
  {
    provider: "charlie",
    providerName: "Charlie",
  },
  {
    provider: "charthop",
    providerName: "Charthop",
  },
  {
    provider: "clayhr",
    providerName: "Clayhr",
  },
  {
    provider: "clockwork",
    providerName: "Clockwork",
  },
  {
    provider: "close",
    providerName: "Close",
  },
  {
    provider: "comeet",
    providerName: "Comeet",
  },
  {
    provider: "copper",
    providerName: "Copper",
  },
  {
    provider: "cornerstone_talentlink",
    providerName: "Cornerstone Talentlink",
  },
  {
    provider: "cyberark",
    providerName: "Cyberark",
  },
  {
    provider: "darwinbox",
    providerName: "Darwinbox",
  },
  {
    provider: "dayforce",
    providerName: "Dayforce",
  },
  {
    provider: "deel",
    providerName: "Deel",
  },
  {
    provider: "dynamics",
    providerName: "Dynamics",
  },
  {
    provider: "employment_hero",
    providerName: "Employment Hero",
  },
  {
    provider: "engageats",
    providerName: "Engageats",
  },
  {
    provider: "eploy",
    providerName: "Eploy",
  },
  {
    provider: "erecruit",
    providerName: "Erecruit",
  },
  {
    provider: "factorial",
    providerName: "Factorial",
  },
  {
    provider: "fountain",
    providerName: "Fountain",
  },
  {
    provider: "freshteam",
    providerName: "Freshteam",
  },
  {
    provider: "google_workspace",
    providerName: "Google Workspace",
  },
  {
    provider: "greenhouse",
    providerName: "Greenhouse",
  },
  {
    provider: "greenhouse_job_boards_api",
    providerName: "Greenhouse Job Boards Api",
  },
  {
    provider: "greenhouse_v2",
    providerName: "Greenhouse V2",
  },
  {
    provider: "gusto",
    providerName: "Gusto",
  },
  {
    provider: "hailey_hr",
    providerName: "Hailey Hr",
  },
  {
    provider: "harbour_ats",
    providerName: "Harbour ATS",
  },
  {
    provider: "healthcare_source",
    providerName: "Healthcare Source",
  },
  {
    provider: "herefish",
    providerName: "Herefish",
  },
  {
    provider: "hibob",
    providerName: "Hibob",
  },
  {
    provider: "homerun",
    providerName: "Homerun",
  },
  {
    provider: "hr_cloud",
    providerName: "Hr Cloud",
  },
  {
    provider: "hr_partner",
    providerName: "Hr Partner",
  },
  {
    provider: "hubspot",
    providerName: "Hubspot",
  },
  {
    provider: "humaans",
    providerName: "Humaans",
  },
  {
    provider: "humi",
    providerName: "Humi",
  },
  {
    provider: "icims",
    providerName: "Icims",
  },
  {
    provider: "infinite_brassring",
    providerName: "Infinite Brassring",
  },
  {
    provider: "insightly",
    providerName: "Insightly",
  },
  {
    provider: "insperity_premier",
    providerName: "Insperity Premier",
  },
  {
    provider: "intellihr",
    providerName: "Intellihr",
  },
  {
    provider: "iris_cascade",
    providerName: "Iris Cascade",
  },
  {
    provider: "jazzhr",
    providerName: "Jazzhr",
  },
  {
    provider: "job_adder",
    providerName: "Job Adder",
  },
  {
    provider: "job_diva",
    providerName: "Job Diva",
  },
  {
    provider: "jobadder",
    providerName: "Jobadder",
  },
  {
    provider: "jobdiva",
    providerName: "Jobdiva",
  },
  {
    provider: "jobscore",
    providerName: "Jobscore",
  },
  {
    provider: "jobsoid",
    providerName: "Jobsoid",
  },
  {
    provider: "jobvite",
    providerName: "Jobvite",
  },
  {
    provider: "jumpcloud",
    providerName: "Jumpcloud",
  },
  {
    provider: "justworks",
    providerName: "Justworks",
  },
  {
    provider: "kallidus",
    providerName: "Kallidus",
  },
  {
    provider: "keap",
    providerName: "Keap",
  },
  {
    provider: "keka",
    providerName: "Keka",
  },
  {
    provider: "kenjo",
    providerName: "Kenjo",
  },
  {
    provider: "labor_edge",
    providerName: "Labor Edge",
  },
  {
    provider: "lano",
    providerName: "Lano",
  },
  {
    provider: "lever",
    providerName: "Lever",
  },
  {
    provider: "lucca",
    providerName: "Lucca",
  },
  {
    provider: "microsoft_dynamics_365_sales",
    providerName: "Microsoft Dynamics 365 Sales",
  },
  {
    provider: "microsoft_entra_id",
    providerName: "Microsoft Entra",
  },
  {
    provider: "namely",
    providerName: "Namely",
  },
  {
    provider: "nmbrs",
    providerName: "Nmbrs",
  },
  {
    provider: "nutshell",
    providerName: "Nutshell",
  },
  {
    provider: "occupop",
    providerName: "Occupop",
  },
  {
    provider: "officient",
    providerName: "Officient",
  },
  {
    provider: "okta",
    providerName: "Okta",
  },
  {
    provider: "onelogin",
    providerName: "Onelogin",
  },
  {
    provider: "oracle_cloud_human_capital_management_hcm",
    providerName: "Oracle Cloud Human Capital Management Hcm",
  },
  {
    provider: "oracle_fusion_recruiting_cloud",
    providerName: "Oracle Fusion Recruiting Cloud",
  },
  {
    provider: "oracle_taleo",
    providerName: "Oracle Taleo",
  },
  {
    provider: "oysterhr",
    providerName: "Oysterhr",
  },
  {
    provider: "paycaptain",
    providerName: "Paycaptain",
  },
  {
    provider: "paychex",
    providerName: "Paychex",
  },
  {
    provider: "paycom",
    providerName: "Paycom",
  },
  {
    provider: "paycor",
    providerName: "Paycor",
  },
  {
    provider: "payfit",
    providerName: "Payfit",
  },
  {
    provider: "paylocity",
    providerName: "Paylocity",
  },
  {
    provider: "peoplehr",
    providerName: "Peoplehr",
  },
  {
    provider: "personio",
    providerName: "Personio",
  },
  {
    provider: "personio_recruiting",
    providerName: "Personio Recruiting",
  },
  {
    provider: "pingone",
    providerName: "Pingone",
  },
  {
    provider: "pinpoint",
    providerName: "Pinpoint",
  },
  {
    provider: "pipedrive",
    providerName: "Pipedrive",
  },
  {
    provider: "pipeliner",
    providerName: "Pipeliner",
  },
  {
    provider: "planday",
    providerName: "Planday",
  },
  {
    provider: "polymer",
    providerName: "Polymer",
  },
  {
    provider: "proliant",
    providerName: "Proliant",
  },
  {
    provider: "recruiterflow",
    providerName: "Recruiterflow",
  },
  {
    provider: "recruitive",
    providerName: "Recruitive",
  },
  {
    provider: "remote",
    providerName: "Remote",
  },
  {
    provider: "rippling",
    providerName: "Rippling",
  },
  {
    provider: "sage_hr",
    providerName: "Sage Hr",
  },
  {
    provider: "sage_people",
    providerName: "Sage People",
  },
  {
    provider: "salesflare",
    providerName: "Salesflare",
  },
  {
    provider: "salesforce",
    providerName: "Salesforce",
  },
  {
    provider: "sap_successfactors",
    providerName: "Sap Successfactors",
  },
  {
    provider: "sesame",
    providerName: "Sesame",
  },
  {
    provider: "smartrecruiters",
    providerName: "Smartrecruiters",
  },
  {
    provider: "square_payroll",
    providerName: "Square Payroll",
  },
  {
    provider: "sugarcrm",
    providerName: "Sugarcrm",
  },
  {
    provider: "talentlyft",
    providerName: "Talentlyft",
  },
  {
    provider: "talentreef",
    providerName: "Talentreef",
  },
  {
    provider: "teamleader",
    providerName: "Teamleader",
  },
  {
    provider: "teamtailor",
    providerName: "Teamtailor",
  },
  {
    provider: "teamwork_crm",
    providerName: "Teamwork CRM",
  },
  {
    provider: "tellent",
    providerName: "Tellent",
  },
  {
    provider: "traffit",
    providerName: "Traffit",
  },
  {
    provider: "tribepad",
    providerName: "Tribepad",
  },
  {
    provider: "trinet",
    providerName: "Trinet",
  },
  {
    provider: "trinet_hr_platform",
    providerName: "Trinet Hr Platform",
  },
  {
    provider: "ukg_pro",
    providerName: "Ukg Pro",
  },
  {
    provider: "ukg_pro_recruiting",
    providerName: "Ukg Pro Recruiting",
  },
  {
    provider: "ukg_pro_workforce_management",
    providerName: "Ukg Pro Workforce Management",
  },
  {
    provider: "ukg_ready",
    providerName: "Ukg Ready",
  },
  {
    provider: "v2_webhook",
    providerName: "V2 Webhook",
  },
  {
    provider: "vtiger",
    providerName: "Vtiger",
  },
  {
    provider: "webhook",
    providerName: "Webhook",
  },
  {
    provider: "workable",
    providerName: "Workable",
  },
  {
    provider: "workday",
    providerName: "Workday",
  },
  {
    provider: "zelt",
    providerName: "Zelt",
  },
  {
    provider: "zendesk_sell",
    providerName: "Zendesk Sell",
  },
  {
    provider: "zoho_crm",
    providerName: "Zoho CRM",
  },
  {
    provider: "zoho_people",
    providerName: "Zoho People",
  },
  {
    provider: "zoho_recruit",
    providerName: "Zoho Recruit",
  },
];
