import { Component } from "react";
import PropTypes from "prop-types";
import ConversationAssignmentsCollection from "features/ActiveConversation/containers/ConversationAssignmentsCollection";
import getNestedId from "utils/getNestedId";

export default class Root extends Component {
  static propTypes = {
    conversation: PropTypes.object.isRequired,
  };

  render() {
    const { conversation } = this.props;
    return (
      <ConversationAssignmentsCollection
        {...this.props}
        conversationAssignmentsCollectionId={getNestedId(
          conversation,
          "assignments",
        )}
      />
    );
  }
}
