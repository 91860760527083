const bounceIn = `
@keyframes bounceIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  40% {
    transform: scale(1.1);
    opacity: 1;
  }
  60% { transform: scale(0.7); }
  100% { transform: scale(1); }
}
`;

const slideUpIn = `
@keyframes slideUpIn {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
`;

const slideDownIn = `
@keyframes slideDownIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
`;

const shake = `
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}
`;

const pulse = `
@keyframes pulse {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
  }

  35% {
    opacity: 0.7;
    transform: scale(1.15);
  }

  50% {
    opacity: 1;
    transform: scale (1.2);
  }

  70% {
    opacity: 1;
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}
`;

export default {
  bounceIn,
  slideUpIn,
  slideDownIn,
  pulse,
  shake,
};
