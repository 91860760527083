import { Skeleton, Alert } from "@mui/material";
import { Formik, useField } from "formik";
import { useEffect } from "react";
import { Account, identifyUser } from "@tesseract/core";
import type { FormikComponentProps, FormValues, Props } from "./types";
import { UsersAutocomplete, useUser } from "features/Users";

/**
 * Renders formik wrapper
 */
export function Form(props: FormikComponentProps) {
  return <Formik<FormValues> {...props} />;
}

/**
 * Renders a component for managing fields in the AutomatedSender feature of the Settings page.
 */
export function Fields({ usersAutocompleteProps }: Props) {
  const [{ value: automatedSenderId }, , { setValue: setAutomatedSender }] =
    useField<Account.Settings["automatedSenderId"]>("automatedSenderId");

  /**
   * Represents the user retrieved from the API based on the automated sender ID.
   * If the automated sender ID is not set, the system user ID is used instead.
   */
  const { user, load, state: userState, reset } = useUser();

  /**
   * Load user
   */
  useEffect(() => {
    const id =
      typeof automatedSenderId?.value === "string" ||
      typeof automatedSenderId?.value === "number"
        ? `${automatedSenderId.value}`
        : undefined;

    if (id) {
      load(id);
    } else {
      reset();
    }
  }, [automatedSenderId, load, reset]);

  return (
    <UsersAutocomplete
      label="Log as"
      required
      value={user ?? ""}
      loading={userState === "loading"}
      onChange={(_, newValue) => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        setAutomatedSender({
          ...automatedSenderId,
          value:
            !newValue || typeof newValue === "string"
              ? null
              : identifyUser(newValue.id),
        });
      }}
      {...usersAutocompleteProps}
    />
  );
}

export function Loading() {
  return <Skeleton variant="rectangular" height={56} />;
}

export function Error() {
  return <Alert severity="error">Failed to load automated sender</Alert>;
}
