import { Component } from "react";
import hoistNonReactStatics from "hoist-non-react-statics";
import { ReactReduxContext } from "react-redux";

import getInjectors from "./sagaInjectors";

/**
 * Dynamically injects a saga, passes component's props as saga arguments
 * @deprecated
 *
 * @param {object} param
 * @param {string} param.key A key of the saga
 * @param {function} param.saga A root saga that will be injected
 */
export default ({ key, saga }) => {
  return (WrappedComponent) => {
    class InjectSaga extends Component {
      static displayName = `withSaga(${
        WrappedComponent.displayName || WrappedComponent.name || "Component"
      })`;

      static WrappedComponent = WrappedComponent;

      static contextType = ReactReduxContext;

      constructor(props, context) {
        super(props, context);

        this.injectors = getInjectors(context.store);

        this.injectors.injectSaga(key, { saga }, this.props);
      }

      componentWillUnmount() {
        this.injectors.ejectSaga(key);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return hoistNonReactStatics(InjectSaga, WrappedComponent);
  };
};
