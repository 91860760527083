import { Link } from "react-router-dom";
import styled from "styled-components";

export const SnackbarLinkText = styled(Link)(({ theme }) => {
  return {
    color: `${theme.colors.secondary.contrastText}`,
    fontSize: "14px",
    textDecoration: "none",
  };
});
