import { Form, useFormikContext } from "formik";
import { Alert, Box, Button, IconButton, Typography } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { MODAL_SELECTIONS } from "../../constants";
import { NewSequenceFormProps } from "./types";
import { SequenceValues } from "features/Sequences/hooks/useSequences/types";
import { SequenceFormInputs } from "features/Sequences/components/SequenceFormInputs";

function NewSequenceForm({
  close,
  handleBack,
  selection,
}: NewSequenceFormProps) {
  const { isValid, isSubmitting, values } = useFormikContext<SequenceValues>();

  const isDuplicatingBlueprint = Boolean(values.useCase);

  const alertText = (
    <div>
      TextUs recommends{" "}
      <Box component="span" sx={{ fontWeight: 700 }}>
        {values.finishedOnReply ? "mark finished" : "continues to next step"}
      </Box>{" "}
      for this blueprint.
    </div>
  );

  const { create, duplicate } = MODAL_SELECTIONS;

  let copy = {
    header: "New sequence details",
    subhead:
      "Choose the sequence name and decide what happens when a contact replies",
    cancel: "Back",
    create: "Create",
  };

  // Update copy for duplicate flow
  if (selection === duplicate) {
    copy = {
      ...copy,
      header: "Update sequence details",
      subhead: "Change settings to personalize your sequence",
    };
  }

  return (
    <Form aria-label="New Sequence Form">
      <Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" flexDirection="column">
            <Typography variant="h5" margin={0}>
              {copy.header}
            </Typography>
            <Typography
              variant="body2"
              sx={(theme) => {
                return {
                  color: theme.palette.text.secondary,
                };
              }}
            >
              {copy.subhead}
            </Typography>
          </Box>
          <IconButton
            onClick={close(false)}
            size="medium"
            data-testid="close-new-sequence-modal"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <SequenceFormInputs
          userFlow={selection === duplicate ? duplicate : create}
        />
        <Box>
          {isDuplicatingBlueprint ? (
            <Alert severity="info" sx={{ mb: "1rem" }}>
              {alertText}
            </Alert>
          ) : null}
          <Box display="flex" justifyContent="end">
            <Box mr={2}>
              <Button
                aria-label="Cancel and Close Modal"
                onClick={() => {
                  if (handleBack) {
                    handleBack();
                  }
                  close(false)();
                }}
              >
                {copy.cancel}
              </Button>
            </Box>
            <Button
              aria-label="Create New Sequence"
              color="primary"
              data-testid="create-new-sequence"
              disabled={!isValid || isSubmitting}
              type="submit"
              variant="contained"
            >
              {copy.create}
            </Button>
          </Box>
        </Box>
      </Box>
    </Form>
  );
}

export { NewSequenceForm };
