import PropTypes from "prop-types";
import get from "lodash/get";
import { Field } from "formik";
import Box from "@mui/material/Box";
import {
  DesktopDatePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { format } from "date-fns";
import renderOptionalTooltip from "../utils/renderOptionalTooltip";

function PersonalizationDatePicker({
  personalization,
  errors,
  setFieldTouched,
  setFormIsDirty,
  touched,
  values,
}) {
  const { description, title } = personalization;
  const handleChange = (date, form, field) => {
    if (date) {
      form.setFieldValue(field.name, date);
    } else {
      form.setFieldValue(field.name, "");
    }
  };
  return (
    <Box my={4}>
      <Field name={`${title}.value`}>
        {({ field, form }) => {
          return (
            <Box
              display="flex"
              flex="0 0 auto"
              style={{ height: "4em", alignItems: "center" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  autoOk
                  clearable
                  error={
                    touched[title] &&
                    Boolean(get(errors, [title, "value"], undefined))
                  }
                  format="MM/dd/yyyy"
                  helperText={
                    touched[title] && get(errors, [title, "value"], undefined)
                  }
                  inputVariant="outlined"
                  label={title}
                  margin="normal"
                  onBlur={() => {
                    setFieldTouched(title);
                    setFormIsDirty(form.dirty);
                  }}
                  onChange={(date) => {
                    setFieldTouched(title);
                    handleChange(date, form, field);
                  }}
                  placeholder="Select a date"
                  value={values.title?.value || null}
                  sx={{ width: "80%" }}
                />
              </LocalizationProvider>
              <Box ml={2} style={{ alignItems: "center" }}>
                {description && renderOptionalTooltip(description, title)}
              </Box>
            </Box>
          );
        }}
      </Field>
    </Box>
  );
}

PersonalizationDatePicker.propTypes = {
  personalization: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PersonalizationDatePicker;
