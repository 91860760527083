import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import AccountSetting from "./AccountSetting";
import { MmsSettingsValidationSchema } from "formHelpers/validationSchemas";

function MmsSettings(props) {
  const getDescription = () => {
    return (
      <>
        <p>
          MMS (or picture messaging) is included as part of all TextUs plans. If
          for compliance or legal reasons you need to disable this feature you
          can do so in either or both directions.
        </p>
        <p>
          Disabling inbound MMS is useful to prevent your contacts from sending
          in pictures of sensitive material like credit cards or other
          personally identifiable information. Disabling outbound MMS is helpful
          if you need to prevent your employees from sending inappropriate
          photos or sensitive material to your contacts.
        </p>
      </>
    );
  };

  const getRender = (label) => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={!field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, !checked);
                }}
                color="secondary"
              />
            }
            label={label}
          />
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      settings={[
        {
          default: false,
          name: "inboundMmsDisabled",
          render: getRender("Inbound MMS"),
        },
        {
          default: false,
          name: "outboundMmsDisabled",
          render: getRender("Outbound MMS"),
        },
      ]}
      title="MMS Settings"
      validationSchema={MmsSettingsValidationSchema}
    />
  );
}

MmsSettings.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
};

export default MmsSettings;
