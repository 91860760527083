import { Component } from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { CSSTransition } from "react-transition-group";

import BasicButton from "components/BasicButton";
import Icon from "components/Icon";
import ICONS from "constants/icons";

const Root = styled.div`
  background: ${(props) => {
    return props.backgroundColor;
  }};
  border-radius: calc(
    2 *
      ${(props) => {
        return props.theme.defaults.borderRadius;
      }}
  );
  color: ${(props) => {
    return props.textColor;
  }};
  font-size: ${(props) => {
    return props.theme.fonts.primaryFontSize;
  }};
  padding: 5px 10px;
  position: absolute;
  right: 10px;
  top: 5px;
  width: calc(100% - 20px);
  z-index: 1000;

  &.isError {
    background: ${(props) => {
      return props.theme.colors.error.main;
    }};
  }

  &.notification-enter {
    opacity: 0;
    transform: translateY(-10px);
  }

  &.notification-enter-active {
    opacity: 1;
    transform: translateY(0px);
    transition: ${(props) => {
      return props.theme.mixins.transition();
    }};
  }

  &.notification-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  &.notification-exit-active {
    opacity: 0;
    transform: translateY(-10px);
    transition: ${(props) => {
      return props.theme.mixins.transition();
    }};
  }
`;

const FlexContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const CloseButton = styled(BasicButton)`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  min-width: 25px;
  max-width: 25px;
`;

class Notification extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    children: PropTypes.any,
    closeHandler: PropTypes.func.isRequired,
    closeTimeout: PropTypes.number,
    isActive: PropTypes.bool,
    isError: PropTypes.bool,
    textColor: PropTypes.string,
    theme: PropTypes.object.isRequired,
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isActive && this.props.isActive && this.props.closeTimeout) {
      setTimeout(this.props.closeHandler, this.props.closeTimeout * 1000);
    }
  }

  render() {
    const {
      children,
      closeHandler,
      isActive,
      isError,
      theme,
      backgroundColor = theme.colors.secondary.main,
      textColor = theme.colors.primary.contrastText,
    } = this.props;
    return (
      <CSSTransition
        classNames="notification"
        mountOnEnter
        unmountOnExit
        in={isActive}
        timeout={500}
      >
        <Root
          backgroundColor={backgroundColor}
          textColor={textColor}
          className={isError && "isError"}
        >
          <FlexContainer>
            <Content>{children}</Content>
            <CloseButton onClick={closeHandler}>
              <Icon
                icon={ICONS.CLOSE}
                iconColor={theme.colors.primary.contrastText}
                iconSize="16"
              />
            </CloseButton>
          </FlexContainer>
        </Root>
      </CSSTransition>
    );
  }
}

export default withTheme(Notification);
