import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class LaborEdgeStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /secure\.laboredge/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: ["#cnInfoDetailsFirstNameId", "#cnInfoDetailsLastNameId"],
      namesSelectorType: "value",
      canParse: () => !!$(doc).find("#candidateDetailsDivId")[0],
      searchTextFunction: () =>
        Array.from($(doc).find("#candidateInfoDetailsFormId input"))
          .filter(node => /phone/i.test(node.id))
          .map(node => node.value.trim())
          .join(", "),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "td[aria-describedby='candidateMangementGridId_name']",
      containerSelector: "tr",
      canParse: () => !!$(doc).find("#listCandidatesGridDivId")[0],
    });
    const prospectPageParser = new MultiContactParser({
      doc,
      namesSelector:
        "td[aria-describedby='prospectCandidateMangementGridId_name']",
      containerSelector: "tr",
      canParse: () => !!$(doc).find("#listProspectCandidatesGridDivId")[0],
    });
    return [detailPageParser, listPageParser, prospectPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "LaborEdgeStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "LaborEdgeStrategy");
      return null;
    }
  };
}
