import Papa from "papaparse";

export const parseCsv = ({
  csv,
  successCallback,
  errorCallback,
  skipEmptyLines = true,
}) => {
  Papa.parse(csv, {
    skipEmptyLines,
    complete: successCallback,
    error: errorCallback,
  });
};
