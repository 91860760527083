import { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import DataObjectIcon from "@mui/icons-material/DataObject";
import {
  Box,
  IconButton,
  Popover,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import TemplateVariablePicker from "./TemplateVariablePicker";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

function TemplateVariablePickerOption({
  closeExpandedActionsMenu,
  currentAccount,
  hideUserPersonalization,
  selectionHandler,
  disabled,
}) {
  const mobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const userSchemaId = get(currentAccount, ["userSchema"], "");

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (mobile) {
      closeExpandedActionsMenu();
    }
  };

  const handleSelect = (value) => {
    selectionHandler(value);
    handleClose();
  };

  return (
    <>
      <Tooltip title="Personalize" disableFocusListener>
        <IconButton
          disabled={disabled}
          aria-haspopup="true"
          onClick={handleOpen}
          size="large"
          date-testid="personalize-icon"
        >
          <DataObjectIcon />
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        slotProps={{
          paper: {
            square: mobile,
            sx: (theme) => {
              return {
                [theme.breakpoints.down("sm")]: {
                  height: "100%",
                  marginTop: "3.5rem",
                  maxHeight: "unset",
                  maxWidth: "unset",
                  position: "relative",
                  width: "100%",
                  // I hate using !important but it's the only way I could find
                  // to override MUI's default Popover calculations.
                  top: "unset !important",
                  left: "unset !important",
                },
              };
            },
          },
        }}
      >
        <Box borderRadius="4px" minWidth="20rem">
          <TemplateVariablePicker
            closeHandler={handleClose}
            handleTemplateVariableSelected={handleSelect}
            hideUserPersonalization={hideUserPersonalization}
            userSchemaId={userSchemaId}
          />
        </Box>
      </Popover>
    </>
  );
}

TemplateVariablePickerOption.propTypes = {
  closeExpandedActionsMenu: PropTypes.func,
  currentAccount: PropTypes.object.isRequired,
  hideUserPersonalization: PropTypes.bool,
  selectionHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(TemplateVariablePickerOption);
