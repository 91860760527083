import styled from "styled-components";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { Formik, Field, Form, FieldArray, getIn, FieldProps } from "formik";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Button,
  useMediaQuery,
  useTheme,
  Skeleton,
  Divider,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { FormValues, Props } from "./types";
import ContactFormTags from "features/ContactModal/containers/ContactFormTags";
import { ContactValidationSchema } from "formHelpers/validationSchemas";
import { BullhornCandidate, GreenhouseCandidate } from "features/ContactSync";
import { DialogFooter } from "components/DialogFooter";
import getUserRole from "utils/getUserRole";
import { AddIcon, CloseIcon } from "icons";

// Prevent Chrome from transitioning to a yellow background on autocomplete
const FormWrapper = styled.div<{
  isSmall?: boolean;
}>`
  display: flex;
  flex: 1 1 0;
  flex-flow: column nowrap;
  gap: 16px;
  padding: 24px 16px;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition-delay: 99999s;
  }
`;

const FormRow = styled(Box)`
  align-items: center;
  display: flex;
  gap: 16px;
`;

const PhoneFieldWrapper = styled.div`
  flex: 1 1 auto;
`;

const PhoneNumberWrapper = styled(PhoneFieldWrapper)``;

const StyledPhoneNumberEntry = styled(Box)`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;

const StyledPhoneNumberRow = styled(Box)`
  display: flex;
  flex: 1 1 0;
  flex-flow: column nowrap;
  gap: 16px;
`;

const StyledDeletePhoneNumber = styled(Box)`
  display: flex;
`;

const StyledAddPhoneNumber = styled(Box)`
  display: flex;
  justify-content: flex-end;
`;

export function ContactFormRedesign({
  contact,
  conversationPhoneNumber,
  currentAccount,
  currentUser,
  onCancel,
  onContactUnlink,
  onDelete,
  onSubmit,
  loading,
}: Props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const canDelete = getUserRole(currentUser, currentAccount) === "admin";

  const initialValues: FormValues = {
    firstName: contact?.firstName ?? "",
    lastName: contact?.lastName ?? "",
    data: {
      business: get(contact, ["data", "business"], ""),
      tags: get(contact, ["data", "tags"], []) || [],
    },
    phones: contact?.phones
      ? contact.phones.members.map((phone) => {
          return {
            id: phone.id,
            number: phone.formattedPhoneNumber,
            type: phone.type || "",
          };
        })
      : [{ number: "", type: "Mobile" }],
    ...(isEmpty(contact) ? { method: "ui", source: "manual" } : {}),
  };

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={ContactValidationSchema}
      validateOnMount
      enableReinitialize
      onSubmit={(values, actions) => {
        return onSubmit(values, actions);
      }}
    >
      {({ errors, touched, isSubmitting, isValid, values, setFieldValue }) => {
        return (
          <Grid
            sx={{
              overflowY: "auto",
              display: "grid",
            }}
            component={Form}
          >
            <Box
              sx={{
                overflow: "auto",
                flex: "1 1 0",
              }}
            >
              <FormWrapper isSmall={isSmall}>
                {/* First and Last name */}
                <FormRow
                  sx={{
                    [theme.breakpoints.down("sm")]: {
                      flexDirection: "column",
                    },
                  }}
                >
                  <Field type="text" name="firstName">
                    {({ field }: FieldProps) => {
                      if (loading) {
                        return (
                          <Skeleton
                            sx={{
                              marginBottom: "23px",
                            }}
                            variant="rounded"
                            width="100%"
                            height={56}
                          />
                        );
                      }

                      return (
                        <TextField
                          {...field}
                          autoFocus
                          aria-label="First Name Field"
                          data-testid="first-name-field"
                          error={touched.firstName && Boolean(errors.firstName)}
                          fullWidth
                          inputProps={{ "data-lpignore": true }}
                          label="First name"
                          placeholder="John"
                          type="text"
                          variant="outlined"
                          id="textus-ContactForm-FirstName"
                        />
                      );
                    }}
                  </Field>

                  <Field type="text" name="lastName">
                    {({ field }: FieldProps) => {
                      if (loading) {
                        return (
                          <Skeleton
                            sx={{
                              marginBottom: "23px",
                            }}
                            variant="rounded"
                            width="100%"
                            height={56}
                          />
                        );
                      }

                      return (
                        <TextField
                          {...field}
                          aria-label="Last Name Field"
                          data-testid="last-name-field"
                          error={touched.lastName && Boolean(errors.lastName)}
                          fullWidth
                          inputProps={{ "data-lpignore": true }}
                          label="Last name"
                          placeholder="Doe"
                          type="text"
                          variant="outlined"
                          id="textus-ContactForm-LastName"
                        />
                      );
                    }}
                  </Field>
                </FormRow>

                {/* Business name */}
                <FormRow>
                  <Field type="text" name="data.business">
                    {({ field }: FieldProps) => {
                      if (loading) {
                        return (
                          <Skeleton
                            sx={{
                              marginBottom: "23px",
                            }}
                            variant="rounded"
                            width="100%"
                            height={56}
                          />
                        );
                      }

                      return (
                        <TextField
                          {...field}
                          aria-label="Business Name"
                          data-testid="business-name"
                          fullWidth
                          inputProps={{ "data-lpignore": true }}
                          label="Business name"
                          placeholder="Acme Co"
                          type="text"
                          variant="outlined"
                        />
                      );
                    }}
                  </Field>
                </FormRow>

                {/* Phone numbers and Types */}
                <FieldArray name="phones">
                  {(arrayHelpers) => {
                    return (
                      <div>
                        {values.phones.map((phone, index) => {
                          return (
                            <StyledPhoneNumberEntry key={index}>
                              <FormRow>
                                <StyledPhoneNumberRow>
                                  <PhoneNumberWrapper>
                                    <Field name={`phones.${index}.number`}>
                                      {({ field }: FieldProps) => {
                                        if (loading) {
                                          return (
                                            <Skeleton
                                              sx={{
                                                marginBottom: "23px",
                                              }}
                                              variant="rounded"
                                              width="100%"
                                              height={56}
                                            />
                                          );
                                        }

                                        return (
                                          <TextField
                                            {...field}
                                            error={
                                              getIn(
                                                touched,
                                                `phones.${index}.number`,
                                              ) &&
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `phones.${index}.number`,
                                                ),
                                              )
                                            }
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                `phones.${index}.number`,
                                              )
                                                ? getIn(
                                                    errors,
                                                    `phones.${index}.number`,
                                                  ) || null
                                                : null
                                            }
                                            inputProps={{
                                              "data-lpignore": true,
                                            }}
                                            label="Phone number*"
                                            aria-label="Phone Number"
                                            data-testid="phone-number"
                                            placeholder="(555) 555-5555"
                                            type="text"
                                            variant="outlined"
                                            id={`textus-ContactForm-number-${index}`}
                                          />
                                        );
                                      }}
                                    </Field>
                                  </PhoneNumberWrapper>

                                  <PhoneFieldWrapper>
                                    <Field name={`phones.${index}.type`}>
                                      {({ field }: FieldProps) => {
                                        if (loading) {
                                          return (
                                            <Skeleton
                                              sx={{
                                                marginBottom: "23px",
                                              }}
                                              variant="rounded"
                                              width="100%"
                                              height={56}
                                            />
                                          );
                                        }

                                        return (
                                          <TextField
                                            {...field}
                                            error={
                                              getIn(
                                                touched,
                                                `phones.${index}.type`,
                                              ) &&
                                              Boolean(
                                                getIn(
                                                  errors,
                                                  `phones.${index}.type`,
                                                ),
                                              )
                                            }
                                            fullWidth
                                            helperText={
                                              getIn(
                                                touched,
                                                `phones.${index}.type`,
                                              )
                                                ? getIn(
                                                    errors,
                                                    `phones.${index}.type`,
                                                  ) || null
                                                : null
                                            }
                                            inputProps={{
                                              "data-lpignore": true,
                                            }}
                                            label="Type"
                                            placeholder="Type"
                                            type="text"
                                            variant="outlined"
                                          />
                                        );
                                      }}
                                    </Field>
                                  </PhoneFieldWrapper>
                                </StyledPhoneNumberRow>

                                <StyledDeletePhoneNumber>
                                  <Tooltip title="Remove phone number">
                                    <div>
                                      <IconButton
                                        type="button"
                                        onClick={() => {
                                          return arrayHelpers.remove(index);
                                        }}
                                        disabled={
                                          loading ||
                                          values.phones.length === 1 ||
                                          conversationPhoneNumber ===
                                            values.phones[index].number
                                        }
                                        size="medium"
                                      >
                                        <CloseIcon fontSize="medium" />
                                      </IconButton>
                                    </div>
                                  </Tooltip>
                                </StyledDeletePhoneNumber>
                              </FormRow>

                              <StyledAddPhoneNumber>
                                {index === values.phones.length - 1 &&
                                  values.phones.length < 5 && (
                                    <Button
                                      startIcon={<AddIcon />}
                                      onClick={() => {
                                        return arrayHelpers.push({
                                          number: "",
                                          type: "Mobile",
                                        });
                                      }}
                                      sx={{
                                        fontSize: "14px",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      Add Number
                                    </Button>
                                  )}
                              </StyledAddPhoneNumber>
                            </StyledPhoneNumberEntry>
                          );
                        })}
                      </div>
                    );
                  }}
                </FieldArray>

                <Divider />

                {/* Tags */}
                <FormRow>
                  <Field type="text" name="data.tags">
                    {() => {
                      if (loading) {
                        return (
                          <Skeleton
                            sx={{
                              marginBottom: "23px",
                            }}
                            variant="rounded"
                            width="100%"
                            height={56}
                          />
                        );
                      }

                      return (
                        <ContactFormTags
                          contactTagsId={`/${currentAccount.slug}/contacts/tags`}
                          changeHandler={setFieldValue}
                          value={values.data.tags}
                        />
                      );
                    }}
                  </Field>
                </FormRow>

                {/* TODO: (JOHN BROWN) CREATE `ADD TO GROUPS` SELECTOR WHEN BACKEND READY */}
              </FormWrapper>

              {contact?.data?.bullhorn?.Candidate && onContactUnlink && (
                <Accordion
                  disableGutters
                  square
                  sx={() => {
                    return {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      boxShadow: "none",
                      "&:before": {
                        display: "none",
                      },
                      "&.Mui-expanded": {
                        ".MuiAccordionSummary-root": {
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        },
                      },
                    };
                  }}
                >
                  <AccordionSummary
                    sx={() => {
                      return {
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-content": {
                          marginLeft: theme.spacing(1),
                        },
                      };
                    }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    Bullhorn Details
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      paddingX: 2,
                      paddingY: 1,
                    }}
                  >
                    <BullhornCandidate
                      contact={contact}
                      currentAccount={currentAccount}
                      onChanged={(candidate) => {
                        setFieldValue("name", candidate.name);
                        setFieldValue("data.bullhorn.Candidate", candidate);
                      }}
                      onContactUnlink={onContactUnlink}
                    />
                  </AccordionDetails>
                </Accordion>
              )}

              {contact?.data?.greenhouse?.Candidate && onContactUnlink && (
                <Accordion
                  disableGutters
                  square
                  sx={() => {
                    return {
                      borderTop: `1px solid ${theme.palette.divider}`,
                      borderBottom: `1px solid ${theme.palette.divider}`,
                      boxShadow: "none",
                      "&:before": {
                        display: "none",
                      },
                      "&.Mui-expanded": {
                        ".MuiAccordionSummary-root": {
                          borderBottom: `1px solid ${theme.palette.divider}`,
                        },
                      },
                    };
                  }}
                >
                  <AccordionSummary
                    sx={() => {
                      return {
                        flexDirection: "row-reverse",
                        "& .MuiAccordionSummary-content": {
                          marginLeft: theme.spacing(1),
                        },
                      };
                    }}
                    expandIcon={<ExpandMoreIcon />}
                  >
                    Greenhouse details
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      paddingX: 2,
                      paddingY: 1,
                    }}
                  >
                    <GreenhouseCandidate
                      contact={contact}
                      currentAccount={currentAccount}
                      onChanged={(candidate) => {
                        setFieldValue(
                          "name",
                          `${candidate.first_name} ${candidate.last_name}`,
                        );
                        setFieldValue("data.greenhouse.Candidate", candidate);
                      }}
                      onContactUnlink={onContactUnlink}
                    />
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
            <DialogFooter
              onCancel={onCancel}
              hideCancelOnMobile
              confirmText="Save"
              isConfirmDisabled={!isValid || isSubmitting || loading}
              altButton={
                canDelete && onDelete ? (
                  <Button
                    color="error"
                    variant="outlined"
                    disabled={isSubmitting || loading}
                    onClick={onDelete}
                    sx={{
                      [theme.breakpoints.down("sm")]: {
                        width: "100%",
                      },
                    }}
                  >
                    Remove Contact
                  </Button>
                ) : undefined
              }
            />
          </Grid>
        );
      }}
    </Formik>
  );
}
