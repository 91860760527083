import get from "lodash/get";
import styled from "styled-components";
import BaseButton from "./BaseButton";

const labeling = get(window, ["branding", "labeling"], {});

const PrimaryButton = styled(BaseButton)`
  color: ${(props) => {
    return props.theme.colors.primary.contrastText;
  }};
  border-color: transparent;
  background-color: ${labeling?.primary_color};

  &:hover,
  &:active {
    color: ${(props) => {
      return props.theme.colors.primary.contrastText;
    }};
    border-color: transparent;
    background-color: ${labeling?.secondary_color};
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active {
    color: ${(props) => {
      return props.theme.colors.action.disabled;
    }};
    border-color: transparent;
    background-color: ${(props) => {
      return props.theme.colors.action.disabledBackground;
    }};
  }
`;

export default PrimaryButton;
