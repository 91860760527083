/* eslint-disable react/no-unstable-nested-components */
import { useState, useEffect, ChangeEvent } from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { components } from "react-select";
import Creatable from "react-select/creatable";
import Divider from "@mui/material/Divider";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  DropdownProps,
  HandleOptionProps,
  MenuProps,
  PrevStateFunctionProps,
  Props,
  WrapperProps,
} from "./types";
import { ArrowBackIcon } from "icons";
import localStorageHelper from "utils/localStorageHelper";
import { useCurrentAccount } from "hooks";

const ClearHistoryButton = styled(Button)({
  display: "block",
  fontSize: "14px",
  fontWeight: 500,
  padding: "15px 10px",
  width: "100%",
  textDecoration: "none",
  textTransform: "uppercase",
  "&:hover": {
    textDecoration: "none",
    backgroundColor: "transparent",
  },
});

const Wrapper = styled(Box)(({ theme }: WrapperProps) => {
  return {
    display: "flex",
    background: theme?.palette.background.paper,
    alignItems: "center",
    padding: "8px",
    minHeight: "55px",
    borderBottom: `1px solid ${theme?.palette.divider}`,
  };
});

// https://github.com/JedWatson/react-select/issues/3068
// Need to add !important to input so the opacity does not go to 0 on selection

const SearchField = styled(Box)({
  flex: "1 1 auto",
  paddingRight: "0",
  "& input": {
    opacity: "1 !important",
  },
  marginRight: "8px",
});

const sanitizeSearchInput = (inputArg = "") => {
  const matches = inputArg.match(/"/g) || [];
  const validatedInput =
    matches.length % 2 === 0 ? inputArg : inputArg.replace('"', "");
  return validatedInput
    .replaceAll(/tags?: ?/gi, "tags:")
    .replaceAll(/business: ?/gi, "business:");
};

function PageSearchHeader({
  backClickHandler,
  clearHandler,
  localStorageKey,
  placeholder,
  searchHandler,
  styles,
  type,
  value,
  autoFocus,
  theme,
}: Props) {
  const [input, setInput] = useState<string | undefined>(undefined);
  const [recentQueries, setRecentQueries] = useState(
    localStorageHelper.getItem(localStorageKey, {
      fallback: [],
    }),
  );

  const [prevValue, setPrevValue] = useState<
    string | null | PrevStateFunctionProps
  >(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    return setInput(value);
  }, [value]);

  useEffect(() => {
    const inputValue = input === undefined ? value : input;
    setInput(inputValue);
    setPrevValue(inputValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input, prevValue]);

  const handleClear = () => {
    setInput("");
    if (clearHandler) {
      clearHandler();
    }
  };

  const handleClearSearchHistory = () => {
    setRecentQueries([]);
    localStorageHelper.setItem({
      item: localStorageKey,
      value: [],
    });
  };

  const handleSubmit = (
    inputSelection: string,
    event?: ChangeEvent<HTMLInputElement>,
  ) => {
    if (event) {
      event.preventDefault();
    }
    const queriesToSave = [
      ...new Set([...recentQueries, input].filter(Boolean)),
    ];

    setRecentQueries(queriesToSave);
    localStorageHelper.setItem({
      item: localStorageKey,
      value: queriesToSave,
    });
    if (searchHandler) {
      searchHandler(inputSelection);
    }
  };

  const handleSelectOption = (selectedOption: {
    value: string;
    label: string;
  }) => {
    setInput(selectedOption.value);
    handleSubmit(selectedOption.value);
  };

  const handleChange = (
    option: HandleOptionProps | string | null,
    { action }: { action: string },
  ) => {
    switch (action) {
      case "create-option":
      case "select-option":
        if (typeof option === "string") {
          return handleSelectOption({
            value: sanitizeSearchInput(option),
            label: sanitizeSearchInput(option),
          });
        }
        if (option) {
          return handleSelectOption({
            value: sanitizeSearchInput(option.value),
            label: sanitizeSearchInput(option.label),
          });
        }
        return undefined;
      case "clear":
        return handleClear();
      default:
        return null;
    }
  };

  const handleInputChange = (
    inputArg: string,
    { action }: { action: string },
  ) => {
    switch (action) {
      case "input-change":
        return setInput(inputArg);
      default:
        return setPrevValue((prevState: string | null) => {
          return { prevState, inputArg };
        });
    }
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const account = useCurrentAccount();

  function ClearInputButton(props: DropdownProps) {
    return (
      props.selectProps.inputValue &&
      (props.isFocused || isHovered) && (
        <Box
          display="flex"
          onMouseDown={(e) => {
            e.stopPropagation();
            return e.preventDefault();
          }}
        >
          <Tooltip
            title="Clear"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -14],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              aria-label="Clear search input"
              onClick={() => {
                return setInput("");
              }}
            >
              <CloseIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      )
    );
  }

  const options = recentQueries.map((q: string) => {
    return { value: q, label: q };
  });

  function MenuListRedesign(props: MenuProps) {
    return (
      <components.MenuList {...props}>
        {props.children}
        {props.selectProps.includesRecentQueries && (
          <>
            <Divider />
            <ClearHistoryButton
              onClick={props.selectProps.clearSearchHistoryHandler}
            >
              Clear Search History
            </ClearHistoryButton>
          </>
        )}
      </components.MenuList>
    );
  }

  function MenuList(props: MenuProps) {
    return (
      <components.MenuList {...props}>
        {props.children}
        {props.selectProps.includesRecentQueries && (
          <ClearHistoryButton
            aria-label="Clear Search History Button"
            onClick={props.selectProps.clearSearchHistoryHandler}
            variant="text"
          >
            Clear Search History
          </ClearHistoryButton>
        )}
      </components.MenuList>
    );
  }

  const createableComponents: any = {
    MenuList: MenuListRedesign,
    DropdownIndicator: ClearInputButton,
    IndicatorContainer: null,
    IndicatorSeparator: null,
  };

  return (
    <Wrapper className="page-search-header">
      <IconButton
        onClick={backClickHandler}
        sx={{
          display: "flex",
          flexFlow: "column",
          height: "40px",
          justifyContent: "center",
          paddingRight: "8px",
          "&.MuiButtonBase-root": {
            backgroundColor: "transparent",
          },
        }}
      >
        <ArrowBackIcon
          style={{
            fontSize: 24,
            color: "rgba(0, 0, 0, 0.8)",
          }}
        />
      </IconButton>
      <SearchField
        data-testid="search-field"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{ position: "relative" }}
      >
        <Creatable
          autoFocus={autoFocus}
          // @ts-expect-error Internal creatable types are missing some props
          clearSearchHistoryHandler={handleClearSearchHistory}
          components={createableComponents}
          createOptionPosition="first"
          formatCreateLabel={(inputValue) => {
            return <div>{`Search ${type} for "${inputValue}"`}</div>;
          }}
          isClearable
          inputValue={input ? input.replace(/^\s+/, "") : ""}
          includesRecentQueries={options.length > 0}
          noOptionsMessage={() => {
            return `Begin typing to search for ${type}`;
          }}
          onChange={handleChange}
          onInputChange={handleInputChange}
          options={options}
          placeholder={placeholder}
          styles={{
            ...styles,
            control: (base, state) => {
              return {
                ...base,
                border: state.isFocused
                  ? "2px solid rgba(40, 60, 89, 1)"
                  : "1px solid base.borderColor",
                "&:hover": {
                  border: isHovered
                    ? "2px solid rgba(40, 60, 89, 1)"
                    : "1px solid base.borderColor",
                },
              };
            },
            menu: (provided) => {
              return {
                ...provided,
                zIndex: 10_000,
              };
            },
            option: (provided, state) => {
              return {
                ...provided,
                backgroundColor: state.isFocused
                  ? "rgba(40, 114, 167, 0.08)"
                  : "white",
                "&:active": {
                  backgroundColor: "rgba(40, 114, 167, 0.12)",
                },
              };
            },
          }}
          theme={(reactSelectTheme) => {
            return {
              ...reactSelectTheme,
              colors: {
                ...reactSelectTheme.colors,
                neutral0: theme.colors.background.paper,
                neutral80: theme.colors.text.primary,
                primary25: theme.colors.action.hover,
                primary: theme.colors.message.outboundBackground,
              },
            };
          }}
          value=""
        />
      </SearchField>
    </Wrapper>
  );
}

PageSearchHeader.propTypes = {
  backClickHandler: PropTypes.func.isRequired,
  clearHandler: PropTypes.func.isRequired,
  localStorageKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  searchHandler: PropTypes.func.isRequired,
  styles: PropTypes.object,
  type: PropTypes.string.isRequired,
  value: PropTypes.string,
  autoFocus: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withTheme(PageSearchHeader);
