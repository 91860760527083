import { useState } from "react";
import { useDispatch } from "react-redux";

import { useSnackbar } from "notistack";
import { actionGenerators } from "../state/contactState";
import { convertToCsv, parseCsv } from "features/AccountSettings/utils";
import { useCurrentAccount } from "hooks";

interface useContactUploadReturn {
  error: any;
  isUploading: boolean;
  signedUrl: string | null;
  parsedResults: any;
  processContacts: (file: File) => void;
  uploadContacts: (headers: string[], url: string) => void;
}

export const useContactUpload = (): useContactUploadReturn => {
  const [error, setError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [parsedResults, setParsedResults] = useState(null);
  const [signedUrl, setSignedUrl] = useState<string | null>(null);
  const account = useCurrentAccount();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    // @ts-ignore
    uploadContactImportRequest,
    // @ts-ignore
    importContactImportRequest,
  } = actionGenerators;

  const parseFile = (csv: File) => {
    return parseCsv({
      csv,
      successCallback: (_parsedResults: any) => {
        setParsedResults(_parsedResults);
        setError(null);
      },
      errorCallback: (_error: any) => {
        setError(_error);
      },
    });
  };

  /*
   * At this point, the dropzone has accepted the file
   * and we need to parse the file before sending to S3
   */
  const processContacts = (file: File) => {
    const fileName = file.name.slice(
      0,
      Math.max(0, file.name.lastIndexOf(".")),
    );
    const reader = new FileReader();
    reader.addEventListener("load", (event) => {
      const data = new Uint8Array(event?.target?.result);
      const csvFile = convertToCsv({ data, fileName });
      parseFile(csvFile);
      uploadFile(csvFile);
    });
    reader.readAsArrayBuffer(file);
  };

  /*
   * The file has been parsed and is being uploaded to S3
   */
  const uploadFile = (file: File) => {
    setIsUploading(true);
    dispatch(
      uploadContactImportRequest(
        account.presignContactImport,
        {
          file,
        },
        {
          successCallback: (_signedUrl: string) => {
            setSignedUrl(_signedUrl);
          },
          errorCallback: (errorUploading: any) => {
            setError(errorUploading);
          },
          cleanup: () => {
            return setIsUploading(false);
          },
        },
      ),
    );
  };

  /*
   * The contacts are now finally being imported and/or created in our system
   */
  const uploadContacts = (headers: string[], url: string) => {
    enqueueSnackbar(
      "We're working on importing your contacts now. We'll send you an email and notification when step 2 is ready.",
      {
        variant: "info",
      },
    );
    dispatch(
      importContactImportRequest(
        account.contactImport,
        {
          headers,
          url,
        },
        {
          successCallback: () => {
            setSignedUrl(null);
            setParsedResults(null);
          },
          errorCallback: (errorImporting: any) => {
            setSignedUrl(null);
            setError(errorImporting);
            setIsUploading(false);
            enqueueSnackbar("Something went wrong. Please try again.", {
              variant: "error",
            });
          },
        },
      ),
    );
  };

  return {
    error,
    isUploading,
    processContacts,
    signedUrl,
    parsedResults,
    uploadContacts,
  };
};
