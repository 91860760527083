import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Formik, Field, getIn } from "formik";
import TextField from "@mui/material/TextField";

import IntegrationForm from "./IntegrationForm";
import IntegrationSubmitSection from "./IntegrationSubmitSection";
import { ErecruitValidationSchema } from "formHelpers/validationSchemas";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

const Logo = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 20px;
  background: url("${(props) => {
    return props.src;
  }}");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`;

const FormRow = styled.div`
  & + & {
    margin-top: 20px;
  }
`;

class Erecruit extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    toggleActive: PropTypes.func.isRequired,
  };

  handleSubmit = (values) => {
    this.props.handleSubmit({
      provider: this.props.provider,
      config: values.config,
    });
  };

  toggleActive = () => {
    this.props.toggleActive({
      provider: this.props.provider,
    });
  };

  render() {
    return (
      <>
        <ModalHeader closeModal={this.props.provider.closeModal}>
          {this.props.provider.name}
        </ModalHeader>
        <ModalContent>
          <Formik
            enableReinitialize
            initialValues={{
              config: {
                clientId: get(
                  this.props.provider,
                  ["integration", "config", "clientId"],
                  "",
                ),
                clientSecret: get(
                  this.props.provider,
                  ["integration", "config", "clientSecret"],
                  "",
                ),
                entityId: get(
                  this.props.provider,
                  ["integration", "config", "entityId"],
                  "00000000-0000-0000-0000-000000000E01",
                ),
                baseUrl: get(
                  this.props.provider,
                  ["integration", "config", "baseUrl"],
                  "",
                ),
              },
            }}
            onSubmit={this.handleSubmit}
            validationSchema={ErecruitValidationSchema}
          >
            {({ errors, touched, isSubmitting, isValid }) => {
              return (
                <IntegrationForm>
                  <Logo src={this.props.provider.logo} />
                  <FormRow>
                    <Field name="config.clientId" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, "config.clientId") &&
                              Boolean(getIn(errors, "config.clientId"))
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "config.clientId")
                                ? getIn(errors, "config.clientId") || " "
                                : " "
                            }
                            label="Client ID"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name="config.clientSecret" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, "config.clientSecret") &&
                              Boolean(getIn(errors, "config.clientSecret"))
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "config.clientSecret")
                                ? getIn(errors, "config.clientSecret") || " "
                                : " "
                            }
                            label="Client secret"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name="config.entityId" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, "config.entityId") &&
                              Boolean(getIn(errors, "config.entityId"))
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "config.entityId")
                                ? getIn(errors, "config.") || " "
                                : " "
                            }
                            label="Entity ID"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                  <FormRow>
                    <Field name="config.baseUrl" type="text">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            error={
                              getIn(touched, "config.baseUrl") &&
                              Boolean(getIn(errors, "config.baseUrl"))
                            }
                            fullWidth
                            helperText={
                              getIn(touched, "config.baseUrl")
                                ? getIn(errors, "config.baseUrl") || " "
                                : " "
                            }
                            label="Base URL"
                            type="text"
                            variant="outlined"
                          />
                        );
                      }}
                    </Field>
                  </FormRow>
                  <IntegrationSubmitSection
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    provider={this.props.provider}
                    toggleActive={this.props.toggleActive}
                  />
                </IntegrationForm>
              );
            }}
          </Formik>
        </ModalContent>
      </>
    );
  }
}

export default Erecruit;
