import { ColumnHeader } from "../models/AutomationModels";

const keywordGroupsColumnHeader: readonly ColumnHeader[] = [
  {
    id: "keyword",
    label: "Group",
    numeric: false,
  },
  {
    id: "sequences",
    label: "Sequences linked",
    numeric: false,
  },
  {
    id: "numberOfContacts",
    label: "Number of contacts",
    numeric: false,
  },
  {
    id: "createdBy",
    label: "Created by",
    numeric: false,
  },
  {
    id: "createdAt",
    label: "Created at",
    numeric: false,
  },
];

const ACCOUNT_LICENSES = {
  campaigns: "campaigns",
  keywords: "keywords",
  sequences: "sequences",
  support: "support",
  analytics: "analytics",
  message: "message",
} as const;

export { ACCOUNT_LICENSES, keywordGroupsColumnHeader };
