import { darken, lighten } from "polished";
import { textUsColors } from "@tesseract/theme/src/palettes/colors";

// TODO: This whole file needs to go. Combine it with the main theme file (createTheme.tsx).

const createBasicTheme = ({ accent, outboundMessage, muiTheme }) => {
  const { grayscale } = textUsColors;
  return {
    ...muiTheme.palette,
    accent: {
      main: accent,
      tint: muiTheme.palette.mode === "dark" ? "#273B5A" : "#F6FBF0",
      contrastText:
        muiTheme.palette.mode === "dark"
          ? "#fff"
          : muiTheme.palette.secondary.contrastText,
    },
    disabledOverlay: "rgb(100, 100, 100, 0.1)",
    message: {
      hoverColor: muiTheme.palette.mode === "dark" ? "#90CAF9" : "#F2F2F2",
      inboundBackground:
        muiTheme.palette.mode === "dark"
          ? lighten(0.08, muiTheme.palette.background.default)
          : darken(0.08, muiTheme.palette.background.default),
      inboundColor: muiTheme.palette.text.primary,
      outboundBackground: outboundMessage,
      outboundMessage: muiTheme.palette.mode === "dark" ? "#5E5E5E" : "#F2F2F2",
      outboundColor: muiTheme.palette.text.primary,
      hubspotOutboundColor:
        muiTheme.palette.mode === "dark"
          ? textUsColors.purple[900]
          : textUsColors.purple[50],
      newBorderColor: muiTheme.palette.mode === "dark" ? "#181818" : "#8bc63f",
      newBackgroundColor:
        muiTheme.palette.mode === "dark" ? grayscale[300] : "#f5fbf0",
      staleBorderColor:
        muiTheme.palette.mode === "dark" ? grayscale[400] : "#fadf56",
      staleBackgroundColor:
        muiTheme.palette.mode === "dark" ? grayscale[500] : "#fffbe5",
      superStaleBorderColor:
        muiTheme.palette.mode === "dark" ? grayscale[600] : "#d16565",
      superStaleBackgroundColor:
        muiTheme.palette.mode === "dark" ? grayscale[700] : "#f5fbf0",
      unreadCheckboxColor: "#7b7c78",
      unreadBottomBorderColor:
        muiTheme.palette.mode === "dark" ? "#7b7c78" : "#0000001f",
      unreadMessageTextColor:
        muiTheme.palette.mode === "dark" ? "#222326" : "#000000cc",
      unreadMessageButtonColor: "#7b7c78",
    },
    navigation: {
      background:
        muiTheme.palette.mode === "dark"
          ? darken(0.2, muiTheme.palette.background.default)
          : muiTheme.palette.primary.main,
      color: "rgba(255, 255, 255, 0.5)",
      hoverColor: "rgba(255, 255, 255, 1)",
    },
    star: "#F2BE1D",
  };
};

export default ({ appColors, muiTheme }) => {
  return createBasicTheme({
    accent: appColors.tertiaryColor,
    outboundMessage: appColors.outboundMessageColor,
    muiTheme,
  });
};
