import { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import { useHistory } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import queryString from "query-string";
import {
  Box,
  Grid,
  Paper,
  Typography,
  Divider,
  TablePagination,
  useMediaQuery,
  Chip,
  Skeleton,
} from "@mui/material";
import { handleResponse } from "@tesseract/core";
import { useSnackbar } from "notistack";
import { createTimeStamp } from "../utils";
import {
  campaignFilterSlugs,
  campaignTypes,
} from "features/Campaigns/constants";
import PageLoader from "components/PageLoader";
import Avatar from "components/Avatar";
import { useLoading } from "hooks";
import { fetchCampaignCollection } from "features/Campaigns/api";

function CampaignOverviewCollection({
  ariaLabel,
  campaignCollectionId,
  campaignFilter,
  currentAccount,
  emptyState,
  header,
  sendVerbiage,
  showAvatar,
}) {
  const fromCampaignLanding = campaignFilter.slug === campaignFilterSlugs.mine;

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const history = useHistory();
  const { isLoading, stopLoading, startLoading } = useLoading();
  const { enqueueSnackbar } = useSnackbar();

  const [campaignCollection, setCampaignCollection] = useState([]);
  const [page, setPage] = useState(0);

  const { oneTime, recurring, recurringInstance } = campaignTypes;

  const queryParams = useMemo(() => {
    return sendVerbiage === "Sending"
      ? [recurring, oneTime]
      : [oneTime, recurringInstance];
  }, [oneTime, recurring, recurringInstance, sendVerbiage]);

  const getCampaigns = useCallback(() => {
    const controller = new AbortController();

    fetchCampaignCollection(campaignCollectionId, {
      type: fromCampaignLanding ? queryParams : undefined,
    })
      .then(handleResponse)
      .then(setCampaignCollection)
      .catch((error) => {
        enqueueSnackbar("Something went wrong, please try again", {
          variant: "error",
        });
        console.error(error);
      });

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignCollectionId, fromCampaignLanding, queryParams]);

  useEffect(() => {
    getCampaigns();
  }, [getCampaigns]);

  if (isEmpty(campaignCollection)) {
    return <PageLoader />;
  }

  const rowsPerPage = 30;
  const showPagination = campaignCollection?.totalItems ?? rowsPerPage < 0;

  const updateDisplayedCampaigns = (endpoint) => {
    startLoading();
    fetchCampaignCollection(endpoint)
      .then(handleResponse)
      .then((data) => {
        return setCampaignCollection(data);
      })
      .finally(stopLoading)
      .catch((error) => {
        enqueueSnackbar("Something went wrong, please try again", {
          variant: "error",
        });
        console.error(error);
      });
  };

  const handleChangePage = (event, newPage) => {
    const { view } = campaignCollection;

    const params = queryString.stringify(
      { type: queryParams },
      { arrayFormat: "bracket" },
    );

    const separator = view.first === view.previous ? "?" : "&";

    if (page < newPage) {
      updateDisplayedCampaigns(
        fromCampaignLanding ? `${view.next}&${params}` : view.next,
      );
    } else {
      updateDisplayedCampaigns(
        fromCampaignLanding
          ? `${view.previous}${separator}${params}`
          : view.previous,
      );
    }

    return setPage(newPage);
  };

  return (
    <Grid item xs={mobileScreen ? 12 : 6} aria-label={ariaLabel}>
      <Paper elevation={3}>
        <Box
          sx={(theme) => {
            return {
              borderBottom: fromCampaignLanding
                ? `1px solid ${theme.palette.divider}`
                : "none",
              backgroundColor: fromCampaignLanding
                ? theme.palette.customColors.backgrounds.secondary
                : "inherit",
            };
          }}
        >
          <Typography variant="h5" padding="1rem">
            {`${header} (${campaignCollection.totalItems})`}
          </Typography>
        </Box>
        {isEmpty(campaignCollection?.members) ? (
          <Box p="2rem" color="text.secondary">
            <Typography align="center" variant="h6" fontWeight={700}>
              {emptyState}
            </Typography>
          </Box>
        ) : (
          <Box
            height={{
              xs: "calc(100vh - 280px)",
              sm: "calc(100vh - 223px)",
            }}
            maxHeight={fromCampaignLanding ? "500px" : "unset"}
            sx={{ overflowY: "auto" }}
          >
            {campaignCollection.members.map(
              ({ id, scheduledAt, schedule, title, type, user }, index) => {
                if (isLoading) {
                  return (
                    <Box
                      display="flex"
                      flexDirection="column"
                      key={id}
                      padding="0 1rem"
                    >
                      <Skeleton width="100%" height="6rem" />
                    </Box>
                  );
                }

                const singleCampaign = type === "single";

                const timeStamp = createTimeStamp({
                  currentAccount,
                  schedule,
                  scheduledAt,
                  sendVerbiage,
                  singleCampaign,
                });

                const campaignType = singleCampaign ? "One-time" : "Recurring";

                const handleClick = () => {
                  const newPath = `${global.document.location.pathname}${id.replace("/campaigns", "")}`;
                  history.push(newPath);
                };

                return (
                  <Box
                    key={id}
                    data-testid={id}
                    onClick={handleClick}
                    sx={(theme) => {
                      return {
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: theme.palette.action.hover,
                        },
                      };
                    }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      padding="1rem"
                    >
                      <Box
                        display="flex"
                        flexDirection="row"
                        alignItems="center"
                      >
                        {showAvatar ? (
                          <Avatar
                            subject={user}
                            sx={{ marginRight: "0.5rem" }}
                          />
                        ) : null}
                        <Box display="flex" flexDirection="column">
                          <Box
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            gap="0.25rem"
                          >
                            <Typography
                              variant="body2"
                              fontWeight={700}
                              color="text.primary"
                            >
                              {title}
                            </Typography>
                            <Chip
                              label={campaignType}
                              variant="outlined"
                              size="small"
                            />
                          </Box>
                          <Typography variant="caption" color="text.secondary">
                            {timeStamp}
                          </Typography>
                        </Box>
                      </Box>
                      <Box alignSelf="center">
                        <ArrowForwardIcon
                          sx={(theme) => {
                            return {
                              color: theme.palette.action.active,
                            };
                          }}
                        />
                      </Box>
                    </Box>
                    {showPagination &&
                    campaignCollection.members.at(-1) ===
                      campaignCollection.members[index] ? null : (
                      <Divider />
                    )}
                  </Box>
                );
              },
            )}
          </Box>
        )}
        <Box
          width="100%"
          display="flex"
          justifyContent="right"
          sx={(theme) => {
            return {
              borderTop: `1px solid ${theme.palette.divider}`,
            };
          }}
        >
          <TablePagination
            page={page} //
            count={campaignCollection?.totalItems ?? 0}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]} // hides rowsPerPage text and dropdown
            sx={{ borderBottom: "none" }}
          />
        </Box>
      </Paper>
    </Grid>
  );
}

CampaignOverviewCollection.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  campaignCollectionId: PropTypes.string.isRequired,
  campaignFilter: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  emptyState: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  showAvatar: PropTypes.bool,
  sendVerbiage: PropTypes.string.isRequired,
};

export { CampaignOverviewCollection };
