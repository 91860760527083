import {
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MobileBottomNavigationProps } from "./MobileBottomNavigationProps";
import { getFontAwesomeIcon } from "features/MainNavigation/utils";
import { useCurrentAccount } from "hooks";
import useIsExtensionPopout from "hooks/useIsExtensionPopout";

const StyledLink = styled(NavLink)`
  color: ${(props) => {
    return props.theme.palette.text.primary;
  }};
  text-decoration: none;
`;

const StyledBottomNavigationAction = styled(BottomNavigationAction)`
  .MuiBottomNavigationAction-label.Mui-selected {
    font-size: 0.75rem;
  }
`;

export function MobileBottomNavigation({
  hideSiteNavigation,
  siteNavigation,
  mobileContactDetailsView,
}: MobileBottomNavigationProps) {
  // ==== HOOKS ==== //
  const { slug } = useCurrentAccount();
  const isExtensionPopout = useIsExtensionPopout();
  const [value, setValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // ==== FIELDS ==== //
  const inboxIcon = getFontAwesomeIcon("inbox");
  const contactsIcon = getFontAwesomeIcon("contacts");
  const open = Boolean(anchorEl);

  // ==== METHODS ==== //
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // ==== RENDER ==== //
  if (hideSiteNavigation) {
    return null;
  }

  return (
    <>
      <Divider />
      <BottomNavigation
        sx={{
          ...(mobileContactDetailsView && {
            width: "100%",
            position: "fixed",
            bottom: 0,
            borderTop: "1px solid #0000001F",
          }),
          minHeight: "3.5rem",
        }}
        showLabels
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <StyledBottomNavigationAction
          label="Inbox"
          icon={<FontAwesomeIcon icon={inboxIcon} />}
          component={Link}
          to={`/${slug}/inbox`}
        />

        <StyledBottomNavigationAction
          label="Contacts"
          icon={<FontAwesomeIcon icon={contactsIcon} />}
          component={Link}
          to={`/${slug}/contacts`}
        />

        <StyledBottomNavigationAction
          label="More"
          icon={<MenuIcon />}
          aria-label="more"
          id="mobile-button"
          aria-controls={open ? "mobile-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        />
      </BottomNavigation>

      <Menu
        id="mobile-menu"
        MenuListProps={{
          "aria-labelledby": "mobile-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        sx={{
          marginTop: "-10px",
        }}
      >
        {siteNavigation.members.map(
          ({ icon, uiLink, name, title, badgeRecord }: any) => {
            const fontAwesomeIcon = getFontAwesomeIcon(icon);

            // Do not display automation, analytics, or settings when in Chrome extension.
            if (isExtensionPopout) {
              if (
                name === "analytics" ||
                name === "settings" ||
                name === "automation"
              ) {
                return null;
              }
            }

            // Skip Inbox and Contacts since they are displayed in the bottom navigation.
            if (name === "inbox" || name === "contacts") {
              return null;
            }

            return (
              <StyledLink
                to={uiLink}
                data-product-tour={`SiteNavigationLink-${title}`}
                key={name}
              >
                <MenuItem key={name} onClick={handleClose}>
                  <ListItemIcon>
                    <FontAwesomeIcon icon={fontAwesomeIcon} />
                  </ListItemIcon>
                  <Typography variant="inherit">{title}</Typography>
                </MenuItem>
              </StyledLink>
            );
          },
        )}
      </Menu>
    </>
  );
}
