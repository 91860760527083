import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import { push } from "connected-react-router";
import get from "lodash/get";
import uniq from "lodash/uniq";
import { userManager } from "@tesseract/core";

import getSource from "./utils/getSource";
import * as schema from "schema";
import { actionGenerators as extensionPromptsActionGenerators } from "features/ExtensionPrompts/state";
import { actionGenerators as contactModalActionGenerators } from "features/ContactModal/state";
import {
  selectAppSettings,
  selectCurrentAccount,
} from "features/EntryPoint/containers/App/selectors";
import { updateExtractedPhones } from "features/EntryPoint/containers/App/actions";
import {
  makeRequest,
  normalizeAndUpdateRecords,
} from "utils/generateContainerState/generateSagas";
import PhoneExtraction from "phoneExtraction";
import sessionStorageHelper from "utils/sessionStorageHelper";
import localStorageHelper from "utils/localStorageHelper";
import checkAndRefreshUser from "utils/checkAndRefreshUser";
import useIsExtensionPopout from "hooks/useIsExtensionPopout";

// ACTION TYPES
const HANDLE_EXTENSION_MESSAGE_REQUEST =
  "app/ExtensionMessageHandler/HANDLE_EXTENSION_MESSAGE_REQUEST";
const HANDLE_EXTENSION_MESSAGE_SUCCESS =
  "app/ExtensionMessageHandler/HANDLE_EXTENSION_MESSAGE_SUCCESS";
const HANDLE_EXTENSION_MESSAGE_FAILURE =
  "app/ExtensionMessageHandler/HANDLE_EXTENSION_MESSAGE_FAILURE";
const GET_TESSERACT_SESSION_REQUEST =
  "app/ExtensionMessageHandler/GET_TESSERACT_SESSION_REQUEST";
const SEND_TESSERACT_AUTH_READY_MESSAGE =
  "app/ExtensionMessageHandler/SEND_TESSERACT_AUTH_READY_MESSAGE";
const SEND_TESSERACT_LOGOUT_MESSAGE =
  "app/ExtensionMessageHandler/SEND_TESSERACT_LOGOUT_MESSAGE";

const actionTypes = {
  HANDLE_EXTENSION_MESSAGE_REQUEST,
  HANDLE_EXTENSION_MESSAGE_SUCCESS,
  HANDLE_EXTENSION_MESSAGE_FAILURE,
  GET_TESSERACT_SESSION_REQUEST,
  SEND_TESSERACT_AUTH_READY_MESSAGE,
  SEND_TESSERACT_LOGOUT_MESSAGE,
};

// ACTION GENERATORS
const handleExtensionMessageRequest = (payload) => {
  return {
    type: HANDLE_EXTENSION_MESSAGE_REQUEST,
    payload,
  };
};

const handleExtensionMessageSuccess = (payload) => {
  return {
    type: HANDLE_EXTENSION_MESSAGE_SUCCESS,
    payload,
  };
};

const handleExtensionMessageFailure = (payload) => {
  return {
    type: HANDLE_EXTENSION_MESSAGE_FAILURE,
    payload,
  };
};

const getTesseractSessionRequest = (payload) => {
  return {
    type: GET_TESSERACT_SESSION_REQUEST,
    payload,
  };
};

const sendTesseractAuthReadyMessage = (payload) => {
  return {
    type: SEND_TESSERACT_AUTH_READY_MESSAGE,
    payload,
  };
};

const sendTesseractLogoutMessage = () => {
  return {
    type: SEND_TESSERACT_LOGOUT_MESSAGE,
  };
};

const actionGenerators = {
  handleExtensionMessageRequest,
  handleExtensionMessageSuccess,
  handleExtensionMessageFailure,
  getTesseractSessionRequest,
  sendTesseractAuthReadyMessage,
  sendTesseractLogoutMessage,
};

// SAGAS
export function* handleExtensionMessageRequestSaga({
  payload: { event, countryCode },
}) {
  try {
    const currentAccount = yield select(selectCurrentAccount);
    if (!get(currentAccount, ["contactsEnabled"])) {
      yield put(
        extensionPromptsActionGenerators.setExtensionPrompts({
          type: "contactsNotEnabled",
        }),
      );
      yield put(
        actionGenerators.handleExtensionMessageFailure("Contacts not enabled"),
      );
      return;
    }
    yield put(updateExtractedPhones([]));
    const data = new PhoneExtraction({
      ...event.data,
      countryCode,
    }).parse();
    yield put(
      actionGenerators.handleExtensionMessageSuccess({
        data,
        number: get(event, ["data", "number"]),
      }),
    );
  } catch (error) {
    yield put(actionGenerators.handleExtensionMessageFailure(error));
  }
}

export function* noNumbersStrategy() {
  yield put(
    extensionPromptsActionGenerators.setExtensionPrompts({
      type: "noNumbersFound",
    }),
  );
}

export function* fetchCreateContact({
  currentAccount,
  newContactParams,
  number,
}) {
  try {
    const contact = yield makeRequest({
      url: currentAccount.contacts,
      params: newContactParams,
      method: "POST",
    });
    yield* normalizeAndUpdateRecords({
      record: contact,
      schema: schema.contact,
    });
    const activeConversation = (() => {
      if (!number) return undefined;
      const matchingPhone = get(contact, ["phones", "members"], []).find(
        ({ phoneNumber }) => {
          return `${phoneNumber}`
            .replace(/\D/g, "")
            .includes(`${number}`.replace(/\D/g, ""));
        },
      );
      return get(matchingPhone, ["phoneNumber"]);
    })();
    yield put(
      contactModalActionGenerators.setContactModal({
        active: true,
        activeConversation,
        contact,
        form: false,
      }),
    );
  } catch (error) {
    const contactCollection = error.response;
    if (contactCollection && contactCollection.totalItems > 0) {
      yield put(
        contactModalActionGenerators.setContactModal({
          active: true,
          activeConversation: undefined,
          contact: newContactParams,
          form: true,
          partialMatches: contactCollection.members,
        }),
      );
    }
  }
}

export function* singleContactStrategy({ currentAccount, phones, source }) {
  const {
    first_name: firstName,
    last_name: lastName,
    phone_numbers: phoneNumbers,
  } = phones[0];

  if (phoneNumbers.length === 0) {
    yield noNumbersStrategy();
    return;
  }

  const newContactParams = {
    name: [firstName, lastName].join(" "),
    phones: uniq(phoneNumbers).map((number) => {
      return {
        number,
      };
    }),
    method: "extension",
    source,
  };

  yield fetchCreateContact({ currentAccount, newContactParams });
}

export function* multipleContactsStrategy({ currentAccount, phones, source }) {
  yield put(updateExtractedPhones(phones));
  const pathname = ["full_page_strategy", "selection_strategy"].includes(source)
    ? `/${currentAccount.slug}/contact_imports/new`
    : `/${currentAccount.slug}/contact_imports/extension`;
  yield put(push(pathname, { source }));
}

export function* numberClickedStrategy({
  currentAccount,
  number,
  phones,
  source,
}) {
  const matchingData = phones.find(({ phone_numbers: phoneNumbers }) => {
    return phoneNumbers.includes(number);
  });
  const newContactParams = matchingData
    ? {
        name: [matchingData.first_name, matchingData.last_name].join(" "),
        phones: matchingData.phone_numbers.map((phoneNumber) => {
          return {
            number: phoneNumber,
          };
        }),
        method: "extension",
        source,
      }
    : {
        phones: [{ number }],
        method: "extension",
        source,
      };
  yield fetchCreateContact({ currentAccount, newContactParams, number });
}

export function* handleExtensionMessageSuccessSaga({
  payload: { data, number },
}) {
  const phones = get(data, ["results"], data);
  const strategy = get(data, ["strategy"]);
  const source = getSource(strategy);
  const currentAccount = yield select(selectCurrentAccount);
  if (!currentAccount) return;
  if (number) {
    yield numberClickedStrategy({
      currentAccount,
      number,
      phones,
      source,
    });
    return;
  }
  if (!phones || phones.length === 0) {
    yield noNumbersStrategy();
    return;
  }
  if (phones.length === 1) {
    yield singleContactStrategy({ currentAccount, phones, source });
    return;
  }
  yield multipleContactsStrategy({ currentAccount, phones, source });
}

const getUserAuth = async () => {
  const user = await userManager.getUser();
  const auth = {
    accessToken:
      user?.access_token || sessionStorageHelper.getItem("access_token"),
    refreshToken:
      user?.refresh_token ||
      sessionStorageHelper.getItem("refresh_token") ||
      localStorageHelper.getItem("refresh_token"),
    accessTokenExpirationDate: user?.expires_at,
  };

  return auth;
};

export function* getTesseractSessionRequestSaga({ payload: { source } }) {
  yield call(checkAndRefreshUser);
  const auth = yield call(getUserAuth);
  source.postMessage(
    { type: "getTesseractSessionResponse", payload: auth },
    "*",
  );
}

function* sendMessageToExtension(message) {
  const appSettings = yield select(selectAppSettings);
  const validSources = appSettings?.CHROME_EXTENSION_URLS || [];
  const isExtensionPopout = useIsExtensionPopout();

  if (isExtensionPopout) {
    validSources.forEach((chromeExtensionUrl) => {
      window.parent.postMessage(message, chromeExtensionUrl);
    });
  } else {
    window.postMessage(message);
  }
}

export function* sendTesseractAuthReadyMessageSaga() {
  const auth = yield call(getUserAuth);
  const { origin } = window.location;
  const message = {
    type: "tesseractAuthReady",
    source: "tesseract",
    payload: { ...auth, origin },
  };

  yield call(sendMessageToExtension, message);
}

export function* sendTesseractLogoutMessageSaga() {
  const { origin } = window.location;
  const message = {
    type: "tesseractAuthLogout",
    source: "tesseract",
    payload: { origin },
  };

  yield call(sendMessageToExtension, message);
}

function* saga() {
  yield all([
    takeLatest(
      actionTypes.HANDLE_EXTENSION_MESSAGE_REQUEST,
      handleExtensionMessageRequestSaga,
    ),
    takeLatest(
      actionTypes.HANDLE_EXTENSION_MESSAGE_SUCCESS,
      handleExtensionMessageSuccessSaga,
    ),
    takeLatest(
      actionTypes.GET_TESSERACT_SESSION_REQUEST,
      getTesseractSessionRequestSaga,
    ),
    takeLatest(
      actionTypes.SEND_TESSERACT_AUTH_READY_MESSAGE,
      sendTesseractAuthReadyMessageSaga,
    ),
    takeLatest(
      actionTypes.SEND_TESSERACT_LOGOUT_MESSAGE,
      sendTesseractLogoutMessageSaga,
    ),
  ]);
}

// REDUCERS
const isHandling = (state = false, action) => {
  switch (action.type) {
    case actionTypes.HANDLE_EXTENSION_MESSAGE_REQUEST:
      return true;
    case actionTypes.HANDLE_EXTENSION_MESSAGE_FAILURE:
    case actionTypes.HANDLE_EXTENSION_MESSAGE_SUCCESS:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({ isHandling });

// SELECTORS
const selectExtensionMessageHandlerContainer = (state) => {
  return {
    substate: state.get("extensionMessageHandlerContainer").toJS(),
  };
};

const selectors = { selectExtensionMessageHandlerContainer };

export { actionTypes, actionGenerators, saga, reducer, selectors };
