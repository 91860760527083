export default function getPathnameArray(pathname = window.location.pathname) {
  const [_, root, ...rest] = pathname.split("/");

  if (pathname.startsWith("/c/embedded")) {
    return [_, ...rest.slice(1)];
  }

  if (root === "c") {
    return [_, ...rest];
  }

  return [_, root, ...rest];
}
