import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Box, Button } from "@mui/material";

const Footer = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  padding: 20px;
`;

export default class IntegrationSubmitSection extends Component {
  static propTypes = {
    provider: PropTypes.object.isRequired,
    isSubmitting: PropTypes.bool,
    isValid: PropTypes.bool,
    toggleActive: PropTypes.func.isRequired,
  };

  toggleActive = () => {
    this.props.toggleActive({
      provider: this.props.provider,
    });
  };

  render() {
    const { provider, isValid, isSubmitting } = this.props;
    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flex: "0 0 auto",
          justifyContent: "flex-end",
          padding: "20px",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        {Boolean(provider.integration) && (
          <Button
            color="primary"
            className="toggle-integration"
            type="button"
            onClick={this.toggleActive}
            sx={{
              marginRight: { sx: 0, sm: "10px" },
              width: { xs: "100%", sm: null },
              marginBottom: { xs: "8px", sm: 0 },
            }}
          >
            {get(provider, ["integration", "active"]) ? "Disable" : "Enable"}
          </Button>
        )}
        <Button
          aria-label="Save Integration"
          variant="contained"
          color="primary"
          type="submit"
          disabled={!isValid || isSubmitting}
          sx={{ width: { xs: "100%", sm: null } }}
        >
          Save
        </Button>
      </Box>
    );
  }
}
