import {
  ListItem,
  Typography,
  Link,
  Chip,
  styled,
  IconButton,
  Box,
} from "@mui/material";
import List from "@mui/material/List";
import capitalize from "lodash/capitalize";
import { ComponentProps } from "react";
import { useGreenhouseApplication } from "../utils/getGreenhouseApplication";
import { GreenhouseCandidateDetailsProps } from "./types";
import { dialogIds } from "features/ContactModal/constants";
import { EditIcon } from "icons";

const listItemProps: ComponentProps<typeof ListItem> = {
  sx: {
    display: "block",
  },
  disablePadding: true,
};

const CandidateDetailsLink = styled(Link)(({ theme }) => {
  return {
    color: theme.palette.text.hyperlink,
    textDecoration: "underline",
    "&:hover": {
      cursor: "pointer",
    },
  };
});

export function GreenhouseCandidateDetails({
  candidate,
  editModal,
  setActiveView,
}: GreenhouseCandidateDetailsProps) {
  const { applications } = candidate;

  const applicationToDisplay = useGreenhouseApplication(
    applications,
    candidate,
  );
  return (
    <List
      disablePadding
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gap: 2,
      }}
    >
      {candidate?.id && !editModal ? (
        <ListItem {...listItemProps}>
          <Box display="flex" justifyContent="space-between">
            <Typography variant="subtitle2">Candidate ID</Typography>
            {!editModal && setActiveView ? (
              <IconButton
                aria-label="Edit Contact"
                onClick={() => {
                  setActiveView(dialogIds.edit);
                }}
                size="large"
                sx={{
                  padding: 0,
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Box>
          <CandidateDetailsLink
            variant="body1"
            href={candidate?.link ?? ""}
            target="_blank"
            rel="noreferrer"
          >
            {candidate.id}
          </CandidateDetailsLink>
        </ListItem>
      ) : null}
      {applicationToDisplay && (
        <>
          {editModal ? null : (
            <ListItem {...listItemProps}>
              <Typography variant="subtitle2">Application ID</Typography>
              <Typography variant="body1">{`#${applicationToDisplay.id}`}</Typography>
            </ListItem>
          )}
          <ListItem {...listItemProps}>
            <Typography variant="subtitle2">Candidate status</Typography>
            <Chip
              sx={(theme) => {
                return {
                  backgroundColor: theme.palette.secondary.main,
                  color: "white",
                  marginLeft: 0,
                };
              }}
              size="small"
              variant="filled"
              label={capitalize(applicationToDisplay.status)}
            />
          </ListItem>
          <ListItem {...listItemProps}>
            <Typography variant="subtitle2">Job</Typography>
            <Typography variant="body1">
              {applicationToDisplay.jobs[0].name}
            </Typography>
          </ListItem>
          <ListItem {...listItemProps}>
            <Typography variant="subtitle2">Job stage</Typography>
            <Chip
              sx={(theme) => {
                return {
                  backgroundColor: theme.palette.action.selected,
                  marginLeft: 0,
                };
              }}
              size="small"
              variant="filled"
              label={applicationToDisplay.current_stage.name}
            />
          </ListItem>
        </>
      )}
      {candidate?.emails && candidate?.emails.length > 0 && (
        <ListItem {...listItemProps}>
          <CandidateDetailsLink
            href={`mailto:${candidate.emails[0]}`}
            target="_blank"
            rel="noreferrer"
            variant="body1"
          >
            {candidate.emails[0]}
          </CandidateDetailsLink>
        </ListItem>
      )}
    </List>
  );
}
