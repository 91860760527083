import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { styled } from "@mui/material/styles";

type Props = TooltipProps & {
  sidebarOpen: boolean;
};

const SearchTooltip = styled(({ className, sidebarOpen, ...props }: Props) => {
  return (
    <Tooltip
      className="search-tooltip"
      {...props}
      classes={{ popper: className }}
    />
  );
})(({ theme, sidebarOpen }) => {
  const width = sidebarOpen
    ? "calc(100vw - 62px - 240px)"
    : "calc(100vw - 62px)";

  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: theme.typography.fontSize,
      boxShadow: theme.shadows[8],
      maxWidth: "none",
      padding: 0,
      width,
    },
  };
});

export default SearchTooltip;
