import compose from "lodash/fp/compose";
import snakeCase from "lodash/snakeCase";
import toUpper from "lodash/toUpper";
import { states } from "./constants";

export type ActionKey<
  Actions extends string[],
  Type extends string,
> = Uppercase<`${Actions[number]}_${Type}_${(typeof states)[number]}`>;

/**
 * Generates action types for a container based on the provided parameters.
 *
 * @deprecated - This function is part of `withRecord` and should not be used.
 */
export default <
  Container extends string,
  Actions extends string[],
  Type extends string,
>({
  container,
  actions,
  recordType,
}: Readonly<{
  container: Container;
  actions: Actions;
  recordType: Type;
}>): {
  readonly [K in ActionKey<Actions, Type>]: `app/${Container}/${K}`;
} => {
  type T = {
    readonly [K in ActionKey<Actions, Type>]: `app/${Container}/${K}`;
  };

  return actions.reduce((result, action) => {
    return {
      ...result,
      ...states.reduce((result2, state) => {
        const constantName = compose(
          toUpper,
          snakeCase,
        )(`${action} ${recordType} ${state}`);
        return {
          ...result2,
          [constantName]: `app/${container}/${constantName}`,
        };
      }, {} as T),
    };
  }, {} as T);
};
