const userAvatarUrl = (user, cssSize) => {
  if (!user.settings?.avatar) {
    return user.avatar;
  }
  const size = Math.round(cssSize * window.devicePixelRatio);
  const [, , domain, key] = user.avatar.split("/");
  const data = JSON.parse(atob(key));
  data.edits = {
    resize: {
      width: size,
      height: size,
      fit: "outside",
    },
  };
  return `https://${domain}/${btoa(JSON.stringify(data))}`;
};

export default userAvatarUrl;
