import { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import styled from "styled-components";
import { CallbackComponent } from "redux-oidc";
import { connect } from "react-redux";
import get from "lodash/get";
import { Redirect } from "react-router-dom";
import { push } from "connected-react-router";

import { userManager } from "@tesseract/core";

import Logo from "components/Logo";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  width: 100%;
  position: fixed;
  background: ${(props) => {
    return props.theme.colors.primary.main;
  }};

  &:after {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: radial-gradient(transparent, rgba(0, 0, 0, 0.45));
    z-index: 0;
  }
`;

class AuthCallback extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      stuck: false,
    };
  }

  componentDidMount() {
    localStorage.removeItem("oldauth");
    this.stuckTimeout = setTimeout(() => {
      this.setState({ stuck: true });
    }, 3000);
  }

  componentWillUnmount() {
    clearTimeout(this.stuckTimeout);
  }

  errorCallback = (error) => {
    throw new Error(error);
  };

  // define a success callback which receives the signed in user & handles redirection
  successCallback = (user) => {
    // the user object gets the browser's URL before
    // redirection was triggered passed into its state
    // when triggerAuthFlow is set to `true`
    const urlBeforeRedirection = get(user, ["state", "redirectUrl"], "/");
    this.props.dispatch(push(urlBeforeRedirection));
  };

  render() {
    return (
      <CallbackComponent
        errorCallback={this.errorCallback}
        successCallback={this.successCallback}
        userManager={userManager}
      >
        <Wrapper>
          <Logo animate width={70} color="transparent" dotColor="contrast" />
          {this.state.stuck && <Redirect to="/" />}
        </Wrapper>
      </CallbackComponent>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
  };
}

export default compose(connect(null, mapDispatchToProps))(AuthCallback);
