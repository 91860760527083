import Alert from "@mui/material/Alert";

function AlertBannerEst() {
  return (
    <Alert
      severity="info"
      sx={{
        width: "70%",
        maxWidth: "38.25rem", // 612px, from UX mocks
        marginBottom: "2rem",
      }}
    >
      Analytics will now be reporting in Eastern Time Zone (EST) and complete
      days.
    </Alert>
  );
}

export { AlertBannerEst };
