import PropTypes from "prop-types";
import get from "lodash/get";
import { Field } from "formik";
import {
  DesktopTimePicker,
  LocalizationProvider,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import Box from "@mui/material/Box";
import renderOptionalTooltip from "../utils/renderOptionalTooltip";

function PersonalizationTimePicker({
  personalization,
  errors,
  setFieldTouched,
  setFormIsDirty,
  touched,
  values,
}) {
  const { description, title } = personalization;
  const handleChange = (time, form, field) => {
    if (time) {
      form.setFieldValue(field.name, new Date(time));
    } else {
      form.setFieldValue(field.name, "");
    }
  };
  return (
    <Box my={4}>
      <Field name={`${title}.value`}>
        {({ field, form }) => {
          return (
            <Box
              display="flex"
              flex="0 0 auto"
              style={{ height: "4em", alignItems: "center" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopTimePicker
                  error={
                    touched[title] &&
                    Boolean(get(errors, [title, "value"], undefined))
                  }
                  helperText={
                    touched[title] && get(errors, [title, "value"], undefined)
                  }
                  inputVariant="outlined"
                  label={title}
                  margin="normal"
                  onBlur={() => {
                    setFieldTouched(title);
                    setFormIsDirty(form.dirty);
                  }}
                  onChange={(time) => {
                    setFieldTouched(title);
                    handleChange(time, form, field);
                  }}
                  placeholder="Select a time"
                  value={
                    get(values, [`${title}`, "value"])
                      ? new Date(get(values, [title, "value"]))
                      : null
                  }
                  sx={{ width: "80%" }}
                />
              </LocalizationProvider>
              <Box ml={2} style={{ alignItems: "center" }}>
                {description && renderOptionalTooltip(description, title)}
              </Box>
            </Box>
          );
        }}
      </Field>
    </Box>
  );
}

PersonalizationTimePicker.propTypes = {
  personalization: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFormIsDirty: PropTypes.func.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
};

export default PersonalizationTimePicker;
