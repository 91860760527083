import useIsExtensionPopout from "./useIsExtensionPopout";

type ChromeWindow = Window & {
  chrome?: {
    runtime?: unknown;
  };
};

export const useIsChromeExtension = (): boolean => {
  // If Tesseract is not in a Iframe, just return false and dont chack anything else
  if (!useIsExtensionPopout()) return false;

   return (window as ChromeWindow).chrome?.runtime !== undefined;
};
