/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useEffect } from "react";

import { Alert, Box, Typography, Divider } from "@mui/material";

import { Formik, Form, FormikValues } from "formik";
import formatDuration from "date-fns/formatDuration";
import formatInTimeZone from "date-fns-tz/formatInTimeZone";

import { isValid as isValidDate } from "date-fns";
import { AddStepModalProps, TimeIncrements } from "./types";
import RelativeTimePicker from "./components/RelativeTimePicker";
import AbsoluteTimePicker from "./components/AbsoluteTimePicker";
import AutomaticMessageIndicator from "components/AutomaticMessageIndicator";
import { DialogFooter } from "components/DialogFooter";
import { DialogHeader } from "components/DialogHeader";
import MessageInputFormRow from "components/MessageInputFormRow";
import MessageQuality from "features/Campaigns/containers/SendCampaign/sharedComponents/MessageQuality";
import SavedRepliesOption from "components/MessageInputOptions/SavedRepliesOption";
import { SequenceStepValidationSchema } from "formHelpers/validationSchemas";
import { useAddStepModal } from "features/Sequences/hooks/useAddStepModal";
import { useTimeZones } from "hooks";
import { SCHEDULE_TYPES } from "features/Sequences/screens/IndividualSequenceOverview/constants";

function AddStepModal({
  close,
  finishedOnReply,
  scheduleType,
  sequenceSteps = [],
  sequenceStepIndex,
  stepCount,
}: AddStepModalProps) {
  const isScheduleAbsolute = scheduleType === SCHEDULE_TYPES.absolute;

  const sequenceStep =
    typeof sequenceStepIndex === "number"
      ? sequenceSteps[sequenceStepIndex]
      : null;

  const initialValues = {
    body: sequenceStep?.body || "",
    attachments: sequenceStep?.attachments || [],
    delay: isScheduleAbsolute
      ? null
      : sequenceStep?.relativeDelay || { days: 0, hours: 0, minutes: 5 },
    absoluteTime: sequenceStep?.absoluteTime
      ? new Date(sequenceStep?.absoluteTime)
      : undefined,
  };

  const {
    currentAccount,
    currentUser,
    isSignatureActive,
    maxCharacterLength,
    nextStepDate,
    previousStepDate,
    scheduleError,
    shortenedLink,
    stepDelayValues,

    getValueOptions,
    handleDelayChange,
    handleStepParams,
    setIsSignatureActive,
    setNextStepDate,
    setPreviousStepDate,
    setScheduleError,
    setShortenedLink,
  } = useAddStepModal(initialValues.delay);

  const { accountTimeZone } = useTimeZones();

  const isInitialStep = stepCount === 0 || sequenceStepIndex === 0;

  const copy = {
    cancel: "Cancel",
    header: `Step #${
      sequenceStepIndex === 0 || sequenceStepIndex
        ? sequenceStepIndex + 1
        : stepCount + 1
    } - ${
      sequenceStepIndex === 0 || stepCount === 0
        ? "Initial message"
        : "Follow-up message"
    }`,
    save: "Save",
    subhead: "Message Delay",
  } as const;

  useEffect(() => {
    let previousIndex = 0;
    let nextIndex = 1;

    // Current step is a new step.
    if (sequenceStepIndex === undefined) {
      previousIndex = stepCount - 1;
      nextIndex = stepCount + 1;
    }

    // Current step is a step the user is trying to edit.
    else {
      previousIndex = sequenceStepIndex - 1;
      nextIndex = sequenceStepIndex + 1;
    }

    const previousSequence = sequenceSteps[previousIndex];
    const nextSequence = sequenceSteps[nextIndex];

    setNextStepDate(
      nextSequence?.absoluteTime
        ? new Date(nextSequence.absoluteTime)
        : undefined,
    );

    setPreviousStepDate(
      previousSequence?.absoluteTime
        ? new Date(previousSequence.absoluteTime)
        : undefined,
    );
  }, [sequenceStepIndex, sequenceSteps]);

  const renderAlert = (values: FormikValues) => {
    const finishingClause = `${
      isInitialStep ? "after a contact is enrolled" : "after the previous step"
    }${finishedOnReply && !isInitialStep ? ", if no reply." : "."}`;
    const absoluteAlertText = (
      <div>
        Messages will automatically send on{" "}
        <Box component="span" sx={{ fontWeight: 700 }}>
          {values.absoluteTime
            ? (() => {
                const date = new Date(values.absoluteTime);
                if (!isValidDate(date)) return "[choose date]";

                return `${formatInTimeZone(
                  date,
                  accountTimeZone,
                  "eeee, MMMM d, yyyy",
                )} at ${formatInTimeZone(date, accountTimeZone, "h:mm a z")}`;
              })()
            : ""}
        </Box>{" "}
        {finishingClause}
      </div>
    );

    const relativeAlertText = (
      <div>
        Messages will automatically send{" "}
        <Box component="span" sx={{ fontWeight: 700 }}>
          {formatDuration(stepDelayValues, { delimiter: ", " })}
        </Box>{" "}
        {finishingClause}
      </div>
    );

    return (
      <Alert severity="info">
        {isScheduleAbsolute ? absoluteAlertText : relativeAlertText}
      </Alert>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      width={{ xs: "100%", sm: "600px", md: "905px", lg: "1040px" }}
    >
      <DialogHeader title={copy.header} onClose={close(false)} />
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => {
          const params = handleStepParams(values);
          close(params)();
        }}
        validationSchema={SequenceStepValidationSchema}
        validateOnMount={SequenceStepValidationSchema}
      >
        {({
          errors,
          isSubmitting,
          isValid,
          handleChange,
          setFieldValue,
          touched,
          values,
        }) => {
          const handleSelect = (
            event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            const strippedName: TimeIncrements = event.target.name.replace(
              "delay.",
              "",
            ) as TimeIncrements;
            handleChange(event);
            handleDelayChange(event, strippedName);
          };
          return (
            <Form id="textus-SequenceStepForm">
              <Box width="100%" padding="1rem">
                <MessageInputFormRow
                  attachmentField={values?.attachments}
                  attachmentFieldName="attachments"
                  currentAccount={currentAccount}
                  errors={errors}
                  fieldName="body"
                  isDisabled={false}
                  isSignatureActive={isSignatureActive}
                  label="Message"
                  maxChars={maxCharacterLength}
                  SavedRepliesOption={SavedRepliesOption}
                  setFieldValue={setFieldValue}
                  setShortenedLink={setShortenedLink}
                  shortenedLink={shortenedLink}
                  signature={currentUser.signature}
                  toggleSignature={() => {
                    return setIsSignatureActive(!isSignatureActive);
                  }}
                  touched={touched}
                  values={values}
                  withSignature
                />
                <AutomaticMessageIndicator />
                <MessageQuality message={values.body} />
              </Box>
              <Divider />
              <Box width="100%" padding="1rem">
                <Typography
                  fontWeight={700}
                  fontSize="14px"
                  marginTop="1rem"
                  color="primary"
                >
                  {copy.subhead}
                </Typography>
                {isScheduleAbsolute ? (
                  <AbsoluteTimePicker
                    existingDate={initialValues.absoluteTime}
                    isInitialStep={isInitialStep}
                    nextStepDate={nextStepDate}
                    previousStepDate={previousStepDate}
                    values={values}
                    setScheduleError={setScheduleError}
                    setFieldValue={setFieldValue}
                  />
                ) : (
                  <RelativeTimePicker
                    errors={errors}
                    touched={touched}
                    values={values}
                    getValueOptions={getValueOptions}
                    handleSelect={handleSelect}
                  />
                )}

                {/* ===== ALERT BANNER ===== */}
                {Boolean(values.absoluteTime) || !isScheduleAbsolute
                  ? renderAlert(values)
                  : null}
              </Box>

              <DialogFooter
                confirmText={copy.save}
                isConfirmDisabled={!isValid || isSubmitting || scheduleError}
                onCancel={close(false)}
              />
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
}
export { AddStepModal };
