import { createContext, useMemo } from "react";
import { useFindNumbers } from "hooks";

export type AppState = {
  findNumbers: ReturnType<typeof useFindNumbers>;
};

export const AppContext = createContext<AppState | undefined>(undefined);

export function AppProvider({ children }: any) {
  // ==== HOOKS ==== //
  const findNumbers = useFindNumbers();

  // ==== APP STATE ==== //
  const appStateValue = useMemo(() => {
    return {
      findNumbers,
    };
  }, [findNumbers]);

  // ==== PROVIDER ==== //
  return (
    <AppContext.Provider value={appStateValue}>{children}</AppContext.Provider>
  );
}
