import MetadataStrategy from "phoneExtraction/MetadataStrategy";
import { getParentsMatching } from "utils/getParents";
import { flattenRdfaNode } from "./getRdfaMetadata";
export default class RdfaStrategy extends MetadataStrategy {
  constructor({ parsedHtml, location }) {
    super({ name: "RdfaStrategy", location, parsedHtml });

    this.parsedHtml = parsedHtml;
    this.contacts = this.getContacts(parsedHtml);
  }

  getContacts = doc => {
    const people = doc.querySelectorAll(
      '[vocab="https://schema.org/"][typeof="Person"]',
    );

    if (people.length === 0) {
      return null;
    }

    const contacts = Array.from(people).map(person => {
      const nodesWithProp = person.querySelectorAll("[property]");

      // Filter out properties that are children of other properties,
      // those will be processed recursively
      const parentNodesWithProp = Array.from(nodesWithProp).filter(item => {
        const parents = getParentsMatching(doc, item, "[property]");
        return parents.length === 0;
      });

      const flattenedPerson = parentNodesWithProp.reduce(flattenRdfaNode, {});

      return flattenedPerson;
    });

    const sanitizedContacts = contacts.map(this.sanitizeProps);
    return sanitizedContacts;
  };
}
