import { getFirstDayOfMonth } from "./GetFirstDayOfMonth";

export const getFormattedMonthAndYear = (monthsToSubtract?: number) => {
  const formattedDate = getFirstDayOfMonth();
  if (monthsToSubtract)
    formattedDate.setMonth(formattedDate.getMonth() - monthsToSubtract);
  return formattedDate.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });
};
