import { AccountPlan, AccountPlanType } from "../models/AccountPlanModels";

// creates an array of license strings assigned to an account
// example output: ["keywords", "campaigns"]
// NOTE: does filter out message plans (line 10)
const getAccountLicenses = (accountId: string, accountPlans: AccountPlan[]) => {
  return accountPlans.flatMap((plan) => {
    return plan.assignments.reduce(
      (licenses: AccountPlanType[], assignment) => {
        const assignedAccount = assignment.account.replace("/accounts/", "");
        if (assignedAccount === accountId && plan.type !== "message") {
          licenses.push(plan.type);
        }
        return licenses;
      },
      [],
    );
  });
};

export default getAccountLicenses;
