import { identifyAccount } from "@tesseract/core";
import type { Account } from "@tesseract/core";
import { textUsApiV3 } from "services";

export const getOrCreateConversationByPhone = async (
  account: Account.Like,
  phoneNumber: string,
): Promise<Response> => {
  const accountId = identifyAccount(account);

  return textUsApiV3.get(
    `/${accountId}/conversations/${encodeURIComponent(phoneNumber)}`,
  );
};
