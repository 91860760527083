import {
  Autocomplete,
  CircularProgress,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { identify } from "@tesseract/core";
import { useUsers } from "../hooks/useUsers";
import {
  UsersAutocompleteProps,
  UsersAutocompleteOption,
  AutocompleteConfig,
} from "./types";
import Avatar from "components/Avatar";
import { systemUser } from "features/Settings";

/**
 * Returns the label for a user option in the UsersAutocomplete component.
 *
 * @param user - The user option.
 * @returns The label for the user option.
 */
function getOptionLabel(user: UsersAutocompleteOption) {
  return user ? `${user.name} (${user.email})` : "";
}

/**
 * Checks if two UsersAutocompleteOption objects are equal based on their id property.
 * @param a - The first UsersAutocompleteOption object.
 * @param b - The second UsersAutocompleteOption object.
 * @returns True if the id property of both objects are equal, false otherwise.
 */
function isOptionEqualToValue(
  a: UsersAutocompleteOption,
  b: UsersAutocompleteOption,
) {
  return `${identify(a)}` === `${identify(b)}`;
}

/**
 * Renders a custom Autocomplete component for selecting users.
 *
 * @template Multiple - A boolean or undefined value indicating whether multiple selections are allowed.
 * @template DisableClearable - A boolean or undefined value indicating whether the clearable option is disabled.
 *
 * @param {UsersAutocompleteProps<Multiple, DisableClearable>} props - The props for the UsersAutocomplete component.
 * @returns {JSX.Element} - The rendered UsersAutocomplete component.
 */
export function UsersAutocomplete<
  Multiple extends boolean | undefined = AutocompleteConfig["multiple"],
  DisableClearable extends
    | boolean
    | undefined = AutocompleteConfig["disableClearable"],
>({
  label,
  value,
  onChange,
  onBlur,
  usersQuery,
  required,
  loading,
  ...props
}: UsersAutocompleteProps<Multiple, DisableClearable>): JSX.Element {
  /**
   * Autocomplete open state.
   */
  const [open, setOpen] = useState(false);

  const { users, state, load, abort, reset } = useUsers(usersQuery);

  /**
   * Handles the opening of the autocomplete.
   * If the state is "waiting" or "error", it triggers the load function before opening.
   */
  const handleOpen = useCallback(() => {
    if (["waiting", "error"].includes(state)) {
      load();
    }

    setOpen(true);
  }, [state, load]);

  /**
   * Closes the autocomplete and aborts any pending requests.
   */
  const handleClose = useCallback(() => {
    abort?.();

    setOpen(false);
  }, [abort]);

  /**
   * Handles the change event of the text field.
   * @param event - The change event object.
   */
  const handleOnChange: TextFieldProps["onChange"] = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      load({
        q: event.currentTarget.value,
      });
    },
    [load],
  );

  /**
   * Represents the options for the UsersAutocomplete component.
   * @type {UsersAutocompleteOption[]} - The options for the UsersAutocomplete component.
   */
  const options: UsersAutocompleteOption[] = useMemo(() => {
    return Object.values(users ?? {});
  }, [users]);

  /**
   * Handles the change event for the UsersAutocomplete component.
   *
   * @param params - The parameters passed to the onChange callback.
   */
  const handleChange = useCallback(
    (...params: Parameters<NonNullable<typeof onChange>>) => {
      const [event, currentValue, ...onChangeArgs] = params;
      if (!currentValue) {
        load();
      }
      onChange?.(...params);
    },
    [load, onChange],
  );

  /**
   * Handles the blur event for the input field.
   * If the value is empty, it resets the input field.
   */
  const handleBlur = useCallback(
    (...onBlurParams: Parameters<NonNullable<typeof onBlur>>) => {
      if (!value) {
        reset();
      }
      onBlur?.(...onBlurParams);
    },
    [value, onBlur, reset],
  );

  /**
   * Renders the input component for the UsersAutocomplete component.
   * @returns The rendered input component.
   */
  const renderInput = useCallback(
    (
      renderInputParams: Parameters<
        NonNullable<
          UsersAutocompleteProps<Multiple, DisableClearable>["renderInput"]
        >
      >[0],
    ) => {
      return (
        <TextField
          required={required}
          label={label}
          onChange={handleOnChange}
          placeholder={systemUser.email}
          {...renderInputParams}
          helperText={
            state === "error"
              ? "Connection timed out, please try again later."
              : undefined
          }
          FormHelperTextProps={{
            error: state === "error",
          }}
          InputProps={{
            ...renderInputParams.InputProps,
            endAdornment: (
              <>
                {loading || state === "loading" ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {renderInputParams.InputProps.endAdornment}
              </>
            ),
          }}
        />
      );
    },
    [required, label, handleOnChange, state, loading],
  );

  /**
   * Renders an option for the UsersAutocomplete component.
   *
   * @returns The rendered option as a ListItem component.
   */
  const renderOption = useCallback(
    (
      ...params: Parameters<
        NonNullable<
          UsersAutocompleteProps<Multiple, DisableClearable>["renderOption"]
        >
      >
    ) => {
      const [optionProps, user] = params;

      return (
        <ListItemButton {...(optionProps as ListItemButtonProps)}>
          {user && (
            <>
              <ListItemIcon>
                <Avatar size={40} subject={user} />
              </ListItemIcon>
              <ListItemText primary={user.name} secondary={user.email} />
            </>
          )}
        </ListItemButton>
      );
    },
    [],
  );

  return (
    <Autocomplete
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={loading || ["loading", "waiting"].includes(state)}
      onBlur={handleBlur}
      onChange={handleChange}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open && state !== "error"}
      options={options}
      renderInput={renderInput}
      renderOption={renderOption}
      value={value}
      {...props}
    />
  );
}
