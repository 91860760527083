/*
The goal of this util is to append the any additional options that the user has selected
to the message body whther that it be form the inbox, campaign, message setting options, etc.
As of now, this is only being called in the inbox, but will be used in other places
as shortened links reaches more touch points in the application. 
*/
export const createMessageBody = (
  body: string,
  isSignatureActive: boolean,
  signatureContent: string | null,
  shortenedLink: {
    shortLink: string;
    fullLink: string;
  },
) => {
  if (!isSignatureActive && !shortenedLink.fullLink) {
    return body;
  }
  const bodyWithSignature =
    isSignatureActive && signatureContent
      ? `${body}\n\n${signatureContent}`
      : body;

  const bodyWithShortLink = shortenedLink.fullLink
    ? bodyWithSignature.replace(
        shortenedLink.shortLink,
        `{{ "${shortenedLink.fullLink}" | shortlink }}`,
      )
    : bodyWithSignature;
  return bodyWithShortLink;
};
