/* eslint-disable @typescript-eslint/require-await */
import { textUsApiV3} from "services";

export const authorizeIntegration = async (
  accountSlug: string,
  body: {
    provider: string;
    active: boolean;
    config: any;
  }
): Promise<Response> => {
  return textUsApiV3.post(`/${accountSlug}/integrations`, body);
};