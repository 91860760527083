import { all, takeLatest, put, call } from "redux-saga/effects";
import queryString from "query-string";
import { CLIENT_VERSION, POPOUT, TESSERACT } from "utils/constants";
import localStorageHelper from "utils/localStorageHelper";

// ACTION TYPES
const PERFORM_LOGIN_REQUEST = `/Authentication/containers/LoginScreen/PERFORM_LOGIN_REQUEST`;
const PERFORM_LOGIN_FAILURE = `/Authentication/containers/LoginScreen/PERFORM_LOGIN_FAILURE`;

const REQUEST_RESET_PASSWORD_LINK_REQUEST = `/Authentication/containers/LoginScreen/REQUEST_RESET_PASSWORD_LINK_REQUEST`;
const REQUEST_RESET_PASSWORD_LINK_FAILURE = `/Authentication/containers/LoginScreen/REQUEST_RESET_PASSWORD_LINK_FAILURE`;

// ACTION GENERATORS
const performLoginRequest = ({ email, password, errorCallback }) => {
  return {
    type: PERFORM_LOGIN_REQUEST,
    email,
    password,
    errorCallback,
  };
};

const requestResetPassword = ({ email, errorCallback }) => {
  return {
    type: REQUEST_RESET_PASSWORD_LINK_REQUEST,
    email,
    errorCallback,
  };
};

const actionGenerators = {
  performLoginRequest,
  requestResetPassword,
};

function* saga() {
  yield all([
    takeLatest(PERFORM_LOGIN_REQUEST, performLoginSaga),
    takeLatest(REQUEST_RESET_PASSWORD_LINK_REQUEST, requestResetPasswordSaga),
  ]);
}

const performRedirect = (response) => {
  const { search } = window.location;
  const { state } = queryString.parse(search);
  const oidcSession = localStorage.getItem(`oidc.${state}`);
  if (oidcSession) {
    const { redirectUrl } = JSON.parse(oidcSession).data;
    document.location = redirectUrl;
  } else {
    document.location = response.url;
  }
};

const getOptions = (params) => {
  const body = new URLSearchParams(params);
  const inPopout = window.self !== window.top;
  return {
    method: "POST",
    headers: {
      Accept: "text/html",
      "Content-Type": "application/x-www-form-urlencoded",
      "X-TextUs-Client": inPopout ? POPOUT : TESSERACT,
      "X-TextUs-Client-Version": CLIENT_VERSION,
    },
    body,
  };
};

function* performLoginSaga({ email, password, errorCallback }) {
  try {
    const provider = password ? "password" : "request_passwordless";
    const params = queryString.stringify(
      {
        email,
        password,
        provider,
      },
      { skipNull: true },
    );

    const url = `/auth/login`;
    const options = getOptions(params);

    const response = yield call(fetch, url, options);

    if (response.status < 400) {
      if (provider !== "request_passwordless") {
        localStorageHelper.setItem({ item: "failedLoginCount", value: 0 });
        performRedirect(response);
      }
    } else {
      const jsonResponse = yield call([response, "json"]);
      const error = new Error(jsonResponse.statusText);
      error.response = jsonResponse;
      throw error;
    }
  } catch (error) {
    errorCallback(error);
    yield put({
      type: PERFORM_LOGIN_FAILURE,
      error,
    });
  }
}

function* requestResetPasswordSaga({ email, errorCallback }) {
  try {
    const params = queryString.stringify({
      email,
    });
    const options = getOptions(params);

    const url = "/auth/reset_password";
    yield call(fetch, url, options);
  } catch (error) {
    errorCallback(error);
    yield put({
      type: REQUEST_RESET_PASSWORD_LINK_FAILURE,
      error,
    });
  }
}

export { actionGenerators, saga };
