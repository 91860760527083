import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import queryString from "query-string";
import { compose } from "redux";
import { withRouter } from "react-router-dom";

import debounce from "lodash/debounce";
import get from "lodash/get";
import identity from "lodash/identity";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FilterListIcon from "@mui/icons-material/FilterList";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import AccountFilterPopper from "./AccountFilterPopper";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import Avatar from "components/Avatar";
import TopLevelPageSearch from "components/TopLevelPage/Search";
import TopLevelPageSearchBar from "components/TopLevelPage/SearchBar";
import fixedDecodeURIComponent from "utils/fixedDecodeURIComponent";

const Filters = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 0 0 auto;
  padding: 0;
  border-left: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  overflow: hidden;
`;

const AccountFilterButton = styled(Button)`
  padding: 0 55px 0 25px !important;
  height: 100%;
  border-radius: 0;

  p {
    text-transform: none;
  }
`;

const CloseIconButton = styled(IconButton)`
  height: 26px;
  width: 26px;
  border: ${(props) => {
    return props.theme.mixins.border({
      color: props.theme.colors.divider,
    });
  }} !important;
  ${(props) => {
    return props.theme.mixins.flexCenter;
  }};
  z-index: 9;
  position: relative;

  svg {
    font-size: 14px;
    font-weight: 700;
  }
`;

const AccountFilterWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
`;

class UserCollectionSearch extends Component {
  debounceSearch = debounce((value) => {
    this.handleSearch(value);
  }, 1000);

  static propTypes = {
    activeAccount: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  handleChange = (value) => {
    this.debounceSearch(value);
  };

  getParamsFromURL = () => {
    return queryString.parse(get(this.props, ["location", "search"]), {
      arrayFormat: "bracket",
    });
  };

  getUpdatedSearchParams = (updates = {}) => {
    const currentParams = this.getParamsFromURL();

    return queryString.stringify(
      pickBy(
        {
          ...currentParams,
          page: undefined,
          cursor: undefined,
          ...updates,
        },
        identity,
      ),
      { arrayFormat: "bracket" },
    );
  };

  updateSearch = (search) => {
    this.props.history.push({
      search,
    });
  };

  handleSearch = (searchTerm) => {
    const search = this.getUpdatedSearchParams({
      q: fixedEncodeURIComponent(searchTerm),
    });

    this.updateSearch(search);
  };

  handleAccountSelect = (account) => {
    const search = this.getUpdatedSearchParams({
      accounts: [get(account, "id")],
    });

    this.setState({ anchorEl: null });
    this.updateSearch(search);
  };

  clearAccountSelect = () => {
    this.handleAccountSelect(null);
    this.setState({ anchorEl: null });
  };

  clearSearch = () => {
    this.debounceSearch.cancel();
    this.handleSearch("");
  };

  render() {
    const { activeAccount } = this.props;
    const { anchorEl } = this.state;

    const showActiveAccount = !isEmpty(activeAccount);

    const searchParams = this.getParamsFromURL();
    const displayedSearchTerm = fixedDecodeURIComponent(
      get(searchParams, "q", ""),
    );

    return (
      <TopLevelPageSearchBar>
        <TopLevelPageSearch
          clearHandler={this.clearSearch}
          initialSearchTerm={displayedSearchTerm}
          placeholder="Search by name or email"
          searchHandler={this.debounceSearch}
        />
        <Filters aria-label="filters">
          <ClickAwayListener
            onClickAway={() => {
              return this.setState({ anchorEl: null });
            }}
          >
            <AccountFilterWrapper>
              <AccountFilterButton
                aria-label="Filter Account"
                color="primary"
                onClick={({ currentTarget }) => {
                  return this.setState({ anchorEl: currentTarget });
                }}
              >
                {showActiveAccount && (
                  <Box marginRight="10px">
                    <Avatar subject={activeAccount} size={24} fontSize="15px" />
                  </Box>
                )}
                <p>
                  {showActiveAccount ? activeAccount.name : "Filter by Account"}
                </p>
              </AccountFilterButton>
              <Box position="absolute" top="50%" right="17px" mt="-14px">
                {showActiveAccount ? (
                  <Tooltip title="Clear Account Filter">
                    <CloseIconButton onClick={this.clearAccountSelect}>
                      <CloseIcon />
                    </CloseIconButton>
                  </Tooltip>
                ) : (
                  <FilterListIcon />
                )}
              </Box>
            </AccountFilterWrapper>
          </ClickAwayListener>
          {anchorEl && (
            <AccountFilterPopper
              anchorEl={anchorEl}
              handleClick={(accountTree) => {
                return this.handleAccountSelect(accountTree);
              }}
              selected={get(searchParams, "accounts", [])}
            />
          )}
        </Filters>
      </TopLevelPageSearchBar>
    );
  }
}

export default compose(withRouter)(UserCollectionSearch);
