import { styled } from "@mui/material/styles";
import { Dropzone } from "components/Dropzone";

export const StyledDropzone = styled(Dropzone)(({ theme }) => {
  return {
    ".dropzone-wrapper": {
      borderWidth: "1px",
      borderRadius: "4px",
      display: "flex",
      gap: "8px",
      padding: "24px 10.5px",
      transition: "all 0.3s",

      "&:hover": {
        backgroundColor: "rgba(40, 60, 89, 0.12)",
        borderColor: theme.palette.primary.main,
        borderWidth: "1px",
      },

      "&.error": {
        backgroundColor: "rgba(194, 30, 30, 0.08)",
        borderColor: theme.palette.error.main,
        borderWidth: "1px",
      },
    },
  };
});
