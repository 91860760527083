import { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import { compose } from "redux";

import withSizes from "react-sizes";

import { useSnackbar } from "notistack";
import ChallengeModal from "./ChallengeModal";
import ReportHeader from "./ReportHeader";
import ReportTable from "./ReportTable";
import ScheduleAnalyticsReportModal from "./ScheduleAnalyticsReportModal";
import withRecord from "higherOrderComponents/withRecord";
import breakpoints from "utils/styles/breakpoints";

const initialValues = {
  account: "",
  descendants: "",
  sendFrequency: "",
  dayToSend: "",
  timePeriod: "",
  reports: "",
  reportType: "",
};

function ScheduledReports({
  accountList,
  currentUser,
  reports,
  fullScreen,
  updateScheduleExportsRequest,
}) {
  const [deletedReport, setDeletedReport] = useState({});
  const [openScheduleModal, setOpenScheduleModal] = useState(false);
  const [openChallengeModal, setOpenChallengeModal] = useState(false);
  const [existingValues, setExistingValues] = useState(initialValues);

  const { enqueueSnackbar } = useSnackbar();

  const descendantsList = {
    billing: [
      {
        descendants: true,
        id: "descendants",
        name: "On this account and below",
        slug: "currentAccountAndDescendants",
      },
    ],
    overview: [
      {
        descendants: false,
        id: "current account",
        name: "On this account",
        slug: "currentAccount",
      },
      {
        descendants: true,
        id: "descendants",
        name: "On this account and below",
        slug: "currentAccountAndDescendants",
      },
    ],
  };

  const getNotificationMessage = ({ reportType, title }) => {
    const notificationMessage = {
      sendReportError: `Something went wrong trying to export ${title} Report.`,
      scheduleSuccess: `${title} Report successfully scheduled!`,
      scheduleError: `Something went wrong trying to schedule ${title} Report.`,
      updateScheduleSuccess: `${title} Report successfully edited!`,
      updateScheduleError: `Something went wrong trying to edit ${title} Report.`,
      deleteScheduleSuccess: `${title} Report successfully deleted!`,
      deleteScheduleError: `Something went wrong trying to delete ${title} Report.`,
    };
    return notificationMessage[reportType];
  };

  const analyticsReports = get(
    currentUser,
    ["settings", "analyticsReports"],
    [],
  );

  const closeEditModal = () => {
    handleScheduleModal();
    setExistingValues(initialValues);
  };

  const handleScheduleModal = () => {
    setOpenScheduleModal(!openScheduleModal);
  };

  const handleChallengeModal = () => {
    setOpenChallengeModal(!openChallengeModal);
  };

  const errorCallback = ({ reportType, title }) => {
    enqueueSnackbar(getNotificationMessage({ reportType, title }), {
      variant: "error",
    });
  };

  const successCallback = ({ reportType, title }) => {
    enqueueSnackbar(getNotificationMessage({ reportType, title }), {
      variant: "info",
    });
  };

  const getDescendantsParam = (descendantsParam) => {
    return (
      descendantsList.overview.find(({ slug, descendants }) => {
        if (typeof descendantsParam === "string")
          return descendantsParam === slug;
        return descendantsParam === descendants;
      }) ?? {}
    );
  };

  const scheduleReport = ({
    account,
    descendants: descendantsParam,
    sendFrequency,
    dayToSend,
    timePeriod,
    reports: report,
  }) => {
    const { descendants = true } = getDescendantsParam(descendantsParam);
    const requestBody = {
      name: currentUser.name,
      email: currentUser.email,
      settings: {
        analyticsReports: analyticsReports.concat({
          account,
          descendants,
          schedule: `${sendFrequency}${dayToSend}`,
          timePeriod,
          reports: [report],
        }),
      },
    };
    const title = startCase(report);
    updateScheduleExportsRequest(currentUser.id, requestBody, {
      successCallback: () => {
        return successCallback({ reportType: "scheduleSuccess", title });
      },
      errorCallback: () => {
        return errorCallback({ reportType: "sendReportError", title });
      },
    });
    handleScheduleModal();
  };

  const editReport = ({
    account,
    descendants: descendantsParam = true,
    reports: report,
    schedule,
    timePeriod,
  }) => {
    const { slug = "currentAccountAndDescendants" } =
      getDescendantsParam(descendantsParam);

    const [, sendFrequency, dayToSend] = schedule.match(/(.*)(BY.*)/);

    /* 
      The below logic is imperfect if other types of billing reports are added in the future, but as of now
      there's no way to track reportType on the backend. This solution allows the frontend to prepopulate fields 
      when editing an existing scheduled report.
    */
    const reportType = report.includes("message_usage")
      ? "billing"
      : "overview";

    setExistingValues({
      account,
      dayToSend,
      descendants: slug,
      reportType,
      reports: report,
      sendFrequency,
      timePeriod,
    });
    handleScheduleModal();
  };

  const filterReports = () => {
    return analyticsReports.filter(
      ({
        account: id,
        descendants: reportDescendants,
        reports: [report],
        schedule: reportSchedule,
        timePeriod: reportTimePeriod,
      }) => {
        const {
          account,
          dayToSend,
          descendants,
          reports: existingReports,
          sendFrequency,
          timePeriod,
        } = existingValues;
        const { slug = "currentAccountAndDescendants" } =
          getDescendantsParam(reportDescendants);
        const correspondingAccount = id !== account;
        const correspondingDescendants = slug !== descendants;
        const correspondingReport = report !== existingReports[0];
        const correspondingTimePeriod = timePeriod !== reportTimePeriod;
        const existingSchedule = `${sendFrequency}${dayToSend}`;
        const schedule = existingSchedule !== reportSchedule;
        return (
          correspondingAccount ||
          correspondingDescendants ||
          correspondingReport ||
          correspondingTimePeriod ||
          schedule
        );
      },
    );
  };

  const confirmDeletion = (report) => {
    setDeletedReport(report);
    handleChallengeModal();
  };

  const deleteReport = () => {
    const deletedReportIndex = analyticsReports.findIndex((report) => {
      return isEqual(report, deletedReport);
    });
    const unmodifedReports = analyticsReports
      .slice(0, deletedReportIndex)
      .concat(
        analyticsReports.slice(deletedReportIndex + 1, analyticsReports.length),
      );

    const requestBody = {
      name: currentUser.name,
      email: currentUser.email,
      settings: {
        analyticsReports: unmodifedReports,
      },
    };
    const title = startCase(deletedReport.reports[0]);
    updateScheduleExportsRequest(currentUser.id, requestBody, {
      successCallback: () => {
        return successCallback({
          reportType: "deleteScheduleSuccess",
          title,
        });
      },
      errorCallback: () => {
        return errorCallback({
          reportType: "deleteScheduleError",
          title,
        });
      },
    });
    setDeletedReport({});
    handleChallengeModal();
  };

  const updateReport = (updatedValues) => {
    if (!isEqual(updatedValues, existingValues)) {
      const {
        account,
        descendants: descendantsParam,
        sendFrequency,
        dayToSend,
        timePeriod,
        reports: report,
      } = updatedValues;
      const { descendants = true } = getDescendantsParam(descendantsParam);
      const unmodifedReports = filterReports();
      const requestBody = {
        name: currentUser.name,
        email: currentUser.email,
        settings: {
          analyticsReports: unmodifedReports.concat({
            account,
            descendants,
            schedule: `${sendFrequency}${dayToSend}`,
            timePeriod,
            reports: Array.isArray(report) ? report : [report],
          }),
        },
      };
      const title = startCase(report);
      updateScheduleExportsRequest(currentUser.id, requestBody, {
        successCallback: () => {
          return successCallback({
            reportType: "updateScheduleSuccess",
            title,
          });
        },
        errorCallback: () => {
          return errorCallback({
            reportType: "updateScheduleError",
            title,
          });
        },
      });
      setExistingValues(initialValues);
      handleScheduleModal();
    }
  };
  return (
    <>
      <ReportHeader openScheduleModal={handleScheduleModal} />
      <ChallengeModal
        deleteReport={deleteReport}
        fullScreen={fullScreen}
        handleClose={handleChallengeModal}
        open={openChallengeModal}
      />
      <ScheduleAnalyticsReportModal
        accountList={accountList}
        descendantsList={descendantsList}
        fullScreen={fullScreen}
        handleClose={closeEditModal}
        initialValues={existingValues}
        open={openScheduleModal}
        reports={reports}
        scheduleReport={scheduleReport}
        updateReport={updateReport}
      />
      <ReportTable
        accountList={accountList}
        confirmDeletion={confirmDeletion}
        editReport={editReport}
        reports={analyticsReports}
        setOpenChallengeModal={setOpenChallengeModal}
      />
    </>
  );
}

ScheduledReports.propTypes = {
  accountList: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  reports: PropTypes.array.isRequired,
  updateScheduleExportsRequest: PropTypes.func.isRequired,
};

export default compose(
  withRecord({
    actions: ["update"],
    container: "features/Profile/ScheduledReports/schedule",
    type: "scheduleExports",
  }),
  withSizes(({ width }) => {
    return { fullScreen: width < breakpoints.medium };
  }),
)(ScheduledReports);
