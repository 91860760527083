import { useDispatch } from "react-redux";
import getState from "higherOrderComponents/withRecord/state";

export const useAccountSettingsActions = () => {
  const dispatch = useDispatch();

  const {
    actionGenerators: { updateAccountRequest },
  } = getState({
    actions: ["update"],
    container: "features/AccountSettings/containers/Account",
    multiple: false,
    shape: {},
    type: "account",
  });

  return {
    updateAccountRequest: (...args) => {
      return dispatch(updateAccountRequest(...args));
    },
  };
};
