import { useMediaQuery, Theme } from "@mui/material";
import RecipientsExclusionSection from "../../../sharedComponents/RecipientsExclusionSection";
import Groups from "../Groups";
import { CampaignHeader } from "../CampaignHeader";
import { StepSection } from "./StepSection";
import { SelectGroupProps } from "./types";

export function SelectGroup({
  exclusionTimeInput,
  handleExclusionTimeChange,
  setGroup,
  setCampaignRecipients,
  currentAccount,
}: SelectGroupProps) {
  // ==== HOOKS ==== //
  const mobileView = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // ==== RENDER ===== //
  return (
    <StepSection>
      <CampaignHeader
        title="Select group"
        subtitle="Choose one group to receive the campaign."
      />

      <RecipientsExclusionSection
        exclusionTimeInput={exclusionTimeInput}
        handleExclusionTimeChange={handleExclusionTimeChange}
      />

      <Groups
        groupFilterCollectionId={
          currentAccount.groupFilters || `/${currentAccount.slug}/group_filters`
        }
        currentAccount={currentAccount}
        setGroup={setGroup}
        setGroupRecipients={setCampaignRecipients}
        mobileView={mobileView}
      />
    </StepSection>
  );
}
