/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { fetchSubscriptions } from "../api/fetchSubscriptions";
import { useCurrentAccount } from "hooks";
import { AUTOMATION_PATHS } from "features/Automation/constants/AutomationConstants";
import { AccountPlan } from "features/AccountSettings/components/AccountConfiguration/models/AccountPlanModels";

const useAutomationNavLinks = () => {
  const currentAccount = useCurrentAccount();
  const { keywordsMain, sequences } = AUTOMATION_PATHS;

  const [keywordsEnabled, setKeywordsEnabled] = useState<boolean>(false);
  const [sequencesEnabled, setSequencesEnabled] = useState<boolean>(false);
  const [initialPage, setInitialPage] = useState<string>();

  useEffect(() => {
    const fetchSubs = async () => {
      try {
        const response = await fetchSubscriptions(currentAccount);
        const subscriptions = await response.json();

        // Account has a keywords license.
        const canAccessKeywords = Boolean(
          subscriptions.find((subscription: AccountPlan) => {
            return subscription.type === "keywords";
          }),
        );

        setKeywordsEnabled(canAccessKeywords);

        // Account has a sequences license.
        const canAccessSequences = Boolean(
          subscriptions.find((subscription: AccountPlan) => {
            return subscription.type === "sequences";
          }),
        );

        setSequencesEnabled(canAccessSequences);

        if (canAccessKeywords) {
          setInitialPage(keywordsMain);
        } else {
          setInitialPage(sequences);
        }
      } catch (err) {
        throw new Error(`Error fetching Subscriptions: ${err}`);
      }
    };

    fetchSubs();
  }, []);

  return {
    initialPage,
    keywordsEnabled,
    sequencesEnabled,
  };
};

export { useAutomationNavLinks };
