import { compose } from "redux";
import { withRouter } from "react-router-dom";
import withSizes from "react-sizes";

import Root from "components/Table/components/Root";

/**
 * @deprecated
 * - Replace with MUI DataGridPro or TextUsTable
 */
export default compose(
  withSizes(({ width }) => {
    return {
      showExpandButton: width < 600,
    };
  }),
  withRouter,
)(Root);
