export default {
  borderRadius: "4px",
  borderWidth: "1",
  tileBorderRadius: "6px",
  uploadZoneBorderRadius: "20px",
  modalBorderRadius: "6px",
  bubbleBorderRadius: "14px",
  lineHeight: "1.5",
  transitionProperty: "all",
};
