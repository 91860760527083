import { OutlinedInput, Select } from "@mui/material";
import { SequencesContactFilterInputProps } from "./types";

function SequencesContactFilterInput({
  checkedItems,
  displayValue,
  field,
  handleChange,
  menuItems,
}: SequencesContactFilterInputProps) {
  const handleRenderValue = (selected: string[]) => {
    if (selected.length === 0) return field;
    return selected.join(", ");
  };

  return (
    <Select
      multiple
      value={displayValue}
      onChange={handleChange}
      displayEmpty
      input={<OutlinedInput size="small" />}
      renderValue={handleRenderValue}
      sx={({ palette }) => {
        return {
          width: "12.5rem",
          color:
            checkedItems.length > 0
              ? palette.text.primary
              : palette.text.disabled,
        };
      }}
    >
      {menuItems}
    </Select>
  );
}

export { SequencesContactFilterInput };
