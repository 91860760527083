import { Account } from "models/Account";
import { SequenceStep } from "models/SequenceStep";
import { deleteStep } from "features/Sequences/api";

export const useDeleteStepService = (currentAccount: Account) => {
  const handleDeleteStep = async (
    sequenceStep: SequenceStep,
    startLoadingSteps: () => void,
    stopLoadingSteps: () => void,
  ) => {
    try {
      startLoadingSteps();
      await deleteStep(currentAccount, sequenceStep.id);
    } catch (err) {
      throw new Error(`Error deleting sequence step: ${err}`);
    } finally {
      stopLoadingSteps();
    }
  };

  return {
    handleDeleteStep,
  };
};
