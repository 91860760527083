import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";
import getNamesFromText from "phoneExtraction/utils/getNamesFromText";

export default class BlueSkyStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /bluesky/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: ["input#txtFirstName", "input#txtLastName"],
      namesSelectorType: "value",
      textSelector: "select#lsbNumber option",
      canParse: () =>
        !!$(doc).find("form[action='./ContractorGeneral.aspx']")[0],
    });
    const notesParser = new SingleContactParser({
      doc,
      getNamesFunction: () =>
        doc
          .querySelector("#tdSINfTopRowName")
          .innerHTML.split("<br>")
          .map(row => getNamesFromText(row.replace(/\(.*\)/g, "")))[0],
      searchTextFunction: () =>
        doc
          .querySelector("#tdSINfPhone")
          .innerHTML.split("<br>")
          .map(n => n.trim())
          .join(", "),
      canParse: () => !!$(doc).find("form[action^='./ContractorNote.aspx']")[0],
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "table.grid td a[data-menu-parent-name='Caregiver']",
      containerSelector: "tr",
      canParse: () => !!$(doc).find("form[action='./Contractors.aspx']")[0],
    });
    return [detailPageParser, notesParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "BlueSkyStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "BlueSkyStrategy");
      return null;
    }
  };
}
