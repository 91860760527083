/* global pendo */
import { Account } from "models/Account";
import { User } from "models/User";

export const initializeTrackers = (
  currentUser: User,
  currentAccount: Account,
) => {
  if (!currentUser || !currentAccount) return;

  const getLocationType = () => {
    return window !== window.top ? "Pop-Out" : "Web App";
  };

  const { email, firstName, id, lastName, topLevelAccounts } =
    currentUser ?? {};
  const { name, slug } = currentAccount ?? {};

  const isUserOrgAdmin = () => {
    const orgAccount = currentUser.memberships.members.find((account) => {
      return account.account.type === "organization";
    });
    return orgAccount ? orgAccount.role === "admin" : false;
  };

  // Pendo's init
  pendo.initialize({
    visitor: {
      id,
      email,
      full_name: `${firstName} ${lastName}`,
      orgAdmin: isUserOrgAdmin(),
      platform: getLocationType(),
    },
    account: {
      id: slug,
      primaryAccount: topLevelAccounts?.[0]?.name,
      currentAccountName: name,
    },
  });
};
