import {
  Skeleton,
  Alert,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip,
  FormHelperText,
  Stack,
  styled,
} from "@mui/material";
import { Formik } from "formik";
import { useId } from "react";
import type {
  DuplicateRulesFormProps,
  DuplicateRulesFormValues,
  DuplicateRulesFieldsProps,
} from "./types";

const List = styled("ul")({
  margin: 0,
});

/**
 * Renders formik wrapper
 */
export function Form(props: DuplicateRulesFormProps) {
  return <Formik<DuplicateRulesFormValues> {...props} />;
}

function MatchDuplicatesBasedOn() {
  const id = useId();

  return (
    <FormControl>
      <Typography id={id} variant="h6">
        Match duplicates based on:
      </Typography>
      <RadioGroup
        sx={{
          paddingLeft: "9px",
        }}
        aria-labelledby={id}
        defaultValue="primary"
        name="match-duplicates-based-on"
      >
        <FormControlLabel
          value="primary"
          control={<Radio color="secondary" />}
          label={
            <>
              Primary phone number
              <Chip sx={{ marginLeft: 1 }} label="Default" size="small" />
            </>
          }
        />
        <FormControlLabel
          value="both"
          control={<Radio color="secondary" />}
          label="Both primary phone number and exact name"
        />
      </RadioGroup>
    </FormControl>
  );
}

function Overwrite() {
  const id = useId();
  return (
    <FormControl>
      <Typography id={id} variant="h6">
        When duplicate contacts are found in the import:
      </Typography>
      <RadioGroup
        sx={{
          paddingLeft: "9px",
        }}
        aria-labelledby={id}
        defaultValue="true"
        name="overwrite"
      >
        <FormControlLabel
          value="true"
          control={<Radio color="secondary" />}
          label={
            <>
              <b>Overwrite</b> existing contact data.{" "}
              <Chip sx={{ marginLeft: 1 }} label="Default" size="small" />
            </>
          }
        />
        <FormHelperText>
          <List>
            <li>Imported data will replace existing data</li>
            <li>Imported blank fields will erase existing data</li>
            <li>Existing opted-out statuses will never be overwritten</li>
          </List>
        </FormHelperText>
        <FormControlLabel
          value="false"
          control={<Radio color="secondary" />}
          label={
            <>
              <b>Do not overwrite</b> existing contact data.
            </>
          }
          sx={{
            marginTop: 1,
          }}
        />
        <FormHelperText>
          <List>
            <li>No updates will be made to the existing contact</li>
            <li>Existing contacts will be included on the list instead</li>
          </List>
        </FormHelperText>
      </RadioGroup>
    </FormControl>
  );
}

/**
 * Renders a component for managing fields in the AutomatedSender feature of the Settings page.
 */
export function Fields(_: DuplicateRulesFieldsProps) {
  return (
    <Stack gap={2}>
      <MatchDuplicatesBasedOn />
      <Overwrite />
    </Stack>
  );
}

export function Loading() {
  return <Skeleton variant="rectangular" height={56} />;
}

export function Error() {
  return <Alert severity="error">Failed to load automated sender</Alert>;
}
