import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useCallback, useState } from "react";
import { useBullhornCandidates } from "../hooks/useBullhornCandidates";
import { Props } from "./types";

export function BullhornCandidateAutocomplete({
  account,
  contact,
  ...props
}: Props) {
  const [open, setOpen] = useState(false);

  const { candidates, state, load, abort } = useBullhornCandidates(
    account,
    contact,
  );

  const handleOpen = useCallback(() => {
    if (["waiting", "error"].includes(state)) {
      load();
    }

    setOpen(true);
  }, [state, load]);

  const handleClose = useCallback(() => {
    abort?.();

    setOpen(false);
  }, [abort]);

  return (
    <Autocomplete
      disableClearable
      getOptionLabel={({ id, name }) => {
        return `${id} - ${name}`;
      }}
      isOptionEqualToValue={(a, b) => {
        return a.id === b.id;
      }}
      loading={state === "loading"}
      onClose={handleClose}
      onOpen={handleOpen}
      open={open && !(state === "error")}
      options={Object.values(candidates ?? {})}
      renderInput={(renderInputParams) => {
        return (
          <TextField
            {...renderInputParams}
            label="Bullhorn ID"
            helperText={
              state === "error"
                ? "Bullhorn connection timed out, please try again later."
                : undefined
            }
            FormHelperTextProps={{
              error: state === "error",
            }}
            InputProps={{
              ...renderInputParams.InputProps,
              endAdornment: (
                <>
                  {state === "loading" ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {renderInputParams.InputProps.endAdornment}
                </>
              ),
            }}
          />
        );
      }}
      {...props}
    />
  );
}
