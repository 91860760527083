import { useState, useEffect } from "react";
import styled from "styled-components";
import get from "lodash/get";

import {
  ClickAwayListener,
  Dialog,
  DialogProps,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { Props } from "./types";
import { dialogIds } from "./constants";
import { contactDialogs } from "./contactDialogs";
import ModalContent from "components/ModalContent";


/**
 * `<ContactModal />` is a modal that is used to create, edit, and view contacts
 */
export function ContactModal(props: Props) {
  /**
   * Active view is used to determine which dialog to show
   *
   * If activeView is undefined, then the modal will determine which dialog to show
   * based on the state of the contactModalContainer
   *
   * @todo remove `contactModalContainer` and use activeView to determine which dialog to show
   */
  const [activeView, setActiveView] = useState<
    (typeof dialogIds)[keyof typeof dialogIds] | undefined
  >(undefined);

  /**
   * Clear the modal state after the modal closes
   */
  const [clearModal, setClearModal] = useState<
    ReturnType<typeof setTimeout> | undefined
  >(undefined);

  const matTheme = useTheme();

  /**
   * Clear the timeout when the component unmounts
   */
  useEffect(() => {
    return () => {
      clearTimeout(clearModal);
    };
  }, [clearModal]);

  /**
   * Determine which dialog to show based on the state of the contactModalContainer
   *
   * @todo remove `contactModalContainer` and use activeView to determine which dialog to show
   */
  const getViewType = (): keyof typeof dialogIds | undefined => {
    const {
      contactModalContainer: {
        substate: { form, contact, partialMatches, errorCreating },
      },
    } = props;

    if (activeView) {
      return activeView;
    }

    if (
      (form && partialMatches) ||
      (errorCreating && errorCreating?.response?.totalItems > 0)
    ) {
      return dialogIds.existing;
    }
    if (get(contact, ["id"]) && form) {
      return dialogIds.edit;
    }
    if (get(contact, ["id"])) {
      return dialogIds.show;
    }
    if (form) {
      return dialogIds.create;
    }

    return undefined;
  };

  /**
   * Close the modal and clear the modal state
   */
  const handleClose = () => {
    setActiveView(undefined);
    props.setContactModal({
      active: false,
    });
    setClearModal(
      setTimeout(() => {
        props.setContactModal({
          activeConversation: undefined,
          contact: undefined,
          conversationPhoneNumber: undefined,
          expanded: undefined,
          form: false,
          partialMatches: undefined,
        });
      }, 1000),
    );
  };
  /**
   * Cancel the edit and clear the modal state
   */
  const cancelEdit = () => {
    setActiveView(undefined);
    props.setContactModal({
      form: false,
    });
  };

  /**
   * Determine which dialog to show
   */
  const viewType = getViewType();
  if (!viewType) {
    return null;
  }

  const {
    fullScreen,
    setContactModal,
    contactModalContainer: {
      substate: { active, activeConversation, contact, expanded },
    },
  } = props;


  /**
   * The active dialog to component to render
   */
  const ActiveDialog = contactDialogs[viewType];

  /**
   * Common props to pass to every dialog component
   */
  const dialogProps = {
    open: active,
    fullScreen,
    fullWidth: true,
    maxWidth: "md",
  } as const satisfies DialogProps;

  /**
   * Conditional props to pass to some dialog components
   */
  const dialogsPropCollection: Partial<
    Record<keyof typeof contactDialogs, Omit<DialogProps, "open">>
  > = {
    confirmDelete: {
      maxWidth: "sm",
      fullWidth: false,
      fullScreen: false,
    },
    confirmUnlink: {
      maxWidth: "sm",
      fullWidth: false,
      fullScreen: false,
    },
  };

  /**
   * Conditional sx styles to pass to some dialog components
   */
  const dialogsStyleCollection: Partial<
    Record<keyof typeof contactDialogs, (theme: Theme) => SxProps<Theme>>
  > = {
    create(theme: Theme) {
      return {
        [theme.breakpoints.down("sm")]: {
          top: "54px",

          "& .MuiBackdrop-root": {
            display: "none",
          },
        },
      };
    },
    edit(theme: Theme) {
      return {
        [theme.breakpoints.down("sm")]: {
          top: "54px",

          "& .MuiBackdrop-root": {
            display: "none",
          },
        },
      };
    },
    show(theme: Theme) {
      return {
        [theme.breakpoints.down("sm")]: {
          top: "54px",

          "& .MuiBackdrop-root": {
            display: "none",
          },
        },
      };
    },
  };

  const styles = dialogsStyleCollection[viewType]
    ? dialogsStyleCollection[viewType](matTheme)
    : {};

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Dialog
        data-testid={`contact-dialog-${viewType}`}
        {...dialogProps}
        {...dialogsPropCollection[viewType]}
        sx={(theme) => {
          return {
            ...styles,
          };
        }}
      >
        <ActiveDialog
          {...props}
          {...{
            setView: setActiveView,
            viewType,
            cancelEdit,
            handleClose,
            setContactModal,
          }}
        />
      </Dialog>
    </ClickAwayListener>
  );
}
