import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { actionGenerators } from "./state";
import { selectAccountNavbarElements } from "features/EntryPoint/containers/App/selectors";
import Logo from "components/Logo";
import Loader from "components/Loader";

function AnalyticsAccountPicker({
  accountNavbarElements,
  history,
  setCurrentAccount,
}) {
  const [account, setAccount] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const accounts = accountNavbarElements.map(
      ({ account: slug, title, uiLink }) => {
        return {
          id: uiLink,
          title,
          slug,
        };
      },
    );
    setAccountList(accounts);
    setLoading(false);
  }, []);

  const selectAccount = ({ value }) => {
    const { id, account: selectedAccount } = value ?? {};
    const analyticsRoute = `${id}/analytics`;
    setAccount(value);
    setCurrentAccount(selectedAccount);
    history.push(analyticsRoute);
  };

  if (loading) {
    return (
      <Loader isLoading={loading} aria-label="Page is Loading">
        <Logo animate dotColor="contrast" />
      </Loader>
    );
  }

  return (
    <Box display="flex" height="100vh" justifyContent="center">
      <Box aria-label="Analytics Account Selector" maxWidth="400px" mt={20}>
        <Typography
          align="center"
          component="h2"
          style={{ fontSize: 40, marginBottom: 20 }}
          variant="h2"
        >
          Select an Account
        </Typography>
        <Typography align="center" component="p" style={{ fontSize: 16 }}>
          Choose an account to view their analytics.
        </Typography>
        {accountList.length && (
          <Box width="100%" mt={10}>
            <TextField
              fullWidth
              label="Account"
              onChange={({ target }) => {
                return selectAccount(target);
              }}
              select
              value={account}
              variant="outlined"
            >
              {accountList.map(({ slug, id, title }) => {
                return (
                  <MenuItem key={id} value={{ slug, id }} width="100%">
                    {title}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>
        )}
      </Box>
    </Box>
  );
}

AnalyticsAccountPicker.propTypes = {
  accountNavbarElements: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  setCurrentAccount: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    accountNavbarElements: selectAccountNavbarElements(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

export default compose(withConnect)(AnalyticsAccountPicker);
