import { Button, Typography, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import UnlinkKeywordSubscriberDialog from "../UnlinkKeywordSubscriberDialog/UnlinkKeywordSubscriberDialog";
import { KeywordSubscribersSectionProps } from "./types";
import { useToggle } from "components/hooks";

const StyledLink = styled(NavLink)`
  color: ${(props) => {
    return props.theme.palette.secondary.main;
  }};
  margin-right: 0.5rem;
`;

export function KeywordSubscribersSection({
  sequence,
  refreshPage,
  unlinkKeyword,
}: KeywordSubscribersSectionProps) {
  // ==== FIELDS ==== //
  const keyword = sequence.linkedKeyword;

  // ==== HOOKS ==== //
  const [isUnlinkDialogOpen, toggleUnlinkDialog] = useToggle();

  // ==== RENDER ==== //
  return (
    <>
      <Typography
        variant="body1"
        fontWeight={700}
        marginTop="2rem"
        marginBottom="1rem"
      >
        Keyword subscribers
      </Typography>

      <Typography marginBottom="1rem">
        Your sequence is currently linked with the following group of keyword
        subscribers:
      </Typography>

      {keyword.id && keyword.keyword ? (
        <>
          <StyledLink to={`../keywords_groups/${keyword.id}`}>
            {keyword.keyword}
          </StyledLink>

          <Button
            color="error"
            onClick={toggleUnlinkDialog}
            data-testid="unlink-button"
          >
            Unlink
          </Button>

          <UnlinkKeywordSubscriberDialog
            open={isUnlinkDialogOpen}
            handleClose={toggleUnlinkDialog}
            handleUnlink={() => {
              unlinkKeyword([sequence.id], keyword, refreshPage);
              toggleUnlinkDialog();
            }}
            keywordSubscriberName={keyword.keyword}
            sequenceName={sequence.name}
          />
        </>
      ) : (
        <Typography color="textSecondary">None linked</Typography>
      )}
    </>
  );
}
