import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

function ChallengeModal({ deleteReport, fullScreen, handleClose, open }) {
  return (
    <Modal closeModal={handleClose} fullScreen={fullScreen} transitionIn={open}>
      <ModalHeader closeModal={handleClose}>Delete a Report</ModalHeader>
      <ModalContent
        maxWidth="100%"
        aria-label="Delete a Report Scheduling Modal"
      >
        <Box minWidth="400px">
          <p style={{ textAlign: "center" }}>
            Are you sure you want to delete this report?
          </p>
          <Box
            display="flex"
            flex="0 0 auto"
            justifyContent="flex-end"
            mt={{ xs: "0px", sm: "40px" }}
          >
            <Button
              aria-label="Cancel Deletion"
              color="primary"
              onClick={handleClose}
              style={{ marginRight: ".5rem" }}
            >
              Cancel
            </Button>
            <Button
              aria-label="Confirm Deletion"
              color="primary"
              onClick={deleteReport}
              type="submit"
              variant="contained"
            >
              Delete
            </Button>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
}

ChallengeModal.propTypes = {
  deleteReport: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ChallengeModal;
