import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Logo from "components/Logo";

const Content = styled.div``;
const LoadingIndicator = styled(Logo)``;

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Content} {
    visibility: ${(props) => {
      return props.isLoading ? "hidden" : "visible";
    }};
  }

  ${LoadingIndicator} {
    position: absolute;
    display: ${(props) => {
      return props.isLoading ? "auto" : "none";
    }};
  }
`;

export default class Loading extends Component {
  static propTypes = {
    children: PropTypes.any,
    isLoading: PropTypes.bool,
    width: PropTypes.number,
  };

  static defaultProps = {
    width: 20,
  };

  render() {
    return (
      <Root isLoading={this.props.isLoading}>
        <Content>{this.props.children}</Content>
        <LoadingIndicator
          animate
          color="transparent"
          dotColor="currentColor"
          width={this.props.width}
        />
      </Root>
    );
  }
}
