import { useState } from "react";
import PropTypes from "prop-types";

import get from "lodash/get";
import uniq from "lodash/uniq";

import {
  Autocomplete,
  createFilterOptions,
  Box,
  TextField,
} from "@mui/material";

function Root({
  value,
  changeHandler,
  contactTagsId,
  fetchContactTagsRequest,
}) {
  const [shouldRequest, setShouldRequest] = useState(true);
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState([]);
  const filter = createFilterOptions();

  const handleChange = (event, selectedOptions = [], action, { option }) => {
    switch (action) {
      case "clear":
      case "createOption":
      case "removeOption":
      case "selectOption":
        return changeHandler(
          "data.tags",
          uniq(
            selectedOptions.map(({ value: val }) => {
              if (!val)
                return option.inputValue.replaceAll(/["',]/g, "").trim();
              return val.replaceAll(/["',]/g, "").trim();
            }),
          ),
        );
      default:
        return null;
    }
  };

  const requestTags = () => {
    if (shouldRequest) {
      fetchContactTagsRequest(contactTagsId, null, {
        successCallback: (record) => {
          const newOptions = get(record, ["members"], []).map((tag) => {
            return {
              value: tag.id,
              label: tag.tag,
            };
          });
          setOptions(newOptions);
          setShouldRequest(false);
        },
      });
    }
  };

  const selectedValues = value.map((val) => {
    return { value: val, label: val };
  });

  const filterOptions = (opts, params) => {
    const filtered = filter(opts, params);

    if (params.inputValue !== "") {
      return filtered.concat({
        inputValue: params.inputValue,
        label: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  return (
    <Box position="relative" width="100%" mb="0">
      <Autocomplete
        ChipProps={{
          size: "small",
        }}
        aria-label="Add Tag"
        data-testid="add-tag"
        multiple
        id="autocomplete-tags"
        options={options}
        onOpen={requestTags}
        filterOptions={filterOptions}
        getOptionLabel={(option) => {
          return option.label;
        }}
        isOptionEqualToValue={(option) => {
          return !!selectedValues.find((item) => {
            return option.value === item.value;
          });
        }}
        value={selectedValues}
        onChange={handleChange}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              onFocus={() => {
                setIsFocused(true);
              }}
              onBlur={() => {
                setIsFocused(false);
              }}
              label={
                isFocused || value.length > 0 ? "Tags" : "Add or create tags"
              }
              aria-label="Add or create tags"
              data-testid="add-or-create-tags"
            />
          );
        }}
      />
    </Box>
  );
}

Root.propTypes = {
  changeHandler: PropTypes.func.isRequired,
  contactTagsId: PropTypes.string,
  fetchContactTagsRequest: PropTypes.func,
  value: PropTypes.array.isRequired,
};

export default Root;
