import { configurePusher, disconnect } from "pusher-redux";
import configureStore from "./configureStore";
import history from "utils/history";

const initialState = {};
export const store = configureStore(initialState, history);

export const setUpPusher = (apiKey, options) => {
  configurePusher(store, apiKey, options);
};

export const disconnectPusher = () => {
  disconnect();
};
