import get from "lodash/get";
import styled from "styled-components";
import PropTypes from "prop-types";
import { lighten } from "polished";
import { Box, Typography } from "@mui/material";

import breakpoints from "utils/styles/breakpoints";

const getPrimaryColor = (props) => {
  return get(props, ["theme", "colors", "primary", "main"], "#283C59");
};

const getBackground = (props) => {
  return props.background || "transparent";
};

const Root = styled.div`
  align-items: center;
  background: ${(props) => {
    return getBackground(props);
  }};
  display: flex;
  flex-flow: column;
  height: 100%;
  justify-content: center;
  padding: 10px;
  text-align: center;
  user-select: none;
  width: 100%;

  svg {
    margin: 20px 0;
  }

  h4 {
    color: ${(props) => {
      return getPrimaryColor(props);
    }};
    font-size: 20px;
    font-weight: 700;
    margin: 3px 0;
  }

  h5 {
    color: ${(props) => {
      return lighten(0.2, getPrimaryColor(props));
    }};
    font-size: 15px;
    font-weight: 400;
    margin: 3px 0;
  }

  button {
    margin-top: 20px;
  }
`;

const ImageContainer = styled.div`
  margin: 5px 0;
  max-width: 250px;

  img {
    width: 100%;
  }

  @media (max-width: ${breakpoints.medium - 1}px) {
    max-width: 30vw;
  }
`;

function BlankState({
  image,
  title,
  subTitle,
  background,
  button,
  className,
  subComponent,
}) {
  const redesignStyles = {
    maxWidth: "261px",

    "& img": {
      marginBottom: "24px",
      width: "124px",
    },
  };

  return (
    <Root className={className} background={background}>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexFlow: "column nowrap",
          ...redesignStyles,
        }}
      >
        {image && <ImageContainer>{image}</ImageContainer>}
        {title && <Typography variant="h4">{title}</Typography>}
        {subTitle && (
          <Typography variant="body2" color="text.secondary">
            {subTitle}
          </Typography>
        )}
        {subComponent}
        {button}
      </Box>
    </Root>
  );
}

BlankState.propTypes = {
  image: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  button: PropTypes.any,
  subTitle: PropTypes.string,
  background: PropTypes.string,
  className: PropTypes.string,
  subComponent: PropTypes.any,
  title: PropTypes.string,
};

export default BlankState;
