import type { Formik, FormikProps } from "formik";
import type { ComponentProps } from "react";
import { Account } from "@tesseract/core";
import type { UsersAutocompleteProps } from "features/Users";

export type FormValues = {
  automatedSenderId: Account.Settings["automatedSenderId"] | undefined;
};

export type FormikComponentProps = ComponentProps<typeof Formik<FormValues>>;

export type Props = Omit<
  FormikProps<FormValues> & {
    usersAutocompleteProps?: Partial<
      Omit<
        UsersAutocompleteProps,
        "renderInput" | "renderOption" | "value" | "onChange" | "onBlur"
      >
    >;
  },
  "handleReset" | "prependOptions"
>;
