import type { Contact } from "features/Sequences/components/Drawers/EnrollContactsDrawer/types";
import type { Contact as AutomationContact } from "features/Automation/models/AutomationModels";

export const formatSelectedRecords = (
  records: (Contact | AutomationContact)[],
) => {
  return records
    .filter(({ phones }) => {
      return phones.totalItems > 0;
    })
    .map(({ name, phones }) => {
      return {
        name,
        contactPhoneId: phones.members[0].id,
        formattedPhoneNumber: phones.members[0].formattedPhoneNumber,
        deliverabilityStatus: phones.members[0].deliverabilityStatus,
      };
    });
};
