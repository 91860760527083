import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useCallback, useMemo } from "react";
import AccountAnalytics from "features/AccountAnalytics";
import Automation from "features/Automation";
import FindNumbers from "features/FindNumbers";
import AccountManagement from "features/AccountManagement/Loadable";
import AccountSettings from "features/AccountSettings";
import Campaigns from "features/Campaigns/Loadable";
import Compose from "features/Compose";
import ContactImports from "features/ContactImports/Loadable";
import ContactModal from "features/ContactModal";
import Contacts from "features/Contacts/Loadable";
import Dashboard from "features/Dashboard/Loadable";
import ExtensionMessageHandler from "features/ExtensionMessageHandler";
import ExtensionPrompts from "features/ExtensionPrompts";
import Groups from "features/Groups/Loadable";
import HeaderTransition from "components/HeaderTransition";
import Inbox from "features/Inbox/Loadable";
import JobAdderCallback from "features/EntryPoint/containers/JobAdderCallback";
import Legal from "features/Legal";
import NotFoundPage from "features/NotFoundPage";
import Profile from "features/Profile/Loadable";
import AnalyticsAccountPicker from "features/AnalyticsAccountPicker";
import SalesforceCallback from "features/EntryPoint/containers/SalesforceCallback";
import { ProviderCallback } from "features/EntryPoint/containers/ProviderCallback";
import canAccessMultipleAccounts from "utils/canAccessMultipleAccounts";
import ClickToTextPage from "features/ClickToText/pages/ClickToTextPage";
import ContactsMatchListPage from "features/ClickToText/pages/ContactsMatchListPage";

const close = {
  /**
   * Auto close window after timeout
   *
   * @param {number} ms
   */
  after: (ms) => {
    setTimeout(() => {
      window.close();
    }, ms);
  },
};

/**
 * Create OAuth callback strategy
 *
 * @param {string} key - URL query param key
 */
const createStrategy = (key) => {
  return ({ resolve, reject }) => {
    const code =
      new URLSearchParams(window.location.search).get(key) ?? undefined;

    (typeof code === "string" && code.length > 0 ? resolve : reject)(code);

    close.after(500);
  };
};

const Wrapper = styled.div`
  background: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
`;

const propTypes = {
  appColors: PropTypes.object.isRequired,
  bootstrapContainer: PropTypes.object.isRequired,
  currentAccount: PropTypes.object,
  currentAccountSiteNavigation: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  isHeaderTransitioning: PropTypes.bool.isRequired,
  oidc: PropTypes.object.isRequired,
  setIsHeaderTransitioning: PropTypes.func.isRequired,
  updateAppColors: PropTypes.func.isRequired,
  updateUserCanHover: PropTypes.func.isRequired,
};

function RouterWrapper(props) {
  const getDefaultFeaturePath = ({ withAccountSlug = false } = {}) => {
    const membershipAccounts = props.currentUser?.memberships.members ?? [];
    const multiAccountUser = membershipAccounts.length > 1;

    const defaultMessagingAccount = membershipAccounts.find(({ account }) => {
      return (
        account.slug === props.currentUser.settings?.defaultMessagingAccount
      );
    });

    // Users with a single messaging account go to /inbox.
    if (!multiAccountUser && !withAccountSlug) {
      return `${membershipAccounts[0].account.inbox}`;
    }

    // Multi-account users with a default messaging account go to /inbox.
    if (
      multiAccountUser &&
      Boolean(defaultMessagingAccount) &&
      !withAccountSlug
    ) {
      return (
        defaultMessagingAccount.account.inbox ??
        `/${props.currentAccount.slug}/not_found`
      );
    }

    // Handler for changing accounts via the account selection dropdown.
    if (multiAccountUser && withAccountSlug) {
      return props.currentAccount.type === "standard"
        ? props.currentAccount.inbox
        : props.currentAccount.contacts;
    }

    // Users with a single non-messaging account or a user with multiple
    // accounts but no default set go to /dashboard.
    return "/dashboard";
  };

  const {
    currentAccount,
    currentAccountSiteNavigation,
    currentUser,
    isHeaderTransitioning,
  } = props;

  const renderFeatureRoute = useCallback(
    (siteNavigationElement) => {
      const Feature = (() => {
        switch (siteNavigationElement.name) {
          case "inbox":
            return Inbox;
          case "contacts":
            return Contacts;
          case "campaigns":
            return Campaigns;
          case "groups":
            return Groups;
          case "automation":
            return Automation;
          case "analytics":
            return AccountAnalytics;
          case "settings":
            return AccountSettings;
          default:
            return NotFoundPage;
        }
      })();
      return (
        <Route
          key={siteNavigationElement.id}
          path={siteNavigationElement.uiLink.replace("?pro", "")}
          render={(routeProps) => {
            return (
              <HeaderTransition isHeaderTransitioning={isHeaderTransitioning}>
                <Feature
                  {...routeProps}
                  currentAccount={currentAccount}
                  accountId={currentAccount.id}
                  currentUser={currentUser}
                  uiLink={siteNavigationElement.uiLink}
                />
              </HeaderTransition>
            );
          }}
        />
      );
    },
    [currentAccount, currentUser, isHeaderTransitioning],
  );

  return (
    <Wrapper>
      <ExtensionMessageHandler />
      <ExtensionPrompts />
      <ContactModal currentUser={currentUser} />
      <Compose />
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return <Redirect to={getDefaultFeaturePath()} />;
          }}
        />
        <Route
          exact
          path="/analytics"
          render={(routeProps) => {
            return (
              <HeaderTransition isHeaderTransitioning={isHeaderTransitioning}>
                <AnalyticsAccountPicker {...routeProps} />
              </HeaderTransition>
            );
          }}
        />
        <Route
          path="/legal"
          render={() => {
            return <Legal currentAccount={currentAccount} />;
          }}
        />
        <Route
          path="/dashboard"
          render={(routeProps) => {
            return <Dashboard {...routeProps} currentUser={currentUser} />;
          }}
        />
        <Route
          path="/profile"
          render={(routeProps) => {
            return (
              <HeaderTransition isHeaderTransitioning={isHeaderTransitioning}>
                <Profile {...routeProps} currentUser={currentUser} />
              </HeaderTransition>
            );
          }}
        />
        {canAccessMultipleAccounts(currentUser) && (
          <Route
            path="/accounts"
            render={(routeProps) => {
              return <AccountManagement {...routeProps} />;
            }}
          />
        )}
        <Route
          exact
          path="/auth/dynamics_callback"
          render={(routeProps) => {
            return (
              <ProviderCallback
                channel="MicrosoftDynamicsChannel"
                strategy={createStrategy("tenant")}
                {...routeProps}
              />
            );
          }}
        />
        <Route
          exact
          path="/auth/hubspot_callback"
          render={(routeProps) => {
            return (
              <ProviderCallback
                channel="HubspotChannel"
                strategy={createStrategy("code")}
                {...routeProps}
              />
            );
          }}
        />
        <Route
          exact
          path="/auth/salesforce_callback"
          render={(routeProps) => {
            return (
              <SalesforceCallback
                {...routeProps}
                integrationUrl={currentAccount.integrations}
              />
            );
          }}
        />
        <Route
          exact
          path="/auth/job_adder_callback"
          render={(routeProps) => {
            return (
              <JobAdderCallback
                {...routeProps}
                integrationUrl={currentAccount.integrations}
              />
            );
          }}
        />
        <Route
          path="/:accountSlug"
          render={({ match }) => {
            return (
              <Switch>
                {currentAccountSiteNavigation.members.map(
                  (siteNavigationElement) => {
                    return renderFeatureRoute(siteNavigationElement);
                  },
                )}
                <Route
                  path={`${match.path}/analytics`}
                  render={(routeProps) => {
                    return (
                      <AccountAnalytics
                        {...routeProps}
                        currentAccount={currentAccount}
                      />
                    );
                  }}
                />
                <Route
                  path={`${match.path}/contact_imports`}
                  render={(routeProps) => {
                    return (
                      <ContactImports
                        {...routeProps}
                        currentAccount={currentAccount}
                      />
                    );
                  }}
                />
                {window.top !== window.self && (
                  <>
                    <Route
                      path={`${match.path}/find_numbers`}
                      render={() => {
                        return <FindNumbers />;
                      }}
                    />

                    <Route
                      path={`${match.path}/click_to_text`}
                      render={() => {
                        return <ClickToTextPage />;
                      }}
                    />

                    <Route
                      path={`${match.path}/contact_match_list`}
                      render={() => {
                        return <ContactsMatchListPage />;
                      }}
                    />
                  </>
                )}
                <Route
                  exact
                  path=""
                  render={() => {
                    return (
                      <Redirect
                        to={getDefaultFeaturePath({
                          withAccountSlug: true,
                        })}
                      />
                    );
                  }}
                />
                <Route component={NotFoundPage} />
              </Switch>
            );
          }}
        />
        <Route component={NotFoundPage} />
      </Switch>
    </Wrapper>
  );
}

RouterWrapper.propTypes = propTypes;

export default RouterWrapper;
