import assert from "assert";
import { userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const deleteStep = async (
  currentAccount: AccountType,
  stepId: string,
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequence_steps/${stepId}`;

  const request = new Request(url, {
    method: "DELETE",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
  });

  return fetch(request);
};
