import { Dialog, DialogContent, DialogContentText } from "@mui/material";
import { DialogFooter } from "components/DialogFooter";
import { DialogHeader } from "components/DialogHeader";
import { DialogWrapper } from "components/DialogWrapper";

type Props = {
  message: { id: string };
  show: boolean;
  closeModal: () => void;
  confirm: (id: string) => void;
};

export function MessageDeleteConfirmModal({
  message,
  show,
  closeModal,
  confirm,
}: Props) {
  return (
    <DialogWrapper>
      <Dialog open={show} onClose={closeModal}>
        <DialogHeader onClose={closeModal} title="Delete message?" />

        <DialogContent>
          <DialogContentText
            aria-label="Confirmation Message"
            variant="body1"
            color="text.primary"
          >
            Are you sure you want to delete this message?
          </DialogContentText>
        </DialogContent>

        <DialogFooter
          onCancel={closeModal}
          onConfirm={() => {
            confirm(message.id);
          }}
        />
      </Dialog>
    </DialogWrapper>
  );
}
