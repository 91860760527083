import { Component } from "react";
import PropTypes from "prop-types";
import BlankState from "components/BlankState";
import Texty from "utils/images/frowny-texty.svg";

export default class ErrorPage extends Component {
  static propTypes = {
    subTitle: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    subTitle: "Please refresh and try again",
    title: "Something went wrong",
  };

  getErrorRedirectComponent() {
    return (
      <div>
        <a href="/">Refresh</a> and try again?
      </div>
    );
  }

  render() {
    const { subTitle, title } = this.props;
    return (
      <BlankState
        image={<img src={Texty} alt="Frowning Texty" />}
        title={title}
        subTitle={subTitle}
        subComponent={this.getErrorRedirectComponent()}
      />
    );
  }
}
