import "whatwg-fetch";
import Honeybadger from "@honeybadger-io/js";
import checkAndRefreshUser from "utils/checkAndRefreshUser";

const setHbContext = (response) => {
  Honeybadger.setContext({
    requestId: response.headers.get("X-Request-Id") || undefined,
  });

  return response;
};

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response) {
  if (
    response.status === 202 ||
    response.status === 204 ||
    response.status === 205
  ) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    await checkAndRefreshUser();
    document.location.reload();
    const error = new Error(response.status);
    error.response = { title: "Unauthorized" };
    throw error;
  }

  if (response.status === 403) {
    const error = new Error(response.statusText);
    error.response = { title: "Forbidden", url: response.url };
    throw error;
  }

  return response.json().then((r) => {
    const error = new Error(r.statusText);
    error.response = r;
    throw error;
  });
}

const clearHbContext = (value) => {
  Honeybadger.setContext({
    requestId: undefined,
  });

  return value;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default function request(url, options) {
  return fetch(url, options)
    .then(setHbContext)
    .then(checkStatus)
    .then(parseJSON)
    .finally(clearHbContext);
}
