import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";

import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";
import generateActionGenerators from "utils/generateContainerState/generateActionGenerators";
import generateActionTypes from "utils/generateContainerState/generateActionTypes";
import { makeRequest } from "utils/generateContainerState/generateSagas";

// ACTION TYPES
const whiteLabelLogoActionTypes = generateActionTypes({
  container: "WhiteLabel",
  actions: ["upload"],
  recordType: "whiteLabelLogo",
});

const whiteLabelMobileLogoActionTypes = generateActionTypes({
  container: "WhiteLabel",
  actions: ["upload"],
  recordType: "whiteLabelMobileLogo",
});

const actionTypes = {
  ...whiteLabelLogoActionTypes,
  ...whiteLabelMobileLogoActionTypes,
};

// ACTION GENERATORS
const actionGenerators = generateActionGenerators(actionTypes);

// SAGA
const sagas = {
  *uploadWhiteLabelLogoRequest({ url, params, options = {} }) {
    try {
      const account = yield select(selectCurrentAccount);

      const { signedUrl } = yield makeRequest({
        url,
        method: "POST",
        params: { contentType: params.file.type, account: account.id },
      });

      if (options.successCallback) {
        const logoKey = signedUrl.split(/[?]/)[0].split("/").pop();
        options.successCallback(logoKey);
      }

      yield call(fetch, signedUrl, {
        method: "PUT",
        body: params.file,
      });
      yield put(actionGenerators.uploadWhiteLabelLogoSuccess(signedUrl));
    } catch (error) {
      yield put(actionGenerators.uploadWhiteLabelLogoFailure(error));
    }
  },
  *uploadWhiteLabelMobileLogoRequest({ url, params, options = {} }) {
    try {
      const account = yield select(selectCurrentAccount);

      const { signedUrl } = yield makeRequest({
        url,
        method: "POST",
        params: { contentType: params.file.type, account: account.id },
      });

      if (options.successCallback) {
        const logoKey = signedUrl.split(/[?]/)[0].split("/").pop();
        options.successCallback(logoKey);
      }

      yield call(fetch, signedUrl, {
        method: "PUT",
        body: params.file,
      });
      yield put(actionGenerators.uploadWhiteLabelMobileLogoSuccess(signedUrl));
    } catch (error) {
      yield put(actionGenerators.uploadWhiteLabelMobileLogoFailure(error));
    }
  },
};

function* saga() {
  yield all([
    takeLatest(
      actionTypes.UPLOAD_WHITE_LABEL_LOGO_REQUEST,
      sagas.uploadWhiteLabelLogoRequest,
    ),
    takeLatest(
      actionTypes.UPLOAD_WHITE_LABEL_MOBILE_LOGO_REQUEST,
      sagas.uploadWhiteLabelMobileLogoRequest,
    ),
  ]);
}

// REDUCER
const errorUploading = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_WHITE_LABEL_LOGO_REQUEST:
      return null;
    case actionTypes.UPLOAD_WHITE_LABEL_LOGO_SUCCESS:
      return null;
    case actionTypes.UPLOAD_WHITE_LABEL_LOGO_FAILURE:
      return action.response;
    default:
      return state;
  }
};

const isUploading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_WHITE_LABEL_LOGO_REQUEST:
      return true;
    case actionTypes.UPLOAD_WHITE_LABEL_LOGO_SUCCESS:
      return false;
    case actionTypes.UPLOAD_WHITE_LABEL_LOGO_FAILURE:
      return false;
    default:
      return state;
  }
};

const isMobileUploading = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_WHITE_LABEL_MOBILE_LOGO_REQUEST:
      return true;
    case actionTypes.UPLOAD_WHITE_LABEL_MOBILE_LOGO_SUCCESS:
      return false;
    case actionTypes.UPLOAD_WHITE_LABEL_MOBILE_LOGO_FAILURE:
      return false;
    default:
      return state;
  }
};

const reducer = combineReducers({
  errorUploading,
  isUploading,
  isMobileUploading,
});

// SELECTORS
const selectWhiteLabelContainer = (state) => {
  return state.get("WhiteLabelContainer").toJS();
};
const selectors = { selectWhiteLabelContainer };

export { actionTypes, actionGenerators, saga, reducer, selectors };
