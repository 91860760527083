/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, LinearProgress, Typography, styled } from "@mui/material";
import Papa from "papaparse";
import { useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { postContactImport, postGenerateSignedUrl, putSignedUrl } from "./api";
import { ConfirmJobDialog } from "./components/ConfirmJobDialog";
import { postJobImport } from "./api/postJobImport";
import { GreenhouseJobDialog } from "./components/GreenhouseJobDialog";
import ImportingContacts from "utils/images/importing-contacts.svg";
import NoNumbersFound from "utils/images/no-numbers-found.svg";
import {
  FindNumbersImportState as ImportState,
  useCurrentAccount,
} from "hooks";
import { useAppContext } from "hooks/useAppContext";
import { Dialog, useDialog } from "components/Dialog";

function FindNumbers() {
  // ==== HOOKS ==== //
  const { ref: confirmJobRef, open: openConfirmJobDialog } = useDialog<
    any,
    boolean | string
  >();
  const history = useHistory();
  const { state: contacts }: { state: any } = useLocation();
  const {
    importState,
    setImportState,
    showSuccessNotification,
    showErrorNotification,
    showInfoNotification,
  } = useAppContext().findNumbers;
  const { presignContactImport, contactImport, slug } = useCurrentAccount();

  // ==== METHODS ==== //
  const handleFindNumbers = () => {
    window.parent.postMessage({ type: "findNumbersRequested" }, "*");
  };

  const importContacts = useCallback(async () => {
    try {
      setImportState(ImportState.Importing);

      // Error out if no contacts were found.
      if (!contacts?.length) {
        setImportState(ImportState.NoContactsFound);

        // Show info notification.
        showInfoNotification();
        return;
      }

      // Create csv file.
      // The order of the contacts keys matters during the csv step!!
      // If it's not as expected, contact import will fail!!
      // Order: phone_numbers, first_name, last_name, docType, isValid
      const csv = Papa.unparse(
        contacts.map((contact: any) => {
          return {
            phone_numbers: contact.phone_numbers,
            first_name: contact.first_name,
            last_name: contact.last_name,
            docType: contact.docType,
            isValid: contact.isValid,
          };
        }),
      );
      const csvBlob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const file = new File([csvBlob], "extension.csv");

      // Generate s3 endpoint to use.
      const generateSignedUrlResponse = await postGenerateSignedUrl(
        presignContactImport!,
        file.name,
      );
      const { signedUrl } = await generateSignedUrlResponse.json();

      // Put file into s3.
      await putSignedUrl(signedUrl, file);

      // Import contacts.
      await postContactImport(contactImport!, signedUrl);

      setImportState(ImportState.Default);
    } catch (e) {
      setImportState(ImportState.Error);

      // Show error notification.
      showErrorNotification();
    }
  }, [contacts]);

  useEffect(() => {
    if (contacts[0]?.docType === "job") {
      openConfirmJobDialog({}, async (jobType) => {
        // Import contacts by job.
        if (
          jobType &&
          typeof jobType === "string" &&
          jobType !== "greenhouseJob"
        ) {
          await postJobImport(
            contactImport!,
            jobType,
            contacts[0].id,
            "bullhorn",
          );
          return null;
        }
        if (jobType === "greenhouseJob") {
          return null;
        }

        // User selected `cancel` so go back.
        return history.goBack();
      })();
    } else {
      importContacts();
    }

    return () => {
      // Clear location state (the contacts list) on page refresh to prevent accidental subsequent imports.
      window.history.replaceState({}, document.title);
    };
  }, [importContacts]);

  // ==== RENDER ==== //
  const render = ({
    imgAlt,
    imgSrc,
    bodyText,
    showProgressBar,
    showTryAgainButton,
  }: any) => {
    return (
      <>
        <img
          alt={imgAlt}
          src={imgSrc}
          style={{
            marginBottom: "24px",
          }}
        />

        {showProgressBar && (
          <LinearProgress
            sx={{
              marginBottom: "24px",
              width: "200px",
            }}
          />
        )}

        <Typography
          variant="body2"
          sx={{
            marginBottom: "24px",
          }}
        >
          {bodyText}
        </Typography>

        {showTryAgainButton && (
          <Button
            onClick={handleFindNumbers}
            variant="outlined"
            sx={{
              textTransform: "uppercase",
            }}
          >
            Try again
          </Button>
        )}
      </>
    );
  };

  const renderImportingState = () => {
    return render({
      imgAlt: "Importing contacts...",
      imgSrc: ImportingContacts,
      bodyText:
        "Hang tight! We're pulling in your contacts. We'll give you a heads-up when it's done.",
      showProgressBar: true,
    });
  };

  const renderNoContactsFoundState = () => {
    return render({
      imgAlt: "No numbers found.",
      imgSrc: NoNumbersFound,
      bodyText:
        "No numbers found. Make sure you’re on a supported page and try again.",
      showTryAgainButton: true,
    });
  };

  return (
    <>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexFlow: "column nowrap",
          height: "100%",
          justifyContent: "center",
          margin: "0 auto",
          textAlign: "center",
          width: "261px",
        }}
      >
        {(importState === ImportState.Importing ||
          importState === ImportState.Default) &&
          renderImportingState()}
        {importState === ImportState.NoContactsFound &&
          renderNoContactsFoundState()}
      </Box>

      {contacts.length > 0 && (
        <Dialog<any, boolean | string> ref={confirmJobRef}>
          {({ close }) => {
            return contacts[0].source === "Greenhouse" ? (
              <GreenhouseJobDialog
                currentAccountSlug={slug}
                findNumbersResponse={contacts[0]}
                close={(confirm) => {
                  close(confirm)();
                }}
                contactImport={contactImport}
              />
            ) : (
              <ConfirmJobDialog
                close={(confirm) => {
                  close(confirm)();
                }}
                name={contacts[0].title}
                id={contacts[0].id}
              />
            );
          }}
        </Dialog>
      )}
    </>
  );
}

export default FindNumbers;
