import {
  Box,
  Checkbox,
  Chip,
  Divider,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { SequencesContactFilterInput } from "../SequencesContactFilterInput";
import { SequencesContactFiltersProps } from "./types";
import { SearchBox } from "components/SearchBox";
import { useCurrentAccount } from "hooks";

function SequencesContactFilters({
  checkedEnrollers,
  checkedSteps,
  checkedStepsDisplayString,
  checkedEnrollersDisplayString,
  searchTerm,
  sequence,
  handleCheckEnroller,
  handleCheckStep,
  handleClear,
  handleSearch,
}: SequencesContactFiltersProps) {
  // ==== HOOKS ==== //
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const history = useHistory();
  const currentAccount = useCurrentAccount();
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // ==== FIELDS ==== //
  const open = Boolean(anchorEl);
  const { stepCount, enrollers, linkedKeyword } = sequence;

  const stepMenuItems = Array.from({ length: stepCount }, (_, index) => {
    return {
      label: `Step #${index + 1}`,
      value: index + 1,
    };
  });

  const enrollerMenuItems = enrollers.map((enroller) => {
    return {
      label: enroller,
      value: enroller,
    };
  });

  // ==== METHODS ==== //
  const handleKeywordLinkedClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleKeywordMenuClose = () => {
    setAnchorEl(null);
  };

  const goToKeyword = () => {
    handleKeywordMenuClose();
    history.push(
      `/${currentAccount.slug}/automation/keywords_groups/${linkedKeyword.id}`,
    );
  };

  // ==== RENDER ==== //
  const renderMenuItems = (
    items: { value: string | number; label: string }[],
  ) => {
    return items.map(({ label }) => {
      const displayString = label.includes("Step")
        ? checkedStepsDisplayString
        : checkedEnrollersDisplayString;
      return (
        <MenuItem key={label} value={label}>
          <Checkbox color="secondary" checked={displayString.includes(label)} />
          <ListItemText primary={label} />
        </MenuItem>
      );
    });
  };

  return (
    <>
      <Box
        display="flex"
        flexDirection={mobileScreen ? "column" : "row"}
        gap={{ xs: "0.5rem", md: "1rem", lg: "1.5rem" }}
        margin="1.5rem 1rem 1.75rem 1rem"
      >
        <Box maxWidth={mobileScreen ? "12.5rem" : "21.375rem"}>
          <SearchBox
            value={searchTerm}
            placeholder="Search contacts"
            onClear={handleClear}
            onChange={handleSearch}
            size="small"
          />
        </Box>
        <SequencesContactFilterInput
          displayValue={checkedStepsDisplayString}
          field="Step"
          handleChange={handleCheckStep}
          checkedItems={checkedSteps}
          menuItems={renderMenuItems(stepMenuItems)}
        />
        <SequencesContactFilterInput
          displayValue={checkedEnrollersDisplayString}
          field="Enroller"
          handleChange={handleCheckEnroller}
          checkedItems={checkedEnrollers}
          menuItems={renderMenuItems(enrollerMenuItems)}
        />
      </Box>

      {linkedKeyword.id && linkedKeyword.keyword && (
        <Box>
          <Chip
            label="Keyword linked"
            variant="outlined"
            onClick={handleKeywordLinkedClick}
          />
          <Menu
            id="keyword-linked-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleKeywordMenuClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              "& .MuiPaper-root": {
                marginTop: "0.438rem",
                minWidth: "220px",
              },
            }}
          >
            <MenuItem onClick={goToKeyword}>{linkedKeyword.keyword}</MenuItem>
          </Menu>
        </Box>
      )}
      <Divider />
    </>
  );
}

export { SequencesContactFilters };
