import { useMediaQuery } from "@mui/material";
import breakpoints from "utils/styles/breakpoints";

/**
 * @deprecated
 * - use css media queries when possible
 * - otherwise use `useMediaQuery` directly from `@mui/material`
 */
export const useLayout = () => {
  const isLarge = useMediaQuery(`(min-width: ${breakpoints.large}px)`);
  const isMd = useMediaQuery(`(max-width: ${breakpoints.medium + 1}px)`);

  return { fullScreen: isMd, isLarge, isSinglePanel: isMd };
};
