import { textUsApiV3 } from "services";

export const postContactImport = async (
  url: string,
  signedUrl: string,
): Promise<Response> => {
  return textUsApiV3.post(url, {
    headers: ["phoneNumber", "firstName", "lastName"],
    url: signedUrl,
  });
};
