import { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import { useSnackbar } from "notistack";
import Loader from "components/Loader";

const HiddenInput = styled.input`
  display: none;
`;

const InputLabel = styled.label`
  span {
    cursor: pointer;
  }
`;

function OptOutImport(props) {
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const successCallback = () => {
    setIsLoading(false);
    enqueueSnackbar("Uploaded opt outs successfully.", {
      variant: "info",
    });
  };

  const errorCallback = () => {
    setIsLoading(false);
    enqueueSnackbar("Opt out upload failed.", {
      variant: "error",
    });
  };

  const handleFilesSelected = (event) => {
    const { currentAccount, createOptOutImportRequest } = props;
    const file = event.target.files[0];
    setIsLoading(true);
    createOptOutImportRequest(
      currentAccount.presignOptOutImport,
      {
        file,
      },
      {
        successCallback,
        errorCallback,
      },
    );
  };

  return (
    <>
      <HiddenInput
        accept="text/csv"
        data-test="ImportOptOutsInput"
        id="opt-out-import"
        onChange={handleFilesSelected}
        type="file"
      />
      <Tooltip title="Upload a csv of numbers to opt out">
        <InputLabel htmlFor="opt-out-import">
          <Button
            variant="contained"
            color="primary"
            component="span"
            data-test="ImportOptOutsButton"
          >
            <Loader isLoading={isLoading}>Import Opt Outs</Loader>
          </Button>
        </InputLabel>
      </Tooltip>
    </>
  );
}

OptOutImport.propTypes = {
  createOptOutImportRequest: PropTypes.func.isRequired,
  currentAccount: PropTypes.object.isRequired,
};

export default OptOutImport;
