export const INDIVIDUAL_SEQUENCE_TABS = Object.freeze({
  overview: "Overview",
  contacts: "Contacts",
  settings: "Settings",
});

export const SEQUENCE_STATES = Object.freeze({
  active: "active",
  inactive: "inactive",
  archive: "archive",
});

export const SCHEDULE_TYPES = Object.freeze({
  relative: "relative",
  absolute: "absolute",
});
