import { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useAnalytics } from "./hooks/useAnalytics";
import {
  AccountAnalyticsProps,
  ReportId,
  ReportMember,
} from "./models/AnalyticsModels";
import { fetchCurrentUser } from "./api/fetchCurrentUser";

import PageSidebar from "components/Page/PageSidebar";
import PageSidebarNavLink from "components/Page/PageSidebarNavLink";
import PageWrapper from "components/Page/PageWrapper";
import withRecord from "higherOrderComponents/withRecord";
import withSidebar from "higherOrderComponents/withSidebar";

function AccountAnalytics({
  currentAccount,
  currentUser,
  fetchAnalyticsReportsListRequest,
  history,
  isSinglePanel,
  location,
  showSidebar,
  toggleSidebar,
}: AccountAnalyticsProps) {
  const { slug } = currentAccount ?? {};
  const url = `/${slug}/analytics`;

  const [scheduledReports, setScheduledReports] = useState<ReportMember[]>([]);

  const { getRouteComponent, reportIds, setReportsIds } = useAnalytics();

  const { enqueueSnackbar } = useSnackbar();

  /*
   * Fetches most up to date user object
   */
  const getScheduledReports = useCallback(() => {
    fetchCurrentUser(currentUser.id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        const reports = data.settings.analyticsReports || [];

        setScheduledReports(reports);

        return reports;
      })
      .catch((error) => {
        enqueueSnackbar(`Error loading scheduled reports. Error: ${error}`, {
          variant: "error",
        });
      });
  }, [currentUser, enqueueSnackbar]);

  const successCallback = ({ members }: { members: ReportId[] }) => {
    return setReportsIds(members);
  };

  const errorCallback = (error: Error) => {
    const { name = "", message = "" } = error;
    enqueueSnackbar(
      `Something went wrong trying to get analytic reports. ${name}: ${message}`,
      { variant: "error" },
    );
  };

  /*
   * Fetch most up to date scheduled reports for current user on initial render.
   */
  useEffect(() => {
    if (currentUser) {
      getScheduledReports();
    }
  }, [currentUser, getScheduledReports]);

  useEffect(() => {
    if (slug) {
      fetchAnalyticsReportsListRequest(url, null, {
        successCallback,
        errorCallback,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  return (
    <PageWrapper>
      <PageSidebar>
        {reportIds.map(({ title, slug: pageSlug, id }: ReportId) => {
          return (
            <PageSidebarNavLink to={`${url}/${pageSlug}`} key={id}>
              {title}
            </PageSidebarNavLink>
          );
        })}
      </PageSidebar>
      <Switch location={location.path}>
        <Route
          exact
          path={url}
          render={() => {
            return <Redirect to={`${url}/overview`} />;
          }}
        />
        {reportIds.map(({ reports, slug: pageSlug, id }: ReportId) => {
          return (
            <Route
              key={id}
              exact
              path={`${url}/${pageSlug}`}
              render={() => {
                return getRouteComponent({
                  currentAccount,
                  currentUser,
                  history,
                  isSinglePanel,
                  reports,
                  scheduledReports,
                  setScheduledReports,
                  showSidebar,
                  slug: pageSlug,
                  toggleSidebar,
                });
              }}
            />
          );
        })}
      </Switch>
    </PageWrapper>
  );
}

const mapStateToProps = (): Record<string, unknown> => {
  return {};
};

const withConnect = connect(() => {
  return mapStateToProps;
});

export default compose(
  withConnect,
  withRecord({
    actions: ["create"],
    container: "features/AccountAnalytics/export",
    type: "analyticExport",
  }),
  withRecord({
    actions: ["fetch"],
    container: "features/AccountAnalytics/analyticsReportsList",
    noFetch: true,
    shape: {},
    showLoader: () => {
      return false;
    },
    type: "analyticsReportsList",
  }),
  withSidebar,
)(AccountAnalytics);
