import get from "lodash/get";

export default (user, account) => {
  const matchingMembership = get(user, ["memberships", "members"], []).find(
    (membership) => {
      return (
        get(membership, ["account", "id"], membership.account) === account?.id
      );
    },
  );
  return get(matchingMembership, ["role"]);
};
