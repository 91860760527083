import {
  ChangeEvent,
  Dispatch,
  MouseEvent,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from "react";
import { debounce } from "lodash";
import getCheckedValues from "../utils/getCheckedValues";
import { GridFilter } from "../models/AccountPlanModels";

const useLicenseGridToolbar = (
  isChecked: GridFilter,
  setSearchTerm: Dispatch<SetStateAction<string>>,
) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [debouncedSearch, setDebouncedSearch] = useState("");

  const open = Boolean(anchorEl);

  const [checkedCount, checkedString] = getCheckedValues(isChecked);

  const setDebouncedSearchTerm = useMemo(() => {
    return debounce((search: string) => {
      setDebouncedSearch(search);
    }, 300);
  }, []);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  const handleSearch = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = event.target;
      setSearchTerm(value);
      setDebouncedSearchTerm(value);
    },
    [setDebouncedSearchTerm, setSearchTerm],
  );

  return {
    anchorEl,
    checkedCount,
    checkedString,
    debouncedSearch,
    open,
    handleClearSearch,
    handleCloseMenu,
    handleOpenMenu,
    handleSearch,
  };
};

export { useLicenseGridToolbar };
