import { Component } from "react";
import Button from "@mui/material/Button";
import { userManager } from "@tesseract/core";
import BlankState from "components/BlankState";
import Texty from "utils/images/frowny-texty.svg";

export default class LoginFailedPage extends Component {
  handleBackToLogin() {
    userManager.signoutRedirect();
  }

  render() {
    return (
      <BlankState
        image={<img src={Texty} alt="Frowny Texty" />}
        title="Hmm, we could not find an account associated with that email"
        subTitle="Please try again. If you used Google to log in, you may have selected the wrong account "
        button={
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleBackToLogin}
          >
            Back To Login
          </Button>
        }
      />
    );
  }
}
