import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";

import MainView from "./components/MainView";
import {
  selectAccountNavbarElements,
  selectCurrentUser,
  selectCurrentAccount,
} from "features/EntryPoint/containers/App/selectors";
import { setCurrentAccount } from "features/MainNavigation/state";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";

class Dashboard extends Component {
  render() {
    return <MainView {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    accountNavbarElements: selectAccountNavbarElements(state, props),
    currentUser: selectCurrentUser(state, props),
    currentAccount: selectCurrentAccount(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  setCurrentAccount,
  setCompose: composeActionGenerators.setCompose,
});

export default compose(withConnect)(Dashboard);
