export const CONTACT_VARIABLES = [
  [`{{contact.name | default: "there"}}`, "Full Name"],
  [`{{contact.firstName | default: "there"}}`, "First Name"],
  [`{{contact.lastName | default: "there"}}`, "Last Name"],
  [`{{contact.business | default: ""}}`, "Business Name"],
];

export const USER_VARIABLES = [
  [`{{user.name}}`, "Full Name"],
  [`{{user.firstName}}`, "First Name"],
  [`{{user.lastName}}`, "Last Name"],
  [`{{user.email}}`, "Email Address"],
  [`{{account.name}}`, "Account Name"],
];
