import { Grid, Typography } from "@mui/material";
import { Account, Contact } from "@tesseract/core";
import { useCallback } from "react";
import { useSnackbar } from "notistack";
import capitalize from "lodash/capitalize";
import { DialogHeader } from "components/DialogHeader";
import { DialogFooter } from "components/DialogFooter";
import { unlinkCandidate } from "features/ContactSync/api/unlinkCandidate";

type Props = {
  currentAccount: Account.Raw;
  handleClose: () => void;
  contact: Contact.Raw;
  cancel: () => void;
  onContactUnlink: () => void;
  integrationName: string;
};

export function ContactUnlinkCandidateDialog({
  cancel,
  handleClose,
  currentAccount,
  contact,
  onContactUnlink,
  integrationName,
}: Props) {
  const snackbar = useSnackbar();
  const handleConfirm = useCallback(() => {
    unlinkCandidate(currentAccount.id, contact, integrationName)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to unlink candidate");
        }
        onContactUnlink();
        return snackbar.enqueueSnackbar("Contact link removed.", {
          variant: "info",
        });
      })
      .catch(() => {
        snackbar.enqueueSnackbar("Error unlinking contact", {
          variant: "error",
        });
      });
  }, [contact, currentAccount.id, snackbar, onContactUnlink, integrationName]);

  return (
    <>
      <DialogHeader onClose={handleClose} title="Unlink contact?" />
      <Grid
        sx={{
          overflowY: "auto",
        }}
      >
        <Grid
          sx={{
            paddingX: 2,
            paddingY: 2,
          }}
        >
          <Typography variant="body1">
            {`This contact will no longer be associated with any matching ${capitalize(integrationName)}
            contacts found. This action cannot be reversed.`}
          </Typography>
        </Grid>
        <DialogFooter
          confirmColor="error"
          confirmVariant="text"
          confirmText="Unlink"
          onCancel={cancel}
          onConfirm={handleConfirm}
        />
      </Grid>
    </>
  );
}
