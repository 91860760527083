import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";

const Header = styled.header`
  align-items: center;
  border-bottom: ${(props) => {
    return (
      props.borderBottom ||
      props.theme.mixins.border({ color: props.theme.colors.divider })
    );
  }};
  color: ${(props) => {
    return props.color || props.theme.colors.primary.main;
  }};
  display: flex;
  flex: 0 0
    ${(props) => {
      return props.theme.layout.modalHeaderHeight;
    }};
  font-size: ${(props) => {
    return props.fontSize || "16px";
  }};
  font-weight: ${(props) => {
    return props.fontWeight || "700";
  }};
  height: ${(props) => {
    return props.theme.layout.modalHeaderHeight;
  }};
  justify-content: space-between;
  padding-left: ${(props) => {
    return props.paddingLeft || "20px";
  }};
  margin-top: env(safe-area-inset-top);
`;

const ChildrenSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
`;

const CloseSection = styled.div`
  flex: 0 0 50px;
`;

/**
 * @deprecated
 * - Use ui/app/components/DialogHeader/DialogHeader.tsx
 */
class ModalHeader extends Component {
  static propTypes = {
    children: PropTypes.any.isRequired,
    closeModal: PropTypes.func,
    borderBottom: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    fontWeight: PropTypes.string,
    paddingLeft: PropTypes.string,
  };

  render() {
    return (
      <Header
        borderBottom={this.props.borderBottom}
        color={this.props.color}
        fontSize={this.props.fontSize}
        fontWeight={this.props.fontWeight}
        paddingLeft={this.props.paddingLeft}
      >
        <ChildrenSection>{this.props.children}</ChildrenSection>
        {this.props.closeModal && (
          <CloseSection>
            <Tooltip title="Close">
              <IconButton
                onClick={this.props.closeModal}
                aria-label="Close Modal"
                size="large"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </CloseSection>
        )}
      </Header>
    );
  }
}

export default ModalHeader;
