import BaseSelect from "@mui/material/Select";
import { ReactElement, useCallback, useMemo } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import type { Option, Props, SupportedValueTypes } from "./types";
import useId from "utils/uuid";
import { useField } from "components/hooks/useField";

/**
 * <Select /> element
 *
 * @param {Props}
 * @returns {ReactElement} Native select element
 * @example
 * ```jsx
 * <Select label="Select option" name="name" options={[{ value: 1, label: "Option 1" }]} />
 * ```
 */
export function Select<
  Options extends Array<Option<ValueOfOptions>>,
  ValueOfOptions extends SupportedValueTypes,
>({
  name,
  label,
  options,
  defaultValue,
  onChange,
  size,
  fullWidth,
  required,
  errorText = "Field is required",
  ...props
}: Props<Options, ValueOfOptions>): ReactElement {
  const id = useId();
  const [{ value, ...field }, setValue] = useField<ValueOfOptions>(name, {
    defaultValue,
    required,
  });

  const optionsMap = useMemo(() => {
    return Object.fromEntries(
      options.map((option) => {
        return [`${option.value ?? ""}`, option];
      }),
    );
  }, [options]);

  const onChangeHandler = useCallback(
    (event) => {
      const newValue = optionsMap[event.target.value].value;
      if (onChange) {
        onChange(newValue, value);
      }
      setValue(newValue);
    },
    [value, onChange, optionsMap, setValue],
  );

  return (
    <FormControl size={size} fullWidth={fullWidth}>
      <InputLabel id={id}>{label}</InputLabel>
      <BaseSelect
        {...field}
        required={Boolean(required)}
        value={value ?? ""}
        size={size}
        label={label}
        labelId={id}
        onChange={onChangeHandler}
        {...props}
      >
        {options.map(({ label: optionLabel, value: optionValue }) => {
          return (
            <MenuItem
              value={`${optionValue ?? ""}`}
              key={`${optionValue ?? ""}`}
              sx={{ height: "45px" }}
            >
              {optionLabel}
            </MenuItem>
          );
        })}
      </BaseSelect>
      {field.error ? <FormHelperText error>{errorText}</FormHelperText> : null}
    </FormControl>
  );
}
