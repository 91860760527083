import { InputAdornment, TextField } from "@mui/material";
import { ChangeEventHandler, useCallback, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import { debounce } from "lodash";
import { Props } from "./types";

const handleSearch = debounce((q, callback) => {
  callback(q ? `?q=${q}` : "");
}, 500);

export function Search(_: Props) {
  const history = useHistory();
  const location = useLocation();
  const [q, setQ] = useState(queryString.parse(location.search).q ?? "");

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setQ(event.currentTarget.value);
      handleSearch(encodeURI(event.currentTarget.value), (search: string) => {
        history.push({
          pathname: location.pathname,
          search,
        });
      });
    },
    [history, location.pathname],
  );

  return (
    <TextField
      autoFocus
      fullWidth
      value={q}
      onChange={handleOnChange}
      placeholder="Search accounts by name or phone number"
      InputProps={{
        "aria-label": "search",
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}
