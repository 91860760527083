import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import difference from "lodash/difference";
import get from "lodash/get";
import intersection from "lodash/intersection";
import isEmpty from "lodash/isEmpty";
import union from "lodash/union";

import { usePreviousDistinct } from "react-use";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";

import BlankState from "components/BlankState";
import Logo from "components/Logo";

function CollectionTable(props) {
  const {
    batchActions,
    blankState,
    collapsedColumns,
    collection,
    columns,
    changePageHandler,
    history,
    label = "Collection Table",
    paginationStyles = {},
    page,
    zebra,
  } = props;
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState([]);
  const useCollapsedColumns = useMediaQuery((theme) => {
    return theme.breakpoints.down("md");
  });

  const visibleColumns =
    Boolean(collapsedColumns) && useCollapsedColumns
      ? collapsedColumns
      : columns;

  const {
    id,
    members = Array.from({ length: 30 }).map((_, i) => {
      return { id: `placeholder-${i + 1}` };
    }),
    totalItems,
    view,
  } = collection;

  const nextId = get(view, ["next"]);
  const hasPagination = Boolean(view);

  // Maintain the previous collection's data to show a skeleton table as the page loads
  const previousTotalItems = usePreviousDistinct(totalItems);
  const previousNextId = usePreviousDistinct(nextId);
  const previousHasPagination = usePreviousDistinct(hasPagination);

  const currentId = id || previousNextId || "";
  const showPagination = hasPagination || previousHasPagination;
  const totalItemsToDisplay = totalItems || previousTotalItems || 0;

  const {
    query: { queryPage, page_size: pageSize = 30 },
  } = queryString.parseUrl(currentId, { parseNumbers: true });

  const memberIds = members.map((member) => {
    return member.id;
  });

  useEffect(() => {
    if (allSelected) {
      setSelected(union(selected, memberIds));
    }
  }, [collection.id]);

  const allVisibleSelected =
    intersection(selected, memberIds).length === memberIds.length;
  const noneSelected = selected.length === 0;
  const partiallySelected = selected.length > 0 && !allVisibleSelected;
  const fullySelected = selected.length > 0 && allVisibleSelected;

  const handleToggleAllVisible = () => {
    if (noneSelected || partiallySelected) {
      setSelected(union(selected, memberIds));
    }
    if (fullySelected) {
      setSelected(difference(selected, memberIds));
    }
    setAllSelected(false);
  };

  const handleToggleSelectAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected(union(selected, memberIds));
    }
    setAllSelected(!allSelected);
  };

  const handleToggleSelection = (memberId) => {
    return () => {
      const nextSelected = selected.includes(memberId)
        ? selected.filter((selectedId) => {
            return selectedId !== memberId;
          })
        : [...selected, memberId];
      setSelected(nextSelected);
      setAllSelected(false);
    };
  };

  const handleChangePage = (event, nextPage) => {
    if (changePageHandler) return changePageHandler(event, nextPage);
    if (page > nextPage) return history.goBack();

    if (nextId) {
      const currentSearchParams = queryString.parse(history.location.search);
      const {
        query: { nextCursor },
      } = queryString.parseUrl(nextId);
      const nextSearch = queryString.stringify({
        ...currentSearchParams,
        cursor: nextCursor,
        page: nextPage,
      });
      return history.push({ ...history.location, search: `?${nextSearch}` });
    }
    return null;
  };
  return (
    <>
      {selected.length > 0 && (
        <Box
          alignItems="center"
          bgcolor="grey.300"
          boxSizing="border-box"
          color="text.primary"
          display="flex"
          height="57px"
          left="52px"
          position="absolute"
          px={2}
          width="calc(100% - 52px)"
          zIndex={3}
        >
          <Box flex="1 1 auto" display="flex" alignItems="center">
            <Typography color="inherit" variant="subtitle2" component="div">
              {allSelected ? totalItemsToDisplay : selected.length} selected
            </Typography>
            <Box ml={2}>
              <Button onClick={handleToggleSelectAll} size="small">
                {allSelected
                  ? "Clear Selection"
                  : `Select all ${totalItemsToDisplay}`}
              </Button>
            </Box>
          </Box>
          <Box flex="0 0 auto">{batchActions(props)}</Box>
        </Box>
      )}
      <Box
        flex="1 1 auto"
        overflow="auto"
        minHeight="0px"
        position="relative"
        aria-label={label}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {Boolean(batchActions) && (
                <TableCell
                  padding="checkbox"
                  sx={{
                    backgroundColor:
                      selected.length > 0 ? "grey.300" : "background.paper",
                  }}
                >
                  <Checkbox
                    color="secondary"
                    indeterminate={partiallySelected}
                    checked={fullySelected}
                    inputProps={{ "aria-label": "Select all visible" }}
                    onClick={handleToggleAllVisible}
                  />
                </TableCell>
              )}
              {visibleColumns.map((column) => {
                return (
                  <TableCell
                    key={column.title}
                    align={column.align}
                    sx={{
                      backgroundColor:
                        selected.length > 0 ? "grey.300" : "background.paper",
                    }}
                  >
                    {column.titleContent || column.title}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {members.map((record) => {
              return (
                <TableRow
                  key={record.id}
                  aria-label="Table Row"
                  data-testid="table-row"
                  hover={!zebra}
                  sx={
                    zebra
                      ? {
                          "&:nth-of-type(even)": {
                            backgroundColor: "action.hover",
                          },
                        }
                      : undefined
                  }
                >
                  {Boolean(batchActions) && (
                    <TableCell
                      padding="checkbox"
                      style={{
                        borderBottom: 0,
                        paddingTop: "2px",
                        paddingBottom: "2px",
                      }}
                    >
                      {record.id.includes("placeholder") ? (
                        <Skeleton
                          variant="rectangular"
                          height="36px"
                          width="36px"
                          style={{ margin: 0 }}
                        />
                      ) : (
                        <Checkbox
                          color="secondary"
                          checked={selected.includes(record.id)}
                          inputProps={{
                            "aria-label": "Select item",
                            "data-testid": "select-item",
                          }}
                          onClick={handleToggleSelection(record.id)}
                        />
                      )}
                    </TableCell>
                  )}
                  {visibleColumns.map((column) => {
                    return (
                      <TableCell
                        key={`${record.id}-${column.title}`}
                        align={column.align}
                        style={{
                          borderBottom: 0,
                          paddingTop: "10px",
                          paddingBottom: "10px",
                        }}
                      >
                        {record.id.includes("placeholder") ? (
                          <Skeleton height="20px" style={{ margin: 0 }} />
                        ) : (
                          column.getTableCellContent(record)
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
      {!isEmpty(collection) &&
        members.length === 0 &&
        (blankState || (
          <BlankState
            image={<Logo color="disabled" />}
            subTitle="No records to display"
          />
        ))}
      {showPagination && (
        <>
          <Divider />
          <TablePagination
            rowsPerPageOptions={[pageSize]}
            component="div"
            count={totalItemsToDisplay}
            rowsPerPage={pageSize}
            page={typeof page === "number" ? page : queryPage}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onPageChange={handleChangePage}
            style={{ flexShrink: 0, width: "100%", ...paginationStyles }}
          />
        </>
      )}
    </>
  );
}

CollectionTable.propTypes = {
  batchActions: PropTypes.func,
  blankState: PropTypes.any,
  collapsedColumns: PropTypes.array,
  collection: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  changePageHandler: PropTypes.func,
  history: PropTypes.object.isRequired,
  label: PropTypes.string,
  paginationStyles: PropTypes.object,
  page: PropTypes.number,
  zebra: PropTypes.bool,
};

export default withRouter(CollectionTable);
