import { Avatar, AvatarProps, useTheme } from "@mui/material";

function BlankAvatar({ sx, ...props }: AvatarProps) {
  const theme = useTheme();

  return (
    <Avatar
      aria-label="Blank avatar"
      sx={{
        height: "2rem",
        width: "2rem",
        ...(typeof sx === "function" ? sx(theme) : sx),
      }}
      {...props}
    >
      \
    </Avatar>
  );
}

export { BlankAvatar };
