import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { actionGenerators, saga, reducer, selectors } from "./state";
import Root from "./components/Root";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";
import { selectCurrentAccount } from "features/EntryPoint/containers/App/selectors";

class ContactFormWrapper extends Component {
  static propTypes = {
    resetContactFormWrapper: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.props.resetContactFormWrapper();
  }

  render() {
    return <Root {...this.props} />;
  }
}

const withConnect = connect((state, props) => {
  return {
    contactFormWrapperContainer: selectors.selectContactFormWrapper(
      state,
      props,
    ),
    currentAccount: selectCurrentAccount(state, props),
  };
}, actionGenerators);

const withReducer = injectReducer({
  key: "contactFormWrapperContainer",
  reducer,
});
const withSaga = injectSaga({ key: "contactFormWrapperContainer", saga });

export default compose(withReducer, withSaga, withConnect)(ContactFormWrapper);
