import { createBrowserHistory } from "history";
import localStorageHelper from "./localStorageHelper";

const history = createBrowserHistory();

export const pageReload = () => {
  window.navigator.splashscreen.show();
  history.push("/");
};

export const setConversationPath = (path) => {
  localStorageHelper.setItem({
    item: "conversationPath",
    value: path,
  });
};

export default history;
