import PropTypes from "prop-types";
import styled from "styled-components";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import AccountSetting from "./AccountSetting";
import { CampaignSettingsValidationSchema } from "formHelpers/validationSchemas";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";

const Description = styled(SettingsDescription)`
  a {
    font-weight: bold;
    color: ${(props) => {
      return props.theme.colors.primary.main;
    }};
  }
`;

export const timeOptions = [
  { label: "", value: "" },
  { label: "1 hour", value: 60 },
  { label: "6 hours", value: 6 * 60 },
  { label: "1 day", value: 24 * 60 },
  { label: "2 days", value: 2 * 24 * 60 },
  { label: "3 days", value: 3 * 24 * 60 },
  { label: "5 days", value: 5 * 24 * 60 },
  { label: "1 week", value: 7 * 24 * 60 },
  { label: "2 weeks", value: 14 * 24 * 60 },
  { label: "4 weeks", value: 28 * 24 * 60 },
];

function ContactFilterSettings(props) {
  const { account } = props;

  const getDescription = () => {
    return (
      <Description>
        <p>
          {`
        When this is configured, contacts will be automatically filtered out of any campaign sent within 
        the organization based off of the last one received. This prevents contacts from receiving multiple 
        campaign messages within a given timeframe.
        `}
        </p>
      </Description>
    );
  };

  const getSwitchRender = () => {
    return () => {
      return function ({ field, form }) {
        return (
          <FormControlLabel
            control={
              <Switch
                {...field}
                checked={field.value}
                onChange={(event) => {
                  const { checked } = event.target;
                  form.setFieldValue(field.name, checked);
                }}
                color="secondary"
              />
            }
            label="Enable Contact Filtering for Organization"
          />
        );
      };
    };
  };

  const getSelectRender = () => {
    return ({ values }) => {
      return function ({ field }) {
        return (
          <TextField
            disabled={!values?.settings?.exclusionTimeEnabled.value}
            {...field}
            select
            variant="outlined"
            SelectProps={{
              native: true,
            }}
            label="Time since previous campaign"
            id="textus-ContactExclusionTime"
            fullWidth
          >
            {timeOptions.map((option) => {
              return (
                <option key={option.label} value={option.value}>
                  {option.label}
                </option>
              );
            })}
          </TextField>
        );
      };
    };
  };

  return (
    <AccountSetting
      {...props}
      description={getDescription()}
      isDisabled={account?.settings?.exclusionTimeEnabled?.disabled}
      settings={[
        {
          default: false,
          name: "exclusionTimeEnabled",
          render: getSwitchRender(),
        },
        {
          default: "",
          name: "exclusionTime",
          render: getSelectRender(),
        },
      ]}
      title="Filter Out Contacts"
      validationSchema={CampaignSettingsValidationSchema}
    />
  );
}

ContactFilterSettings.propTypes = {
  account: PropTypes.object.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
};

export default ContactFilterSettings;
