import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class SalesforceLightningStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /lightning\.force/i.test(this.location?.href);

  getParsers = () => {
    const { href } = this.location;
    const doc = this.parsedHtml;

    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: "div.oneContent.active h1 span.uiOutputText",
      searchTextFunction: () => {
        /* The Detail Page updated in the SF ⚡️Spring Release. We are unsure
        how these releases roll out to customers, so we are adding new a new
        query to find phone numbers in the spring release alongside the
        existing queries. */
        const springReleaseNumbers = Array.from(
          doc.querySelectorAll(
            ".oneContent.active lightning-formatted-phone, .oneContent.active lightning-click-to-dial",
          ),
        ).map(n => n.textContent.trim());
        const headerNumbers = Array.from(
          doc.querySelectorAll(".oneContent.active .uiOutputPhone"),
        ).map(n => n.textContent.trim());
        const activeDetailSection = Array.from(
          doc.querySelectorAll(`[data-component-id="force_detailPanel"]`),
        ).find(n =>
          Boolean(
            Array.from(n.querySelectorAll(".uiOutputText")).find(textNode =>
              textNode.textContent.includes(doc.title.split(" | ")[0]),
            ),
          ),
        );
        const activeDetailSectionNumbers = activeDetailSection
          ? Array.from(
              activeDetailSection.querySelectorAll(".uiOutputPhone"),
            ).map(n => n.textContent.trim())
          : [];
        return [
          ...springReleaseNumbers,
          ...headerNumbers,
          ...activeDetailSectionNumbers,
        ].join(", ");
      },
      canParse: () => !/list/i.test(href),
    });

    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "table.uiVirtualDataTable tbody th",
      containerSelector: "tr",
      canParse: () => /list/i.test(href),
    });

    const singlePageParser = new SingleContactParser({
      doc,
      textSelector: '.test-id__field-value span',
      namesSelector: 'lightning-formatted-name',
      canParse: () =>
        !!doc.querySelector(
          'a.slds-card__header-link.slds-truncate.slds-show--inline-block.uiOutputURL',
        ),
    });

    const multiPageParser = new MultiContactParser({
      doc,
      namesSelector: 'table.uiVirtualDataTable tbody th',
      containerSelector: 'tr',
      canParse: () => !!doc.querySelector('table.slds-table.forceRecordLayout'),
    });

    return [detailPageParser, listPageParser, multiPageParser, singlePageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "SalesforceLightningStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "SalesforceLightningStrategy");
      return null;
    }
  };
}
