import inboundNotificationAudio from "utils/audio/inbound-notification.mp3";

export default () => {
  const audio = new Audio(inboundNotificationAudio);

  /* It is not uncommon for a user to disable audio notifications at the
  browser level even if they have audio notifications turn on in app. Rather
  than flooding HB when this occurs, we are going to catch these errors and for
  now silently ignore them. If in the future, we want to try to let the user
  know about them, we can do that here. For now, that sounds potentially
  problematic b/c it may not be easy to help the user resolve the issue. */
  audio.play().catch(() => {});
};
