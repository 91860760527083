import { Link } from "../components/types";
import stripKeywordId from "./stripKeywordId";

const getKeywordSequenceLink = (links: Link[], keywordId: string) => {
  return links.find((link) => {
    return stripKeywordId(link.keyword.id) === stripKeywordId(keywordId);
  });
};

export default getKeywordSequenceLink;
