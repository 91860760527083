import assert from "assert";
import { userManager } from "@tesseract/core";
import { SequenceValues } from "../hooks/useSequences/types";
import { headers } from "constants/apiConstants";
import type { Account } from "models/Account";

export const createSequence = async (
  currentAccount: Account,
  body: SequenceValues,
): Promise<Response> => {
  const user = await userManager.getUser();

  assert(user?.access_token);

  const url = `/api/v4/${currentAccount.slug}/sequences`;

  const request = new Request(url, {
    method: "POST",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name: body.sequenceName,
      finishedOnReply: body.finishedOnReply === "true", // converts to boolean for BE
      scheduleType: body.scheduleType,
    }),
  });

  return fetch(request);
};
