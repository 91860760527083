const getOptimisticallyUpdatedConversation = (action) => {
  return (conversationId) => {
    switch (action) {
      case "close":
        return {
          id: conversationId,
          currentState: "closed",
        };
      case "reopen":
        return {
          id: conversationId,
          currentState: "open",
        };
      case "star":
        return {
          id: conversationId,
          requestingUserInfo: {
            id: `${conversationId}/user_info`,
            starred: true,
          },
        };
      case "unstar":
        return {
          id: conversationId,
          requestingUserInfo: {
            id: `${conversationId}/user_info`,
            starred: false,
          },
        };
      case "read":
        return {
          id: conversationId,
          requestingUserInfo: {
            id: `${conversationId}/user_info`,
            read: true,
          },
        };
      case "unread":
        return {
          id: conversationId,
          requestingUserInfo: {
            id: `${conversationId}/user_info`,
            read: false,
          },
        };
      default:
        return { id: conversationId };
    }
  };
};

export default getOptimisticallyUpdatedConversation;
