import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import MainView from "./components/MainView";
import { actionGenerators, saga, reducer, selectors } from "./state";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";

class SalesforceCallback extends Component {
  render() {
    return <MainView {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    salesforceCallbackContainer: selectors.selectSalesforceCallback(
      state,
      props,
    ),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "salesforceCallbackContainer",
  reducer,
});
const withSaga = injectSaga({ key: "salesforceCallbackContainer", saga });

export default compose(withReducer, withSaga, withConnect)(SalesforceCallback);
