import { all, put, takeLatest } from "redux-saga/effects";
import { combineReducers } from "redux-immutable";
import * as schema from "schema";
import generateContainerState from "utils/generateContainerState";

import { actionGenerators as contactModalActionGenerators } from "features/ContactModal/state";

const RESET_CONTACT_FORM_WRAPPER =
  "app/ContactFormWrapper/RESET_CONTACT_FORM_WRAPPER";

const resetContactFormWrapper = () => {
  return {
    type: RESET_CONTACT_FORM_WRAPPER,
  };
};

const {
  actionTypes: crudActionTypes,
  actionGenerators: crudActionGenerators,
  sagas,
  reducers,
  selectors,
} = generateContainerState([
  {
    container: "ContactFormWrapper",
    crudActions: ["create", "update"],
    recordType: "contact",
    schema: schema.contact,
  },
]);

const actionTypes = { ...crudActionTypes, RESET_CONTACT_FORM_WRAPPER };
const actionGenerators = { ...crudActionGenerators, resetContactFormWrapper };

function* contactSuccess({ response }) {
  yield put(
    contactModalActionGenerators.setContactModal({
      active: true,
      activeConversation: undefined,
      contact: response,
      form: false,
      partialMatches: undefined,
    }),
  );
}

function* saga() {
  yield all([
    takeLatest(actionTypes.CREATE_CONTACT_REQUEST, sagas.createContactRequest),
    takeLatest(actionTypes.CREATE_CONTACT_SUCCESS, contactSuccess),
    takeLatest(actionTypes.UPDATE_CONTACT_REQUEST, sagas.updateContactRequest),
    takeLatest(actionTypes.UPDATE_CONTACT_SUCCESS, contactSuccess),
  ]);
}

// REDUCERS
const errorCreating = (state = null, action) => {
  switch (action.type) {
    case actionTypes.CREATE_CONTACT_REQUEST:
    case actionTypes.CREATE_CONTACT_SUCCESS:
    case actionTypes.RESET_CONTACT_FORM_WRAPPER:
      return null;
    case actionTypes.CREATE_CONTACT_FAILURE:
      return {
        response: action.response,
        url: action.url,
        params: action.params,
      };
    default:
      return state;
  }
};

const errorUpdating = (state = null, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CONTACT_REQUEST:
    case actionTypes.UPDATE_CONTACT_SUCCESS:
    case actionTypes.RESET_CONTACT_FORM_WRAPPER:
      return null;
    case actionTypes.UPDATE_CONTACT_FAILURE:
      return {
        response: action.response,
        url: action.url,
        params: action.params,
      };
    default:
      return state;
  }
};

const reducer = combineReducers({ ...reducers, errorCreating, errorUpdating });

export { actionTypes, actionGenerators, saga, reducer, selectors };
