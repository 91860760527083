import kebabCase from "lodash/kebabCase";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

function FormikSelect({
  dependentFieldOptions,
  handleChange,
  items,
  label,
  name,
  required = false,
  setFieldValue,
  values,
}) {
  const onChange = ({ target: { value } }) => {
    setFieldValue(name, value);
    if (handleChange) {
      dependentFieldOptions.forEach((field) => {
        return setFieldValue(field, "");
      });
      handleChange(name, value);
    }
  };

  return (
    <Field errorString={<ErrorMessage name={name} />} required={required}>
      {({ errorString, onBlur }) => {
        return (
          <>
            <TextField
              aria-label={`${label} Select Field`}
              fullWidth
              label={label}
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={label}
              required={required}
              select
              value={values[name] || ""}
              variant="outlined"
              inputProps={{
                "data-testid": `${kebabCase(label)}-test`,
              }}
            >
              <MenuItem value="" aria-hidden disabled>
                {label}
              </MenuItem>
              {items.map(({ name: displayValue, slug }) => {
                return (
                  <MenuItem key={slug} value={slug} aria-label={displayValue}>
                    {displayValue}
                  </MenuItem>
                );
              })}
            </TextField>
            <FormHelperText>{errorString}</FormHelperText>
          </>
        );
      }}
    </Field>
  );
}

FormikSelect.propTypes = {
  dependentFieldOptions: PropTypes.array,
  handleChange: PropTypes.any,
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
};

export default FormikSelect;
