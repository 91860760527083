import { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { IconButton, Tooltip, Typography } from "@mui/material";

import { formatInTimeZone } from "date-fns-tz";
import NoteForm from "./NoteForm";
import List from "components/List";
import Avatar from "components/Avatar";
import { DeleteIcon, EditIcon } from "icons";
import { getAccountTimeZone } from "utils/date";
import { account } from "schema";

const Wrapper = styled.div`
  font-size: 1rem;
  width: 100%;
`;

const EmptySection = styled.div`
  padding: 15px 20px;
`;

const ListItem = styled.li`
  font-size: 0.8rem;
  padding: 15px 10px 16px 20px;
  width: 100%;
`;

const ListItemHeader = styled.header`
  align-items: center;
  display: flex;
  margin-bottom: 16px;
`;

const ListItemAvatar = styled.div`
  flex: 0 0 auto;
  margin-right: 10px;
`;

const ListItemText = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
`;

const ListItemAction = styled.div`
  flex: 0 0 40px;
`;

const ListItemContent = styled.div`
  font-size: 0.8rem;
  margin-top: 10px;
  margin-right: 10px;
`;

const NewNoteRow = styled.div`
  padding: 0 20px 20px 20px;
  text-align: right;
`;

export default class Root extends Component {
  static propTypes = {
    contactNoteCollection: PropTypes.object.isRequired,
    contactNoteCollectionId: PropTypes.string.isRequired,
    createContactNoteRequest: PropTypes.func.isRequired,
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    deleteContactNoteRequest: PropTypes.func.isRequired,
    fetchContactNoteCollectionRequest: PropTypes.func.isRequired,
    updateContactNoteRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      noteBeingEdited: undefined,
    };
  }

  handleEdit = (noteId) => {
    return () => {
      this.setState({ noteBeingEdited: noteId });
    };
  };

  handleDelete = (noteId) => {
    return () => {
      const {
        contactNoteCollectionId,
        deleteContactNoteRequest,
        fetchContactNoteCollectionRequest,
      } = this.props;
      deleteContactNoteRequest(noteId, null, {
        successCallback: () => {
          fetchContactNoteCollectionRequest(contactNoteCollectionId, null, {
            successCallback: () => {
              this.setState({ noteBeingEdited: undefined });
            },
          });
        },
      });
    };
  };

  renderNote = (note) => {
    return (
      <Typography variant="caption" color="text.secondary">
        {note.content}
      </Typography>
    );
  };

  renderNoteForm = (note) => {
    const {
      contactNoteCollectionId,
      createContactNoteRequest,
      fetchContactNoteCollectionRequest,
      updateContactNoteRequest,
    } = this.props;
    const { requestUrl, submitHandler } = note
      ? { requestUrl: note.id, submitHandler: updateContactNoteRequest }
      : {
          requestUrl: contactNoteCollectionId,
          submitHandler: createContactNoteRequest,
        };
    return (
      <ListItemContent>
        <NoteForm
          contactNoteCollectionId={contactNoteCollectionId}
          fetchContactNoteCollectionRequest={fetchContactNoteCollectionRequest}
          note={note}
          cancelEdit={this.handleEdit(undefined)}
          requestUrl={requestUrl}
          submitHandler={submitHandler}
        />
      </ListItemContent>
    );
  };

  render() {
    const { currentAccount, contactNoteCollection, currentUser } = this.props;
    const { noteBeingEdited } = this.state;
    const accountTimeZone = getAccountTimeZone(currentAccount);

    return (
      <Wrapper data-testid="notes-section">
        {contactNoteCollection.totalItems === 0 && (
          <EmptySection>There are no notes for this contact</EmptySection>
        )}
        <List>
          {contactNoteCollection.members.map((note) => {
            return (
              <ListItem data-testid="note-list-item" key={note.id}>
                <ListItemHeader>
                  <ListItemAvatar>
                    <Avatar subject={note.user} />
                  </ListItemAvatar>
                  <ListItemText>
                    <Typography variant="body2" color="text.primary">
                      {note.user.name}
                    </Typography>
                    <Typography variant="caption" color="text.secondary">
                      {formatInTimeZone(
                        new Date(note.updatedAt),
                        accountTimeZone,
                        "MM/dd/yyyy hh:mm a z",
                      )}
                    </Typography>
                  </ListItemText>
                  <ListItemAction>
                    {note.user.id === currentUser.id &&
                      (noteBeingEdited === note.id ? (
                        <Tooltip title="Delete Note">
                          <IconButton
                            data-testid="delete-note-button"
                            onClick={this.handleDelete(note.id)}
                            size="large"
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Edit Note">
                          <IconButton
                            data-testid="edit-note-button"
                            onClick={this.handleEdit(note.id)}
                            size="large"
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ))}
                  </ListItemAction>
                </ListItemHeader>
                {noteBeingEdited === note.id
                  ? this.renderNoteForm(note)
                  : this.renderNote(note)}
              </ListItem>
            );
          })}
          {noteBeingEdited === "new" && (
            <ListItem key="new">
              <ListItemHeader>
                <ListItemAvatar>
                  <Avatar subject={currentUser} />
                </ListItemAvatar>
                <ListItemText>
                  <Typography variant="body2" color="text.primary">
                    {currentUser.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {formatInTimeZone(
                      new Date(),
                      accountTimeZone,
                      "MM/dd/yyyy hh:mm a z",
                    )}
                  </Typography>
                </ListItemText>
                <ListItemAction />
              </ListItemHeader>
              {this.renderNoteForm()}
            </ListItem>
          )}
        </List>
        {!noteBeingEdited && (
          <NewNoteRow>
            <Button
              data-testid="create-note-button"
              variant="outlined"
              color="primary"
              onClick={this.handleEdit("new")}
            >
              Create Note
            </Button>
          </NewNoteRow>
        )}
      </Wrapper>
    );
  }
}
