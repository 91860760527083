import { useState } from "react";
import qs from "query-string";

import {
  GetBillingReportIdsParams,
  GetOverviewReportIdsParams,
  GetRouteComponentParams,
  BillingReportId,
  OverviewReportId,
  ReportId,
  UseAnalyticsReturn,
  GetAggregatePlanParams,
} from "../models/AnalyticsModels";
import BillingAnalytics from "../screens/BillingAnalytics";
import OverviewAnalytics from "../screens/Overview";
import { CumulativeBillingAnalytics } from "../screens/CumulativeBillingAnalytics/CumulativeBillingAnalytics";

const useAnalytics = (): UseAnalyticsReturn => {
  const [reportIds, setReportsIds] = useState<ReportId[]>([]);

  const getRouteComponent = ({
    currentAccount,
    currentUser,
    history,
    isSinglePanel,
    reports,
    scheduledReports,
    setScheduledReports,
    showSidebar,
    slug,
    toggleSidebar,
  }: GetRouteComponentParams) => {
    // CUMULATIVE BILLING FEATURE FLAG
    const { featureFlags } = currentAccount;

    const BillingAnalyticsComponent =
      featureFlags.billingPageWithCumulative &&
      currentAccount.hasCumulativePlan &&
      currentAccount.effectiveRole === "admin" &&
      currentAccount.phoneNumbers.length === 0
        ? CumulativeBillingAnalytics
        : BillingAnalytics;

    const AnalyticsComponents = [
      { componentId: "overview", Component: OverviewAnalytics },
      { componentId: "billing", Component: BillingAnalyticsComponent },
    ];
    const { Component } =
      AnalyticsComponents.find(({ componentId }) => {
        return slug === componentId;
      }) ?? {};
    if (Component)
      return (
        <Component
          currentAccount={currentAccount}
          currentUser={currentUser}
          history={history}
          isSinglePanel={isSinglePanel}
          reports={reports}
          scheduledReports={scheduledReports}
          setScheduledReports={setScheduledReports}
          showSidebar={showSidebar}
          reportType={slug}
          toggleSidebar={toggleSidebar}
        />
      );
    return null;
  };

  const getOverviewReportIds = ({
    descendants,
    endDate,
    reports,
    slug,
    startDate,
  }: GetOverviewReportIdsParams): OverviewReportId[] => {
    return reports.members.map(({ slug: reportName }) => {
      return {
        reportName,
        id: qs.stringifyUrl({
          url: `/${slug}/analytics_reports/${reportName}`,
          query: {
            account: `accounts/${slug}`,
            descendants,
            endDate,
            startDate,
          },
        }),
      };
    });
  };

  const getBillingReportIds = ({
    endDate,
    reports,
    slug,
    startDate,
  }: GetBillingReportIdsParams): BillingReportId[] => {
    return reports.members.map(({ slug: reportName }) => {
      return {
        reportName,
        id: qs.stringifyUrl({
          url: `/${slug}/analytics_reports/${reportName}`,
          query: {
            account: `accounts/${slug}`,
            endDate,
            startDate,
            descendants: true,
          },
        }),
      };
    });
  };

  const getAggregatePlanUrl = ({
    endDate,
    startDate,
    slug,
  }: GetAggregatePlanParams): string => {
    return qs.stringifyUrl({
      url: `/${slug}/statistics/aggregate_plan`,
      query: {
        endDate,
        startDate,
      },
    });
  };

  return {
    getBillingReportIds,
    getOverviewReportIds,
    getAggregatePlanUrl,
    getRouteComponent,
    reportIds,
    setReportsIds,
  };
};

export { useAnalytics };
