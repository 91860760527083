import { Component } from "react";
import { compose } from "redux";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class ApiTokens extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default compose(
  withRecord({
    container: "features/Profile/containers/ApiTokens/userApiToken",
    type: "userApiToken",
    actions: ["create", "delete"],
  }),
  withRecord({
    container: "features/Profile/containers/ApiTokens/userApiTokenCollection",
    type: "userApiTokenCollection",
    actions: ["fetch"],
    shape: { members: [] },
  }),
)(ApiTokens);
