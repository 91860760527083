import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Tooltip10dlcNotice from "components/Tooltip10dlcNotice";

const defaultClickHandler = () => {};

function SidebarHeaderButton({
  clickHandler = defaultClickHandler,
  dataTag,
  ariaLabel,
  dataTestId,
  disabled = false,
  icon,
  primary,
  text,
  tooltipText,
}) {
  const variant = primary ? "contained" : "text";
  const visibility = disabled ? "visible" : "hidden";

  return (
    <Tooltip10dlcNotice text={tooltipText} visibility={visibility}>
      {/* Extra div required to get Tooltip to display on a disabled Button */}
      <div style={{ width: "100%" }}>
        <Button
          // className={className}
          color="primary"
          fullWidth
          // disabled={disabled}
          onClick={clickHandler}
          variant={variant}
          {...(dataTag && { "data-product-tour": dataTag })}
          {...(ariaLabel && { "aria-label": ariaLabel })}
          {...(dataTestId && { "data-testid": dataTestId })}
        >
          {icon}
          <span style={{ marginLeft: "10px" }}>{text}</span>
        </Button>
      </div>
    </Tooltip10dlcNotice>
  );
}

SidebarHeaderButton.propTypes = {
  clickHandler: PropTypes.func,
  className: PropTypes.string,
  dataTag: PropTypes.string,
  dataTestId: PropTypes.string,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any.isRequired,
  primary: PropTypes.bool,
  text: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
};

export default SidebarHeaderButton;
