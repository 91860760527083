import { useCallback, useState } from "react";
import { SequencesContact } from "features/Sequences/screens/IndividualSequenceOverview/types";
import {
  FetchSequenceRecipientsResponse,
  SequenceRecipientsPage,
  SequencesContactsQueryParams,
} from "features/Sequences/components/ContactsDataGrid/types";
import { fetchSequenceRecipients } from "features/Sequences/api";
import { formatSequenceContactRows } from "features/Sequences/screens/IndividualSequenceOverview/utils";
import { useCurrentAccount } from "hooks";
import { RowState } from "features/Sequences/components/SequencesContactsTab/types";

function useSequenceRecipients(rowState: RowState, sequenceId: string) {
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState<SequenceRecipientsPage | null>(
    null,
  );

  const { rows, setRows } = rowState;

  const currentAccount = useCurrentAccount();

  const getUpdatedRecipients = (
    currentRows: SequencesContact[],
    newRows: SequencesContact[],
  ) => {
    const currentRowIds = currentRows.map((row) => {
      return row.id;
    });
    return newRows.filter((row) => {
      return !currentRowIds.includes(row.id);
    });
  };

  const getSequenceRecipients = useCallback(
    async (id: string, next?: string, query?: SequencesContactsQueryParams) => {
      setIsLoading(true);
      try {
        const response = await fetchSequenceRecipients(
          currentAccount,
          id,
          next || undefined,
          query,
        );
        const recipient: FetchSequenceRecipientsResponse =
          await response.json();

        const formattedRows = formatSequenceContactRows(recipient.items);
        const updatedRows = getUpdatedRecipients(rows, formattedRows);

        setPagination(recipient.page);
        setRows(query && !next ? formattedRows : [...rows, ...updatedRows]);
        setIsLoading(false);
      } catch (err) {
        throw new Error(`Error fetching sequence recipients: ${err}`);
      }
    },
    [currentAccount, rows, setRows],
  );

  const fetchNextPage = () => {
    if (!pagination) return null;
    return pagination.count !== pagination.to
      ? getSequenceRecipients(sequenceId, pagination.nextUrl)
      : null;
  };

  return {
    isLoadingAllRecipients: isLoading,
    pagination,
    fetchNextPage,
    getSequenceRecipients,
  };
}

export { useSequenceRecipients };
