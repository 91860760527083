export const formatPrivateChannel = (slug: string) => {
  return `private-${slug}@updates`;
};

export const formatConversationChannel = (slug: string) => {
  return `private-v2;conversations-${slug}@updates`;
};

export const formatGlobalChannel = () => {
  const prMatch = document.location.hostname.match(/pr-\d+/);
  return prMatch
    ? `private-${prMatch[0]}-application@global`
    : "private-application@global";
};