export default {
  hugeHeaderFontSize: "44px",
  xLargeHeaderFontSize: "28px",
  largeHeaderFontSize: "22px",
  largeSecondaryHeaderFontSize: "18px",
  messageFontSize: "14px",
  primaryFontSize: "12px",
  primaryHeaderFontSize: "16px",
  primaryListItemFontSize: "13px",
  secondaryFontSize: "11px",
  secondaryHeaderFontSize: "14px",
  tertiaryHeaderFontSize: "12px",
  tinyFontSize: "11px",
  miniButtonFontSize: "9px",
};
