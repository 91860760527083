import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import { differenceInMinutes, format } from "date-fns";

import { RefreshTimerProps } from "./types";

function RefreshTimer({
  lastRefreshTimestamp,
  clickHandler,
}: RefreshTimerProps) {
  const [lastRefreshCopy, setLastRefreshCopy] = useState<string>(
    "Last refresh just now",
  );

  useEffect(() => {
    const refreshTimer = setInterval(() => {
      const timeDiffInMinutes = differenceInMinutes(
        new Date(),
        lastRefreshTimestamp,
      );

      // 0 - 1 minutes
      if (timeDiffInMinutes < 1) {
        setLastRefreshCopy("Last refresh just now");
      }

      // 1 - 5 minutes
      else if (timeDiffInMinutes < 5) {
        setLastRefreshCopy("Last refresh a few minutes ago");
      }

      // 5 - 10 minutes
      else if (timeDiffInMinutes < 10) {
        setLastRefreshCopy("Last refresh 5 minutes ago");
      }

      // 10 - 1440 minutes (24 hours)
      else if (timeDiffInMinutes < 1440) {
        setLastRefreshCopy(
          `Last refresh ${format(lastRefreshTimestamp, "hh:mmaaa")}`,
        );
      }

      // 1440 minutes + (24 hours +)
      else {
        setLastRefreshCopy(
          `Last refresh ${format(lastRefreshTimestamp, "MMMM d, yyyy")}`,
        );
      }
    }, 1000);

    return () => {
      return clearTimeout(refreshTimer);
    };
  }, [lastRefreshTimestamp]);

  return (
    <Box flex="0 0 auto" mr="20px">
      <Typography variant="caption" mr="8px">
        {lastRefreshCopy}
      </Typography>
      <Button variant="outlined" onClick={clickHandler}>
        <RefreshIcon />
      </Button>
    </Box>
  );
}
export { RefreshTimer };
