import { Link } from "@mui/material";
import { getFormattedIntegrationName } from "../utils/getFormattedIntegrationName";
import { getContactSyncId } from "../utils/getContactSyncId";
import { IntegrationIdLinkProps } from "./types";

function IntegrationIdLink({
  integrationData,
  integrationName,
  fromConversation,
}: IntegrationIdLinkProps) {
  const formattedIntegrationName =
    getFormattedIntegrationName(integrationName) ?? "";
  const contactSyncId = getContactSyncId(integrationData) ?? "";

  return (
    <Link
      onClick={() => {}}
      href={integrationData?.Candidate?.link}
      target="_blank"
      underline={integrationData?.Candidate?.link ? "always" : "none"}
      sx={(theme) => {
        return {
          color: integrationData?.Candidate?.link
            ? theme.palette.text.hyperlink
            : "",
          fontSize: "12px",
          fontWeight: 400,
          margin: 0,
          marginLeft: fromConversation ? "4px" : 0,
          overflow: "hidden",
          textAlign: "left",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            cursor: integrationData?.Candidate?.link ? "pointer" : "",
          },
        };
      }}
    >
      {formattedIntegrationName} ID {contactSyncId}
    </Link>
  );
}

export { IntegrationIdLink };
