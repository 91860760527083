import type { FormikContextType, FormikErrors } from "formik";
import { Account as AccountType } from "models/Account";
import { Account } from "features/Accounts";

export type AccountsRaw = Record<string, Partial<Account> | undefined>;

export type FormValues = {
  accounts: Record<string, Partial<Account> | undefined>;
};

export type FormErrors = FormikErrors<FormValues>;

export type Props = FormikContextType<FormValues> & {
  currentAccount: AccountType;
  serverErrors: FormErrors | undefined;
};

export interface FormRef {
  openUsersDrawer: (account: [string, Partial<Account>]) => void;
}
