import type { Contact } from "@tesseract/core";
import { identifyContact } from "@tesseract/core/src/models/Contact";
import { FormikErrors } from "formik";
import { FormValues } from "features/ContactForm";
import { textUsApiV3 } from "services";

export type ValidationErrorResponse = {
  "@type": string;
  "@context": string;
  title: string;
  detail: string;
  invalidParams: unknown[];
  validationErrors: FormikErrors<FormValues>;
};

export const updateContact = (
  contact: Contact.Identity,
  values: FormValues,
): Promise<Response> => {
  return textUsApiV3.put(`/contacts/${identifyContact(contact)}`, values);
};
