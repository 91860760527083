import $ from "jquery";
import getFormattedContactData from "./getFormattedContactData";
import getPhones from "./getPhones";

const ignoredClassNamesRegex = /\.active|\.first|\.even|\.odd/g;

const innerText = node => {
  if (node.querySelectorAll("[data-textus-ignore]").length === 0) {
    return node.innerText || node.innerHTML;
  }
  const copyOfNode = node.cloneNode(true);
  copyOfNode
    .querySelectorAll("[data-textus-ignore]")
    .forEach(ignoredNode => ignoredNode.remove());
  return copyOfNode.innerText || copyOfNode.innerHTML;
};

const getContainer = node =>
  Array.from($(node).parents()).find(parent =>
    /^li$|^tr$|^body$/i.test(parent.tagName),
  );

const getPath = startingNode => endingNode =>
  [startingNode, ...Array.from($(startingNode).parentsUntil(endingNode))]
    .map(node => {
      let result = node.tagName.toLowerCase();
      // if (node.id !== '') { result += `#${node.id}`; }
      if (node.className !== "") {
        result += `.${node.className.trim()}`
          .replace(/\s+/g, ".")
          .replace(ignoredClassNamesRegex, "");
      }
      return result;
    })
    .reverse()
    .join(" ");

const getEndingNode = container => node => {
  switch (container.tagName.toLowerCase()) {
    case "tr":
      return $(node).closest("table");
    case "li":
      return $(node).closest("ul");
    case "aventitycard":
      return $(node).closest(".card-list");
    default:
      return $(node).closest("body");
  }
};

const getStartingNode = container => node =>
  container.tagName.toLowerCase() === "tr" &&
  !["td", "th"].includes(node.tagName.toLowerCase())
    ? node.closest("td", "th")
    : node;

const getPathWithIndex = startingNode => endingNode =>
  `${getPath(startingNode)(endingNode)}:nth-child(${$(startingNode).index() +
    1})`;

const getMatches = context => path => Array.from($(context).find(path));

const addContainer = match => ({ node: match, container: getContainer(match) });

const addPhones = match => getSearchText => ({
  ...match,
  phones: getPhones(getSearchText(match.container)),
});

const includesPhone = match => match.phones.length > 0;

const extractNames = nameContent => {
  if (nameContent != null && nameContent.includes(",")) {
    return nameContent.split(",", 2).reverse();
  }
  if (nameContent != null) {
    return nameContent.split(/\s+/, 2);
  }
  return undefined;
};

const addNames = match => ({
  ...match,
  names: extractNames(innerText(match.node)),
});

const formatContactData = (result, match) => [
  ...result,
  getFormattedContactData(match.names)(match.phones),
];

export const getContactDataFromNameNodes = (
  matches,
  getSearchText = container =>
    Array.from($(container).children())
      .map(child => child.textContent)
      .join(", "),
) =>
  // try {
  matches
    .map(match => addContainer(match))
    .map(match => addPhones(match)(getSearchText))
    .filter(match => includesPhone(match))
    .map(match => addNames(match))
    .reduce((result, match) => formatContactData(result, match), []);
// } catch (error) {
//   Honeybadger.notify(error, "PhoneExtractionError::getContactDataFromNode");
//   return null;
// }

export default (node, getSearchText = container => innerText(container)) => {
  // try {
  const container = getContainer(node);
  const startingNode = getStartingNode(container)(node);
  const endingNode = getEndingNode(container)(node);
  const path = getPathWithIndex(startingNode)(endingNode);
  return getMatches(endingNode)(path)
    .map(match => addContainer(match))
    .map(match => addPhones(match)(getSearchText))
    .filter(match => includesPhone(match))
    .map(match => addNames(match))
    .reduce((result, match) => formatContactData(result, match), []);
  // } catch (error) {
  //   Honeybadger.notify(error, "PhoneExtractionError::getContactDataFromNode");
  //   return null;
  // }
};
