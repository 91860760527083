import { Box, Button, Typography, Theme, useMediaQuery } from "@mui/material";
import { GridActionToolbarProps } from "./types";
import ActionMenu from "./components/ActionMenu";

function GridActionToolbar({
  actions,
  allSelected,
  isMobile,
  position,
  selectedCount,
  selectAllCount,
  setAllSelected,
  setSelectedContacts,
  setSelectedRecords,
}: GridActionToolbarProps) {
  const handleSelectAllClick = () => {
    if (!setAllSelected || !setSelectedRecords) {
      return;
    }
    setAllSelected(!allSelected);
    // setSelectedContacts check required for ActiveConversation flow from Inbox,
    // which does not get passed a setSelectedContacts prop.
    if (allSelected && setSelectedContacts) {
      setSelectedContacts([]);
      setSelectedRecords([]);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      minHeight="3.4rem"
      paddingRight="0.2rem"
      position="absolute"
      left={position.left || "0px"}
      top={position.top || "0px"}
      width={`calc(100% - ${position.left || "0px"})`}
      sx={{
        backgroundColor: "customColors.outboundMessageColor",
        borderBottom: 1,
        borderColor: "grayscale.alphas.black.A16",
      }}
      zIndex="10"
    >
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection={isMobile ? "column" : "row"}
          alignItems="flex-start"
          justifyContent="center"
        >
          <Typography
            aria-label="Number of contacts selected"
            data-testid="number-of-contacts-selected"
            variant="subtitle2"
            fontWeight={500}
            paddingLeft="10px"
            sx={{
              maxWidth: {
                xs: "200px",
                sm: "none",
              },
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {allSelected
              ? `All ${selectAllCount} selected`
              : `${selectedCount} selected`}
          </Typography>
          <Button
            aria-label="Select all conversations total"
            data-testid="select-all-conversations-total"
            onClick={handleSelectAllClick}
            variant="text"
            sx={{
              display: "block",
              paddingLeft: "10px",
              paddingY: "0px",
            }}
          >
            <Typography
              variant="subtitle2"
              fontWeight={500}
              sx={{
                textTransform: "none",
                textDecoration: "underline",
                color: "text.hyperlink",
              }}
            >
              {allSelected ? "Clear selection" : `Select all ${selectAllCount}`}
            </Typography>
          </Button>
        </Box>
        <ActionMenu actions={actions} />
      </Box>
    </Box>
  );
}

export { GridActionToolbar };
