import ConversationExport from "../components/ConversationExport";
import InboxSettings from "../components/InboxSettings";
import is from "utils/is";

import { SettingsSection } from "models/SettingsSection";

export const getInboxSettings = ({
  isAdmin,
}: {
  isAdmin: boolean;
}): SettingsSection => {
  return {
    title: "Inbox Settings",
    path: "inbox",
    settings: [
      {
        title: "Inbox Organization",
        to: `/organization`,
        OptionComponent: InboxSettings,
      },
      isAdmin
        ? {
            title: "Conversation Export",
            to: `/conversation-export`,
            OptionComponent: ConversationExport,
          }
        : null,
    ].filter((value) => {
      return is.truthy(value);
    }),
  };
};
