import { accountKeyMapInverse, userKeyMapInverse } from "./constants";

export interface Query {
  cursor?: string | string[] | null;
  q?: string | string[] | null;
  type?: string;
  items?: number;
  // Search Query: by account slug
  "q[slugOrNameOrProviderPhonesPhoneNumberCont]"?: string;
}

export type ValidationErrorResponse = Readonly<{
  "@type": "ValidationError";
  "@context": string;
  title: "There were errors when validating the request";
  detail: string;
  invalidParams: unknown;
  validationErrors: {
    account: Record<
      string,
      Record<keyof typeof accountKeyMapInverse, string[]> & {
        users: Record<string, Record<keyof typeof userKeyMapInverse, string[]>>;
      }
    >;
  };
}>;
