/* eslint-disable unicorn/prefer-string-replace-all */
/* eslint-disable camelcase */
/* eslint-disable react/jsx-key */
import {
  Alert,
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  Switch,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { generateMessageLoggingText } from "./utils/generateMessageLoggingText";
import { IntegrationDetailsMessageLoggingTabProps } from "./types";
import { SettingsCard, SettingsCardContent } from "features/Settings";
import { InfoOutlineIcon } from "icons";

export function IntegrationDetailsMessageLoggingTab({
  initialIntegrationValues,
  integrationState,
  setUpdatedIntegrationData,
  updatedIntegrationData,
}: IntegrationDetailsMessageLoggingTabProps) {
  const largeScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("lg");
  });

  const { settings: settingsState } = integrationState ?? {};
  const { settings: updatedSettings } = updatedIntegrationData ?? {};

  const renderBullhornPhoneMappingSection = () => {
    const { enabledPhones } = settingsState ?? {};
    const currentEnabledPhones = updatedSettings?.enabled_phones ?? [];
    return (
      <SettingsCardContent
        sx={{
          paddingY: "16px",
        }}
      >
        <Typography variant="h5" gutterBottom>
          Additional phone mapping
        </Typography>
        <Typography variant="body2">
          If messaging candidates use a non-standard phone field, message
          logging can be enabled for that mapping from the list below. The
          standard phone mapping fields (‘Mobile’ and ‘Phone’) are always
          enabled.
        </Typography>
        <Divider sx={{ paddingBotttom: "16px", paddingTop: "8px" }} />
        <Box display="flex" flexDirection="column">
          {enabledPhones?.options.map((option) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentEnabledPhones.includes(option.value)}
                    color="secondary"
                    disabled={!updatedIntegrationData?.active}
                    onChange={() => {
                      setUpdatedIntegrationData({
                        ...updatedIntegrationData,
                        settings: {
                          ...updatedSettings,
                          enabled_phones: currentEnabledPhones.includes(
                            option.value,
                          )
                            ? updatedSettings.enabled_phones.filter(
                                (phone) => phone !== option.value,
                              )
                            : [...currentEnabledPhones, option.value],
                        },
                      });
                    }}
                  />
                }
                label={option.label}
              />
            );
          })}
        </Box>
      </SettingsCardContent>
    );
  };

  const renderCustomizedIndividualNoteTypeSetting = () => {
    return (
      <Box display="flex" flexDirection="column" paddingTop="8px">
        <RadioGroup
          sx={{
            paddingLeft: "8px",
          }}
          value={updatedSettings?.note_type}
        >
          {settingsState?.noteType?.options.map((option) => {
            return (
              <FormControlLabel
                control={
                  <Radio
                    color="secondary"
                    onChange={() => {
                      setUpdatedIntegrationData({
                        ...updatedIntegrationData,
                        settings: {
                          ...updatedSettings,
                          note_type: option.value,
                        },
                      });
                    }}
                  />
                }
                disabled={!updatedIntegrationData?.active}
                key={option.value}
                label={option.label}
                value={option.value}
              />
            );
          })}
        </RadioGroup>
      </Box>
    );
  };

  const renderCustomObjectField = () => {
    return (
      <Box paddingY="8px">
        <FormControlLabel
          control={
            <Switch
              checked={updatedSettings?.custom_object?.active}
              color="secondary"
              disabled={updatedSettings?.batching?.enabled}
              onChange={() => {
                setUpdatedIntegrationData({
                  ...updatedIntegrationData,
                  settings: {
                    ...updatedSettings,
                    custom_object: {
                      ...updatedSettings?.custom_object,
                      active: !updatedSettings?.custom_object?.active,
                    },
                  },
                });
              }}
            />
          }
          disabled={
            updatedSettings?.batching?.enabled ||
            !updatedIntegrationData?.active
          }
          label="Use custom object (optional)"
        />
        <TextField
          key="custom_object"
          disabled={
            updatedSettings?.batching?.enabled ||
            !updatedIntegrationData?.active
          }
          error={
            updatedSettings?.custom_object?.active &&
            !updatedSettings?.custom_object?.entity_name
          }
          fullWidth
          helperText={
            updatedSettings?.custom_object?.active &&
            !updatedSettings?.custom_object?.entity_name
              ? "Required if using custom object"
              : ""
          }
          onChange={(event) => {
            setUpdatedIntegrationData({
              ...updatedIntegrationData,
              settings: {
                ...updatedSettings,
                custom_object: {
                  ...updatedSettings?.custom_object,
                  entity_name: event.target.value,
                },
              },
            });
          }}
          placeholder="Entity name"
          type="text"
          defaultValue={updatedSettings?.custom_object?.entity_name ?? ""}
          variant="outlined"
        />
      </Box>
    );
  };

  const renderBanner = () => {
    return updatedIntegrationData?.active ? (
      <Alert
        severity="info"
        icon={<InfoOutlineIcon />}
        sx={{
          marginBottom: "8px",
        }}
      >
        <Typography variant="body2">
          Message logging remains active unless the integration is paused or
          removed.
        </Typography>
      </Alert>
    ) : (
      <Alert
        severity="warning"
        sx={{
          marginBottom: "8px",
        }}
      >
        <Typography variant="body2">
          Integration paused. Resume to continue logging messages.
        </Typography>
      </Alert>
    );
  };

  const renderBatchActions = () => {
    const { batching } = updatedSettings;
    return (
      <>
        <Divider sx={{ paddingTop: "8px" }} />
        <FormControlLabel
          control={
            <Switch
              checked={batching?.enabled}
              color="secondary"
              onChange={() => {
                setUpdatedIntegrationData({
                  ...updatedIntegrationData,
                  settings: {
                    ...updatedSettings,
                    batching: {
                      ...updatedSettings?.batching,
                      enabled: !updatedSettings?.batching?.enabled,
                    },
                  },
                });
              }}
            />
          }
          disabled={!updatedIntegrationData?.active}
          label="Use batch message logging"
          sx={{ paddingY: "8px" }}
        />
        {batching?.enabled && (
          <>
            {Object.entries(settingsState.batching).map((entry) => {
              /* 
              The below regex is used to convert camelCase to snake_case to match 
              data keys bewtween `settingsState` and `updatedSettings` 
              */
              const dataKey = entry[0]
                .replace(
                  /(([a-z])(?=[A-Z][a-zA-Z])|([A-Z])(?=[A-Z][a-z]))/g,
                  "$1_",
                )
                .toLowerCase();
              const { type, label } = entry[1];
              if (type === "radio") {
                const { options } = entry[1];
                return (
                  <Box display="flex" flexDirection="column">
                    <Typography color="primary" key={dataKey} variant="h6">
                      {label}:
                    </Typography>
                    <RadioGroup
                      sx={{
                        paddingLeft: "8px",
                      }}
                      value={batching[dataKey]}
                    >
                      {options.map((option) => {
                        return (
                          <FormControlLabel
                            control={
                              <Radio
                                color="secondary"
                                disabled={!updatedIntegrationData?.active}
                                onChange={() => {
                                  setUpdatedIntegrationData({
                                    ...updatedIntegrationData,
                                    settings: {
                                      ...updatedSettings,
                                      batching: {
                                        ...updatedSettings?.batching,
                                        [dataKey]: option.value,
                                      },
                                    },
                                  });
                                }}
                              />
                            }
                            key={option.value}
                            label={option.label}
                            value={option.value}
                          />
                        );
                      })}
                    </RadioGroup>
                  </Box>
                );
              }
              if (type === "select") {
                const { options } = entry[1];
                return (
                  <>
                    <Typography
                      color="primary"
                      key={dataKey}
                      sx={{ paddingBottom: "8px" }}
                      variant="h6"
                    >
                      {label}:
                    </Typography>
                    <TextField
                      disabled={!updatedIntegrationData?.active}
                      fullWidth
                      key={dataKey}
                      onChange={(event) => {
                        setUpdatedIntegrationData({
                          ...updatedIntegrationData,
                          settings: {
                            ...updatedSettings,
                            batching: {
                              ...updatedSettings?.batching,
                              [dataKey]: Number(event.target.value)
                                ? Number(event.target.value)
                                : event.target.value,
                            },
                          },
                        });
                      }}
                      placeholder="Value"
                      select
                      SelectProps={{
                        native: true,
                        inputProps: { "aria-label": "Batch Frequency" },
                      }}
                      defaultValue={batching[dataKey] ?? ""}
                      variant="outlined"
                    >
                      {options.map((option) => {
                        return (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </TextField>
                  </>
                );
              }
              return null;
            })}
          </>
        )}
      </>
    );
  };

  return (
    <Box
      component="section"
      display="flex"
      flexDirection={largeScreen ? "row" : "column"}
      justifyContent="space-between"
      width="100%"
    >
      <Box
        textOverflow="ellipsis"
        overflow="hidden"
        minWidth={largeScreen ? "18rem" : "0rem"}
      >
        <Typography variant="h4" gutterBottom>
          Message logging
        </Typography>
      </Box>
      <Box>
        <SettingsCard sx={{ width: "100%", minWidth: "288px" }}>
          <SettingsCardContent
            sx={{
              paddingY: "16px",
            }}
          >
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              paddingBottom="24px"
            >
              <Box display="flex">
                <Typography variant="h5" gutterBottom>
                  TextUs
                </Typography>
                <ArrowForwardIcon
                  sx={{
                    marginX: "8px",
                  }}
                />
                <Typography variant="h5" gutterBottom>
                  {integrationState?.providerName}
                </Typography>
              </Box>
              <Chip
                color={updatedIntegrationData?.active ? "secondary" : "default"}
                label={updatedIntegrationData?.active ? "Active" : "Paused"}
                size="small"
              />
            </Box>
            {renderBanner()}
            <Typography sx={{ paddingTop: "8px" }} variant="h6">
              Individual message logging
            </Typography>
            <Typography variant="body2">
              {generateMessageLoggingText(integrationState)}
            </Typography>
            {settingsState?.noteType &&
              renderCustomizedIndividualNoteTypeSetting()}
            {settingsState?.customObject && renderCustomObjectField()}
            {settingsState?.batching && renderBatchActions()}
          </SettingsCardContent>
        </SettingsCard>
        {settingsState?.enabledPhones && (
          <SettingsCard
            sx={{
              marginBottom: "48px",
              marginTop: "16px",
            }}
          >
            {renderBullhornPhoneMappingSection()}
          </SettingsCard>
        )}
      </Box>
    </Box>
  );
}
