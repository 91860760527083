import BaseCheckbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ReactElement, useCallback } from "react";
import { useField } from "../hooks/useField";
import type { Option, Props, SupportedValueTypes } from "./types";

/**
 * <Checkbox /> element
 *
 * @param {Props}
 * @returns {ReactElement} Native select element
 * @example
 * ```jsx
 * <Checkbox name="name" options={[false, 1]} defaultValue={1} />
 * ```
 */
export function Checkbox<
  Options extends Array<Option<ValueOfOptions>>,
  ValueOfOptions extends SupportedValueTypes,
>({
  name,
  options: [on, off],
  defaultValue,
  formControlLabelProps,
  ...props
}: Props<Options, ValueOfOptions>): ReactElement {
  const [field, setValue] = useField<ValueOfOptions | undefined>(name, {
    defaultValue,
  });

  const onChangeHandler = useCallback(
    (event) => {
      const { checked } = event.target;

      setValue(checked ? on.value : off?.value);
    },
    [setValue, on, off],
  );

  return (
    <FormControlLabel
      {...(formControlLabelProps ?? {})}
      control={
        <BaseCheckbox
          {...{
            ...field,
            ...props,
            onChange: onChangeHandler,
            checked: field.value === on.value,
          }}
        />
      }
      label={
        field.value !== on.value && off?.label !== undefined
          ? off?.label
          : on.label
      }
    />
  );
}
