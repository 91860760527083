import { Component } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { FormikHelpers } from "formik";

const successCallbackCreator = (actions: FormikHelpers<unknown>) => {
  return () => {
    actions.setSubmitting(false);
  };
};

const errorCallbackCreator = (actions: FormikHelpers<unknown>) => {
  return (errors: unknown[]) => {
    actions.setSubmitting(false);
    actions.setErrors(errors);
  };
};

export function withSubmit(
  WrappedComponent: typeof Component<{
    handleSubmit: (values: unknown, actions: FormikHelpers<unknown>) => void;
  }>,
) {
  class WithSubmit extends Component<{
    requestUrl: string;
    isSubmitting: boolean;
    errorSubmitting: unknown;
    submitHandler: (...p: unknown[]) => void;
  }> {
    static propTypes = {
      errorSubmitting: PropTypes.object,
      isSubmitting: PropTypes.bool.isRequired,
      requestUrl: PropTypes.string.isRequired,
      submitHandler: PropTypes.func.isRequired,
    };

    public successCallback: (() => void) | undefined;

    public errorCallback: ((errors: unknown[]) => void) | undefined;

    componentDidUpdate(prevProps: {
      isSubmitting: boolean;
      errorSubmitting: unknown;
    }) {
      const finishedSubmitting =
        prevProps.isSubmitting && !this.props.isSubmitting;
      if (finishedSubmitting && !this.props.errorSubmitting) {
        this.successCallback?.();
      }
      if (finishedSubmitting && this.props.errorSubmitting) {
        this.errorCallback?.(
          get(this.props.errorSubmitting, ["response", "validationErrors"]),
        );
      }
    }

    handleSubmit = (values: unknown, actions: FormikHelpers<unknown>) => {
      const { requestUrl, submitHandler } = this.props;
      this.successCallback = successCallbackCreator(actions);
      this.errorCallback = errorCallbackCreator(actions);
      submitHandler(requestUrl, values);
    };

    render() {
      return (
        <WrappedComponent handleSubmit={this.handleSubmit} {...this.props} />
      );
    }
  }

  return WithSubmit;
}
