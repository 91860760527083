import { identifyAccount } from "@tesseract/core";
import type { Account } from "@tesseract/core";
import { FormValues } from "features/ContactForm";
import { textUsApiV3 } from "services";

export const createContact = (
  account: Account.Like,
  values: FormValues,
): Promise<Response> => {
  return textUsApiV3.post(`/${identifyAccount(account)}/contacts`, values);
};
