import { normalize } from "normalizr";
import { put, select } from "redux-saga/effects";

import * as schema from "schema";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import { selectRecords } from "features/EntryPoint/containers/App/selectors";

function* optimisticUpdateFunc(
  url: string,
  { assignments }: Record<string, any>,
) {
  const records: Record<string, any> = yield select(selectRecords);
  const accountPlan = records.get(url).toJS();
  if (assignments.length < accountPlan.assignments.length) {
    const { account } = accountPlan.assignments.find(
      ({ account: storeId }: { account: string }) => {
        return !assignments.some(
          ({ account: assignmentId }: { account: string }) => {
            return assignmentId === storeId;
          },
        );
      },
    );
    const accountSlug = account.slice(account.lastIndexOf("/") + 1);
    const id = `/navbar/${accountSlug}/site_nav/campaigns`;
    const accountRecord = records.get(id);
    if (!accountRecord) return;
    const accountNavbar = accountRecord.toJS();
    const updatedAccountNavbar = {
      ...accountNavbar,
      uiLink: `/${accountSlug}/campaigns`,
    };
    const { entities } = normalize(updatedAccountNavbar, schema.accountNavbar);
    yield put(updateRecords(entities));
  } else if (assignments.length > accountPlan.assignments.length) {
    const accountPlanAssignments = assignments.filter(
      ({ account: assignmentId }: { account: string }) => {
        return !accountPlan.assignments.some(
          ({ account: storeId }: { account: string }) => {
            return assignmentId === storeId;
          },
        );
      },
    );
    for (const accountAssignment of accountPlanAssignments) {
      const { account } = accountAssignment;
      const accountSlug = account.slice(account.lastIndexOf("/") + 1);
      const id = `/navbar/${accountSlug}/site_nav/campaigns`;
      const accountRecord = records.get(id);
      if (!accountRecord) return;
      const accountNavbar = accountRecord.toJS();
      const updatedAccountNavbar = {
        ...accountNavbar,
        uiLink: `/${accountSlug}/campaigns?pro`,
      };
      const { entities } = normalize(
        updatedAccountNavbar,
        schema.accountNavbar,
      );
      yield put(updateRecords(entities));
    }
  }
}

export { optimisticUpdateFunc };
