export default ({
  accountSlug,
  activeConversationSlug,
  activeConversationFilterSlug,
  queryParams = "",
}) => {
  return {
    activeConversation:
      activeConversationSlug && `/conversations/${activeConversationSlug}`,
    activeConversationFilter:
      activeConversationFilterSlug &&
      `/${accountSlug}/conversation_filters/${decodeURIComponent(
        activeConversationFilterSlug,
      )}${queryParams}`,
  };
};
