import PropTypes from "prop-types";

import { Box, Divider, List, Typography } from "@mui/material";
import ConversationFilterOption from "../../../components/ConversationFilterOption";

import OtherConversationFilters from "./OtherConversationFilters";
import MuiInlineBadge from "components/MuiInlineBadge";

function Root({
  conversationFilterCollection,
  showCampaignFilters,
  showOtherFilters,
  currentAccount,
  campaignFiltersId,
  isSinglePanel,
  otherFiltersId,
  toggleCampaignFilters,
  toggleOtherFilters,
  toggleSidebar,
}) {
  const handleFilterClick = () => {
    if (isSinglePanel) {
      toggleSidebar();
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      height="100%"
      sx={{ overflowY: "auto" }}
    >
      <List disablePadding>
        {conversationFilterCollection.members.map(
          ({ id, slug, title, totalCount, unreadCount }) => {
            const emphasize = unreadCount > 0;
            return (
              <li key={id}>
                <ConversationFilterOption
                  to={`/${currentAccount.slug}/inbox/${slug}`}
                  onClick={handleFilterClick}
                  className={emphasize ? "emphasized" : ""}
                  data-product-tour={`ConversationFilterOption-${slug}`}
                  aria-label={`${slug} conversations`}
                  data-testid={`${slug}-conversations`}
                >
                  <Typography variant="body1">{title}</Typography>
                  {slug === "open" ? (
                    <MuiInlineBadge
                      badgeContent={unreadCount}
                      color="error"
                      letterColor="customColors.badgeText"
                    />
                  ) : (
                    <MuiInlineBadge
                      badgeContent={totalCount}
                      classes={{ badge: "total-count-badge" }}
                    />
                  )}
                </ConversationFilterOption>
              </li>
            );
          },
        )}
      </List>
      <Divider />
      {otherFiltersId && (
        <OtherConversationFilters
          conversationFilterCollectionId={otherFiltersId}
          emptyText="No other users"
          isActive={showOtherFilters}
          handleFilterClick={handleFilterClick}
          title="Assigned to others"
          toggleShow={toggleOtherFilters}
        />
      )}
      <Divider />
      {campaignFiltersId && (
        <OtherConversationFilters
          conversationFilterCollectionId={campaignFiltersId}
          emptyText="No recent campaigns"
          isActive={showCampaignFilters}
          handleFilterClick={handleFilterClick}
          title="Campaigns"
          toggleShow={toggleCampaignFilters}
        />
      )}
    </Box>
  );
}

Root.propTypes = {
  campaignFiltersId: PropTypes.string,
  conversationFilterCollection: PropTypes.object.isRequired, // withRecord
  currentAccount: PropTypes.object.isRequired, // withConnect
  isSinglePanel: PropTypes.bool.isRequired, // withSizes
  otherFiltersId: PropTypes.string,
  showCampaignFilters: PropTypes.bool.isRequired, // withConnect
  showOtherFilters: PropTypes.bool.isRequired, // withConnect
  toggleCampaignFilters: PropTypes.func.isRequired, // withConnect
  toggleOtherFilters: PropTypes.func.isRequired, // withConnect
  toggleSidebar: PropTypes.func,
};

export default Root;
