import { endOfDay, format, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

export const tzDateRegex =
  /\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[1-2]\d|3[0-1])T(?:[0-1]\d|2[0-3]):[0-5]\d:[0-5]\d(?:\.\d+|)(?:Z|(?:\+|-)(?:\d{2}):?(?:\d{2}))/;

export const sendFrequency = [
  { name: "Daily (Mon-Sun)", slug: "daily" },
  {
    name: "Weekly",
    slug: "weekly",
  },
  {
    name: "Biweekly",
    slug: "biweekly",
  },
  {
    name: "Monthly",
    slug: "monthly",
  },
  {
    name: "Yearly",
    slug: "yearly",
  },
];

export const sendFrequencyValues = {
  daily: "FREQ=DAILY",
  weekly: "FREQ=WEEKLY",
  biweekly: "FREQ=WEEKLY;INTERVAL=2",
  monthly: "FREQ=MONTHLY",
  yearly: "FREQ=YEARLY",
};

export const dayToSend = {
  daily: [{ name: "Daily (Mon-Sun)", slug: "BYDAY=SU,MO,TU,WE,TH,FR,SA" }],
  weekly: [
    { name: "Monday", slug: "BYDAY=MO" },
    { name: "Tuesday", slug: "BYDAY=TU" },
    { name: "Wednesday", slug: "BYDAY=WE" },
    { name: "Thursday", slug: "BYDAY=TH" },
    { name: "Friday", slug: "BYDAY=FR" },
    { name: "Saturday", slug: "BYDAY=SA" },
    { name: "Sunday", slug: "BYDAY=SU" },
  ],
  biweekly: [
    { name: "Monday", slug: "BYDAY=MO" },
    { name: "Tuesday", slug: "BYDAY=TU" },
    { name: "Wednesday", slug: "BYDAY=WE" },
    { name: "Thursday", slug: "BYDAY=TH" },
    { name: "Friday", slug: "BYDAY=FR" },
    { name: "Saturday", slug: "BYDAY=SA" },
    { name: "Sunday", slug: "BYDAY=SU" },
  ],
  monthly: [
    { name: "1st of the Month", slug: "BYMONTHDAY=1" },
    { name: "Last of the Month", slug: "BYMONTHDAY=-1" },
  ],
  yearly: [
    { name: "1st of the Year", slug: "BYYEARDAY=1" },
    { name: "Last of the Year", slug: "BYYEARDAY=-1" },
  ],
};

export const formatTimeValues = (frequency, sendDay) => {
  if (!frequency || !sendDay) return {};
  const formattedSendFrequency = sendFrequency.find(({ slug }) => {
    return slug === frequency;
  })?.name;
  const formattedDayToSend = dayToSend[frequency].find(({ slug }) => {
    return slug === sendDay;
  })?.name;
  return { formattedSendFrequency, formattedDayToSend };
};

export const formatValues = (schedule) => {
  if (!schedule) return {};
  const [frequency, sendDate, sendHour, sendMinute] = schedule
    .split(/;BY|;UNTIL/)
    .map((param) => {
      return param.split(/=(.+)/)[1];
    });
  const frequencyValue = Object.keys(sendFrequencyValues).find((key) => {
    return sendFrequencyValues[key] === `FREQ=${frequency}`;
  });
  const formattedFrequency = sendFrequency.find(({ slug }) => {
    return slug === frequencyValue;
  })?.name;
  const formattedSendDate = dayToSend[frequencyValue].find(({ slug }) => {
    return (
      slug === `BYDAY=${sendDate}` ||
      slug === `BYMONTHDAY=${sendDate}` ||
      slug === `BYYEARDAY=${sendDate}`
    );
  })?.name;
  const parsedHour = Number.parseInt(sendHour, 10);
  const parsedMinute = sendMinute.replaceAll(/[^0-9]/gi, "");
  const period = parsedHour >= 12 ? "PM" : "AM";
  const checkForMidnight = (hour) => {
    return hour === 0 ? hour + 12 : hour;
  };
  const formattedHour =
    parsedHour > 12 ? parsedHour - 12 : checkForMidnight(parsedHour);
  const formattedTime = `${formattedHour}:${parsedMinute} ${period}`;

  const endDate = schedule.split("UNTIL=")[1];
  const formattedEndDate = endDate
    ? format(parseISO(endDate), "EEEE, MMMM d, yyyy")
    : "N/A";

  return {
    formattedEndDate,
    formattedFrequency,
    formattedSendDate,
    formattedTime,
  };
};

export const getCampaignSchedule = ({
  campaignEndDate,
  runsIndefinitely,
  dayToSend: sendDate,
  sendFrequency: frequency,
  sendTime,
}) => {
  const getFormattedHour = (hour, period) => {
    const parsedHour = Number.parseInt(hour, 10);
    if (period === "PM" && parsedHour < 12) return parsedHour + 12; // 13-23, for PM
    if (period === "AM" && parsedHour === 12) return parsedHour - 12; // 0, for midnight
    return hour; // 1-12, for AM and noon
  };
  const until = runsIndefinitely
    ? ""
    : `UNTIL=${format(
        zonedTimeToUtc(endOfDay(new Date(campaignEndDate)), "UTC"),
        "yyyyMMdd'T'HHmmss'Z'",
      )}`;
  const [hour, min, period] = sendTime.match(/\d+|\w+/g);
  const formattedHour = getFormattedHour(hour, period);
  const byHour = `BYHOUR=${formattedHour}`;
  const byMinute = `BYMINUTE=${min}`;
  return `${sendFrequencyValues[frequency]};${sendDate};${byHour};${byMinute};${until}`;
};
