import { textUsApiV3 } from "../../../services";
import { GetConversationByPhoneNumberResponse } from "../interfaces/GetNewConversationByPhoneResponse";

interface Props {
  phoneNumber: string;
  accountSlug: string;
}

const getConversationByPhoneNumberApi = async ({
  phoneNumber,
  accountSlug,
}: Props): Promise<GetConversationByPhoneNumberResponse> => {
  const result = await textUsApiV3.get(
    `/${accountSlug}/conversations/${phoneNumber}`,
  );
  const response: GetConversationByPhoneNumberResponse = await result.json();
  return response;
};

export { getConversationByPhoneNumberApi };
