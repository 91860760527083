import { useState } from "react";
import { SelectChangeEvent } from "@mui/material";
import { SnackbarKey, SnackbarMessage, useSnackbar } from "notistack";
import { updateSequenceSettings } from "features/Sequences/api/updateSequenceSettings";
import { useCurrentAccount } from "hooks";
import { DELIVERY_WINDOW_OPTIONS } from "features/Sequences/components/SettingsTabContent/constants";
import {
  RadioOptions,
  SequenceFormValues,
} from "features/Sequences/components/SettingsTabContent/types";
import { Sequence } from "models/Sequence";
import { Snackbar } from "components/Snackbar";

export const useSequenceSettings = (
  sequence: Sequence,
  refreshPage: () => void,
) => {
  const currentAccount = useCurrentAccount();
  const { enqueueSnackbar } = useSnackbar();

  const { anytime, restricted } = DELIVERY_WINDOW_OPTIONS;

  const initialSelection = sequence.settings?.deliveryWindow
    ? restricted
    : anytime;

  const [radioSelection, setRadioSelection] =
    useState<RadioOptions>(initialSelection);

  const handleChangeRadio = (event: SelectChangeEvent) => {
    setRadioSelection(event.target.value as RadioOptions);
  };

  const handleSubmit = async (values: SequenceFormValues) => {
    const { startDay, startTime, endDay, endTime } =
      values.settings?.deliveryWindow ?? {};

    try {
      await updateSequenceSettings(currentAccount, sequence.id, {
        name: values.sequenceName,
        settings:
          radioSelection === anytime
            ? {}
            : {
                deliveryWindow: {
                  startDay,
                  startTime,
                  endDay,
                  endTime,
                },
              },
      });

      enqueueSnackbar("Successfully updated sequence settings.", {
        content: (key: SnackbarKey, message: SnackbarMessage) => {
          return <Snackbar id={key} message={message} variant="info" />;
        },
      });
    } catch (error) {
      enqueueSnackbar(
        "There was an error updating sequence settings. Please try again.",
        {
          content: (key: SnackbarKey, message: SnackbarMessage) => {
            return <Snackbar id={key} message={message} variant="error" />;
          },
        },
      );
      throw new Error(`Error updating sequence settings: ${error}`);
    } finally {
      refreshPage();
    }
  };

  return {
    currentAccount,
    radioSelection,
    handleChangeRadio,
    handleSubmit,
  };
};
