import get from "lodash/get";
import { useState } from "react";
import ContactCollection from "./ContactCollection";
import withRecord from "higherOrderComponents/withRecord";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";

function UnwrappedContactFilter(props) {
  const [pages, setPages] = useState([
    get(props, ["contactFilter", "contacts"]),
  ]);

  const [currentPage, setCurrentPage] = useState(0);

  const handleSearch = (value) => {
    const searchTerm = value ? `?q=${fixedEncodeURIComponent(value)}` : "";
    const contactCollectionId = `${get(props, [
      "contactFilter",
      "contacts",
    ])}${searchTerm}`;
    setPages([contactCollectionId]);
    setCurrentPage(0);
  };

  return (
    <ContactCollection
      contactCollectionId={pages[currentPage]}
      currentPage={currentPage}
      pages={pages}
      setCurrentPage={setCurrentPage}
      setPages={setPages}
      handleSearch={handleSearch}
      {...props}
    />
  );
}

export default withRecord({
  actions: ["fetch"],
  container: "features/Groups/pages/Group/ContactFilter/contactFilter",
  shape: {},
  type: "contactFilter",
})(UnwrappedContactFilter);
