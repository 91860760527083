import { Component } from "react";
import PropTypes from "prop-types";

import getNestedId from "utils/getNestedId";
import getPaginatedId from "utils/getPaginatedId";
import NewCampaignContactCollection from "features/Campaigns/containers/NewCampaignContactCollection";

export default class Root extends Component {
  static propTypes = {
    contactFilter: PropTypes.object.isRequired,
  };

  render() {
    const { contactFilter } = this.props;
    const baseContactCollectionId = getNestedId(contactFilter, "contacts");
    const contactCollectionId = getPaginatedId(baseContactCollectionId);
    return (
      <NewCampaignContactCollection
        contactFilter={contactFilter}
        contactCollectionId={contactCollectionId}
        {...this.props}
      />
    );
  }
}
