import { useCallback, useState } from "react";
import { fetchSequenceSteps } from "features/Sequences/api";
import { Account as AccountType } from "models/Account";
import { SequenceStep } from "models/SequenceStep";
import { useLoading } from "hooks";

export const useSequenceSteps = (currentAccount: AccountType) => {
  const { isLoading, startLoading, stopLoading } = useLoading();

  const [sequenceSteps, setSequenceSteps] = useState<SequenceStep[]>([]);

  const getSequenceSteps = useCallback(
    async (sequenceId: string) => {
      startLoading();
      const response = await fetchSequenceSteps(currentAccount, sequenceId);
      const steps: SequenceStep[] = await response.json();

      setSequenceSteps(steps);
      stopLoading();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentAccount],
  );

  const noSteps = sequenceSteps.length === 0;

  const stepsComplete =
    Date.now() >
    Date.parse(sequenceSteps[sequenceSteps.length - 1]?.absoluteTime);

  return {
    noSteps,
    isLoadingSteps: isLoading,
    sequenceSteps,
    stepsComplete,

    getSequenceSteps,
    startLoadingSteps: startLoading,
    stopLoadingSteps: stopLoading,
  };
};
