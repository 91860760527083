import assert from "assert";
import { userManager } from "@tesseract/core";
import { headers } from "constants/apiConstants";
import { Account as AccountType } from "models/Account";

export const fetchSearchContacts = async (
  currentAccount: AccountType,
  next: string | null,
  searchTerm?: string,
): Promise<Response> => {
  const user = await userManager.getUser();
  assert(user?.access_token);

  const url =
    next ??
    `/${currentAccount.slug}/contact_filters/all/contacts?q=${searchTerm}`;

  const request = new Request(url, {
    method: "GET",
    headers: {
      ...headers,
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
    },
  });

  return fetch(request);
};
