import { MouseEvent, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  Button,
  ButtonGroup,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { IndividualSequenceButtonProps } from "props/IndividualSequenceButtonProps";
import { SEQUENCE_STATES } from "features/Sequences/screens/IndividualSequenceOverview/constants";
import { renderTooltipTitle } from "features/Sequences/screens/IndividualSequenceOverview/utils";

function IndividualSequenceButton({
  selectedTab,
  sequenceHasNoSteps,
  sequence,
  stepsComplete,
  text,
  handleArchiveSequenceClick,
  handleDuplicateSequenceClick,
  toggleAddStepDialog,
  toggleEnrollDrawer,
}: IndividualSequenceButtonProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { inactive, archive } = SEQUENCE_STATES;

  const sequenceIsArchived = sequence.state === archive;

  const handleArrowClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    return selectedTab === 0 ? toggleAddStepDialog() : toggleEnrollDrawer();
  };

  const isOverviewTab = selectedTab === 0;
  const isContactsTab = selectedTab === 1;

  const enrollmentDisabled =
    sequenceHasNoSteps || sequenceIsArchived || sequence.state === inactive;

  const buttonDisabled =
    (isContactsTab && enrollmentDisabled) ||
    (isOverviewTab && sequence.contactCount > 0) ||
    stepsComplete;

  const sequenceButton = (
    <Button
      aria-label={`add-${text}-button`}
      onClick={handleClick}
      size="large"
      startIcon={<AddIcon />}
      data-testid={`add-${text.toLowerCase()}-button`}
      disabled={buttonDisabled}
    >
      {text}
    </Button>
  );

  // Prevents display of button until data is loaded.
  if (sequence.state === "") {
    return null;
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginRight="20px"
    >
      {sequenceIsArchived ? (
        <Button
          onClick={() => {
            handleDuplicateSequenceClick();
          }}
          variant="outlined"
          data-testid="duplicate-sequence"
        >
          Duplicate
        </Button>
      ) : (
        <>
          <ButtonGroup variant="contained">
            {isContactsTab ? (
              <Tooltip title={renderTooltipTitle(sequence)}>
                <span>{sequenceButton}</span>
              </Tooltip>
            ) : (
              sequenceButton
            )}
            {isOverviewTab ? (
              <Button
                data-testid="open-sequence-arrow-menu"
                size="small"
                onClick={handleArrowClick}
              >
                <KeyboardArrowDownIcon />
              </Button>
            ) : null}
          </ButtonGroup>
          <Menu
            data-testid="view-sequence-arrow-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            slotProps={{
              root: {
                slotProps: {
                  backdrop: {
                    sx: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  },
                },
              },
            }}
          >
            <MenuItem
              data-testid="enroll-contacts"
              disabled={enrollmentDisabled}
              onClick={() => {
                toggleEnrollDrawer();
                handleClose();
              }}
            >
              Enroll contacts
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDuplicateSequenceClick();
                handleClose();
              }}
              data-testid="duplicate-sequence"
            >
              Duplicate sequence
            </MenuItem>
            {!sequenceIsArchived ? (
              <MenuItem
                onClick={() => {
                  handleArchiveSequenceClick();
                  handleClose();
                }}
                data-testid="menu-archive-sequence"
              >
                Archive sequence
              </MenuItem>
            ) : null}
          </Menu>
        </>
      )}
    </Box>
  );
}
export { IndividualSequenceButton };
