import { useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  Theme,
  useMediaQuery,
} from "@mui/material";
import CampaignMessage from "./CampaignMessage";
import { CampaignPreviewProps } from "./types";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";

import AttachmentPreview from "features/Campaigns/components/AttachmentPreview";
import { LinkIcon } from "icons";
import { DialogHeader } from "components/DialogHeader";

function CampaignPreview({
  campaign,
  registeredLinkIncluded,
}: CampaignPreviewProps) {
  const [open, setModalState] = useState(false);
  const { title } = campaign;

  const fullScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("md");
  });

  const attachments = campaign?.attachments?.members ?? [];

  return (
    <Box>
      <Button
        aria-label={`Open ${title} Campaign Message`}
        onClick={() => {
          return setModalState(!open);
        }}
        sx={{ fontSize: "1rem", mx: 4 }}
      >
        View Message
      </Button>
      <Modal
        ariaLabel={`${title} Campaign Message`}
        closeModal={() => {
          return setModalState(!open);
        }}
        fullScreen={fullScreen}
        transitionIn={open}
      >
        <DialogHeader
          title={title ?? ""}
          onClose={() => {
            return setModalState(!open);
          }}
        />
        <ModalContent noPadding>
          {registeredLinkIncluded ? (
            <>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                width="100%"
                margin="0.25rem"
              >
                <Chip
                  color="primary"
                  icon={<LinkIcon />}
                  label="Shortened Link"
                  size="small"
                  sx={{
                    fontWeight: 700,
                    maxWidth: "100%",
                  }}
                />
              </Box>
              <Divider />
            </>
          ) : null}
          <Box
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
            margin="1rem"
          >
            {attachments?.length > 0 ? (
              <AttachmentPreview attachment={attachments[0]} />
            ) : null}
            <CampaignMessage campaign={campaign} />
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CampaignPreview;
