import { useCallback, useEffect, useState } from "react";
import { Account } from "@tesseract/core";
import { fetchAccount } from "../api";
import { subscribeToAccount } from "../events/accountUpdated";

/**
 * States
 */
const states = Object.freeze({
  waiting: "waiting",
  loading: "loading",
  error: "error",
  done: "done",
});

/**
 * Hook to fetch account.
 */
export const useAccount = (accountId: Account.Identity) => {
  /**
   * Account
   */
  const [account, setAccount] = useState<Account.Raw | undefined>(undefined);

  /**
   * Current state
   */
  const [state, setState] = useState<(typeof states)[keyof typeof states]>(
    states.waiting,
  );

  /**
   * Error
   */
  const [error, setError] = useState<Error | undefined>(undefined);

  const load = useCallback(() => {
    let ignore = false;

    setState(states.loading);

    setError(undefined);

    const controller = new AbortController();

    fetchAccount(accountId, {
      signal: controller.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then(
        (
          responseData:
            | { id: string; members: [Account.Raw]; totalItems: 1 }
            | Account.Raw,
        ) => {
          if (!ignore) {
            setAccount(
              "members" in responseData
                ? responseData.members[0]
                : responseData,
            );
            setState(states.done);
          }

          return responseData;
        },
      )

      .catch((error_) => {
        setState(states.error);
        setError(error_);
      });

    return () => {
      controller.abort();

      ignore = true;
    };
  }, [accountId]);

  /**
   * Load account
   */
  useEffect(() => {
    return load();
  }, [accountId, load]);

  /**
   * Account updated
   */
  useEffect(() => {
    const unsubscribe = subscribeToAccount((updatedAccount) => {
      if (updatedAccount.id === accountId) {
        setAccount(updatedAccount);
        setState(states.done);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [accountId, load]);

  return {
    error,
    state,
    account,
  };
};
