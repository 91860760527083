import { useEffect, useState } from "react";
import { Breadcrumbs, Typography } from "@mui/material";
import { fetchAutomationKeyword } from "features/Automation/api/fetchAutomationKeyword";

import PageHeader from "components/Page/PageHeader";
import PageContent from "components/Page/PageContent";
import { KeywordProps } from "features/Keywords/types";

function Keyword({
  currentAccount,
  keywordId,
  isSinglePanel,
  showSidebar,
  toggleSidebar,
}: KeywordProps) {
  const [keyword, setKeyword] = useState(null);
  useEffect(() => {
    const handleFetchAutomationKeywordById = async () => {
      const response = await fetchAutomationKeyword(currentAccount, keywordId);
      const json = await response.json();

      setKeyword(json);
    };
    handleFetchAutomationKeywordById().catch((error) => {
      console.error(error);
    });
  }, [keywordId, currentAccount]);

  return (
    <PageContent isSinglePanel={isSinglePanel} showSidebar={showSidebar}>
      <PageHeader
        title={
          <Breadcrumbs>
            <Typography>Keywords</Typography>
            <Typography color="text.hyperlink">{keyword?.keyword}</Typography>
          </Breadcrumbs>
        }
        toggleSidebar={toggleSidebar}
      />
    </PageContent>
  );
}

export default Keyword;
