import type { SavedReply } from "@tesseract/core";
import { textUsApiV3 } from "services";

function updateSavedReply(
  url: `/saved_replies/${string}`,
  values: SavedReply.FormValues,
) {
  return textUsApiV3.patch(url, values);
}

export { updateSavedReply };
