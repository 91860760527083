import { Component } from "react";
import ErrorPage from "components/ErrorPage";

export default class NotFoundPage extends Component {
  render() {
    return (
      <ErrorPage
        title="Uh oh! Page not found"
        subTitle="Please check your url and try again"
      />
    );
  }
}
