import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";

// ACTION TYPES
const SET_EXTENSION_PROMPTS = "app/ExtensionPrompts/SET_EXTENSION_PROMPTS";
const actionTypes = { SET_EXTENSION_PROMPTS };

// ACTION GENERATORS
const setExtensionPrompts = (payload) => {
  return {
    type: SET_EXTENSION_PROMPTS,
    payload,
  };
};

const actionGenerators = { setExtensionPrompts };

// REDUCER
const substate = (state = fromJS({ type: undefined, props: {} }), action) => {
  switch (action.type) {
    case actionTypes.SET_EXTENSION_PROMPTS:
      return state.merge(action.payload);
    default:
      return state;
  }
};
const reducer = combineReducers({ substate });

// SELECTORS
const selectExtensionPromptsContainer = (state) => {
  return state.get("extensionPromptsContainer").toJS();
};
const selectors = { selectExtensionPromptsContainer };

export { actionTypes, actionGenerators, reducer, selectors };
