import PropTypes from "prop-types";
import parseISO from "date-fns/parseISO";
import { getNiceLongDate } from "utils/date";

/**
 * For PageHeader :)
 * @type {import('./types').RendersNiceFileName}
 */
export function FullFileLabel({
  contactImport: { createdAt, recordCount, originalFilename },
}) {
  const createdAtDate = parseISO(createdAt);

  const niceDate = getNiceLongDate(createdAtDate);
  // const shortHandUsDate = getUsShortHandDate(createdAtDate);

  return (
    <>
      {/* foo.csv - 12/12/2022 10:12pm - 2 Rows */}
      {originalFilename && `${originalFilename} - `}
      <span
        title={
          !originalFilename
            ? `(Sorry, file name not recorded at time of upload)`
            : ""
        }
      >
        {/* Ideally, render shortHandUsDate on smaller screens */}
        {niceDate}
      </span>{" "}
      {`- ${recordCount} Rows`}
    </>
  );
}

FullFileLabel.propTypes = {
  contactImport: PropTypes.shape({
    createdAt: PropTypes.string.isRequired,
    recordCount: PropTypes.number.isRequired,
    originalFilename: PropTypes.string,
  }).isRequired,
};
