import { all, takeEvery, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";

import camelCase from "lodash/camelCase";
import get from "lodash/get";
import snakeCase from "lodash/snakeCase";
import startCase from "lodash/startCase";
import toUpper from "lodash/toUpper";

import * as schema from "schema";
import { selectRecords } from "features/EntryPoint/containers/App/selectors";
import deepEqualSelector from "utils/deepEqualSelector";
import denormalizeAccountHierarchy from "utils/denormalizeAccountHierarchy";
import denormalizeWithShape from "utils/denormalizeWithShape";
import generateContainerState from "utils/generateContainerState";

/**
 * Retrieves the state object for a higher order component with record.
 *
 * @param {Object} options - The options for configuring the state.
 * @param {Array} options.actions - The actions to be performed.
 * @param {string} options.type - The type of the record.
 * @param {string} options.container - The container name.
 * @param {Object} options.shape - The shape of the record.
 * @param {boolean} options.multiple - Indicates if multiple records are involved.
 * @returns {Object} - The state object containing action generators, action types, saga, and selectors.
 */
const getState = ({ actions, type, container, shape, multiple }) => {
  const actionKeys = actions.map((action) => {
    return typeof action === "string" ? action : action.type;
  });

  const { actionTypes, actionGenerators, sagas } = generateContainerState([
    {
      container,
      crudActions: actionKeys,
      idPropsVar: `${type}Id`,
      recordType: type,
      schema: schema[type],
      actions,
    },
  ]);

  const takeFunction = multiple ? takeEvery : takeLatest;

  function* saga() {
    yield all(
      actionKeys.map((action) => {
        return takeFunction(
          actionTypes[
            `${toUpper(snakeCase(action))}_${toUpper(snakeCase(type))}_REQUEST`
          ],
          sagas[camelCase(`${action}${startCase(type)}Request`)],
        );
      }),
    );
  }

  const selectRecordId = (state, props) => {
    return get(props, `${type}Id`);
  };
  const selectRecord = deepEqualSelector(
    createSelector(selectRecords, selectRecordId, (records, id) => {
      if (!records.get(id) || typeof records.get(id) === "string") return {};
      if (type === "accountHierarchy") {
        return denormalizeAccountHierarchy({ id, records: records.toJS() });
      }
      return denormalizeWithShape({ id, records: records.toJS(), shape });
    }),
  );

  const selectors = { selectRecord };

  return { actionGenerators, actionTypes, saga, selectors };
};

export default getState;
