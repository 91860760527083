import { Account } from "@tesseract/core";
import { textUsApiV4 } from "services";

export const fetchAutomationKeywords = (
  currentAccount: Account.Raw,
  queryString: string = "",
): Promise<Response> => {
  return textUsApiV4.get(
    `/${currentAccount.slug}/automation_keywords?${queryString}`,
  );
};
