import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { closeSnackbar } from "notistack";
import Button from "@mui/material/Button";

function ViewGroupButton({ groupPathname }) {
  return (
    <Button
      color="inherit"
      component={Link}
      onClick={() => {
        return closeSnackbar();
      }}
      to={groupPathname}
    >
      View Group
    </Button>
  );
}

ViewGroupButton.propTypes = {
  groupPathname: PropTypes.string.isRequired,
};

export default ViewGroupButton;
