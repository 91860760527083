import PropTypes from "prop-types";
import { styled } from "@mui/material";
import Box from "@mui/material/Box";
import getFileThumbnail from "utils/getFileThumbnail";

const StyledFigure = styled("figure")({});

const StyledFigCaption = styled("figcaption")(({ theme }) => {
  return {
    fontSize: theme.typography.body1,
    color: theme.palette.primary.main,
    textAlign: "center",
  };
});

const StyledImage = styled("img")({});

function AttachmentPreview({ attachment }) {
  const { contentType, originalFilename } = attachment;

  const isRichMedia = !contentType.startsWith("image");
  const src = attachment ? getFileThumbnail(attachment) : "";
  const displayedFilename = isRichMedia ? originalFilename : null;

  return (
    <Box position="relative">
      <StyledFigure
        scrollSnapTypeX={(theme) => {
          return isRichMedia
            ? {
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                overflowWrap: "anywhere",
                justifyContent: "center",
                width: "150px",
                height: "150px",
                padding: "76px 18px 79px 21px",
                backgroundColor:
                  theme.palette.customColors.outboundMessageColor,
                borderRadius: "14px",
              }
            : {
                display: "flex",
                flexFlow: "column",
                alignItems: "center",
                overflowWrap: "anywhere",
              };
        }}
      >
        <StyledImage
          alt="message attachment"
          src={src}
          width={150}
          sx={(theme) => {
            return isRichMedia
              ? {
                  borderRadius: "4px",
                  display: "block",
                  maxHeight: "300px",
                  maxWidth: "300px",
                  height: "56px",
                  width: "56px",
                  filter: theme.palette.customColors.svgFilter,
                }
              : {
                  borderRadius: "4px",
                  display: "block",
                  maxHeight: "300px",
                  maxWidth: "300px",
                };
          }}
        />
        <StyledFigCaption>{displayedFilename}</StyledFigCaption>
      </StyledFigure>
    </Box>
  );
}

AttachmentPreview.propTypes = {
  attachment: PropTypes.object,
};

export default AttachmentPreview;
