import BaseDrawer from "@mui/material/Drawer";
import {
  ForwardedRef,
  forwardRef,
  Ref,
  useImperativeHandle,
  useMemo,
} from "react";
import { Props, DrawerRef } from "./types";
import { usePromise } from "components/hooks";

/**
 * <Drawer /> component
 */
function _Drawer<T, R>(
  { children, defaultCloseValue, onClose, ...props }: Props<T, R>,
  ref: ForwardedRef<DrawerRef<T, R>>,
) {
  const { isOpen, open, close, parameters } = usePromise<T, R>();

  const value = useMemo(() => {
    return { close, parameters };
  }, [close, parameters]);

  useImperativeHandle(ref, () => {
    return {
      open,
    };
  });

  return (
    <BaseDrawer
      open={isOpen}
      {...props}
      onClose={(...closeProps) => {
        const should = onClose?.(...closeProps) ?? true;

        if (should) {
          close(defaultCloseValue)();
        }
      }}
    >
      {typeof children === "function" ? children(value) : children}
    </BaseDrawer>
  );
}

/**
 * Forward Drawer as generic
 *
 * @link https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref
 * @link https://fettblog.eu/typescript-react-generic-forward-refs/
 */
export const Drawer = forwardRef(_Drawer) as <T, R>(
  props: Omit<Parameters<typeof _Drawer<T, R>>[0], "ref"> & {
    ref: Ref<DrawerRef<T, R>>;
  },
) => ReturnType<typeof _Drawer<T, R>>;
