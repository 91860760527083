import { all, delay, put, takeLatest, select } from "redux-saga/effects";
import { getChannel } from "pusher-redux";
import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import Honeybadger from "@honeybadger-io/js";
import { getPusher } from "../../../PusherComponentV2/createPusherInstance";
import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";

// ACTION TYPES
const ADD_TYPING_INDICATOR = "app/TypingIndicators/ADD_TYPING_INDICATOR";
const EVENTUALLY_CLEAR_TYPING_INDICATOR =
  "app/TypingIndicators/EVENTUALLY_CLEAR_TYPING_INDICATOR";
const EMIT_TYPING_INDICATOR = "app/TypingIndicators/EMIT_TYPING_INDICATOR";
const REMOVE_TYPING_INDICATOR = "app/TypingIndicators/REMOVE_TYPING_INDICATOR";
const actionTypes = {
  ADD_TYPING_INDICATOR,
  EVENTUALLY_CLEAR_TYPING_INDICATOR,
  EMIT_TYPING_INDICATOR,
  REMOVE_TYPING_INDICATOR,
};

// ACTION GENERATORS
const addTypingIndicator = (typingIndicator) => {
  return {
    type: ADD_TYPING_INDICATOR,
    typingIndicator,
  };
};

const emitTypingIndicator = (payload) => {
  return {
    type: EMIT_TYPING_INDICATOR,
    payload,
  };
};

const eventuallyClearTypingIndicator = (payload) => {
  return {
    type: EVENTUALLY_CLEAR_TYPING_INDICATOR,
    payload,
  };
};

const removeTypingIndicator = (typingIndicator) => {
  return {
    type: REMOVE_TYPING_INDICATOR,
    typingIndicator,
  };
};

const actionGenerators = {
  addTypingIndicator,
  emitTypingIndicator,
  eventuallyClearTypingIndicator,
  removeTypingIndicator,
};

export function* emitTypingIndicatorSaga({
  payload: { currentAccountSlug, conversationId, user, type },
}) {
  try {
    const currentUser = yield select(selectCurrentUser);
    const isPusherFlag = !!currentUser.featureFlags.pusherbackoff;
    const channel = isPusherFlag
      ? getPusher().channel(`private-${currentAccountSlug}@updates`)
      : getChannel(`private-${currentAccountSlug}@updates`);

    if (channel) {
      const eventName = `client-response-${type}`;
      const eventData = { conversationId, user };
      channel.trigger(eventName, eventData);
    }
  } catch (error) {
    // Only emit an error to HB if the typing indicator type is not ended
    if (type !== "ended") {
      Honeybadger.notify(error, {
        context: {
          current_account_slug: currentAccountSlug,
          conversation_id: conversationId,
          user,
          type,
        },
      });
    }
  }
}

function* eventuallyClearTypingIndicatorSaga({ payload }) {
  yield delay(30 * 1000);
  yield put(actionGenerators.removeTypingIndicator(payload));
}

// SAGA
function* saga() {
  yield all([
    takeLatest(actionTypes.EMIT_TYPING_INDICATOR, emitTypingIndicatorSaga),
    takeLatest(
      actionTypes.EVENTUALLY_CLEAR_TYPING_INDICATOR,
      eventuallyClearTypingIndicatorSaga,
    ),
  ]);
}

const getExisitngIndicators = ({ state, conversationId }) => {
  return state.get(conversationId) || fromJS([]);
};

// REDUCER
// eslint-disable-next-line default-param-last
const typingIndicators = (state = fromJS({}), action) => {
  switch (action.type) {
    case actionTypes.ADD_TYPING_INDICATOR:
      return state.merge({
        [action.typingIndicator.conversationId]: [
          ...getExisitngIndicators({
            state,
            conversationId: action.typingIndicator.conversationId,
          }).filter((user) => {
            return user !== action.typingIndicator.user;
          }),
          action.typingIndicator.user,
        ],
      });
    case actionTypes.REMOVE_TYPING_INDICATOR:
      return state.merge({
        [action.typingIndicator.conversationId]: getExisitngIndicators({
          state,
          conversationId: action.typingIndicator.conversationId,
        }).filter((user) => {
          return user !== action.typingIndicator.user;
        }),
      });
    default:
      return state;
  }
};
const reducer = combineReducers({ typingIndicators });

// SELECTORS
const selectTypingIndicators = (state) => {
  return state.get("typingIndicatorsContainer").toJS();
};
const selectors = { selectTypingIndicators };

export { actionTypes, actionGenerators, reducer, saga, selectors };
