import { useCallback } from "react";
import Button from "@mui/material/Button";
import { Box, Grid, Typography } from "@mui/material";
import { Form } from "formik";
import { isEmpty } from "lodash";
import type { FormValues, User } from "../UsersForm";
import { UsersForm } from "../UsersForm";
import { DrawerProps } from "./types";
import { sanitize } from "utils/sanitize";

export function UsersDrawer({ close, parameters, serverErrors }: DrawerProps) {
  const [, account] = parameters ?? [];

  const onSubmit = useCallback(
    (values: FormValues) => {
      close(sanitize<Record<string, User>>(values.users))();
    },
    [close],
  );

  return (
    <Grid
      display="grid"
      gridTemplateColumns="1fr"
      gridTemplateRows="auto 1fr auto"
      sx={{
        maxWidth: "500px",
        position: "relative",
        height: "100%",
      }}
    >
      <Box pl={3}>
        <Box
          pt="17px"
          pb="18px"
          sx={{
            borderBottomStyle: "solid",
            borderColor: "grey.300",
            borderBottomWidth: "1px",
          }}
        >
          <Typography variant="h5" component="h2" color="primary">
            Add Users
          </Typography>
        </Box>
      </Box>
      <UsersForm.Form users={account?.users} onSubmit={onSubmit}>
        {({ isValid, errors, dirty, values, ...props }) => {
          return (
            <Box display="contents" component={Form}>
              <Box
                pt="18px"
                pl={3}
                sx={{
                  overflowY: "auto",
                }}
              >
                {account?.name && (
                  <Typography
                    variant="h6"
                    mb="12px"
                    color="primary"
                    component="h3"
                  >
                    {account?.name}
                  </Typography>
                )}
                <Typography
                  pr={2}
                  mb="16px"
                  variant="body1"
                  letterSpacing="0.15px"
                  fontSize="16px"
                >
                  Add up to 10 users at a time. If you need to add more, please
                  submit this form again.
                </Typography>
                <UsersForm.Fields
                  values={values}
                  errors={errors}
                  dirty={dirty}
                  isValid={isValid}
                  serverErrors={serverErrors}
                  {...props}
                />
              </Box>
              <Grid
                container
                justifyContent="flex-end"
                p={3}
                gap={3}
                sx={{
                  backgroundColor: "background.paper",
                }}
              >
                <Button size="large" onClick={close(false)}>
                  Cancel
                </Button>
                <Button
                  size="large"
                  variant="contained"
                  disabled={!dirty || isEmpty(sanitize(values.users))}
                  type="submit"
                >
                  {account?.users ? "Update" : "Add"} users
                </Button>
              </Grid>
            </Box>
          );
        }}
      </UsersForm.Form>
    </Grid>
  );
}
