import get from "lodash/get";
import uniq from "lodash/uniq";
import Box from "@mui/material/Box";
import DeliverabilityStatus from "components/DeliverabilityStatus";

export const getCollapsedColumns = () => {
  return [
    {
      title: "Contact",
      getTableCellContent: (contact) => {
        return contact.name;
      },
    },
  ];
};

export const getColumns = () => {
  return [
    {
      title: "Name",
      primary: true,
      getTableCellContent: (contact) => {
        return contact.name;
      },
    },
    {
      title: "Primary phone number",
      align: "left",
      getTableCellContent: (contact) => {
        const primaryPhone = contact?.phones?.members?.[0] ?? {};
        const { deliverabilityStatus, formattedPhoneNumber } = primaryPhone;
        return (
          <>
            <DeliverabilityStatus deliverabilityStatus={deliverabilityStatus} />
            <Box ml="10px" display="inline-block">
              {formattedPhoneNumber ?? "No Number Assigned"}
            </Box>
          </>
        );
      },
    },
    {
      title: "Business name",
      align: "left",
      getTableCellContent: (contact) => {
        const business = get(contact, ["data", "business"]);
        return business;
      },
    },
    {
      title: "Tags",
      align: "left",
      getTableCellContent: (contact) => {
        return uniq(get(contact, ["data", "tags"], [])).join(", ");
      },
    },
  ];
};
