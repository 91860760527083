import { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { Redirect } from "react-router-dom";
import queryString from "query-string";
import withSizes from "react-sizes";

import getRedirectUrl from "./utils/getRedirectUrl";
import ResponsiveRoot from "./components/ResponsiveRoot";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import {
  selectCurrentAccount,
  selectActiveConversations,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";
import breakpoints from "utils/styles/breakpoints";
import convertInboxSlugs from "utils/convertInboxSlugs";
import PageLoader from "components/PageLoader";
import withRecord from "higherOrderComponents/withRecord";

export class InboxRoot extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    fetchInboxRequest: PropTypes.func.isRequired,
    inbox: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
  };

  componentDidMount() {
    const { inbox } = this.props;
    if (isEmpty(inbox)) {
      const { currentAccount, match } = this.props;
      const { search } = global.document.location;
      const urlParams = convertInboxSlugs({
        ...match.params,
        accountSlug: currentAccount.slug,
        queryParams: search,
      });
      const url = `${currentAccount.inbox}?${queryString.stringify(urlParams)}`;
      this.props.fetchInboxRequest(url);
    }
  }

  render() {
    const { currentAccount, inbox, match } = this.props;
    if (
      !isEmpty(inbox) &&
      match.path.replace(/\/$/, "") === currentAccount.inbox
    ) {
      return <Redirect to={getRedirectUrl({ currentAccount, inbox })} />;
    }
    if (isEmpty(inbox)) return <PageLoader />;

    return <ResponsiveRoot {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    activeConversations: selectActiveConversations(state, props),
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  setCompose: composeActionGenerators.setCompose,
});

export default compose(
  withSizes(({ width }) => {
    return {
      isSinglePanel: width < breakpoints.medium,
      isLarge: width >= breakpoints.large,
      isXLarge: width >= breakpoints.xLarge,
    };
  }),
  withRecord({
    actions: ["fetch"],
    container: "Inbox/containers/InboxRoot",
    noFetch: true,
    shape: {
      activeConversation: {},
      activeConversationFilter: {},
      conversationFilters: {},
    },
    showLoader: () => {
      return false;
    },
    type: "inbox",
  }),
  withConnect,
)(InboxRoot);
