import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Root = styled.svg`
  transform: ${(props) => {
    return props.direction === "in" && "rotateY(180deg)";
  }};

  g {
    fill: ${(props) => {
      if (
        props.direction === "out" &&
        (props.messageSource === "hubspot" ||
          props.messageSource === "campaignsAppendedMessagePreview" ||
          props.messageSource === "campaigns")
      ) {
        return props.theme.colors.message.hubspotOutboundColor;
      }
      return props.direction === "in"
        ? props.theme.colors.message.inboundBackground
        : props.theme.colors.message.outboundBackground;
    }};
  }
`;

export default class MessageBubbleTip extends Component {
  static propTypes = {
    direction: PropTypes.string.isRequired,
    messageSource: PropTypes.string,
  };

  render() {
    const { direction, messageSource } = this.props;
    return (
      <Root
        messageSource={messageSource}
        direction={direction}
        className="message-bubble-tip"
        width="14px"
        height="17px"
        viewBox="0 0 14 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g
          id="Symbols"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Conversation/ThreadMessageTemplate"
            transform="translate(-462.000000, -177.000000)"
            fill="#8AC832"
          >
            <path
              d="M470.766776,194 C469.940055,189.173657 467.512522,184.591489 463.484177,180.253495 C459.455831,175.915502 463.627772,175.915502 476,180.253495"
              id="Path-2"
              transform="translate(469.000000, 185.500000) scale(-1, -1) translate(-469.000000, -185.500000) "
            />
          </g>
        </g>
      </Root>
    );
  }
}
