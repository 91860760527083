import { Color } from "@mui/material";

export const amber: Color = {
  50: "#FFF8E1",
  100: "#FFECB3",
  200: "#FFE082",
  300: "#FFD54F",
  400: "#FFCA28",
  500: "#FFC107",
  600: "#FFB300",
  700: "#FFA000",
  800: "#FF8F00",
  900: "#FF6F00",
  A100: "#FFE57F",
  A200: "#FFD740",
  A400: "#FFC400",
  A700: "#FFAB00",
};
