import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import SignatureIcon from "icons/SignatureIcon";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";

function Signature({ signature, setCompose }) {
  const handleClick = () => {
    setCompose({ active: false, recipientIds: [] });
  };

  return (
    <Box
      alignItems="flex-start"
      color="text.secondary"
      display="flex"
      padding="8px"
    >
      <Box marginRight="10px" flex="0 0 auto">
        <SignatureIcon color="disabled" />
      </Box>
      <Box flex="1 1 auto">
        {signature ? (
          <Tooltip title="Go to your profile to update your signature">
            <Box
              data-testid="signature-content"
              component="span"
              sx="white-space: pre-line;"
            >
              {signature.content}
            </Box>
          </Tooltip>
        ) : (
          <Box component="em">
            Go to your{" "}
            <Link to="/profile/signature" onClick={handleClick}>
              profile
            </Link>{" "}
            to create a signature
          </Box>
        )}
      </Box>
    </Box>
  );
}

Signature.propTypes = {
  signature: PropTypes.object,
  setCompose: PropTypes.func,
};

const withConnect = connect(null, {
  setCompose: composeActionGenerators.setCompose,
});

export default withConnect(Signature);
