import { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field } from "formik";
import TextField from "@mui/material/TextField";
import PasswordlessSuccess from "./PasswordlessSuccess";
import { PasswordlessLoginSchema } from "formHelpers/validationSchemas";
import H3 from "components/H3";
import H5 from "components/H5";
import Button from "features/Authentication/components/PrimaryButton";
import SecondaryButton from "features/Authentication/components/SecondaryButton";
import Overlay from "features/Authentication/components/Overlay";

export default class PasswordlessOverlay extends Component {
  static propTypes = {
    isActive: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  render() {
    const { isActive } = this.props;
    return (
      <Overlay isActive={isActive}>
        {isActive && (
          <Formik
            initialValues={{ email: "" }}
            validationSchema={PasswordlessLoginSchema}
            onSubmit={this.props.onSubmit}
          >
            {({ errors, touched, isSubmitting }) => {
              return isSubmitting ? (
                <PasswordlessSuccess />
              ) : (
                <Form>
                  <>
                    <span role="img" aria-label="Email Inbox Emoji">
                      📨
                    </span>
                    <H3>Please enter your email address</H3>
                    <H5>
                      You will receive an email containing a link that will
                      securely log you back into TextUs.
                    </H5>
                    <Field name="email">
                      {({ field }) => {
                        return (
                          <TextField
                            {...field}
                            data-testid="passwordless-email"
                            id="passwordless-email"
                            type="email"
                            error={touched.email && errors.email}
                            fullWidth
                            label="Email"
                            placeholder="Please enter your email"
                            helperText={touched.email ? errors.email || "" : ""}
                            variant="standard"
                          />
                        );
                      }}
                    </Field>
                    <Button
                      type="submit"
                      aria-label="Send Passwordless Email Link"
                      data-testid="send-passwordless-email-link"
                    >
                      Send me a link
                    </Button>
                    <SecondaryButton type="button" onClick={this.props.close}>
                      Cancel
                    </SecondaryButton>
                  </>
                </Form>
              );
            }}
          </Formik>
        )}
      </Overlay>
    );
  }
}
