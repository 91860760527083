import { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { actionGenerators, saga, reducer, selectors } from "./state";
import MainView from "./components/MainView";
import injectSaga from "utils/injectSaga";
import injectReducer from "utils/injectReducer";

import {
  selectCurrentAccount,
  selectCurrentUser,
} from "features/EntryPoint/containers/App/selectors";

class GettingStarted extends Component {
  render() {
    return <MainView {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    currentAccount: selectCurrentAccount(state, props),
    currentUser: selectCurrentUser(state, props),
    gettingStartedContainer: selectors.selectGettingStarted(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "gettingStartedContainer",
  reducer,
});
const withSaga = injectSaga({ key: "gettingStartedContainer", saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
  withRouter,
)(GettingStarted);
