import { UsersFormErrors } from "../UsersForm";
import type { Account } from "../../Accounts";
import { State } from "../../../components/Dialog";

export type DrawerProps = State<
  [string, Partial<Account> | undefined] | undefined,
  Account["users"] | false
> & {
  serverErrors: UsersFormErrors | undefined;
};
