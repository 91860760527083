import animations from "./animations";
import dark from "./dark";
import defaults from "./defaults";
import fonts from "./fonts";
import layout from "./layout";
import mixins from "./mixins";
import getColors from "./getColors";

const getTheme = ({ appColors, muiTheme }) => {
  return {
    animations,
    dark,
    defaults,
    fonts,
    layout,
    mixins,
    colors: getColors({ appColors, muiTheme }),
  };
};

export default getTheme;
