import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from "@mui/material";

import { ModalText, SubmitButton } from "../styles/styles";
import { LicenseAssignmentModalProps } from "../models/AccountPlanModels";
import { useLicenseAssignmentModal } from "../hooks/useLicenseAssignmentModal";
import Modal from "components/Modal";
import ModalContent from "components/ModalContent";
import ModalHeader from "components/ModalHeader";

function LicenseAssignmentModal({
  assignLicenses,
  campaignsPro,
  keywords,
  sequences,
  selectedAccounts = [],
  openModal,
  getAccount,
  toggleModal,
}: LicenseAssignmentModalProps) {
  const {
    alertShowing,

    campaignsProChecked,
    campaignsProEnabled,
    campaignsProShowing,

    keywordsChecked,
    keywordsEnabled,
    keywordsShowing,

    sequencesChecked,
    sequencesEnabled,
    sequencesShowing,

    setCampaignsProChecked,
    setKeywordsChecked,
    setSequencesChecked,
  } = useLicenseAssignmentModal(
    campaignsPro,
    keywords,
    selectedAccounts,
    sequences,
  );

  const closeModal = () => {
    setCampaignsProChecked(false);
    setKeywordsChecked(false);
    setSequencesChecked(false);
    toggleModal();
  };

  const handleSubmit = () => {
    assignLicenses({
      assignKeywords: keywordsChecked,
      assignCampaignsPro: campaignsProChecked,
      assignSequences: sequencesChecked,
    });
  };

  const numAccounts = <b>{selectedAccounts.length}</b>;

  const singleAccount =
    selectedAccounts.length > 0
      ? selectedAccounts[0].replace("/accounts/", "")
      : selectedAccounts[0];

  const modalTextSingle = (
    <Typography fontSize={16}>
      Assign or remove licenses from <b>{getAccount(singleAccount)?.name}</b>.
      Assigning a license here will add the license to {numAccounts} account.
      Removing a license here will remove the license from {numAccounts} account
      if applicable.
    </Typography>
  );

  const modalTextBulk = (
    <Typography fontSize={16}>
      Assigning or removing licenses here will apply to {numAccounts} accounts.
      Assigning a license here will add the same license to {numAccounts}{" "}
      accounts. Removing a license here will remove the license from{" "}
      {numAccounts} accounts if applicable.
    </Typography>
  );

  return (
    <Box m={2}>
      <Modal
        ariaLabel="Assign License Modal"
        closeModal={closeModal}
        maxHeight="500px"
        transitionIn={openModal}
      >
        <ModalHeader
          closeModal={closeModal}
          borderBottom="none"
          color="#000"
          fontSize="20px"
          fontWeight="500"
          paddingLeft="1.5rem"
        >
          {selectedAccounts.length > 1
            ? "Bulk manage licenses"
            : "Manage licenses"}
        </ModalHeader>
        <ModalContent // @ts-ignore
          width="475"
          noPadding
          overflowY="none"
        >
          <Box
            minWidth="400px"
            display="flex"
            flexDirection="column"
            padding="0 1.5rem 1.5rem 1.5rem"
          >
            <ModalText color="textSecondary">
              {selectedAccounts.length > 1 ? modalTextBulk : modalTextSingle}

              {alertShowing && (
                <Alert
                  severity="info"
                  sx={{
                    marginTop: "0.5rem",
                    "& .MuiAlert-icon": {
                      alignItems: "center",
                    },
                  }}
                >
                  There are a mix of license assignments in your selection. The
                  following settings will be applied across all selected
                  accounts.
                </Alert>
              )}
            </ModalText>
            <FormGroup>
              <Box
                display="flex"
                flexDirection="column"
                my={2}
                marginLeft="9px"
              >
                {campaignsProShowing && (
                  <FormControlLabel
                    sx={{ marginBottom: "0.5rem" }}
                    control={
                      <Switch
                        color="secondary"
                        checked={campaignsProChecked}
                        disabled={!campaignsProEnabled}
                        onChange={() => {
                          return setCampaignsProChecked(!campaignsProChecked);
                        }}
                      />
                    }
                    label="Campaigns Pro"
                  />
                )}
                {keywordsShowing && (
                  <FormControlLabel
                    sx={{ marginBottom: "0.5rem" }}
                    control={
                      <Switch
                        color="secondary"
                        checked={keywordsChecked}
                        disabled={!keywordsEnabled}
                        onChange={() => {
                          return setKeywordsChecked(!keywordsChecked);
                        }}
                      />
                    }
                    label="Keywords"
                  />
                )}
                {sequencesShowing && (
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={sequencesChecked}
                        disabled={!sequencesEnabled}
                        onChange={() => {
                          return setSequencesChecked(!sequencesChecked);
                        }}
                      />
                    }
                    label="Sequences"
                  />
                )}
              </Box>
            </FormGroup>
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="0 1rem 1rem 1rem"
          >
            <Button color="primary" onClick={closeModal}>
              Cancel
            </Button>
            <SubmitButton
              color="primary"
              onClick={handleSubmit}
              variant="contained"
            >
              Confirm
            </SubmitButton>
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default LicenseAssignmentModal;
