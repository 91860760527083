import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Modal from "components/Modal";
import ModalHeader from "components/ModalHeader";

const ModalContent = styled.div`
  color: ${(props) => {
    return props.theme.colors.text.primary;
  }};
  padding: 20px;
`;

export default class ContactsNotEnabledModal extends Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    autoDismiss: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
    fullScreen: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
  };

  componentDidUpdate(prevProps) {
    const { active, autoDismiss } = this.props;
    if (!prevProps.active && active && autoDismiss) {
      this.autoCloseTimeout = setTimeout(() => {
        this.props.closeModal();
      }, 5000);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.autoCloseTimeout);
  }

  render() {
    const { active, closeModal, content, fullScreen, title } = this.props;
    return (
      <Modal
        transitionIn={active}
        closeModal={closeModal}
        fullScreen={fullScreen}
      >
        <ModalHeader closeModal={closeModal}>{title}</ModalHeader>
        <ModalContent>{content}</ModalContent>
      </Modal>
    );
  }
}
