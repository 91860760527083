/* eslint-disable react/prop-types */

import { ClipboardEvent, useState, useMemo, useCallback } from "react";
import { Field, FieldInputProps, getIn } from "formik";
import { Box, TextField, Typography } from "@mui/material";
import { SavedReply } from "@tesseract/core";
import { SegmentedMessage } from "sms-segments-calculator";
import { ItemToAdd, MessageInputFormRowProps } from "./types";
import AttachmentsOption from "containers/NewMessage/containers/AttachmentsOption";
import DraftMessage from "components/DraftMessage";
import EmojiPickerOption from "components/MessageInputOptions/EmojiPickerOption";
import MessageInputForMobile from "components/MessageInputForMobile";
import ShortenedLinkOption from "components/MessageInputOptions/ShortenedLinkOption/index";
import SignatureOption from "components/MessageInputOptions/SignatureOption";
import TemplateVariablePickerOption from "components/MessageInputOptions/TemplateVariablePickerOption";
import { MessageAddOns, SetFieldValue } from "containers/NewMessage/types";

export default function MessageInputFormRow({
  attachmentField,
  attachmentFieldName,
  errors = {},
  fieldName,
  hideUserPersonalization,
  isCampaignProMember,
  isDisabled,
  isSignatureActive,
  label,
  linksFieldName,
  maxChars,
  SavedRepliesOption,
  placeholder = "",
  setFieldValue,
  signature,
  shortenedLink,
  toggleSignature,
  touched = {},
  values,
  setShortenedLink,
  withSignature,
  formPadding = "0",
  templates,
  settings,
}: MessageInputFormRowProps) {
  const [itemToAdd, setItemToAdd] = useState<ItemToAdd | null>(null);
  const [pastedImage, setPastedImage] = useState<File | undefined>(undefined);

  function getSelectionHandler({ optionName }: { optionName: MessageAddOns }) {
    switch (optionName) {
      case "Emojis":
        return (emoji: { native: string }) => {
          return setItemToAdd({
            item: emoji.native,
            type: "emoji",
          });
        };
      case "Personalize":
        return (templateVariable: string) => {
          return setItemToAdd({
            item: templateVariable,
            type: "templateVariable",
          });
        };
      case "Link":
        return (links: MessageInputFormRowProps["shortenedLink"]) => {
          return setItemToAdd({
            item: links.shortLink,
            type: "shortenedLink",
          });
        };
      default:
        return () => {};
    }
  }

  const insertSavedReply = (savedReply: SavedReply.Raw) => {
    setItemToAdd({
      item: savedReply.content,
      type: "savedReply",
    });
  };

  const handlePaste = (event: ClipboardEvent) => {
    if (event.clipboardData.files.length > 0) {
      const file = event.clipboardData.files[0];
      setPastedImage(file);
    }
  };

  const handleCampaignsProAttachmentsChange = (
    setAttachmentField: SetFieldValue,
  ) => {
    return (attachments?: File[]) => {
      setAttachmentField(attachmentFieldName, attachments);
      setItemToAdd(null);
    };
  };

  const handleSetShortenedLink = (setShortenedLinkField: SetFieldValue) => {
    return (links: MessageInputFormRowProps["shortenedLink"]) => {
      if (linksFieldName) {
        setShortenedLinkField(linksFieldName, links);
      }
      setShortenedLink(links);
      setItemToAdd(null);
    };
  };

  const formatShortLink = (message: string) => {
    const shortLink = shortenedLink?.shortLink;
    if (message.includes(shortLink)) {
      return message.replace(
        shortLink,
        `{{ "${shortenedLink?.fullLink ?? ""}" | shortlink }}`,
      );
    }
    return message;
  };

  const currentValue = getIn(values, fieldName) ?? "";
  const signatureContent = signature?.content ?? "";

  const { characterCount, segmentCount } = useMemo(() => {
    let message = currentValue;
    if (message.length === 0) {
      return {
        characterCount: 0,
        segmentCount: 0,
      };
    }

    if (isSignatureActive && signatureContent.length > 0) {
      message += `\n\n${signatureContent}`;
    }

    const smsMessage = new SegmentedMessage(message);

    return {
      characterCount: message.length,
      segmentCount: smsMessage.segmentsCount,
    };
  }, [currentValue, isSignatureActive, signatureContent]);

  const isSequence = Object.keys(values).includes("absoluteTime");

  const InputDraftMessage = useCallback(
    (props: any) => {
      if (templates && settings) {
        return <DraftMessage {...props} maxHeight="275px" overflow="auto" />;
      }

      if (templates && !settings) {
        return <DraftMessage {...props} maxHeight="150px" overflow="auto" />;
      }

      return <DraftMessage {...props} />;
    },
    [settings, templates],
  );

  return (
    <Box flex="0 0 auto" padding={formPadding}>
      <Field type="text" name={fieldName}>
        {({ field }: { field: FieldInputProps<any> }) => {
          return (
            <TextField
              id="textus-NewMessageTextArea"
              data-testid="new-message-text-area"
              disabled={isDisabled}
              {...field}
              error={
                getIn(touched, fieldName) && Boolean(getIn(errors, fieldName))
              }
              helperText={
                getIn(touched, fieldName) ? getIn(errors, fieldName) : ""
              }
              variant="outlined"
              fullWidth
              inputProps={{
                name: field.name,
                handleChange: (value: string) => {
                  setFieldValue(field.name, formatShortLink(value));
                  setItemToAdd(null);
                },
                isSignatureActive,
                itemToAdd,
                selectionHandler: getSelectionHandler({
                  optionName: "Link",
                }),
                setShortenedLink: handleSetShortenedLink(setFieldValue),
                shortenedLink,
                signature,
                value: field.value,
                withSignature,
              }}
              InputLabelProps={{
                margin: "dense",
                shrink: Boolean(field.value),
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent:
                  process.env.NODE_ENV === "test"
                    ? MessageInputForMobile
                    : InputDraftMessage,
              }}
              label={label}
              placeholder={placeholder}
              onPaste={handlePaste}
              type="text"
            />
          );
        }}
      </Field>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
      >
        <Box>
          {withSignature && (
            <SignatureOption
              isSignatureActive={isSignatureActive}
              toggleSignature={toggleSignature}
            />
          )}
          <EmojiPickerOption
            selectionHandler={
              getSelectionHandler({
                optionName: "Emojis",
              }) as (emoji: { native: string }) => void
            }
            disabled={isDisabled}
          />
          {SavedRepliesOption && (
            <SavedRepliesOption selectionHandler={insertSavedReply} />
          )}
          <TemplateVariablePickerOption
            hideUserPersonalization={hideUserPersonalization}
            selectionHandler={
              getSelectionHandler({
                optionName: "Personalize",
              }) as (templateVariable: string) => void
            }
            disabled={isDisabled}
          />
          {(isCampaignProMember || isSequence) && (
            <AttachmentsOption
              attachments={attachmentField}
              handleAttachmentsChange={handleCampaignsProAttachmentsChange(
                setFieldValue,
              )}
              pastedImage={pastedImage}
              disabled={isDisabled}
            />
          )}
          <ShortenedLinkOption
            shortenedLink={shortenedLink}
            setShortenedLink={handleSetShortenedLink(setFieldValue)}
            selectionHandler={
              getSelectionHandler({
                optionName: "Link",
              }) as (links: MessageInputFormRowProps["shortenedLink"]) => void
            }
            disabled={isDisabled}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          sx={(theme) => {
            const error =
              getIn(values, "messageBody") !== "" && characterCount > maxChars;
            return {
              color: error
                ? theme.palette.error.main
                : theme.palette.text.secondary,
            };
          }}
        >
          <Typography fontSize="0.75rem">{`${characterCount} of ${maxChars} characters`}</Typography>
          <Typography fontSize="0.75rem">{`(~${segmentCount} SMS messages)`}</Typography>
        </Box>
      </Box>
    </Box>
  );
}
