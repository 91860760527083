import { CSSProperties } from "react";
import type { FileRejection } from "react-dropzone";

export type RejectedFiles = FileRejection[];

export type Props = {
  acceptedFileTypes: string;
  children: React.ReactNode;
  className?: string;
  error: boolean;
  isVisible?: boolean;
  maxFileSize: number;
  onFileAccepted: (file: File) => void;
  onFileRejected: (
    filesWithErrors: {
      errors: {
        message: string;
      }[];
    }[],
  ) => void;
  rootStyles?: CSSProperties;
  wrapperStyles?: CSSProperties;
};
