import { ListItem, ListItemText, List } from "@mui/material";
import { BaseDrawer } from "../BaseDrawer";
import { DuplicateSequenceDrawerProps } from "./types";
import { getSequenceStepAccordionHeading } from "features/Sequences/screens/IndividualSequenceOverview/utils";

export function DuplicateSequenceDrawer({
  isOpen,
  sequence,
  sequenceSteps,
  handleBack,
  handleConfirm,
  handleClose,
}: DuplicateSequenceDrawerProps) {
  // ==== RENDER ==== //
  return (
    <BaseDrawer
      title="Duplicate sequence"
      isOpen={isOpen}
      handleClose={handleClose}
      handleBack={handleBack}
      handleConfirm={handleConfirm}
    >
      <List disablePadding>
        <ListItem divider>
          <ListItemText
            primary={sequence.name}
            secondary={`Active | ${sequence.engagementRate}% engagement | ${sequence.stepCount} steps`}
            primaryTypographyProps={{
              fontSize: "16px",
            }}
          />
        </ListItem>

        {sequenceSteps.map((step) => {
          return (
            <ListItem key={step.id}>
              <ListItemText
                primary={getSequenceStepAccordionHeading(step, false)}
                secondary={step.body}
                primaryTypographyProps={{
                  fontSize: "16px",
                }}
                secondaryTypographyProps={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              />
            </ListItem>
          );
        })}
      </List>
    </BaseDrawer>
  );
}
