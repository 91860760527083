export const getTopPerformerIndex = (replyRates: number[]) => {
  // sort largest to smallest
  const sortedReplyRates = [...replyRates].sort((a, b) => {
    return b - a;
  });
  if (sortedReplyRates[0] === sortedReplyRates[1]) {
    // multiple top performers, just don't mark any as top performer:
    // This also covers the case of a campaign that hasn't started yet,
    // where all reply rates are 0
    return -1;
  }
  return replyRates.indexOf(sortedReplyRates[0]);
};
