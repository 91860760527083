import Box from "@mui/material/Box";
import { useTheme } from "styled-components";
import LockIcon from "@mui/icons-material/Lock";

function LockedSettingBanner() {
  const theme = useTheme();

  return (
    <Box
      bgcolor={theme.colors.secondary.main}
      color={theme.colors.primary.contrastText}
      p="10px"
      borderRadius="4px"
      textAlign="center"
      marginBottom="20px"
    >
      <LockIcon style={{ marginRight: "10px" }} />
      Your admin has <strong>locked</strong> this setting.
    </Box>
  );
}

export default LockedSettingBanner;
