import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { actionGenerators, reducer } from "./state";
import injectReducer from "utils/injectReducer";

class DragListener extends Component {
  static propTypes = {
    children: PropTypes.any,
    setIsDragging: PropTypes.func.isRequired,
  };

  state = {
    dragEventCount: 0,
  };

  handleDragEnter = () => {
    if (this.state.dragEventCount === 0) {
      this.props.setIsDragging(true);
    }
    this.setState({ dragEventCount: this.state.dragEventCount + 1 });
  };

  handleDragLeave = () => {
    this.setState({ dragEventCount: this.state.dragEventCount - 1 }, () => {
      if (this.state.dragEventCount === 0) {
        this.props.setIsDragging(false);
      }
    });
  };

  handleDrop = () => {
    this.setState({ dragEventCount: 0 });
    this.props.setIsDragging(false);
  };

  render() {
    return (
      <div
        onDragEnter={this.handleDragEnter}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
      >
        {this.props.children}
      </div>
    );
  }
}

const withConnect = connect(null, actionGenerators);

const withReducer = injectReducer({
  key: "dragListenerContainer",
  reducer,
});

export default compose(withReducer, withConnect)(DragListener);
