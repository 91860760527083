import { useCallback, useRef } from "react";
import { DrawerRef } from "./types";

export const useDrawer = <T, R>() => {
  const ref = useRef<DrawerRef<T, R>>(null);
  const open = useCallback(
    (parameters: T, callback: (result: R | undefined) => void) => {
      return async () => {
        const result = await ref.current?.open(parameters);

        callback(result);
      };
    },
    [],
  );

  return {
    ref,
    open,
  };
};
