export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";

// extracts version from the 8 first characters of deployed commit
export const CLIENT_VERSION =
  process.env.COMMIT_ID?.substring(0, 8) ?? "";

export const TESSERACT = "tesseract";
export const POPOUT = "popout";
