import { User } from "@tesseract/core";
import { useCallback, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { merge } from "lodash";
import { UsersQuery, fetchUsers } from "../api/fetchUsers";

/**
 * States
 */
const states = Object.freeze({
  waiting: "waiting",
  loading: "loading",
  error: "error",
  done: "done",
});

/**
 * Hook to fetch users.
 */
export const useUsers = (baseQuery?: UsersQuery, config?: { wait: number }) => {
  /**
   * Users
   */
  const [users, setUsers] = useState<User.Collection | undefined>(undefined);

  /**
   * Total items
   */
  const [totalItems, setTotalItems] = useState(0);

  /**
   * Current state
   */
  const [state, setState] = useState<(typeof states)[keyof typeof states]>(
    states.waiting,
  );

  /**
   * Error
   */
  const [error, setError] = useState<Error | undefined>(undefined);

  /**
   * Abort
   * @param reason
   * @returns void
   */
  const [abort, setAbort] = useState<((reason?: string) => void) | undefined>(
    undefined,
  );

  /**
   * Load users
   */
  const load = useCallback(
    (query?: UsersQuery) => {
      let ignore = false;

      setState(states.loading);

      setError(undefined);

      const controller = new AbortController();

      setAbort(() => {
        return (reason?: string) => {
          controller.abort(reason);

          ignore = true;
        };
      });

      fetchUsers(merge(baseQuery, query), {
        signal: controller.signal,
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }

          return response.json();
        })
        .then((data: { members: User.Raw[]; totalItems: number }) => {
          if (!ignore) {
            setUsers(
              Object.fromEntries(
                data.members.map((user) => {
                  return [`${user.id}`, user];
                }),
              ),
            );
            setTotalItems(data.totalItems);
            setState(states.done);
          }

          return data;
        })

        .catch((error_) => {
          setState(states.error);
          setError(error_);
        });

      return () => {
        controller.abort();

        ignore = true;
      };
    },
    [baseQuery],
  );

  const reset = useCallback(() => {
    setUsers(undefined);
    setTotalItems(0);
    setState(states.waiting);
    setError(undefined);
    setAbort((currentAbort?: (reason?: string) => void) => {
      currentAbort?.();
      return undefined;
    });
  }, []);

  /**
   * Debounced version of the `load` function.
   */
  const debouncedLoad = useMemo(() => {
    return debounce(load, config?.wait ?? 500);
  }, [config?.wait, load]);

  return {
    abort,
    error,
    load: debouncedLoad,
    reset,
    state,
    totalItems,
    users,
  };
};
