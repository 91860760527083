import { connect } from "react-redux";
import { compose } from "redux";
import { Component } from "react";
import withSizes from "react-sizes";

import { actionGenerators, reducer, selectors } from "./state";
import Root from "./components/Root";
import injectReducer from "utils/injectReducer";

class ComposeRoot extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const mapStateToProps = (state, props) => {
  return {
    composeRootContainer: selectors.selectComposeRootContainer(state, props),
  };
};

const withConnect = connect(mapStateToProps, actionGenerators);

const withReducer = injectReducer({
  key: "composeRootContainer",
  reducer,
});

export default withSizes(({ width }) => {
  return { fullScreen: width < 620 };
})(compose(withReducer, withConnect)(ComposeRoot));
