import { Component } from "react";
import get from "lodash/get";
import withRecord from "higherOrderComponents/withRecord";
import Root from "features/Campaigns/containers/Campaign/components/Root";

class Campaign extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default withRecord({
  actions: ["fetch", "delete", "cancel", "update"],
  container: "Campaigns/containers/Campaign",
  shape: {
    user: {},
    totalRecipients: { members: [{ contactPhone: { contact: {} } }] },
    skippedRecipients: { members: [{ contactPhone: { contact: {} } }] },
    sentRecipients: { members: [{ contactPhone: { contact: {} } }] },
    deliveredRecipients: { members: [{ contactPhone: { contact: {} } }] },
    failedRecipients: { members: [{ contactPhone: { contact: {} } }] },
    repliedRecipients: { members: [{ contactPhone: { contact: {} } }] },
  },
  showLoader: (props) => {
    return !get(props, ["campaign", "totalRecipients", "members"]);
  },
  type: "campaign",
})(Campaign);
