import { Component } from "react";
import StatusOverlay from "./StatusOverlay";
import H3 from "components/H3";
import H5 from "components/H5";

export default class PasswordlessSuccess extends Component {
  render() {
    return (
      <StatusOverlay>
        <span role="img" aria-label="successfully sent mail">
          ✉️✨
        </span>
        <H3>Sweet, check your email!</H3>
        <H5>
          Click the link we sent and you will be asked to enter a new password
        </H5>
      </StatusOverlay>
    );
  }
}
