import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import camelCase from "lodash/camelCase";
import isEmpty from "lodash/isEmpty";
import startCase from "lodash/startCase";

import getState from "./state";
import PageLoader from "components/PageLoader";
import injectSaga from "utils/injectSaga";

// @ts-check

/**
 * A higher-order component that enhances a component by providing record-related functionality.
 *
 * Issue with this HOC:
 * - Handles too many responsibilities
 * - Difficult to understand what it does
 * - Hard to extend or modify
 *
 * @deprecated - This HOC is deprecated and should not be used in new code.
 *
 * @param {Object} options - The options for configuring the withRecord HOC.
 * @param {string} options.type - The type of the record.
 * @param {string} [options.container=options.type] - The container type.
 * @param {Array} options.actions - The actions to be used for fetching the record.
 * @param {React.Component} [options.loader=<PageLoader />] - The loader component to be displayed while fetching the record.
 * @param {boolean} [options.multiple=false] - Indicates whether the record is multiple or not.
 * @param {boolean} [options.noFetch=false] - Indicates whether to skip fetching the record.
 * @param {Object} [options.shape={}] - The shape of the record.
 * @param {Function} [options.options=() => undefined] - The function that returns additional options for fetching the record.
 * @param {Function} [options.shouldFetch=(_) => !noFetch] - The function that determines whether to fetch the record or not.
 * @param {Function} [options.showLoader=(props) => isEmpty(props[type])] - The function that determines whether to show the loader or not.
 * @returns {Function} - The enhanced component.
 *
 */
const withRecord = ({
  type,
  container = type,
  actions,
  loader = <PageLoader />,
  multiple = false,
  noFetch = false,
  shape = {},
  options = () => {
    return undefined;
  },
  shouldFetch = (_) => {
    return !noFetch;
  },
  showLoader = (props) => {
    return isEmpty(props[type]);
  },
}) => {
  return (WrappedComponent) => {
    const actionTypes = actions.map((action) => {
      return typeof action === "string" ? action : action.type;
    });

    /**
     * Higher-order component that enhances a component by providing record-related functionality.
     * @class WithRecord
     * @extends Component
     */
    class WithRecord extends Component {
      static propTypes = {
        [camelCase(`fetch${startCase(type)}Request`)]: PropTypes.func,
        [type]: PropTypes.object,
        [`${type}Id`]: PropTypes.string,
        history: PropTypes.object,
      };

      componentDidMount() {
        if (actionTypes.includes("fetch") && shouldFetch(this.props)) {
          this.props[camelCase(`fetch${startCase(type)}Request`)](
            this.props[`${type}Id`],
            // Shouldn't we pass in `options()` here too?
          );
        }
      }

      componentDidUpdate(prevProps) {
        if (
          actionTypes.includes("fetch") &&
          prevProps[`${type}Id`] !== this.props[`${type}Id`] &&
          shouldFetch(this.props)
        ) {
          this.props[camelCase(`fetch${startCase(type)}Request`)](
            this.props[`${type}Id`],
            null,
            options(this.props),
          );
        }
      }

      render() {
        if (Boolean(this.props[type]) && showLoader(this.props)) return loader;
        return <WrappedComponent {...this.props} />;
      }
    }

    const { actionGenerators, saga, selectors } = getState({
      actions,
      container,
      multiple,
      shape,
      type,
    });

    const mapStateToProps = actionTypes.includes("fetch")
      ? (state, props) => {
          return {
            [type]: selectors.selectRecord(state, props),
          };
        }
      : () => {
          return {};
        };

    const withConnect = connect(mapStateToProps, actionGenerators);

    const withSaga = injectSaga({ key: `${container}Container`, saga });

    return compose(withSaga, withConnect)(WithRecord);
  };
};

export default withRecord;
