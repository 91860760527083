import { MouseEvent, useState } from "react";
import { Chip, Menu, MenuItem, Typography } from "@mui/material";
import { useHistory } from "react-router";
import { SequencesLinkedChipProps } from "./types";
import SequencesIcon from "icons/SequencesIcon";
import { useCurrentAccount } from "hooks";

function SequencesLinkedChip({
  sequence = { id: "", name: "" },
}: SequencesLinkedChipProps) {
  const history = useHistory();
  const currentAccount = useCurrentAccount();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClick = (sequenceId: string) => {
    history.push(`/${currentAccount.slug}/automation/sequences/${sequenceId}`);
    handleCloseMenu();
  };

  return (
    <>
      <Chip
        icon={<SequencesIcon />}
        label="Sequence linked"
        onClick={handleOpenMenu}
        size="small"
        variant="outlined"
        data-testid="linked-sequence-chip"
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        slotProps={{ paper: { sx: { width: "8rem" } } }}
      >
        <MenuItem
          key={sequence.id}
          onClick={() => {
            handleClick(sequence.id);
          }}
          dense
          data-testid="linked-sequence-chip-menu-link"
        >
          <Typography
            noWrap
            sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
          >
            {sequence.name}
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export { SequencesLinkedChip };
