import { useState, useEffect } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import { useContactUpload } from "../hooks/useContactUpload";
import { RejectedFilesT } from "../models/UploadModels";
import ImportZone from "./ImportZone";
import ContactImportPreview from "./ContactImportPreview";
import injectSaga from "utils/injectSaga";
import H3 from "components/H3";
import { saga } from "features/AccountSettings/components/ConversationImport/state/contactState";
import ImportLoader from "features/ContactImports/containers/NewContactImport/components/ImportLoader";

interface ContactUploadProps {
  onSkip?: () => void;
}

function ContactUpload({ onSkip }: ContactUploadProps) {
  const [error, setError] = useState<string | null>(null);

  const {
    isUploading,
    parsedResults,
    processContacts,
    signedUrl,
    uploadContacts,
  } = useContactUpload();

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (error) {
      timeout = setTimeout(() => {
        setError(null);
      }, 5000);
    }
    return () => {
      return clearTimeout(timeout);
    };
  }, [error]);

  const handleFileRejected = (filesWithErrors: RejectedFilesT) => {
    const _error =
      filesWithErrors[0]?.errors[0]?.message ||
      "Something went wrong. Please try again.";
    setError(_error);
  };

  const handleSkip = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onSkip && onSkip();
  };

  if (isUploading)
    return (
      <Box maxWidth="1000px" margin="0 auto" px={{ xs: 2, sm: 4 }} py={0}>
        <ImportLoader />
      </Box>
    );

  if (parsedResults && signedUrl && !isUploading)
    return (
      <Box maxWidth="1000px" margin="0 auto" px={{ xs: 2, sm: 4 }} py={0}>
        <ContactImportPreview
          parsedResults={parsedResults}
          onSubmit={(headers) => {
            return uploadContacts(headers, signedUrl);
          }}
          isUploading={isUploading}
        />
      </Box>
    );

  return (
    <Box maxWidth="720px" margin="0 auto" px={{ xs: 2, sm: 4 }} py={0}>
      <ImportZone
        onFileAccepted={processContacts}
        onFileRejected={handleFileRejected}
        error={error}
        onReset={() => {
          return setError(null);
        }}
      >
        <H3 style={{ margin: "1rem" }}>Step 1</H3>
        <Typography variant="body1" my="0.2rem">
          Drop your contacts CSV to upload
        </Typography>
        <Typography variant="body2" my="0.2rem">
          or
        </Typography>
        <Button variant="outlined" color="primary" sx={{ my: "0.2rem" }}>
          Select File
        </Button>
        <Typography variant="caption" sx={{ mt: "5rem" }}>
          I've already uploaded my contacts{" "}
          <Button onClick={handleSkip}>Skip Step 1</Button>
        </Typography>
      </ImportZone>
    </Box>
  );
}

// @ts-ignore
const withSaga = injectSaga({ key: "NewContactImportContainer", saga });

export default withSaga(ContactUpload);
