import { AppBar, Toolbar } from "@mui/material";

export default function PageFooter({ children }: any) {
  return (
    <AppBar
      position="static"
      sx={{
        bottom: "0",
        boxShadow: "none",
        top: "auto",
      }}
    >
      <Toolbar
        sx={{
          backgroundColor: "background.paper",
          borderTop: "1px solid",
          borderTopColor: "action.selected",
          color: "text.primary",
          "&.MuiToolbar-root": {
            gap: "8px",
            padding: "16px",
            justifyContent: "flex-end",
          },
        }}
      >
        {children}
      </Toolbar>
    </AppBar>
  );
}
