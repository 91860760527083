import type { GreenhouseCandidate } from "@tesseract/core";
import { useMemo } from "react";

export const getGreenhouseApplication = (
  applications: GreenhouseCandidate.ApplicationsObject[],
  selectedCandidate: GreenhouseCandidate.Raw,
) => {
  /*
      Check for applications
    */
  if (!applications || applications.length === 0) {
    return undefined;
  }
  /*
      If there are applications, return the current application
    */
  if (selectedCandidate.current_application_id) {
    return applications.find((application) => {
      return application.id === selectedCandidate.current_application_id;
    });
  }
  const activeApplications = applications.find((application) => {
    return application.status === "active";
  });
  /*
      If there is not a current application, return most recent "active" application
    */
  if (activeApplications) {
    return activeApplications;
  }
  /*
     If there is no active applications, return most recent application
    */
  return applications[0];
};

export const useGreenhouseApplication = (
  applications: GreenhouseCandidate.ApplicationsObject[],
  selectedCandidate: GreenhouseCandidate.Raw,
) => {
  return useMemo(() => {
    return getGreenhouseApplication(applications, selectedCandidate);
  }, [applications, selectedCandidate]);
};
