import Honeybadger from "@honeybadger-io/js";
import getContactDataFromText from "phoneExtraction/utils/getContactDataFromText";

export default class FullPageSearchStrategy {
  constructor({ parsedHtml }) {
    this.parsedHtml = parsedHtml;
  }

  getSearchText = () =>
    this.parsedHtml.body.innerText ||
    this.parsedHtml.body.textContent ||
    this.parsedHtml.body.innerHTML;

  parse = () => {
    try {
      const results = getContactDataFromText()(this.getSearchText());
      return { strategy: "FullPageSearchStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "FullPageSearchStrategy");
      return null;
    }
  };
}
