import { Component } from "react";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { put, select } from "redux-saga/effects";
import get from "lodash/get";

import Root from "./components/Root";
import {
  selectActiveConversationFilter,
  selectCurrentUser,
  selectRecords,
} from "features/EntryPoint/containers/App/selectors";
import { updateRecords } from "features/EntryPoint/containers/App/actions";
import withRecord from "higherOrderComponents/withRecord";

class ConversationAssignmentsCollection extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

const createOptimisticUpdateFunc = (action) => {
  return function* optimisticUpdateFunc(url) {
    if (action !== "delete") return;
    const activeConversationFilter = yield select(
      selectActiveConversationFilter({
        conversations: { members: [{ assignments: {} }] },
      }),
    );
    if (get(activeConversationFilter, "slug") !== "you") return;
    const currentUser = yield select(selectCurrentUser);
    const records = yield select(selectRecords);
    const assignee = records.getIn([url, "assignee"]);
    if (currentUser.id !== assignee) return;
    const { conversations } = activeConversationFilter;
    const updatedConversationCollection = {
      id: conversations.id,
      members: conversations.members
        .filter((conversation) => {
          return !get(conversation, ["assignments", "members"], []).includes(
            url,
          );
        })
        .map((conversation) => {
          return conversation.id;
        }),
      totalItems: conversations.totalItems - 1,
    };
    yield put(
      updateRecords({
        conversationCollections: {
          [updatedConversationCollection.id]: updatedConversationCollection,
        },
      }),
    );
  };
};

const actions = ["create", "delete"].map((action) => {
  return {
    type: action,
    optimisticUpdateFunc:
      action === "delete" ? createOptimisticUpdateFunc(action) : undefined,
  };
});

export default compose(
  withRecord({
    actions,
    container:
      "features/ActiveConversation/containers/AssignedUserCollection/conversationAssignment",
    type: "conversationAssignment",
  }),
  withRouter,
)(ConversationAssignmentsCollection);
