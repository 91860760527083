import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "@mui/material/Button";
import H4 from "components/H4";
import P from "components/P";
import Graphic from "utils/images/who-is-this-texty.svg";

const AssignContactButton = styled(Button)``;

const AssignContactsText = styled(P)`
  padding: 15px 30px 30px 30px;
`;

const MultipleAssociatedText = styled(AssignContactsText)``;
const NoneAssociatedText = styled(AssignContactsText)``;

const Root = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-top: 45px;
  text-align: center;

  ${H4} {
    font-size: ${(props) => {
      return props.theme.fonts.largeHeaderFontSize;
    }};
  }

  img {
    max-width: 100%;
    margin-bottom: 45px;
  }
`;

export default class WhoIsThis extends Component {
  static propTypes = {
    associatedContacts: PropTypes.object,
    showAssignContacts: PropTypes.func,
  };

  render() {
    const { associatedContacts = {}, showAssignContacts } = this.props;
    const text =
      associatedContacts.totalItems > 0 ? (
        <MultipleAssociatedText>
          {`There are multiple possible contacts for this number and we're not
          sure which one's right. Help us out by assigning one below.`}
        </MultipleAssociatedText>
      ) : (
        <NoneAssociatedText>
          {`We didn’t find any contacts matching this phone number. Help us out by
          assigning one below.`}
        </NoneAssociatedText>
      );
    return (
      <Root>
        <H4>Who Is This?</H4>
        {text}
        <img alt="Connected Texty" src={Graphic} />
        <AssignContactButton
          variant="outlined"
          color="primary"
          onClick={showAssignContacts}
        >
          Assign Contact
        </AssignContactButton>
      </Root>
    );
  }
}
