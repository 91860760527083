import { textUsApiV3 } from "../../../services";
import { GetConversationByContactIdResponse } from '../interfaces/GetConversationByContactIdResponse';

interface Props {
  contactId: string;
  accountSlug: string;
}

const getConversationByContactIdApi = async ({
  contactId,
  accountSlug,
}: Props): Promise<GetConversationByContactIdResponse> => {
  const result = await textUsApiV3.get(
    `/contacts/${contactId}/conversations?account=/accounts/${accountSlug}`,
  );
  const response: GetConversationByContactIdResponse = await result.json();
  return response;
};

export { getConversationByContactIdApi };
