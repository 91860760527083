/**
 * @deprecated
 *  - Use Material-UI breakpoints and access them via theme.breakpoints
 *  - MUI breakpoints are defined in createTheme.ts
 */

export default {
  small: 450,
  navbarMedium: 600,
  medium: 768,
  large: 1024,
  xLarge: 1440,
  superSized: 2560,

  xs: 0,
  sm: 600,
  sm2: 768,
  md: 905,
  lg: 1240,
  xl: 1440,
};
