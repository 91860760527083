import camelCase from "lodash/camelCase";
import endsWith from "lodash/endsWith";
const CRUD_ACTIONS = [
  "create",
  "schedule",
  "fetch",
  "update",
  "delete",
  "assign",
];

const presentParticipleAction = (crudAction) => {
  return endsWith(crudAction, "e")
    ? `${crudAction.substring(0, crudAction.length - 1)}ing`
    : `${crudAction}ing`;
};

const transitionAction = (crudAction) => {
  return camelCase(`is ${presentParticipleAction(crudAction)}`);
};

const errorAction = (crudAction) => {
  return camelCase(`error ${presentParticipleAction(crudAction)}`);
};

const successful = (crudAction) => {
  return ({ container, nextProps, props }) => {
    const nextError = nextProps[container].substate[errorAction(crudAction)];
    const nextPresentParticiple =
      nextProps[container].substate[transitionAction(crudAction)];
    const currentPresentParticiple =
      props[container].substate[transitionAction(crudAction)];
    return !nextError && !nextPresentParticiple && currentPresentParticiple;
  };
};

const successTransitions = CRUD_ACTIONS.reduce((prev, crudAction) => {
  return {
    ...prev,
    [`${crudAction}Successful`]: successful(crudAction),
  };
}, {});

export default successTransitions;
