import { FC } from "react";
import { styled } from "@mui/system";

import { RejectedFilesT } from "../models/UploadModels";
import { Dropzone } from "components/Dropzone";
import ImportError from "features/ContactImports/containers/NewContactImport/components/ImportError";

const StyledImportZone = styled(Dropzone)((props) => {
  return {
    display: "flex",
    flexFlow: "column",
    flex: "1 1 auto",
    maxHeight: "50vh",
    minHeight: 0,
    minWidth: "100%",
    aspectRatio: "1 / 1",

    "> div": {
      flex: "1 1 auto",
    },

    p: {
      color: props.theme.palette.text.primary,
      margin: "10px 0 20px 0",
    },

    "&.error": {
      animation: "shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both",
      transform: "translate3d(0, 0, 0)",
      backfaceVisibility: "hidden",
      perspective: "1000px",
    },

    "@keyframes shake": {
      "10%, 90%": {
        transform: "translate3d(-1px, 0, 0)",
      },

      "20%, 80%": {
        transform: "translate3d(2px, 0, 0)",
      },

      "30%, 50%, 70%": {
        transform: "translate3d(-4px, 0, 0)",
      },

      "40%, 60%": {
        transform: "translate3d(4px, 0, 0)",
      },
    },
  };
});

interface ImportZoneProps {
  error?: boolean | null;
  maxFileSize?: number;
  onFileAccepted: (file: any) => void;
  onFileRejected: (filesWithErrors: RejectedFilesT) => void;
  onReset: () => void;
}

// eslint-disable-next-line react/function-component-definition
const ImportZone: FC<ImportZoneProps> = ({
  children,
  onFileAccepted,
  onFileRejected,
  onReset,
  error = null,
  maxFileSize = 2_000_000, // 2MB
}) => {
  return (
    <StyledImportZone
      acceptedFileTypes="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      maxFileSize={maxFileSize}
      onFileAccepted={onFileAccepted}
      onFileRejected={onFileRejected}
      className={error ? "error" : ""}
      error={error}
    >
      {error ? (
        <ImportError
          errorMessage={error}
          showImage={false}
          startNewImport={onReset}
        />
      ) : (
        <>{children}</>
      )}
    </StyledImportZone>
  );
};

export default ImportZone;
