import { textUsApiV3 } from "services";

export const postGenerateSignedUrl = async (
  url: string,
  filename: string,
): Promise<Response> => {
  return textUsApiV3.post(url, {
    filename,
  });
};
