// TODO: need to add logic to CE to properly fetch the "active" iframe for Job Diva's detail page
import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";
import get from "lodash/get";

export default class JobDivaStrategy {
  constructor({
    activeFrameAttributes,
    activeFrameParsedHtml,
    parsedHtml,
    location,
  }) {
    this.activeFrameAttributes = activeFrameAttributes;
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /jobdiva/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const activeFrameDoc = this.activeFrameParsedHtml;
    const activeFrameId = get(this.activeFrameAttributes, ["id"]);
    const detailPageParser = new SingleContactParser({
      doc: activeFrameDoc,
      namesSelector: "#fullnameLabel",
      textSelector: "#infoBoxContent",
      canParse: () => /infoFrame/i.test(activeFrameId),
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: "table#candidates_table td>span>a",
      containerSelector: "tr",
      canParse: () => !!$(doc).find("table#candidates_table")[0],
    });
    return [detailPageParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "JobDivaStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "JobDivaStrategy");
      return null;
    }
  };
}
