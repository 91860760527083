import { styled } from "@mui/material/styles";

const ImportSection = styled("div")(({ theme }) => {
  return {
    alignItems: "center",
    display: "flex",
    flex: "1 1 auto",
    flexFlow: "column",
    height: "100%",
    minHeight: "0",
    padding: "16px",
    textAlign: "center",

    [theme.breakpoints.up("sm")]: {
      padding: "32px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "32px 64px",
    },
  };
});
export default ImportSection;
