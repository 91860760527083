import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";
import getNamesFromText from "../utils/getNamesFromText";

export default class LaborEdgeNexusStrategy {
  constructor({ parsedHtml, location }) {
    this.parsedHtml = parsedHtml;
    this.location = location;
  }

  canParse = () => /nexus\.laboredge/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.parsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      getNamesFunction: () =>
        getNamesFromText(
          $(doc)
            .find("app-breadcrumb > div > ul > li:nth-child(5)")
            .last()
            .text(),
        ),
      canParse: () =>
        Boolean(
          $(doc)
            .find("app-breadcrumb > div > ul > li:nth-child(5)")
            .last(),
        ),
      searchTextFunction: () =>
        $(doc)
          .find("#candidates-header-phone")
          .text(),
    });
    const nameColumn = doc.querySelector("th[title*='First Name']");
    const nameColumnIndex = nameColumn ? nameColumn.cellIndex : 0;
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: `td:nth-child(${nameColumnIndex + 1}) span span`,
      containerSelector: "tr",
      canParse: () => Boolean($(doc).find("th[title*='First Name']")[0]),
      getNamesFunction: node => {
        const firstName = node.textContent.trim();
        const lastName = $(node)
          .closest("td")
          .next()
          .text()
          .trim();
        return [firstName, lastName];
      },
    });
    return [detailPageParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "LaborEdgeNexusStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "LaborEdgeNexusStrategy");
      return null;
    }
  };
}
