import get from "lodash/get";

const getRedirectUrl = ({ currentAccount, inbox }) => {
  const { activeConversationFilter, conversationFilters } = inbox;

  let redirectUrl = currentAccount.inbox;

  const activeConversationFilterSlug = get(
    activeConversationFilter,
    ["slug"],
    get(conversationFilters, ["members", 0], "").split("/")[3],
  );

  if (!activeConversationFilterSlug) return redirectUrl;
  redirectUrl += `/${decodeURIComponent(activeConversationFilterSlug)}`;

  if (get(activeConversationFilter, ["id"], "").includes("?")) {
    redirectUrl += `?${activeConversationFilter.id.split("?")[1]}`;
  }
  return redirectUrl;
};

export default getRedirectUrl;
