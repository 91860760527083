import { Account, BullhornCandidate, Contact } from "@tesseract/core";
import { useCallback, useState } from "react";
import { fetchCandidates } from "../api/fetchCandidates";

export const useBullhornCandidates = (
  account: Account.Like,
  contact: Contact.Like,
) => {
  const [candidates, setCandidates] =
    useState<Record<string, BullhornCandidate.Raw>>();

  const [state, setState] = useState<"waiting" | "loading" | "error" | "done">(
    "waiting",
  );
  const [error, setError] = useState<Error>();
  const [abort, setAbort] = useState<AbortController["abort"]>();

  const load = useCallback(() => {
    setState("loading");
    setError(undefined);

    const controller = new AbortController();

    setAbort(() => {
      return () => {
        controller.abort();
      };
    });

    fetchCandidates(account, contact, "bullhorn", {
      signal: controller.signal,
    })
      .then((response) => {
        return response.json();
      })
      .then((data: BullhornCandidate.Raw[]) => {
        setCandidates(
          Object.fromEntries(
            data.map((candidate) => {
              return [`${candidate.id}`, candidate];
            }),
          ),
        );
        setState("done");
        setAbort(undefined);

        return data;
      })

      .catch((error_) => {
        setState("error");
        setError(error_);
        setAbort(undefined);
      });
  }, [account, contact]);

  return {
    load,
    candidates,
    state,
    error,
    abort: abort
      ? () => {
          abort?.();
          setState("waiting");
          setError(undefined);
          setAbort(undefined);
          setCandidates(undefined);
        }
      : undefined,
  };
};
