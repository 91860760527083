import { fromJS } from "immutable";
import { combineReducers } from "redux-immutable";
import { createSelector } from "reselect";
import get from "lodash/get";
import { selectRecords } from "features/EntryPoint/containers/App/selectors";

import denormalizeWithShape from "utils/denormalizeWithShape";

// ACTION TYPES
const SET_CONTACT_MODAL = "app/ContactModal/SET_CONTACT_MODAL";
const actionTypes = { SET_CONTACT_MODAL };

// ACTION GENERATORS
const setContactModal = (payload) => {
  return {
    type: SET_CONTACT_MODAL,
    payload,
  };
};

const actionGenerators = { setContactModal };

// REDUCER
const substate = (state = fromJS({ active: false }), action) => {
  switch (action.type) {
    case actionTypes.SET_CONTACT_MODAL: {
      return state.merge(action.payload);
    }
    default:
      return state;
  }
};
const reducer = combineReducers({ substate });

// SELECTORS
const selectOriginalContactModalContainer = (state) => {
  return state.get("contactModalContainer")
    ? state.get("contactModalContainer").toJS()
    : {};
};
const selectContactId = createSelector(
  selectOriginalContactModalContainer,
  (contactModalContainer) => {
    return get(contactModalContainer, ["substate", "contact", "id"]);
  },
);
const selectContact = createSelector(
  selectOriginalContactModalContainer,
  selectRecords,
  selectContactId,
  (contactModalContainer, records, id) => {
    return records.get(id)
      ? denormalizeWithShape({
          id,
          records: records.toJS(),
          shape: { notes: { members: [] }, phones: { members: [] } },
        })
      : get(contactModalContainer, ["substate", "contact"]);
  },
);
const selectContactModalContainer = createSelector(
  selectOriginalContactModalContainer,
  selectContact,
  (contactModalContainer, contact) => {
    return {
      ...contactModalContainer,
      substate: {
        ...contactModalContainer.substate,
        contact,
      },
    };
  },
);
const selectors = { selectContactModalContainer };

export { actionTypes, actionGenerators, reducer, selectors };
