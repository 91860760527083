import assert from "assert";
import { Grid, Button, Typography, Skeleton } from "@mui/material";
import { useCallback } from "react";
import { Form, FormikHelpers, FormikProps } from "formik";
import { identifyAccount } from "@tesseract/core";
import { useSnackbar } from "notistack";
import { ImportRulesProps } from "./types";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import PageHeader from "components/Page/PageHeader";
import {
  SettingsCard,
  SettingsCardActions,
  SettingsCardContent,
  SettingsCardHeader,
  SettingsCardDescription,
  SettingsCardAlert,
  DuplicateRules,
} from "features/Settings";
import type { DuplicateRulesFormValues } from "features/Settings";
import { useAccount } from "features/Accounts/hooks";
import { LockIcon } from "icons";

const settingIdentifier = "importRules";

export function ImportRules({
  isNavbarOpen,
  account: { id, settings: staticSettings },
  toggleSidebar,
  isAdmin,
}: ImportRulesProps) {
  /**
   * Snackbar component for displaying notifications.
   */
  const snackbar = useSnackbar();

  /**
   * Represents the user retrieved from the API based on the automated sender ID.
   * If the automated sender ID is not set, the system user ID is used instead.
   */
  const { account, state: accountState } = useAccount(id);

  /**
   * Handles the form submission for updating account settings.
   *
   * @param formValues - The form values containing the updated settings.
   * @param {object} helpers - The form helpers.
   * @param helpers.setErrors - A function to set errors in the form.
   * @returns {Promise<void>} - A Promise that resolves when the submission is complete.
   */
  const handleSubmit = useCallback(
    async (
      formValues: DuplicateRulesFormValues,
      { setErrors }: FormikHelpers<DuplicateRulesFormValues>,
    ) => {
      // todo
    },
    [],
  );

  const handleToggleLock = useCallback(
    ({
      setFieldValue,
      submitForm,
      values,
    }: FormikProps<DuplicateRulesFormValues>) => {
      return () => {
        assert(account);

        // todo handle toggle lock

        return submitForm();
      };
    },
    [account],
  );

  const locked =
    Boolean(account?.settings[settingIdentifier]?.locked) || !isAdmin;

  return (
    <SettingsPageWrapper>
      <PageHeader title="Import rules" toggleSidebar={toggleSidebar} />
      <Grid
        sx={{
          overflowY: "auto",
        }}
      >
        <Grid
          sx={(theme) => {
            return {
              margin: "auto",
              maxWidth: "100%",
              padding: 2,
              [theme.breakpoints.up("lg")]: {
                padding: 4,
                maxWidth: "1040px",
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                gap: theme.spacing(4),
              },
              [theme.breakpoints.up("xl")]: {
                maxWidth: "1420px",
              },
            };
          }}
        >
          <Typography variant="h4" gutterBottom>
            Find numbers & file imports
          </Typography>
          <DuplicateRules.Form
            initialValues={{
              matchDuplicatesBasedOn:
                account?.settings.importRules?.value?.matchDuplicatesBasedOn ??
                "primaryPhone",
              overwrite:
                account?.settings.importRules?.value?.overwrite ?? true,
            }}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {(formProps) => {
              return (
                <SettingsCard component={Form}>
                  <SettingsCardHeader
                    account={account}
                    canEditLock={
                      isAdmin &&
                      (!locked ||
                        account?.settings.automatedSenderId.lockedAtAccount
                          ?.id === id)
                    }
                    locked={locked}
                    onLockedChange={
                      isAdmin ? handleToggleLock(formProps) : undefined
                    }
                    title="Duplicate rules"
                  />

                  <>
                    {["loading", "waiting"].includes(accountState) && (
                      <SettingsCardContent>
                        <Skeleton variant="rectangular" height={56} />
                      </SettingsCardContent>
                    )}
                    {isAdmin ? (
                      <>
                        {locked && (
                          <SettingsCardAlert
                            icon={<LockIcon />}
                            severity="info"
                          >
                            Locked by an admin for accounts below{" "}
                            <strong>
                              {
                                account?.settings.automatedSenderId
                                  .lockedAtAccount?.name
                              }
                            </strong>
                          </SettingsCardAlert>
                        )}
                        {account?.familyCount && account?.familyCount > 1 && (
                          <SettingsCardAlert severity="warning">
                            Modifying this may overwrite changes made on{" "}
                            <b>
                              {account.familyCount} branch and messaging
                              accounts.
                            </b>
                          </SettingsCardAlert>
                        )}
                      </>
                    ) : (
                      <SettingsCardAlert icon={<LockIcon />} severity="info">
                        This setting can only be modified by your administrator.
                      </SettingsCardAlert>
                    )}
                    <SettingsCardDescription>
                      When importing contacts, we&apos;ll look for duplicate
                      contacts. If no duplicate is found, a new contact will be
                      created.
                    </SettingsCardDescription>
                    <SettingsCardContent>
                      {["loading", "waiting"].includes(accountState) && (
                        <DuplicateRules.Loading />
                      )}
                      {!["loading", "waiting"].includes(accountState) &&
                        account && (
                          <DuplicateRules.Fields
                            usersAutocompleteProps={{
                              disabled: locked,
                              usersQuery: {
                                orgAdmin: true,
                                accountSlug: identifyAccount(account),
                              },
                            }}
                            {...formProps}
                          />
                        )}
                    </SettingsCardContent>
                    <SettingsCardActions
                      sx={{
                        flexDirection: "row-reverse",
                      }}
                    >
                      <Button
                        data-testid="duplicate-rules-save-button"
                        variant="contained"
                        type="submit"
                        disabled={locked}
                      >
                        Save
                      </Button>
                    </SettingsCardActions>
                  </>
                </SettingsCard>
              );
            }}
          </DuplicateRules.Form>
        </Grid>
      </Grid>
    </SettingsPageWrapper>
  );
}
