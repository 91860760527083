import { Component } from "react";
import styled from "styled-components";
import Button from "features/Authentication/components/PrimaryButton";

const Root = styled(Button)`
  position: relative;
`;

const LogoWrapper = styled.div`
  background: rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: auto;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default class AzureButton extends Component {
  render() {
    return (
      <Root aria-label="Sign in With Azure" {...this.props}>
        <LogoWrapper>
          <svg
            width="20px"
            height="16px"
            viewBox="0 0 20 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="azure-1" fill="#FFFFFF" fillRule="nonzero">
                <path
                  d="M10.9271974,0 L5.03123647,5.11288427 L0,14.1436259 L4.53640131,14.1436259 L10.9271974,0 Z M11.7115111,1.19626399 L9.19527432,8.2884889 L14.0199171,14.3502196 L4.6601101,15.9584338 L20,15.9584338 L11.7115111,1.19626399 Z"
                  id="Shape"
                />
              </g>
            </g>
          </svg>
        </LogoWrapper>
        <span>Sign in With Azure</span>
      </Root>
    );
  }
}
