if (
  // If not in prod (development, test, staging, etc)
  !process.env.NODE_ENV?.startsWith("prod")
) {
  const DESIRED_STACK_LENGTH = 3;

  // eslint-disable-next-line no-console
  const originalConsoleError = console.error;
  type ConsoleError = typeof originalConsoleError;

  const newConsoleError: ConsoleError = (...args: unknown[]): void => {
    const firstArg = args[0];
    const lastArg = args[args.length - 1];
    if (
      typeof firstArg === "string" &&
      firstArg.startsWith("Warning: ") &&
      typeof lastArg === "string" &&
      lastArg.split("\n").length > 15
    ) {
      let foundSuspiciousStackFrame = false;
      lastArg.split("\n").forEach((line) => {
        if (line.length > 0 && !line.startsWith("    at ")) {
          foundSuspiciousStackFrame = true;
        }
      });
      if (!foundSuspiciousStackFrame) {
        // If this is a react warning, mutating `args` should not be a problem.
        // eslint-disable-next-line no-param-reassign
        args[args.length - 1] = lastArg
          .split("\n")
          .slice(0, DESIRED_STACK_LENGTH + 1)
          .join("\n");
      }
    }
    originalConsoleError(...args);
  };

  // eslint-disable-next-line no-console
  console.error = newConsoleError;
}

export default {};
