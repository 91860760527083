import { useRef } from "react";

import ImportPreview from "features/ContactImports/containers/NewContactImport/components/ImportPreview";

interface ContactImportPreviewProps {
  isUploading: boolean;
  parsedResults: any;
  onSubmit: (headers: string[]) => void;
}

function ContactImportPreview({
  parsedResults,
  onSubmit,
  isUploading,
}: ContactImportPreviewProps) {
  const previewRef = useRef(null);

  return (
    <div ref={previewRef} style={{ marginBottom: "50px" }}>
      <ImportPreview
        handleSubmit={onSubmit}
        header={parsedResults.data[0]}
        isUploading={isUploading}
        previewRows={parsedResults.data?.slice(1, 6)}
        total={parsedResults.data?.length - 1}
        withHeaders
      />
    </div>
  );
}

export default ContactImportPreview;
