import $ from "jquery";
import Honeybadger from "@honeybadger-io/js";
import findAndReturn from "phoneExtraction/utils/findAndReturn";
import MultiContactParser from "phoneExtraction/MultiContactParser";
import SingleContactParser from "phoneExtraction/SingleContactParser";

export default class MicrosoftDynamicsStrategy {
  constructor({ activeFrameParsedHtml, location }) {
    this.activeFrameParsedHtml = activeFrameParsedHtml;
    this.location = location;
  }

  canParse = () => /employbridge/i.test(this.location?.href);

  getParsers = () => {
    const doc = this.activeFrameParsedHtml;
    const detailPageParser = new SingleContactParser({
      doc,
      namesSelector: [
        '[jawsreadlabel$="firstname_c"]',
        '[jawsreadlabel$="lastname_c"]',
      ],
      textSelector:
        '[jawsreadlabel*="phone"]:not([jawsreadlabel*="emergencycontactphone"])',
      canParse: () => !!$(doc).find("#formContainer")[0],
    });
    const listPageParser = new MultiContactParser({
      doc,
      namesSelector: `[id^="gridBodyTable_primaryField"]`,
      containerSelector: "tr",
      canParse: () => !!$(doc).find(`[id^="gridBodyTable_primaryField"]`)[0],
    });
    const firstNameIndex = Array.from(
      doc.querySelectorAll("td.ms-crm-List-Sortable"),
    ).findIndex(n => /First Name/.test(n.innerText));
    const employeesListPageParser = new MultiContactParser({
      doc,
      getNamesFunction: node => [node.innerText, node.nextSibling.innerText],
      namesSelector: `td.ms-crm-List-DataCell:nth-child(${firstNameIndex + 2})`,
      containerSelector: "tr",
      canParse: () => firstNameIndex !== -1,
    });
    return [detailPageParser, employeesListPageParser, listPageParser];
  };

  parse = () => {
    try {
      if (!this.canParse()) return null;
      const results =
        findAndReturn(this.getParsers())(parser => parser.parse()) || [];
      return { strategy: "MicrosoftDynamicsStrategy", results };
    } catch (error) {
      Honeybadger.notify(error, "MicrosoftDynamicsStrategy");
      return null;
    }
  };
}
