import AutoReplyMessage from "../components/AutoReplyMessage";
import CharacterLimit from "../components/CharacterLimit";
import SavedReplies from "../components/SavedReplies";
import { AutomatedSender } from "../components/AutomatedSender";
import { IntroAndAppended } from "../components/IntroAndAppended";

import { Account } from "models/Account";
import { SettingsSection } from "models/SettingsSection";

export const getMessageSettings = ({
  account,
  isAdmin,
}: {
  account: Account;
  isAdmin: boolean;
}): SettingsSection => {
  const { featureFlags } = account;

  return {
    title: "Message Settings",
    path: "message",
    settings: [
      {
        title: "Templates",
        to: `/templates`,
        OptionComponent: SavedReplies,
      },
      isAdmin
        ? {
            title: "Intro & Appended",
            to: `/intro-appended-message`,
            OptionComponent: IntroAndAppended,
          }
        : null,
      {
        title: "Auto Reply Message",
        to: `/auto-reply-message`,
        OptionComponent: AutoReplyMessage,
      },
      {
        title: "Character Limit",
        to: `/character-limit`,
        OptionComponent: CharacterLimit,
      },
      featureFlags.automatedSender
        ? {
            title: "Automated sender",
            to: `/automated-sender`,
            OptionComponent: AutomatedSender,
          }
        : null,
    ].filter((setting) => {
      return setting !== null;
    }),
  };
};
