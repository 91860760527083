import { Button, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { IndividualScreenBodyProps } from "./types";

function IndividualScreenBody({
  noSteps,
  isArchived,
  toggleAddStepDialog,
  renderSteps,
}: IndividualScreenBodyProps) {
  return noSteps && !isArchived ? (
    <>
      <Typography
        color="textSecondary"
        marginBottom="1rem"
        marginTop="10%"
        sx={{ fontSize: "1rem", fontWeight: 400 }}
        gutterBottom
      >
        Add steps to start your sequence
      </Typography>
      <Button
        data-testid="add-sequence-step"
        disabled={isArchived}
        onClick={() => {
          toggleAddStepDialog();
        }}
        startIcon={<AddIcon />}
        size="medium"
        variant="outlined"
      >
        Step
      </Button>
    </>
  ) : (
    renderSteps()
  );
}

export { IndividualScreenBody };
