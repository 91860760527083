import { compose } from "redux";
import PusherBatchAuthorizer from "pusher-js-auth";
import { useEffect, useMemo } from "react";
import { store } from "../../store";
import { CLIENT_VERSION, POPOUT, TESSERACT } from "../../utils/constants";
import injectSaga from "../../utils/injectSaga";
import saga from "./saga";
import { createPusherInstance } from "./createPusherInstance";
import { PusherOption } from "./PusherInterfaces";
import { addPusherRetriesAndBackoff } from "./utils/addPusherRetriesAndBackoff";
import { authEndpoint } from "utils/pusherConfig";

function PusherComponentV2() {
  // Normalize the state to get appSettings and oidc
  const state = store.getState();
  const stringState = JSON.stringify(state);
  const sanitizedState = JSON.parse(stringState);
  const { pusherConnected, pusherDelay } = sanitizedState.pusher;

  const pusherParams = useMemo((): {
    pusherKey: string;
    pusherOptions: PusherOption;
  } => {
    const { appSettings } = sanitizedState.global.records["/boot"];
    const { oidc } = sanitizedState;
    const accessToken = oidc?.user?.access_token;
    const inPopout = window.self !== window.top;

    const pusherOptions = {
      activityTimeout: appSettings.PUSHER_ACTIVITY_TIMEOUT,
      pongTimeout: appSettings.PUSHER_PONG_TIMEOUT,
      authorizer: PusherBatchAuthorizer,
      authDelay: 600,
      cluster: "mt1",
      authEndpoint,
      auth: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "X-TextUs-Client": inPopout ? POPOUT : TESSERACT,
          "X-TextUs-Client-Version": CLIENT_VERSION,
        },
      },
    };
    return { pusherKey: appSettings.PUSHER_KEY, pusherOptions };
  }, [sanitizedState]);

  useEffect(() => {
    if (pusherConnected) return;
    // TO DEBUG BACKOFF AND DELAY
    // eslint-disable-next-line no-console
    console.log({
      pusherDelay: pusherDelay.delay,
      retries: pusherDelay.currentRetry,
    });

    store.dispatch({
      type: "app/PusherComponent/PUSHER_ADD_DELAY",
      payload: addPusherRetriesAndBackoff(pusherDelay),
    });
    createPusherInstance({ ...pusherParams, delay: pusherDelay.delay, store });
  }, [pusherConnected, pusherDelay, pusherParams, sanitizedState]);

  return null;
}

const withSaga = injectSaga({ key: "pusherComponentV2", saga });
export default compose(withSaga)(PusherComponentV2) as React.FC;
