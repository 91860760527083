import { Box, Theme, Typography, useMediaQuery } from "@mui/material";
import { IntroAndAppendedSectionProps } from "../types";
import { introSectionCopy, messageTypes } from "../constants";
import { IntroMessageSettingsCard } from "./IntroMessageSettingsCard";
import { AppendedMessageSettingsCard } from "./AppendedMessageSettingsCard";
import { DefaultsCard } from "./DefaultsCard";

function IntroAndAppendedSection({
  account,
  copy: { paragraphs, title, slug },
}: IntroAndAppendedSectionProps) {
  const largeScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.up("lg");
  });

  const renderParagraphs = () => {
    return paragraphs.map((paragraph) => {
      return (
        <Typography key={paragraph} paragraph variant="body2" gutterBottom>
          {paragraph}
        </Typography>
      );
    });
  };

  return (
    <Box
      component="section"
      display="flex"
      flexDirection={largeScreen ? "row" : "column"}
      width="100%"
    >
      <Box
        textOverflow="ellipsis"
        overflow="hidden"
        minWidth={largeScreen ? "18rem" : "0rem"}
      >
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column">
        {renderParagraphs()}
        {slug === introSectionCopy.slug ? (
          <IntroMessageSettingsCard account={account} />
        ) : (
          <Box display="flex" flexDirection="column" gap={2}>
            <DefaultsCard account={account} />
            <AppendedMessageSettingsCard
              account={account}
              setting={messageTypes.conversation}
            />
            <AppendedMessageSettingsCard
              account={account}
              setting={messageTypes.campaign}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export { IntroAndAppendedSection };
