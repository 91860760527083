import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { rgba } from "polished";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import hasAdminMembership from "utils/hasAdminMembership";
import { useCurrentUser } from "hooks";
import attachmentPreviewUrl from "utils/attachmentPreviewUrl";

const DeleteButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  visibility: hidden;
  background: ${(props) => {
    return rgba(props.theme.colors.background.paper, 0.7);
  }};
  border-radius: 100%;
  &:hover {
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }};
  }
  .deleteButton {
    cursor: pointer !important;
    &:hover {
      background: inherit !important;
    }
  }
`;

const FileAttachmentStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-width: 200px;
  min-height: 200px;
  background-color: ${(props) => {
    return props.theme.colors.message.outboundBackground;
  }};
  border-radius: 14px;
  padding: 10px;
`;

const WhiteBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  width: 140px;
  height: 140px;

  img {
    filter: invert(20%) sepia(57%) saturate(476%) hue-rotate(178deg)
      brightness(93%) contrast(93%);
  }
`;

const FileName = styled.p`
  width: 140px;
  text-align: left;
  margin: 0;
  font-size: ${(props) => {
    return props.theme.fonts.messageFontSize;
  }};
  word-break: break-all;
`;

const ImageAttachmentStyles = css`
  border-radius: 14px;
`;

const DownloadLink = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  visibility: hidden;
  background: ${(props) => {
    return rgba(props.theme.colors.background.paper, 0.7);
  }};
  border-radius: 100%;

  &:hover {
    background: ${(props) => {
      return props.theme.colors.background.paper;
    }};
  }

  .downloadButton {
    cursor: pointer !important;
    &:hover {
      background: inherit !important;
    }
  }
`;

export const Root = styled.div`
  position: relative;
  margin-right: 5px;
  margin-bottom: ${(props) => {
    return props.noMessageBody ? "0px" : "5px";
  }};
  img {
    max-width: 200px;
    max-height: 200px;
    height: ${(props) => {
      return props.width > props.height
        ? 200 / (props.width / props.height)
        : 200 * (props.height / props.width);
    }}px;
    ${(props) => {
      return !props.isImage && "height: 50px;";
    }};
    ${(props) => {
      return props.isImage && ImageAttachmentStyles;
    }};
  }

  &:hover ${DownloadLink} {
    visibility: visible;
  }

  &:hover ${DeleteButton} {
    visibility: visible;
  }

  & + .MessageAttachment {
    margin-top: 5px;
  }
`;

function MessageAttachment({
  attachment,
  appSettings,
  handleDeleteAttachment,
  isImage,
  showModal,
}) {
  const user = useCurrentUser();
  const [isAdmin, setIsAdmin] = useState(hasAdminMembership(user));

  useEffect(() => {
    setIsAdmin(hasAdminMembership(user));
  }, [user]);

  const attachmentWithLink = (
    <a
      href={attachment.url}
      target="_new"
      onClick={
        isImage
          ? (event) => {
              return showModal && showModal(attachment, event);
            }
          : undefined
      }
    >
      <img
        src={attachmentPreviewUrl(attachment, appSettings, 200, 200)}
        alt="message attachment"
      />
    </a>
  );

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    return setModalOpen(!modalOpen);
  };

  const handleDelete = () => {
    handleDeleteAttachment();
    toggleModal();
  };

  return (
    <Root
      appSettings={appSettings}
      attachment={attachment}
      isImage={isImage}
      height={attachment.height}
      showModal={showModal}
      width={attachment.width}
    >
      {isAdmin && (
        <DeleteButton>
          <Tooltip title="Delete">
            <IconButton onClick={toggleModal} className="deleteButton">
              <DeleteOutline />
            </IconButton>
          </Tooltip>
          <ConfirmationModal
            cancellationHandler={toggleModal}
            confirmationHandler={handleDelete}
            boldText={`This action cannot be undone. `}
            buttonText="Delete"
            isOpen={modalOpen}
            message={`You are about to delete this attachment. `}
            title="Delete attachment?"
          />
        </DeleteButton>
      )}
      <DownloadLink>
        <Tooltip title="Download">
          <IconButton
            href={attachment.downloadUrl}
            className="downloadButton"
            size="large"
          >
            <CloudDownloadIcon />
          </IconButton>
        </Tooltip>
      </DownloadLink>
      {isImage ? (
        attachmentWithLink
      ) : (
        <FileAttachmentStyles>
          <WhiteBox>{attachmentWithLink}</WhiteBox>
          <FileName>{attachment.originalFilename}</FileName>
        </FileAttachmentStyles>
      )}
    </Root>
  );
}

export default MessageAttachment;

MessageAttachment.propTypes = {
  attachment: PropTypes.object,
  appSettings: PropTypes.object.isRequired,
  handleDeleteAttachment: PropTypes.func,
  isImage: PropTypes.bool,
  showModal: PropTypes.func,
};
