export const pattern =
  // eslint-disable-next-line no-useless-escape
  /^[\+]?[\s]?[\(]?[0-9]{3}[\)]?[\s\-\.]?[0-9]{3}[\s\-\.]?[0-9]{4,6}/;

export const validate = (value: string) => {
  if (!pattern.test(value)) {
    return "Invalid phone number";
  }
  return undefined;
};
