import { supportedIntegrations } from "../constants/supportedIntegrations";

// Used in ContactSyncHyperlink.tsx, IntegrationIdLink.tsx
export const getFormattedIntegrationName = (integrationName: string) => {
    if (integrationName.includes("merge")) {
        const extractedIntegrationName = integrationName.split(".")[0];
        const matchedIntegration = supportedIntegrations.find((integration) => {
            return integration.provider === extractedIntegrationName;
        })
        return matchedIntegration?.providerName;
    }
    const matchedIntegration = supportedIntegrations.find((integration) => {
        return integration.provider === integrationName;
    });
    return matchedIntegration?.providerName;
};