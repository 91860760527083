/* eslint-disable jsx-a11y/anchor-is-valid */
import { Typography, Button, Link } from "@mui/material";
import { StyledDropzone } from "./styles";
import { UploaderProps } from "./types";
import { UploadFileIcon } from "icons";

export function Uploader({
  error,
  onFileAccepted,
  onFileRejected,
}: UploaderProps) {
  return (
    <StyledDropzone
      acceptedFileTypes="text/csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      maxFileSize={1024 * 1024}
      onFileAccepted={onFileAccepted}
      onFileRejected={onFileRejected}
      className={error ? "error" : ""}
      error={error}
      rootStyles={{
        width: "100%",
      }}
    >
      <>
        <UploadFileIcon
          data-testid="upload-file-icon"
          color="primary"
          sx={{ margin: "8px" }}
        />

        <Typography variant="body1">
          <Link variant="body1Hyperlink" color="text.hyperlink">
            Click to upload
          </Link>{" "}
          or drag and drop
        </Typography>

        {Boolean(error) === false ? (
          <Typography variant="body2">
            Accepted files are .xlsx, .xls, CSV
          </Typography>
        ) : (
          <Typography variant="body2" color="error.main">
            Unsupported file.
          </Typography>
        )}
      </>
    </StyledDropzone>
  );
}
