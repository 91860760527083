import { Tabs, Tab, List, ListItem, ListItemText } from "@mui/material";
import { useState, useEffect, SyntheticEvent } from "react";
import { BaseDrawer } from "..";
import { StyledBadge } from "../components";
import {
  NoSequencesCreated,
  StyledAccordion,
  StyledAccordionDetails,
  StyledAccordionSummary,
  StyledListItemButton,
  StyledTypography,
} from "./components";
import { SelectSequenceDrawerProps } from "./types";
import { useCurrentAccount, useCurrentUser } from "hooks";
import type { Sequence } from "models/Sequence";

export function SelectSequenceDrawer({
  isOpen,
  sequences,
  isKeywordLink,
  handleSelectSequence,
  handleClose,
}: SelectSequenceDrawerProps) {
  // ==== FIELDS ==== //
  enum NavLink {
    Mine = 0,
    Others = 1,
  }

  // ==== HOOKS ==== //
  const currentUser = useCurrentUser();
  const currentAccount = useCurrentAccount();
  const [selectedNavLink, setSelectedNavLink] = useState<any>(NavLink[0]);
  const [filteredSequences, setFilteredSequences] = useState<any>({
    Mine: {
      ongoing: [],
      scheduled: [],
    },
    Others: {
      ongoing: [],
      scheduled: [],
    },
  });

  useEffect(() => {
    sequences.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });

    const mine: { ongoing: any[]; scheduled: any[] } = {
      ongoing: [],
      scheduled: [],
    };
    const others: { ongoing: any[]; scheduled: any[] } = {
      ongoing: [],
      scheduled: [],
    };

    sequences.forEach((sequence: Sequence) => {
      if (sequence.stepCount > 0) {
        // Ignore if not active.
        if (sequence.state !== "active") {
          return;
        }

        // Mine
        if (sequence.createdBy === currentUser.name) {
          if (sequence.scheduleType === "relative") {
            mine.ongoing.push(sequence);
          } else {
            mine.scheduled.push(sequence);
          }
        }

        // Others
        else if (sequence.scheduleType === "relative") {
          others.ongoing.push(sequence);
        } else {
          others.scheduled.push(sequence);
        }
      }
    });

    setFilteredSequences({
      Mine: mine,
      Others: others,
    });
  }, [currentUser, sequences]);

  // ==== METHODS ==== //
  const handleChange = (ev: SyntheticEvent, newValue: number): void => {
    setSelectedNavLink(NavLink[newValue]);
  };

  const secondaryText = (sequence: Sequence): string => {
    const stepCount =
      sequence.stepCount === 1
        ? `${sequence.stepCount} step`
        : `${sequence.stepCount} steps`;
    return `${sequence.engagementRate}% engagement | ${sequence.pendingCount} pending | ${stepCount}`;
  };

  const mineCount: number =
    filteredSequences.Mine.ongoing.length +
    filteredSequences.Mine.scheduled.length;
  const othersCount: number =
    filteredSequences.Others.ongoing.length +
    filteredSequences.Others.scheduled.length;

  // ==== RENDER ==== //
  return (
    <BaseDrawer
      title={isKeywordLink ? "Link sequence" : "Select sequence"}
      isOpen={isOpen}
      handleClose={handleClose}
      showFooter={false}
    >
      <Tabs
        value={NavLink[selectedNavLink]}
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab label={`Mine (${mineCount})`} />
        {currentAccount.multiUser && othersCount && (
          <Tab label={`Others (${othersCount})`} />
        )}
      </Tabs>

      {/* === ONGOING ACCORDION ==== */}
      <StyledAccordion defaultExpanded className="ongoing-accordion">
        <StyledAccordionSummary>
          <StyledTypography>
            Ongoing ({filteredSequences[selectedNavLink].ongoing.length})
          </StyledTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails className="ongoing-accordion-details">
          {!filteredSequences[selectedNavLink].ongoing.length &&
            NoSequencesCreated}
          <List disablePadding>
            {filteredSequences[selectedNavLink].ongoing.map(
              (sequence: Sequence) => {
                return (
                  <ListItem key={sequence.id} divider disablePadding>
                    <StyledListItemButton
                      disabled={
                        isKeywordLink && Boolean(sequence.linkedKeyword.id)
                      }
                      onClick={() => {
                        handleSelectSequence(sequence);
                      }}
                    >
                      <StyledBadge
                        color={
                          sequence.state === "active" ? "secondary" : "default"
                        }
                      >
                        <ListItemText
                          primary={sequence.name}
                          primaryTypographyProps={{
                            fontSize: "16px",
                          }}
                          sx={{
                            margin: "0 10px 0 0",
                            padding: "0",
                          }}
                        />
                      </StyledBadge>
                      <ListItemText
                        secondary={secondaryText(sequence)}
                        sx={{
                          margin: "0",
                          padding: "0",
                        }}
                      />
                      {sequence.linkedKeyword.id ? (
                        <ListItemText
                          secondary={`Keyword linked: ${sequence.linkedKeyword.keyword}`}
                          sx={{
                            margin: "0",
                            padding: "0",
                          }}
                        />
                      ) : null}
                    </StyledListItemButton>
                  </ListItem>
                );
              },
            )}
          </List>
        </StyledAccordionDetails>
      </StyledAccordion>

      {/* ==== SCHEDULED ACCORDION ==== */}
      <StyledAccordion defaultExpanded className="scheduled-accordion">
        <StyledAccordionSummary>
          <StyledTypography>
            Scheduled ({filteredSequences[selectedNavLink].scheduled.length})
          </StyledTypography>
        </StyledAccordionSummary>
        <StyledAccordionDetails className="scheduled-accordion-details">
          {!filteredSequences[selectedNavLink].scheduled.length &&
            NoSequencesCreated}
          <List disablePadding>
            {filteredSequences[selectedNavLink].scheduled.map(
              (sequence: Sequence) => {
                return (
                  <ListItem key={sequence.id} divider disablePadding>
                    <StyledListItemButton
                      disabled={Boolean(sequence.linkedKeyword.id)}
                      onClick={() => {
                        handleSelectSequence(sequence);
                      }}
                    >
                      <StyledBadge
                        color={
                          sequence.state === "active" ? "secondary" : "default"
                        }
                      >
                        <ListItemText
                          primary={sequence.name}
                          primaryTypographyProps={{
                            fontSize: "16px",
                          }}
                          sx={{
                            margin: "0 10px 0 0",
                            padding: "0",
                          }}
                        />
                      </StyledBadge>
                      <ListItemText
                        secondary={secondaryText(sequence)}
                        sx={{
                          margin: "0",
                          padding: "0",
                        }}
                      />
                      {sequence.linkedKeyword.id ? (
                        <ListItemText
                          secondary={`Keyword linked: ${sequence.linkedKeyword.keyword}`}
                          sx={{
                            margin: "0",
                            padding: "0",
                          }}
                        />
                      ) : null}
                    </StyledListItemButton>
                  </ListItem>
                );
              },
            )}
          </List>
        </StyledAccordionDetails>
      </StyledAccordion>
    </BaseDrawer>
  );
}
