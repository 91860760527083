import { Box } from "@mui/material";
import { useLocation } from "react-router";
import ConversationSkeleton from "../fragments/ConversationSkeleton";
import ConversationListSkeleton from "../fragments/ConversationListSkeleton";
import { useClickToText } from "../hook/useClickToText";

function ClickToTextPage() {
  const { state: contact }: { state: { number: string; name: string } } =
    useLocation();
  const { gotoConversation } = useClickToText();

  // It will go to the conversation or create an empty one if one doesn't exist.
  // It will also create a contact if one doesn't exist.
  gotoConversation({ phoneNumber: contact.number, name: contact.name });

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <ConversationListSkeleton />
      <ConversationSkeleton />
    </Box>
  );
}

export default ClickToTextPage;
