import { TextUsColor } from "./types";

export const blueGrey: TextUsColor = {
  50: "#E7EAEE",
  100: "#C1CAD6",
  200: "#9BA889",
  300: "#76879D",
  400: "#596E8A",
  500: "#3C5679",
  600: "#354F71",
  700: "#2E4566",
  800: "#283C59",
  900: "#212B40",
  A8: "rgba(40, 60, 89, 0.08)",
  A12: "rgba(40, 60, 89, 0.12)",
  A16: "rgba(40, 60, 89, 0.16)",
  A30: "rgba(40, 60, 89, 0.3)",
  A50: "rgba(40, 60, 89, 0.5)",
  A80: "rgba(40, 60, 89, 0.8)",
};
