import { Component } from "react";
import { compose } from "redux";
import Root from "./components/Root";
import withRecord from "higherOrderComponents/withRecord";

class ContactTags extends Component {
  render() {
    return <Root {...this.props} />;
  }
}

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "features/ContactSidebar/containers/ContactTags",
    noFetch: true,
    shape: { members: [] },
    showLoader: () => {
      return false;
    },
    type: "contactTags",
  }),
  withRecord({
    actions: ["update"],
    container: "features/ContactSidebar/containers/ContactTags/Contact",
    type: "contact",
  }),
)(ContactTags);
