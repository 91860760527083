import { TextUsColor } from "./types";

export const lightBlue: TextUsColor = {
  50: "#E2F4FB",
  100: "#BEECFE",
  200: "#87DOEE",
  300: "#65C5ED",
  400: "#46B0E2",
  500: "#38A3DD",
  600: "#3395CE",
  700: "#2C83BB",
  800: "#2872A7",
  900: "#1F5285",
  A8: "rgba(40, 114, 167, 0.08)",
  A12: "rgba(40, 114, 167, 0.12)",
  A16: "rgba(40, 114, 167, 0.16)",
  A30: "rgba(40, 114, 167, 0.3)",
  A50: "rgba(40, 114, 167, 0.5)",
};
