import { useEffect, useMemo, useState } from "react";
import { compose } from "redux";
import isEmpty from "lodash/isEmpty";

import PropTypes from "prop-types";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";

import AccountList from "./components/AccountList";
import useDebounce from "./utils/useDebounce";
import fixedEncodeURIComponent from "utils/fixedEncodeURIComponent";
import Logo from "components/Logo";
import withRecord from "higherOrderComponents/withRecord";

const Wrapper = styled.div`
  min-height: 180px;
`;

const InputWrapper = styled.div`
  padding: 20px;
  padding-bottom: 0;
  position: relative;
`;

const Loading = styled(Logo)`
  height: 100%;
  padding-top: 20px;
  position: absolute;
  right: 20px;
  top: 0;
`;

const ClearSearch = styled.span`
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 11px;
  top: 20px;
  color: ${(props) => {
    return props.theme.colors.primary.main;
  }};
`;

const NoResults = styled.div`
  padding: 20px;
  padding-bottom: 25px;
  text-align: center;
  width: 320px;

  span {
    font-size: 40px;
  }

  p {
    color: ${(props) => {
      return props.theme.colors.text.secondary;
    }};
    margin: 0;
  }
`;

const getFlattenedTree = (treeCollection, nestingLevel = 0) => {
  return treeCollection.members.reduce((acc, tree) => {
    return [
      ...acc,
      { ...tree, nestingLevel },
      ...getFlattenedTree(tree.children, nestingLevel + 1),
    ];
  }, []);
};

function AccountTreeSelector({
  accountTreeCollectionId,
  accountTreeCollection,
  containerHeight,
  fetchAccountCollectionRequest,
  fetchAccountTreeCollectionRequest,
  handleClick,
  selected,
  setSelectedAccounts,
  withCheckboxes,
}) {
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [flattenedTree, setFlattenedTree] = useState([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleAccountClicked = useMemo(() => {
    return (event, account) => {
      handleClick(event, account);
    };
  }, []);

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setAccountList(flattenedTree);
      setLoading(false);
    } else {
      fetchAccountCollectionRequest(
        `/accounts?q=${fixedEncodeURIComponent(debouncedSearchTerm)}`,
        null,
        {
          successCallback: (response) => {
            setAccountList(response.members);
            setLoading(false);
          },
        },
      );
    }
  }, [debouncedSearchTerm]);

  const setTreeCollection = (treeCollection) => {
    const flattened = getFlattenedTree(treeCollection);
    setFlattenedTree(flattened);
    setAccountList(flattened);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    if (!isEmpty(accountTreeCollection)) {
      setTreeCollection(accountTreeCollection);
    } else {
      fetchAccountTreeCollectionRequest(accountTreeCollectionId, null, {
        successCallback: (response) => {
          setTreeCollection(response);
        },
      });
    }
  }, []);

  const showNoResults =
    !loading && debouncedSearchTerm && accountList.length === 0;

  return (
    <Wrapper>
      <InputWrapper>
        <TextField
          fullWidth
          autoFocus
          value={searchTerm}
          placeholder="Search for an account"
          onChange={(event) => {
            setLoading(true);
            setSearchTerm(event.target.value);
          }}
          variant="standard"
        />
        {loading && (
          <Loading animate width={30} dotColor="primary" color="transparent" />
        )}
        {!loading && searchTerm && (
          <ClearSearch
            onClick={() => {
              return setSearchTerm("");
            }}
          >
            <CloseIcon title="clear" />
          </ClearSearch>
        )}
      </InputWrapper>
      {showNoResults ? (
        <NoResults>
          <span role="img" aria-label="shrug emoji">
            🤷‍♀️
          </span>
          <p>No accounts found</p>
        </NoResults>
      ) : (
        <AccountList
          accountList={accountList}
          containerHeight={containerHeight}
          handleClick={handleAccountClicked}
          selected={selected}
          setSelectedAccounts={setSelectedAccounts}
          withCheckboxes={withCheckboxes}
        />
      )}
    </Wrapper>
  );
}

AccountTreeSelector.propTypes = {
  accountTreeCollectionId: PropTypes.string.isRequired,
  accountTreeCollection: PropTypes.object.isRequired,
  containerHeight: PropTypes.number,
  fetchAccountCollectionRequest: PropTypes.func.isRequired,
  fetchAccountTreeCollectionRequest: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  selected: PropTypes.array,
  setSelectedAccounts: PropTypes.func,
  withCheckboxes: PropTypes.bool,
};

export default compose(
  withRecord({
    actions: ["fetch"],
    container: "/features/AccountSelector/containers/Tree",
    type: "accountTreeCollection",
    noFetch: true,
    showLoader: () => {
      return false;
    },
  }),
  withRecord({
    actions: ["fetch"],
    container: "/features/AccountSelector/containers/Search",
    type: "accountCollection",
    shape: { members: [] },
    noFetch: true,
    showLoader: () => {
      return false;
    },
  }),
)(AccountTreeSelector);
