import get from "lodash/get";

const denormalizeWithShape = ({ id, records, shape = {} }) => {
  const record = records[id];
  if (!record) {
    return id;
  }
  const keys = Object.keys(shape).filter((key) => {
    return key !== "schema";
  });
  if (keys.length === 0) return record;
  return keys.reduce((prev, key) => {
    return {
      ...prev,
      [key]: (() => {
        if (Array.isArray(record[key])) {
          return record[key].map((item) => {
            return typeof item === "string"
              ? denormalizeWithShape({
                  id: item,
                  records,
                  shape: shape[key][0],
                })
              : denormalizeWithShape({
                  id: item.id,
                  records,
                  shape: shape[key].find(({ schema }) => {
                    return schema === item.schema;
                  }),
                });
          });
        }
        if (shape[key].type === "Union") {
          return denormalizeWithShape({
            id: get(record, [key, "id"], null),
            records,
            shape: shape[key].schemas.find(({ schema }) => {
              return schema === get(record, [key, "schema"]);
            }),
          });
        }
        return denormalizeWithShape({
          id: record[key],
          records,
          shape: shape[key],
        });
      })(),
    };
  }, record);
};

export default denormalizeWithShape;
